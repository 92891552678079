<template>
  <!-- education -->
  <div class="container">
    <!-- experience -->
    <div class="card_container">
      <div class="flex items-center justify-between header">
        <h3 class="H600">{{ $t("experience.professionalExperience") }}</h3>
        <app-primary-button
          :buttonLabel="$t('experience.addExperience')"
          @click-event="showDialog({ data: 'add' })"
          :disabled="
            (currentPath === '/edit-employee' ||
              currentPath === '/edit-expert' ||
              currentPath === '/profile') &&
            isEditEmployee &&
            !isEditButton
          "
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </div>
      <div class="flex content SPC-MT-400">
        <div class="left_section w-50">
          <h3 class="H500">{{ $t("experience.experienceInfo") }}</h3>
          <p class="P300 desc">
            {{ $t("experience.manageYour") }}
          </p>
        </div>
        <div class="flex w-100 flex-column">
          <div
            v-for="item in values"
            :key="item.id"
            class="flex items-center justify-between my-2 right_section w-100"
          >
            <div>
              <h3 class="H500">{{ item.company }} . {{ item.location }}</h3>
              <p class="P300">{{ item.job_title }} | {{ item.duration }}</p>
            </div>
            <div>
              <DropDown
                @edit-event="showDialog({ $event, data: 'edit', id: item.id })"
                @delete-event="
                  showDeleteDialog({ $event, data: 'delete', id: item.id })
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- dialog for adding and updating education -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <div>
          <app-input-field
            :label="$t('experience.company')"
            :placeholder="$t('experience.enterCompanyName')"
            v-model="company"
            :hasError="hasError"
            :errorMessage="errorMessage"
            @input="hideError"
          ></app-input-field>
          <app-input-field
            :label="$t('experience.location')"
            type="text"
            :placeholder="$t('experience.enterLocationName')"
            v-model="location"
            :hasError="hasError"
            :errorMessage="errorMessage"
            @input="hideError"
          ></app-input-field>
          <app-input-field
            :label="$t('experience.jobTitle')"
            type="text"
            :placeholder="$t('experience.enterJobTitle')"
            v-model="job_title"
            :hasError="hasError"
            :errorMessage="errorMessage"
            @input="hideError"
            id="start-date"
            name="start-date"
          ></app-input-field>
          <v-row>
            <v-col cols="6" class="">
              <app-date-field
                class="w5"
                :label="$t('experience.startDate')"
                type="date"
                placeholder="23 / 02 / 2020"
                v-model="start_date"
                id="start-date"
                name="start-date"
              ></app-date-field>
            </v-col>
            <v-col cols="6" class="">
              <app-date-field
                class="w50"
                :label="$t('experience.endDateReq')"
                type="date"
                placeholder="23 / 02 / 2023"
                v-model="end_date"
                id="end-date"
                name="end-date"
                :min="minEndDate"
              ></app-date-field>
            </v-col>
          </v-row>
        </div>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('experience.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :disabled="
                company === '' ||
                location === '' ||
                job_title === '' ||
                start_date === '' ||
                end_date === ''
              "
              :loading="loading"
              :buttonLabel="
                isEdit
                  ? $t('experience.updateExperience')
                  : $t('experience.addExperience')
              "
              @click-event="isEdit ? updateItem() : addToExperience()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for delete experience -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('experience.noKeepIt')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="confirmDeleteAction()"
            >
              {{ $t("experience.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { mapState } from "vuex";
import { formatDuration, getTransString } from "@/services/helpers";
import AppPrimaryButton from "@/components/shared/AppPrimaryButton.vue";
import DropDown from "@/components/shared/DropDown.vue";

export default defineComponent({
  name: "Experience",
  components: {
    DropDown,
    AppPrimaryButton,
  },
  data() {
    return {
      errorMessage: "",
      hasError: false,
      loading: false,
      addpage: false,
      currentPage: 1,
      totalPages: 50,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      header: ["Institution", "Certificate Title"],
      data: [],
      cancelLabel: "Cancel",
      buttonLabel: "Add Language",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      placeholder: "",
      items: null,
      values: [],
      company: "",
      location: "",
      job_title: "",
      start_date: "",
      end_date: "",
      duration: "3 months",
    };
  },

  mounted() {
    this.getAllExperience();
  },

  computed: {
    minEndDate() {
      if (this.start_date) {
        const minDate = new Date(this.start_date);
        minDate.setDate(minDate.getDate() + 1);
        return minDate.toISOString().split("T")[0];
      }
      return null;
    },

    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    hrConfigLang() {
      return this.translation.locale;
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["hrResources/isEditButton"] &&
        this.canEditPersonalModule?.includes("Qualification")
      );
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    ...mapState(["translation"]),
  },
  watch: {
    startDate(newStartDate) {
      if (this.end_date && this.end_date < newStartDate) {
        this.end_date = null;
      }
    },
  },
  methods: {
    addEmployee() {
      this.$router.push({ name: "addEmployee" });
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog({ data, id }) {
      if (data === "add") {
        this.subTitle = "";
        this.title = "experience.addExperience";
        this.isEdit = false;
        this.buttonLabel = "experience.add";
        this.label = "experience.addTitle";
        (this.location = ""),
          (this.job_title = ""),
          (this.start_date = ""),
          (this.end_date = ""),
          (this.$refs.dialog.showDialog = true);
      } else if (data === "edit") {
        this.isEdit = true;
        this.buttonLabel = "experience.update";
        this.label = "experience.editTitle";
        const selectedItem = this.values.find((item) => item.id === id);
        if (selectedItem) {
          this.company = selectedItem.company;
          (this.location = selectedItem.location),
            (this.job_title = selectedItem.job_title),
            (this.start_date = selectedItem.start_date),
            (this.end_date = selectedItem.end_date),
            (this.title = "experience.updateExperience");
          this.updateId = id; // Store the ID of the item to be updated
          this.$refs.dialog.showDialog = true;
        }
      }
    },
    showDeleteDialog({ data, id }) {
      if (data === "delete") {
        this.isDelete = true;
        this.title = "experience.deleteExperience";
        this.subTitle = "experience.areYouSure";
        this.confirmDelete = id; // Store the ID of the item to be deleted
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },

    confirmDeleteAction() {
      this.deleteItem(this.confirmDelete);
      this.isDelete = false;
      this.confirmDelete = null;
      this.$refs.dialog.showDialog = false;
    },

    editItem() {
      const updatedItem = {
        id: this.editId,
      };
      this.editItem(updatedItem);
      this.isEdit = false;
      this.editId = null;
      this.$refs.dialog.showDialog = false;
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.addTitle = "";
      this.isEdit = false;
      this.isAdd = false;
      this.isDelete = false;
      this.subTitle = "";
      this.buttonLabel = "experience.add";
      this.label = "experience.addTitle";
      this.cancelLabel = "experience.cancel";
    },
    hideError() {
      this.errorMessage = "";
      this.hasError = false;
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      this.isDelete = false;
      this.confirmDelete = null;
    },
    addToExperience() {
      this.loading = true;
      if (
        !this.company ||
        !this.location ||
        !this.job_title ||
        !this.start_date ||
        !this.end_date
      ) {
        this.hasError = true;
        this.errorMessage = "experience.thisFieldIs";
        this.loading = false;
        return;
      }
      const formattedDuration = formatDuration(this.start_date, this.end_date);

      const payload = {
        user: this.isUserKey,
        company: this.company,
        location: this.location,
        job_title: this.job_title,
        start_date: this.start_date,
        end_date: this.end_date,
        duration: formattedDuration,
      };
      Api()
        .post("/hr/expert/qualifications/professional-experience/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.getAllExperience();
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            setTimeout(() => {
              this.addTitle = "";
              this.company = "";
              this.$refs.dialog.showDialog = false;
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllExperience() {
      Api()
        .get(
          `/hr/expert/qualifications/user-professional-experience/${this.isUserKey}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.headers = response.data.data.headers;
            const data = response.data.data;
            this.$refs.dialog.showDialog = false;
            this.values = data.map((obj) => {
              return {
                id: obj.id,
                company: obj.company,
                location: obj.location,
                job_title: obj.job_title,
                start_date: obj.start_date,
                end_date: obj.end_date,
                duration: obj.duration,
              };
            });
            this.$refs.dialog.showDialog = false;
          }
        });
    },
    deleteItem(id) {
      this.selectedItemId = id;
      Api()
        .delete(
          `/hr/expert/qualifications/professional-experience/${this.selectedItemId}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllExperience();
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.isDeleteDialog.showDialog = false;
            this.isDelete = false;
            this.confirmDelete = null;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.$refs.isDeleteDialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateItem(id) {
      const formattedDuration = formatDuration(this.start_date, this.end_date);
      this.loading = true;
      this.selectedItemId = id;
      const payload = {
        company: this.company,
        location: this.location,
        job_title: this.job_title,
        start_date: this.start_date,
        end_date: this.end_date,
        duration: formattedDuration,
      };

      Api()
        .patch(
          `/hr/expert/qualifications/professional-experience/${this.updateId}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.getAllExperience();
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.dialog.showDialog = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
  },
});
</script>

<style scoped>
label {
  color: #344054;
}

.header {
  padding-top: 10px;
}
.desc {
  width: 300px;
}
</style>
