<template>
  <div class="container">
    <div class="table">
      <app-table
        :isCalender="true"
        :headers="headers"
        :items="isEmployeeLeaves"
        @delete-event="showDelete({ $event, data: 'delete' })"
        pagename="Leaves"
        message="Leaves"
        @edit-event="showDetail({ $event, data: 'detail' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("leaves.AllLeaves") }}</h3>
        </template>
        <template #actions v-if="canEditPersonalModule?.includes('Time Off')">
          <app-primary-button
            @click-event="showDialog({ data: 'add' })"
            :buttonLabel="$t('leaves.newLeave')"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <!-- dialog  for add and update position-->
    <app-calender-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="662"
      width="1808"
    >
      <template #content>
        <div>
          <HrBookingCalender @close-event="hideDialog" />
          <!-- <LeavesCalender v-else @close-event="hideDialog" /> -->
        </div>
      </template>
    </app-calender-dialog>
    <!-- dialog for delete position -->
    <app-calender-dialog ref="isDetail" :title="title" :subtitle="subTitle">
      <template #content>
        <div>
          <HrDetailCalender @close-event="closeDetails" />
        </div>
      </template>
    </app-calender-dialog>
    <app-dialog
      ref="delete"
      :title="$t('Delete Leave?')"
      :subtitle="`Are you sure you want to permanently delete this Leave ? This action can’t be undone.`"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('No, Keep it')"
              @click-event="closeDelete()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteLeave()"
            >
              Yes , delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import {defineComponent} from "vue";
import Api from "@/services/api";
import AppCalenderDialog from "@/components/shared/AppCalenderDialog.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
// import LeavesCalender from '../employees/LeavesCalender.vue'
import HrBookingCalender from "../hrleave/HrBookingCalender.vue"
import HrDetailCalender from "../hrleave/HrDetailCalender.vue";
import {getEmployeeLeave, } from '@/services/endpoints'
import {useI18n} from 'vue-i18n';
import moment from 'moment'
import {getTransString} from "@/services/helpers";
import {isEqual} from "lodash";

export default defineComponent({
  name: "Leaves",
  components: {
    Paginator,
    // LeavesCalender,
    HrBookingCalender,
    HrDetailCalender,
    AppCalenderDialog,
  },
  created() {
    this.$store.commit('hrResources/SET_CURRENT_TAB_TO_STATE', 4);

  },
  data() {
    return {
      headers: [
        "Leave Type",
        "Date",
        "Days",
        "Status",
      ],
      currentPages: null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      data: [],
      values: [],
      options: [""],
      cancelLabel: "Cancel",
      buttonLabel: "Add position",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      firstRun: true,
      addTitle: "",
      modelValue: "",
      probation_period_date: "",
      placeholder: "",
      carryover: " ",
      defaultCarryOver: "",
      selectedEmployee: null,
      selectedItems: [],
      approvers: [],
      selectedName: '',
      isLeavedeleteId: '',
      originalDate: new Date('2023-03-31'),
    };
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  computed: {
    sameDate() {
      const currentYear = new Date().getFullYear();
      return new Date(currentYear, this.originalDate.getMonth(), this.originalDate.getDate());
    },

    inputDependencies() {
      return [
        this.selectedEmployee,
        this.carryover
      ]
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"]
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isCompaniesTitle() {
      return this.$store.getters["hrConfigurations/isCompaniesTitle"];
    },
    isPositionsTitle() {
      return this.$store.getters["hrConfigurations/isPositionsTitle"];
    },
    isTeamsTitle() {
      return this.$store.getters["hrConfigurations/isTeamsTitle"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    isEmployeeLeaves() {
      const allEmployeeLeaves = this.$store.getters["hrResources/isEmployeeLeaves"];
      return allEmployeeLeaves.reduce((accumulator, current) => {
        const newCurrent = {...current};
        newCurrent.status = this.transText(current.status)
        newCurrent['leave type'] = this.transText(current['leave type'])
        return [...accumulator, newCurrent]
      }, [])
    },
    getAllEmployees() {
      const employees = this.$store.getters["hrResources/isAllEmployees"];
      return employees.map((employee) => ({
        text: employee.first_name + " " + employee.last_name,
        value: employee.employee_id,
      }));
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections
    },
  },

  watch: {
    inputDependencies: {
      immediate: true,
      handler(newValue) {
        if (!this.firstRun) {
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            "Leaves": !isEqual(newValue, [null, this.defaultCarryOver])
          });
        }
      }
    }
  },

  methods: {
    handleselect(data) {
      this.selectedEmployee = data?.$event?.value
    },

 transText(text) {
      return this.t(getTransString(text));
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showDialog(data) {
      this.leaveId = data?.$event?.id;
      this.leaveType = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "add") {
        this.title = "";
        this.$refs.dialog.showDialog = true;
      }
    },
    hideDialog() {
      this.getAllLeaves(this.currentPage);
      this.$refs.dialog.showDialog = false;

    },

    showDetail(data) {
      this.leaveId = data?.$event?.id;
      this.leaveType = data?.$event.leaveType;
      this.status = data?.$event.status;
      this.month = data?.$event.month
      this.year=data?.$event.year
      this.$store.commit("hrResources/SET_LEAVE_ID_TO_STATE", this.leaveId);
      this.$store.commit("hrResources/SET_LEAVE_TYPE_TO_STATE", this.leaveType);
      this.$store.commit("hrResources/SET_LEAVE_STATUS_TO_STATE", this.status);
      this.$store.commit("hrResources/SET_LEAVE_MONTH_TO_STATE", this.month);
      this.$store.commit("hrResources/SET_LEAVE_YEAR_TO_STATE", this.year);
      if (data.data === "detail") {
        this.isDelete = !this.isDelete;
        this.$refs.isDetail.showDialog = true;
      }
    },
    closeDetails() {
      this.$refs.isDetail.showDialog = false;
      this.getAllLeaves(this.currentPage);
    },
    getAllLeaves(page) {
      const userKey = this.isUserKey;

      const payload = {
        user_key: userKey,
      };

      Api()
          .post(`${getEmployeeLeave}${page}/`, payload)
          .then((response) => {
            if (response.data.status === "success") {
              const data = response.data.data;
              this.$refs.dialog.showDialog = false;
              this.values = data.map((obj) => {
                const startDate = moment(obj.leave_start_date).format('Do MMM YYYY');
                const Year = moment(obj.leave_start_date).format('YYYY');
                const endDate = moment(obj.leave_end_date).format('Do MMM YYYY');
               
                const days = obj.total_days;
                let result = '';

                if (days > 0) {
                  result += `${days} ${days > 1 ?  this.t("calender.days") : this.t("calender.day")}`;
                }
                return {
                  id: obj.id,
                  days: result,
                  date: ` ${startDate} - ${endDate}`,
                  status: obj.status,
                  'leave type': obj.leave_type,
                  leaveType: obj.leave_type,
                  month: obj.leave_start_date,
                  is_approver_approved:obj.is_approver_approved,
                  year: Year,
                };
              });
              this.$refs.dialog.showDialog = false;
              this.$store.commit("hrResources/SET_EMPLOYEE_LEAVES_TO_STATE", this.values);
              this.$store.commit(
                  "hrResources/SET_CURRENT_PAGE_TO_STATE",
                  response.data.current_page
              );
              this.$store.commit(
                  "hrResources/SET_TOTAL_PAGES_TO_STATE",
                  response.data.total_data
              );
            }
          });
    },



    loadMore() {
      const page = this.currentPages;
      this.getAllLeaves(page);
    },
    close() {
      this.$router.push({name: "employees"})
    },
    showDelete(data) {
      this.isLeavedeleteId = data?.$event?.id;
      if (data.data === "delete") {
        this.isDelete = !this.isDelete;
        this.$refs.delete.showDialog = true;
      }
    },
    closeDelete() {
      this.$refs.delete.showDialog = false;
      this.getAllLeaves(this.currentPage);
    },
    deleteLeave() {
      Api()
          .delete(`/hr/employee/delete-leave/${this.isLeavedeleteId}/`,)
          .then((response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
              this.$refs.delete.showDialog = false;
              this.getAllLeaves(this.currentPage)
            }
          })
          .catch((error) => {
            if (error) {
              this.getAllLeaves(this.currentPage)
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
              this.$refs.delete.showDialog = false;
            }
          });

    },

  },

  mounted() {
    this.$store.dispatch("hrResources/getAllEmployees");
    this.getAllLeaves(this.currentPage);


  },

});
</script>

<style scoped>
.table {
  width: 100%;
}
</style>
