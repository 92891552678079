

export default {
    namespaced: true,
    state: {
        viewTimesheetYear: null,
        viewTimesheetMonth: new Date(new Date().getFullYear(), new Date().getMonth()).toLocaleString('en-us', { month: 'long' }),
    },
    mutations: {
        setViewTimesheetYear: (state: any, payload: any) => {
            state.viewTimesheetYear = payload
        },
        setViewTimesheetMonth: (state: any, payload: any) => {
            state.viewTimesheetMonth = payload
        },
    },
    getters: {
        getViewTimesheetYear: (state: any) => state.viewTimesheetYear,
        getViewTimesheetMonth: (state: any) => state.viewTimesheetMonth,
    },
    actions: {}
}