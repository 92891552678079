<template>
  <v-sheet class="bg-transparent">
    <app-table
      :showCursor="false"
      :items="isCostCenters"
      :headers="header"
      @edit-event="showDialog({ $event, data: 'edit' })"
      @delete-event="showDeleteDialog({ $event, data: 'delete' })"
    >
      <template #title>
        <h3 class="H500">
          {{ $t("projectConfiguration.allCostCenter") }}
        </h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('projectConfiguration.addCostCenter')"
          @click.prevent="showDialog({ data: 'add' })"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
      <template #footer>
        <Paginator
          :total-items="totalPages"
          v-model="currentPages"
          :items-per-page="10"
          :max-pages-shown="5"
          :on-click="loadMore"
        />
      </template>
    </app-table>

    <!-- dialog for creating and editin cost center -->
    <create-edit-cost-center
      ref="createEditCostCenter"
    ></create-edit-cost-center>

    <!-- dialog for deleting cost center-->
    <delete-cost-center ref="deleteCostCenter"></delete-cost-center>
  </v-sheet>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { getTransString } from "@/services/helpers"
import Paginator from "@/components/shared/PaginationComponent.vue";
import CreateEditCostCenter from "./cost-center-dialogs/CreateEditCostCenters.vue"
import DeleteCostCenter from './cost-center-dialogs/DeleteCostCenter.vue';
export default defineComponent({
    name: "Cost Centers",
    components: {
    Paginator,
    CreateEditCostCenter,
    DeleteCostCenter,

  },
  data(){
    return{
      currentPages: 1,
      isEdit: false,
      header: ["Number", "Title", "Type", "ICON Company"]
    }
  },

  computed: {

    isCostCenters() {
      const costCenter = this.$store.getters['hrConfigurations/isCostCenters'];
      const modifiedData = costCenter.map((item)=>{
        return {
          number: item.cost_center_number,
          title: item.title,
          type: item.cost_center_type,
          'icon company': item.icon_company__title,
          id : item.id
        }
      })
      return modifiedData

    },
    currentPage() {
      return this.$store.getters["hrConfigurations/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrConfigurations/isTotalPages"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showDialog(data) {
      if (data.data === "add") {
       this.$refs.createEditCostCenter.showCreateCostCenterDialog()
      } else if (data.data === "edit") {
        this.isEdit = !this.isEdit;
        this.$refs.createEditCostCenter.editObject = data
        this.$refs.createEditCostCenter.showEditCostCenterDialog()
      }
    },

    showDeleteDialog(data) {
      if (data.data === "delete") {
        this.$refs.deleteCostCenter.showDialog()
        this.$refs.deleteCostCenter.costCenterID = data?.$event?.id;
      }
    },
    loadMore() {
      const page = this.currentPages;

      this.$store.dispatch('hrConfigurations/getAllCostCenters', page)
    },
},

mounted() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 4)
  },

  created() {
    this.$store.dispatch('hrConfigurations/getAllCostCenters', this.currentPage)
  }
})
</script>
