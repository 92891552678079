export const removeSquareBrackets = (data) => {
  const result = [];
  for (const item of data) {
    if (Array.isArray(item)) {
      result.push(...item);
    } else {
      result.push(item);
    }
  }
  return result;
};
// format the number to percentage
export function formatAsPercentage(num) {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
}
// format strings
export function convertToFormattedString(inputString) {
  if (inputString.includes("_")) {
    const words = inputString.split("_");
    const formattedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return formattedWords.join(" ");
  } else {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
}
// curency converter
export function convertCurrencyFormat(inputValue) {
  try {
    // Check if inputValue is not a valid number
    if (isNaN(inputValue)) {
      return inputValue;
    }

    if (typeof inputValue === "string") {
      // Remove any non-numeric characters and check if it matches the format
      const cleanedValue = inputValue.replace(/[^\d.-]/g, "");
      const pattern = /^-?\d+(\.\d+)?\(-?\d+(\.\d+)?%\)$/;
      if (pattern.test(cleanedValue)) {
        return inputValue; // Return the value as is
      }
    }

    // Apply formatting for other cases
    const formatter = new Intl.NumberFormat("en-US");
    const formattedAmount = formatter.format(Number(inputValue));

    return formattedAmount;
  } catch (error) {
    return error.message;
  }
}
