<template>
  <div>
    <form>
      <v-container class="ml-0">
        <v-row>
          <v-col cols="6">
            <app-single-select
              :labelPlaceholder="$t('projects.bankGuarantee.selectBankName')"
              :label="$t('projects.bankGuarantee.bankName')"
              :searchPlaceholder="$t('projects.bankGuarantee.searchBankName')"
              :isTranslation="false"
              :showCheckbox="false"
              :showSearch="true"
              :options="isAllBankDetails"
              v-model="payload.bank_id"
              @update:modelValue="valueToInputField"
              :disabled="shouldDisableInput ? 'disabled' : null"
              :hasError="isFieldInvalid('bank_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.bankGuarantee.enterAccountNumber')"
              :label="$t('projects.bankGuarantee.accountNumber')"
              v-model="bank_number"
              disabled
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="
                $t('projects.bankGuarantee.enterAmountOfBankGuarantee')
              "
              :label="$t('projects.bankGuarantee.amountOfBankGuarantee')"
              v-model.number="payload.amount"
              :disabled="shouldDisableInput ? 'disabled' : null"
              validation="floatingNumber"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-toggle-button
              v-model="payload.advance"
              :label="$t('projects.bankGuarantee.advance')"
              class="justify-between"
            ></app-toggle-button>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.bankGuarantee.enterBankGuaranteeNr')"
              :label="$t('projects.bankGuarantee.bankGuaranteeNr')"
              v-model="payload.bank_guarantee_nr"
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.bankGuarantee.enterReductionToBank')"
              :label="$t('projects.bankGuarantee.reductionToBank')"
              v-model.number="payload.reduction_to_bank"
              :disabled="shouldDisableInput ? 'disabled' : null"
              validation="floatingNumber"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.bankGuarantee.enterAvalCommission')"
              :label="$t('projects.bankGuarantee.avalCommission')"
              v-model.number="payload.aval_commission"
              :disabled="shouldDisableInput ? 'disabled' : null"
              validation="floatingNumber"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-input-field
              type="date"
              :label="$t('projects.bankGuarantee.endOfBankGuarantee')"
              v-model="payload.end_of_bank_guarantee"
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-toggle-button
              v-model="payload.bank_guarantee_expiration_status"
              :label="$t('projects.bankGuarantee.bankGuaranteeExpired')"
              :disabled="shouldDisableInput ? 'disabled' : null"
              class="justify-between"
            ></app-toggle-button>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="2" class="flex justify-end pt-10"
            ><app-primary-button
              class="w-24"
              :buttonLabel="$t('projects.save')"
              :loading="isLoading"
              @click="
                isBankGuarantee === null ||
                isBankGuarantee.bank_guarantee === null
                  ? saveBankGuarantee()
                  : updateBankGuarantee()
              "
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-primary-button
          ></v-col>
        </v-row>
      </v-container>
    </form>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue";
import { addOrUpdateBankGuarantee, fetchContractDetails } from "@/services/endpoints"
import Api from "@/services/api";
import { currencyFormatter, getTransString } from "@/services/helpers";
import { isEqual } from 'lodash';
import { mapState } from "vuex";

export default defineComponent({
    name: "BankGuarantee",
    props: {},
    data() {
        return {
          payload: {
            bank_id: null,
            amount: 0,
            advance: false,
            reduction_to_bank: 0,
            aval_commission: 0,
            end_of_bank_guarantee: null,
            bank_guarantee_expiration_status: false,
            bank_guarantee_nr: '',
            contract_id: this.$store.state.projectModules.contractID
          },
          originalPayload: {
            bank_id: null,
            amount: 0,
            advance: false,
            reduction_to_bank: 0,
            aval_commission: 0,
            end_of_bank_guarantee: "",
            bank_guarantee_expiration_status: false,
            bank_guarantee_nr: "",
            contract_id: this.$store.state.projectModules.contractID
          },
          bank_number: "",
          alertType: "success",
          alertState: false,
          alertTitle: "",
          isLoading: false,
          requiredFields: ['bank_id'],
          isDirty: [],
          hasError: ""
        }
    },
    computed: {
      isProjectID(){
      return this.$store.getters["projectModules/isProjectID"]
     },
      isAllBankDetails(){
        return this.isComputedBankDetails?.map((obj) => {
          const { id: value, "bank_name": text, "account_number": account_number } = obj;
          return { account_number, value, text };
        });
      },
      isComputedBankDetails() {
      return this.$store.getters["foreignOfficeModule/allBanks"];
    },
      contractDetails() {
        return this.$store.getters["projectModules/isContractDetails"];
      },
      currentPath() {
        return this.$route.path;
      },
      isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      shouldDisableInput() {
      // Add your condition to disable the input element here
       return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
      },
      isBankGuarantee(){
        const modifiedData = this.$store.getters["projectModules/isContractDetails"]
        return Object.keys(modifiedData).length === 0 ? null : modifiedData
      },
      appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),

    },
    watch: {

      payload: {
        deep: true,
        handler(value) {

          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectContract": !isEqual(value, this.originalPayload)
        });

        },
      },
      shouldDisableInput(){
        this.payload.amount = this.contractDetails?.bank_guarantee?.amount
    }
    },
    methods: {
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
      checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
    },
      isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
      inputFieldsValidation(){
    this.hasError = false
    Object.keys( this.payload).forEach((key) => {
        if (this.requiredFields?.includes(key) && ( this.payload[key] === "" ||  this.payload[key] === undefined || this.payload[key] === null)) {
          this.isDirty.push(key);

          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {

          return this.hasError;
        }

  },
      saveBankGuarantee(){
        if(this.payload.contract_id === null){
          this.hasError = true
          this.checkAlertEror('Contract ID is required. Please fill the contract details section')
        }

        this.inputFieldsValidation()
        if (this.hasError) return this.hasError;
        Api()
        .post(addOrUpdateBankGuarantee, this.payload)
        .then(response => {
          if(response.data.status === "success"){
            // this.getBankGuarantee()
            this.alertType = "success",
            this.alertState = true
            this.alertTitle = response?.data?.detail
          }
        })
      },
      valueToInputField(){
        if(this.payload.bank_id){
          const bank  = this.isComputedBankDetails?.find(account => {
            if(account.id === this.payload.bank_id){
              return account
            }
          })
          this.bank_number = bank['account_number']
        }

      },
      updateBankGuarantee(){

        this.inputFieldsValidation()
        if (this.hasError) return this.hasError;
        const updatePayload = {
          bank_guarantee_id: this.$store.state.projectModules.contractDetails.bank_guarantee.id,
          bank_id: this.payload.bank_id,
          amount: this.payload.amount,
          advance: this.payload.advance,
          reduction_to_bank: this.payload.reduction_to_bank,
          aval_commission: this.payload.aval_commission,
          end_of_bank_guarantee: this.payload.end_of_bank_guarantee,
          bank_guarantee_expiration_status: this.payload.bank_guarantee_expiration_status,
          bank_guarantee_nr: this.payload.bank_guarantee_nr,
          contract_id: this.$store.state.projectModules.contractID
        }

        Api()
        .post(addOrUpdateBankGuarantee, updatePayload)
        .then(response => {
          if(response.data.status === "success"){
            this.alertType = "success",
            this.alertState = true
            this.alertTitle = response?.data?.detail
          }
        })
      },
      getBankGuarantee(){
        Api()
        .get(fetchContractDetails + `${this.isProjectID}`)
        .then(response => {
          if(response.data.status === "success"){
            const contractData = response.data.data

            if(contractData?.bank_guarantee=== null) return
            this.payload.bank_id = contractData?.bank_guarantee?.bank_id
            this.payload.advance = contractData?.bank_guarantee?.advance
            this.payload.amount = Number(contractData?.bank_guarantee?.amount)?.formatCurrency()
            this.payload.bank_guarantee_nr = contractData?.bank_guarantee?.bank_guarantee_nr
            this.payload.reduction_to_bank = Number(contractData?.bank_guarantee?.reduction_to_bank)?.formatCurrency()
            this.payload.aval_commission = Number(contractData?.bank_guarantee?.aval_commission)?.formatCurrency()
            this.payload.bank_guarantee_expiration_status = contractData?.bank_guarantee?.bank_guarantee_expiration_status
            this.payload.end_of_bank_guarantee = contractData?.bank_guarantee?.end_of_bank_guarantee
            this.bank_number = contractData.bank_guarantee?.account_number

            this.originalPayload.bank_id = contractData?.bank_guarantee?.bank_id
            this.originalPayload.advance = contractData?.bank_guarantee?.advance
            this.originalPayload.amount = Number(contractData?.bank_guarantee?.amount)?.formatCurrency()
            this.originalPayload.bank_guarantee_nr = contractData?.bank_guarantee?.bank_guarantee_nr
            this.originalPayload.reduction_to_bank = contractData?.bank_guarantee?.reduction_to_bank
            this.originalPayload.aval_commission = contractData?.bank_guarantee?.aval_commission
            this.originalPayload.bank_guarantee_expiration_status = contractData?.bank_guarantee?.bank_guarantee_expiration_status
            this.originalPayload.end_of_bank_guarantee = contractData?.bank_guarantee?.end_of_bank_guarantee

          }
        })
      }
    },
    mounted(){

    },
    created(){
      // this.payload.project_amount = this.appLang === "de" ? currencyFormatter(this.contractDetails.project_amount) : Number(this.contractDetails.project_amount)?.formatCurrency()
      this.$store.dispatch('foreignOfficeModule/getAllBanks')
      if(Object.keys(this.contractDetails).length > 0){
        if(this.contractDetails?.bank_guarantee=== null || this.contractDetails?.bank_guarantee?.length=== 0) return
            this.payload.bank_id = this.contractDetails?.bank_guarantee?.bank_id
            this.originalPayload.bank_id = this.contractDetails?.bank_guarantee?.bank_id
            this.payload.advance = this.contractDetails?.bank_guarantee?.advance
            this.originalPayload.advance = this.contractDetails?.bank_guarantee?.advance
            if(this.shouldDisableInput){
              this.payload.amount = this.appLang === "de" ? currencyFormatter(this.contractDetails?.bank_guarantee?.amount) : Number(this.contractDetails?.bank_guarantee?.amount)?.formatCurrency()
              this.originalPayload.amount = this.appLang === "de" ? currencyFormatter(this.contractDetails?.bank_guarantee?.amount) : Number(this.contractDetails?.bank_guarantee?.amount)?.formatCurrency()
            }else{
              this.payload.amount = this.contractDetails?.bank_guarantee?.amount
              this.originalPayload.amount = this.contractDetails?.bank_guarantee?.amount
            }
            this.payload.bank_guarantee_nr = this.contractDetails?.bank_guarantee?.bank_guarantee_nr
            this.payload.reduction_to_bank = Number(this.contractDetails?.bank_guarantee?.reduction_to_bank)?.formatCurrency()
            this.payload.aval_commission = Number(this.contractDetails?.bank_guarantee?.aval_commission)?.formatCurrency()
            this.payload.bank_guarantee_expiration_status = this.contractDetails?.bank_guarantee?.bank_guarantee_expiration_status
            this.payload.end_of_bank_guarantee = this.contractDetails?.bank_guarantee?.end_of_bank_guarantee

            this.originalPayload.bank_guarantee_nr = this.contractDetails?.bank_guarantee?.bank_guarantee_nr
            this.originalPayload.reduction_to_bank = Number(this.contractDetails?.bank_guarantee?.reduction_to_bank)?.formatCurrency()
            this.originalPayload.aval_commission = Number(this.contractDetails?.bank_guarantee?.aval_commission)?.formatCurrency()
            this.originalPayload.bank_guarantee_expiration_status = this.contractDetails?.bank_guarantee?.bank_guarantee_expiration_status
            this.originalPayload.end_of_bank_guarantee = this.contractDetails?.bank_guarantee?.end_of_bank_guarantee
            this.bank_number = this.contractDetails.bank_guarantee?.account_number
            this.$store.dispatch('hrResources/getAllBankDetails',1)
      }
    }

});
</script>
