<template>
  <div>
    <app-header
      :name="employeeName"
      class="mb-5 ml-2"
      :show-arrow-back="true"
      :clickEvent="handleGoToBack"
    >
      <template #action v-if="canEdit">
        <app-secondary-button
          v-show="hideEditButton"
          :buttonLabel="$t('experts.editProfile')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
    </app-header>
    <div>
      <div>
        <button
          v-for="(tab, index) in tabList"
          :key="index"
          :class="['tab-button', { active: currentTab === tabs[index] }]"
          @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component :is="currentTab" class="pt-5"></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
import Personal from "@/components/dashboard/experts/Personal.vue";
import Contact from "@/components/dashboard/experts/Contact.vue";
import Qualification from "@/components/dashboard/experts/Qualifications.vue";
import Projects from "@/components/dashboard/experts/Projects.vue";
import Documents from "@/components/dashboard/experts/Documents.vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";

export default defineComponent({
  name: "AddEmployee",
  components: {
    AppUnsavedChangesDialog,
    Personal,
    Contact,
    Qualification,
    Projects,
    Documents,
    AppHeader,
  },
  data() {
    return {
      employeeName: "",
      currentTab: "Personal",
      tabs: ["Personal", "Contact", "Qualification", "Documents"],
    };
  },
  computed: {
    noAccess() {
      const modifiedData = this.$store.getters["auth/noAccess"];
      return modifiedData
        .filter((module) => module.module_parent_name === "Expert")
        .map((module) => module.module_name);
    },
    canView() {
      return this.$store.getters["auth/canView"];
    },

    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData
        .filter((module) => module.module_parent_name === "Expert")
        .map((module) => module.module_name);
      this.$store.commit("auth/SET_CAN_EDIT_TO_STATE", findModules);
      return findModules;
    },

    tabList() {
      const notFoundInFilter = [];

      for (const item of this.tabs) {
        if (!this.noAccess.includes(item)) {
          notFoundInFilter.push(item);
        }
      }
      return notFoundInFilter;
    },
    hideEditButton() {
      const excludes = { Documents: "User Documents" };
      if (Object.keys(excludes).includes(this.currentTab)) {
        return (
          !this.isEditButton && this.canEdit.includes(excludes[this.currentTab])
        );
      }
      return !this.isEditButton && this.canEdit.includes(this.currentTab);
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isExpertsUpdate() {
      return this.$store.getters["expertModules/isExpertsUpdate"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isEmployeeName() {
      return this.$store.getters["hrResources/isEmployeeName"];
    },
  },
  mounted() {
    this.currentTab = this.tabs[0];
    this.$refs.unsavedChanges.showDialog = false;
  },
  watch: {
    isUserKey(newVal) {
      if (newVal) {
        const currentIndex = this.tabs.indexOf(this.currentTab);
        if (currentIndex !== -1 && currentIndex < this.tabs.length - 1) {
          this.currentTab = this.tabs[currentIndex + 1];
        }
      }
    },
  },
  methods: {
    showEditButton() {
      this.$store.commit(
        "hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE",
        this.canEdit.includes(
          this.currentTab === "Documents" ? "User Documents" : this.currentTab
        )
      );
    },
    handleProceed(index) {
      this.newTabIndex = index;
      if (this.isExpertsUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    handleGoToBack() {
      if (Object.values(this.isExpertsUpdate).some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isExpertsUpdate).reduce(
        (acc, curr) => {
          if (this.isExpertsUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("expertModules/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isExpertsUpdate).some((elt) => elt === true);
    },
  },
  created() {
    this.employeeName = this.isEmployeeName;
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>
