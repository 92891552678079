<template>
  <div>
    <div class="flex flex-col">
      <label class="mb-1" for="">{{ label }}</label>
      <div class="select-wrapper">
        <select
        :disabled="disabled"
          v-model="selectedYear"
          class="border outline-none w-[320px] rounded-lg p-2 cursor-pointer hover:border-[#d8dae5] focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] focus:ring peer block border-1 border-solid border-gray-300 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);"
          @change="onChange"
        >
          <option value="" disabled selected>
            {{ $t("Select year"?.getTranslationKey()) }}
          </option>
          <option v-for="year in availableYears" :value="year" :key="year">
            {{ year }}
          </option>
        </select>
        <span class="icon-calendar">
          <v-icon  icon="custom:calendarIcon" size="20" :class="{'!text-slate-400': disabled}" ></v-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
export default defineComponent({
  name: "AppYearSelect",
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Year',
    },
  },
  data() {
    return {
      selectedYear: this.value || "",
      availableYears: this.generateYears(),
    };
  },
  computed: {},
  watch:{
    value(val){
      this.selectedYear = val;
    },
    selectedYear(val){
      this.$emit('update:modelValue', val);
    }
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      let years = [];
      for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push(i);
      }
      return years;
    },
    onChange() {
      this.$emit('update:modelValue', this.selectedYear);
    },
  },
  created() {},
});
</script>

<style scoped>
select:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
</style>
