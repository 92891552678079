<!-- <template lang="">
  <v-col cols="12">
    <div class="w-full">
      <div class="flex items-center justify-space-between">
        <div class="flex items-center header ml-n2">
          <div style="cursor: pointer" @click="goToPrevious">
            <v-icon
              v-if="showArrowBack"
              icon="custom:arrowBackIcon"
              class="mr-2"
            />
          </div>
          <h4 class="H700 ">{{ name }}</h4>
        </div>
        <div>
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </v-col>
</template>
<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App Header",
  props: {
    name: {
      type: String,
      default: "",
    },

    showArrowBack: {
      type: Boolean,
      default: true,
    },
    clickEvent: {
      type: Function,
    },
  },
  data() {
    return {};
  },
  methods: {
    // functions
    goToPrevious(){
      if (typeof this.clickEvent === 'function') {
        this.clickEvent();
      } else {
        this.$router.go(-1);
      }
    }
  },
});
</script> -->

<template>
  <v-col cols="12">
    <div class="w-full">
      <div class="flex items-center justify-between">
        <div class="flex items-center header ml-n2">
          <div style="cursor: pointer" @click="goToPrevious">
            <v-icon
              v-if="showArrowBack"
              icon="custom:arrowBackIcon"
              class="mr-2"
            />
          </div>
          <div class="tooltip-container">
            <h4 class="cursor-default H700 line-clamp-1">
              {{ subStringProjectName(name) }}
            </h4>
            <p
              v-if="showTooltip"
              class="tooltip-text P200 absolute top-[70px] px-3"
            >
              {{ name }}
            </p>
          </div>
        </div>
        <div>
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </v-col>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { truncateText } from "@/services/helpers";

export default defineComponent({
  name: "App Header",
  props: {
    name: {
      type: String,
      default: "",
    },

    showArrowBack: {
      type: Boolean,
      default: true,
    },
    clickEvent: {
      type: Function,
    },
    fallbackRoute: {
      type: String,
      default: "",
    }
  },
  data() {
    return {};
  },
  methods: {
    // functions
    goToPrevious() {
      if (typeof this.clickEvent === 'function') {
        this.clickEvent();
      } else if (window.history.state?.back ===  null && this.fallbackRoute)  {
        this.$router.push(this.fallbackRoute);
      } else {
        this.$router.go(-1);
      }
    },
    showTooltip(name) {
      if (name.length > 60) {
        return true;
      }
    },
    subStringProjectName(name) {
      return truncateText(name, 60);
    },
  },
});
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  /* Hide tooltip by default */
  background-color: white;
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  /* Shadow effect */

  /* Positioning */
  /* position: absolute; */
  z-index: 1;
  margin-top: -30px;

  /* Fade-in effect */
  opacity: 0;
  transition: opacity 0.6s;
}

/* Tooltip arrow */
.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 42px;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-top: -52px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  rotate: 180deg;

  border-color: white transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
