<script lang="ts">
import {defineComponent} from 'vue'
import ProjectHoursComponent from "@/components/dashboard/profile/timesheet/ProjectHoursComponent.vue";

export default defineComponent({
  name: "ProjectHours",
  components: {ProjectHoursComponent}
})
</script>

<template>
  <project-hours-component></project-hours-component>
</template>

<style scoped>

</style>