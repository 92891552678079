<template>
  <v-sheet class="bg-transparent">
    <div class="d-flex flex-column justify-center align-center mt-16">
      <div>
        <EmptyState />
      </div>
      <h3 class="H500 mt-5">
        {{ $t("employees.no") }} {{ pageName }} {{ $t("employees.found") }}
      </h3>
      <p class="P300">
        {{ $t("employees.youWillSee") }} {{ messageType }}
        {{ $t("employees.hereWhen") }}
      </p>
    </div>
  </v-sheet>
</template>

<script lang="js">
import {defineComponent} from "vue"
import EmptyState from '@/assets/icons/EmptyState.vue'
export default defineComponent( {
    name: 'No Available Data',
    props: {
   pageName: {
      type: String,
    },
    messageType: {
      type: String,
    },
      },
    components: {
        EmptyState
    }
})
</script>
