<template>
  <app-alert
      :type="alertType"
      :state="alertState"
      :timeout="5000"
      @close-alert="alertState = false"
      :title="alertTitle"
  ></app-alert>
  <section>
    <app-signature-dialog
      ref="signatureDialog"
      width="75%"
      :title="$t('Invoice Settlement'.getTranslationKey())"
    >
      <template #content>
        <div class="my-5 text-[14px] text-[#101828] font-['inter']">
          <div>
            <div>
              <div class="flex mb-2">
                <p class="mr-2 font-bold">
                  {{ $t("Title".getTranslationKey()) }}
                </p>
                <p>:</p>
                <p class="ml-2">
                  {{ settlementData.mission__project__project_title ? settlementData.mission__project__project_title : "N/A" }}
                </p>
              </div>
              <div class="flex">
                <p class="mr-2 font-bold">
                  {{ $t("Location".getTranslationKey()) }}
                </p>
                <p>:</p>
                <p class="ml-2">
                  {{
                    settlementData.mission__assignment_place ? settlementData.mission__assignment_place : "N/A"
                  }}
                </p>
              </div>
              <div class="flex mt-2">
                <p class="mr-2 font-bold">
                  {{ $t("Period".getTranslationKey()) }}
                </p>
                <p>:</p>
                <p class="ml-2">
                  {{
                    `${formattedDate(
                      settlementData.mission__accounting_period_start_date
                    )} - ${formattedDate(
                      settlementData.mission__accounting_period_end_date
                    )}`
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <app-custom-table
              :headers="headers"
              :items="settlementTableData"
              title="Generate Invoice"
              :column-widths="{title: '20%', number: '10%', amount: '12%', total: '13%', retention_rate: '15%', retention_amount: '15%', account_number: '15%'}"
            >
              <template v-for="headerSlot in headerSlotNames" v-slot:[headerSlot]="{item}" :key="headerSlot">
                <div class="text-right">
                  <span>{{item}}</span>
                </div>
              </template>
              <template #requested="{item}">
                <div class="text-right">
                  <input
                      v-if="type === 'generate' && item.title !== 'Amount To Be Paid'"
                      type="number"
                      name="" id=""
                      v-model="settlementTableData[item.index].number"
                      @input="calculateTotal(settlementTableData[item.index].number, item.index)"
                      @keydown="checkNumberInput"
                      min="0"
                      class="h-10 w-[100px] focus:outline-1 focus:outline-[#FDC6C2] px-2 text-right">
                  <span v-else>{{item.requested}}</span>
                </div>
              </template>
              <template v-for="slotName in ['title', 'amount', 'total', 'retention_amount', 'retention_rate', 'account_number']" v-slot:[slotName]="{item}" :key="slotName">
                <span v-if="slotName === 'title'" :class="item.title === 'Amount To Be Paid' && 'font-bold'">{{item.title}}</span>
                <div v-else class="text-right">
                  <span
                      v-if="slotName === 'total' || (slotName === 'amount' && item.title !== 'Amount To Be Paid')">
                    {{appLang === 'de' ? Number(item[slotName]).germanCurrencyFormatter() : Number(item[slotName]).formatCurrency()}}
                  </span>
                  <span v-else-if="slotName === 'retention_rate' && item.title !== 'Amount To Be Paid'">{{item[slotName] ? `${item[slotName]}%` : '0%'}}</span>
                  <span v-else-if="slotName === 'retention_amount' && item.title !== 'Amount To Be Paid'">{{item[slotName] ? item[slotName] : 0}}</span>
                  <span v-else>{{item[slotName] ? item[slotName] : ''}}</span>
                </div>
              </template>
            </app-custom-table>
          </div>
          <div class="mt-8">
            <v-row>
              <v-col cols="5" class="py-1">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("Account Holder".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        settlementData.mission__new_expert__account_owner
                          ? settlementData.mission__new_expert__account_owner
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("Bank".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{
                        settlementData.mission__new_expert__bank_name
                          ? settlementData.mission__new_expert__bank_name
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("City of Bank".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        settlementData.mission__new_expert__city_of_bank
                          ? settlementData.mission__new_expert__city_of_bank
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("BIC/SWIFT".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{
                        settlementData.mission__new_expert__swift_code
                          ? settlementData.mission__new_expert__swift_code
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("Account Number".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        settlementData.mission__new_expert__account_number
                          ? settlementData.mission__new_expert__account_number
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("IBAN".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{ settlementData.mission__new_expert__iban ? settlementData.mission__new_expert__iban : "N/A" }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex gap-5">
              <app-upload-signature
                  v-if="type === 'generate'"
                  :signee="currentUser"
                  position="Requester"
                  ref="requesterSignature"
              ></app-upload-signature>
            <div v-else v-for="signer in signers" :key="signer.name" class="w-[20%]">
              <app-upload-signature
                  :is-signed="!!signer.signature"
                  :date-signed="signer.signed_on"
                  :signed-url="signer.signature"
                  :signee="signer.name"
                  :position="signer.position"
              ></app-upload-signature>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <v-row v-if="type === 'generate'">
          <v-col cols="12" class="justify-end my-2 d-flex">
            <app-secondary-button
              :buttonLabel="$t('hospitalityPackage.cancel')"
              @click="hideDialog()"
            >
            </app-secondary-button>
            <app-primary-button
              :buttonLabel="$t('Generate Invoice'.getTranslationKey())"
              class="mx-6"
              @click="generateInvoice()"
              :loading="loading"
            >
            </app-primary-button>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="justify-end my-2 d-flex">
            <app-primary-button
                :buttonLabel="$t('hospitalityPackage.close')"
              class="mx-6"
              @click="hideDialog()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-signature-dialog>
  </section>
</template>
<script>
import {checkNumberInput, formatDate, getTransString} from "@/services/helpers";
import {defineComponent, ref} from "vue";
import {createExpertMissionInvoice, getInvoiceDetailsForSettlement} from "@/services/endpoints";
import Api from "@/services/api";
import {format} from "date-fns";
import {getAllExpertMissionPlanningInvoices} from "@/services/missionsApi";

export default defineComponent({
  name: "SignatureForm",
  components: {},
  data() {
    return {
      headers: [
        {
          key: "title",
          label: "Service Provided",
        },
        {
          key: "requested",
          label: "Number",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "total",
          label: "Total",
        },
        {
          key: "retention_rate",
          label: "Retention Rate",
        },
        {
          key: "retention_amount",
          label: "Retention Amt",
        },
        {
          key: "account_number",
          label: "Account No",
        }
      ],
      settlementTableData: [],
      settlement_type: "",
      alertState: false,
      alertTitle: "",
      alertType: "success",
      loading: false,
      type: '',
      signers: []
    };
  },
  computed: {
    getFullName() {
      return (firstName, lastName) => {
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        }
        if (firstName && !lastName) {
          return firstName;
        }
        if (!firstName && lastName) {
          return lastName;
        }
        return "N/A";
      }
    },
    headerSlotNames() {
      return this.headers.filter(header => header.key !== 'title').map((header) => `header-${header.key}`);
    },
    isEditMissionId() {
      return this.$store.getters["contractModules/isEditMissionId"];
    },
    projectId() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
    settlementData() {
      if (this.type === "view") {
        return this.$store.getters["projectModules/getSettlementDataForViewing"];
      }
      return this.$store.getters["projectModules/getSettlementData"];
    },
    signatureImageUrl() {
      return this.$store.getters["commonResources/signatureImageUrl"];
    },
    signatureDate() {
      return this.$store.getters["commonResources/signatureDate"];
    },
    currentUser() {
      return this.$store.getters["auth/isfullName"];
    },
    isSignedEvent() {
      return this.$store.getters["commonResources/getIsSignedEvent"];
    }
  },
  methods: {
    checkNumberInput,
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDialog() {
      this.signers = [];
      this.$refs.signatureDialog.showDialog = false;
    },
    showSignatureDialog(settlement_type, type) {
      this.settlement_type = settlement_type;
      this.type = type;
      this.settlementTableData = this.settlementData.request_details;
      this.$refs.signatureDialog.showDialog = true;
    },
    formattedDate(date, lang) {
      lang = lang ? lang : this.appLang;
      return formatDate(date?.trim()?.dateToYYYYMMDD(false), lang);
    },
    calculateTotal(number, index) {
      let total = 0;
      this.settlementTableData[index].total = this.settlementTableData[index].amount * number;
      this.settlementTableData[index].retention_amount = this.settlementTableData[index].total * this.settlementTableData[index].retention_rate / 100;
      this.settlementTableData.forEach((item) => {
        if(item.title !== "Amount To Be Paid") {
          total += Number(item.total);
        }
      });
      this.settlementTableData[this.settlementTableData.length - 1].total = total;
    },
    generateInvoice() {
      if(!this.$refs.requesterSignature.signed) {
        this.alertType = "error";
        this.alertState = true;
        this.alertTitle = "Please upload requester signature";
        return;
      }

      this.loading = true;

      const payload = {
        settlement_type: this.settlement_type,
        requester_signature: this.signatureImageUrl,
        amount_to_be_paid: this.settlementTableData[this.settlementTableData.length - 1].total,
        requester_signed_on: this.signatureDate.split("/").reverse().join("-"),
        invoicing_data: this.settlementTableData.filter((item) => item.number !== 0),
        category: `${this.settlement_type} invoice`,
        mission_id: this.isEditMissionId,
        project_id: this.projectId
      }
      Api()
          .post(`${createExpertMissionInvoice}${this.isEditMissionId}/`, payload)
          .then((response) => {
            if (response.status === 200) {
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response.data.detail || "Invoice Generated Successfully";

              getAllExpertMissionPlanningInvoices(this.isEditMissionId).then((data) => {
                this.$store.dispatch("projectModules/formatInvoiceData", data)
                this.$store.commit("commonResources/SET_SIGNED_EVENT_TO_STATE", false)
                this.hideDialog();
              });
            }
            else {
              this.alertType = "error";
              this.alertState = true;
              this.alertTitle = "Cannot generate invoice now, try again later";
            }
            this.$store.commit("commonResources/SET_SIGNATURE_IMAGE_URL_TO_STATE", "");
            this.$store.commit("commonResources/SET_SIGNATURE_DATE_TO_STATE", "");
          })
          .catch((error) => {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error.response.data.detail[0] || "Something went wrong";
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
  created() {},
  mounted() {},
  watch: {
    settlementData(val) {
      ['Requester', 'pm', 'CEO', 'Controller'].forEach(name => {
        if (val[`is_${name.toLowerCase()}_signed`]) {
          this.signers.push({
            name: name === 'pm' ?
                this.getFullName(val.project_manager__first_name, val.project_manager__last_name) :
                this.getFullName(val[`${name.toLowerCase()}__first_name`], val[`${name.toLowerCase()}__last_name`]),
            signature: val[`${name.toLowerCase()}_signature`],
            signed_on: val[`${name.toLowerCase()}_signed_on`],
            position: name === 'pm' ? 'Project Manager' : name
          })
        }
      })
    },
  },
});
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
