<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.833252 7.9987C0.833252 7.9987 4.16658 1.33203 9.99992 1.33203C15.8333 1.33203 19.1666 7.9987 19.1666 7.9987C19.1666 7.9987 15.8333 14.6654 9.99992 14.6654C4.16658 14.6654 0.833252 7.9987 0.833252 7.9987Z"
      stroke="#667085"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99992 10.4987C11.3806 10.4987 12.4999 9.37941 12.4999 7.9987C12.4999 6.61799 11.3806 5.4987 9.99992 5.4987C8.61921 5.4987 7.49992 6.61799 7.49992 7.9987C7.49992 9.37941 8.61921 10.4987 9.99992 10.4987Z"
      stroke="#667085"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 15px;
}
</style>
