<template>
  <div class="filter_parent SPC-MT-100">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchPublicHolidays"
          :callback="true"
        />
      </v-col>
    </v-row>
    <div class="SPC-MT-400">
      <app-table
        :showCursor="false"
        :showEditPage="false"
        :headers="tableHeaders"
        :items="publicHolidays"
        :showCheck="false"
        :hideMenu="!canEdit"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDeleteDialog({ $event, data: 'delete' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("hrconfiguration.allPublicHolidays") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            :buttonLabel="$t('hrconfiguration.addPublicHolidays')"
            @click="showDialog({ data: 'add' })"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <!-- dialog for adding and deleting config types -->
    <app-dialog
      ref="dialog"
      :title="
        combineTranslation($t(title), $t('hrconfiguration.publicHoliday'))
      "
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="combineTranslation($t('hrconfiguration.publicHoliday'), '*')"
          class-string="public-holiday"
          v-model.trim="addTitle"
        >
        </app-input-field>
        <app-date-field
          class="w5"
          :label="$t('experience.startDate')"
          type="date"
          v-model="start_date"
          id="start-date"
          name="start-date"
        ></app-date-field>
        <app-date-field
          class="w50"
          :label="combineTranslation($t('experience.endDate'), '*')"
          type="date"
          v-model="end_date"
          :min="start_date"
          id="end-date"
          name="end-date"
        ></app-date-field>
      </template>
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
              :buttonLabel="
                isEdit ? $t('generalConfig.update') : $t('generalConfig.add')
              "
              @click-event="
                isEdit ? updatePublicHolidays() : addToPublicHolidays()
              "
              :disabled="
                addTitle === '' || start_date === '' || end_date === ''
              "
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for deleting config types -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deletePublicHoliday()"
            >
              {{ $t("accessrole.delete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(this.getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import countries from "@/services/country_api";
import { searchPublicHolidays, publicHolidays } from "@/services/endpoints";
import { getTransString, monthNames } from "@/services/helpers";

export default defineComponent({
  name: "Public Holidays",
  components: {
    Paginator,
  },
  data() {
    return {
      countryOptions: null,
      tableHeaders: ["Public Holiday", "Date"],
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      buttonLabel: "Add",
      label: "Title",
      currentPublicHoliday: {},
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      end_date: "",
      start_date: "",
      addTitle: "",
      modelValue: "",
      items: null,
      loading: false,
      currentPages: 1,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",
    };
  },
  watch: {},
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Time Off"
      );
      return findModules?.length !== 0;
    },
    publicHolidays() {
      return this.$store.getters["hrResources/isPublicHolidays"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
  },

  methods: {
    setResponseData(response) {
      const payload = response.data.reduce((prev, curr) => {
        const startDate = new Date(curr.start_date);
        const endDate = new Date(curr.end_date);

        const date = `${
          monthNames[startDate.getMonth()]
        } ${startDate.getDate()} - ${
          monthNames[endDate.getMonth()]
        } ${endDate.getDate()}`;
        return [
          ...prev,
          {
            "public holiday": curr.title,
            date,
            start_date: startDate.toISOString().slice(0, 10),
            end_date: endDate.toISOString().slice(0, 10),
            id: curr.id,
          },
        ];
      }, []);

      this.$store.commit("hrResources/SET_PUBLIC_HOLIDAYS_TO_STATE", payload);
      this.$store.commit(
        "hrResources/SET_CURRENT_PAGE_TO_STATE",
        response.current_page
      );
      this.$store.commit(
        "hrResources/SET_TOTAL_PAGES_TO_STATE",
        response.total_data
      );
    },
    searchPublicHolidays(search_query) {
      Api()
        .post(`${searchPublicHolidays}${this.currentPage}/`, { search_query })
        .then((response) => {
          if (response.data.status === "success") {
            // this.headers = response.data.headers;
            this.setResponseData(response.data);
          }
        });
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    combineTranslation(...args) {
      return args.reduce((acc, curr) => acc + " " + curr, "");
    },
    getAlertMessage(configType, alertKeyTextTrans, alertText) {
      if (this.alertType === "success") {
        return this.combineTranslation(configType, alertKeyTextTrans);
      } else {
        return alertText;
      }
    },
    showDialog(data) {
      this.isEdit = false;
      this.jobID = data?.$event?.value;
      this.modelValue = data?.$event?.title;
      if (data.data === "add") {
        this.title = "generalConfig.add";
        this.$refs.dialog.showDialog = true;
      } else if (data.data === "edit") {
        this.isEdit = true;
        this.buttonLabel = "generalConfig.update";
        this.label = "generalConfig.edit";
        this.addTitle = data.$event["public holiday"];
        this.start_date = data.$event["start_date"];
        this.end_date = data.$event["end_date"];
        this.currentPublicHoliday = data.$event;
        this.title = "generalConfig.update";
        this.$refs.dialog.showDialog = true;
      }
    },
    showDeleteDialog(data) {
      if (data.data === "delete") {
        this.currentPublicHoliday = data?.$event;
        this.isDelete = true;
        this.cancelLabel = "generalConfig.cancelLabel";
        this.title = "hrconfiguration.deletePublicHoliday";
        this.subTitle = "hrconfiguration.publicHolidaySubtitle";
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      setTimeout(() => {
        this.isDelete = false;
        this.confirmDelete = null;
        this.subTitle = "";
      }, 600);
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.addTitle = "";
        this.isEdit = false;
        this.isAdd = false;
        this.isDelete = false;
        this.subTitle = "";
        this.buttonLabel = "generalConfig.add";
        this.label = "generalConfig.title";
        this.cancelLabel = "generalConfig.cancel";
      }, 600);
    },
    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllPublicHolidays(page);
    },
    addToPublicHolidays() {
      const payload = {
        title: this.addTitle,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      Api()
        .post("/configuration/public-holiday/add-public-holiday/", payload)
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "Public holiday added successfully";
            this.getAllPublicHolidays(this.currentPage);
            this.end_date = null;
            this.start_date = null;
            this.addTitle = "";
            this.$refs.dialog.showDialog = false;
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updatePublicHolidays() {
      const payload = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      const currentPublicHoliday = this.publicHolidays.find(
        (elt) => elt.id === this.currentPublicHoliday.id
      );
      if (currentPublicHoliday["public holiday"] !== this.addTitle) {
        payload.title = this.addTitle;
      }
      Api()
        .patch(
          `/configuration/public-holiday/update-public-holiday/${this.currentPublicHoliday.id}/`,
          payload
        )
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "Public holiday updated successfully";
            this.getAllPublicHolidays(this.currentPage);
            this.end_date = null;
            this.start_date = null;
            this.currentPublicHoliday = {};
            this.addTitle = "";
            this.$refs.dialog.showDialog = false;
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deletePublicHoliday() {
      const payload = {
        title: this.addTitle,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      Api()
        .delete(
          `/configuration/public-holiday/delete-public-holiday/${this.currentPublicHoliday.id}/`,
          payload
        )
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "Public holiday deleted successfully";
            this.getAllPublicHolidays(this.currentPage);
            this.end_date = null;
            this.start_date = null;
            this.currentPublicHoliday = {};
            this.addTitle = "";
            this.hideDeleteDialog();
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllPublicHolidays(page) {
      Api()
        .get(`${publicHolidays}${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            // this.headers = response.data.headers;
            this.setResponseData(response.data);
          }
        });
    },
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllPublicHolidays(page);
    },
  },
  created() {
    this.getAllPublicHolidays(this.currentPage);
    this.countryOptions = this.getCountryByName();
  },
});
</script>

<style scoped>
label {
  color: #344054;
}
</style>
