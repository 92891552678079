<template>
  <div class="w-full mt-4">
    <div
      class="relative overflow-hidden bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg"
    >
      <div
        class="tablecard flex flex-col items-center justify-between p-4 px-6 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
      >
      <p class="P300" v-if="allCurrencies.length===0">No currencies</p>
        <div class="w-full py-2 text-l font-medium flex items-center">
          <div v-for="(item , index) in allCurrencies" :key="index" class="flex items-center pr-10 SPC-MT-100">
            <input
              class="cursor-pointer"
              :class="{ '!cursor-default': shouldDisableInput }"
              type="radio"
              name="flexRadioDefault"
              id="radioDefault01"
              v-model="selectedOption"
              :value="item?.id"
              :checked="index === 0" 
            />
            <label
              class="H400"
              for="radioDefault01"
              :class="{ '!cursor-default': shouldDisableInput }"
            >
              {{item?.currency }}
            </label>
          </div>
      
          <div class="flex items-center justify-between"></div>
        </div>
      </div>
      <div class="rounded-lg">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
          aria-describedby=""
        >
          <thead class="bg-white">
            <tr class="bg-gray-200">
              <th class="p-2 text-base !text-[#344054] !font-medium">{{ $t('currencyExchange.title') }}</th>
              <th class=" py-2 text-base !text-[#344054] !font-medium">
                {{ $t('currencyExchange.date') }}
              </th>
              <th class=" py-2 text-base !text-[#344054] !font-medium">
                {{ $t('currencyExchange.amtIn') }} {{selectedCurrencyName}}
              </th>
              <th class=" py-2 text-base !text-[#344054] !font-medium">
                {{ $t('currencyExchange.amtIn') }} EUR
              </th>
              <th class=" py-2 text-base !text-[#344054] !font-medium">
                {{selectedCurrencyName}}/EUR {{ $t('currencyExchange.rate') }}
              </th>
              <th class=" py-2 text-base !text-[#344054] !font-medium">
                EUR/{{selectedCurrencyName}} {{ $t('currencyExchange.rate') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in isSingleExchangeRate" :key="item.title" class="">
              <td class="H400">{{ item.title }}</td>
              <td class="H400">{{ item.date?formattedDate(item.date):"-"}}</td>
              <td class="H400">{{getLanguageCurrency(item.local_currency_amount?item.local_currency_amount:0)}}</td>
              <td class="H400">{{getLanguageCurrency(item.eur_amount?item.eur_amount:0)}}</td>
              <td class="H400">{{ item.local_currency_rate}}</td>
              <td class="H400">{{ item.eur_rate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="empty_state" v-if="isSingleExchangeRate.length === 0">
        <EmptyState />
        <h3 class="H500">
          {{ $t("employees.no") }} {{ pagename }} {{ $t("employees.found") }}
        </h3>
        <p class="P300">
          {{ $t("employees.youWillSee") }} {{ message }}
          {{ $t("employees.hereWhen") }}
        </p>
      </div>
    </div>
  </div>
  <add-edit-client-rate ref="addEditClientRate"></add-edit-client-rate>
</template>

<script>
import { defineComponent } from "vue";
// import EmptyState from "@/assets/icons/EmptyState.vue";
import { currencyFormatter, formatAmount,formatDate } from "@/services/helpers";
import AddEditClientRate from "@/components/dashboard/projects/pm-local/dialogs/ViewExchange.vue";
import { mapGetters, mapState } from 'vuex';
import { getSingleClientRate } from "@/services/endpoints";
import Api from "@/services/api";
export default defineComponent({
  components: { AddEditClientRate,  },
  data() {
    return {
      selectedOption:null,
    
    };
  },
  watch: {
    selectedOption(val) {
      this.$store.commit("pmLocalModules/SET_CURRENCY_EXCHANGE_ID_TO_STATE", val);
    },
  },
  computed: {
    ...mapGetters("translation", ["getLocale"]),
  ...mapState(["translation"]),

  appLang() {
    return this.$store.getters["translation/getLocale"];
  },
    isExchangeCurrency() {
    return this.$store.getters["pmLocalModules/isExchangeCurrency"];
  },
allCurrencies() {
const currencies = this.$store.getters["pmLocalModules/allCurrencies"];
const filteredCurrencies = currencies.filter(currency => currency.currency !== "EUR");
return filteredCurrencies;
},



  isSingleExchangeRate() {
    return this.$store.getters["pmLocalModules/isSingleExchangeRate"];
  },
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
    selectedCurrencyName() {
  const selectedCurrencyId = this.selectedOption;
  const currency = this.allCurrencies?.find(item => item?.id === selectedCurrencyId);
  return currency ? currency?.currency : '';
},
  },
  methods: {
    getLanguageCurrency(amount) {
    return this.appLang === "de" ? this.germanCurrency(amount) : amount?.formatCurrency()
  },

  germanCurrency(curr) {
    return currencyFormatter(curr);
  },
  amountFormatting(amt) {
    return formatAmount(amt);
  },
  formattedDate(date) {
    return formatDate(date);
  },
    formatMonth(month) {
      return month?.substring(0, 3);
    },
    showDialog(month, data, items) {
      // console.log(month, data, items)
      const payload = {
        year: items.year,
        month: data.month,
      };
      this.getSingleClientRate(payload);
      // this.$store.dispatch('pmLocalModules/getSingleClientRate', payload)
      this.$refs.addEditClientRate.clientRateForm.year = items.year;
      this.$refs.addEditClientRate.clientRateForm.month = data.month;
      this.$refs.addEditClientRate.clientRateForm.pm_local = data.pmLocalID;
      this.$refs.addEditClientRate.month = month;
      this.$refs.addEditClientRate.showDialog();
    },
    getSingleClientRate(payload) {
      Api()
        .get(
          `${getSingleClientRate}${this.pmLocalID}/${payload.year}/${payload.month}`
        )
        .then((response) => {
          if (response.data.status === 1) {
            const responseData = response.data.data;

            this.$refs.addEditClientRate.allCurrencyRates = {
              year: responseData.year,
              month: responseData.month,
              rate_data: responseData.client_rates
            };
            this.$store.commit(
              "SET_SINGLE_CLIENT_RATE_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.$store.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
  mounted() {
    this.selectedOption=this.allCurrencies[0]?.id;
    this.$store.commit("pmLocalModules/SET_CURRENCY_EXCHANGE_ID_TO_STATE",this.selectedOption);

  },
});
</script>

<style scoped>
table {
  color: #344054;
  border-radius: 16px;
}
tr > th {
  background-color: #f2f4f7;
}
tbody tr:nth-of-type(even) {
  background-color: #f9fafb;
}
td {
  padding-left: 16px;
}
tr {
  padding: 16px 24px;
  height: 36px;
  width: 100%;
}
.tablecard {
  border: 1px solid #eaecf0;
}

/* unselected radio button */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 0.5rem;
  position: relative;
}

/* middle fill color */
input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* selected radio button */
input[type="radio"]:checked::before {
  background-color: #8b2923;
  border: 2px solid #8b2923;
  width: 8px;
  height: 8px;
}
input[type="radio"]:checked {
  background-color: #f4eae9;
  border: 1px solid #ae6a65;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  outline: none;

  position: relative;
}
</style>


