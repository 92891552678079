import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import Api from "@/services/api";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import moment from "moment";
import {
  approverLeaveApproval,
  hrLeaveApproval,
  getSingleLeave,
  hrLeaveRequest,
  publicHolidays,
} from "@/services/endpoints";
import { moduleNames } from "@/services/permissionlevels";

export const setupLogic = () => {
  let alertState = ref(false);
  let alertTitle = ref("");
  let alertType = ref("success");
  const currentMonth = ref(new Date());
  const weeksInMonth = ref([]);
  let currentMonthName = ref("");
  const { t } = useI18n();
  const inputValues = ref([]);
  const inputs = ref([]);
  const inputs1 = ref([]);
  const inputs2 = ref([]);
  const inputs3 = ref([]);
  const inputs4 = ref([]);
  const inputs5 = ref([]);
  const inputs6 = ref([]);
  const showCardIndex = ref(-1);
  const showCardIndex2 = ref(-1);
  const showCardIndex3 = ref(-1);
  const showCardIndex4 = ref(-1);
  const showCardIndex5 = ref(-1);
  const showCardIndex6 = ref(-1);
  const showCardIndex7 = ref(-1);
  const showCardIndex8 = ref(-1);
  const inputValuesByMonth = ref({});
  const inputsByMonth = ref({});
  const inputsByMonth1 = ref({});
  const inputsByMonth2 = ref({});
  const inputsByMonth3 = ref({});
  const inputsByMonth4 = ref({});
  const inputsByMonth5 = ref({});
  const inputsByMonth6 = ref({});
  let annualLeaveDays = ref(0);
  let specialLeaveDays = ref(0);
  let sickLeaveDays = ref(0);
  let sickHoLeaveDays = ref(0);
  let childsickLeaveDays = ref(0);
  let kCareLeaveDays = ref(0);
  let unpaidLeaveDays = ref(0);
  let unexcusedLeaveDays = ref(0);
  let leave_stats = ref(undefined);

  let start_date1,
    end_date1,
    description1,
    start_date2,
    end_date2,
    description2;
  let start_date3,
    end_date3,
    description3,
    start_date4,
    end_date4,
    description4;
  let start_date5,
    end_date5,
    description5,
    start_date6,
    end_date6,
    description6;
  let start_date7,
    end_date7,
    description7,
    start_date8,
    end_date8,
    description8;
  const cardValues = [
    { value: "0.5", label: t("calender.firsthalf") },
    { value: "0.5", label: t("calender.secondhalf") },
    { value: "1", label: t("calender.fullday") },
    { value: "", label: t("calender.remove") },
  ];
  const currentYearAndMonth = computed(() =>
    format(currentMonth.value, "yyyy")
  );
  const currentYearAndMonth1 = computed(() =>
    format(currentMonth.value, "MMMM yyyy")
  );
  const store = useStore();
  const isLeaveId = computed(() => {
    return store.getters["hrResources/isLeaveId"];
  });
  const isStatus = computed(() => {
    return store.getters["hrResources/isStatus"];
  });
  const ishr = computed(() => {
    return store.getters["auth/ishr"];
  });
  const isMonth = computed(() => {
    return store.getters["hrResources/isMonth"];
  });
  const isYear = computed(() => {
    return store.getters["hrResources/isYear"];
  });
  const yearHome = computed(() => {
    return store.getters["hrResources/isYearHome"];
  });
  const monthHome = computed(() => {
    return store.getters["hrResources/isMonthHome"];
  });
  const isRequestedPerson = computed(() => {
    return store.getters["hrResources/isRequestedPerson"];
  });
  const isRequestedHome = computed(() => {
    return store.getters["hrResources/isRequestedHome"];
  });
  const moduleNamesRef = ref(moduleNames);
  const isLeaveType = computed(() => {
    return store.getters["hrResources/isLeaveType"];
  });
  const nextMonth = () => {
    getAllLeaves();
    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() + 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    updateCalendar();
  };

  const previousMonth = () => {
    getAllLeaves();
    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() - 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    updateCalendar();
  };
  // Add the following function to your setup()

  const updateCalendar = () => {
    const start = startOfMonth(currentMonth.value);
    const end = endOfMonth(currentMonth.value);
    const dates = eachDayOfInterval({ start, end }).map((date) => {
      return {
        date: format(date, "d"),
        dayOfWeek: format(date, "E"),
      };
    });

    const calendarWeeks = [];
    while (dates.length) {
      const week = dates.splice(0, 7);
      calendarWeeks.push(week);
    }

    weeksInMonth.value = calendarWeeks;

    // Retrieve input values for the current month
    inputValues.value =
      inputValuesByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs.value =
      inputsByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs1.value =
      inputsByMonth1.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs2.value =
      inputsByMonth2.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs3.value =
      inputsByMonth3.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs4.value =
      inputsByMonth4.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs5.value =
      inputsByMonth5.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs6.value =
      inputsByMonth6.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
  };

  updateCalendar();

  const days = computed(() => {
    const daysWithValues = [];
    const firstWeek = weeksInMonth.value[0];

    for (let i = 0; i < firstWeek.length; i++) {
      if (firstWeek[i].date) {
        const day = firstWeek[i].dayOfWeek;
        daysWithValues.push(day.substring(0, 2));
      }
    }

    return daysWithValues;
  });

  const weeks = computed(() => {
    const filteredWeeks = weeksInMonth.value.map((week) => {
      return week.filter((day) => day.date !== "");
    });

    return filteredWeeks;
  });

  const getIndex = (weekIndex, dateIndex) => {
    const totalDays = weeksInMonth.value.flat().length;
    return weekIndex * 7 + dateIndex >= totalDays
      ? totalDays - 1
      : weekIndex * 7 + dateIndex;
  };
  const bindInputWithDate = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputValues.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };

  const bindInputWithDate2 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate3 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs1.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate4 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs2.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate5 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs3.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate6 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs4.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate7 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs5.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate8 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs6.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const getValidDays = (week) => {
    const validDays = [];
    const dateRow = week.filter((date) => date.date);
    // const totalDates = dateRow.length;

    for (let i = 0; i < week.length; i++) {
      if (dateRow[i]) {
        validDays.push(week[i].dayOfWeek.substring(0, 2));
      } else {
        validDays.push("");
      }
    }

    return validDays;
  };
  const selectedDates1 = []; // Array to store selected dates for input row 1
  const selectedDates2 = []; // Array to store selected dates for input row 2
  const selectedDates3 = []; // Array to store selected dates for input row 3
  const selectedDates4 = []; // Array to store selected dates for input row 4
  const selectedDates5 = []; // Array to store selected dates for input row 5
  const selectedDates6 = []; // Array to store selected dates for input row 6
  const selectedDates7 = []; // Array to store selected dates for input row 7
  const selectedDates8 = []; // Array to store selected dates for input row 8

  // Card methods for the first input row
  const totalHours1 = ref(0);
  const totalHours2 = ref(0);
  const totalHours3 = ref(0);
  const totalHours4 = ref(0);
  const totalHours5 = ref(0);
  const totalHours6 = ref(0);
  const totalHours7 = ref(0);
  const totalHours8 = ref(0);
  const showCard = (weekIndex, dateIndex) => {
    showCardIndex.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate(weekIndex, dateIndex, 1);
    description1 = "Annual Leave";
  };

  const selectCardValue = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputValues.value[index] = value;
    showCardIndex.value = -1;
    const { date, time } = bindInputWithDate(weekIndex, dateIndex);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;

    const dateTime = `${date} ${time}`;
    selectedDates1.push(dateTime); // Push the combined date and time to the array for input row 2

    totalHours1.value = inputValues.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const parsedDates = selectedDates1.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date1 = new Date(Math.min(...parsedDates));
    end_date1 = new Date(Math.max(...parsedDates));
  };

  // Card methods for the second input row
  const showCard2 = (weekIndex, dateIndex) => {
    showCardIndex2.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description2 = "Special Leave";
  };

  const selectCardValue2 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs.value[index] = value;
    showCardIndex2.value = -1;
    const { date, time } = bindInputWithDate2(weekIndex, dateIndex);
    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates2.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours2.value = inputs.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates2.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date2 = new Date(Math.min(...parsedDates));
    end_date2 = new Date(Math.max(...parsedDates));
  };
  const showCard3 = (weekIndex, dateIndex) => {
    showCardIndex3.value = getIndex(weekIndex, dateIndex);
    description3 = "Sick Leave";
  };
  const selectCardValue3 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs1.value[index] = value;
    showCardIndex3.value = -1;
    const { date, time } = bindInputWithDate3(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates3.push(dateTime); // Push the combined date and time to the array for input row 2
    totalHours3.value = inputs.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates3.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date3 = new Date(Math.min(...parsedDates));
    end_date3 = new Date(Math.max(...parsedDates));
  };

  const showCard4 = (weekIndex, dateIndex) => {
    showCardIndex4.value = getIndex(weekIndex, dateIndex);
    description4 = "Sick Leave HO";
  };
  const selectCardValue4 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs2.value[index] = value;
    showCardIndex4.value = -1;
    const { date, time } = bindInputWithDate4(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates4.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours4.value = inputs2.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates4.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date4 = new Date(Math.min(...parsedDates));
    end_date4 = new Date(Math.max(...parsedDates));
  };

  const showCard5 = (weekIndex, dateIndex) => {
    showCardIndex5.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description5 = "Child Sick Leave";
  };
  const selectCardValue5 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs3.value[index] = value;
    showCardIndex5.value = -1;
    const { date, time } = bindInputWithDate5(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates5.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours5.value = inputs3.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates5.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date5 = new Date(Math.min(...parsedDates));
    end_date5 = new Date(Math.max(...parsedDates));
  };

  const showCard6 = (weekIndex, dateIndex) => {
    showCardIndex6.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description6 = "Child Care Leave";
  };
  const selectCardValue6 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs4.value[index] = value;
    showCardIndex6.value = -1;
    const { date, time } = bindInputWithDate6(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates6.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours6.value = inputs4.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates6.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date6 = new Date(Math.min(...parsedDates));
    end_date6 = new Date(Math.max(...parsedDates));
  };
  const showCard7 = (weekIndex, dateIndex) => {
    showCardIndex7.value = getIndex(weekIndex, dateIndex);

    description7 = "Child Care Leave";
  };
  const selectCardValue7 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs5.value[index] = value;
    showCardIndex7.value = -1;
    const { date, time } = bindInputWithDate7(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates7.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours7.value = inputs5.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates7.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date7 = new Date(Math.min(...parsedDates));
    end_date7 = new Date(Math.max(...parsedDates));
  };
  const showCard8 = (weekIndex, dateIndex) => {
    showCardIndex8.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description8 = "Unexcused Leave";
  };
  const selectCardValue8 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    inputs6.value[index] = value;
    showCardIndex8.value = -1;
    const { date, time } = bindInputWithDate8(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates8.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours8.value = inputs6.value.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates8.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date8 = new Date(Math.min(...parsedDates));
    end_date8 = new Date(Math.max(...parsedDates));
  };
  const rejectLeaveByHr = () => {
    const payload = {
      leave_id: isLeaveId.value,
      approved: false,
    };

    Api()
      .post(`${hrLeaveApproval}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          alertTitle.value = response.data.detail;
          emitClose(response.data);
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  const rejectLeaveByApprover = () => {
    const payload = {
      leave_id: isLeaveId.value,
      approved: false,
    };

    Api()
      .post(`${approverLeaveApproval}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          alertTitle.value = response.data.detail;
          emitClose(response.data);
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  const approveLeaveByHr = () => {
    const payload = {
      leave_id: isLeaveId.value,
      approved: true,
    };

    Api()
      .post(`${hrLeaveApproval}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  const approveLeaveByApprover = () => {
    const payload = {
      leave_id: isLeaveId.value,
      approved: true,
    };

    Api()
      .post(`${approverLeaveApproval}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  const addLeave = () => {
    const leaveDays1 = selectedDates1.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const leaveDays2 = selectedDates2.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays3 = selectedDates3.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays4 = selectedDates4.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays5 = selectedDates5.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays6 = selectedDates7.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays7 = selectedDates7.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays8 = selectedDates8.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const payload = {
      leaves: [
        {
          leave_type: description1,
          start_date: formatDate(start_date1),
          end_date: formatDate(end_date1),
          total_days: totalHours1.value,
          leave_days: leaveDays1 || null,
        },
        {
          leave_type: description2,
          start_date: formatDate(start_date2),
          end_date: formatDate(end_date2),
          total_days: totalHours2.value,
          leave_days: leaveDays2 || null,
        },
        {
          leave_type: description3,
          start_date: formatDate(start_date3),
          end_date: formatDate(end_date3),
          total_days: totalHours3.value,
          leave_days: leaveDays3 || null,
        },
        {
          leave_type: description4,
          start_date: formatDate(start_date4),
          end_date: formatDate(end_date4),
          total_days: totalHours4.value,
          leave_days: leaveDays4 || null,
        },
        {
          leave_type: description5,
          start_date: formatDate(start_date5),
          end_date: formatDate(end_date5),
          total_days: totalHours5.value,
          leave_days: leaveDays5 || null,
        },
        {
          leave_type: description6,
          start_date: formatDate(start_date6),
          end_date: formatDate(end_date6),
          total_days: totalHours6.value,
          leave_days: leaveDays6 || null,
        },
        {
          leave_type: description7,
          start_date: formatDate(start_date7),
          end_date: formatDate(end_date7),
          total_days: totalHours7.value,
          leave_days: leaveDays7 || null,
        },
        {
          leave_type: description8,
          start_date: formatDate(start_date8),
          end_date: formatDate(end_date8),
          total_days: totalHours8.value,
          leave_days: leaveDays8 || null,
        },
      ],
      //   user_key:isUserKey.value,
    };

    Api()
      .post(`${hrLeaveRequest}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      });
    // .catch((error) => {
    //   if (error) {
    //
    //   }
    // });
  };
  // get leaves
  // get leaves and processed them into the cells
  const LEAVE_TYPES = {
    ANNUAL: "Annual Leave",
    SPECIAL: "Special Leave",
    SICKLEAVE: "Sick Leave",
    SICKLEAVEHO: "Sick Leave HO",
    CHILDSICKLEAVE: "Child Sick Leave",
    KCARELEAVE: "Child Care Leave",
    UNPAIDLEAVE: "Unpaid Leave",
    UNEXECUSEDLEAVE: "Unexcused Leave",
  };

  const getAllLeaves = () => {
    Api()
      .get(`${getSingleLeave}${isLeaveId.value}/`)
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.data;
          leave_stats.value = data.leave_statistics;
          processLeaveDaysArray(data.leave_days);
        }
      });
  };

  const processLeaveDaysArray = (leaveDays) => {
    leaveDays.forEach((leaveDay) => {
      const date = moment(leaveDay.leave_day);
      const weekIndex = findWeekIndex(date);

      if (weekIndex !== -1) {
        const dateIndex = findDateIndex(weekIndex, date);

        if (dateIndex !== -1) {
          const index = getIndex(weekIndex, dateIndex);
          assignLeaveValue(index, leaveDay);
        }
      }
    });

    computeLeaveTotals();
  };

  const findWeekIndex = (date) => {
    return weeks.value.findIndex((week) =>
      week.some(
        (d) =>
          d.date &&
          moment([
            currentMonth.value.getFullYear(),
            currentMonth.value.getMonth(),
            d.date,
          ]).isSame(date, "day")
      )
    );
  };

  const findDateIndex = (weekIndex, date) => {
    return weeks.value[weekIndex].findIndex(
      (d) =>
        d.date &&
        moment([
          currentMonth.value.getFullYear(),
          currentMonth.value.getMonth(),
          d.date,
        ]).isSame(date, "day")
    );
  };

  const assignLeaveValue = (index, leaveDay) => {
    switch (isLeaveType.value) {
      case LEAVE_TYPES.ANNUAL:
        inputValues.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.SPECIAL:
        inputs.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.SICKLEAVE:
        inputs1.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.SICKLEAVEHO:
        inputs2.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.CHILDSICKLEAVE:
        inputs3.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.KCARELEAVE:
        inputs4.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.UNPAIDLEAVE:
        inputs5.value[index] = leaveDay.days;
        break;
      case LEAVE_TYPES.UNEXECUSEDLEAVE:
        inputs6.value[index] = leaveDay.days;

        break;
      default:
        // console.error('Unknown leave type:', leaveDay.leave_type);
        break;
    }
  };

  const computeLeaveTotals = () => {
    annualLeaveDays.value = sumDays(inputValues.value);
    specialLeaveDays.value = sumDays(inputs.value);
    sickLeaveDays.value = sumDays(inputs1.value);
    sickHoLeaveDays.value = sumDays(inputs2.value);
    childsickLeaveDays.value = sumDays(inputs3.value);
    kCareLeaveDays.value = sumDays(inputs4.value);
    unpaidLeaveDays.value = sumDays(inputs5.value);
    unexcusedLeaveDays.value = sumDays(inputs6.value);
  };
  const sumDays = (values) => {
    return values.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) ? total + parsedValue : total;
    }, 0);
  };

  // end of process

  onMounted(() => {
    const currentMonthIndex = new Date().getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);

    getMonthName();
    getAllLeaves();
    getAllPublicHolidays();
  });
  const { emit } = getCurrentInstance();

  const emitClose = (data) => {
    emit("close-event", data);
  };
  // const formatLeaveDays = (leaveDays) => {
  //   if (leaveDays === 0) {
  //     return "0";
  //   }

  //   const isNegative = leaveDays < 0;
  //   const absoluteDays = Math.abs(Math.floor(leaveDays));
  //   const absoluteHours = Math.floor((Math.abs(leaveDays) % 1) * 8);

  //   let formattedString = "";

  //   if (isNegative) {
  //     formattedString += "-";
  //   }

  //   if (absoluteDays > 0) {
  //     formattedString += `${absoluteDays} ${
  //       absoluteDays === 1 ? t("calender.day") : t("calender.days")
  //     }`;
  //   }

  //   if (absoluteHours > 0) {
  //     if (formattedString !== "") {
  //       formattedString += " ";
  //     }
  //     formattedString += `${absoluteHours} ${
  //       absoluteHours === 1 ? t("calender.hour") : t("calender.hours")
  //     }`;
  //   }

  //   return formattedString;
  // };

  const formatLeaveDays = (leaveDays) => {
    if (leaveDays === undefined) {
      return "";
    }
    // Calculate the absolute decimal value
    const absoluteValue = leaveDays;
  
    // Determine the sign
    const sign = leaveDays < 0 ? "-" : "";
  
    // Create the formatted string
    const formattedString = `${sign}${absoluteValue} ${
      absoluteValue === "1.0" ? t("calender.day") : t("calender.days")
    }`;
  
    return formattedString;
  };
  

  function getPreviousYear() {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return previousYear;
  }

  const previousYear = getPreviousYear();
  function formatDatecaryOver(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return date.toLocaleDateString(undefined, options);
    } else {
      return "N/A";
    }
  }

  // get holidays
  const holidays = ref([]);

  const getAllPublicHolidays = async () => {
    try {
      const response = await Api().get(`${publicHolidays}`);

      if (response.data && response.data.status === "success") {
        holidays.value = response.data.data;
      }
    } catch (error) {
      // Handle the error as needed
    }
  };
  function getMonthValue(currentMonth) {
    const date = new Date(currentMonth);
    const monthValue = date.getMonth() + 1; // Adding 1 to get month value from 1 to 12
    return monthValue;
  }
  function isHoliday(day) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth1 = getMonthValue(currentMonth.value); // Month starts from 0, so adding 1

    const formattedDate = `${currentYear}-${currentMonth1
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

    for (const holiday of holidays.value) {
      if (
        formattedDate >= holiday.start_date &&
        formattedDate <= holiday.end_date
      ) {
        return true; // The day is a holiday
      }
    }

    for (const holiday of holidays.value) {
      if (
        formattedDate >= holiday.start_date &&
        formattedDate <= holiday.end_date
      ) {
        return true; // The day is a holiday
      }
    }

    return false; // The day is not a holiday
  }
  const formattedMonth = ref("");
  const currentYear = ref("");
  function checkActiveMonth() {
    const currentDate = new Date();
    const currentMonthAndYearWithday = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    return currentMonthAndYearWithday === currentYearAndMonth1.value;
  }
  // swich to curent month of viewing
  const switchToMonth = (month, year) => {
    const monthIndex = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ].indexOf(month.toLowerCase());

    if (monthIndex !== -1) {
      const currentDate = new Date();
      currentDate.setFullYear(year); // Set the year
      currentDate.setMonth(monthIndex);

      currentMonth.value = currentDate;
      const currentMonthIndex = currentMonth.value.getMonth();
      const switchedMonthName = t(`calender.months.${currentMonthIndex}`);
      currentMonthName.value = switchedMonthName;

      updateCalendar();
    }
  };

  const getMonthName = () => {
    const dateObject = new Date(isMonth.value || monthHome.value);
    const monthName = dateObject.toLocaleString("default", { month: "long" });
    switchToMonth(monthName, isYear.value || yearHome.value);
  };
  const pastDateAnyYear = (index) => {
    const currentDate = new Date();
    const selectedDate = new Date(currentMonth.value);
    selectedDate.setDate(index - -1);
    const isPastDate = selectedDate.getTime() < currentDate.getTime();
    return isPastDate;
  };
  return {
    pastDateAnyYear,
    getMonthName,
    switchToMonth,
    checkActiveMonth,
    formattedMonth,
    currentYear,
    isHoliday,
    getAllPublicHolidays,
    formatDatecaryOver,
    previousYear,
    getPreviousYear,
    formatLeaveDays,
    emitClose,
    addLeave,
    days,
    weeks,
    nextMonth,
    previousMonth,
    currentYearAndMonth,
    format,
    inputValues,
    inputs,
    inputs1,
    inputs2,
    inputs3,
    inputs4,
    inputs5,
    inputs6,
    getIndex,
    showCard,
    selectCardValue,
    showCard2,
    showCard7,
    showCard3,
    showCard4,
    showCard5,
    showCard6,
    showCard8,
    selectCardValue2,
    selectCardValue3,
    selectCardValue4,
    selectCardValue5,
    selectCardValue6,
    selectCardValue7,
    selectCardValue8,
    showCardIndex,
    showCardIndex2,
    showCardIndex3,
    showCardIndex4,
    showCardIndex5,
    showCardIndex6,
    showCardIndex7,
    showCardIndex8,
    inputValuesByMonth,
    inputsByMonth,
    inputsByMonth1,
    inputsByMonth2,
    inputsByMonth3,
    inputsByMonth4,
    inputsByMonth5,
    inputsByMonth6,
    cardValues,
    totalHours1,
    totalHours2,
    totalHours3,
    totalHours4,
    totalHours5,
    totalHours6,
    totalHours7,
    totalHours8,
    isRequestedPerson,
    alertState,
    alertTitle,
    alertType,
    getValidDays,
    approveLeaveByApprover,
    rejectLeaveByApprover,
    rejectLeaveByHr,
    approveLeaveByHr,
    isLeaveId,
    getAllLeaves,
    isStatus,
    isLeaveType,
    leave_stats,
    annualLeaveDays,
    specialLeaveDays,
    sickHoLeaveDays,
    sickLeaveDays,
    childsickLeaveDays,
    kCareLeaveDays,
    unexcusedLeaveDays,
    unpaidLeaveDays,
    moduleNames: moduleNamesRef,
    ishr,
    currentMonthName,
    isRequestedHome,
  };
};
