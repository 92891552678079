<template>
  <v-sheet max-width="" class="bg-transparent">
    <!-- end of parent-module -->
    <app-dialog
      ref="dialog"
      :title="
        isEdit ? $t('documents.editDocument') : $t('documents.addDocument')
      "
      subtitle=""
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('projects.addTitle')"
          :placeholder="$t('projects.enterFileTitle')"
          v-model.trim="fileTitle"
          :required="true"
        ></app-input-field>
        <!-- drag and drop -->
        <div
          class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
          @dragover="dragover"
          @drop.prevent
          @drop="dragFile"
          @click="pickFile"
          v-if="isEdit && editFileUrl === ''"
        >
          <span>
            <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
          </span>
          <input
            ref="file"
            @change="selectedFile"
            type="file"
            accept="*"
            hidden
            multiple
          />
          <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
            {{ $t("projects.clickToUpload") }}
            <!-- <span class="!font-normal text-[#667085]">{{
              $t("projects.orDragAndDrop")
            }}</span> -->
          </p>
          <p
            class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
          >
            {{ $t("projects.acceptedFiles") }}
          </p>
        </div>
        <div
          class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
          @dragover="dragover"
          @drop.prevent
          @drop="dragFile"
          @click="pickFile"
          v-if="!isEdit"
        >
          <span>
            <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
          </span>
          <input
            ref="file"
            @change="selectedFile"
            type="file"
            accept="*"
            hidden
            multiple
          />
          <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
            {{ $t("projects.clickToUpload") }}
            <!-- <span class="!font-normal text-[#667085]">{{
              $t("projects.orDragAndDrop")
            }}</span> -->
          </p>
          <p
            class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
          >
            {{ $t("projects.acceptedFiles") }}
          </p>
        </div>
        <div v-if="isEdit && editFileUrl !== ''">
          <div
            class="border w-full h-full mt-6 rounded-lg p-4 justify-between text-[#344054] font-semibold text-sm"
          >
            <div class="flex justify-between">
              <p class="line-clamp-2">{{ fileName }}</p>
              <v-icon
                class="cursor-pointer"
                icon="mdi:mdi-close-circle"
                color="#AE6A65"
                @click="deleteFile()"
              ></v-icon>
            </div>
            <p class="font-normal">{{ fileSize }}</p>
          </div>
        </div>
        <div v-if="uploading">
          <div v-for="(singleUpload, index) of uploadedFiles" :key="index">
            <div
              class="p-4 mt-6 text-[#667085] !rounded-lg border border-gray-200 w-full h-full"
            >
              <v-row v-if="showUploadOnEdit">
                <v-col cols="10">
                  <p
                    class="!font-inter !font-medium !text-[0.875rem] text-[#344054]"
                    @click="pickFile"
                  >
                    {{ singleUpload?.name }}
                  </p>
                  <p
                    class="mt-0 !font-inter !font-normal !text-[0.875rem] text-[#667085]"
                  >
                    {{ singleUpload?.size }} - {{ singleUpload?.progress }}%
                    {{
                      singleUpload?.progress === 100 ? "uploaded" : "uploading"
                    }}
                  </p>
                </v-col>
                <v-col cols="2" class="ml-auto">
                  <v-icon
                    v-if="singleUpload?.progress === 100"
                    icon="mdi:mdi-check-circle"
                    color="#AE6A65"
                  ></v-icon>
                  <v-progress-circular
                    v-else
                    :model-value="singleUpload?.progress"
                    :width="3"
                    color="#AE6A65"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <app-input-field
          :label="$t('File Path'.getTranslationKey())"
          :placeholder="$t('Enter file path'.getTranslationKey())"
          v-model.trim="filePath"
          :required="true"
          class="mt-3"
        ></app-input-field>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :loading="isLoading"
              :buttonLabel="
                isEdit ? $t('addcustomrole.saveChanges') : $t('accessrole.add')
              "
              :disabled="isSaveBtnDisabled()"
              @click-event="isEdit ? editProjectFile() : handleFileSave()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import {defineComponent} from 'vue';
import {formatBytes, getFullFileNameFromURL, getTransString} from '@/services/helpers'
import Queue from '@/services/Queue'
import UploadApi from '@/services/fileUploadApi';
import api from '@/services/api';
import {manageUserDocumentsUrl} from '@/services/endpoints';

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    documentTitle: {
      type: String,
      default: "Add file(s)"
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showUploadOnEdit: {
      type: Boolean,
      default: false
    },
    showFileType: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: false,
      count: 0,
      fileTitle: '',
      fileUrl: '',
      fileName: "",
      fileId: "",
      fileDate: "",
      uploading: false,
      uploadedFiles: [],
      queueUpload: new Queue(),
      queueSize: null,
      lastItem: null,
      filesDetails: [],
      urlArray: [],
      editEventProp: Function,
      isUploaded: false,
      editObject: {},
      editFileUrl: "",
      fileSize: "",
      filePath: ""
    }
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFile()
      }
    },
    editFileEventProp(newEvent) {
      this.editEventProp = newEvent
    },
  },
  computed: {
    isDisabled() {
      return this.lastItem?.progress !== 100
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isAllProjectFiles() {
      return this.$store.getters["projectModules/isAllProjectFiles"];
    },
    userKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkFieldCondition(elt) {
      return elt === "" || elt === null || !elt?.length;
    },
    isSaveBtnDisabled() {
      const requiredFieldsValues = [this.filesDetails, this.filePath];
      return this.fileTitle && !requiredFieldsValues.some((elt) => !this.checkFieldCondition(elt));
    },
    showDialog(data) {
      this.fileTitle = this.isEdit === true ? data?.title : ''
      this.fileName = this.isEdit === true ? getFullFileNameFromURL(data?.file_url) : ''
      this.fileId = this.isEdit === true ? data?.id : '';
      this.editFileUrl = this.isEdit === true ? data?.file_url : '';
      this.fileSize = this.isEdit === true ? data?.file_size : '';
      if (data?.file_url) {
        this.filesDetails = [{
          file_url: data?.file_url,
          file_title: data?.title
        }];
      }
      this.$refs.dialog.showDialog = true
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false
      this.fileTitle = '';
      this.fileUrl = '';
      this.fileName = "";
      this.fileId = "";
      this.fileDate = '';
      this.editFileUrl = "";
      this.uploadedFiles = []
      this.filesDetails = [];
      this.$emit("reset-dialog-status");
    },
    pickFile() {
      this.$refs.file.click()
    },

    /**
     * Handle the file creation functionality.
     */
    handleFileSave() {
      this.isLoading = true
      const documents = [...this.filesDetails]
      const documentsData = documents.length
          ? documents
          : [{title: this.fileTitle}]
      let modProjectFiles = documentsData.reduce((prev, curr) => [...prev, {
        ...curr,
        title: this.fileTitle,
        file_path: this.filePath
      }], [])

      if (documents.length === 1) {
        const currentProjectFile = {...modProjectFiles[0]};
        currentProjectFile.title = this.fileTitle;
        modProjectFiles = [currentProjectFile];
      }
      const payload = {
        user_documents: modProjectFiles
      }

      api()
          .post(`/hr/expert/documents/save-documents/${this.userKey}/`, payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.hideDialog()
              this.isLoading = false
              this.alertState = true
              this.alertType = "success"
              this.alertTitle = response.data.detail;
              this.$emit("get-data", 1)
              this.fileTitle = '';
            }
          })
          .catch((error) => {
            if (error) {
              this.isLoading = false
              this.alertState = true
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
            }
          });

    },
    editProjectFile() {
      const newPayload = {
        title: this.fileTitle,
        file_url: this.filesDetails[0]?.file_url,
      }
      this.isLoading = true

      api()
          .patch(`${manageUserDocumentsUrl}${this.fileId}/`, newPayload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.hideDialog()
              this.isLoading = false
              this.alertState = true
              this.alertType = "success"
              this.alertTitle = response.data.detail
              this.$emit("get-data", 1);
            }
          })
          .catch(error => {
            if (error) {
              this.isLoading = false;
              this.alertState = true;
              this.alertType = "error";
              this.alertTitle = error.response.data.details;
            }
          })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('ae-bg-green-300')) {
        event.currentTarget.classList.remove('ae-drag-window')
        event.currentTarget.classList.add('ae-bg-green-300')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('ae-bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      this.filelist = [...event.dataTransfer.files]

    },
    dragFile(event) {
      this.uploading = true

      this.filelist = ''
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      event.currentTarget.classList.add('ae-drag-window')
      this.filelist = [...event.dataTransfer.files]

      this.validateAndInitialUpload(this.filelist)

    },
    selectedFile(event) {
      this.uploading = true
      this.filelist = ''
      this.filelist = [...event.target.files]
      this.validateAndInitialUpload(this.filelist)
    },
    validateAndInitialUpload() {
      const SIZE = 16777216
      for (let i = 0; i < this.filelist.length; i++) {
        const file = this.filelist[i];
        this.queueUpload.enqueue(file)
        this.queueSize = this.queueUpload.getSize()

        if (file.size > SIZE) {
          this.$refs.dialog.showDialog = !this.isDialog
          this.isLoading = false
          this.alertState = true
          this.alertType = "error"
          this.alertTitle = "You cannot upload file size more than 16mb";

          return
        } else {
          this.uploadedFiles.push({name: file.name, progress: 0, size: formatBytes(file.size)});
        }
        const formData = new FormData();
        formData.append("project_file", file);
        formData.append("title", this.fileTitle);

        this.config = {
          onUploadProgress: (progressEvent) => {
            this.uploadedFiles[this.count % this.uploadedFiles?.length].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        };
      }
      this.lastItem = this.uploadedFiles?.length > 0 ? this.uploadedFiles[this.uploadedFiles?.length - 1] : undefined;

    },
    uploadFile() {
      const file = this.queueUpload.peek()
      const formData = new FormData();
      formData.append("document", file);
      formData.append("title", this.fileTitle)

      const details = {};

      UploadApi().patch(`hr/expert/documents/upload/${this.userKey}/`, formData, this.config)
          .then((response) => {
            if (response.data.status === 'success') {
              // Handle the response from the server if needed
              this.fileUrl = response.data.data.file_url
              details.title = response.data.data.title
              details.file_url = this.fileUrl;

              this.urlArray.push(this.fileUrl)
              this.isUploaded = true;

              this.$emit("url-from-file", this.urlArray)

              const isEmpty = this.queueUpload.isEmpty()
              if (!isEmpty) {
                this.queueUpload.removeItem(0)
                this.queueSize = this.queueUpload.getSize()
                this.count++

              } else {
                this.count = 0
                this.uploadedFiles = []
              }
              this.filesDetails.push(details)
            }
          })
          .catch((error) => {
            // Handle any error that occurred during the upload
            if (error) {
              this.uploadedFiles = []

            }
          });
    },
    deleteFile() {
      this.editFileUrl = "";
      this.filesDetails = [];
    }
  }
})
</script>
<style scoped>
.ae-drag-window {
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}

.ae-drag-window:hover {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border: 1px solid #fdc6c2 !important;
}

.ae-upload-progress {
  border-radius: 5px;
}

.ae-bg-green-300 {
  border: 5px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}
</style>
