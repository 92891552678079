<template>
  <div>
    <div class="date-wrapper">
      <div :class="['label', 'py-2', classString]">
        <label :for="id">{{ $t(`${label}`) }}</label>
      </div>
      <input
        placeholder="dd.mm.yyyy"
        class="cursor-pointer"
        type="text"
        id="customDatePicker"
        :value="modelValue"
        @click="showDatePicker"
        readonly
      />
      <span class="icon-calendar">
        <v-icon
          icon="custom:calendarIcon"
          size="20"
          :class="{ '!text-slate-400': disabled }"
        ></v-icon>
      </span>
    </div>
    <div class="date-picker-overlay" v-if="showCalendar">
      <div class="date-picker-container">
        <div class="date-picker-header">
          <!-- Month and year selector -->
        <div class="month-year-selector">
          <button class="change-month" @click="showMonthPicker = !showMonthPicker">{{ formattedMonth }}</button>
          <button class="change-year" @click="showYearPicker = !showYearPicker">{{ currentYear }}</button>
          <!-- Month picker dropdown -->
          <div v-if="showMonthPicker" class="month-picker">
            <span v-for="(month, index) in months" :key="month" class="month" :class="{'selected': currentMonth === index}" @click="setMonth(index)">
              {{ month }}
            </span>
          </div>
          <!-- Year picker dropdown -->
          <div v-if="showYearPicker" class="year-picker">
            <span v-for="year in years" :key="year" class="year" :class="{'selected': currentYear === year}" @click="setYear(year)">
              {{ year }}
            </span>
          </div>
        </div>
        <button class="change-date" @click="nextYear">&gt;</button>
          <button class="change-month" @click="changeMonth(-1)">
            <v-icon variant="text" icon="custom:chevronLeft"></v-icon>
          </button>
          <span class="current-month">{{ formattedDate }}</span>
          <button class="change-month" @click="changeMonth(1)">
            <v-icon variant="text" icon="custom:chevronRight"></v-icon>
          </button>
        </div>
        <div class="date-picker-body">
          <div class="calendar-grid">
            <div class="day-name" v-for="day in days" :key="day">{{ day }}</div>
            <div
              v-for="(date, index) in datesOfMonth"
              :key="index"
              class="date"
              :class="{ selected: isSelected(date), today: isToday(date) }"
              @click="selectDate(date)"
            >
              {{ date.getDate() }}
            </div>
          </div>
        </div>
        <div class="date-picker-footer">
          <button
            @click="cancel"
            class="w-full mr-5 py-[12px] px-[10px] inline-flex justify-center items-center gap-2 bg-transparent !font-inter !text-[1rem] !font-medium rounded-lg border border-transparent !font-medium !text-[#344054] focus:outline-none text-sm"
          >
            Cancel
          </button>
          <button
            @click="selectToday"
            class="w-full ml-5 py-[12px] px-[10px] inline-flex justify-center items-center gap-2 bg-transparent !font-inter !text-[1rem] !font-medium rounded-lg border border-transparent !font-medium !text-[#344054] focus:outline-none text-sm"
          >
            Today
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/services/helpers";
export default {
  name: "AppDatePicker",
  props: {
    id: {
      type: String,
      default: "input-field",
    },
    label: {
      type: String,
      default: "Select a date",
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: String,
      default: "",
    },
    classString: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showCalendar: false,
      currentDate: new Date(),
      selectedDate: null,
      days: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      showMonthPicker: false,
      showYearPicker: false,
      // Add more years as needed
      years: Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 5 + i),
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };
  },
  computed: {
    formattedMonth() {
      return this.months[this.currentMonth];
    },
    formattedDate() {
      return this.currentDate.toLocaleDateString("default", {
        month: "long",
        year: "numeric",
      });
    },
    datesOfMonth() {
      const dates = [];
      const firstDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      ).getDay();
      const daysInLastMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        0
      ).getDate();
      const daysInMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      ).getDate();

      // Add days from the previous month
      for (let i = firstDay; i > 0; i--) {
        dates.push(
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            -i + 1
          )
        );
      }

      // Add days of current month
      for (let i = 1; i <= daysInMonth; i++) {
        dates.push(
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            i
          )
        );
      }

      // Add days of next month to complete the week
      let nextMonthDay = 1;
      while (dates.length % 7 !== 0) {
        dates.push(
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            nextMonthDay++
          )
        );
      }

      return dates;
    },
  },
  methods: {
    showDatePicker() {
      this.selectedDate = new Date();
      this.showCalendar = true;
      this.$emit("update:modelValue", null);
    },
    formatDate(date) {
      return formatDate(date);
    },
    changeMonth(step) {
      this.currentDate.setMonth(this.currentDate.getMonth() + step);
      this.currentDate = new Date(this.currentDate); // Trigger Vue reactivity
    },
    selectDate(date) {
      this.selectedDate = date;
      this.showCalendar = false;
    },
    selectToday() {
      this.selectedDate = new Date();
      this.currentDate = new Date();
      this.showCalendar = false;
    },
    cancel() {
      this.showCalendar = false;
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    isSelected(date) {
      return (
        this.selectedDate.getDate() === date.getDate() &&
        this.selectedDate.getMonth() === date.getMonth() &&
        this.selectedDate.getFullYear() === date.getFullYear()
      );
    },
    setMonth(month) {
      this.currentMonth = month;
      this.showMonthPicker = false;
    },
    setYear(year) {
      this.currentYear = year;
      this.showYearPicker = false;
    },
    nextYear() {
      this.currentYear++;
    },
    prevYear() {
      this.currentYear--;
    },
  },
};
</script>

<style scoped>
.date-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-picker-container {
  background: white;
  min-width: 360px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.date-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.date-picker-header .change-month {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.date-picker-header .current-month {
  font-size: 20px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  justify-content: center;
  align-content: center;
}

.day-name {
  text-align: center;
  font-weight: bold;
}

.date {
  text-align: center;
  cursor: pointer;
  padding: 9px 3px;
  border-radius: 50%;
}

.date:hover {
  background-color: #d5d5d5;
}

.selected {
  background-color: #8b2923;
  color: white;
}

.date-picker-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.date-picker-footer button {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  height: 40px;
  color: white;
  font-size: 16px;
}

.date-picker-footer button:hover {
  background-color: #a2544f;
}

.border-red-500 {
  border-color: red;
}
.input-wrapper {
  position: relative;
}
label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

input {
  align-items: center;
  padding: 10px 14px;
  outline-width: 0;
  gap: 8px;
  min-width: 360px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  color: #101828;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

input:focus {
  background: #ffffff;
  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}

input:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
.date-wrapper {
  position: relative;
  display: inline-block;
}
.date-wrapper .icon-calendar {
  position: absolute;
  right: 10px;
  top: 72%;
  transform: translateY(-50%);
  pointer-events: none;
}
.month-year-selector {
  position: relative;
}

.month-picker,
.year-picker {
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.month,
.year {
  padding: 5px 10px;
  cursor: pointer;
}

.month:hover,
.year:hover,
.month.selected,
.year.selected {
  background-color: #007bff;
  color: white;
}
</style>
