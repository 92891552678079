import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import moment from "moment";
import Api from "@/services/api";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  getUnpaginatedData,
  publicHolidays,
  employeeRequestLeave,
} from "@/services/endpoints";

export const setupLogic = () => {
  let alertState = ref(false);
  let alertTitle = ref("");
  let alertType = ref("success");
  const currentMonth = ref(new Date());
  const weeksInMonth = ref([]);
  const inputValues = ref([]);
  const inputs = ref([]);
  const inputs1 = ref([]);
  const inputs2 = ref([]);
  let currentMonthName = ref("");
  let annualLeaveDays = ref(0);
  let specialLeaveDays = ref(0);
  const showCardIndex = ref(-1);
  const showCardIndex2 = ref(-1);
  const { t } = useI18n();
  const inputValuesByMonth = ref({});
  const inputsByMonth = ref({});
  const inputsByMonth1 = ref({});
  const statuses = ref([]);
  const statuses1 = ref([]);
  let loading = ref(false);
  let leave_stats = ref(undefined);
  let start_date1,
    end_date1,
    description1,
    start_date2,
    end_date2,
    description2;

  // Before touching this code please go through the guidelines
  // 1. this code uses date-fns to create a customize calender from scratch for booking leaves
  // 2.the variable declaration with 1,2,3,4,5,5,6,7,8 etc eg input1, showcard1 etc
  //  means that that particular variable perform it function on cell 1
  // Thank you very much the rest is code and analyzing the function

  const cardValues = [
    { value: "0.5", label: t("calender.firsthalf") },
    { value: "0.5", label: t("calender.secondhalf") },
    { value: "1", label: t("calender.fullday") },
    { value: "", label: t("calender.remove") },
  ];
  const currentYearAndMonth = computed(() =>
    format(currentMonth.value, "yyyy")
  );
  const currentYearAndMonth1 = computed(() =>
    format(currentMonth.value, "MMMM yyyy")
  );

  const store = useStore();
  const isUserKey = computed(() => {
    return store.getters["auth/isPersonalKey"];
  });
  const isUserName = computed(() => {
    return store.getters["auth/isUsername"];
  });
  const isName = computed(() => {
    return store.getters["hrResources/isPersonalData"];
  });
  const isLeaveId = computed(() => {
    return store.getters["hrResources/isLeaveId"];
  });

  // this function get the status color of a booked leave
  const getStatusColor = (index) => {
    const status = statuses.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor1 = (index) => {
    const status = statuses1.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class";
      // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  // go to next month
  const nextMonth = () => {
    getAllLeaves();
    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() + 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);

    updateCalendar();
  };
  // go to previous month
  const previousMonth = () => {
    getAllLeaves();

    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() - 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    updateCalendar();
  };

  // this function update the calsender as the user moves from prev to next
  const updateCalendar = () => {
    const start = startOfMonth(currentMonth.value);
    const end = endOfMonth(currentMonth.value);
    const dates = eachDayOfInterval({ start, end }).map((date) => {
      return {
        date: format(date, "d"),
        dayOfWeek: format(date, "E"),
      };
    });

    const calendarWeeks = [];
    while (dates.length) {
      const week = dates.splice(0, 7);
      calendarWeeks.push(week);
    }

    weeksInMonth.value = calendarWeeks;
    // Retrieve input values for the current month
    inputValues.value =
      inputValuesByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs.value =
      inputsByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs1.value =
      inputsByMonth1.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
  };

  updateCalendar();

  const days = computed(() => {
    const daysWithValues = [];
    const firstWeek = weeksInMonth.value[0];

    for (let i = 0; i < firstWeek.length; i++) {
      if (firstWeek[i].date) {
        const day = firstWeek[i].dayOfWeek;
        daysWithValues.push(day.substring(0, 2));
      }
    }

    return daysWithValues;
  });

  const weeks = computed(() => {
    return weeksInMonth.value.map((week) => {
      return week.filter((day) => day.date !== "");
    });
  });

  const getIndex = (weekIndex, dateIndex) => {
    const totalDays = weeksInMonth.value.flat().length;
    return weekIndex * 7 + dateIndex >= totalDays
      ? totalDays - 1
      : weekIndex * 7 + dateIndex;
  };
  const bindInputWithDate = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputValues.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };

  const bindInputWithDate2 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const isDatePast = (index) => {
    const currentDate = new Date();
    const selectedDate = new Date(currentMonth.value);
    selectedDate.setDate(index);

    const isSameDay = selectedDate.getDate() === currentDate.getDate();
    const isPastDate = selectedDate.getTime() < currentDate.getTime();

    return !isSameDay && isPastDate;
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  // get rid of extra days if the month is not up to that days
  const getValidDays = (week) => {
    const validDays = [];
    const dateRow = week.filter((date) => date.date);
    // const totalDates = dateRow.length;

    for (let i = 0; i < week.length; i++) {
      if (dateRow[i]) {
        validDays.push(week[i].dayOfWeek.substring(0, 2));
      } else {
        validDays.push("");
      }
    }

    return validDays;
  };
  const selectedDates1 = []; // Array to store selected dates for input row 1
  const selectedDates2 = []; // Array to store selected dates for input row 2
  const totalHours1 = ref(0);
  const totalHours2 = ref(0);

  const showCard = (weekIndex, dateIndex) => {
    showCardIndex.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate(weekIndex, dateIndex, 1);
    description1 = "Annual Leave";
    closeCard2();
  };
  const closeCard1 = () => {
    showCardIndex.value = -1;
  };
  const selectCardValue = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (inputs.value[index] === "") {
      const removeLabel = t("calender.remove");
      const hasRemoveLabel = cardValues.some(
        (item) => item.label === removeLabel
      );
      if (!hasRemoveLabel) {
        cardValues.push({ value: "", label: removeLabel });
      }
    }

    if (Number(value ?? 0) + Number(inputs.value[index] ?? 0) > 1) return null;
    inputValues.value[index] = value;
    showCardIndex.value = -1;
    const { date, time } = bindInputWithDate(weekIndex, dateIndex);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;

    const dateTime = `${date} ${time}`;
    selectedDates1.push(dateTime); // Push the combined date and time to the array for input row 2
    totalHours1.value = inputValues.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const parsedDates = selectedDates1.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date1 = new Date(Math.min(...parsedDates));
    end_date1 = new Date(Math.max(...parsedDates));
  };

  const showCard2 = (weekIndex, dateIndex) => {
    showCardIndex2.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description2 = "Special Leave";

    closeCard1();
  };
  const closeCard2 = () => {
    showCardIndex2.value = -1;
  };

  const selectCardValue2 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (Number(value ?? 0) + Number(inputValues.value[index] ?? 0) > 1)
      return null;
    inputs.value[index] = value;

    showCardIndex2.value = -1;
    const { date, time } = bindInputWithDate2(weekIndex, dateIndex);
    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates2.push(dateTime); // Push the combined date and time to the array for input row 2
    totalHours2.value = inputs.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses1.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;

    // Find the start and future date for input row 2
    const parsedDates = selectedDates2.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date2 = new Date(Math.min(...parsedDates));
    end_date2 = new Date(Math.max(...parsedDates));
  };

  const rejectLeave = () => {
    description1 = false;
  };
  const approveLeave = () => {
    description1 = false;
  };
  const addLeave = () => {
    loading.value = true;
    const leaveDays1 = selectedDates1.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const leaveDays2 = selectedDates2.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const payload = {
      leaves: [
        {
          leave_type: description1,
          start_date: formatDate(start_date1),
          end_date: formatDate(end_date1),
          total_days: totalHours1.value,
          leave_days: leaveDays1 || null,
        },
        {
          leave_type: description2,
          start_date: formatDate(start_date2),
          end_date: formatDate(end_date2),
          total_days: totalHours2.value,
          leave_days: leaveDays2 || null,
        },
      ],
      user_key: isUserKey.value,
      is_draft: false,
    };

    // filtering payload  to  accept only valid leave days
    payload.leaves = payload.leaves.filter(
      (leaf) =>
        leaf.leave_type &&
        leaf.start_date !== "NaN-aN-aN" &&
        leaf.end_date !== "NaN-aN-aN" &&
        leaf.total_days !== undefined &&
        leaf.leave_days !== undefined
    );
    function filterLeaveDays(payload) {
      const { leaves } = payload;

      for (let i = 0; i < leaves.length; i++) {
        const leave = leaves[i];
        const { leave_days } = leave;

        if (leave_days && leave_days.length > 0) {
          const leaveDaysMap = new Map();

          for (let j = 0; j < leave_days.length; j++) {
            const { leave_day } = leave_days[j];
            leaveDaysMap.set(leave_day, j);
          }

          const filteredLeaveDays = [];

          for (const index of leaveDaysMap.values()) {
            const leave_day = leave_days[index];
            filteredLeaveDays.unshift(leave_day);
          }

          leave.leave_days = filteredLeaveDays;
        }
      }

      return payload;
    }
    // after first filter of payload we apply another filter again
    const filteredPayload = filterLeaveDays(payload);
    filteredPayload.leaves.forEach((leave) => {
      leave.leave_days = leave.leave_days.filter((day) => day.days !== "");
    });

    function processFilteredPayload(filteredPayload) {
      // We're going to use a filter on the leaves array
      filteredPayload.leaves = filteredPayload.leaves.filter((leave) => {
        // Filter out leave_days with empty days
        leave.leave_days = leave.leave_days.filter((day) => day.days !== "");
        // If after filtering, there are no leave_days, then return false so this leave gets removed
        if (leave.leave_days.length === 0) return false;
        const leaveDays = leave.leave_days.map(
          (day) => new Date(day.leave_day)
        );
        const minDate = new Date(Math.min(...leaveDays));
        const maxDate = new Date(Math.max(...leaveDays));
        leave.start_date = minDate.toISOString().split("T")[0];
        leave.end_date = maxDate.toISOString().split("T")[0];
        return true; // If we reach here, keep the leave in the array
      });
      return filteredPayload;
    }

    const processedPayload = processFilteredPayload(filteredPayload);
    Api()
      .post(`${employeeRequestLeave}`, processedPayload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          getAllLeaves();

          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  // save leave as draft
  const saveDraft = () => {
    // loading.value = true;
    const leaveDays1 = selectedDates1.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const leaveDays2 = selectedDates2.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const payload = {
      leaves: [
        {
          leave_type: description1,
          start_date: formatDate(start_date1),
          end_date: formatDate(end_date1),
          total_days: totalHours1.value,
          leave_days: leaveDays1 || null,
        },
        {
          leave_type: description2,
          start_date: formatDate(start_date2),
          end_date: formatDate(end_date2),
          total_days: totalHours2.value,
          leave_days: leaveDays2 || null,
        },
      ],
      user_key: isUserKey.value,
      is_draft: true,
    };

    // filtering payload  to  accept only valid leave days
    payload.leaves = payload.leaves.filter(
      (leaf) =>
        leaf.leave_type &&
        leaf.start_date !== "NaN-aN-aN" &&
        leaf.end_date !== "NaN-aN-aN" &&
        leaf.total_days !== undefined &&
        leaf.leave_days !== undefined
    );
    function filterLeaveDays(payload) {
      const { leaves } = payload;

      for (let i = 0; i < leaves.length; i++) {
        const leave = leaves[i];
        const { leave_days } = leave;

        if (leave_days && leave_days.length > 0) {
          const leaveDaysMap = new Map();

          for (let j = 0; j < leave_days.length; j++) {
            const { leave_day } = leave_days[j];
            leaveDaysMap.set(leave_day, j);
          }

          const filteredLeaveDays = [];

          for (const index of leaveDaysMap.values()) {
            const leave_day = leave_days[index];
            filteredLeaveDays.unshift(leave_day);
          }

          leave.leave_days = filteredLeaveDays;
        }
      }

      return payload;
    }
    // after first filter of payload we apply another filter again
    const filteredPayload = filterLeaveDays(payload);
    filteredPayload.leaves.forEach((leave) => {
      leave.leave_days = leave.leave_days.filter((day) => day.days !== "");
    });

    // function formatDate(inputDate) {
    //   const [year, month, day] = inputDate
    //     .split("-")
    //     .map((str) => str.padStart(2, "0"));
    //   return `${year}-${month}-${day}`;
    // }
    function processFilteredPayload(filteredPayload) {
      // We're going to use a filter on the leaves array
      filteredPayload.leaves = filteredPayload.leaves.filter((leave) => {
        // Filter out leave_days with empty days
        leave.leave_days = leave.leave_days.filter((day) => day.days !== "");
        // If after filtering, there are no leave_days, then return false so this leave gets removed
        if (leave.leave_days.length === 0) return false;
        const leaveDays = leave.leave_days.map(
          (day) => new Date(day.leave_day)
        );
        const minDate = new Date(Math.min(...leaveDays));
        const maxDate = new Date(Math.max(...leaveDays));

        leave.start_date = minDate.toISOString().split("T")[0];
        leave.end_date = maxDate.toISOString().split("T")[0];
        return true; // If we reach here, keep the leave in the array
      });
      return filteredPayload;
    }

    const processedPayload = processFilteredPayload(filteredPayload);
    Api()
      .post(`${employeeRequestLeave}`, processedPayload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          getAllLeaves();

          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };

  // get leaves

  const getAllLeaves = () => {
    const userKey = isUserKey.value;
    const payload = {
      user_key: userKey,
    };

    Api()
      .post(`${getUnpaginatedData}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.data[1];
          leave_stats.value = response.data.data[0].leave_stat;

          const leaveDaysArray = data.leaves.flatMap((entry) =>
            entry.leave_days.map((leaveDay) => ({
              ...leaveDay,
              leave_type: entry.leave_type,
              status: entry.status,
            }))
          );

          processLeaveDaysArray(leaveDaysArray);
        }
      });
  };

  let isStatus = ref("");
  // processed the leave days from the endpoint and fill the cell correctly
  const LEAVE_TYPES = {
    ANNUAL: "Annual Leave",
    SPECIAL: "Special Leave",
  };

  const processLeaveDaysArray = (leaveDays) => {
    statuses.value = Array(inputValues.value.length).fill("");
    statuses1.value = Array(inputs.value.length).fill("");
    leaveDays.forEach((leaveDay) => {
      const date = moment(leaveDay.leave_day);
      const weekIndex = findWeekIndex(date);

      if (weekIndex !== -1) {
        const dateIndex = findDateIndex(weekIndex, date);

        if (dateIndex !== -1) {
          const index = getIndex(weekIndex, dateIndex);
          assignLeaveValue(index, leaveDay);
        }
      }
    });

    computeLeaveTotals();
  };

  const findWeekIndex = (date) => {
    return weeks.value.findIndex((week) =>
      week.some(
        (d) =>
          d.date &&
          moment([
            currentMonth.value.getFullYear(),
            currentMonth.value.getMonth(),
            d.date,
          ]).isSame(date, "day")
      )
    );
  };

  const findDateIndex = (weekIndex, date) => {
    return weeks.value[weekIndex].findIndex(
      (d) =>
        d.date &&
        moment([
          currentMonth.value.getFullYear(),
          currentMonth.value.getMonth(),
          d.date,
        ]).isSame(date, "day")
    );
  };

  const assignLeaveValue = (index, leaveDay) => {
    switch (leaveDay.leave_type) {
      case LEAVE_TYPES.ANNUAL:
        inputValues.value[index] = leaveDay.days;
        statuses.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.SPECIAL:
        inputs.value[index] = leaveDay.days;
        statuses1.value[index] = leaveDay.status;
        break;
      default:
        // console.error('Unknown leave type:', leaveDay.leave_type);
        break;
    }
  };

  const computeLeaveTotals = () => {
    annualLeaveDays.value = sumDays(inputValues.value);
    specialLeaveDays.value = sumDays(inputs.value);
  };

  const sumDays = (values) => {
    return values.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) ? total + parsedValue : total;
    }, 0);
  };

  onMounted(() => {
    const currentMonthIndex = new Date().getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    checkActiveMonth();
    getAllPublicHolidays();
    getAllLeaves();
  });
  const { emit } = getCurrentInstance();

  const emitClose = (data) => {
    emit("close-event", data);
  };
  // this is where the leave days are formated to 8hrs per day
  // const formatLeaveDays = (leaveDays) => {
  //   if (leaveDays === 0) {
  //     return "0";
  //   }

  //   const isNegative = leaveDays < 0;
  //   const absoluteDays = Math.abs(Math.floor(leaveDays));
  //   const absoluteHours = Math.floor((Math.abs(leaveDays) % 1) * 8);

  //   let formattedString = "";

  //   if (isNegative) {
  //     formattedString += "-";
  //   }

  //   if (absoluteDays > 0) {
  //     formattedString += `${absoluteDays} ${
  //       absoluteDays === 1 ? t("calender.day") : t("calender.days")
  //     }`;
  //   }

  //   if (absoluteHours > 0) {
  //     if (formattedString !== "") {
  //       formattedString += " ";
  //     }
  //     formattedString += `${absoluteHours} ${
  //       absoluteHours === 1 ? t("calender.hour") : t("calender.hours")
  //     }`;
  //   }

  //   return formattedString;
  // };

  const formatLeaveDays = (leaveDays) => {
   
    if (leaveDays === undefined) {
      return "";
    }
  
    // Calculate the absolute decimal value
    const absoluteValue = leaveDays;
  
    // Determine the sign
    const sign = leaveDays < 0 ? "-" : "";
  
    // Create the formatted string
    const formattedString = `${sign}${absoluteValue} ${
      absoluteValue === "1.0" ? t("calender.day") : t("calender.days")
    }`;
  
    return formattedString;
  };
  
  const closeAll = () => {
    closeCard1();
    closeCard2();
  };
  // get holidays
  const holidays = ref([]);

  const getAllPublicHolidays = async () => {
    try {
      const response = await Api().get(`${publicHolidays}`);

      if (response.data && response.data.status === "success") {
        holidays.value = response.data.data;
      }
    } catch (error) {
      // Handle the error as needed
    }
  };

  function getMonthValue(currentMonth) {
    const date = new Date(currentMonth);
    return date.getMonth() + 1; // Adding 1 to get month value from 1 to 12
  }
  // this function check and mark a cell as holiday
  function isHoliday(day) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth1 = getMonthValue(currentMonth.value); // Month starts from 0, so adding 1
    const formattedDate = `${currentYear}-${currentMonth1
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    for (const holiday of holidays.value) {
      if (
        formattedDate >= holiday.start_date &&
        formattedDate <= holiday.end_date
      ) {
        return true; // The day is a holiday
      }
    }

    return false; // The day is not a holiday
  }
  function getPreviousYear() {
    const currentYear = new Date().getFullYear();
    return currentYear - 1;
  }
  // get the previous year value
  const previousYear = getPreviousYear();
  function formatDatecaryOver(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return date.toLocaleDateString(undefined, options);
    } else {
      return "N/A";
    }
  }
  // perform action on the month eg indicationg active etc
  const formattedMonth = ref("");
  const currentYear = ref("");
  function checkActiveMonth() {
    const currentDate = new Date();
    const currentMonthAndYearWithday = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    return currentMonthAndYearWithday === currentYearAndMonth1.value;
  }
  const pastDateAnyYear = (index) => {
    const currentDate = new Date();
    const selectedDate = new Date(currentMonth.value);
    selectedDate.setDate(index - -1);
    return selectedDate.getTime() < currentDate.getTime();
  };
  return {
    loading,
    saveDraft,
    checkActiveMonth,
    formattedMonth,
    pastDateAnyYear,
    currentYear,
    formatDatecaryOver,
    previousYear,
    getPreviousYear,
    getMonthValue,
    isHoliday,
    formatLeaveDays,
    getAllPublicHolidays,
    emitClose,
    isUserName,
    addLeave,
    days,
    weeks,
    nextMonth,
    previousMonth,
    currentYearAndMonth,
    format,
    inputValues,
    inputs,
    inputs1,
    inputs2,
    getIndex,
    showCard,
    selectCardValue,
    showCard2,
    selectCardValue2,
    showCardIndex,
    showCardIndex2,
    inputValuesByMonth,
    inputsByMonth,
    inputsByMonth1,
    cardValues,
    totalHours1,
    totalHours2,
    alertState,
    alertTitle,
    alertType,
    getValidDays,
    approveLeave,
    rejectLeave,
    isUserKey,
    getAllLeaves,
    isStatus,
    isDatePast,
    getStatusColor,
    getStatusColor1,
    annualLeaveDays,
    specialLeaveDays,
    statuses,
    statuses1,
    leave_stats,
    closeCard2,
    closeCard1,
    closeAll,
    currentMonthName,
    isName,
    isLeaveId,
  };
};
