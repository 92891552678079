<template>
  <div>
    <app-header
      :name="isUserKey ? isName : $t('employees.newEmployee')"
      class="mb-5 ml-2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
    </app-header>
    <div>
      <div>
        <button
          :disabled="!isUserKey"
          v-for="(tab, index) in tabList"
          :key="index"
          :class="[
            'tab-button',
            { active: currentTab === tabs[index] },
            { disabled: !isUserKey },
          ]"
          @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-5"
      :handleButtonRoute="handleButtonRoute"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
import Personal from "@/components/dashboard/employees/Personal.vue";
import Contact from "@/components/dashboard/employees/Contact.vue";
import Employment from "@/components/dashboard/employees/Employment.vue";
import Qualifications from "@/components/dashboard/employees/Qualifications.vue";
import Projects from "@/components/dashboard/employees/Projects.vue";
import Documents from "@/components/dashboard/employees/Documents.vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";

export default defineComponent({
  name: "AddEmployee",
  components: {
    AppUnsavedChangesDialog,
    Personal,
    Contact,
    Employment,
    Qualifications,
    Projects,
    Documents,
    AppHeader,
  },
  data() {
    return {
      currentTab: "Personal",
      isArrowBack: false,
      tabList: ["Personal", "Contact", "Employment", "Qualifications"],
      tabs: ["Personal", "Contact", "Employment", "Qualifications"],
    };
  },
  computed: {
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isEmployeesUpdate() {
      return this.$store.getters["hrResources/isEmployeesUpdate"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    isName() {
      const personalData = this.$store.getters["hrResources/isPersonalData"];
      return (
        (personalData?.first_name || "") + " " + (personalData?.last_name || "")
      );
    },
    addEmployeeLang() {
      return this.translation.locale;
    },
  },
  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isEmployeesUpdate).some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      if (this.isEmployeesUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    handleGoToBack() {
      if (Object.values(this.isEmployeesUpdate).some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.isEmployeesUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isEmployeesUpdate).reduce(
        (acc, curr) => {
          if (this.isEmployeesUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  created() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.currentTab = this.tabs[this.isCurrentTab];
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>

<style scoped></style>
