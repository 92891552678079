<template>
  <div>
    <div
      v-if="apploader"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else>

      <div class="table ">
        <!-- <div class="p-4 mx-1 mb-8 border rounded-lg">
            <div class="text-lg font-bold">{{ $t("cheques.bankBalance") }}</div>
            <div class="flex-container">
              <p v-if="balances.length === 0" class="P300">{{ $t("bankcash.noBankBalance") }}</p>
              <div
                v-for="data in balances"
                :key="data.currency"
                class="flex-item"
              >
                <div class="">
                  <div class="currency">{{ data.currency_name }}</div>
                  <div class="amount">
                    {{
                      appLang === "de"
                        ? germanCurrency(data.bank_balance)
                        : amountFormatting(data.bank_balance)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="py-4">
          <v-sheet max-width="" class="px-3 pb-3 mt-5 bg-white  border rounded-lg">
        <CurrencyExchangeBalances></CurrencyExchangeBalances>
      </v-sheet>
    </div>
        <app-table
          :showCursor="false"
          :hideMenu="shouldDisableInput"
          :headers="headers"
          :items="isCurrencyData"
          :pagename="$t('currencyExchange.currencyExchange')"
          :message="$t('currencyExchange.currencyExchange')"
          @delete-event="showDelete({ $event, data: 'delete' })"
          @edit-event="showDialog({ $event, data: 'detail' })"
          data-cy="curency-exchange"
        >
          <template #title>
            <h3 class="H500">{{ $t("currencyExchange.allCurrencyExchange") }}</h3>
          </template>
          <template #actions>
            <app-primary-button
              @click-event="showDialog({ data: 'add' })"
              :buttonLabel="$t('currencyExchange.addCurrencyExchange')"
              data-cy="btn_cash_bank"
              v-if="!shouldDisableInput"
            >
              <template #icon>
                <v-icon icon="mdi-plus"></v-icon>
              </template>
            </app-primary-button>
          </template>
          <template #footer>
            <div>
              <Paginator
                :total-items="totalPages"
                v-model="currentPages"
                :items-per-page="10"
                :max-pages-shown="5"
                :on-click="loadMore"
              />
            </div>
          </template>
        </app-table>
      </div>
      <AverageExchange></AverageExchange>
      <app-dialog
        ref="dialog"
        :title="
          isEdit
            ? $t('currencyExchange.editCurrencyExchange')
            : $t('currencyExchange.addCurrencyExchange')
        "
        :subtitle="``"
      >
        <template #content>
           <p class="mb-2">{{ $t('bankcash.transferTo') }}</p>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="flex items-center pr-2">
                <input
                  class="cursor-pointer"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault01"
                  v-model="selectedOption"
                  value="Cash"
                  checked
                />
                <label
                  class="H400"
                  for="radioDefault01"
                  :class="{ '!cursor-default': shouldDisableInput }"
                >
                  {{ $t("bankcash.cash") }}
                </label>
              </div>
              <div class="flex items-center ml-9">
                <input
                  class="cursor-pointer"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault02"
                  value="Bank"
                  v-model="selectedOption"
                />
                <label
                  class="H400"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  for="radioDefault02"
                >
                  {{ $t("bankcash.bank") }}
                </label>
              </div>
            </div>
          </div>
          <app-date-field
            v-model="form.date"
            type="date"
            :hasError="isFieldInvalid('date')"
            :isRequired="true"
            :label="$t('bankcash.date')"
          ></app-date-field>
          <app-single-select
            id="currencies"
            :showCheckbox="false"
            :showSearch="true"
            :options="sourceCurrencyOptions"
            :searchPlaceholder="$t('bankcash.search')"
            :labelPlaceholder="$t('currencyExchange.selectSourcecurrency')"
            :label="$t('currencyExchange.sourceCurrency')"
            v-model="form.source_currency"
            :hasError="isFieldInvalid('source_currency')"
            :isRequired="true"
          ></app-single-select>
    
          <app-input-field
            :placeholder="$t('currencyExchange.enterSourceAmount')"
            v-model="source_amount"
            :label="$t('currencyExchange.sourceAmount')"
            validation="floatingNumber"
            :isRequired="true"
            :hasError="isFieldInvalid('source_amount')"
            :min="0"
          ></app-input-field>
          <p v-if="isAmountAvailable" class="text-red-500">
            {{ $t("currencyExchange.availableAmount") }}:{{
              selectedOption === "Bank" ?getLanguageCurrency(totalBankBalance):getLanguageCurrency( totalCashBalance)
            }}
          </p>
    
          <app-single-select
            id="currencies"
            :showCheckbox="false"
            :showSearch="true"
            :options="targetCurrencyOptions"
            :searchPlaceholder="$t('bankcash.search')"
            :labelPlaceholder="$t('currencyExchange.selectTargetCurrency')"
            :label="$t('currencyExchange.targetCurrency')"
            v-model="form.target_currency"
            :isRequired="true"
            :hasError="isFieldInvalid('target_currency')"
          ></app-single-select>
    
          <app-input-field
            :placeholder="$t('currencyExchange.enterTargetAmount')"
            v-model="form.target_amount"
            :label="$t('currencyExchange.targetAmount')"
            validation="floatingNumber"
            :isRequired="true"
            :hasError="isFieldInvalid('target_amount')"
          ></app-input-field>
          <ExchangeFile></ExchangeFile>
        </template>
        <template #actions>
          <v-row class="px-4 mt-1 mb-5">
            <v-col cols="6">
              <app-secondary-button
                :buttonLabel="$t('projects.cancel')"
                @click-event="hideDialog()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="6">
              <app-primary-button
                :buttonLabel="
                  isEdit ? $t('bankcash.savechanges') : 'currencyExchange.save'
                "
                :loading="loading"
                @click-event="isEdit ? editExchange() : AddExchange()"
              >
              </app-primary-button>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
      <!-- delete mile stone  -->
      <app-dialog
        ref="delete"
        :title="$t(`currencyExchange.deleteCurrencyExchange`)"
        :subtitle="`currencyExchange.sureDelete`"
      >
        <template #actions>
          <v-row class="px-4 mt-1 mb-5">
            <v-col cols="5">
              <app-secondary-button
                :buttonLabel="$t('bankcash.noKeepIt')"
                @click-event="hideDelete()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="7">
              <v-btn
                variant="default"
                min-width="100%"
                min-height="100%"
                class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
                @click="deleteExchange()"
              >
                {{$t('currencyExchange.delete')}}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
    
      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Api from "@/services/api"; import { getCurrencyTransaction, manageExchange, AddExchange, getAllCurrencies } from "@/services/endpoints";
import Paginator from "@/components/shared/PaginationComponent.vue";
import AverageExchange from '@/components/dashboard/projects/pm-local/currency-exchange/AverageExchange.vue';
import ExchangeFile from '@/components/dashboard/projects/pm-local/currency-exchange/ExchangeFile.vue';
import { useI18n } from 'vue-i18n';
import { currencyFormatter, formatAmount } from "@/services/helpers";
import CurrencyExchangeBalances from './CurrencyBalances.vue'
import { mapGetters, mapState } from 'vuex';
import { getTransString } from "@/services/helpers";
import moment from "moment";
export default defineComponent({
  name: "CurrencyExchange",
  components: {
    Paginator,
    AverageExchange, ExchangeFile,CurrencyExchangeBalances
  },
  created() {

    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 5);
  },
  data() {
    return {
      headers: [
        "Item No.",
        "Date",
        "Cash/Bank",
        "Source Currency",
        "Source Amount",
        "Target Currency",
        "Target Amount",
        "Exchange Rate",
      ],
      apploader: true,
      currentPages: null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      values: [],
      sourceCurrencyOptions: [],
      targetCurrencyOptions: [],
      cancelLabel: "Cancel",
      buttonLabel: "Add position",
      title: "",
      deadline: "",
      description: "",
      itemId: null,
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      source_amount: 0,
      form: {
        date: "",
        source_currency: null,

        target_currency: null,
        target_amount: 0,
        transaction_type: ""
      }
      ,
      balances: [],
      fetchCurrencies: [],
      selectedOption: "Cash",
      amount: 0,
      isAmountAvailable: false,
      totalAmount: null,
      totalCashBalance: 0,
      totalBankBalance: 0,
      isDirty: [],
      updatedCurrencies: [],
      requiredFields: [
        "date",
        "source_currency",
        "target_currency",
        "target_amount",
      ],
    };

  },

  computed: {
    ...mapGetters("translation", ["getLocale"]),
    ...mapState(["translation"]),
    isExchangeFile() {
      return this.$store.getters["pmLocalModules/isExchangeFile"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isAllBankCash() {
      return this.$store.getters["pmLocalModules/isAllBankCash"];
    },
    currentPath() {
      return this.$route.path;
    },

    role() {
      return this.$store.getters["auth/role"]
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isAllCurrency() {
      return this.$store.getters["pmLocalModules/isAllCurrency"];
    },
    currentPage() {
      return this.$store.getters["pmLocalModules/isCurrentPage"];
    },
    isPmlocalSubId() {
      return this.$store.getters["pmLocalModules/isPmlocalSubId"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isForeignEstablishMentID() {
      return this.$store.getters["pmLocalModules/isForeignEstablishMentID"];
    },
    isCurrencyData() {
      return this.$store.getters["pmLocalModules/isCurrencyData"];
    },
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    totalPages() {
      return this.$store.getters["pmLocalModules/isTotalPages"];
    },

    canEditPmLocalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"] && this.canEditPmLocalModule?.includes("Currency Exchange")
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    shouldDisableInput() {
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
    appLang() {
      return this.$store.getters["translation/getLocale"];
    }
  },
  setup() {
    const { t } = useI18n();

    function transText(text) {
      return t(getTransString(text));
    }

    return {
      transText,

    };
  },
  watch: {
    'form.source_currency': function (newSourceCurrency) {
      this.targetCurrencyOptions = this.fetchCurrencies.filter(currency => currency.value !== newSourceCurrency);
    },
    'form.target_currency': function (newTargetCurrency) {
      this.sourceCurrencyOptions = this.fetchCurrencies.filter(currency => currency.value !== newTargetCurrency);
    },
   selectedOption(newValue) {
      if (newValue) {
        this.calculateTotalBalance();
      }
    },
    source_amount(newValue, oldValue) {
      if(newValue < 0){
        this.source_amount = oldValue >= 0 ? oldValue : 0;
      }
      if (this.selectedOption === "Cash") {
        this.isAmountAvailable = newValue > this.totalCashBalance
        this.calculateTotalBalance();
      } else if (this.selectedOption === "Bank") {
        this.isAmountAvailable = newValue > this.totalBankBalance
        this.calculateTotalBalance();
      } 
    },
    target_amount(newValue, oldValue) {
      if(newValue < 0){
        this.target_amount = oldValue >= 0 ? oldValue : 0;
      }
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getLanguageCurrency(amount){
      return this.appLang === "de" ? this.germanCurrency(amount) : amount?.formatCurrency()
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.form).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.form[key] === "" ||
            this.form[key] === undefined ||
            this.form[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },


    getAllCurrencies() {
      Api()
        .get(`${getAllCurrencies}${this.pmLocalID}`)
        .then((res) => {
          this.fetchCurrencies = res.data.data.map((currency) => ({
            text: currency.currency,
            value: currency.id,
          }));
        })

    },
    removeComma(valueWithComma) {
      return valueWithComma.replace(/,/g, "");
    },
    showDialog(data) {
      this.itemId = data?.$event?.id;
      this.modelValue = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "add") {
        this.title = data?.$event?.title;
        this.$refs.dialog.showDialog = true;
        this.source_amount = 0;
        this.form.date = "";
        this.form.source_currency = "";
        this.form.target_currency = "";
        this.form.target_amount = "";
        this.isEdit = false
      } else
        if (data.data === "detail") {

          this.isEdit = true
          this.$refs.dialog.showDialog = true;
          this.form.date = moment(data?.$event?.date).format("YYYY-MM-DD");
          this.form.source_currency = data?.$event?.['source currency'];
          this.source_amount = this.removeComma(data?.$event?.['source amount']);
          this.form.target_currency = data?.$event?.['target currency'];
          this.form.target_amount = this.removeComma(data?.$event?.['target amount']);
          this.form.source_currency = data?.$event?.source_currency_id;
          this.form.target_currency = data?.$event?.target_currency_id;
          this.selectedOption = data?.$event?.['transfer type'];
        }
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.source_amount = 0;
      this.form.date = "";
      this.form.source_currency = "";
      this.form.target_currency = "";
      this.form.target_amount = "";
      this.isEdit = false

    },

    showDelete(data) {
      this.itemId = data?.$event?.id;
      this.modelValue = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "delete") {
        this.title = data?.$event?.title;
        this.deadline = data?.$event?.deadline;
        this.description = data?.$event?.description;
        this.$refs.delete.showDialog = true;
      }
    },
    hideDelete() {
      this.$refs.delete.showDialog = false;


    },

    AddExchange() {
      this.inputFieldsValidation();
      if (this.isAmountAvailable) {
        this.checkAlertEror("Insufficient balance, cannot perform currency transaction")
        return this.isAmountAvailable
      }
      if (this.hasError) return this.hasError;
      if(this.form.target_amount < 0) return this.checkAlertEror("Target amount must be greater than 0")
      const payload = {
        date: this.form.date,
        source_currency: this.form.source_currency,
        source_amount: Number(this.source_amount),
        target_currency: this.form.target_currency,
        target_amount: Number(this.form.target_amount),
        transaction_type: this.selectedOption,
        file_url: this.isExchangeFile[0]?.file_url,
        file_title: this.isExchangeFile[0]?.title
      }
      Api()
        .post(`${AddExchange}${this.isPmlocalSubId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getCurrencyExchange(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    calculateTotalBalance() {
      this.totalCashBalance = 0;
      this.totalBankBalance = 0;
      for (const item of this.balances || []) {
        if (item.currency === this.form.source_currency) {
          if (this.selectedOption === "Cash") {
            this.totalCashBalance += item.cash_balance;
          } else if (this.selectedOption === "Bank") {
            this.totalBankBalance += item.bank_balance;
          }
        }
      }
    },
    editExchange() {
      const payload = {
        date: this.form.date,
        source_currency: this.form.source_currency,
        source_amount: Number(this.source_amount),
        target_currency: this.form.target_currency,
        target_amount: Number(this.form.target_amount),
        transaction_type: this.selectedOption
      }
      Api()
        .put(`${manageExchange}${this.itemId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getCurrencyExchange(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteExchange() {
      Api()
        .delete(`${manageExchange}${this.itemId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.delete.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getCurrencyExchange(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.delete.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    getCurrencyExchange(page) {
      this.apploader = true;
      Api()
        .get(`${getCurrencyTransaction}${this.isPmlocalSubId}/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.apploader = false;
            const data = response.data.data;
            this.balances = response.data.balances;
            this.values = data.map((obj, index) => {
              const formattedDate = moment(obj.date).format(
                "D.M.YYYY"
              );
              return {
                id: obj.id,
                amount: this.getLanguageCurrency(obj.amount),
                currency: obj.transfer_currency_name,
                'transfer type': obj.transaction_type,
                date: `${formattedDate}`,
                "source amount": this.getLanguageCurrency(obj.source_amount),
                "source currency": obj.source_currency_name,
                "target currency": obj.target_currency_name,
                "cash/bank": obj.transaction_type,
                "target amount": this.getLanguageCurrency(obj.target_amount),
                "exchange rate": obj.rate,
                'item no.': index + 1,
                source_currency_id: obj.source_currency,
                target_currency_id: obj.target_currency
              };
            });
            this.$store.commit("pmLocalModules/SET_ALL_CURRENCY_ITEM_TO_STATE", response.data);
            this.$store.commit("pmLocalModules/SET_ALL_CURRENCY_TO_STATE", this.values);
            this.$store.commit(
              "pmLocalModules/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "pmLocalModules/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },

    loadMore() {
      const page = this.currentPages;
      this.getCurrencyExchange(page);
    },
  },
      mounted() {
   
      this.calculateTotalBalance();
     this.getAllCurrencies()
     this.getCurrencyExchange(this.currentPage)
     this.$store.commit("pmLocalModules/SET_EXCHANGE_FILES_TO_STATE", []);
      },
  
    });
  </script>
  
  <style scoped>
  .table {
    width: 100%;
  }
  .input {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    background-color: white;
  }
  /* unselected radio button */
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #d0d5dd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    outline: none;
    margin-right: 0.5rem;
    position: relative;
  }
  
  /* middle fill color */
  input[type="radio"]::before {
    content: "";
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
  }
  
  /* selected radio button */
  input[type="radio"]:checked::before {
    background-color: #8b2923;
    border: 2px solid #8b2923;
    width: 8px;
    height: 8px;
  }
  input[type="radio"]:checked {
    background-color: #f4eae9;
    border: 1px solid #ae6a65;
    border-radius: 50%;
    width: 1.3em;
    height: 1.3em;
    outline: none;
  
    position: relative;
  }
  </style>
  
