<template>
    <div>
      <v-col cols="12" class="">
        <form>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                id="contact_phone"
                :label="$t('contact.phoneNumber')"
                :placeholder="$t('contact.enterPhoneNumber')"
                validation="phoneOnly"
                v-model:modelValue="personalForm.phone_number"
                :disabled="
                isDisabled
                "
                type="text"
                data-cy="contact_number"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="contact_mobile"
                :label="$t('contact.mobileNumber')"
                :placeholder="$t('contact.enterMobileNumber')"
                validation="phoneOnly"
                v-model:modelValue="personalForm.mobile_number"
                :disabled="
                isDisabled
                "
                type="text"
                data-cy="mobile_number"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-3">
            <div class="w-[440px]">
              <app-input-field
                id="emailAddress"
                type="email"
                :label="$t('contact.emailAddress')"
                :placeholder="$t('contact.enterEmailAddress')"
                validation="emailOnly"
                v-model:modelValue="personalForm.email"
                :disabled="
                isDisabled
                "
                data-cy="contact_emailAddress"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="emergencyPhone"
                :label="$t('contact.emergencyPhone')"
                :placeholder="$t('contact.enterEmergencyPhone')"
                type="text"
                validation="phoneOnly"
                v-model:modelValue="personalForm.emergency_phone_number"
                :disabled="isDisabled
                "
                data-cy="contact_emergency"
              ></app-input-field>
            </div>
          </div>
          <div class="flex items-center gap-3 mb-7">
            <input
              type="checkbox"
              class=""
              v-model="personalForm.isCommunication"
              :disabled="isDisabled ||
                personalForm.official_email === ''
              "
              data-cy="contact_isCommunication"
            />
            <label
              for="communicationCheckbox"
              class="text-[#667085] text-[14px]"
              >{{ $t("contact.selectForCommunication") }}</label
            >
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                id="contact_houseNumber"
                :label="$t('contact.houseNumber')"
                :placeholder="$t('contact.enterHouseNumber')"
                v-model:modelValue="personalForm.house_address"
                :disabled="isDisabled||
                  personalForm.official_email === ''
                "
                data-cy="contact_houseNumber"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="contact_addressLine"
                :label="$t('contact.addressLine')"
                :placeholder="$t('contact.enterAddressLine')"
                v-model:modelValue="personalForm.second_address"
                :disabled="
                isDisabled ||
                  personalForm.official_email === ''
                "
                data-cy="contact_addressLine"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                id="contact_postalCode"
                :label="$t('contact.postalCode')"
                :placeholder="$t('contact.enterPostalCode')"
                validation="digitOnly"
                v-model:modelValue="personalForm.postal_code"
                :disabled="
                isDisabled||
                  personalForm.official_email === ''
                "
                data-cy="contact_postalCode"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="contact_City"
                :label="$t('contact.city')"
                :placeholder="$t('contact.enterCity')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.city"
                :disabled="
                isDisabled
                "
                data-cy="contact_City"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-select-field
                :label="$t('contact.country')"
                :labelPlaceholder="$t('contact.selectCountry')"
                class="select-field"
                :options="countryOptions"
                v-model="personalForm.country_of_birth"
                :disabled="
                isDisabled
                "
                id="contact_Country"
                data-cy="contact_Country"
              ></app-select-field>
            </div>
          </div>
  
          <div class="w-[920px]">
            <div
              v-if="
                !isEditEmployee ||
                (currentPath !== '/edit-employee' &&
                  currentPath !== '/edit-expert' &&
                  currentPath !== '/profile')
              "
              class="flex justify-end gap-3 mb-7"
            >
              <div></div>
              <div class="flex gap-3">
                <div>
                  <app-secondary-button
                    @click="previous"
                    :buttonLabel="$t('contact.previous')"
                    id="previous"
                    data-cy="contact_previous"
                  ></app-secondary-button>
                </div>
                <div>
                  <app-primary-button
                    :buttonLabel="$t('contact.next')"
                    @click-event="nextPage"
                    v-show="disableSaveButton()"
                    id="next"
                    data-cy="contact_next"
                  >
                  </app-primary-button>
                </div>
                <div>
                  <app-primary-button
                    :buttonLabel="$t('contact.saveAndContinue')"
                    @click-event="savePersonalToState('continue')"
                    v-show="!disableSaveButton()"
                    id="saveAndContinue"
                    data-cy="contact_saveAndContinue"
                  >
                  </app-primary-button>
                </div>
              </div>
            </div>
            <div v-else class="flex justify-end gap-7">
              <div v-show="isEditButton">
                <app-secondary-button
                  :buttonLabel="$t('personal.cancel')"
                  @click="previous"
                  data-cy="contact_cancel"
                  id="cancel"
                ></app-secondary-button>
              </div>
              <app-primary-button
                v-show="isEditButton"
                :buttonLabel="$t('contact.save')"
                @click-event="savePersonalToState"
                id="save"
                data-cy="contact_save"
              >
              </app-primary-button>
            </div>
          </div>
  
          <app-alert
            :title="$t(getTranslatedStringKey(alertTitle))"
            @close-alert="alertState = false"
            :state="alertState"
            :timeout="5000"
            :type="alertType"
            id="alert"
            data-cy="response_alert"
          />
        </form>
      </v-col>
    </div>
    <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="navigateToNextRoute()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-unsaved-changes-dialog>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import countries from "@/services/country_api";
  import { mapState } from "vuex";
  import Api from "@/services/api";
  import { validEmail } from "@/services/validation";
  import { getTransString } from "@/services/helpers";
  import { isEqual } from "lodash";
  
  export default defineComponent({
    name: "Contact",
    components: {},
    props: {
      handleButtonRoute: {
        type: Function,
      },
    },
    data() {
      return {
        countryOptions: null,
        isEdit: false,
        alertTitle: "",
        alertType: "success",
        alertState: false,
        firstRun: true,
        originalFormData: {
          phone_number: "",
          mobile_number: "",
          house_address: "",
          email: "",
          postal_code: "",
          emergency_phone_number: "",
          isCommunication: false,
          country: "",
          second_address: "",
          city: "",
          user_key: "",
        },
        personalForm: {
          phone_number: "",
          mobile_number: "",
          house_address: "",
          email: "",
          postal_code: "",
          emergency_phone_number: "",
          isCommunication: false,
          country: "",
          second_address: "",
          city: "",
          user_key: "",
        },
      };
    },
    computed: {
        isDisabled(){
        return  this.currentPath === '/edit-foreign-employee' && this.isEditEmployee && !this.isEditButton
      },
      isEditEmployee() {
        return this.$store.getters["hrResources/isEditEmployee"];
      },
      canEditPersonalModule() {
        return this.$store.state.auth.canEditModuleSections;
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"] &&
              this.canEditPersonalModule?.includes("Foreign Staff Contact");
      },
      isCurrentTab() {
        return this.$store.getters["hrResources/isCurrentTab"];
      },
      userKey() {
        return this.$store.getters["hrResources/isUserKey"];
      },
      isPersonalData() {
        return this.$store.getters["hrResources/isPersonalData"];
      },
      currentPath() {
        return this.$route.path;
      },
      isHome() {
        return this.$store.getters["hrResources/isHome"];
      },
      isEmployeeUserKeyFromHome() {
        return this.$store.getters["hrResources/isEmployeeUserKeyFromHome"];
      },
      ...mapState("hrResources", ["personalData"]),
    },
    watch: {
      isPersonalData(newValue) {
        this.populateFieldsWithData(newValue, this.personalForm);
        if (this.firstRun) {
          this.populateFieldsWithData(newValue, this.originalFormData);
          this.firstRun = false;
        }
      },
      personalForm: {
        deep: true,
        handler(value) {
          this.originalFormData.country_of_birth = this.countryOptions[64];
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            Contact: !isEqual(value, this.originalFormData),
          });
        },
      },
    },
    methods: {
      disableSaveButton() {
        const personalFormObj = { ...this.personalForm };
        const excludes = ["user_key", "isCommunication", "country_of_birth"];
        const modifiedObject = Object.keys(personalFormObj).reduce(
          (prev, curr) => {
            if (excludes.includes(curr)) {
              return prev;
            } else {
              prev[curr] = personalFormObj[curr];
              return prev;
            }
          },
          {}
        );
        return Object.values(modifiedObject)?.every((c) => c === "" || c === null);
      },
      nextPage() {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
      },
      previous() {
        const getCurrentTab = this.isCurrentTab - 1;
        this.handleButtonRoute(getCurrentTab);
      },
      getCountryByName() {
        const countryArray = [];
        for (const country of countries) {
          countryArray.push(country.name);
        }
        return countryArray;
      },
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
      savePersonalToState(data) {
        if (this.personalForm.email && !validEmail(this.personalForm.email)) {
          this.alertType = "error";
          this.alertTitle = "Please enter a valid email address.";
          this.alertState = !this.alertState;
          return;
        }
  
        const updatedPayload = {};
        for (const field in this.isPersonalData) {
          if (this.isPersonalData[field] !== this.personalForm[field]) {
            updatedPayload[field] = this.personalForm[field];
          }
        }
  
        updatedPayload.user_key = this.userKey;
        if (this.personalForm.isCommunication) {
          updatedPayload.communication_email = this.personalForm.email;
        }
        
  
        Api()
          .post("/hr/employees/add-personal-info/", {...updatedPayload, employment_type: "Foreign Office Staff",})
          .then((response) => {
            if (response.data.status === "success") {
              this.$store.commit(
                "hrResources/SET_USER_KEY_TO_STATE",
                response.data.data.user_key
              );
  
              this.alertType = "success";
              this.alertTitle = response.data.detail;
              this.alertState = !this.alertState;
  
              this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
                Contact: false,
              });
  
              setTimeout(() => {
                if (this.currentPath === "/add-foreign-employee") {
                  if (data === "continue") {
                    // this.$router.push({ name: "employees" });
                    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
                  }
                }
              }, 1500);
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      },
      getPersonalContactInfo() {
        let newUserKey = this.userKey;
        if (this.isHome) {
          newUserKey = this.isEmployeeUserKeyFromHome;
        } else {
          newUserKey;
        }
        Api()
          .get(`/hr/employees/get-employee-personal-contact-info/${newUserKey}/`)
          .then((response) => {
            if (response.data.status === "success") {
              this.$store.commit(
                "hrResources/SET_PERSONAl_DATA_TO_STATE",
                response.data.data
              );
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      },
      populateFieldsWithData(data, saveTo) {
        saveTo.phone_number = data?.phone_number;
        saveTo.mobile_number = data?.mobile_number;
        saveTo.house_address = data?.house_address;
        saveTo.email = data?.email;
        saveTo.postal_code = data?.postal_code;
        saveTo.emergency_phone_number = data?.emergency_phone_number;
        saveTo.country = data?.country;
        saveTo.second_address = data?.second_address;
        saveTo.city = data?.city;
        saveTo.user_key = this.$store.state.hrResources?.userKey;
        saveTo.isCommunication = false;
      },
    },
    mounted() {
      this.personalForm.country_of_birth = this.countryOptions[64];
      this.personalForm.isCommunication = false;
    },
    created() {
      this.getPersonalContactInfo();
      this.countryOptions = this.getCountryByName();
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 1);
      this.isEdit = this.isEditEmployee;
    },
  });
  </script>
  
  <style scoped>
  .select-field {
    width: 440px;
  }
  
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #d0d5dd;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s;
    position: relative;
  }
  
  input[type="checkbox"]:checked {
    background: #f4eae9;
  }
  
  input[type="checkbox"]:checked::before {
    content: "✔";
    color: #ae6a65;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .disabled {
    /* cursor: not-allowed; */
    color: gray;
    opacity: 0.5;
  }
  </style>
  