import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./sentry";
import "vuetify/styles";
import "@/styles/main.css";
import "@/styles/tailwind.css";
import "@/services/_globals";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import AppAlert from "@/components/shared/AppAlert.vue";
import AppInputField from "@/components/shared/AppInputField.vue";
import AppDates from "@/components/shared/AppDateFields.vue";
import AppPrimaryButton from "@/components/shared/AppPrimaryButton.vue";
import AppSecondaryButton from "@/components/shared/AppSecondaryButton.vue";
import AppSelectField from "@/components/shared/AppSelectField.vue";
import AppSearchField from "@/components/shared/AppSearchField.vue";
import AppTable from "@/components/shared/AppTable.vue";
import AppDialog from "@/components/shared/AppDialog.vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import AppSelectFieldWithObject from "@/components/shared/AppSelectFieldWithObject.vue";
import AppAccessRoleSections from "@/components/shared/AccessRoleSections.vue";
import AppMultiSelect from "@/components/shared/AppMultiSelect.vue";
import createCookies from "vue3-cookies";
import AppSingleSelect from "@/components/shared/AppSingleSelect.vue";
import AppSingleSelectId from "@/components/shared/AppSinglewithId.vue";
import AppAccordion from "@/components/shared/AppAccordion.vue";
import AppFileUpload from "@/components/shared/AppFileUpload.vue";
import AppToggleButton from "@/components/shared/AppToggleButton.vue";
import AppTextEditor from "@/components/shared/AppTextEditor.vue";
import AppPromptAlert from "@/components/shared/AppPromptAlert.vue";
import AppTextArea from "@/components/shared/AppTextArea.vue";
import AppLoader from "@/components/shared/AppLoader.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";
import AppDocumentUpload from "@/components/shared/AppDocumentUpload.vue";
import MultiSelect from "@/components/shared/MultiSelect.vue";
import AppTooltip from "@/components/shared/AppTooltip.vue";
import AppBasicTable from "@/components/shared/AppBasicTable.vue"
import AppCustomPagination from "@/components/shared/AppCustomPagination.vue"
import AppRadio from "@/components/shared/AppRadio.vue";
import AppYearSelect from "./components/shared/AppYearSelect.vue";
import AppMonthSelect from "./components/shared/AppMonthSelect.vue";
import UploadSignature from "./components/shared/UploadSignature.vue";
import SignatureDialog from "./components/shared/SignatureDialog.vue";
import AppCustomTable from "@/components/shared/AppCustomTable.vue";
import AppDatePicker from "@/components/shared/AppDatePicker.vue";
import AppNotification from "@/components/shared/AppNotification.vue";
import UploadDocuments from "@/components/shared/UploadDocuments.vue";
import InvoiceFileUpload from "./components/shared/InvoiceFileUpload.vue";
const app = createApp(App);

app.component("app-alert", AppAlert);
app.component("app-input-field", AppInputField);
app.component("app-date-field", AppDates);
app.component("app-select-field", AppSelectField);
app.component("app-select-field-object", AppSelectFieldWithObject);
app.component("app-search-field", AppSearchField);
app.component("app-table", AppTable);
app.component("app-header", AppHeader);
app.component("app-dialog", AppDialog);
app.component("app-primary-button", AppPrimaryButton);
app.component("app-secondary-button", AppSecondaryButton);
app.component("app-access-role-sections", AppAccessRoleSections);
app.component("app-multi-select", AppMultiSelect);
app.component("app-single-select", AppSingleSelect);
app.component("app-accordion", AppAccordion);
app.component("app-text-editor", AppTextEditor);
app.component("app-file-upload", AppFileUpload);
app.component("app-document-upload", AppDocumentUpload);
app.component("app-toggle-button", AppToggleButton);
app.component("app-single-select-id", AppSingleSelectId);
app.component("app-prompt-alert", AppPromptAlert);
app.component("app-text-area", AppTextArea);
app.component("app-loader", AppLoader);
app.component("app-unsaved-changes-dialog", AppUnsavedChangesDialog);
app.component("multi-select", MultiSelect);
app.component("app-tooltip", AppTooltip);
app.component("app-basic-table", AppBasicTable);
app.component("app-custom-pagination", AppCustomPagination);
app.component("app-radio-button", AppRadio);
app.component("app-year-select", AppYearSelect);
app.component("app-month-select", AppMonthSelect);
app.component("app-date-picker", AppDatePicker);
app.component("app-signature-dialog", SignatureDialog);
app.component("app-custom-table", AppCustomTable);
app.component("app-notification", AppNotification);
app.component("app-upload-signature", UploadSignature);
app.component("app-upload-documents", UploadDocuments);
app.component("app-invoice-file-upload", InvoiceFileUpload);


app
  .use(router)
  .use(createCookies)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .mount("#app");
