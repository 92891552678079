<template>
  <div>
    <app-header
      :name="$t('partners.newPartner')"
      class="mb-5 ml-2"
      :show-arrow-back="true"
    >
    </app-header>
    <div class="mb-10">
      <div>
        <button
          :disabled="!isPartnerId"
          v-for="(tab, index) in tabList"
          :key="index"
          :class="[
            'tab-button',
            { active: currentTab === tabs[index] },
            { disabled: !isPartnerId },
          ]"
          @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component :is="currentTab" v-bind="isCurrentTab" class="pt-10"></component>
    <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="closeDialog"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="discardChanges"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-unsaved-changes-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BasicInformation from "@/components/dashboard/partners/BasicInformation.vue";
import Contact from "@/components/dashboard/partners/Contact.vue";
import Address from "@/components/dashboard/partners/Address.vue";
import BankDetails from "@/components/dashboard/partners/BankingDetails.vue";
import AdditionalInformation from "@/components/dashboard/partners/AdditionalInformation.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";

export default defineComponent({
  name: "AddEmployee",
  components: {
    AppUnsavedChangesDialog,
    BasicInformation,
    Address,
    Contact,
    BankDetails,
    AdditionalInformation,
  },
  data() {
    return {
      currentTab: "Basic Information",
      tabList: [
        "Basic Information",
        "Address",
        "Contact",
        "Bank Details",
        "Additional Information",
      ],
      tabs: [
        "BasicInformation",
        "Address",
        "Contact",
        "BankDetails",
        "AdditionalInformation",
      ],
    };
  },
  computed: {
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    isCurrentTab() {
      return this.$store.getters["partnerModules/isCurrentTab"];
    },
    isPartnerUpdate() {
      return this.$store.getters["partnerModules/isPartnerUpdate"];
    },
  },
  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isPartnerUpdate).some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      if (this.isPartnerUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isPartnerUpdate).reduce(
        (acc, curr) => {
          if (this.isPartnerUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  created() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.currentTab = this.tabs[this.isCurrentTab];
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>
