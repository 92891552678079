<template>
  <div>
    <div class="pt-10">
      <app-table
        :headers="isBankHeaders"
        :isBankDetails="true"
        :items="isAllBankDetails"
        :showCursor="false"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("projectConfiguration.bankDetails") }}</h3>
        </template>
        <template #actions>
          <app-primary-button
            :buttonLabel="$t('projectConfiguration.addBankDetails')"
            @click-event="showDialog({ data: 'add' })"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
  </div>

  <!-- add and update bank details -->
  <app-dialog
    ref="dialogState"
    :title="
      isEdit
        ? $t('projectConfiguration.updateBankTitle')
        : $t('projectConfiguration.addBankTitle')
    "
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('projectConfiguration.bankName')"
        placeholder="Enter bank name"
        v-model.trim="bankFormValues.bankName"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.accountNo')"
        placeholder="Enter account number"
        v-model.trim="bankFormValues.bankAcc"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.accountTitle')"
        placeholder="Enter account title"
        v-model.trim="bankFormValues.bankTitle"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.swiftCode')"
        placeholder="Enter swift code"
        v-model.trim="bankFormValues.bankSwiftCode"
      >
      </app-input-field>
      <app-input-field
        :label="$t('partners.iban')"
        placeholder="Enter iban"
        v-model.trim="bankFormValues.bankIban"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.bankAddress') + '*'"
        placeholder="Enter bank address"
        v-model.trim="bankFormValues.bankAddress"
      >
      </app-input-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit
                ? $t('addcustomrole.saveChanges')
                : $t('projectConfiguration.save')
            "
            @click-event="isEdit ? updateBankDetails() : addBankDetails()"
            :disabled="
              bankFormValues.bankName === '' ||
              bankFormValues.bankAcc === '' ||
              bankFormValues.bankAddress === ''
            "
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <!-- delete bank details -->
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteBankDetails"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import { extractUnderscore, getTransString } from "@/services/helpers";
import api from "@/services/api";

export default defineComponent({
  name: "BankDetails",
  components: {
    Paginator,
  },
  data() {
    return {
      currentPages: 1,
      isEdit: false,
      isDelete: false,
      title: "",
      subTitle: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
      dialogState: false,
      bankID: null,
      bankFormValues: {
        bankName: "",
        bankAcc: "",
        bankTitle: "",
        bankSwiftCode: "",
        bankIban: "",
        bankAddress: "",
      },
      errroMessage: "",
    };
  },
  computed: {
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isBankHeaders() {
      return this.$store.getters["hrResources/isBankHeaders"];
    },
    isAllBankDetails() {
      return this.$store.getters["hrResources/isAllBankDetails"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog(data) {
      if (data.data === "add") {
        this.$refs.dialogState.showDialog = true;
        this.subTitle = "";
      } else if (data.data === "delete") {
        this.bankID = data?.$event.id;
        this.$refs.dialog.showDialog = true;
        this.title = "projectConfiguration.deleteBankDetail";
        this.subTitle = "projectConfiguration.deleteBankDetailsConfirm";
      }
      const newdata = data?.$event;
      if (data.data === "edit") {
        const modifiedData = {};

        for (const key in newdata) {
          const newKey = key.replace(/ /g, "_");
          modifiedData[newKey] = newdata[key];
        }
        this.bankID = data?.$event.id;
        this.subTitle = "";
        this.isEdit = true;
        this.$refs.dialogState.showDialog = true;

        this.bankFormValues.bankName = modifiedData.bank_name;
        this.bankFormValues.bankAcc = modifiedData.account_number;
        this.bankFormValues.bankTitle = modifiedData.account_title;
        this.bankFormValues.bankSwiftCode = modifiedData.swift_code;
        this.bankFormValues.bankIban = modifiedData.iban;
        this.bankFormValues.bankAddress = modifiedData.bank_address;
      }
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      setTimeout(() => {
        this.bankFormValues.bankName = "";
        this.bankFormValues.bankAcc = "";
        this.bankFormValues.bankSwiftCode = "";
        this.bankFormValues.bankTitle = "";
        this.bankFormValues.bankIban = "";
        this.bankFormValues.bankAddress = "";
        this.isEdit = false;
      }, 600);
    },
    getAllBankDetails(page) {
      api()
        .post(`/configuration/projects/all-banks/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;
            const modifiedData = extractUnderscore(data);
            this.$store.commit(
              "hrResources/SET_BANK_DETAILS_TO_STATE",
              modifiedData
            );
            this.$store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    addBankDetails() {
      const payload = {
        bank_name: this.bankFormValues.bankName,
        account_number: this.bankFormValues.bankAcc,
        account_title: this.bankFormValues.bankTitle,
        swift_code: this.bankFormValues.bankSwiftCode,
        iban: this.bankFormValues.bankIban,
        bank_address: this.bankFormValues.bankAddress,
      };
      api()
        .post("/configuration/projects/add-bank/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllBankDetails(this.currentPage);
            this.hideDialog();
          }
        });
    },
    updateBankDetails() {
      const payload = {
        bank_id: this.bankID,
        bank_name: this.bankFormValues.bankName,
        account_number: this.bankFormValues.bankAcc,
        account_title: this.bankFormValues.bankTitle,
        swift_code: this.bankFormValues.bankSwiftCode,
        iban: this.bankFormValues.bankIban,
        bank_address: this.bankFormValues.bankAddress,
      };
      api()
        .post("/configuration/projects/add-bank/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllBankDetails(this.currentPage);
            this.hideDialog();
          }
        });
    },
    deleteBankDetails() {
      api()
        .delete(`/configuration/projects/delete-bank/${this.bankID}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response?.data?.detail;
            this.getAllBankDetails(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.isDelete = !this.isDelete;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.alertTpye = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllBankDetails(page);
    },
  },
  mounted() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 5);
  },
  created() {
    this.getAllBankDetails(this.currentPage);
  },
});
</script>
