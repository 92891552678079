<template>
  <div>
    <!-- <app-header
      :name="$t('pmLocals.title')"
      :show-arrow-back="false"
      class="!text-[16px]"
    /> -->
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchPmLocal"
          :callback="true"
        />
      </v-col>
      <v-col cols="4" class="">
          <app-select-field
            class="px-2"
            :options="countryOptions"
            :label="$t('foreignOffices.country')"
            placeholder="All"
            v-model="country"
          />
        </v-col>
    </v-row>

    <div
      v-if="appLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="tableHeaders"
        :items="allPmLocals"
        :showCheck="false"
        :hideMenu="true"
      >
        <template #title>
          <h3 class="H500">{{ $t("pmLocals.tableTitle") }}</h3>
        </template>
        <template #footer>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </template>
      </app-table>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
// import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import { mapState } from "vuex";
import countries from "@/services/country_api";

import { formatDate }  from "@/services/helpers";
export default defineComponent({
  name: "PmLocalsList",
  components: {
    // AppHeader,
    Paginator,
  },
  data() {
    return {
      currentPages: 1,
      country: ''
    };
  },
  watch: {
   country(val){
    this.filterPmLocal(val)
   }
  },
  computed: {
      allPmLocals() {
      const modifiedData =
        this.$store.getters["pmLocalModules/allPmLocals"];
      return modifiedData?.map((data) => {
        return {
          "id": data.id,
          "office title": data.project_office__place,
          "project title": data.project__project_title,
          "country": data.project_office__country,
          "cost center": data.project__cost_centre__cost_center_number,
          "person on site": data.icon_personnel_on_site?.map((person)=>{
            return{
              text: `${person.first_name}  ${person.last_name}`,
              value: person.id
            }
          }) ,
          "period": data.project__project_end_date === null ? formatDate(data.project__project_start_date):`${formatDate(data.project__project_start_date)} - ${formatDate(data.project__project_end_date)}`
        };
      });
    },
    tableHeaders() {
      return this.$store.getters["pmLocalModules/tableHeaders"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "PM-Local"
      );
      return findModules?.length !== 0;
    },
    currentPage() {
      return this.$store.getters["pmLocalModules/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["pmLocalModules/isTotalPages"];
    },
    countryOptions(){
      return countries?.map((country)=>country.name)
    },
    appLoading() {
      return this.$store.getters["pmLocalModules/appLoading"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  methods: {
      getCountryByName() {
    const countryArray = [];
    for (const country of countries) {
      countryArray.push(country.name);
    }
    return countryArray;
  },
    showDialog(event) {
      this.$refs.deleteOffice.officeId = event.id;
      this.$refs.deleteOffice.showDialog();
    },
    getAllPmLocals(page) {
      const payload = {
        load_page: false,
        page_number: page,
      };
      this.$store.dispatch("pmLocalModules/getAllPmlocals", payload);
    },
    fetchAgain(page) {
      this.getAllPmLocals(page);
    },
    searchPmLocal(search_query) {
      const payload = {
        search_query: search_query,
      };
      this.$store.dispatch("pmLocalModules/searchPmlocals", payload);
    },
    filterPmLocal(filtered_data) {
      const payload =  {
          filters: [{"country": filtered_data}]
    }
      this.$store.dispatch("pmLocalModules/searchPmlocals", payload);
    },
    loadMore() {
      const page = this.currentPages;
      this.getAllPmLocals(page);
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    // this.$store.commit("expenseModules/SET_EDIT_EXPENSE_TO_STATE", false)
    this.$store.commit("projectOverview/SET_ADD_EXPENSE_TO_STATE", true);
    this.$store.commit("chequeModules/SET_ADD_CHEQUE_TO_STATE", false);
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
    this.getAllPmLocals(1);
  },
  mounted(){
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
  }
});
</script>