<template>
  <!-- Qualification -->
  <div class="container">
    <Education />
    <Language />
    <Experience />
    <v-row>
      <v-col cols="12" class="mt-7">
        <div
          v-if="!isEditEmployee && currentPath === '/add-employee'"
          class="flex justify-end gap-3 pr-2 mb-7"
        >
          <div class="flex gap-3">
            <div>
              <app-secondary-button
                @click="previous"
                :buttonLabel="$t('employment.previous')"
              ></app-secondary-button>
            </div>
            <div>
              <app-primary-button
                @click="handleNextClick"
                :buttonLabel="$t('employment.finish')"
                @click-event="handleFinish"
              >
              </app-primary-button>
            </div>
          </div>
        </div>
        <!-- <div v-else class="flex justify-end gap-7">
          <div v-show="isEditButton">
            <app-secondary-button
              :buttonLabel="$t('employment.cancel')"
              @click="handleFinish"
            ></app-secondary-button>
          </div>
          <app-primary-button
            v-show="isEditButton"
            :buttonLabel="$t('employment.saveChanges')"
            @click-event="saveUserKey"
          >
          </app-primary-button>
        </div> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Language from "./Language.vue";
import Experience from "./Experience.vue";
import Education from "./Education.vue";
export default defineComponent({
  name: "Qualification",
  components: {
    Language,
    Experience,
    Education,
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    // isEditButton() {
    //   return this.$store.getters["hrResources/isEditButton"];
    // },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
  },
  methods: {
    saveUserKey() {
      const userKey = localStorage.getItem("userKey");
      this.$store.commit("hrResources/SET_USER_KEY_TO_STATE", userKey);
    },
    previous() {
      const getCurrentTab = this.isCurrentTab - 1;
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", getCurrentTab);
    },
    handleNextClick() {
      if (this.currentPath === "/profile") {
        const getCurrentTab = this.isCurrentTab + 1;
        this.$store.commit(
          "hrResources/SET_CURRENT_TAB_TO_STATE",
          getCurrentTab
        );
      } else {
        this.$router.push({ name: "employees" });
      }
    },
    handleFinish() {
      this.$router.push({ name: "employees" });
    },
  },
  created() {
    if (this.currentPath === "/profile") {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
    } else {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 3);
    }
    // this.$store.commit("hrResources/SET_USER_KEY_TO_STATE", null);
  },
});
</script>

<style scoped>
label {
  color: #344054;
}
.right_section {
  margin-top: 30px;
}
.header {
  padding-top: 10px;
}
.desc {
  width: 300px;
}
.container {
  padding-bottom: 30px;
}
</style>
