<template>
  <v-sheet max-width="1125" class=" mt-5 bg-transparent">
    <!-- conditions: if currentFlightRequestId and current path === edit or profile-edit or profile add -->
    <v-row v-if="currentFlightRequestId">
      <v-col cols="10" class="justify-end d-flex !mr-16">
        <span >
          <app-primary-button
            :buttonLabel="$t('hospitalityPackage.previewAndSign')"
            @click="previewAndSign()"
            v-if="!shouldDisableInput"
          >
          </app-primary-button>
        </span>
      </v-col>
    </v-row>
    <signature-form ref="signatureForm"></signature-form>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
// import AddFlight from "@/components/dashboard/accounting/flight/AddFlight.vue";
import SignatureForm from "@/components/dashboard/profile/flight-requests/SignatureForm.vue";
export default defineComponent({
  name: "PreviewAndSign",
  components: {

    SignatureForm,
  },
  data() {
    return {};
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    currentFlightRequestId() {
      return this.$store.getters["flightModule/isEditFlightId"];
    },
    shouldDisableInput() {
      return (
        this.currentPath === "/edit-flight" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
  },
  methods: {
    previewAndSign() {
      this.$refs.signatureForm.showSignatureDialog();
    },
  },
  mounted() {},
});
</script>
