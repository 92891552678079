<template>
  <div>
    <v-col cols="12">
      <div class="leave_stats">
        <h3 class="H600">{{ $t("calender.totalLeaves") }}</h3>
        <div class="flex items-center justify-start">
          <div>
            <div class="h-[310px] pr-4">
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">
                    {{ $t("calender.remaingLeave") }} {{ previousYear }}
                  </h3>
                  <p class="py-1 P300">
                    {{ $t("calender.tobeUsed") }}
                    {{ carrryOverEndDate }}
                  </p>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ carryOverLeaveDays }}
                  </p>
                </div>
              </div>
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">{{ $t("calender.totalLeaveleft") }}</h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ totalLeaveDaysLeft }}
                  </p>
                </div>
              </div>
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">
                    {{ $t("calender.holidayEntitlement") }}
                    {{ currentYearAndMonth }}
                  </h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ holidayEntitlement }}
                  </p>
                </div>
              </div>
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">{{ $t("calender.totalEntitlement") }}</h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ totalHolidays }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="h-[310px] py-2 px-4">
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">{{ $t("calender.annualRequested") }}</h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ annuaLeaveRequested }}
                  </p>
                </div>
              </div>
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">{{ $t("calender.alreadydeducted") }}</h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ totalLeaveTaken }}
                  </p>
                </div>
              </div>
              <!-- <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">{{ $t("calender.specialRequested") }}</h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ totalSpecialLeaveRequested }}
                  </p>
                </div>
              </div> -->
                <div class="leave_card SPC-MT-200 py-1">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalDaysOfSpecial") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalSpecialLeave }}
                </p>
              </div>
            </div>
              <div class="leave_card SPC-MT-200">
                <div class="left">
                  <h3 class="H400">
                    {{ $t("leaves.deductedLeaves") }} {{ currentYearAndMonth }}
                  </h3>
                </div>
                <div class="right">
                  <p class="P300 py-1">
                    {{ deductedOrExtra }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="h-[310px] px-4" v-if="ishr || role">
            <!-- <div class="px-6 leave_card SPC-MT-200 py-1">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalDaysOfSpecial") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalSpecialLeave }}
                </p>
              </div>
            </div> -->

            <div class="px-6 leave_card SPC-MT-200">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalUnpaid") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalUnpaidLeave }}
                </p>
              </div>
            </div>
            <div class="px-6 leave_card SPC-MT-200">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalSick") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalSickLeave }}
                </p>
              </div>
            </div>
            <div class="px-6 leave_card SPC-MT-200">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalChild") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalChildSickLeave }}
                </p>
              </div>
            </div>
            <div class="px-6 leave_card SPC-MT-200">
              <div class="left">
                <h3 class="H400">{{ $t("calender.totalKcare") }}</h3>
              </div>
              <div class="right">
                <p class="py-1 P300">
                  {{ totalKcareLeave }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Leave Statistics",

  props: {
    previousYear: {
      type: String,
    },
    carrryOverEndDate: {
      type: String,
    },
    carryOverLeaveDays: {
      type: String,
    },
    totalLeaveDaysLeft: {
      type: String,
    },
    currentYearAndMonth: {
      type: String,
    },
    holidayEntitlement: {
      type: String,
    },

    totalHolidays: {
      type: String,
    },
    annuaLeaveRequested: {
      type: String,
    },
    totalLeaveTaken: {
      type: String,
    },
    totalSpecialLeaveRequested: {
      type: String,
    },
    totalSpecialLeave: {
      type: String,
    },
    totalUnpaidLeave: {
      type: String,
    },
    totalSickLeave: {
      type: String,
    },
    totalChildSickLeave: {
      type: String,
    },
    totalKcareLeave: {
      type: String,
    },
    deductedOrExtra: {
      type: String,
    },
  },
  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    ishr() {
      return this.$store.getters["auth/ishr"];
    },
  },
});
</script>
<style scoped>
.leave_card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  width: 399px;
}
</style>
