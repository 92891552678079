<template>
  <div>
    <app-header :name="$t(`${data.expert_name}`)" :show-arrow-back="true">
      <template #action>
<!--        <v-row v-if="!isContractApprover" class="flex items-center ">-->
<!--          <p class="pr-2 cursor-pointer P300" @click="pushToProject()">Contract Forms</p>-->
<!--          <app-secondary-button :disabled="data.approval_status==='Approved'"-->
<!--                                :buttonLabel="-->
<!--              isEdditingContract ? $t('Cancel') : $t('Edit Contract Document ')-->
<!--            "-->
<!--                                @click-event="-->
<!--              isEdditingContract ? notEdditingContract() : edditingContract()-->
<!--            "-->
<!--          >-->
<!--          </app-secondary-button>-->
<!--          <app-primary-button-->
<!--              class="px-2"-->
<!--              :buttonLabel="-->
<!--              isEdditingContract ? $t('Save Changes') : $t('Send Contract to Expert')-->
<!--            "-->
<!--              @click="-->
<!--              isEdditingContract-->
<!--                ? saveDocument()-->
<!--                : showMailDialog({ data: 'add' })-->
<!--            "-->
<!--          >-->
<!--          </app-primary-button>-->
<!--        </v-row>-->
<!--        <v-row v-if="isContractApprover" class="items-center justify-center">-->
<!--          <h3-->
<!--              v-if="!isEdditingContract"-->
<!--              :class="['pr-4', 'cursor-pointer', { 'pointer-events-none':data.approval_status==='Approved' }]"-->
<!--              @click="-->
<!--              isEdditingContract ? notEdditingContract() : edditingContract()-->
<!--            "-->
<!--          >-->
<!--            {{ isEdditingContract ? "Cancel" : "Edit Contract Document" }}-->
<!--          </h3>-->
<!--          <app-secondary-button-->
<!--              class="mr-2"-->
<!--              :buttonLabel="isEdditingContract ? $t('Cancel') : $t('Disapprove')"-->
<!--              @click-event="-->
<!--              isEdditingContract-->
<!--                ? notEdditingContract()-->
<!--                : showDisApproveDialog({ data: 'add' })-->
<!--            "-->
<!--          >-->
<!--          </app-secondary-button>-->
<!--          <app-primary-button :disabled="data.approval_status==='Approved'"-->
<!--                              :buttonLabel="-->
<!--              isEdditingContract ? $t('Save Changes') : $t('Approve')-->
<!--            "-->
<!--                              @click-event="isEdditingContract ? saveDocument() : Approval()"-->
<!--          >-->
<!--          </app-primary-button>-->
<!--        </v-row>-->
      </template>
    </app-header>
    <div
        class="flex flex-col w-full pb-8 pages-container"
        v-if="!isEdditingContract"
    >
      <div
          v-for="(pageContent, index) in splitContent"
          :key="index"
          class="mt-10 pages"
      >
        <div v-if="index === 0">
          <v-col align="center" justify="center" class="">
            <img class="mb-4" src="../../../assets/logo.png" alt=""/>
            <h1 class="mb-1 H600">{{ $t("ICON-INSTITUTE") }}</h1>
            <p class="P300">{{ $t("CONTRACT") }}</p>
            <!-- <h3 class="text-contract-header">Between ICON-INSTITUT Sustain GmbH, Von-Groote-Straße 28, 50968 Köln
              Hereinafter named ICON and {{data.expert_name}}, Netherlands, the following contracts will be concluded:
            </h3> -->
          </v-col>
        </div>
        <div class="mb-4 content-area">
          <div class="content-container">
            <div v-html="pageContent"></div>
          </div>
        </div>
        <div class="" v-if="index === splitContent.length - 1">
          <div class="py">
            <div class="flex justify-between company-innetials">
              <div class="names">
                <h3 class="H500">Köln, the {{ getCurrentDate() }}</h3>
                <p class="P300">ICON-INSTITUT GmbH, Köln</p>
              </div>
            </div>
          </div>
          <div></div>
          <!-- <div v-if="showsignatureDialog" class="flex items-center justify-center ">
            <h5 class="px-2 text-[#8B2923]">Contract has not been approve yet</h5>
            <v-icon
            @click="closeErrorDialog()"
            icon="custom:redClose"
            class="icon"
            color="red"
          ></v-icon>
          </div> -->

          <div class="flex items-center justify-between pt-10 sign-input">
            <div>
              <img alt="ceo signature"
                   v-if="data.ceo_signature"
                   :src="data.ceo_signature"
                   class="h-[30px] w-full object-cover"
              />
              <p
                  v-if="!data.ceo_signature && isCeo"
                  class="text-[#8B2923] cursor-pointer"
                  @click="addCeo()"
              >
                Click to sign here
              </p>
              <div class="px-2 signature-line"></div>
              <h3 class="H500">{{ data.ceo_name }}</h3>
            </div>
            <div>
              <img alt="pm signature"
                   v-if="data.project_manager_signature"
                   :src="data.project_manager_signature"
                   class="h-[30px] w-full object-cover"
              />
              <p
                  v-if="!data.project_manager_signature && data.project_manager_name===isfullName"
                  class="text-[#8B2923] cursor-pointer"
                  @click="addPm()"
              >
                Click to sign here
              </p>
              <div class="px-2 signature-line"></div>
              <h3 class="H500">{{ data.project_manager_name }}</h3>
            </div>
            <div>
              <img alt="expert signature"
                   v-if="data.expert_signature"
                   :src="data.expert_signature"
                   class="h-[30px] w-full object-cover"
              />
              <p
                  v-if="!data.expert_signature && data.expert_name===isfullName"
                  :class="['text-[#8B2923] cursor-pointer', 'pointer-events-none opacity-60' && loading]"
                  @click="addExpert()"
              >
                {{ loading ? "Signing document..." : "Click to sign here" }}
              </p>
              <div class="px-2 signature-line"></div>
              <h3 class="H500">{{ data.expert_name }}</h3>
            </div>
          </div>
        </div>

        <div class="py-10 pb-10 annexes" v-if="index === splitContent.length - 1">
          <h2 class="H600">Annexes:</h2>
          <h3 class="H400">- Terms of Reference (Consultant)</h3>
          <h3 class="H400">
            - Mission Plan 1 (as the dates of the Mission will only be specified
            in the course of the Contract Duration, the Mission Plan still
            missing will be handed over at a later stage)
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div v-if="isEdditingContract">
      <div></div>
      <div class="mb-8 pages">
        <div>
          <v-col align="center" justify="center" class="center">
            <img class="mb-4" src="../../../assets/logo.png" alt=""/>
            <h1 class="mb-1 H600">{{ $t("ICON-INSTITUTE") }}</h1>
            <p class="P300">{{ $t("CONTRACT") }}</p>
          </v-col>
        </div>
        <QuillEditor
            contentType="html"
            v-model:content="documentData"
            :options="options"
            :style="editorStyles"
        />
      </div>
    </div>
  </div>
  <app-dialog ref="dialog" :title="$t('No Signature Found!')" :subtitle="``">
    <template #content>
      <!-- <ContractPreviewFile></ContractPreviewFile> -->
      <p class="P300">You do not have any uploaded signature yet. Would you like to add your signature from your
        profile?</p>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
              :buttonLabel="$t('projects.cancel')"
              @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
              :buttonLabel="$t('Yes')"
              :loading="loading"
              @click-event="pushToSignature()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-dialog
      ref="dialogDisApprove"
      width="600"
      :title="$t('Disapprove Contract')"
      :subtitle="``"
  >
    <template #content>
      <DisappovalComment
          @disapproved="hideDisApproveDialog"
      ></DisappovalComment>
    </template>
  </app-dialog>
  <app-dialog
      ref="mailDialog"
      width="550"
      :title="$t('Send Mail to Expert')"
      :subtitle="``"
  >
    <template #content>
      <ExpertEmail @hide-dialog="hideMailDialog()"/>
    </template>
  </app-dialog>
  <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
  />
</template>
<script>
import {QuillEditor} from "@vueup/vue-quill";
import API from "@/services/api";
import {mapGetters} from 'vuex';
import documentData from "@/services/contractDocument.js";
import documentDataDe from "@/services/contractDocumentDe.js"
import {
  getAllProjectContracts,
  ceoSignsture,
  pmSign,
  expertSign,
  previewApproval,
  saveContractDocument,
  getDocument,
  getEmployeeSignature,
} from "@/services/endpoints";
// import ContractPreviewFile from "@/components/dashboard/projects/contracts/contract-preview/ContractPreviewFile.vue";
import DisappovalComment from "@/components/dashboard/projects/contracts/contract-preview/DisapprovalComment.vue";
import ExpertEmail from "@/components/dashboard/projects/contracts/contract-preview/ExpertEmail.vue";
import {getTransString} from "@/services/helpers";

export default {
  name: "contract",
  components: {
    QuillEditor,
    // ContractPreviewFile,
    DisappovalComment,
    ExpertEmail,
  },
  data() {
    return {
      options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ["blockquote"],
            ["link"],
            [{list: "ordered"}, {list: "bullet"}],
          ],
        },
        placeholder: "Enter a description",
      },
      editorStyles: {
        minHeight: "990px",
        padding: "10px 14px",
        fontSize: "12px",
        backgroundColor: "#fff",
        color: "#344054",
        lineHeight: "1.5",
        border: "1px solid #ddd",
        borderRadius: "4px",
        textIndent: "-10px",
      },
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      firstRun: true,
      addTitle: "",
      documentData,
      documentDataDe,
      data: [],
      comment: "",
      signatureEndpoint: "",
      ceo: "",
      pm: "",
      expert: "",
      showsignatureDialog: false,
      imageUrl: null,
    };
  },
  computed: {
    ...mapGetters("translation", ["getLocale"]),
    isContractEditId() {
      return this.$store.getters["contractModules/isContractEditId"];
    },
    isContractApprover() {
      return this.$store.getters["auth/isContractApprover"];
    },
    isCeo() {
      return this.$store.getters["auth/isCeo"];
    },
    isfullName() {
      return this.$store.getters["auth/isfullName"];
    },
    userHasSigned() {
      return this.$store.getters["contractModules/userHasSigned"];
    },
    isContractType() {
      return this.$store.getters["contractModules/isContractType"];
    },
    isEdditingContract() {
      return this.$store.getters["contractModules/isEdditingContract"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isSignatureFile() {
      return this.$store.getters["contractModules/isSignatureFile"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },

    splitContent() {
      const maxLinesPerPage = 48;
      const modifiedContent = this.documentData.replace(/<br>/g, "\n");
      const lines = modifiedContent.split("\n");
      const pages = [];
      let currentPageLines = [];
      let lineCount = 0;

      for (let i = 0; i < lines.length; i++) {
        let currentLine = lines[i];
        currentPageLines.push(currentLine);
        lineCount++;

        if (lineCount >= maxLinesPerPage || i === lines.length - 1) {
          pages.push(currentPageLines.join("\n"));
          currentPageLines = [];
          lineCount = 0;
        }
      }
      return pages;
    },
  },
  methods: {
    notEdditingContract() {
      this.$store.commit(
          "contractModules/SET_EDDITING_CONTRACT_TO_STATE",
          false
      );
    },
    edditingContract() {
      this.$store.commit(
          "contractModules/SET_EDDITING_CONTRACT_TO_STATE",
          true
      );
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    updateDocumentWithFetchedData() {
      this.documentData = this.documentData.replaceAll(
          "[expert name]",
          this.data.expert_name || "[EXPERT NAME]"
      );
      this.documentData = this.documentData.replaceAll(
          "[contract ID]",
          this.data.id || "[CONTRACT NUMBER]"
      );
      this.documentData = this.documentData.replaceAll(
          "[function]",
          this.data.function || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[fees]",
          this.data.fees_per_day || this.data.month_fees || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[projectName]",
          this.isCurrentProject.project_title || "N/A"
      );
      this.documentData = this.documentData.replaceAll(
          "[tor]",
          this.data.tor || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[mission]",
          this.data.has_mission || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[integral]",
          this.data.has_mission || this.data.tor || this.data.timesheet_signee.length || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[timesheet]",
          this.data.timesheet_signee.length || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[project_id]",
          this.isCurrentProject.id || "N/A"
      );
      this.documentData = this.documentData.replaceAll(
          "[contracting authority from the project creation form]",
          this.isCurrentProject.client__company_name || "N/A"
      );
      this.documentData = this.documentData.replaceAll(
          "[EUR]",
          this.isCurrentProject.currency || "N/A"
      );
      this.documentData = this.documentData.replaceAll(
          "[language]",
          this.data.contract_language || "N/A"
      );
      this.documentData = this.documentData.replaceAll(
          "[contract start date]",
          this.data.contract_start_date || "[start date]"
      );
      this.documentData = this.documentData.replaceAll(
          "[contract end date]",
          this.data.contract_end_date || "[end date]"
      );
      this.documentData = this.documentData.replaceAll(
          "[performance]",
          this.data.travel.length || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[country to where the expect will travel to]",
          this.data.travel.length || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[only show if the contract involves travel]",
          this.data.travel.length || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
          "[individual deliverable title]",
          this.data.deliverables.length || "[yes]"
      );
      if (Array.isArray(this.data.travel)) {
        this.data?.travel?.forEach(travelItem => {
          this.documentData = this.documentData.replaceAll(
              "[country to where the expect will travel to]",
              travelItem?.countries || "[yes]"
          );
        });
      } else {
        this.documentData = this.documentData.replaceAll(
            "[performance]", "[yes]"
        );
        this.documentData = this.documentData.replaceAll(
            "[performance]", "[yes]"
        );
      }
      if (Array.isArray(this.data?.deliverables)) {
        this.data.deliverables.forEach(deliverablesItem => {
          this.documentData = this.documentData.replaceAll(
              "[individual deliverable title]",
              deliverablesItem?.title || "[yes]"
          );
          this.documentData = this.documentData.replaceAll(
              "[fixed price per month/day]",
              deliverablesItem?.amount || "[yes]"
          );
        });
      } else {
        this.documentData = this.documentData.replaceAll(
            "[fixed price per month/day]",
            this.data.deliverables?.amount || "[yes]"
        );
        this.documentData = this.documentData.replaceAll(
            "[individual deliverable title]",
            this.data.deliverables?.title || "[yes]"
        );
      }
      this.formatHeaders();
      this.documentData = this.hideLinesWithYes(this.documentData);
    },

    linesTillNextH2(linesSlice) {
      let lineCounter = 0;
      for (let line of linesSlice) {
        if (line.includes("<h2>")) break;
        lineCounter++;
      }
      return lineCounter;
    },
    hideLinesWithYes(text) {
      const lines = text.split('\n');
      const filteredLines = lines.filter(line => !/\[yes\]/i.test(line));
      return filteredLines.join('\n');
    },
    formatHeaders() {
      let currentH2Number = 0;
      let currentH3Number = 0;
      this.documentData = this.documentData.replace(
          /<h[23]>(.*?)<\/h[23]>/g,
          (match, content) => {
            const trimmedContent = content.trim();

            if (trimmedContent.startsWith("-")) {
              return match; // Return original match without numbering
            }
            if (trimmedContent.startsWith("*")) {
              return match.replace("*", "").trim(); // Remove the * and return without numbering
            }
            if (match.includes("<h2>")) {
              // Check if the line contains [yes] before incrementing H2 number
              if (!/\[yes\]/i.test(match)) {
                currentH2Number += 1;
              }
              currentH3Number = 0; // reset H3 numbering
              return `<h2>${currentH2Number}. ${trimmedContent}</h2>`;
            } else if (match.includes("<h3>")) {
              // Check if the line contains [yes] before incrementing H3 number
              if (!/\[yes\]/i.test(match)) {
                currentH3Number += 1;
              }
              return `<h3>${currentH2Number}.${currentH3Number} ${trimmedContent}</h3>`;
            }
          }
      );
    },

    pushToProject() {
      this.$router.push({name: "editProject"});
      this.$store.commit(
          "contractModules/SET_PREVIEW_PROFILE_TO_STATE",
          true
      );
    },
    closeErrorDialog() {
      this.showsignatureDialog = false
    },
    showDialog(data) {
      // if(data.approval_status !=="Approved"){
      //   return this.showsignatureDialog=true
      // }
      this.title = "Add Signature";
      if (data.data === "addceo") {
        this.$store.commit("contractModules/SET_SIGNATURE_FILES_TO_STATE", []);
        this.$refs.dialog.showDialog = true;
        this.signatureEndpoint = ceoSignsture; // make sure 'ceoSignsture' is defined
        this.isEdit = false;
        this.ceo = "ceo"
      } else if (data.data === "addpm") {
        this.$refs.dialog.showDialog = true;
        this.$store.commit("contractModules/SET_SIGNATURE_FILES_TO_STATE", []);
        this.signatureEndpoint = pmSign;
        this.pm = "pm"
      } else if (data.data === "addexpert") {
        this.$store.commit("contractModules/SET_SIGNATURE_FILES_TO_STATE", []);
        this.$refs.dialog.showDialog = true;
        this.signatureEndpoint = expertSign;
        this.expert = "expert"
      }
    },
    addCeo() {
      if (!this.imageUrl) {
        this.$refs.dialog.showDialog = true;
      } else {
        this.ceo = "ceo"
        this.signatureEndpoint = ceoSignsture
        this.addCeoSignature()
      }

    },
    addPm() {
      if (!this.imageUrl) {
        this.$refs.dialog.showDialog = true;
      } else {
        this.pm = "pm"
        this.signatureEndpoint = pmSign;
        this.addCeoSignature()
      }

    },
    addExpert() {
      if (!this.imageUrl) {
        this.$refs.dialog.showDialog = true;
      } else {
        this.expert = "expert"
        this.signatureEndpoint = expertSign;
        this.addCeoSignature()
      }

    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
    },
    addSignature() {
      this.getSignature()
    },
    showDisApproveDialog(data) {
      this.subTitle = "";
      this.title = "Disapproved Contract";
      if (data.data === "add") {
        this.$refs.dialogDisApprove.showDialog = true;
        this.isEdit = false;
      } else if (data.data === "detail") {
        this.$refs.dialogDisApprove.showDialog = true;
      }
    },
    hideDisApproveDialog() {
      this.$refs.dialogDisApprove.showDialog = false;
    },
    // send mail dialog
    showMailDialog(data) {
      this.subTitle = "";

      this.title = "Send Mail to Expert";
      if (data.data === "add") {
        this.$refs.mailDialog.showDialog = true;
        this.isEdit = false;
      } else if (data.data === "detail") {
        this.$refs.mailDialog.showDialog = true;
      }
    },
    hideMailDialog() {
      this.$refs.mailDialog.showDialog = false;
    },
    // approval
    // Contract approval
    Approval() {
      const payload = {
        contract_id: this.isContractEditId,
        contract_type: this.isContractType,
        approval_status: "Approved"
      };
      this.loading = true;
      API()
          .post(previewApproval, payload)
          .then((res) => {
            if (res.data.status === "success") {
              this.alertType = "success";
              this.alertTitle = res.data.detail;
              this.alertState = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            this.alertType = "error";
            this.alertTitle = err.response.data.detail;
            this.alertState = true;
            this.loading = false;
          });
    },
    // CEO SIGNATURE
    addCeoSignature() {
      let payload = {
        contract_id: this.isContractEditId,
        contract_type: this.isContractType,
      };
      if (this.ceo === "ceo") {
        payload.ceo_signature = this.imageUrl
      } else if (this.pm === "pm") {
        payload.project_manager_signature = this.imageUrl
      } else if (this.expert === "expert") {
        payload.expert_signature = this.imageUrl
      }
      API()
          .post(this.signatureEndpoint, payload)
          .then((res) => {
            if (res.data.status === "success") {
              this.alertType = "success";
              this.alertTitle = res.data.detail;
              this.alertState = true;
              this.loading = false;
              this.hideDialog();
              this.getAllpreFixData()
            }
          })
          .catch((err) => {
            this.alertType = "error";
            this.alertTitle = err.response.data.detail;
            this.alertState = true;
            this.loading = false;
          });
    },
    saveDocument() {
      const payload = {
        contract_document: this.documentData,
      };
      this.loading = true;
      API()
          .post(
              `${saveContractDocument}${this.isContractType}/${this.isContractEditId}/`,
              payload
          )
          .then((res) => {
            if (res.data.status === "success") {
              this.alertType = "success";
              this.alertTitle = res.data.detail;
              this.alertState = true;
              this.loading = false;
              this.hideDialog();
              this.notEdditingContract();
              this.getDocument();
            }
          })
          .catch((err) => {
            this.alertType = "error";
            this.alertTitle = err.response.data.detail;
            this.alertState = true;
            this.loading = false;
          });
    },
    getAllpreFixData() {
      API()
          .get(
              `${getAllProjectContracts}${this.isContractType}/${this.isContractEditId}/`
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.data = response.data.data;
              this.updateDocumentWithFetchedData();
            }
          })
          .catch();
    },
    // get document data
    getDocument() {
      API()
          .get(`${getDocument}${this.isContractType}/${this.isContractEditId}/`)
          .then((response) => {
            if (response.data.status === "success") {
              if (response.data.data) {
                this.documentData = response.data.data;
                this.updateDocumentWithFetchedData();
              }
            }
          })
          .catch();
    },
    getSignature() {
      API()
          .get(`${getEmployeeSignature}${this.isPersonalKey}/`)
          .then((response) => {
            if (response.data.status === "success") {
              this.imageUrl = response.data.data.signature;
            }
          });
    },
    pushToSignature() {
      this.$router.push({name: "profile"});
      this.$store.commit(
          "contractModules/SET_PREVIEW_PROFILE_TO_STATE",
          true
      );
    },
    getCurrentDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 since months are 0-indexed
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },
  watch: {
    getLocale() {
      if (this.getLocale === "de") {
        this.documentData = this.documentDataDe
        this.getAllpreFixData();
        this.getDocument();
        this.formatHeaders();
      } else {
        this.documentData = documentData
        this.getAllpreFixData();
        this.getDocument();
        this.formatHeaders();
      }
    }
  },
  mounted() {
    this.getSignature()
    this.$store.commit(
        "contractModules/SET_PREVIEW_PROFILE_TO_STATE",
        false
    );
    this.getAllpreFixData();
    this.getDocument();

    this.$store.commit("contractModules/SET_EDDITING_CONTRACT_TO_STATE", false);
  },
};
</script>
<style>
.content-area h2 {
  color: var(--gray-cool-700, #404968);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
  margin-left: -15px;
  line-height: 20px;
  padding-top: 10px;
  text-indent: -10px;
}

.text-contract-header {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 13px;
  margin: 1;
}

.content-area h3 {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 13px;
  margin: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem; /* 150% */
  text-indent: -10px

}

.content-area h4 {
  color: var(--gray-cool-700, #404968);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-top: 10px;
  text-indent: -20px;
}

.annexes > h2 {
  color: var(--gray-cool-700, #404968);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
  margin-left: -15px;
  line-height: 20px;
  padding-top: 10px;
}

.annexes h3 {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 13px;
  margin: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem; /* 150% */
  text-indent: -10px;
}

.content-area h5 {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 13px;
  margin: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem; /* 150% */
}

.content-container {
  padding-bottom: 50px;
}

.content-container p {
  /* Style for paragraphs */

  font-size: 16px;
}

.content-container ul {
  /* Style for unordered lists */
  list-style-type: disc;
  padding-left: 2em;
}

.content-container ol {
  /* Style for ordered lists */
  list-style-type: decimal;
  padding-left: 2em;
}

.pages-container {
  display: flex;
  flex-direction: column;
}

.italic {
  font-style: italic;
}

.selected-style {
  background-color: #ccc;
}

.pages {
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 0px 5px 4px rgba(252, 253, 255, 0.04),
  0px 1px 12px 0px rgba(167, 169, 173, 0.05);
  width: 800px;
  min-height: 1122px;
  margin: 10px;
  padding: 40px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;

}

.page:not(:last-child)::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;

}

.signature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto; /* Push to the bottom */
}

.signature,
.date {
  margin-bottom: 10px; /* or adjust as needed */
}

.signature-line,
.date-line {
  border-bottom: 1px dotted gray;
  width: 200px; /* or adjust as needed */
  text-align: center;
}
</style>
