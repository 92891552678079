<template >
  <div>
    <v-sheet  class="pb-12">
      <AverageExchangeRate class="mt" ></AverageExchangeRate>
  </v-sheet>
  </div>
</template>
<script>
import AverageExchangeRate from "./AverageExchangeRate.vue";
export default {
  components: {
    AverageExchangeRate,
  },
}
</script>
<style scoped>
  
</style>