<script>
import {defineComponent} from 'vue'
import {getYearsList, monthsNameWithNumber} from '@/services/helpers'
import ProjectHoursTable from "@/components/dashboard/profile/timesheet/ProjectHoursTable.vue";
import ViewDetailsModal from "@/components/dashboard/profile/timesheet/ViewDetailsModal.vue";
import Api from "@/services/api";
import {getAllProjectHours, lockAndUnlockProjectHours} from "@/services/endpoints";

export default defineComponent({
  name: "ProjectHours",
  components: {ProjectHoursTable, ViewDetailsModal},
  props: {
    tabId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableHeaders: [
        {key: 'year', label: 'Year'},
        {key: 'month', label: 'Month'},
        {key: 'total_hours', label: 'Total Hours'},
        {key: 'status', label: 'Status'}
      ],
      allProjectHoursData: {},
      currentPage: 1,
      filters: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      prevProjectFilters: {
        year: "all",
        month: "all",
        status: "all"
      },
      monthsNameWithNumber,
      alertState: {
        show: false,
        message: "",
        type: ""
      }
    }
  },
  computed: {
    getPreviousProjectFiltersOptions() {
      return (filter) => {
        if (filter === 'Year') {
          return [{value: 'all', text: 'All'}, ...getYearsList(2000)]
        } else if (filter === 'Month') {
          return [{value: 'all', text: 'All'}, ...monthsNameWithNumber]
        } else if (filter === 'Status') {
          return [{value: 'all', text: 'All'}, {value: 'Locked', text: 'Locked'}, {value: 'Unlocked', text: 'Unlocked'}]
        }
      }
    },
    user_key() {
      if(this.$route.name === 'editEmployee' || this.$route.name === 'editExpert') {
        return this.$store.getters["hrResources/isUserKey"];
      }
      return this.$store.getters["auth/isPersonalKey"];
    },
    isAdmin() {
      return this.$store.getters["auth/role"];
    },
    dropdownOptions() {
      return (status) => {
        if(this.isAdmin) {
          return status === "Locked" ? ["View Details", "Unlock"] : ["View Details", "Lock"];
        }
        return ["View Details"]
      }
    }
  },
  methods: {
    getYearsList,
    lockAndUnlock({year, month}) {
      Api()
          .put(lockAndUnlockProjectHours, {
            user_key: this.user_key,
            year,
            month
          })
          .then((response) => {
            this.alertState.message = response.data.detail;
            this.alertState.type = "success";
          })
          .catch((error) => {
            this.alertState.message = error.response.data.detail;
            this.alertState.type = "error";
          })
          .finally(() => {
            this.getProjectHours();
            this.alertState.show = true;

            setTimeout(() => {
              this.alertState.show = false;
            }, 3000);
          })
      ;
    },
    getProjectHours() {
      Api()
          .post(`${getAllProjectHours}${this.currentPage}/`, {
            user_key: this.user_key,
            filter: Object.keys(this.prevProjectFilters).reduce((acc, key) => {
              if (this.prevProjectFilters[key] !== 'all') {
                acc[key] = this.prevProjectFilters[key];
              }
              return acc;
            }, {})
          })
          .then((response) => {
            this.allProjectHoursData = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    handleMenuClick(item) {
      if (item.option === "View Details") {
        this.$store.commit("projectHours/setViewTimesheetMonth", item.month)
        this.$store.commit("projectHours/setViewTimesheetYear", item.year)
        this.$refs.viewDetailsModal.showModal(item.status === "Locked");
        return;
      }
      const month = monthsNameWithNumber.find(month => month.text === item.month).value;
      const year = item.year;
      this.lockAndUnlock({year, month});
    }
  },
  mounted() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.getProjectHours();
  },
  watch: {
    prevProjectFilters: {
      handler() {
        this.getProjectHours();
      },
      deep: true
    }
  }
})
</script>

<template>
  <app-alert
      :state="alertState.show"
      :type="alertState.type"
      :title="alertState.message"
      @close="alertState.show = false"
  >
  </app-alert>
  <div class="mt-6 mb-16">
    <h1 class="font-semibold text-[16px]">{{$t('Record Project Hours'.getTranslationKey())}}</h1>
    <h4 class="text-[#667085] text-[14px]">{{$t('Select year and month to record your project hours.'.getTranslationKey())}}</h4>
    <div class="flex gap-4 items-center mt-4">
      <app-single-select
          v-for="filter in ['Year', 'Month']"
          :key="filter"
          class="!w-[175px]"
          :label="$t(filter.getTranslationKey())"
          :options="filter === 'Year' ? getYearsList(2000) : monthsNameWithNumber"
          v-model="filters[filter.toLowerCase()]"
      ></app-single-select>
    </div>
    <div class="mt-8">
      <project-hours-table @onUpdate="getProjectHours" :year="filters.year" :month="filters.month">
      </project-hours-table>
    </div>
    <hr class="border-[1px] my-10">

    <div>
      <h1 class="font-semibold text-[16px]">{{$t('Previous Project Hours'.getTranslationKey())}}</h1>
      <h4 class="text-[#667085] text-[14px]">{{$t('Filter previous projects based on their year, month or status'.getTranslationKey())}}</h4>

      <div class="flex gap-4 items-center mt-4">
        <app-single-select
            v-for="filter in ['Year', 'Month', 'Status']"
            :key="filter"
            class="!w-[175px]"
            :label="$t(filter.getTranslationKey())"
            :options="getPreviousProjectFiltersOptions(filter)"
            v-model="prevProjectFilters[filter.toLowerCase()]"
        ></app-single-select>
      </div>

      <div class="mt-6">
        <app-custom-table
            table-name="Project Hours"
            :headers="tableHeaders"
            :items="allProjectHoursData.data"
            title="All Project Hours"
            show-menu
            :show-pagination="allProjectHoursData.total_data > 0"
            items-per-page="10"
            :total-items="allProjectHoursData.total_data"
            :current-page="currentPage"
        >
          <template #status="{item}">
            <span>{{item.status}}</span>
          </template>
          <template #listItems="{item}">
            <v-list-item
                v-for="option in dropdownOptions(item.status)"
                class="my-n1 hover:bg-gray-200 cursor-pointer"
                :key="option"
                @click="handleMenuClick({...item, option})"
            >{{ $t(option.getTranslationKey()) }}</v-list-item
            >
          </template>
        </app-custom-table>
      </div>

    </div>
  </div>
  <view-details-modal ref="viewDetailsModal"></view-details-modal>
</template>

<style scoped>

</style>