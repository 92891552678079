<template>
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteForeignOffice"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
  :title="$t(getTranslatedStringKey(alertTitle))"
  @close-alert="alertState = false"
  :state="alertState"
  :timeout="5000"
  :type="alertType"
/>
</template>
<script>
import { defineComponent } from "vue";
import {getTransString } from "@/services/helpers";
import Api from "@/services/api";
import { deleteForeignOffice } from "@/services/endpoints";
export default defineComponent({
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      officeId: ""
    };
  },
  computed: {
    currentPage() {
        return this.$store.getters["foreignOfficeModule/isCurrentPage"];
      },
      
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog() {
      this.$refs.dialog.showDialog = true;
      this.subTitle = "foreignOffices.confirmDeleteOffice";
      this.title = "foreignOffices.deleteOffice";
      this.cancelLabel = "employees.noKeepIt";
    },

    hideDialog() {
      this.$refs.dialog.showDialog = false;
    },
    getAllOffices() {
        const payload ={
            load_page: false,
            page_number:this.currentPage
        }
        this.$store.dispatch('foreignOfficeModule/getAllOffices',payload)
      },
    deleteForeignOffice() {

      Api()
        .delete(`${deleteForeignOffice}${this.officeId}/` )
        .then((response) => {
          if (response.data.status === "success") {
            this.hideDialog()
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllOffices()

          }
        })
        .catch((error) => {
          if (error) {
            this.hideDialog()
           
          }
        });
    },
  },
});
</script>
