<template>
  <div>
    <ProjectForeignEstablishment />
  </div>
</template>

<script>
import ProjectForeignEstablishment from "./ProjectForeignEstablishment.vue";
export default {
  name: "ForeignEstablishment",
  components: {
    ProjectForeignEstablishment,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created(){
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 2);
  }
};
</script>
