<template>
  <div>
    <div
      v-if="isAppLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div class="w-4/5 p-[30px] h-[1122px] mb-6" v-else>
      <div
        v-for="(pageContent, index) in splitContent"
        :key="index"
        class="rounded-lg pages"
      >
        <div v-if="index === 0">
          <v-col align="center" justify="center" class="">
            <img class="mb-4" src="../../../assets/logo.png" alt="" />
            <h1 class="mb-1 H600">{{ $t("ICON-INSTITUTE") }}</h1>
            <p class="P300">{{ $t("MISSION ORDER".getTranslationKey()) }}</p>
          </v-col>
        </div>
        <div class="mb-4">
          <div class="">
            <div v-html="pageContent"></div>
          </div>
        </div>
        <div class="" v-if="index === splitContent.length - 1">
          <div class="py">
            <div class="flex justify-between company-innetials">
              <div class="names">
                <h3 class="H500">
                  Köln, {{ formattedDate(data.mission_end_date) }}
                </h3>
                <p class="P300">
                  {{ $t("Project Coordinator".getTranslationKey()) }}: {{ data.project_manager }}
                </p>
              </div>
            </div>
          </div>
          <div></div>
          <div class="flex items-center justify-between pt-10 sign-input">
            <div></div>
          </div>
        </div>
        <div
          class="py-10 pb-10 annexes"
          v-if="index === splitContent.length - 1"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTransString } from "@/services/helpers";
import { defineComponent } from "vue";
import API from "@/services/api";
import { getDeploymentAgreement } from "@/services/endpoints";
import documentData from "@/services/missionDeployment.js";
import { formatDate } from "@/services/helpers";
import { mapGetters, mapState } from "vuex";
export default defineComponent({
  name: "DeploymentAgreement",
  components: {},
  data() {
    return {
      activeButton: null,
      documentData,
      data: [],
      isAppLoading: false,
    };
  },
  computed: {
    ...mapGetters("translation", ["getLocale"]),
    ...mapState(["translation"]),

    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
    isEditMissionId() {
      return this.$store.getters["contractModules/isEditMissionId"];
    },
    isDeploymentData() {
      return this.$store.getters["contractModules/isDeploymentData"];
    },

    splitContent() {
      const maxLinesPerPage = 108;
      const modifiedContent = this.documentData.replace(/<br>/g, "\n");
      const lines = modifiedContent.split("\n");
      const pages = [];
      let currentPageLines = [];
      let lineCount = 0;

      for (let i = 0; i < lines.length; i++) {
        let currentLine = lines[i];
        currentPageLines.push(currentLine);
        lineCount++;

        if (lineCount >= maxLinesPerPage || i === lines.length - 1) {
          pages.push(currentPageLines.join("\n"));
          currentPageLines = [];
          lineCount = 0;
        }
      }
      return pages;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    formattedDate(date, lang) {
      lang = lang ? lang : this.appLang;
      return formatDate(date?.trim()?.dateToYYYYMMDD(false), lang);
    },

    linesTillNextH2(linesSlice) {
      let lineCounter = 0;
      for (let line of linesSlice) {
        if (line.includes("<h2>")) break;
        lineCounter++;
      }
      return lineCounter;
    },
    hideLinesWithYes(text) {
      const lines = text.split("\n");
      const filteredLines = lines.filter((line) => !/\[yes\]/i.test(line));
      return filteredLines.join("\n");
    },
    formatHeaders() {
      let currentH2Number = 0;
      let currentH3Number = 0;
      this.documentData = this.documentData.replace(
        /<h[23]>(.*?)<\/h[23]>/g,
        (match, content) => {
          const trimmedContent = content.trim();

          if (trimmedContent.startsWith("-")) {
            return match; // Return original match without numbering
          }
          if (trimmedContent.startsWith("*")) {
            return match.replace("*", "").trim(); // Remove the * and return without numbering
          }
          if (match.includes("<h2>")) {
            // Check if the line contains [yes] before incrementing H2 number
            if (!/\[yes\]/i.test(match)) {
              currentH2Number += 1;
            }
            currentH3Number = 0; // reset H3 numbering
            return `<h2>${currentH2Number}. ${trimmedContent}</h2>`;
          } else if (match.includes("<h3>")) {
            // Check if the line contains [yes] before incrementing H3 number
            if (!/\[yes\]/i.test(match)) {
              currentH3Number += 1;
            }
            return `<h3>${currentH2Number}.${currentH3Number} ${trimmedContent}</h3>`;
          }
        }
      );
    },
    updateDocumentWithFetchedData() {
      this.documentData = this.documentData.replaceAll(
        "[expert]",
        this.data.expert || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[country]",
        this.data.travel_country || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[country]",
        this.data.travel_country || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[number]",
        `${this.data.cost_center_number}-${this.data.contract_id}-${this.data.id}` ||
          "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[assignment]",
        `${this.data.travel_country},${this.data.assignment_place}` || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[project]",
        this.data.project_title || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[accountingperiod]",
        `${this.formattedDate(
          this.data.accounting_period_start_date
        )}-${this.formattedDate(this.data.accounting_period_end_date)}` ||
          "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[iconticketprovided]",
        this.data.is_ticket_provided || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[cost]",
        `${this.data.other_costs_amount} ${this.data.currency}` || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[missiontaskreport]",
        this.data.mission_task_description || "[yes]"
      );
      this.documentData = this.documentData.replaceAll(
        "[paymentamount]",
        `${this.data.mission_agreement_total} ${this.data.currency}` || "[yes]"
      );

      if (this.data.table_data && this.data.table_data.length > 0) {
        let tableRows = this.data.table_data
          .map((row) => {
            return `
      <tr class="${row.is_header ? "border-l border-b border-r" : "border"}">
    <td style="color: #344054; font-size: 13px;" class="${
      row.is_header ? "" : "border "
    } px-2">${row.agreement}</td>
    <td style="color: #344054; font-size: 13px;" class="${
      row.is_header ? "" : "border"
    } px-2">${row.requires_up_to ? "up to" : ""}${
              row.requires_star ? "*)" : ""
            }${
              row.is_header
                ? row.number > 0
                  ? row.number
                  : ""
                : row.number > 0
                ? `${row.number} `
                : "-"
            }</td>
    <td style="color: #344054; font-size: 13px;" class="${
      row.is_header ? "" : "border"
    } px-2">${row.requires_up_to ? "up to" : ""}${
              row.requires_star ? "*)" : ""
            }${
              row.is_header
                ? row.amount > 0
                  ? `${row.amount} ${this.data.currency} `
                  : ""
                : row.amount > 0
                ? `${row.amount} ${this.data.currency} `
                : "-"
            }</td>
    <td style="color: #344054; font-size: 13px;" class="${
      row.is_header ? "" : "border"
    } px-2">${row.requires_up_to ? "up to" : ""}${
              row.requires_star ? "*)" : ""
            }${
              row.is_header
                ? row.total > 0
                  ? `${row.total} ${this.data.currency} `
                  : ""
                : row.total > 0
                ? `${row.total} ${this.data.currency} `
                : "-"
            }</td>
  </tr>`;
          })
          .join("");

        // Replace the table body with the new rows
        this.documentData = this.documentData.replace(
          /<tbody>[\s\S]*?<\/tbody>/,
          `<tbody>${tableRows}</tbody>`
        );
      }
      // this.formatHeaders();
      this.documentData = this.hideLinesWithYes(this.documentData);
    },
    fetchDeploymentAgreement() {
      this.isAppLoading = true;
      API()
        .get(`${getDeploymentAgreement}${this.isEditMissionId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.isAppLoading = false;
            const responseData = response.data.data;
            this.data = responseData;
            this.updateDocumentWithFetchedData();
            this.$store.commit(
              "contractModules/SET_DEPLOYMENT_DATA_TO_STATE",
              responseData
            );
          }
        })
        .catch();
    },
  },

  created() {},
  mounted() {
    this.fetchDeploymentAgreement();
  },
});
</script>

<style scoped>
.active-button {
  background-color: #eddedc !important;
  border-radius: 8px !important;
}
</style>
