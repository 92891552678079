<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="flex justify-start gap-10">
          <div>
            <app-multi-select
              :label="$t('projectConfiguration.collaborators')"
              :options="getAllEmployees"
              :labelPlaceholder="$t('projectConfiguration.selectCollaborator')"
              :searchPlaceholder="$t('projectConfiguration.searchEmployees')"
              :showSearch="true"
              :showCheckbox="true"
              :optionItems="collaborators"
              @selected-options="
                selectedOptions({ $event, data: 'collaborators' })
              "
            >
            </app-multi-select>
          </div>

          <div class="w-[400px]">
            <app-multi-select
              :label="$t('projectConfiguration.positions')"
              :options="getAllPositions"
              :labelPlaceholder="$t('projectConfiguration.selectPosition')"
              :searchPlaceholder="
                $t('projectConfiguration.searchCollaborators')
              "
              :showSearch="true"
              :showCheckbox="true"
              :optionItems="positions"
              @selected-options="selectedOptions({ $event, data: 'positions' })"
            >
            </app-multi-select>
          </div>
        </div>

        <div class="h-[300px]"></div>

        <v-row>
          <v-col cols="12">
            <div class="w-[910px]">
              <div class="flex justify-end gap-3 mt-10">
                <div>
                  <app-secondary-button
                    @click="previous"
                    :buttonLabel="$t('projectConfiguration.previous')"
                  >
                  </app-secondary-button>
                </div>
                <div>
                  <app-primary-button
                    :buttonLabel="$t('projectConfiguration.save')"
                    @click="addUpdateDefaultCollaborators"
                  ></app-primary-button>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import AppSecondaryButton from "@/components/shared/AppSecondaryButton.vue";
import AppPrimaryButton from "@/components/shared/AppPrimaryButton.vue";

export default defineComponent({
  components: { AppSecondaryButton, AppPrimaryButton },
  name: "Default Collaborators",
  data() {
    return {
      alertTitle: "",
      alertType: "success",
      alertState: false,
      collaborators: [],
      positions: [],
      selectedCollaborators: [],
      selectedPositions: [],
      initialSelected: [[], []],
    };
  },
  computed: {
    getAllEmployees() {
      const employees = this.$store.getters["hrResources/isAllEmployees"];
      return employees.map((employee) => ({
        text: employee.first_name + " " + employee.last_name,
        value: employee.employee_id,
      }));
    },

    getAllPositions() {
      const positions = this.$store.getters["hrConfigurations/isPositions"];
      return positions.map((position) => ({
        text: position.title,
        value: position.id,
      }));
    },

    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    allSelected() {
      return [this.selectedCollaborators, this.selectedPositions];
    },
  },
  methods: {
    getCollaboratorsAndPositions() {
      Api()
        .get(`/configuration/default-collaborators/get-default-collaborators/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.collaborators = response.data.data.collaborators.map(
              (collaborator) => ({
                text: collaborator.first_name + " " + collaborator.last_name,
                value: collaborator.employee_id,
              })
            );
            this.positions = response.data.data.positions.map((position) => ({
              text: position.title,
              value: position.id,
            }));
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    addUpdateDefaultCollaborators() {
      const payload = {};
      if (this.selectedCollaborators.length > 0) {
        payload.collaborators = this.selectedCollaborators;
      }
      if (this.selectedPositions.length > 0) {
        payload.positions = this.selectedPositions;
      }
      if (Object.keys(payload).length > 0) {
        Api()
          .post(
            `/configuration/default-collaborators/add-update-default-collaborators/`,
            payload
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      }
    },

    selectedOptions(data) {
      if (data.data === "collaborators") {
        this.selectedCollaborators = data.$event;
      }
      if (data.data === "positions") {
        this.selectedPositions = data.$event;
      }
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    previous() {
      const getCurrentTab = this.isCurrentTab - 1;
      this.$store.commit(
        "commonResources/SET_CURRENT_TAB_TO_STATE",
        getCurrentTab
      );
    },
  },
  mounted() {
    this.getCollaboratorsAndPositions();
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 2);
    this.$store.dispatch("hrResources/getAllEmployees");
    this.$store.dispatch("hrConfigurations/getAllConfigurationTypes", {
      configType: "Positions",
      setter: "SET_POSITIONS_TO_STATE",
    });
  },
});
</script>

<style scoped></style>
