<template>
    <div class="px-10">
      <app-header :name="$t('Expert Contract')" :show-arrow-back="false">
        <template #action>
          <v-row class="items-center justify-center">
            <app-primary-button
              :buttonLabel="$t('Download Contract')"
              @click="downloadAsPDF()"
            >
            </app-primary-button>
          </v-row>
        </template>
      </app-header>
  
      <div  ref="pdfSection"
        class="w-full flex flex-col pages-container"
        v-if="!isEdditingContract"
      >
        <div
          v-for="(pageContent, index) in splitContent"
          :key="index"
          class="page"
        >
        <div  >
          <div v-if="index === 0">
            <v-col align="center" justify="center" class="">
              <img class="mb-4" src="@/assets/logo.png" alt="" />
              <h1 class="mb-1 H600">{{ $t("ICON-INSTITUTE") }}</h1>
              <p class="P300">{{ $t("CONTRACT") }}</p>
            </v-col>
          </div>
          <div class="content-area mb-4">
            <div class="content-container">
              <div v-html="pageContent"></div>
            </div>
          </div>
          <div class="" v-if="index === splitContent.length - 1">
            <div class="py">
              <div class="company-innetials flex justify-between">
                <div class="names">
                  <h3 class="H500">Köln, the {{ getCurrentDate() }}</h3>
                  <p class="P300">ICON-INSTITUT GmbH, Köln</p>
                </div>
              </div>
            </div>
            <div></div>
            <div class="sign-input flex justify-between items-center pt-10">
              <div>
                <img
                  alt="ceo signature"
                  v-if="data.ceo_signature"
                  :src="data.ceo_signature"
                  class="h-[30px] w-full object-cover"
                />
                <p
                  v-if="!data.ceo_signature"
                  class="text-[#8B2923] cursor-pointer"
                  @click="showDialog({ data: 'addceo' })"
                >
                  Click to sign here
                </p>
                <div class="signature-line px-2"></div>
                <h3 class="H500">{{ data.ceo_name }}</h3>
              </div>
              <div>
                <img
                  alt="pm signature"
                  v-if="data.project_manager_signature"
                  :src="data.project_manager_signature"
                  class="h-[30px] w-full object-cover"
                />
                <p
                  v-if="!data.project_manager_signature"
                  class="text-[#8B2923] cursor-pointer"
                  @click="showDialog({ data: 'addpm' })"
                >
                  Click to sign here
                </p>
                <div class="signature-line px-2"></div>
                <h3 class="H500">{{ data.project_manager_name }}</h3>
              </div>
              <div>
                <img
                  alt="expert signature"
                  v-if="data.expert_signature"
                  :src="data.expert_signature"
                  class="h-[30px] w-full object-cover"
                />
                <p
                  v-if="!data.expert_signature"
                  class="text-[#8B2923] cursor-pointer"
                  @click="showDialog({ data: 'addexpert' })"
                >
                  Click to sign here
                </p>
                <div class="signature-line px-2"></div>
                <h3 class="H500">{{ data.expert_name }}</h3>
              </div>
            </div>
          </div>
  
          <div
            class="annexes pb-10 py-10"
            v-if="index === splitContent.length - 1"
          >
            <h3 class="H600">Annexes:</h3>
            <h3 class="H400">- Terms of Reference (Consultant)</h3>
            <h3 class="H400">
              - Mission Plan 1 (as the dates of the Mission will only be specified
              in the course of the Contract Duration, the Mission Plan still
              missing will be handed over at a later stage)
            </h3>
          </div>
        </div>
      </div>
  </div>
    </div>
    <div></div>
    <app-dialog ref="dialog" :title="$t('Add Signature')" :subtitle="``">
      <template #content>
        <ContractPreviewFile></ContractPreviewFile>
        <p class="P200" v-if="contractId">{{$t('contracts.contractAgreement')}}</p>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('projects.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t(isEdit ? 'Update' : 'Finish')"
              :loading="loading"
              @click-event="addCeoSignature()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="mailDialog"
      width="550"
      :title="$t('Send Mail to Expert')"
      :subtitle="``"
    >
      <template #content>
        <ExpertEmail @hide-dialog="hideMailDialog()" />
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </template>
  <script>
  import API from "@/services/api";
  import documentData from "@/services/contractDocument.js";
  import html2pdf from "html2pdf.js";
  import {
    unrestrictedContract,
    ceoSignsture,
    pmSign,
    expertSign,
    getDocument,
  } from "@/services/endpoints";
  import ContractPreviewFile from "@/components/dashboard/projects/contracts/contract-preview/ContractPreviewFile.vue";
  // import DisappovalComment from "@/components/dashboard/projects/contracts/contract-preview/DisapprovalComment.vue";
  import ExpertEmail from "@/components/dashboard/projects/contracts/contract-preview/ExpertEmail.vue";
  import { getTransString } from "@/services/helpers";
  export default {
    name: "contract",
    components: {
      ContractPreviewFile,
      // DisappovalComment,
      ExpertEmail,
    },
    data() {
      return {
        options: {
          modules: {
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["blockquote"],
              ["link"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
          placeholder: "Enter a description",
        },
        editorStyles: {
          minHeight: "990px",
          padding: "10px 14px",
          fontSize: "13px",
        },
        loading: false,
        addloading: false,
        alertType: "success",
        alertState: false,
        alertTitle: "",
        title: "",
        subTitle: "",
        isEdit: false,
        isAdd: false,
        isDelete: false,
        firstRun: true,
        addTitle: "",
        documentData,
        data: [],
        comment: "",
        signatureEndpoint: "",
        ceo: "",
        pm: "",
        expert: "",
        contractId: null,
        contractType: null,
      };
    },
    computed: {
      isSignatureFile() {
      return this.$store.getters["contractModules/isSignatureFile"];
    },
      splitContent() {
        const maxLinesPerPage = 40;
        const modifiedContent = this.documentData.replace(/<br>/g, "\n");
        const lines = modifiedContent.split("\n");
        const pages = [];
        let currentPageLines = [];
        let lineCount = 0;
  
        for (let i = 0; i < lines.length; i++) {
          let currentLine = lines[i];
          currentPageLines.push(currentLine);
          lineCount++;
  
          if (lineCount >= maxLinesPerPage || i === lines.length - 1) {
            pages.push(currentPageLines.join("\n"));
            currentPageLines = [];
            lineCount = 0;
          }
        }
  
        return pages;
      },
    },
    methods: {
      parseQueryParams() {
        let searchParams = new URLSearchParams(window.location.search);
  
        this.contractId = searchParams.get("contractId");
        this.contractType = searchParams.get("contractType");
     
      },
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
      linesTillNextH2(linesSlice) {
        let lineCounter = 0;
        for (let line of linesSlice) {
          if (line.includes("<h2>")) break;
          lineCounter++;
        }
        return lineCounter;
      },
      formatHeaders() {
        let currentH2Number = 1;
        let currentH3Number = 0;
  
        this.documentData = this.documentData.replace(
          /<h[23]>(.*?)<\/h[23]>/g,
          (match, content) => {
            const trimmedContent = content.trim();
            if (trimmedContent.startsWith("-")) {
              return match; // Return original match without numbering
            }
            if (match.includes("<h2>")) {
              currentH2Number += 1;
              currentH3Number = 0; // reset H3 numbering
              return `<h2>${currentH2Number}. ${trimmedContent}</h2>`;
            } else if (match.includes("<h3>")) {
              currentH3Number += 1;
              return `<h3>${currentH2Number}.${currentH3Number} ${trimmedContent}</h3>`;
            }
          }
        );
      },
      showDialog(data) {
        this.title = "Add Signature";
  
        if (data.data === "addceo") {
          this.$refs.dialog.showDialog = true;
          this.signatureEndpoint = ceoSignsture; // make sure 'ceoSignsture' is defined
          this.isEdit = false;
          this.ceo = "ceo";
        } else if (data.data === "addpm") {
          this.$refs.dialog.showDialog = true;
          this.signatureEndpoint = pmSign;
          this.pm = "pm";
        } else if (data.data === "addexpert") {
          this.$refs.dialog.showDialog = true;
          this.signatureEndpoint = expertSign;
          this.expert = "expert";
        }
      },
      hideDialog() {
        this.$refs.dialog.showDialog = false;
      },
      
      addCeoSignature() {
        let payload = {
          contract_id: this.contractId,
          contract_type: this.contractType,
        };
        if (this.ceo === "ceo") {
          payload.ceo_signature = this.isSignatureFile[0].file_url;
        } else if (this.pm === "pm") {
          payload.project_manager_signature = this.isSignatureFile[0].file_url;
        } else if (this.expert === "expert") {
          payload.expert_signature = this.isSignatureFile[0].file_url;
        }
        API()
          .post(this.signatureEndpoint, payload)
          .then((res) => {
            if (res.data.status === "success") {
              this.alertType = "success";
              this.alertTitle = res.data.detail;
              this.alertState = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            this.alertType = "error";
            this.alertTitle = err.response.data.detail;
            this.alertState = true;
            this.loading = false;
          });
      },
   
      async getAllpreFixData() {
      try {
        const response = await API().get(`${unrestrictedContract}${this.contractType}/${this.contractId}/`);
        if (response.data.status === "success") {
          this.data = response.data.data;
          
        }
      } catch (err) {
    this.data=''
      }
    },
    async getDocument() {
      try {
        const response = await API().get(`${getDocument}${this.contractType}/${this.contractId}/`);
        if (response.data.status === "success" && response.data.data) {
          this.documentData = response.data.data;

        }
      } catch (err) {
    this.data=''
      }
    },
      getCurrentDate() {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 since months are 0-indexed
        const year = date.getFullYear();
  
        return `${day}.${month}.${year}`;
      },
      downloadAsPDF() {
        let section = this.$refs.pdfSection;
        let opt = {
          margin: 1, 
       filename: 'contract.pdf', 
       html2canvas: {dpi:20, letterRendering: true, useCORS: true}, 
       jsPDF: {unit: 'pt', format: [595, 851.5], orientation: 'portrait'}
        };

        html2pdf().from(section).set(opt).save();
      },
    },
    created() {
      this.parseQueryParams();
      this.formatHeaders();
    },
    mounted() {
      this.getAllpreFixData();
      this.getDocument();
     
    },
  };
  </script>
  <style>
  
  .content-area h5 {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-size: 13px;
    margin: 1;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3rem; /* 150% */
  }
  .content-container {
    padding-bottom: 50px;
  }
  .content-container p {
    /* Style for paragraphs */
  
    font-size: 16px;
  }
  
  .content-container ul {
    /* Style for unordered lists */
    list-style-type: disc;
    padding-left: 2em;
  }
  
  .content-container ol {
    /* Style for ordered lists */
    list-style-type: decimal;
    padding-left: 2em;
  }
  .pages-container {
    display: flex;
    flex-direction: column;
  }
  .italic {
    font-style: italic;
  }
  .page {
    background-color: white;
    width:796.8px;
    padding: 40px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    height:1122px;

  }
  
  .signature-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto; /* Push to the bottom */
  }
  
  .signature,
  .date {
    margin-bottom: 10px; /* or adjust as needed */
  }
  
  .signature-line,
  .date-line {
    border-bottom: 1px dotted gray;
    width: 200px; /* or adjust as needed */
    text-align: center;
  }
  </style>
  