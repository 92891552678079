<template>
  <div class="input-wrapper">
    <div v-if="showLabel" :class="['label', 'py-2', classString]">
      <label :for="id">{{ $t(`${label}`) }}</label>
    </div>
    <div class="relative">
      <input
        :disabled="disabled"
        :type="inputType"
        :name="id"
        :id="id"
        :class="[
          {
            '!border-red-500': error,
            'cursor-pointer': type === 'date',
            'text-right': currency,
          },
        ]"
        :style="[inputStyling, { height, padding }]"
        :placeholder="placeholder"
        :value="modelValue"
        :validation="validation"
        :min="type === 'date' ? getMinDate() : null"
        :max="type === 'date' ? getMaxDate() : null"
        @input="updateValue"
        @blur="$emit('blur', $event)"
      />
      <div
        :class="[
          'absolute right-0 flex mr-2 transform -translate-y-1/2 item-center top-1/2',
        ]"
      >
        <span v-if="type === 'password' || error">
          <v-icon
            size="20"
            :icon="error ? 'custom:errorIcon' : filterIcon"
            color="#98A2B3"
            @click="showPassword"
          />
        </span>
        <span v-else >
          <v-icon
            class="bg-gray-100 rounded-lg  hover:bg-gray-200"
            v-if="showAddIcon"
            size="20"
            :icon="error ? 'custom:errorIcon' : 'mdi-plus' "
            color="#98A2B3"
            @click="$emit('add', modelValue)"
          />
        </span>
      </div>
    </div>
    <p v-if="error" class="text-red-500 text-[14px]">
      {{ $t(`${errorMessage.getTranslationKey()}`) }}
    </p>
  </div>
</template>

<script lang="js">
import { restrictCharactersByRegex } from "@/services/helpers";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputField",
  props: {
    id: {
      type: String,
      default: "input-field",
    },
    label: {
      type: String,
      default: "Label",
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: String,
      default: "",
    },
    classString: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    inputStyling: {


  },
  height: {
    type: String,
    default: "44px"
  },
  padding: {
    type: String,
    default: "10px 14px"
  },
  currency: {
    type: Boolean,
    default: false
  },
  showAddIcon: {
    type: Boolean,
    default: false
  },

  },
  data() {
    return {
      error: false,
      inputType: this.type,
      // errorMessages: this.errorMessage,
      regexValidation: {
        lettersOnly: /[^a-zA-Z]/g,
        lettersOnlyAndSpace: /[^a-zA-Z\s]/g,
        lettersSpecialCharactersOnly: /[^ÃåÅåÄäÀàÁáÂâÇçČčÉéÈèÊêËëĔĕĞğĢģÏïÎîÍíÌìÑñÖöÔôŌōÒòÓóØøŜŝŞşÜüŪūÛûÙùÚúŸÿßẞÞþÐða-zA-Z-_]/g,
        phoneOnly: /[^0-9+]/g,
        digitOnly: /[^0-9]/g,
        digitsAndLettersOnly: /[^a-zA-Z0-9]/g,
        withoutWhitespace: /^\S.*$/g,
        floatingNumber: /^(?![0-9.-]+$).*$/g,
        // floatingNumber: /[+-]?([0-9]*[.])?[0-9]+/g,
      }
    };
  },
  watch: {
    hasError(val) {
      this.error = val
    }
  },
  computed: {
   filterIcon() {
      return this.inputType === 'password' ? "mdi-eye-outline" : "mdi-eye-off-outline";
    }
  },

  methods: {
    showPassword(){
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    getMinDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 200);
      return date.toISOString().slice(0, 10);
    },
    getMaxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 100);
      return date.toISOString().slice(0, 10);
    },

    updateValue(event) {
      // this.error = this.hasError || (this.isRequired && !event.target.value?.trim())
      this.error = this.isRequired && event.target.value?.trim() && this.hasError;
      restrictCharactersByRegex(event, this.regexValidation[this.validation]);
      this.$emit('update:modelValue', event.target.value)
    },
  }
});
</script>

<style scoped>


.input-wrapper {
  position: relative;
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

.label.public-holiday {
  font-weight: 400;
  font-size: 16px;
}

input {
  align-items: center;
  /* padding: 10px 14px; */
  outline-width: 0;
  gap: 8px;
  /* min-width: 360px; */
  width: 100%;
  /* height: 44px; */
  background: #ffffff;
  color: #101828;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

input:focus {
  background: #ffffff;
  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}

input:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
</style>
