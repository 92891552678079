<template>
  <!-- view file -->
  <section v-if="documentFiles.length > 0">
    <p class="!font-medium !text-[0.875rem] text-[#344054] mb-2">Uploaded Document(s)</p>
    <v-row  class="cursor-pointer">
      <v-col cols="3" v-for="(file, index) of documentFiles" :key="index">
        <div
          :class="['px-4 py-2 text-[#667085] !rounded-lg border border-gray-200', { '!border-[#B97F7B]': singleUpload?.progress === 100 } ]"
        >
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <p
                  class="truncate !font-inter !font-medium !text-[0.875rem] text-[#344054]"
                  @click="viewFile(file)"
                >
                  {{ file?.file_title }}
                </p>
                <span class="ml-2">
                  <v-icon
                  
                    icon="mdi:mdi-close-circle"
                    color="#AE6A65"
                    size="20"
                    @click="removeFile(index)"
                  ></v-icon>
            
                </span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </section>
  <!-- upload file -->
  <section v-else >
    <div>
      <div class="w-[235px]">
        <input type="file" hidden multiple ref="fileInput"  @change="selectedFile" />
        <app-secondary-button
          :buttonLabel="$t('documents.documentUpload')"
          @click="pickFile"
          class="!text-sm"
          :disabled="disabled"
        >
          <template #icon>
            <v-icon icon="custom:uploadIcon" color="#344054" size="20"></v-icon>
          </template>
        </app-secondary-button>
      </div>
    </div>
    <v-row v-if="uploading" class="">
      <v-col cols="3" v-for="(singleUpload, index) of uploadedFiles" :key="index">
        <div
          :class="['px-4 py-2 mt-6 text-[#667085] !rounded-lg border border-gray-200', { '!border-[#B97F7B]': singleUpload?.progress === 100 } ]"
        >
          <v-row v-show="!isEdit">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <p
                  class="truncate !font-inter !font-medium !text-[0.875rem] text-[#344054]"
                  @click="pickFile"
                >
                  {{ singleUpload?.name }}
                </p>
                <span class="ml-2">
                  <v-icon
                    v-if="singleUpload?.progress === 100"
                    icon="mdi:mdi-close-circle"
                    color="#AE6A65"
                    size="20"
                    @click="removeFile(index)"
                  ></v-icon>
                  <v-progress-circular
                    v-else
                    :model-value="singleUpload?.progress"
                    :width="3"
                    color="#AE6A65"
                  ></v-progress-circular>
                </span>
              </div>
              <p
                class="mt-0 !font-['inter'] !font-normal !text-sm text-[#667085]"
              >
                {{ singleUpload?.size }} - {{ singleUpload?.progress }}%
                {{
                  singleUpload?.progress === 100
                    ? $t("projects.fileUploaded")
                    : $t("projects.fileUploading")
                }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  
  </section>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import { getTransString, formatBytes } from "@/services/helpers";
import { uploadResquestFile } from "@/services/endpoints";
import UploadApi from "@/services/fileUploadApi";
import Queue from "@/services/Queue";

export default defineComponent({
  name: "UploadDocument",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    documentFiles: {
      type: Array,
      default: () => [],
    },  
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      fileList: [],
      queueUpload: new Queue(),
      queueSize: null,
      lastItem: null,
      uploading: false,
      uploadedFiles: [],
      count: 0,
    };
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFiles();
      }
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    removeFile(index) {
      const files = this.documentFiles ? this.documentFiles : this.uploadedFiles;
  
      files.splice(index, 1);
      this.deleteUploadedFile(files[index]?.id);
    },
    pickFile() {
      this.$refs.fileInput.click();
    },
    viewFile(data){
      window?.open(data.file_path)
    },
    selectedFile(event) {
      this.uploading = true;
      this.filelist = "";
      this.filelist = [...event.target.files];

      this.validateAndInitialUpload();
    },
    validateAndInitialUpload() {
      for (let i = 0; i < this.filelist.length; i++) {
        const file = this.filelist[i];
        this.queueUpload.enqueue(file);
        this.queueSize = this.queueUpload.getSize();

        if (file.size > 16777216) {
          this.isLoading = false;
          this.alertState = true;
          (this.alertType = "error"),
            (this.alertTitle = "You cannot upload file size more than 16mb");

          return;
        } else {
          this.uploadedFiles?.push({
            name: file.name,
            progress: 0,
            size: formatBytes(file.size),
          });
        }

        this.config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            this.uploadedFiles[
              this.count % this.uploadedFiles?.length
            ].progress = progress;
          },
        };
      }
      this.lastItem =
        this.uploadedFiles?.length > 0
          ? this.uploadedFiles[this.uploadedFiles?.length - 1]
          : undefined;
    },
    uploadFiles() {

      const file = this.queueUpload.peek();
      const formData = new FormData();
      formData.append("request_files", file);
      UploadApi()
        .post(`${uploadResquestFile}`, formData, this.config)
        .then(async (response) => {
          if (response.data.status === "success") {
            this.uploaded = true;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.details;
            this.fileList.push(response?.data?.data[0]);
            this.$store.commit(
              "commonResources/SET_DOCUMENT_URL_TO_STATE",
              this.fileList
            );
            const isEmpty = this.queueUpload.isEmpty();
            if (!isEmpty) {
              this.queueUpload.removeItem(0);
              this.queueSize = this.queueUpload.getSize();
              this.count++;
            } else {
              this.count = 0;
              this.uploadedFiles = [];
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.uploaded = false;
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = "Error uploading image. Please try again.";
          }
        });
    },
    deleteUploadedFile(id) {
      UploadApi()
        .delete(`${uploadResquestFile}${id}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = "Error deleting image. Please try again.";
          }
        });
    },
  },
  mounted() {
    this.$store.commit("commonResources/SET_DOCUMENT_URL_TO_STATE", []);
  },
});
</script>

<style lang="scss" scoped></style>
