<template>
    <v-sheet max-width="1125" class="bg-transparent">
      <!-- end of parent-module -->
      <div
      class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
      @dragover="dragover"
      @drop.prevent
      @drop="dragFile"
      @click="pickFile"
      v-if="!isEdit"
      :isRequired="true" >
      <span>
        <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
      </span>
      <input
        ref="file"
        @change="selectedFile"
        type="file"
        accept="*"
        hidden
        multiple
      />
      <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
        {{ $t("projects.clickToUpload") }}
      </p>
      <p
        class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
      >
        {{ $t("projects.acceptedFiles") }}
      </p>
    </div>
          <v-row class="flex items-center">
            <v-col cols="12" class="pr-4">
              <div>
             
              </div>
            </v-col>
            <v-col class="pl-4">
              <div class="w-[120px]">
                <input
                  ref="file"
                  @change="selectedFile"
                  type="file"
                  accept="*"
                  hidden
                  multiple
                />
             
              </div>
            </v-col>
          </v-row>
          <div v-if="uploading">
            <div
              v-for="(singleUpload, index) of uploadedFiles"
              :key="index"
            >
              <div
                class="p-4 mt-2 text-[#667085] !rounded-lg border border-gray-200 w-full h-full"
              >
                <v-row v-show="!isEdit">
                  <v-col cols="10">
                    <p
                      class="!font-inter !font-medium !text-[0.875rem] text-[#344054]"
                      @click="pickFile"
                    >
                      {{ singleUpload?.name }}
                    </p>
                    <p
                      class="mt-0 !font-inter !font-normal !text-[0.875rem] text-[#667085]"
                    >
                      {{ singleUpload?.size }} -
                      {{ singleUpload?.progress }}%
                      {{
                        singleUpload?.progress === 100
                          ? "uploaded"
                          : "uploading"
                      }}
                    </p>
                  </v-col>
                  <v-col cols="2" class="ml-auto">
                    <v-icon
                      v-if="singleUpload?.progress === 100"
                      icon="mdi:mdi-close-circle"
                      color="#AE6A65"
                    ></v-icon>
                    <v-progress-circular
                      v-else
                      :model-value="singleUpload?.progress"
                      :width="3"
                      color="#AE6A65"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
         
      
    </v-sheet>
    <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
  </template>
  
  <script lang="js">
  import { defineComponent } from 'vue';
  import { formatBytes, getTransString  } from '@/services/helpers'
  import Queue from '@/services/Queue'
  import UploadApi from '@/services/fileUploadApi'
  import Api from '@/services/api'
  import {exchangeFile } from "@/services/endpoints";
  
  export default defineComponent({
    props: {
        fileType: {
            type: String,
            default: ''
        },
        isDialog:{
            type: Boolean,
            default: true
        },
        filePropTitle: {
          type: String
        },
        filePropPath: {
          type: String
        },
        isEditProp: {
          type: Boolean,
          default: false
        },
        fileNameProp:{
          type: String,
          default: "Tech requirement"
        },
        fileSize:{
          type: String,
        },
        editFileEventProp: {
          type: Function
        },
        editObject: {
          type: Object
        }
  
    },
    data() {
      return {
        alertType: "success",
        alertState: false,
        alertTitle: "",
        isLoading: false,
        count: 0,
        fileTitle: '',
        filePath: '',
        fileUrl: '',
        fileName: "",
        uploading: false,
        uploadedFiles: [],
        queueUpload: new Queue(),
        queueSize: null,
        lastItem: null,
        isEdit: false,
        filesDetails: [],
        editEventProp: Function,
        projectId: null,
        contractType: null,
      }
    },
    watch: {
      queueSize(newSize) {
        if (newSize > 0) {
          this.uploadFile()
        }
      },
      filePropTitle(newVal) {
        this.fileTitle = newVal;
      },
      filePropPath(newVal) {
        this.filePath = newVal;
      },
      isEditProp(newVal){
        this.isEdit = newVal
      },
      fileNameProp(newName){
        this.fileName = newName
      },
      editFileEventProp(newEvent){
        this.editEventProp = newEvent
      }
    },
    computed: {
      isDisabled(){
        // return this.fileUrl === '' ? true : false
        return this.lastItem?.progress === 100 ? false : true
      },
      isProjectID() {
        return this.$store.getters["projectModules/isProjectID"];
      },
    },
    methods: {
      parseQueryParams() {
          let searchParams = new URLSearchParams(window.location.search);
    
          this.projectId = searchParams.get("projectId");
          this.contractType = searchParams.get("contractType");
     
        
        },
      getTranslatedStringKey(field) {
  
        return getTransString(field);
      },
  
      pickFile() {
        this.$refs.file.click()
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('ae-bg-green-300')) {
          event.currentTarget.classList.remove('drag-window')
          event.currentTarget.classList.add('ae-bg-green-300')
        }
      },
  
  
      selectedFile(event) {
        this.uploading = true
        // this.uploadedFiles = []
        this.filelist = ''
  
        this.filelist = [...event.target.files]
        this.validateAndInitialUpload(this.filelist)
      },
  
      validateAndInitialUpload(){
  
        for (let i = 0; i < this.filelist.length; i++) {
          const file = this.filelist[i];
          this.queueUpload.enqueue(file)
          this.queueSize = this.queueUpload.getSize()
  
          if(file.size > 16777216) {
            this.$refs.dialog.showDialog = !this.isDialog
            this.isLoading = false
            this.alertState= true
            this.alertType= "error",
            this.alertTitle = "You cannot upload file size more than 16mb";
  
            return
          } else {
            this.uploadedFiles.push({ name: file.name, progress: 0, size: formatBytes(file.size) });
          }
  
  
  
          const formData = new FormData();
          formData.append("project_file", file);
          formData.append("title", this.fileTitle);
  
          this.config = {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  
  
              this.uploadedFiles[this.count % this.uploadedFiles?.length].progress = progress;
  
            },
          };
        }
        this.lastItem = this.uploadedFiles?.length > 0 ? this.uploadedFiles[this.uploadedFiles?.length - 1] : undefined;
  
      },
      uploadFile() {
        const file = this.queueUpload.peek()
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", this.fileTitle)
        const details = {};
  
        UploadApi().post(`${exchangeFile}`, formData, this.config)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fileUrl= response.data.data.file_url
              details.title = response.data.data.file_title
              details.file_url = this.fileUrl;
              const isEmpty = this.queueUpload.isEmpty()
              if (!isEmpty) {
                this.queueUpload.removeItem(0)
                this.queueSize = this.queueUpload.getSize()
                this.count++
              } else {
                this.count = 0
                this.uploadedFiles = []
              }
              this.filesDetails.push(details)
              this.$store.commit("pmLocalModules/SET_EXCHANGE_FILES_TO_STATE", this.filesDetails);
            }
          })
          .catch((error) => {
            // Handle any error that occurred during the upload
            if (error) {
              this.uploadedFiles = []
  
            }
          });
      },
      createProjectFile() {
        this.isLoading = true
        const projectFiles = [ ...this.filesDetails ]
        let modProjectFiles = projectFiles.reduce((prev, curr) => [ ...prev, { ...curr, file_path: this.filePath, file_type: this.fileType}], [])
        if (projectFiles.length === 1) {
          const currentProjectFile = { ...modProjectFiles[0] };
          currentProjectFile.title = this.fileTitle;
          modProjectFiles = [ currentProjectFile ];
        }
       const payload={
        project_id: this.isProjectID,
        project_files: modProjectFiles
       }
  
        Api().post('/projects/project/project-save-files/', payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.hideDialog()
              this.isLoading = false
              this.alertState= true
              this.alertType= "success"
              this.alertTitle = response.data.details
  
              this.$store.dispatch("projectModules/getAllProjectFiles", this.isProjectID);
  
            }
            // Handle the response from the server if needed
          })
          .catch((error) => {
            if (error) {
              this.isLoading = false
              this.alertState= true
              this.alertType= "error",
              this.alertTitle = error.response.data.detail;
            }
            // Handle any error that occurred during the file creation
          });
      },
      editProjectFile(){
      const newPayload = {
        title : this.fileTitle,
        file_path : this.filePath,
        file_url : this.editObject.file_url
      }
      this.isLoading = true
  
        Api()
        .patch(`/projects/project/manage-files/${this.editObject.id}/`, newPayload)
        .then((response) => {
          if(response.data.status === 'success'){
            this.hideDialog()
            this.isLoading = false
            this.alertState= true
            this.alertType= "success"
            this.alertTitle = response.data.updateFile
            this.$store.dispatch("projectModules/getAllProjectFiles", this.isProjectID);
          }
        })
        .catch(error => {
          if (error) {
              this.isLoading = false
              this.alertState= true
              this.alertType= "error",
              this.alertTitle = error.response.data.updateFileError;
            }
        })
      },
  
    },
    created() {
        this.parseQueryParams();
  
      },
  })
  </script>
  <style scoped>
  .drag-window {
    border-radius: 12px;
    padding-top: 50px;
  }
  
  .drag-window:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(139, 41, 35, 0.04);
    border: 1px solid #fdc6c2 !important;
  }
  
  .ae-upload-progress {
    border-radius: 5px;
  }
  
  .ae-bg-green-300 {
    border: 5px solid #fdc6c2 !important;
    border-radius: 12px;
    height: 270px;
    padding-top: 50px;
  }
  </style>
  