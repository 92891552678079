<template>
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #content>
        
      <app-single-select
        id="country"
        :showSearch="true"
        :searchPlaceholder="$t('foreignOffices.searchEmployeePlaceholder')"
        :disabled="isEdit ? true : shouldDisableInput"
        :showCheckbox="false"
        :options="employeeList"
        :labelPlaceholder="$t('foreignOffices.selectEmployeePlaceholder')"
        :label="$t('foreignOffices.staff*')"
        v-model="officeStaffForm.employee_id"
        :hasError="isFieldInvalid('employee_id')"
        :isRequired="true"
      ></app-single-select>
      <app-input-field
        class="mt-3"
        :label="$t('foreignOffices.position*')"
        placeholder="Enter position"
        v-model.trim="officeStaffForm.position"
        :hasError="isFieldInvalid('position')"
        :isRequired="true"
      >
      </app-input-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit ? $t('foreignOffices.update') : $t('foreignOffices.add')
            "
            @click="isEdit ? updateOfficeStaff(): createOfficeStaff()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script>
import { defineComponent } from "vue";
import { getTransString } from "@/services/helpers";
import Api from "@/services/api";
import { createOfficeStaff, updateOfficeStaff } from "@/services/endpoints";
export default defineComponent({
  name: "CreateEditStaff",
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      staffId: "",
      position: "",
      isEdit: false,
      officeStaffForm: {
        employee_id: null,
        foreign_office_id: null,
        position: "",
      },
      isDirty: [],
      requiredFields: ["employee_id", "position"],
    };
  },
  computed: {
    currentPage() {
      return this.$store.getters["foreignOfficeModule/isCurrentPage"];
    },
    officeID() {
      return this.$store.getters["foreignOfficeModule/isOfficeID"];
    },
    employeeList() {
      const modifiedData =
        this.$store.getters["foreignOfficeModule/allEmployees"];

      return modifiedData?.map((employee) => {
        return {
          text: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        };
      });
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog() {
      this.$refs.dialog.showDialog = true;
      this.subTitle = "";
      this.title = this.isEdit
        ? "foreignOffices.editStaff"
        : "foreignOffices.addStaff";
      this.cancelLabel = "foreignOffices.cancel";
    },

    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.subTitle = "";
      this.title = "";
      this.isEdit = false;
      this.hasError = false;
      this.isDirty = [];
      this.officeStaffForm = {};
    },
    getAllOffices() {
      const payload = {
        load_page: false,
        page_number: this.currentPage,
      };
      this.$store.dispatch("foreignOfficeModule/getAllOfficeStaff", payload);
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.officeStaffForm)?.forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.officeStaffForm[key] === "" ||
            this.officeStaffForm[key] === undefined ||
            this.officeStaffForm[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    createOfficeStaff() {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      this.officeStaffForm.foreign_office_id = this.officeID;
      Api()
        .post(`${createOfficeStaff}`, this.officeStaffForm)
        .then((response) => {
          if (response.data.status === "success") {
            this.hideDialog();
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllOffices();
          }
        })
        .catch((error) => {
          if (error) {
            this.hideDialog();
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error.response?.data?.detail;
          }
        });
    },
    updateOfficeStaff() {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      this.officeStaffForm.foreign_office_id = this.officeID;
      Api()
        .put(
          `${updateOfficeStaff}${this.staffId}/`,
          {position: this.officeStaffForm.position}
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.hideDialog();
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllOffices();
          }
        })
        .catch((error) => {
          if (error) {
            this.hideDialog();
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error.response?.data?.detail;
          }
        });
    },
  },
});
</script>
