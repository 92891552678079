<template>
  <div class="mb-2">
    <div class="mb-16" v-if="passedData?.bank_balances">
      <v-row>
        <v-col cols="6">
          <div class="text-lg font-semibold text-gray-800">
            <p>Cash Balance</p>
          </div>
          <v-row>
            <v-col
              cols="3"
              class="font-medium"
              v-for="cash in passedData?.bank_balances"
              :key="cash"
            >
              <div class="mt-1">
                <p class="py-3 text-sm text-gray-500">
                  {{ cash.currency__currency }}
                </p>
                <p class="text-base text-gray-800">
                  {{
                    appLang === "de"
                      ? germanCurrency(cash.bank_balance)
                      : amountFormatting(cash.bank_balance)
                  }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="text-lg font-semibold text-gray-800">
            <p>Bank Balance</p>
          </div>
          <v-row>
            <v-col
              cols="3"
              class="font-medium"
              v-for="bank in passedData?.bank_balances"
              :key="bank"
            >
              <div class="mt-3">
                <p class="text-sm text-gray-500">
                  {{ bank.currency__currency }}
                </p>
                <p class="text-base text-gray-800">
                  {{
                    appLang === "de"
                      ? germanCurrency(bank.bank_balance)
                      : amountFormatting(bank.bank_balance)
                  }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- {{ passedData.average_exchange_rate }} -->
    <app-table
      :hideTableHeader="true"
      :headers="tableHeaders"
      :items="tableItems"
      :hideMenu="true"
    >
    </app-table>
  </div>
</template>
<script>
import { currencyFormatter, formatAmount } from "@/services/helpers";
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    passedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bankCashHeaders: ["Date", "Transfer Type", "Currency", "Amount"],
      currencyExchangeHeaders: [
        "Item No.",
        "Date",
        "Cash/Bank",
        "Source Currency",
        "Source Amount",
        "Target Currency",
        "Target Amount",
        "Exchange Rate",
      ],
      averageExchangeRateHeaders: [
        "Title",
        "Date",
        "Local Currency Amount",
        "Eur Amount",
        "Local Currency Rate",
        "Eur Rate",
      ],
      fundExpenseOverviewHeaders: [
        "Title",
        "Quantity",
        "Amt/Unit",
        "Plan Total",
        "Plan Monthly",
        "Expenses",
        "Difference",
        "Expense till Now",
        "Differences",
        "Carryover",
        "New Monthly",
      ],
    };
  },
  computed: {
    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
    tableHeaders() {
      if (this.passedData?.bank_transfers) {
        return this.bankCashHeaders;
      } else if (this.passedData?.currency_exchanges) {
        return this.currencyExchangeHeaders;
      } else if (this.passedData?.average_exchange_rate) {
        return this.averageExchangeRateHeaders;
      } else {
        return this.fundExpenseOverviewHeaders;
      }
    },
    tableItems() {
      if (this.passedData?.bank_transfers) {
        return this.passedData?.bank_transfers.map((item) => {
          const formattedDate = moment(item.date).format("D.M.YYYY");
          return {
            date: item.date ? formattedDate : ' ',
            "transfer type": item.transfer_type,
            currency: item.transfer_currency__currency,
            amount:
              this.appLang === "de"
                ? this.germanCurrency(item.amount)
                : this.amountFormatting(item.amount),
          };
        });
      } else if (this.passedData?.currency_exchanges) {
        return this.passedData?.currency_exchanges?.map((item, index) => {
          const formattedDate = moment(item.date).format("D.M.YYYY");
          return {
            "item no.": index + 1,
            date: item.date ? formattedDate : ' ',
            "cash/bank": item.transaction_type,
            "source currency": item.source_currency__currency,
            "source amount":
              this.appLang === "de"
                ? this.germanCurrency(item.source_amount)
                : this.amountFormatting(item.source_amount),
            "target currency": item.target_currency__currency,
            "target amount":
              this.appLang === "de"
                ? this.germanCurrency(item.target_amount)
                : this.amountFormatting(item.target_amount),
            "exchange rate": item.rate,
          };
        });
      } else if (this.passedData?.average_exchange_rate) {
        return this.passedData?.average_exchange_rate.map((item) => {
          const formattedDate = moment(item.date).format("D.M.YYYY");
          return {
            title: item.title ? item.title : ' ',
            date: item.date ? formattedDate : ' ',
            "local currency amount":
              this.appLang === "de"
                ? item.local_currency_amount ? this.germanCurrency(item.local_currency_amount) : ' '
                : item.local_currency_amount ? this.amountFormatting(item.local_currency_amount) : ' ',
            "eur amount":
              this.appLang === "de"
                ? item.eur_amount ? this.germanCurrency(item.eur_amount) : ' ' 
                : item.eur_amount ? this.amountFormatting(item.eur_amount) : ' ',
            "local currency rate": this.appLang === "de"
                ? item.local_currency_rate ? this.germanCurrency(item.local_currency_rate) : ' '
                : item.local_currency_rate ? this.amountFormatting(item.local_currency_rate) : ' ', 
            "eur rate": this.appLang === "de"
                ? item.eur_rate ? this.germanCurrency(item.eur_rate) : ' '
                : item.eur_rate ? this.amountFormatting(item.eur_rate) : ' ',
          };
        });
      } else {
        return this.passedData?.map((item) => {
          return {
            title: item.budget__budget_item__position,
            quantity: item.budget_quantity,
            "amt/unit": item.amount_per_unit,
            "plan total":
              this.appLang === "de"
                ? this.germanCurrency(item.plan_total)
                : this.amountFormatting(item.plan_total),
            "plan monthly": item.plan_monthly,
            expenses:
              this.appLang === "de"
                ? this.germanCurrency(item.total_taxed)
                : this.amountFormatting(item.total_taxed),
            difference:
              this.appLang === "de"
                ? this.germanCurrency(item.difference)
                : this.amountFormatting(item.difference),
            "expense till now":
              this.appLang === "de"
                ? this.germanCurrency(item.total_expenses_till_now)
                : this.amountFormatting(item.total_expenses_till_now),
            differences:
              this.appLang === "de"
                ? this.germanCurrency(item.second_difference)
                : this.amountFormatting(item.second_difference),
            carryover:
              this.appLang === "de"
                ? this.germanCurrency(item.carry_over)
                : this.amountFormatting(item.carry_over),
            "new monthly":
              this.appLang === "de"
                ? this.germanCurrency(item.new_monthly)
                : this.amountFormatting(item.new_monthly),
          };
        });
      }
    },
  },
  methods: {
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
  },
});
</script>
