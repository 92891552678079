import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  postContractTaskUrl,
  getAllContractTaskUrl,
  putContractTaskUrl,
  getOneContractTaskUrl,
  deleteContractTaskUrl,
  searchContractTaskUrl,
  getAllEmployeesUrl,
  getAllContractApproversUrl,
  postContractApproversUrl,
} from "./../../services/endpoints";

interface Payload {
  task_id?: string | number;
  title?: string;
  content?: string;
}

interface Page {
  pageNumber?: string | number;
  searchQuery?: string;
}

interface ApproverPayload {
  first_approver_id: string;
  second_approver_id: string;
}

export default {
  namespaced: true,
  state: {
    error: {},
    response: {},
    contractTasks: [],
    employees: [],
    approvers: {},
    isConfigurationsUpdate: {},
    current_page: 1,
    total_data: 1
  },
  getters: {
    getError(state: any) {
      return state.error;
    },
    isTotalPages(state: any) {
      return state.total_data;
    },
    isCurrentPage(state: any) {
      return state.current_page;
    },
    getResponse(state: any) {
      return state.response;
    },
    getContractTasks(state: any) {
      return state.contractTasks;
    },
    getEmployees(state: any) {
      return state.employees;
    },
    getApprovers(state: any) {
      return state.approvers;
    },
    isConfigurationsUpdate(state: any) {
      return state.isConfigurationsUpdate;
    },
  },
  mutations: {
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    SET_CONTRACT_TASKS(state: any, data: any) {
      state.contractTasks = data
    },
    REMOVE_CONTRACT_TASK(state: any, taskIdToRemove: number | string) {
      const indexToRemove = state.contractTasks?.data?.findIndex(
        (task: { id: string | number }) => task.id === taskIdToRemove
      );
      if (indexToRemove !== -1) {
        state.contractTasks?.data?.splice(indexToRemove, 1);
      }
    },
    SET_EMPLOYEES(state: any, data: any) {
      state.employees = [...data];
    },
    SET_APPROVERS(state: any, data: any) {
      state.approvers = { ...data };
    },
    SET_CURRENT_PAGE(state: any, data: any) {
      state.current_page = data;
    },
    SET_TOTAL_PAGE(state: any, data: any) {
      state.total_data = data ;
    },
    SET_CONFIGURATIONS_UPDATE(state: any, payload: any) {
      state.isConfigurationsUpdate = { ...payload };
    },
  },
  actions: {
    async getAllContractTasks(
      { commit }: ActionContext<any, any>,
      payload: Page
    ) {
      try {
        const response = await Api().get(
          `${getAllContractTaskUrl}${payload.pageNumber}`
        );
        
        commit("SET_CONTRACT_TASKS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_CONTRACT_TASKS", {});
      }
    },

    async createContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().post(postContractTaskUrl, {
          title: payload.title,
          content: payload.content,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async updateContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().put(
          `${putContractTaskUrl}${payload.task_id}/`,
          {
            title: payload.title,
            content: payload.content,
          }
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async deleteContractTask(
      { state, commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().delete(
          `${deleteContractTaskUrl}${payload.task_id}`
        );

        commit("SET_RESPONSE", data.data);
        if (state.response?.status === "success") {
          commit("REMOVE_CONTRACT_TASK", payload.task_id); //remove from store
        }
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async getOneContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().get(
          `${getOneContractTaskUrl}${payload.task_id}`
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async searchContractTasks(
      { commit }: ActionContext<any, any>,
      payload: Page
    ) {
      try {
        const response = await Api().post(
          `${searchContractTaskUrl}${payload.pageNumber}/`,
          {
            search_query: payload.searchQuery,
          }
        );
        commit("SET_CONTRACT_TASKS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_CONTRACT_TASKS", {});
      }
    },

    async getAllEmployees({ commit }: ActionContext<any, any>) {
      try {
        const data = await Api().get(`${getAllEmployeesUrl}`);

        commit("SET_EMPLOYEES", data?.data?.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_EMPLOYEES", []);
      }
    },

    async createOrUpdateContractApprovers(
      { commit }: ActionContext<any, any>,
      payload: ApproverPayload
    ) {
      try {
        const data = await Api().post(postContractApproversUrl, {
          first_approver_id: payload.first_approver_id,
          second_approver_id: payload.second_approver_id,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async getAllContractApprovers({ commit }: ActionContext<any, any>) {
      try {
        const data = await Api().get(`${getAllContractApproversUrl}`);
        commit("SET_APPROVERS", data?.data?.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_APPROVERS", {});
      }
    },
  },
};
