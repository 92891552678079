<template>
  <div>
    <app-header
      :name="isProjectID ? isProjectTitle : $t('projects.editProject')"
      class="mb-5 ml-n2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
      <template #action v-if="isProjectID">
        <span v-show="isBudgetMode">
          <div
            v-if="
              currentTab === 'ProjectBudget' &&
              !isEmptyBudgetState &&
              isEditButton
            "
            class="d-flex"
          >
            <app-secondary-button
              class="mr-4"
              :disabled="isEnabledButton"
              :buttonLabel="$t('projectBudget.cancel')"
              @click="cancelEditBudgetItem"
            >
            </app-secondary-button>
            <app-primary-button
              :disabled="isEnabledButton"
              :buttonLabel="$t('projectBudget.save')"
              @click="
                budgetLineItemChanged && isSplitted
                  ? showConfirmChanges()
                  : createUpdateBudgetLineItem()
              "
            >
            </app-primary-button>
          </div>
        </span>
        <app-secondary-button
          v-show="!isEditButton && !showClosedProject"
          :buttonLabel="$t('projects.editProject')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
        <v-row v-if="isEditButton && currentTab === 'ProjectLocalBudget'">
          <v-col cols="6">
            <div>
              <app-secondary-button 
              :buttonLabel="$t('generalConfig.cancel')"
              @click="hideSaveCancel"
              >
              </app-secondary-button>
            </div>
          </v-col>
          <v-col cols="6">
            <div>
              <app-primary-button
              :buttonLabel="$t('projects.save')"
              @click="editLocalBudget"
            >
            </app-primary-button>
            </div>
          </v-col>
        </v-row>
      </template>
    </app-header>
  
    <div>
      <span>
        <button
          v-for="(tab, index) in filteredTabs()"
          :key="index"
          :class="['tab-button', { active: currentTab === tab.id }]"
          @click="handleProceed(tab.id, tab.name)"
        >
          {{ $t(getTranslatedStringKey(tab.name)) }}
        </button>
      </span>
      <div class="mt-2 border-t-[1px]"></div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-10"
      :handleButtonRoute="handleButtonRoute"
      :ref="currentTab"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />

  
  <app-dialog ref="confirmChanges" :title="$t('projectBudget.confirmChanges')">
    <template #content>
      <div class="confirmList">
        <ul>
          <li>{{ $t("projectBudget.confirmItem1") }}</li>
          <li>{{ $t("projectBudget.confirmItem2") }}</li>
          <li>{{ $t("projectBudget.confirmItem3") }}</li>
        </ul>
      </div>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog1"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('projectBudget.confirm')"
            @click="createUpdateBudgetLineItem"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ProjectMission from "@/components/dashboard/projects/ProjectMission.vue";
import Contracts from "@/components/dashboard/projects/contracts/Contracts.vue";
import { getTransString } from "@/services/helpers";
import { mapState, mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "Edit Project",
  components: {
    ProjectMission,
    Contracts,
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      currentTab: "",
      tabList: [
        "Contracts",
        "Missions",
      ],
      tabs: [
        "Contracts",
        "ProjectMission",
        
      ],
      newTabList: [
        
        { id: "Contracts", name: "Contracts" },
        { id: "ProjectMission", name: "Missions" },
        
      ],
    };
  },

  computed: {
    ...mapGetters("projectBudgetModules", [
      "getError",
      "getResponse",
      "isBudgetData",
      "getShowLocalBudget"
    ]),
    ...mapGetters("projectLocalBudgetModule", ["getError", "getResponse", "getProjectLocalBudgetData"]),
    isMileStoneData() {
      return this.$store.getters["projectBudgetModules/isMileStoneData"];
    },
    isSplitType() {
      return this.$store.getters["projectBudgetModules/isSplitType"];
    },
    isPreviewToProfile() {
      return this.$store.getters["contractModules/isPreviewToProfile"];
    },
    isFromHomeToEditProfile(){
      return this.$store.getters["projectModules/getFromHomeToEditProject"]
    },
    isBudgetData() {
      return this.$store.getters["projectBudgetModules/isBudgetData"];
    },
    isBudgetSections() {
      const budgetItems =
        this.$store.getters["projectBudgetModules/isBudgetSections"];
      const modifiedData = budgetItems.map(
        (budgetItem) => budgetItem.budget_items
      );

      return modifiedData;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    showTabs() {
      return this.$store.getters["projectModules/showTabs"];
    },
    isSubCostCenter() {
      return this.$store.getters["projectModules/isSubCostCenter"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isProjectTitle() {
      return this.$store.getters["projectModules/isProjectTitle"];
    },
    isEnabledButton() {
      return this.$store.getters["projectBudgetModules/isEnabledButton"];
    },
    isProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    showLocalBudget() {
      return this.isCurrentProject.show_local_budget;
    },
    isBudgetMode() {
      return this.$store.getters["projectBudgetModules/isBudgetMode"];
    },
    isEmptyBudgetState() {
      return this.$store.getters["projectBudgetModules/isEmptyBudgetState"];
    },
    showClosedProject() {
      const closedProject =
        this.$store.getters["projectModules/isCurrentProject"];
      return (
        this.$route.path === "/edit-project" &&
        closedProject.project_status === "Closed"
      );
    },
    isProjectUpdate() {
      return this.$store.getters["projectModules/isProjectUpdate"];
    },

    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },
    budgetLineItemChanged(){
      if("ProjectBudget" in this.isProjectUpdate) {
        return this.isProjectUpdate.ProjectBudget
      }
      return false
    },
    isSplitted() {
      return this.$store.getters["projectBudgetModules/isSplitted"];
    },
    ...mapState(["translation"]),
  },
  methods: {
    ...mapActions("projectBudgetModules", [
      "updateBudgetSectionLineItems",
      "fetchAllBudgetSection",
    ]),
    ...mapActions("projectLocalBudgetModule", ["updateLocalBudget"]),
    async editLocalBudget() {
      await this.updateLocalBudget({project: this.isProjectID, request: this.getProjectLocalBudgetData})

      if(this.getResponse.status == "success") {
        this.alertType = this.getResponse.status;
        this.alertState = true;
        this.alertTitle = this.getResponse.detail;
      }else {
        this.alertType = 'error';
        this.alertState = true;
        this.alertTitle = "The sum of all quantities cannot exceed the total quantity.";
      }

    },
    hideSaveCancel() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
    },
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    cancelEditBudgetItem() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
    },
    filteredTabs() {    
      let newTabs = this.newTabList;
      return newTabs;
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isProjectUpdate).some((elt) => elt === true);
    },
    handleProceed(id, tabName) {
      this.newTabIndex = id;
      const currentTabStatus = this.isProjectUpdate[tabName];
      if (currentTabStatus) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = id;
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.isProjectUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    handleGoToBack() {
      if (Object.values(this.isProjectUpdate).some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isProjectUpdate).reduce(
        (acc, curr) => {
          if (this.isProjectUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("projectModules/SET_PROJECT_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.newTabIndex;
      }
    },
    async createUpdateBudgetLineItem() {
      let budgetSectionData = this.assignRowIndexToBudgetSection(
        this.isBudgetData
      );
      this.$refs.confirmChanges.showDialog = false;
      await this.updateBudgetSectionLineItems({
        project: this.isProjectID,
        request: budgetSectionData,
      });
      
      if (this.$store.getters['projectBudgetModules/getResponse'].status === "success") {
        this.alertType = "success";
        this.alertState = !this.alertState;
        this.alertTitle = this.$store.getters['projectBudgetModules/getResponse'].detail;
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });

        await this.fetchAllBudgetSection({ project: this.isProjectID });
        this.$store.commit("projectModules/SET_LOCAL_BUDGET_TAB", this.getShowLocalBudget);
      } else {
        const error = this.getBudgetLineItemError(this.$store.getters['projectBudgetModules/getError']);

        this.alertType = "error";
        this.alertState = !this.alertState;
        this.alertTitle = error?.message;

        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          ProjectBudget: false,
        });
      }
    },

    closeDialog1() {
      this.$refs.confirmChanges.showDialog = false;
    },

    showConfirmChanges() {
      this.$refs.confirmChanges.showDialog = true;
    },


    getBudgetLineItemError(errorObject) {
      let indexOfNonEmpty = null;
      let data = errorObject.data;
      for (let i = 0; i < data.length; i++) {
        if (Object.keys(data[i]).length > 0) {
          // Check if the object is not empty
          indexOfNonEmpty = i;
          break;
        }
      }

      // If a non-empty object is found, get the budget_items and find the first non-empty item
      if (indexOfNonEmpty !== null) {
        const nonEmptyObject = data[indexOfNonEmpty];
        const budgetItems = nonEmptyObject["budget_items"] || [];

        // Find the first non-empty item in budget_items
        const nonEmptyItem = budgetItems.find(
          (item) => Object.keys(item).length > 0
        );

        if ("structure" in nonEmptyItem) {
          return {
            field: "structure",
            sectionIndex: indexOfNonEmpty,
            message: "Please Select a Structure",
          };
        } else if ("amount_per_unit" in nonEmptyItem) {
          return {
            field: "amount_per_unit",
            sectionIndex: indexOfNonEmpty,
            message: "Amount Per Unit is Required",
          };
        } else if ("number_of_units" in nonEmptyItem) {
          return {
            field: "number_of_units",
            sectionIndex: indexOfNonEmpty,
            message: "Number of Units is Required",
          };
        }
        return {
          field: "unknown",
          sectionIndex: 0,
          message: "Some Required Fields are Missing",
        };
      }
    },

    assignRowIndexToBudgetSection(budgetSectionDataFromStore) {
      if (budgetSectionDataFromStore.length > 0) {
        // Clone the budget_sections array to avoid modifying the original data
        const clonedBudgetSections = [...budgetSectionDataFromStore];

        // Assign row index to each element in the cloned array
        clonedBudgetSections.forEach((section) => {
          section?.budget_items?.forEach((item, index) => {
            item.row_index = index;
          });
        });

        return clonedBudgetSections;
      }
      return budgetSectionDataFromStore;
    },
  },
  mounted() {
    if(this.isPreviewToProfile)
    {
      this.currentTab = this.tabs[6];
    }
    else if(this.isFromHomeToEditProfile) {
      this.currentTab = this.tabs[1];
    }
    else{
      this.currentTab = this.tabs[0];
    }
    this.$refs.unsavedChanges.showDialog = false;
  },
  updated() {
    this.currentTab = this.newTabList[this.isCurrentTab].id;
  },
  created() {
    const tabId = 0;
    this.$store.commit(
      "commonResources/SET_CURRENT_TAB_TO_STATE",
      parseInt(tabId)
      );
      this.currentTab = this.newTabList[0].id;
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>

<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
.tab-button.disabled {
  cursor: not-allowed;
}

.confirmList > ul {
  list-style-type: disc;
  color: #667085;
  padding-left: 1rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
