<template>
  <div>
    <div>
      <app-table
        :headers="interimPaymentHeaders"
        :items="isInterimPayment"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        :showTotal="true"
        :showCursor="false"
        :plannedTotalLabel="$t('projects.plannedInterimAmt')"
        :totalBudgetLabel="$t('projects.totalBudget')"
        :reductionTotalLabel="$t('projects.reductionOfAdvancePay')"
        :totalInterimLabel="$t('projects.budgetDifference')"
        :totalAdvanceLabel="$t('projects.totalAdvancePayment')"
        :advanceTotalLabel="$t('projects.advanceDifference')"
        :plannedTotal="
          appLang === 'de'
            ? germanCurrency(isContractDetails?.total_interim_payment)
            : amountFormatting(isContractDetails?.total_interim_payment)
        "
        :totalBudget="
          appLang === 'de'
            ? germanCurrency(isContractDetails?.project_amount)
            : amountFormatting(isContractDetails?.project_amount)
        "
        :reductionTotal="
          appLang === 'de'
            ? germanCurrency(isContractDetails.total_reduction_to_bank)
            : amountFormatting(isContractDetails.total_reduction_to_bank)
        "
        :totalInterim="
          appLang === 'de'
            ? germanCurrency(isContractDetails.budget_difference)
            : amountFormatting(isContractDetails.budget_difference)
        "
        :advanceTotal="
          appLang === 'de'
            ? germanCurrency(isContractDetails.payment_difference)
            : amountFormatting(isContractDetails.payment_difference)
        "
        :totalAdvance="
          appLang === 'de'
            ? germanCurrency(isContractDetails.total_advance_payment)
            : amountFormatting(isContractDetails.total_advance_payment)
        "
        :currency="isCurrency"
        :currencyBudget="isCurrency"
        :currencyReduction="isCurrency"
        :currencyInterim="isCurrency"
      >
        <template #title>
          <h3 class="H500">
            {{ $t("projectConfiguration.allInterimPayment") }}
          </h3>
        </template>
        <template #actions>
          <app-primary-button
            :buttonLabel="$t('projectConfiguration.addInterimPayment')"
            @click-event="showDialog({ data: 'add' })"
            :disabled="shouldDisableInput ? 'disabled' : null"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div class="flex justify-between w-full">
            <div
              v-show="
                isContractDetails?.total_interim_payment !==
                isContractDetails?.project_amount
              "
              class="text-[12px] font-medium pt-2 flex justify-start"
            >
              <span
                class="h-[22px] px-[3px] py-[2px] rounded-lg text-[#B54708] bg-[#FFFAEB]"
                >{{ $t("projects.interimEqualTotal") }}</span
              >
            </div>
            <div
              v-show="
                isContractDetails?.total_advance_payment !==
                isContractDetails?.total_reduction_to_bank
              "
              class="text-[12px] font-medium pt-2"
            >
              <span
                class="h-[22px] px-[3px] py-[2px] rounded-lg text-[#B54708] bg-[#FFFAEB]"
                >{{ $t("projects.paymentEqualTotal") }}</span
              >
            </div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
              class="flex justify-end"
            />
          </div>
        </template>
      </app-table>
    </div>
  </div>

  <app-dialog
    ref="dialogState"
    :title="
      isEdit
        ? $t('projectConfiguration.updateBankTitle')
        : $t('projectConfiguration.addInterimPayment')
    "
    width="408"
  >
    <template #content>
      <app-input-field
        class="mb-2"
        :label="$t('projectConfiguration.plannedAmt') + '*'"
        :placeholder="$t('projects.enterPlannedAmount')"
        v-model.number="interimPayment.amount"
        validation="floatingNumber"
        :hasError="isFieldInvalid('amount')"
        :isRequired="true"
      >
      </app-input-field>
      <app-input-field
        class="mb-2"
        :label="$t('projects.reduceOfAdvancePay')"
        :placeholder="$t('projects.reductionPaymentLabel')"
        v-model.number="interimPayment.reductionAmt"
        validation="floatingNumber"
        :hasError="isGreater"
        :isRequired="true"
        :errorMessage="errorMessage"
      >
      </app-input-field>
      <app-date-field
        class="mb-2"
        :label="$t('projectConfiguration.provisionOf') + '*'"
        v-model.trim="interimPayment.date"
        type="date"
        :hasError="isFieldInvalid('date')"
        :isRequired="true"
      >
      </app-date-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit
                ? $t('addcustomrole.saveChanges')
                : $t('projectConfiguration.save')
            "
            @click-event="isEdit ? updateInterimPayment() : addInterimPayment()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteInterimPayment"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import api from "@/services/api";
import {
  addOrUpdateInterimPayment,
  deleteInterimPayment,
  fetchInterimPayment,
} from "@/services/endpoints";
import {
  currencyFormatter,
  extractUnderscore,
  formatAmount,
  getTransString,
} from "@/services/helpers";
import Paginator from "@/components/shared/PaginationComponent.vue";
import moment from "moment";
import { mapState } from "vuex";

export default defineComponent({
  name: "InterimPayments",
  components: {
    Paginator,
  },
  data() {
    return {
      interimPayment: {
        amount: "",
        reductionAmt: "",
        date: "",
        contract_id: "",
      },
      interimPaymentHeaders: [
        "Sr. No.",
        "Planned Amount",
        "Advance Payment Reduction",
        "Provision of Service up to Date",
      ],
      alertType: "success",
      alertState: false,
      alertTitle: "",
      interimTotal: "",
      reductionOfAdvance: "",
      totalBudget: "",
      budgetDifference: "",
      isEdit: false,
      currentPages: 1,
      title: "",
      subTitle: "",
      // isGreater: false,
      errorMessage: "",
      requiredFields: ["amount", "date"],
      isDirty: [],
      hasError: "",
    };
  },
  computed: {
    isContractID() {
      return this.$store.getters["projectModules/isContractId"];
    },
    currentPage() {
      return this.$store.getters["projectModules/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["projectModules/isInterimPaymentTotalPages"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isCurrency() {
      return this.isCurrentProject?.currency;
    },
    isInterimPayment() {
      const newKey = "sr. no.";
      const modifiedData =
        this.$store.getters["projectModules/isInterimPayment"];
      modifiedData?.map((obj) => {
        obj[newKey] = obj["sr no"];

        delete obj["sr no"];
        for (let key in obj) {
          if (typeof obj[key] === "boolean") {
            obj[key] = obj[key] ? "Yes" : "No";
          }
        }
      });
      return modifiedData;
    },
    isContractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-project" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  watch: {
    // isContractID(value) {
    //   this.payload.contract_id = value;
    // },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.interimPayment).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.interimPayment[key] === "" ||
            this.interimPayment[key] === undefined ||
            this.interimPayment[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.isEdit = false;
      this.isDirty = [];
      this.interimPayment.amount = "";
      this.interimPayment.date = "";
      this.interimPayment.reductionAmt = "";
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    showDialog(data) {
      if (data.data === "add") {
        this.isEdit = false;
        this.interimPayment.amount = "";
        this.interimPayment.reductionAmt = "";
        this.interimPayment.date = "";
        this.$refs.dialogState.showDialog = true;
      } else if (data.data === "delete") {
        this.interimPaymentID = data?.$event.id;
        this.$refs.dialog.showDialog = true;
        this.title = "projectConfiguration.deleteInterimPayment";
        this.subTitle = "projectConfiguration.deleteInterimPaymentConfirm";
      }
      const newdata = data?.$event;
      if (data.data === "edit") {
        this.interimPaymentID = data?.$event.id;
        const modifiedData = {};
        for (const key in newdata) {
          const newKey = key.replace(/ /g, "_");
          modifiedData[newKey] = newdata[key];
        }
        this.subTitle = "";
        this.isEdit = true;
        this.$refs.dialogState.showDialog = true;
        parseFloat(modifiedData.planned_amount?.replace(/,/g, ""));
        this.interimPayment.amount = parseFloat(
          modifiedData.planned_amount?.replace(/,/g, "")
        );
        this.interimPayment.date = moment(
          modifiedData.provision_of_service_up_to_date,
          "Do MMM YYYY"
        ).format("YYYY-MM-DD");
        this.interimPayment.reductionAmt = parseFloat(
          modifiedData.advance_payment_reduction?.replace(/,/g, "")
        );
      }
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    getInterimPayment(page) {
      api()
        .get(fetchInterimPayment + `${page}` + `/${this.isContractID}`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;
            const modifiedData = extractUnderscore(data);
            modifiedData.map((dateFormat) => {
              dateFormat["provision of service up to date"] = moment(
                dateFormat["provision of service up to date"]
              ).format("Do MMM YYYY");
              const formattedPlannedAmount = formatAmount(
                dateFormat["planned amount"]
              );
              dateFormat["planned amount"] = formattedPlannedAmount;
              const formatAdvanceReduction = formatAmount(
                dateFormat["advance payment reduction"]
              );
              dateFormat["advance payment reduction"] = formatAdvanceReduction;

              return {
                dateFormat,
                formattedPlannedAmount,
                formatAdvanceReduction,
              };
            });
            this.$store.commit(
              "projectModules/SET_INTERIM_PAYMENT_TO_STATE",
              modifiedData
            );
            this.$store.commit(
              "projectModules/SET_INTERIM_PAYMENT_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    getContractDetails() {
      const payload = {
        projectID: this.isProjectID,
      };
      this.$store.dispatch("projectModules/getContractDetails", payload);
    },
    addInterimPayment() {
      this.isGreater = false;
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      if (this.interimPayment.amount < this.interimPayment.reductionAmt) {
        this.isGreater = true;
        this.errorMessage =
          "Reduction of Advance Payment should be less than Planned Amount";
        return;
      }
      const payload = {
        contract_id: this.isContractID,
        planned_amount: this.interimPayment.amount,
        provision_of_service_up_to: this.interimPayment.date,
        reduction_of_the_advance_payment:
          this.interimPayment.reductionAmt === ""
            ? 0
            : Number(this.interimPayment.reductionAmt),
      };
      api()
        .post(addOrUpdateInterimPayment, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            (this.alertType = "success"), (this.alertState = true);
            this.isGreater = false;
            this.alertTitle = response?.data?.detail;
            this.getContractDetails();
            this.getInterimPayment(this.currentPage);
            this.interimPayment.amount = "";
            this.interimPayment.date = "";
            this.interimPayment.reductionAmt = "";
          }
        })
        .catch((error) => {
          if (error.response.data.code === "313") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
          if (error) {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response?.data?.detail;
          }
        });
    },
    updateInterimPayment() {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      this.isGreater = false;
      if (this.interimPayment.amount < this.interimPayment.reductionAmt) {
        this.isGreater = true;
        this.errorMessage =
          "Reduction of Advance Payment should be less than Planned Amount";
        return;
      }
      const updatePayload = {
        interim_payment_id: this.interimPaymentID,
        contract_id: this.isContractID,
        planned_amount:
          this.interimPayment.amount === ""
            ? 0
            : Number(this.interimPayment.amount),
        provision_of_service_up_to: this.interimPayment.date,
        reduction_of_the_advance_payment:
          this.interimPayment.reductionAmt === ""
            ? 0
            : Number(this.interimPayment.reductionAmt),
      };
      api()
        .post(addOrUpdateInterimPayment, updatePayload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.isEdit = false;
            this.alertState = true;
            this.isGreater = false;
            this.alertType = "success";
            this.alertTitle = response?.data?.detail;
            this.getContractDetails();
            this.getInterimPayment(this.currentPage);
            this.interimPayment.amount = "";
            this.interimPayment.date = "";
            this.interimPayment.reductionAmt = "";
          }
        })
        .catch((error) => {
          if (error.response.data.code === "313") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
          if (error) {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response?.data?.detail;
          }
        });
    },
    deleteInterimPayment() {
      api()
        .delete(deleteInterimPayment + `${this.interimPaymentID}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertState = true;
            (this.alertType = "success"),
              (this.alertTitle = response?.data?.detail);
            this.getContractDetails();
            this.getInterimPayment(this.currentPage);
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getInterimPayment(page);
    },
  },
  mounted() {
    this.$store.commit("projectModules/SET_INTERIM_PAYMENT_TO_STATE", []);
    // this.getContractDetails();
  },
  created() {
    this.getInterimPayment(this.currentPage);
  },
});
</script>

<style></style>
