import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/dashboard/home/HomeView.vue";
import Employees from "../views/dashboard/hr-resources/Employees.vue";
import EditEmployee from "../views/dashboard/hr-resources/EditEmployee.vue";
import Profile from "../views/dashboard/profile/Profile.vue";
import HrLeave from "../views/dashboard/hr-resources/HrLeave.vue";
import Partners from "../views/dashboard/partners/Partners.vue";
import Projects from "../views/dashboard/projects/Projects.vue";
import Experts from "../views/dashboard/experts/Experts.vue";
import AddExpert from "../views/dashboard/experts/AddExpert.vue";
import EditExpert from "../views/dashboard/experts/EditExpert.vue";
import AddPartner from "../views/dashboard/partners/AddPartner.vue";
import AddEmployee from "../views/dashboard/hr-resources/AddEmployee.vue";
import AddProject from "../views/dashboard/projects/AddProject.vue";
import EditPartner from "../views/dashboard/partners/EditPartner.vue";
import EditProject from "../views/dashboard/projects/EditProject.vue";
import ContractPreview from "../views/dashboard/projects/ContractPreview.vue";
import Announcements from "@/views/dashboard/hr-resources/MyAnnouncements.vue";
import AccessDenied from '@/components/authentication/AccessDenied.vue'
import ProjectConfiguration from "../views/dashboard/settings/ProjectConfiguration.vue"
import DeploymentAgreement from "../views/dashboard/projects/DeploymentAgreement.vue"
import ForeignOffices from "@/views/dashboard/foreign-office/ForeignOffices.vue"
import AddForeignOffice from "@/views/dashboard/foreign-office/AddForeignOffice.vue"
import EditForeignOffice from "@/views/dashboard/foreign-office/EditForeignOffice.vue"
import ForeignEmployees from "@/views/dashboard/foreign-employees/ForeignEmployees.vue"
import AddForeignEmployee from "@/views/dashboard/foreign-employees/AddForeignEmployee.vue"
import EditForeignEmployee from "@/views/dashboard/foreign-employees/EditForeignEmployee.vue"
import PmLocals from "@/views/dashboard/pm-locals/PmLocals.vue"
import EditPmLocal from "@/views/dashboard/pm-locals/EditPmLocal.vue"
import RequestDetails from "@/views/dashboard/profile/RequestDetails.vue"  
import PreviewAndSign from "@/views/dashboard/profile/PreviewAndSign.vue";

import ExpertContractSign from "@/components/dashboard/projects/contracts/contract-preview/ExpertSignature.vue";
import { moduleNames } from "@/services/permissions";
// import { hasTokenExpired } from "@/services/token";
// import store from "@/store";
// import BasicInfo from "@/components/dashboard/projects/BasicInfo.vue";
// import ProjectFiles from "@/components/dashboard/projects/ProjectFiles.vue";
import { isLoggedIn } from "@/services/auth";

function requireAuth(to: any, from: any, next: any) {
  if (isLoggedIn()) {
    next();
  } else {
    next({
      name: "login",
      // query: { redirect: to.fullPath }
    });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },

  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-flight-request-details",
    name: "flightRequestDetails",
    component: PreviewAndSign,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/view-advance-payments",
    name: "viewAdvancePayments",
    component: RequestDetails,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/leaves",
    name: "leaves",
    component: HrLeave,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Leave"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/experts",
    name: "experts",
    component: Experts,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/partners",
    name: "partners",
    component: Partners,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/foreign-employees",
    name: "foreignEmployees",
    component: ForeignEmployees,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-foreign-employee",
    name: "addForeignEmployee",
    component: AddForeignEmployee,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
  path: "/expert-contract-approval",
  name: "expertcontractapproval",
  component:ExpertContractSign,
  meta: {
    allowAnonymous:true,
    layout: "blank",
    permissions:[],
  },
  },
  {
    path: "/edit-foreign-employee",
    name: "editForeignEmployee",
    component: EditForeignEmployee,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/foreign-office",
    name: "foreignOffice",
    component: ForeignOffices,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-foreign-office",
    name: "addForeignOffice",
    component: AddForeignOffice,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-foreign-office",
    name: "editForeignOffice",
    component: EditForeignOffice,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/employees",
    name: "employees",
    component: Employees,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-employee",
    name: "editEmployee",
    component: EditEmployee,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: Announcements,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-expert",
    name: "editExpert",
    component: EditExpert,

    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-partner",
    name: "editPartner",
    component: EditPartner,

    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-employee",
    name: "addEmployee",
    component: AddEmployee,

    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-expert",
    name: "addExpert",
    component: AddExpert,

    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-partner",
    name: "addPartner",
    component: AddPartner,

    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Experts & Partners"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/add-project",
    name: "addProject",
    component: AddProject,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-project",
    name: "editProject",
    component: EditProject,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/contract-approval",
    name: "contract-approval",
    component: ContractPreview,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/deployment-agreement",
    name: "deployment-agreement",
    component: DeploymentAgreement,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/pm-locals",
    name: "pmLocals",
    component: PmLocals,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Local Office Project Overview"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/edit-pm-local",
    name: "editPmLocal",
    component: EditPmLocal,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Local Office Project Overview"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/hr-configuration",
    name: "HRConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "hr-settings" */ "../views/dashboard/settings/HRConfiguration.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },

  {
    path: "/project-configuration",
    name: "ProjectConfiguration",
    component: ProjectConfiguration,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: ["Projects"],
    },
    beforeEnter: requireAuth,
  },

  {
    path: "/access-role",
    name: "access-role",
    component: () =>
      import(
        /* webpackChunkName: "hr-settings" */ "../views/dashboard/settings/AccessRole.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/access-settings",
    name: "access-settings",
    component: () =>
      import(
        /* webpackChunkName: "access-settings" */ "../views/dashboard/settings/AddCustomRole.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "dashboard",
      permissions: ["Human Resource"],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/authentication/Login.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/authentication/ForgotPassword.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },

  {
    path: "/access-denied",
    name: "accessDenied",
    component: AccessDenied,
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../components/authentication/ResetPassword.vue"
      ),
    meta: {
      allowAnonymous: true,
      layout: "blank",
      permissions: [],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const allowAnonymous: any = to.meta.allowAnonymous;
  const requiredPermissions: any = to.meta.permissions;
  if (allowAnonymous) {
    // Allow access to routes that don't require authentication
    next();
  } else if (isLoggedIn()) {
    const userPermissions: string[] = moduleNames.value;
    if (!requiredPermissions || !requiredPermissions.length) {
      // If there are no permissions required for this route, proceed
      next();
    } else if (
      userPermissions &&
      requiredPermissions.every((permission: string) =>
        userPermissions.includes(permission)
      )
    ) {
      // If the user has all required permissions, proceed
      next();
    }
     else {
      // If the user doesn't have the required permissions, redirect to an "access denied" route
      next(
        {
          name: "accessDenied", // Adjust this to whatever your access denied route name is
        }
      );
    }
  } else {
    // If the user isn't logged in, redirect to login page
    next(
      to.name === "expertcontractapproval" 
        ? { name: "expertcontractapproval" }
        : { name: "login" }
    );
    
  }
});

export default router;
