<script lang="js">
import { defineComponent } from "vue";
import EmptyState from "@/assets/icons/EmptyState.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";


// type Header = {
//   key: string;
//   label: string;
//   type?: string;
// };

export default defineComponent({
  name: "AppCustomTable",

  components: {Paginator, EmptyState },
  props: {
    tableName: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    rowStyles: {
      type: String || Function,
      default: "",
    },
    columnStyles: {
      type: Object,
      default: () => ({}),
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    columnWidths: {
      type: Object,
      default: () => ({}),
    },
    doubleHeader: {
      type: Boolean,
      default: false,
    },
    topHeaders: {
      type: Array,
      default: () => [],
    },
    showActionBtn: {
      type: Boolean,
      default: false,
    },
    actionBtnText: {
      type: String,
      default: "",
    },
    actonBtnIcon: {
      type: String,
      default: "",
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    loadMorePaginationData: {
      type: Function,
      default: () => ({}),
    },
    isDeleteIcon: {
      type: Boolean,
      default: false,
    },
    hideLastIcon: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    itemsTotal: {
      type: Number,
      default: 0,
    },
    styles: {
      type: String,
      default: "",
    },
    headerStyles: {
      type: String,
      default: "",
    },
    scrollHorizontally: {
      type: Boolean,
      default: false,
    },
    scrollVertically: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hoveredRow: -1,
      currentPageRef: this.currentPage
    };
  },
  emits: ["menu-click", "item-click", "menu-click"],
  computed: {
    getColumWidth() {
      return (header) => {
        return this.columnWidths[header.key] || "auto";
      };
    },
    getOverAllTotal() {
      return 0
      // return this.items.reduce((acc : any, item: any) => {
      //   return acc + Number(item.total_amount)
      // }, 0)
    },
    statusBackgroundColor(){
      return (status) => {
        return status === "Paid" ? "bg-[#ECFDF3] text-[#027A48]" : "bg-[#FFFAEB] text-[#B54708]";
      }
    },
    locale() {
      return this.$store.getters["translation/getLocale"]
    },
  },
  methods: {
    showDeleteIcon(index) {
      this.hoveredRow = index;
    },
    hideDeleteIcon() {
      this.hoveredRow = -1;
    },
  },
});
</script>

<template>
  <div
    class="w-full relative !z-50 border-2 border-gray-100 rounded-lg bg-white"
    :style="{ height: height }"
  >
    <div v-if="title" class="flex justify-between items-center px-6 py-4 w-full">
      <div class="H500">
        {{ $t(title.getTranslationKey()) }}
      </div>
      <div class="flex items-center gap-4">
        <div class="flex gap-1" v-if="showLegend">
          <div
            v-for="legend in ['Pending', 'Paid']"
            :key="legend"
            class="flex gap-2 items-center rounded-full px-2 w-max text-sm"
            :class="{
              'bg-[#ECFDF3] text-[#027A48]': legend === 'Paid',
              'bg-[#FFFAEB] text-[#B54708]': legend === 'Pending',
            }"
          >
            <div
              class="w-2 h-2 rounded-full"
              :class="{
                'bg-[#12B76A]': legend === 'Paid',
                'bg-[#F79009]': legend === 'Pending',
              }"
            ></div>
            {{ $t(legend.getTranslationKey()) }}
          </div>
        </div>
        <div>
          <slot name="action-btn">
            <app-primary-button
              :buttonLabel="$t(actionBtnText.getTranslationKey())"
              data-cy="btn_add"
              @click="$emit('menu-click', {option: 'add'})"
              v-if="showActionBtn"
              class=""
            >
              <template #icon>
                <v-icon :icon="actonBtnIcon ? actonBtnIcon : 'mdi-plus'"></v-icon>
              </template>
            </app-primary-button>
          </slot>
        </div>
      </div>
    </div>
    <div :class="['w-full h-full', {'overflow-x-auto': scrollHorizontally, 'overflow-y-auto': scrollVertically}]">
      <table :class="['w-full text-sm text-left text-[#404b5e] font-[inter]', styles]">
        <thead class="">
        <tr class="bg-[#f2f4f7]" v-if="doubleHeader">
          <th
              scope="col"
              v-for="(headerItem, index) in topHeaders"
              :key="headerItem.title"
              class="px-6 py-3 !font-medium border-r border-b text-center"
              :colspan="index === topHeaders.length - 1 ? 2 : headerItem.colspan"
          >
            {{headerItem.title}}
          </th>
        </tr>
        <tr class="bg-[#f2f4f7]">
          <th
              scope="col"
              v-for="(header) in headers"
              :key="header.key"
              :style="{ width: getColumWidth(header) }"
              :class="[{
              'text-right': header.type === 'digit',
                'border-r': doubleHeader
            }, 'px-6 py-3 !font-medium', headerStyles]"
          >
            <slot
                :name="`header-${header.key.toLowerCase()}`"
                :item="header.label"
            >{{ $t(header.label.getTranslationKey()) }}</slot>
          </th>
          <th v-if="isDeleteIcon || showMenu || showIcon"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="[
            {
              'bg-transparent': index % 2 === 0,
              'bg-[#F9FAFB]': index % 2 !== 0,
            },
            typeof rowStyles === 'function' ? rowStyles(item, index) : rowStyles,
            'relative w-full'
          ]"
            @click="$emit('item-click', {...item, option: 'View', index,})"
            @mouseover="showDeleteIcon(index)"
            @mouseleave="hideDeleteIcon()"
        >
          <td
              v-for="header in headers"
              :key="header.key"
              :class="[columnStyles[header.key] || '',  'px-4 py-3',  header.type === 'digit' ? 'text-right' : 'text-left' ]"
              :style="{ width: header.key === 'amount' ? '5%' : '' }"
          >
            <div >
              <slot :name="header.key" :item="{ ...item, index }">
                
                <span
                    :class="[header.key === 'status' && statusBackgroundColor(item.status), 'px-3 rounded-full']">
                      {{header.type === 'digit' ? Number(item[header.key]).getCurrencyFormat() : item[header.key] || '-'}}
                </span>
              </slot>
            </div>
          </td>
          <td class="mt-3 mr-6" v-if="showMenu">
            <slot name="menu-item" :item="{...item, index}">
              <v-menu location="start">
                <template #activator="{ props }">
                  <v-icon
                      icon="custom:dotVertical"
                      v-bind="props"
                      class="cursor-pointer mt-2"
                  ></v-icon>
                </template>
                <v-list class="rounded-lg item">
                  <slot name="listItems" :item="item">
                    <v-list-item
                        v-for="option in dropdownOptions"
                        class="my-n1 hover:bg-gray-200 cursor-pointer"
                        :key="option"
                        @click="$emit('menu-click', { ...item, option, index })"
                    >{{ $t(option.getTranslationKey()) }}</v-list-item
                    >
                  </slot>
                </v-list>
              </v-menu>
            </slot>
          </td>
          <td v-if="isDeleteIcon" class="w-[3%]">
            <v-icon
                v-if="!(hideLastIcon && index === items.length - 1)"
                icon="custom:trashIcon"
                class="cursor-pointer ml-auto"
                @click="$emit('menu-click', {option: 'delete', item, index })"
            ></v-icon>
          </td>
          <td class="" v-if="showIcon">
            <v-icon
                v-show="hoveredRow === index"
                icon="custom:trashIcon"
                class="cursor-pointer"
                @click="$emit('icon-click', {option: 'Delete', item, index })"
            ></v-icon>
          </td>
        </tr>
        <tr
            v-if="showTotal"
            class="font-bold"
            :class="[
            {
              'bg-transparent': (items.length) % 2 === 0,
              'bg-[#F9FAFB]': (items.length) % 2 !== 0,
            },
          ]"
        >
          <td class="px-6 py-3" v-for="(item, index) in headers" :key="item.key">
            <span v-if="index === 0">Total</span>
            <div class="text-right" v-if="item.key === 'total_amount'|| item.key === 'total' || item.key === 'amount'">{{itemsTotal.getCurrencyFormat()}}</div>
          </td>
          <th v-if="isDeleteIcon || showMenu || showIcon"></th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="empty_state" v-if="!items || items?.length <= 0">
      <EmptyState />
      <h3 class="H500 capitalize">{{ $t("employees.no") }} {{$t(tableName.getTranslationKey())}} {{ $t("employees.found") }}</h3>
      <p class="P300">
        {{ $t("employees.youWillSee") }}
        {{$t(tableName.getTranslationKey()).toLowerCase()}}
        {{ $t("employees.hereWhen") }}
      </p>
    </div>
    <div>
      <slot name="table-footer">
        <div class="w-max ml-auto mr-6 mt-3" v-if="showPagination">
          <Paginator
              :total-items="totalItems"
              v-model="currentPageRef"
              :items-per-page="itemsPerPage"
              :on-click="loadMorePaginationData"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
