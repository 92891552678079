import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  fetchAllAdvancePayment,
  fetchAllPartnerWPS,
  fetchInterimPayment,
  fetchContractDetails,
  fetchProjectMangers,
} from "@/services/endpoints";
import {format} from "date-fns";

interface StateObject {
  [key: string]: any; // Define an index signature
}
export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    partnerwpsTotalPages: null,
    advancePaymentTotalPages: null,
    interimPaymentTotalPages: null,
    contractAddendaTotalPages: null,
    totalData: null,
    projects: [],
    userKey: null,
    tabId: 0,
    tableHeaders: [
      "Project Title",
      "Project Manager",
      "Start Date",
      "End Date",
      "Status"
    ],
    phase: ["Forecast", "EOI", "Shortlist", "Proposal", "Offer", "Project"],
    status: [
      "In-Process",
      "Submitted",
      "Not-submitted",
      "Dropped by us",
      "Cancelled by Client",
      "Lost",
      "Confirmed",
      "Running",
      "Closed",
    ],

    disabled: true,
    filterBys: [],
    submissionMethods: ["Electronic", "By Courier", "Other"],
    settlementTypes: [
      {
        id: "Fee-Based",
        value: "Fee-Based",
      },
      {
        id: "Global Price",
        value: "Global Price",
      },
      {
        id: "Other",
        value: "Other",
      },
    ],
    fundingSource: ["BMZ", "EU", "World bank", "UN"],
    selectedPhase: "",
    selectedStatus: "",
    projectTitle: "",
    projectID: "",
    fileID: "",
    currentProject: {},
    projectPage: false,
    allProjectFiles: [],
    dialogState: false,
    advancedPayments: [],
    interimPayments: [],
    contractDetails: {},
    contractID: "",
    contractPartners: [],
    tempClientData: {},
    subCostCenter: false,
    appLoading: false,
    isProjectUpdate: {},
    subSelectedOption: "",
    projectManagers: [],
    foreignEstablishment: [],
    feeInvoice: false,
    travelExpense: false,
    ticketInvoice: false,
    reportInvoice: false,
    feeInvoiceData: [],
    invoiceData: {
      fee_invoices: [],
      travel_invoices: [],
      ticket_invoices: [],
      report_invoices: []
    },
    settlementData: {},
    settlementDataForViewing: {},
    fromHomeToEditProject: false
  },

  mutations: {
    SET_SUB_COST_CENTER_TO_STATE(state: any, payload: any) {
      state.subCostCenter = payload;
    },
    SET_SUB_TO_STATE(state: any, payload: any) {
      state.subSelectedOption = payload;
    },

    SET_PROJECTS_TO_STATE(state: any, payload: any) {
      state.projects = payload;
    },
    SET_ALL_PROJECT_FILES_TO_STATE(state: any, payload: any) {
      state.allProjectFiles = payload;
    },
    SET_PROJECT_PAGE_TO_STATE(state: any, payload: any) {
      state.projectPage = payload;
    },
    SET_TABLE_HEADERS_TO_STATE(state: any, payload: any) {
      state.tableHeaders = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_PARTNERWPS_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.partnerwpsTotalPages = payload;
    },
    SET_ADVANCE_PAYMENT_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.advancePaymentTotalPages = payload;
    },
    SET_INTERIM_PAYMENT_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.interimPaymentTotalPages = payload;
    },
    SET_CONTRACT_ADDENDA_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.contractAddendaTotalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_SELECTED_PHASE_TO_STATE(state: any, payload: any) {
      state.selectedPhase = payload;
    },
    SET_SELECTED_STATUS_TO_STATE(state: any, payload: any) {
      state.selectedStatus = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_PROJECT_UPDATE(state: any, payload: any) {
      state.isProjectUpdate = { ...state.isProjectUpdate, ...payload };
    },
    SET_FILTER_BYS_TO_STATE(state: any, payload: Array<string>) {
      const filterByObj: StateObject = {};
      filterByObj[payload[0]] = payload[1];

      // Convert state.filterBys array to a Map
      const filterByMap = new Map<string, StateObject>(
        state.filterBys.map((obj: StateObject) => [Object.keys(obj)[0], obj])
      );

      // Set the new filter object in the Map
      filterByMap.set(payload[0], filterByObj);

      // Convert the Map back to an array of objects
      state.filterBys = Array.from(filterByMap.values());
    },
    REMOVE_FILTER_BYS_FROM_STATE(state: any, payload: string) {
      const filterBysArr = [...state.filterBys];
      state.filterBys = filterBysArr.filter((obj: StateObject) => {
        return Object.keys(obj)?.includes(payload) === false;
      });
    },
    SET_DISABLED_TO_STATE(state: any, payload: any) {
      state.disabled = payload;
    },
    SET_PROJECT_TITLE_STATE(state: any, payload: any) {
      state.projectTitle = payload;
    },
    SET_PROJECT_ID_STATE(state: any, payload: any) {
      state.projectID = payload;
    },
    SET_FILE_ID_STATE(state: any, payload: any) {
      state.fileID = payload;
    },
    SET_CURRENT_PROJECT_TO_STATE(state: any, payload: any) {
      state.currentProject = payload;
    },
    SET_DIALOG_TO_STATE(state: any, payload: any) {
      state.dialogState = payload;
    },
    SET_ADVANCE_PAYMENT_TO_STATE(state: any, payload: any) {
      state.advancedPayments = payload;
    },
    SET_INTERIM_PAYMENT_TO_STATE(state: any, payload: any) {
      state.interimPayments = payload;
    },
    SET_CONTRACT_DETAILS_TO_STATE(state: any, payload: any) {
      state.contractDetails = payload;
    },
    SET_CONTRACT_ID_TO_STATE(state: any, payload: any) {
      state.contractID = payload;
    },
    SET_CONTRACT_PARTNERS_TO_STATE(state: any, payload: any) {
      state.contractPartners = payload;
    },
    SET_TEMP_CLIENT_TO_STATE(state: any, payload: any) {
      state.tempClientData = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_PROJECT_MANAGERS_TO_STATE(state: any, payload: any) {
      state.projectManagers = payload;
    },
    SET_PROJECT_FOREIGN_ESTABLISHMENT(state: any, payload: any) {
      state.foreignEstablishment = payload;
    },
    SET_LOCAL_BUDGET_TAB(state: any, payload: boolean) {
      state.currentProject.show_local_budget = payload;
    },
    SET_FEE_INVOICE(state: any, payload: boolean) {
      state.feeInvoice = payload;
    },
    SET_TRAVEL_EXPENSE(state: any, payload: boolean) {
      state.travelExpense = payload;
    },
    SET_TICKET_INVOICE(state: any, payload: boolean) {
      state.ticketInvoice = payload;
    },
    SET_REPORT_INVOICE(state: any, payload: boolean) {
      state.reportInvoice = payload;
    },
    SET_INVOICE_DATA(state: any, payload: any) {
      state.invoiceData = payload
    },
    SET_SETTLEMENT_DATA(state: any, payload: any) {
      state.settlementData = payload
    },
    SET_SETTLEMENT_DATA_FOR_VIEWING(state: any, payload: any) {
      state.settlementDataForViewing = payload
    },
    SET_FROM_HOME_TO_EDIT_PROJECT(state: any, payload: boolean) {
      state.fromHomeToEditProject = payload
    }
  },
  getters: {
    // showTabs(state: any) {
    //   // const selectedPhase = state.selectedPhase
    //   const project = state.currentProject
    //   return project.project_phase === 'Offer' && project.sub_cost_center!== null || project.project_phase === 'Project' && project.sub_cost_center!== null
    // },
    showTabs(state: any) {
      const selectedPhase = state.selectedPhase;
      // const projectPhase = state.currentProject?.project_phase
      // return selectedPhase === "Offer" || selectedPhase === "Project" ? true : false
      const projectPhase = state.currentProject?.project_phase;
      return (
        selectedPhase === "Offer" ||
        selectedPhase === "Project" ||
        projectPhase === "Offer" ||
        projectPhase === "Project"
      );
    },
    isSubCostCenter(state: any) {
      return state.subCostCenter;
    },
    isSubData(state: any) {
      return state.subSelectedOption;
    },
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isContractId(state: any) {
      return state.contractID;
    },
    isDialogState(state: any) {
      return state.dialogState;
    },
    isProjectPage(state: any) {
      return state.projectPage;
    },
    isCurrentProject(state: any) {
      return state.currentProject;
    },
    isAllProjects(state: any) {
      return state.projects;
    },
    isFilterBys(state: any) {
      return state.filterBys;
    },
    isTableHeaders(state: any) {
      return state.tableHeaders;
    },
    isPhaseOptions(state: any) {
      return state.phase;
    },
    isStatusOptions(state: any) {
      return state.status;
    },
    isSubmissionMethods(state: any) {
      return state.submissionMethods;
    },
    isSettlementTypes(state: any) {
      return state.settlementTypes;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isPartnerwpsTotalPages(state: any) {
      return state.partnerwpsTotalPages;
    },
    isAdvancePaymentTotalPages(state: any) {
      return state.advancePaymentTotalPages;
    },
    isInterimPaymentTotalPages(state: any) {
      return state.interimPaymentTotalPages;
    },
    isContractAddendaTotalPages(state: any) {
      return state.contractAddendaTotalPages;
    },
    isSelectedPhase(state: any) {
      return state.selectedPhase;
    },
    isSelectedStatus(state: any) {
      return state.selectedStatus;
    },
    isDisabled(state: any) {
      return state.disabled;
    },
    isProjectTitle(state: any) {
      return state.projectTitle
        ? state.projectTitle
        : state.currentProject?.project_title;
    },
    isProjectID(state: any) {
      return state.projectID;
    },
    isFileID(state: any) {
      return state.fileID;
    },
    isFundingSource(state: any) {
      return state.fundingSource;
    },
    isAllProjectFiles(state: any) {
      return state.allProjectFiles;
    },
    isAdvancePayment(state: any) {
      return state.advancedPayments;
    },
    isInterimPayment(state: any) {
      return state.interimPayments;
    },
    isContractDetails(state: any) {
      return state.contractDetails;
    },
    isContractPartner(state: any) {
      return state.contractPartners;
    },
    isTempClient(state: any) {
      return state.tempClientData;
    },
    isAppLoading(state: any) {
      return state.appLoading;
    },
    isProjectUpdate(state: any) {
      return state.isProjectUpdate;
    },
    isProjectMangers(state: any) {
      return state.projectManagers["Project Managers"];
    },
    isAssistantProjectMangers(state: any) {
      return state.projectManagers["Project Assistants"];
    },
    isEmptyFileState(state: any) {
      return state.allProjectFiles.length === 0;
    },
    isForeignEstablishment(state: any) {
      return state.foreignEstablishment;
    },
    isFeeInvoice(state: any) {
      return state.feeInvoice;
    },
    isTravelExpense(state: any) {
      return state.travelExpense;
    },
    isTicketInvoice(state: any) {
      return state.ticketInvoice;
    },
    isReportInvoice(state: any) {
      return state.reportInvoice;
    },
    getInvoiceData(state: any) {
      return state.invoiceData
    },
    getSettlementData(state: any) {
      return state.settlementData
    },
    getSettlementDataForViewing(state: any) {
      return state.settlementDataForViewing
    },
    getFromHomeToEditProject(state: any) {
      return state.fromHomeToEditProject
    }
  },
  actions: {
    getAllProjectFiles(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`/projects/project/files/${payload.project_id}/`)
        .then((response: any) => {
          if (response) {
            context.commit(
              "SET_ALL_PROJECT_FILES_TO_STATE",
              response.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSingleProject(
      context: ActionContext<any, any>,
      payload: {
        projectID: number;
        setter: string;
      }
    ) {
      context.commit("SET_APP_LOADING_TO_STATE", true);
      Api()
        .get(`/projects/project/single-project/${payload.projectID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit(payload.setter, responseData.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            // const project = context.state.currentProject
            // if(project.project_phase === 'Offer' && project.sub_cost_center!== null || project.project_phase === 'Project' && project.sub_cost_center!== null ){

            //   context.commit("SET_SUB_COST_CENTER_TO_STATE", true)
            // }
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllAdvancePayments(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchAllAdvancePayment +
            `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_ADVANCE_PAYMENT_TO_STATE", responseData);
          }
        });
    },
    getContractPartners(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchAllPartnerWPS + `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CONTRACT_PARTNERS_TO_STATE", responseData);
          }
        });
    },
    getAllInterimPayments(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchInterimPayment + `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_INTERIM_PAYMENT_TO_STATE", responseData);
          }
        });
    },
    getContractDetails(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(fetchContractDetails + `${payload.projectID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response?.data;
            context.commit("SET_CONTRACT_DETAILS_TO_STATE", responseData?.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getProjectManagers(context: ActionContext<any, any>) {
      // context.commit('SET_APP_LOADING_TO_STATE', false);
      Api()
        .get(fetchProjectMangers)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response?.data;
            context.commit("SET_PROJECT_MANAGERS_TO_STATE", responseData?.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    formatInvoiceData({commit}: ActionContext<any, any>, payload: any) {
      const newObj: any = {}
      Object.keys(payload).map((key: string) => {
        if(key === "success" || key == "message") return
        newObj[key] = payload[key].map((item: any) => {
          return {
            "srNo": item.id,
            requested: item.amount_requested,
            "request date": format(new Date(item.created_at), "dd.MM.yy"),
            status: item.status,
            approved: item.amount_approved,
            settlement_type: item.settlement_type
          }
        })
      })
      commit("SET_INVOICE_DATA", newObj)
    },
    setSettlementData({commit}: ActionContext<any, any>, payload: any) {
      // const newTableData: any = payload.data?.request_details?.map((item: any) => {
      //   return {
      //     'service_provided': item.title,
      //     number: item.requested,
      //     amount: item.amount,
      //     total: item.total,
      //     'retention_rate': item.retention_rate,
      //     'retention_amt': item.retention_amt,
      //     'account_no': item.account_no,
      //   }
      // })
      // const newSettlementData: any = {
      //   ...payload.data,
      //   request_details: newTableData
      // }
      //   if(payload.type === 'view') commit("SET_SETTLEMENT_DATA_FOR_VIEWING", payload.data)
      //   else commit("SET_SETTLEMENT_DATA", payload.data)
      // commit("SET_SETTLEMENT_DATA", newSettlementData)
    }
  },
};
