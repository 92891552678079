<template>
  <div :class="['employee', type]">
    <div>
      <div class="ml8 flex w-100 SPC-MT-400 justify-between items-center">
        <div class="H400 flex items-center w-[25%]">
          <div v-if="itemChild.children.length > 0">
            <v-icon
              icon="custom:expandLess"
              v-if="!isExpanded(itemChild.id)"
              @click="toggleExpand(itemChild.id)"
            />
            <v-icon
              v-else
              icon="custom:expandIcon"
              @click="toggleExpand(itemChild.id)"
            />
          </div>
          <h4 class="ml-4">{{ $t(getTranslatedStringKey(itemChild.name)) }}</h4>
        </div>
        <div class="access_level flex-column">
          <div class="flex">
            <div
              v-for="(labelItem, i) in label"
              :class="[
                'access_level_btns',
                'H400',
                'cursor-pointer',
                itemChild.name === 'Announcement' && labelItem === 'Can View'
                  ? 'pointer-events-none can_view_access'
                  : '',
                i > parentAccess && 'pointer-events-none',
                i <= parentAccess && isSelected(itemChild.id, i) && 'selected',
              ]"
              :key="i"
              @click="selectAccess(itemChild.id, i, type)"
            >
              {{ $t(getTranslatedStringKey(labelItem)) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="type === 'child'"
        style="
          border-bottom: 1px solid #eaecf0;
          margin-left: 0;
          margin-right: 8%;
        "
        class="SPC-MT-200 -ml-16"
      ></div>
    </div>
    <div v-if="!isExpanded(itemChild.id)">
      <div v-for="(itemGrandChild, i) in itemChild.children" :key="i">
        <app-access-role-sections
          type="grandchild"
          :label="label"
          :parentAccess="modulesSelected[itemChild.id] || 0"
          :modulesSelected="modulesSelected"
          :itemChild="itemGrandChild"
          :isExpanded="isExpanded"
          :toggleExpand="toggleExpand"
          :isSelected="isSelected"
          :selectAccess="selectAccess"
          :getTranslatedStringKey="getTranslatedStringKey"
        />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent,} from "vue";

export default defineComponent({
  name: "AppAccessRoleSections",
  props: {
    label: {
      type: Array,
    },
    getTranslatedStringKey: {
      type: Function,
    },
    selectAccess: {
      type: Function,
    },
    isSelected: {
      type: Function
    },
    itemChild: {
      type: Object,
    },
    type: {
      type: String,
    },
    isExpanded: {
      type: Function,
    },
    toggleExpand: {
      type: Function
    },
    parentAccess: {
      type: Number
    },
    modulesSelected: {
      type: Object
    }
  },
  data() {
    return {
      isState: false,
      timer: null,
    };
  },
  computed: {
  },
  methods: {
  },
  watch: {

  },
});
</script>

<style scoped>
.employee {
  margin-right: 3%;
}
.employee.child {
  margin-left: 25%;
  margin-right: 3%;
  margin-top: 10px;
  padding: 10px;
}
.employee.grandchild {
  margin-left: 3%;
  margin-right: 0;
}
.access_level_btns {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 110px;
  min-height: 40px;
  outline: none;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.access_level_btns:nth-child(1) {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.access_level_btns:nth-child(3) {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.access_level_btns.selected {
  background: #f4eae9;
}
.can_view_access {
  opacity: 0.3767;
}
</style>
