<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-4">
        <form class="mt-[30px]">
          <div class="flex justify-start mb-[24px] items-center">
            <div
              :class="[
                'rounded-full profile-img-wrapper p-4 bg-[#F9FAFB]',
                personalForm.profile_picture && 'selected',
              ]"
              data-cy="profile_image"
            >
              <img
                :src="
                  personalForm.profile_picture
                    ? personalForm.profile_picture
                    : noProfileImg
                "
                alt=""
                class="w-[80px] h-[80px] rounded-full object-cover"
              />
              <input
                type="file"
                ref="fileInput"
                @change="handleFileSelect"
                hidden
                data-cy="image_file"
              />
            </div>
            <div class="flex flex-col ml-[24px] items-center">
              <div>
                <h1 class="text-[30px]">
                  {{ $t(getTranslatedStringKey("Profile")) }}
                </h1>
                <div class="flex mt-[16px]">
                  <app-secondary-button
                    :buttonLabel="$t('personal.changePhoto')"
                    :disabled="
                      (currentPath === '/edit-employee' ||
                        currentPath === '/edit-expert' ||
                        currentPath === '/profile') &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    @click="openFileInput"
                    data-cy="changePhoto"
                  />
                  <button
                    v-if="personalForm.profile_picture"
                    type="button"
                    :onclick="removePhoto"
                    :disabled="
                      (currentPath === '/edit-employee' ||
                        currentPath === '/edit-expert' ||
                        currentPath === '/profile') &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    class="text-[#667085] p-2 ml-3 hover:bg-gray-50"
                    data-cy="removePhoto"
                  >
                    {{ $t("personal.removePhoto") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="max-w-[87%] mb-[32px]" />
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                id="1"
                :label="$t('personal.username')"
                :placeholder="$t('personal.enterUsername')"
                v-model:modelValue="personalForm.userName"
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                data-cy="personal_username"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="2"
                :label="$t('tablefields.title')"
                :placeholder="$t('personal.enterTitle')"
                v-model:modelValue="personalForm.title"
                data-cy="personal_title"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="2"
                :label="$t('personal.firstName')"
                :placeholder="$t('personal.enterFirstName')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.firstName"
                data-cy="personal_firstname"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="3"
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                :label="$t('personal.lastName')"
                :placeholder="$t('personal.enterLastName')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.lastName"
                data-cy="personal_lastname"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="4"
                :label="$t('personal.middleName')"
                :placeholder="$t('personal.enterMiddleName')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.middleName"
                data-cy="personal_middlename"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="5"
                :label="$t('personal.dateOfBirth')"
                minDate=""
                type="date"
                v-model:modelValue="personalForm.dateOfBirth"
                data-cy="personal_dob"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                v-model="personalForm.gender"
                :label="$t('personal.gender')"
                placeholder="Select gender"
                :options="genderOptions"
                data-cy="personal_gender"
              ></app-select-field-object>
            </div>
            <div class="w-[440px]">
              <app-select-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                v-model="personalForm.countryOfBirth"
                :label="$t('personal.countryOfBirth')"
                placeholder="Select country of birth"
                :options="countryOptions"
                data-cy="personal_country"
                :isTranslation="true"
              ></app-select-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-3">
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                :label="$t('personal.civilStatus')"
                placeholder="Select civil status"
                :options="civilStatusOptions"
                v-model="personalForm.civilStatus"
                data-cy="personal_civil"
              ></app-select-field-object>
            </div>
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="5"
                :label="$t('personal.citizenship')"
                :options="countryCitizensArray"
                :isTranslation="false"
                :placeholder="$t('personal.selectCitizenship')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.citizenship"
                data-cy="personal_citizenship"
              ></app-select-field-object>
            </div>
          </div>
          <div class="w-[440px] mb-5">
            <app-input-field
              :disabled="
                (currentPath === '/edit-employee' ||
                  currentPath === '/edit-expert' ||
                  currentPath === '/profile') &&
                isEditEmployee &&
                !isEditButton
              "
              id="6"
              :label="$t('personal.officialEmail')"
              :placeholder="$t('personal.enterOfficialEmail')"
              validation="emailOnly"
              v-model:modelValue="personalForm.official_email"
              data-cy="personal_officialEmail"
            ></app-input-field>
          </div>
          <!-- <div class="flex items-center gap-3 mb-7">
            <input
              :disabled="
                ((currentPath === '/edit-employee' ||
                  currentPath === '/edit-expert' ||
                  currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton) ||
                personalForm.official_email === ''
              "
              type="checkbox"
              class=""
              v-model="personalForm.isCommunication"
              data-cy="personal_isComm"
            />
            <label
              for="communicationCheckbox"
              class="text-[#667085] text-[14px]"
              >{{ $t("personal.selectForCommunication") }}</label
            >
          </div> -->

          <v-row class="mt-5">
            <v-col cols="12">
              <div class="w-[920px]">
                <div
                  v-if="
                    !isEditEmployee ||
                    (currentPath !== '/edit-employee' &&
                      currentPath !== '/edit-expert' &&
                      currentPath !== '/profile')
                  "
                  class="flex justify-end mb-7"
                >
                  <div class="flex gap-3">
                    <div>
                      <app-secondary-button
                        :buttonLabel="$t('personal.saveAndExit')"
                        @click-event="savePersonalToState('exit')"
                        :disabled="
                          personalForm.userName === '' ||
                          personalForm.firstName === '' ||
                          personalForm.lastName === ''
                        "
                        data-cy="btn_saveAndExit"
                      >
                      </app-secondary-button>
                    </div>
                    <div>
                      <app-primary-button
                        :buttonLabel="$t('personal.saveAndContinue')"
                        @click-event="savePersonalToState('continue')"
                        :disabled="
                          personalForm.userName === '' ||
                          personalForm.firstName === '' ||
                          personalForm.lastName === ''
                        "
                        data-cy="btn_saveAndContinue"
                      >
                      </app-primary-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-end gap-7">
                  <div v-show="isEditButton">
                    <app-secondary-button
                      :buttonLabel="$t('personal.cancel')"
                      @click="cancel"
                      data-cy="btn_personalCancel"
                    ></app-secondary-button>
                  </div>
                  <app-primary-button
                    v-show="isEditButton"
                    :buttonLabel="$t('employment.saveChanges')"
                    @click-event="savePersonalToState('edit')"
                    :disabled="
                      personalForm.userName === '' ||
                      personalForm.firstName === '' ||
                      personalForm.lastName === ''
                    "
                    data-cy="btn_personalSave"
                  >
                  </app-primary-button>
                </div>
              </div>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
      id="alert"
      data-cy="response_alert"
    />
  </div>
  <div
    class="modal-board"
    v-if="showmodal && personalForm.official_email === ''"
  >
    <div class="flex justify-between">
      <h3 class="H400">{{ $t("personal.weRecommend") }}</h3>
      <v-icon icon="custom:closeIcon" @click="showmodal = false" />
    </div>
    <p class="P300">
      {{ $t("personal.leavingTheEmail") }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { isEqual } from "lodash";
import countries from "@/services/country_api";
import { getTransString } from "@/services/helpers";
import { countryCitizens } from "@/services/citizen";
import { validEmail } from "@/services/validation";
import NoProfileImg from "@/assets/no-profile-img.svg";
import Api from "@/services/api";
import { FormApi } from "@/services/customApi";
export default defineComponent({
  name: "Personal",
  components: {},
  props: {
    errors: {
      type: Boolean,
      default: true,
    },
    tabId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      noProfileImg: NoProfileImg,
      confirmationDialogVisible: false,
      alertType: "success",
      profilePictureFile: null,
      alertState: false,
      alertTitle: "",
      isEdit: false,
      employeesRoutes: ["/edit-employee", "/add-employee"],
      loadUserKey: null,
      countryOptions: null,
      countryCitizensArray: [],
      firstRun: true,
      genderOptions: [
        { id: "Male", value: "Male" },
        { id: "Female", value: "Female" },
      ],

      originalFormData: {
        userName: "",
        title: null,
        firstName: "",
        middleName: "",
        lastName: "",
        countryOfBirth: null,
        dateOfBirth: null,
        citizenship: "",
        civilStatus: "",
        profile_picture: null,
        gender: "",
        official_email: "",
        isCommunication: false,
      },
      personalForm: {
        userName: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        countryOfBirth: "",
        dateOfBirth: null,
        citizenship: "",
        civilStatus: "",
        profile_picture: null,
        gender: "",
        official_email: "",
        isCommunication: false,
      },
      username: null,
      allUserRoles: [],
      page: 1,
      roles: [],
      showmodal: true,
    };
  },
  computed: {
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },

    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.currentPath === "/profile"
        ? this.$store.getters["hrResources/isEditButton"]
        : this.$store.getters["hrResources/isEditButton"] &&
            this.canEditPersonalModule?.includes("Personal");
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    userKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    currentPath() {
      return this.$route.path;
    },
    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isEmployeeUserKeyFromHome() {
      return this.$store.getters["hrResources/isEmployeeUserKeyFromHome"];
    },
    civilStatusOptions() {
      const data = [
        "Unknown",
        "Married",
        "Unmarried",
        "Divorced",
        "Widowed",
        "Other",
      ];
      return data?.convertArrayToObject();
    },
  },
  watch: {
    isPersonalData(newValue) {
      this.populateFieldsWithData(newValue, this.personalForm);
      if (this.firstRun) {
        this.populateFieldsWithData(newValue, this.originalFormData);
        this.firstRun = false;
      }
    },
    isHome() {
      window.location.reload();
    },
    personalForm: {
      deep: true,
      handler(value) {
        this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
          Personal: !isEqual(value, this.originalFormData),
        });
      },
    },
  },
  methods: {
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleNextClick() {
      this.$emit("next-clicked");
    },
    cancel() {
      if (this.currentPath === "/profile") {
        this.getPersonalContactInfo();
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    removePhoto() {
      this.$refs.fileInput.value = "";
      URL.revokeObjectURL(this.personalForm.profile_picture);
      this.profilePictureFile = null;
      this.personalForm.profile_picture = null;
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.profilePictureFile = file;
      this.personalForm.profile_picture = URL.createObjectURL(file);
    },
    sendEmail() {
      const payload = {
        user_key: this.userKey,
      };
      Api()
        .post("/auth/send-employee-credentials-email/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
          }
        });
    },
    async getUserInfo() {
      const userInfoResp = await Api().get("/auth/get-user-information/");
      this.$store.commit("auth/SET_AUTH_USER_INFO", userInfoResp?.data?.data);
    },
    savePersonalToState(data) {
      if (
        this.personalForm.official_email &&
        !validEmail(this.personalForm.official_email)
      ) {
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }
      let payload = {
        user_key: this.isEditEmployee ? this.userKey : "",
        username: this.personalForm.userName,
        title: this.personalForm.title || "",
        first_name: this.personalForm.firstName,
        middle_name: this.personalForm.middleName,
        last_name: this.personalForm.lastName,
        country_of_birth: this.personalForm.countryOfBirth,
        citizenship: this.personalForm.citizenship,
        civil_status: this.personalForm.civilStatus,
        gender: this.personalForm.gender,
        official_email: this.personalForm.official_email || "",
        user_type: "Employee",
        communication_email: this.personalForm.isCommunication
          ? this.personalForm.official_email
          : "",
      };

      if (payload.user_key === "") {
        delete payload.user_key;
      }

      if (this.personalForm.dateOfBirth) {
        payload.date_of_birth = this.personalForm.dateOfBirth;
      }
      const formData = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }
      if (this.profilePictureFile) {
        formData.append("profile_picture", this.profilePictureFile);
      }

      FormApi.post("/hr/employees/add-personal-info/", formData)
        .then(async (response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_USER_KEY_TO_STATE",
              response.data.data.user_key
            );
            this.$store.commit(
              "hrResources/SET_USER_INFO_TO_STATE",
              response.data.data
            );
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;

            this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
              Personal: false,
            });

            setTimeout(() => {
              this.sendEmail();
              if (data === "exit") {
                this.$router.push({ name: "employees" });
              }
              if (data === "continue") {
                this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 1);
              }
            }, 1500);

            if (this.currentPath === "/profile") {
              await this.getUserInfo();
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getPersonalContactInfo() {
      let newUserKey = this.userKey;
      if (this.isHome) {
        newUserKey = this.isEmployeeUserKeyFromHome;
      }
      Api()
        .get(`/hr/employees/get-employee-personal-contact-info/${newUserKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    populateFieldsWithData(data, saveTo) {
      saveTo["userName"] = this.isEdit ? data.username : "";
      saveTo["firstName"] = this.isEdit ? data.first_name : "";
      saveTo["middleName"] = this.isEdit ? data?.middle_name : "";
      saveTo["lastName"] = this.isEdit ? data?.last_name : "";
      saveTo["countryOfBirth"] = this.isEdit
        ? data?.country_of_birth
        : this.countryOptions[64];
      saveTo["dateOfBirth"] = this.isEdit ? data?.date_of_birth : null;
      saveTo["citizenship"] = this.isEdit ? data?.citizenship : "";
      saveTo["civilStatus"] = this.isEdit
        ? data?.civil_status
        : this.civilStatusOptions[0]?.value;
      saveTo["gender"] = this.isEdit
        ? data?.gender
        : this.genderOptions[0]?.value;
      saveTo["official_email"] = this.isEdit ? data?.official_email : "";
      saveTo["isCommunication"] = this.isEdit
        ? data?.communication_email === data?.official_email &&
          data?.official_email !== ""
        : false;

      saveTo["profile_picture"] = this.isEdit ? data?.profile_picture : "";
      saveTo["title"] = this.isEdit ? data?.title : "";
    },
  },
  mounted() {
    // Loop through the countryCitizens object
    for (const country in countryCitizens) {
      if (Object.prototype.hasOwnProperty.call(countryCitizens, country)) {
        const citizen = countryCitizens[country];
        this.countryCitizensArray.push({ id: citizen, value: citizen });
      }
    }

    this.countryOptions = this.getCountryByName();
    // this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
  },
  created() {
    this.$store.commit(
      "hrResources/SET_EDIT_EMPLOYEE_STATE",
      this.userKey !== null && this.userKey?.length > 0
    );
    if (this.isEditEmployee) {
      this.getPersonalContactInfo();
    }
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
    this.isEdit = this.isEditEmployee;
    this.isEditButton = this.isEditEmployee;
    this.username = this.$store.state.hrResources?.userName;
  },
});
</script>

<style scoped>
.select-field {
  width: 440px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-board {
  position: fixed;
  top: 90%;
  left: 85%;
  transform: translate(-50%, -50%);
  right: 0;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 396px;
  height: 98px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}
.profile-img-wrapper {
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
  border: 2px solid #fff;
}

.profile-img-wrapper.selected {
  padding: 5px;
}

.profile-img-wrapper.selected img {
  width: 100px;
  height: 100px;
}
</style>
