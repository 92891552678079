<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" width="auto" height="629px" persistent>
      <v-card class="!rounded-lg custom-card">
        <div class="text-center mt-5">
          <v-card-title class="text-h6">
            {{ $t(`${title}`) }}
          </v-card-title>
          <v-card-subtitle class="text-wrap">
            {{ $t(`${subtitle}`) }}
          </v-card-subtitle>
        </div>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  }
});
</script>
<style scoped></style>
