<template>
    <div>
        <app-header
        :name="$t('advancePaymentRequest.requestDetails')"
        class="mb-5 ml-n2"
        :clickEvent="handleGoToBack"
        :show-arrow-back="true"
      >
      </app-header>
      <div class="mt-10 mb-n16">
          <PreviewAndSign/>
      </div>
      <div>
          <AddFlight :disabledFormInputs="true"/>
      </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import PreviewAndSign from "@/components/dashboard/profile/flight-requests/PreviewAndSign.vue";
import AddFlight from "@/components/dashboard/profile/flight-requests/AddFlight.vue";

export default defineComponent({
    name: "FlightRequestDetails",
    components: {
        PreviewAndSign,
        AddFlight
    },
    data() {
        return {};
    },
   
    created() {
        this.$store.dispatch("flightModule/getSingleFlight", {load_page: true});
    },
});
</script>