<template>
  <div class="container">
    <div class="table py-13">
      <app-table
        :isCalender="true"
        :headers="headers"
        :showCursor="false"
        :items="isRequested"
        :canEdit="canEdit"
        pagename="Leaves"
        message="Leaves"
        @delete-event="showDelete({ $event, data: 'delete' })"
        @edit-event="showDetail({ $event, data: 'detail' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("leaves.LeaveRequests") }}</h3>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <app-calender-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="662"
      width="1808"
    >
      <template #content>
        <div>
          <LeavesCalender />
        </div>
      </template>
    </app-calender-dialog>
    <app-calender-dialog ref="isDetail" :title="title" :subtitle="subTitle">
      <template #content>
        <div>
          <HrApproval @close-event="hideDetails" />
        </div>
      </template>
    </app-calender-dialog>
    <app-dialog
      ref="delete"
      :title="$t('Delete Leave?')"
      :subtitle="`Are you sure you want to permanently delete this Leave ? This action can’t be undone.`"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('No, Keep it')"
              @click-event="closeDelete()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteLeave()"
            >
              Yes , delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";
import Api from "@/services/api";
import AppCalenderDialog from "@/components/shared/AppCalenderDialog.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import LeavesCalender from '../employees/LeavesCalender.vue'
import HrApproval from "../hrleave/HrApprovalCalender.vue"
import { getAllPendingLeaves } from '@/services/endpoints'
import moment from 'moment'
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: "LeaveRequest",
  components: {
    Paginator,
    LeavesCalender,
    HrApproval,
    AppCalenderDialog,
  },
  created() {
    this.$store.commit('hrResources/SET_CURRENT_TAB_TO_STATE', 4);
    // const urlParams = new URLSearchParams(window.location.search);


  },
  data() {
    return {
      headers: [
        "Requested By",
        "Date",
        "Approved By",

      ],
      currentPages: null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      data: [],
      values: [],
      options: [""],
      cancelLabel: "Cancel",
      buttonLabel: "Add position",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      probation_period_date: "",
      placeholder: "",
      acessLevel: this.roles,
      allUserRoles: [],
      isLeavedeleteId:''
    };

  },

  computed: {

    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isMonth() {
      return this.$store.getters["hrResources/isMonth"];
    },
    isLeaveId() {
      return this.$store.getters["hrResources/isLeaveId"];
    },
    isLeaveType() {
      return this.$store.getters["hrResources/isLeaveType"];
    },
    isStatus() {
      return this.$store.getters["hrResources/isStatus"];
    },

    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },

    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    isRequested() {
      return this.$store.getters["hrResources/isRequested"];
    },
    canEdit(){
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter((module)=>module.module_name==='Leave Requests')
      return findModules?.length === 0 ? false : true
    },
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },


    showDetail(data) {
      this.leaveId = data?.$event?.id;
      this.leaveType = data?.$event.leaveType;
      this.status = data?.$event.status;
      this.month = data?.$event.month;
      this.year=data?.$event.year
      this.requestedby=data?.$event.requestedby
      this.$store.commit("hrResources/SET_LEAVE_PERSON_TO_STATE",this.requestedby);
      this.$store.commit("hrResources/SET_LEAVE_ID_TO_STATE", this.leaveId);
      this.$store.commit("hrResources/SET_LEAVE_TYPE_TO_STATE", this.leaveType || this.isLeaveType);
      this.$store.commit("hrResources/SET_LEAVE_STATUS_TO_STATE", this.status || this.isStatus);
      this.$store.commit("hrResources/SET_LEAVE_YEAR_TO_STATE", this.year);
      this.$store.commit("hrResources/SET_LEAVE_MONTH_TO_STATE", this.month);
      if (data.data === "detail") {
        this.isDelete = !this.isDelete;

        this.$refs.isDetail.showDialog = true;
      }
    },

    hideDetails() {
      this.getAllLeaves(this.currentPage);
      this.$refs.isDetail.showDialog = false;
      this.$store.commit("hrResources/SET_HOME_TO_STATE", false);

    },

    getAllLeaves(page) {

      Api()
        .get(`${getAllPendingLeaves}${page}`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;


            this.values = data.map((obj) => {
              const startDate = moment(obj.leave_start_date).format('Do MMM YYYY');
              const endDate = moment(obj.leave_end_date).format('Do MMM YYYY');
              const Year = moment(obj.leave_start_date).format('YYYY');
              const days = Math.floor(obj.total_days);
              const hours = Math.floor((obj.total_days - days) * 24);
              const result = `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`;
              return {
                id: obj.id,
                'approved by': ` ${obj.leave_approver__first_name == '' ? "-" : obj.leave_approver__first_name}   ${obj.leave_approver__last_name}`,
                'requested by': ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                days: result,
                date: ` ${startDate} - ${endDate}`,
                status: obj.status,
                'leave type': obj.leave_type,
                leaveType: obj.leave_type,
                month: obj.leave_start_date,
                is_approver_approved:obj.is_approver_approved,
                requestedby: ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                year: Year,
              };
            });

            this.$store.commit("hrResources/SET_REQUEST_LEAVES_TO_STATE", this.values);
            this.$store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },

    showDelete(data) {
      this.isLeavedeleteId = data?.$event?.id;
      if (data.data === "delete") {
        this.isDelete = !this.isDelete;
        this.$refs.delete.showDialog = true;
      }
    },
    closeDelete() {
      this.$refs.delete.showDialog = false;
      this.getAllLeaves(this.currentPage);
    },
    deleteLeave(){
      Api()
          .delete(`/hr/employee/delete-leave/${this.isLeavedeleteId}/`,)
          .then((response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
              this.$refs.delete.showDialog = false;
          this.getAllLeaves(this.currentPage)
            }
          })
          .catch((error) => {
            if (error) {

              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
              this.$refs.delete.showDialog = false;
            }
          });

    },


    loadMore() {
      const page = this.currentPages;
      this.getAllLeaves(page);
    },
  },
  mounted() {

    this.getAllLeaves(this.currentPage);

    if (this.isHome && this.isLeaveId) {

      // const formattedId = this.extractNumberFromString(this.leave_Id);
      // this.$store.commit("hrResources/SET_LEAVE_ID_TO_STATE",this.isLeaveId);
      //  this.$store.commit("hrResources/SET_LEAVE_MONTH_TO_STATE","2023-09-1");

      this.showDetail({ $event: { id: this.isLeaveId }, data: 'detail' });
      //  this.$store.commit("hrResources/SET_LEAVE_TYPE_TO_STATE",`${this.isLeaveType}`);
      // this.$store.commit("hrResources/SET_LEAVE_STATUS_TO_STATE","Pending");
    }
  },

});
</script>

<style scoped>
.table {
  width: 100%;
  cursor: context-menu;
}
</style>
