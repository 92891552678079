<template>
  <app-accordion :accordionInfo="{name: 'Absence'}">
    <template #content>
      <app-basic-table class="mt-n5" :items="isAllEmployeesAbsent" :headers="header" :hideTableHeader="true" :hideTableFooter="true">
        <template #title>
          <h3 class="">{{ $t("leaves.absence") }}</h3>
        </template>
      </app-basic-table>
    </template>
    <template #action>
      <app-custom-pagination
      :current-date="current_date"
      :current-position="position"
      :current-direction="direction"
      @pagechanged="fetchDataMore"
      
    ></app-custom-pagination>
    </template>
  </app-accordion>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {getFirstDateOfWeek} from '@/services/helpers'
import moment from "moment"

export default defineComponent({
    name: "AbsenceTable",

    data() {
        return {
          start_day:0,
          header: ['Name', 'Reason', 'Destination', 'Start & End Date'],
           paginationForm:{
            direction: 'current',
            position: 5,
            current_date: '',
           }  
        }
    },

    computed: {
      isAllEmployeesAbsent(){
        const modifiedData = this.$store.getters['hrResources/isAllEmployeesAbsent']
        return modifiedData?.map((data)=>{
        return {
          name: `${data.employee__first_name} ${data.employee__last_name}`,
          reason: data.reason,
          destination: data.destination,
          'start & end date': `${moment(data.start_date)?.format('Do, MMM YYYY')} - ${moment(data.end_date)?.format('Do, MMM YYYY')}`
        }
      }) ;
      }
    },

    methods: {
      fetchDataMore(event){
        this.paginationForm.current_date = getFirstDateOfWeek(this.start_day)
        this.paginationForm.direction = event.direction
        this.paginationForm.position = event.position
        this.$store.dispatch('hrResources/getAllEmployeesAbsent', this.paginationForm)
      },
      fetchData(){
        this.paginationForm.current_date = getFirstDateOfWeek(this.start_day)
        
        this.$store.dispatch('hrResources/getAllEmployeesAbsent', this.paginationForm)
      }
    },
    created() {

      this.fetchData()
    },
});
</script>
<style scoped>
.type {
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #344054;
  font-size: 12px;
  border-radius: 16px;
  background-color: #f8f9fc;
  padding: 2px;
  width: fit-content;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.close-icon {
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
</style>
