<template>
  <div class="container">
    <div class="flex justify-between header">
      <div>
        <h3 class="H600">{{ isRequestedPerson }}</h3>
      </div>
      <div class="controls">
        <button class="px-16" @click="previousMonth">
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 11.5L1.5 6.5L6.5 1.5"
              stroke="#667085"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="current-date">
          {{ currentMonthName }} {{ currentYearAndMonth }}
        </div>
        <button class="px-16" @click="nextMonth">
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 11.5L6.5 6.5L1.5 1.5"
              stroke="#667085"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="flex status">
        <div class="flex px-3 approve">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.approved") }}</p>
        </div>
        <div class="flex pending">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.pending") }}</p>
        </div>
        <div class="flex px-3 rejected">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.rejected") }}</p>
        </div>
        <div class="flex px-3 holiday-l">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.holiday") }}</p>
        </div>
      </div>
    </div>

    <v-col cols="12">
      <div
        :class="[
          'flex calender_container',
          {
            disable_calender:
              isStatus == 'Approved' ||
              isStatus == 'Declined' ||
              isStatus == 'Draft',
          },
        ]"
      >
        <div class="flex flex-column labels">
          <div class="flex desc">
            <p class="holiday">{{ $t("calender.holidayDescription") }}</p>
            <p class="textleft total">{{ $t("calender.aplyTotal") }}</p>
          </div>
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Annual Leave' ? getStatusClass(isStatus) : '',
            ]"
          >
            <p class="leave">{{ $t("calender.annualLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours1 == 0 ? annualLeaveDays : totalHours1 }}
            </p>
          </div>
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Special Leave' ? getStatusClass(isStatus) : '',
            ]"
          >
            <p class="leave">{{ $t("calender.specialLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours2 == 0 ? specialLeaveDays : totalHours2 }}
            </p>
          </div>
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Sick Leave' ? getStatusClass(isStatus) : '',
            ]"
          >
            <p class="leave">{{ $t("calender.sickLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours3 == 0 ? sickLeaveDays : totalHours3 }}
            </p>
          </div>
          <!-- <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Sick Leave HO' ? getStatusClass(isStatus) : '',
            ]"
          >
            <p class="leave">{{ $t("calender.sickHo") }}</p>
            <p class="textleft hour">
              {{ totalHours4 == 0 ? sickHoLeaveDays : totalHours4 }}
            </p>
          </div> -->
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Child Sick Leave'
                ? getStatusClass(isStatus)
                : '',
            ]"
          >
            <p class="leave">{{ $t("calender.childSick") }}</p>
            <p class="textleft hour">
              {{ totalHours5 == 0 ? childsickLeaveDays : totalHours5 }}
            </p>
          </div>
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Child Care Leave'
                ? getStatusClass(isStatus)
                : '',
            ]"
          >
            <p class="leave">{{ $t("calender.childCare") }}</p>
            <p class="textleft hour">
              {{ totalHours5 == 0 ? kCareLeaveDays : totalHours6 }}
            </p>
          </div>
          <div
            :class="[
              'flex',
              'text',
              isLeaveType === 'Unpaid Leave' ? getStatusClass(isStatus) : '',
            ]"
          >
            <p class="leave">{{ $t("calender.unpaidLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours7 == 0 ? unpaidLeaveDays : totalHours7 }}
            </p>
          </div>
        </div>
        <div class="calendar" @mouseleave="closeAll()">
          <div
            v-for="(week, index) in weeks"
            :key="'week' + index"
            class="week"
          >
            <div class="day-row">
              <div
                v-for="(day, i) in getValidDays(week)"
                :key="'day' + i"
                :class="{ cell: true, weekend: day === 'Su' || day === 'Sa' }"
                :disabled="day === 'Su' || day === 'Sa'"
              >
                {{ day }}
              </div>
            </div>
            <div class="date-row">
              <div
                v-for="(date, i) in week"
                :key="'date' + i"
                :class="{
                  'is-holiday': isHoliday(date.date),
                 
                  cell: true,
                  
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'is-holiday': isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="flex flex-column">
                  {{ date.date || "" }}
                  <div
                    class="active"
                    v-if="
                      format(new Date(), 'd') === date.date &&
                      checkActiveMonth()
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input' + i"
                :class="{
                  notAllowcellType: 'Annual Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputValues[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard(index, i)"
                  >
                    {{ inputValues[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputValues[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input2' + i"
                :class="{
                  notAllowcellType: 'Special Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputs[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard2(index, i)"
                  >
                    {{ inputs[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex2 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue2(index, i, value.value)"
                    >
                      {{ value.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input3' + i"
                :class="{
                  notAllowcellType: 'Sick Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputs1[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard3(index, i)"
                  >
                    {{ inputs1[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex3 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue3(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputs1[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input5' + i"
                :class="{
                  notAllowcellType: 'Child Sick Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputs3[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard5(index, i)"
                  >
                    {{ inputs3[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex5 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue5(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputs3[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input6' + i"
                :class="{
                  notAllowcellType: 'Child Care Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputs4[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard6(index, i)"
                  >
                    {{ inputs4[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex6 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue6(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputs4[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input7' + i"
                :class="{
                  notAllowcellType: 'Unpaid Leave' !== isLeaveType,
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      inputs5[getIndex(index, i)]
                        ? getStatusClass(isStatus)
                        : 'input_disabled',
                    ]"
                    @mouseenter="showCard7(index, i)"
                  >
                    {{ inputs5[getIndex(index, i)] }}
                  </div>
                  <div
                    v-show="showCardIndex7 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value"
                      class="card-item"
                      @click="selectCardValue7(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputs5[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>

    <div class="mt-1 mb-2 btn">
      <div cols="10"><div></div></div>

      <div class="flex items-center justify-between">
        <app-secondary-button
          class="px-2 P300"
          :buttonLabel="$t('employment.cancel')"
          @click="emitClose(item)"
        >
        </app-secondary-button>

        <app-primary-button
          v-if="canEdit"
          :buttonLabel="$t('leaves.updateLeave')"
          :loading="loading"
          @click-event="addLeave()"
          :disabled="
            isStatus == 'Approved' ||
            isStatus == 'Declined' ||
            isStatus == 'Draft' ||
            (totalHours2 === 0 &&
              totalHours1 === 0 &&
              totalHours3 === 0 &&
              totalHours4 === 0 &&
              totalHours5 === 0 &&
              totalHours6 === 0 &&
              totalHours7 === 0 &&
              totalHours8 === 0)
          "
        >
        </app-primary-button>
      </div>
    </div>

    <!-- leave statistics component -->
    <leave-statistics
      :previousYear="previousYear"
      :carrryOverEndDate="
        formatDatecaryOver(leave_stats && leave_stats.carry_over_end_date)
      "
      :carryOverLeaveDays="
        formatLeaveDays(leave_stats && leave_stats.carry_over_leave_days)
      "
      :totalLeaveDaysLeft="
        formatLeaveDays(leave_stats && leave_stats.total_leave_days_left)
      "
      :currentYearAndMonth="currentYearAndMonth"
      :holidayEntitlement="
        formatLeaveDays(leave_stats && leave_stats.holiday_entitlement)
      "
      :totalHolidays="
        formatLeaveDays(leave_stats && leave_stats.total_holiday_days)
      "
      :annuaLeaveRequested="
        formatLeaveDays(leave_stats && leave_stats.total_annual_leave_requested)
      "
      :totalLeaveTaken="
        formatLeaveDays(leave_stats && leave_stats.total_leave_days_taken)
      "
      :totalSpecialLeaveRequested="
        formatLeaveDays(leave_stats && leave_stats.total_special_leave)
      "
      :totalSpecialLeave="
        formatLeaveDays(leave_stats && leave_stats.total_special_leave)
      "
      :totalUnpaidLeave="
        formatLeaveDays(leave_stats && leave_stats.total_unpaid_leave)
      "
      :totalSickLeave="
        formatLeaveDays(leave_stats && leave_stats.total_sick_leave)
      "
      :totalChildSickLeave="
        formatLeaveDays(leave_stats && leave_stats.total_child_sick_leave)
      "
      :totalKcareLeave="
        formatLeaveDays(leave_stats && leave_stats.total_child_care_leave)
      "
      :deductedOrExtra="
        formatLeaveDays(leave_stats && leave_stats.extra_leave_days)
      "
    />
  </div>
  <app-alert
    :title="alertTitle"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";
import { setupLogic } from "@/components/dashboard/employees/leavesScript/LeaveDetails";
import LeaveStatistics from "../employees/Leavestats.vue";
export default {
  name: "HrDetailCalender", // Don't forget to replace 'YourComponentName' with your actual component name.
  components: {
    LeaveStatistics,
  },
  setup() {
    const {
      pastDateAnyYear,
      isRequestedPerson,
      isDatePast,
      getStatusClass,
      checkActiveMonth,
      isHoliday,
      formatDatecaryOver,
      previousYear,
      formatLeaveDays,
      addLeave,
      weeks,
      nextMonth,
      previousMonth,
      currentYearAndMonth,
      format,
      inputValues,
      inputs,
      inputs1,
      inputs3,
      inputs4,
      inputs5,
      getIndex,
      showCard,
      selectCardValue,
      showCard2,
      showCard7,
      showCard3,
      showCard5,
      showCard6,
      selectCardValue2,
      selectCardValue3,
      selectCardValue5,
      selectCardValue6,
      selectCardValue7,
      showCardIndex,
      showCardIndex2,
      showCardIndex3,
      showCardIndex5,
      showCardIndex6,
      showCardIndex7,
      cardValues,
      totalHours1,
      totalHours2,
      totalHours3,
      totalHours4,
      totalHours5,
      totalHours6,
      totalHours7,
      totalHours8,
      alertState,
      alertTitle,
      alertType,
      getValidDays,
      annualLeaveDays,
      specialLeaveDays,
      sickLeaveDays,
      childsickLeaveDays,
      kCareLeaveDays,
      unpaidLeaveDays,
      isStatus,
      isLeaveType,
      leave_stats,
      closeAll,
      currentMonthName,
    } = setupLogic();

    const { emit } = getCurrentInstance();
    const store = useStore();
    const emitClose = (data) => {
      emit("close-event", data);
    };
    const canEdit = computed(() => {
      const modifiedData = store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "All Leaves"
      );
      return findModules?.length === 0 ? false : true;
    });

    const isName = computed(() => {
      return store.getters["hrResources/isEmployeeName"];
    });
    // If you're going to use any of these values in the template, you need to return them.
    return {
      isName,
      pastDateAnyYear,
      canEdit,
      isRequestedPerson,
      emitClose,
      isDatePast,
      getStatusClass,
      checkActiveMonth,
      isHoliday,
      formatDatecaryOver,
      previousYear,
      formatLeaveDays,
      addLeave,
      weeks,
      nextMonth,
      previousMonth,
      currentYearAndMonth,
      format,
      inputValues,
      inputs,
      inputs1,
      inputs3,
      inputs4,
      inputs5,
      getIndex,
      showCard,
      selectCardValue,
      showCard2,
      showCard7,
      showCard3,
      showCard5,
      showCard6,
      selectCardValue2,
      selectCardValue3,
      selectCardValue5,
      selectCardValue6,
      selectCardValue7,
      showCardIndex,
      showCardIndex2,
      showCardIndex3,
      showCardIndex5,
      showCardIndex6,
      showCardIndex7,
      cardValues,
      totalHours1,
      totalHours2,
      totalHours3,
      totalHours4,
      totalHours5,
      totalHours6,
      totalHours7,
      totalHours8,
      alertState,
      alertTitle,
      alertType,
      getValidDays,
      annualLeaveDays,
      specialLeaveDays,
      sickLeaveDays,
      childsickLeaveDays,
      kCareLeaveDays,
      unpaidLeaveDays,
      isStatus,
      isLeaveType,
      leave_stats,
      closeAll,
      currentMonthName,
    };
  },
};
</script>

<style scoped>
.container {
  margin-top: -60px;
  width: 100%;
}
.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;

  border-radius: 40px;
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px;
}

.current-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344054;
}

.week {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.day-row,
.date-row,
.input-row {
  display: flex;
  width: 100%;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.11rem;
  height: 2.11rem;
  font-size: 12px;
  border: 1px solid #eaecf0;
  padding: 2px;
  box-sizing: border-box;
}
.celll:hover {
  background: #a2554f6b;
}
.weekend {
  z-index: 10px;
  background-color: #eaecf0;
}

.active {
  border-radius: 50%;

  width: 5px;
  height: 5px;

  background: #a2544f;
}

input {
  outline-width: 0;
  width: 100px;
  height: 60px;
}

.input-card {
  position: relative;
}

.input-value {
  padding: 0.2em;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.card {
  position: absolute;
  top: 100%;
  left: 0;
  width: 110px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 0.5em;
  z-index: 1;
  justify-content: center;
}

.card-item {
  cursor: pointer;
  padding: 0.2em 0.5em;
}
.approve > div {
  width: 16px;
  height: 16px;
  background: #d0f8ab;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #eaecf0;
}
.pending > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #fef0c7;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.rejected > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #fda29b;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.holiday-l > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #f4eae9;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.pending,
.rejected,
.holiday-l,
.approve {
  display: flex;
  align-items: center;
}
.leave_card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  width: 399px;
}
.header {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.desc {
  height: 4.22rem;
  border: 1px solid #eaecf0;
  width: 13.75rem;
  color: #344054;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.text {
  height: 2.11rem;
  border: 1px solid #eaecf0;
  width: 13.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.textleft {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100px;

  border-left: 1px solid #eaecf0;
}
.leave {
  color: #101828;
  width: 12.5rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 5px;
}
.holiday {
  width: 200px;
}
.total {
  height: 70px;
}
.hour {
  height: 35px;
}
.pending-class {
  background: #fef0c7 !important;
}
.approved-class {
  background: #d3f8df !important;
  pointer-events: none;
}
.rejected-class {
  background: #fda29b !important;
  pointer-events: none;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.is-holiday {
  background: #f4eae9 !important;
}

.holidays {
  pointer-events: none;
  background-color: #f2f4f7;
}
.disable_calender {
  pointer-events: none;
}
.disabled {
  background-color: #f2f4f7;
  pointer-events: none;
}
.draft-class {
  background: #eef4ff;
  pointer-events: none;
}
.blocked {
  background-color: #f2f4f7;
  pointer-events: none;
}
.notAllowcellType {
  pointer-events: none;
}
</style>
