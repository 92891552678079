<template>
    <div>
      <div class="pl-3 cash">
        <h3 class="py-2 H500 SPC-MT-200">{{ $t("bankcash.cashBalance") }}</h3>
        <div class="grid grid-cols-4 gap-4 cash__card">
          <div v-if="isAllCurrency?.balances?.length === 0">
            <p class="P300">{{ $t("bankcash.noCashBalance") }}</p>
          </div>
          <div
            class="cash__card__item"
            v-for="(cash, index) in isAllCurrency?.balances"
            :key="index"
          >
            <p class="P300">{{ cash.currency_name }}</p>
            <h3 class="py-2 H500">
              {{ getLanguageCurrency(cash?.cash_balance) }}
            </h3>
          </div>
        </div>
      </div>
      <div class="pl-2 bank SPC-MT-200">
        <h3 class="py-2 H500 SPC-MT-200">{{ $t("bankcash.bankBalance") }}</h3>
        <div class="grid grid-cols-4 gap-4 bank__card">
          <div v-if="isAllCurrency?.balances?.length === 0">
            <p class="P300">{{ $t("bankcash.noBankBalance") }}</p>
          </div>
          <div
            class="cash__card__item"
            v-for="(cash, index) in isAllCurrency?.balances"
            :key="index"
          >
            <p class="P300">{{ cash.currency_name }}</p>
            <h3 class="py-2 H500">
              {{ getLanguageCurrency(cash?.bank_balance) }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent } from "vue";
  import { mapGetters, mapState } from 'vuex';
  import { currencyFormatter } from "@/services/helpers";
  export default defineComponent({
    name: "CurrencyBalances",
  
    data() {
      return {
        showClose: false
      }
    },
  
    props: {
  
      cash_currency: {
        type: String,
        default: 'US Dollar',
      },
  
      cash_amount: {
        type: Number,
        default: 0.0,
      },
      bank_curency: {
        type: String,
        default: 'US Dollar',
      },
  
      bank_amount: {
        type: Number,
        default: 0.0,
      },
    },
    computed: {
      ...mapGetters("translation", ["getLocale"]),
      isAllCurrency() {
      return this.$store.getters["pmLocalModules/isAllCurrency"];
    },
      appLang() {
        return this.translation.locale;
      },
      ...mapState(["translation"]),
  
    },
    methods: {
      emitDelete(data) {
  
        this.$emit("delete-event", data);
      },
      getLanguageCurrency(amount) {
        return this.appLang === "de" ? this.germanCurrency(amount) : amount?.formatCurrency()
      },
  
      germanCurrency(curr) {
        return currencyFormatter(curr);
      },
      emitOpen(data) {
  
        this.$emit("open-event", data);
      },
    },
    mounted() {
  
    },
  });
  </script>
  <style scoped>
  .cash__card__item > p {
    font-size: 16px;
    font-weight: 500;
  }
  </style>
  