<template>
  <div>
    <app-header
      :name="$t('partners.editPartner')"
      class="mb-5 ml-2"
      :show-arrow-back="true"
    >
      <template #action v-if="isPartnerId">
        <app-secondary-button
          :buttonLabel="$t('partners.editPartner')"
          @click="showEditButton"
          v-show="hideEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
    </app-header>
    <div class="mb-10">
      <div>
        <button
          :disabled="!isPartnerId"
          v-for="(tab, index) in tabList"
          :key="index"
          :class="[
            'tab-button',
            { active: currentTab === tabs[index] },
            { disabled: !isPartnerId },
          ]"
          @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component :is="currentTab" v-bind="isCurrentTab" class="pt-10"></component>
    <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="closeDialog"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="discardChanges"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-unsaved-changes-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BasicInformation from "@/components/dashboard/partners/BasicInformation.vue";
import ContactInformation from "@/components/dashboard/partners/Contact.vue";
import Address from "@/components/dashboard/partners/Address.vue";
import BankDetails from "@/components/dashboard/partners/BankingDetails.vue";
import AdditionalInformation from "@/components/dashboard/partners/AdditionalInformation.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";

export default defineComponent({
  name: "AddEmployee",
  components: {
    AppUnsavedChangesDialog,
    BasicInformation,
    Address,
    ContactInformation,
    BankDetails,
    AdditionalInformation,
  },
  data() {
    return {
      currentTab: "Basic Information",
      // tabList: [
      //   "Basic Information",
      //   "Address",
      //   "Contact",
      //   "Bank Details",
      //   "Additional Information",
      // ],
      tabs: [
        "BasicInformation",
        "Address",
        "ContactInformation",
        "BankDetails",
        "AdditionalInformation",
      ],
    };
  },
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData
        ?.filter((module) => module.module_parent_name === "Partners")
        .map((module) => module.module_name);
      this.$store.commit("auth/SET_CAN_EDIT_TO_STATE", findModules);
      return findModules;
    },

    noAccess() {
      const modifiedData = this.$store.getters["auth/noAccess"];
      const findModules = modifiedData
        ?.filter((module) => module.module_parent_name === "Partners")
        .map((module) => module.module_name?.replace("Partner", "")?.trim());
      return findModules;
    },
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    isCurrentTab() {
      return this.$store.getters["partnerModules/isCurrentTab"];
    },
    isPartnerUpdate() {
      return this.$store.getters["partnerModules/isPartnerUpdate"];
    },
    isEditButton() {
      return this.$store.getters["partnerModules/isEditButton"];
    },
    currentPath() {
      return this.$route.path;
    },
    tabList() {
      const list = [
        "Basic Information",
        "Address",
        "Contact",
        "Bank Details",
        "Additional Information",
      ];
      const notFoundInFilter = [];

      for (const item of list) {
        if (!this.noAccess?.includes(item)) {
          notFoundInFilter.push(item);
        }
      }
      // const filteredList = list?.find(this.noAccess)
      return notFoundInFilter;
    },
    modifiedCanEdit() {
      return this.canEdit?.map((item) =>
        item?.replace("Partner", "")?.replace(/\s/g, "")
      );
    },
    hideEditButton() {
      const getStatus =
        !this.isEditButton && this.modifiedCanEdit?.includes(this.currentTab);
      return getStatus;
    },
  },
  methods: {
    showEditButton() {
      this.$store.commit(
        "partnerModules/SET_EDIT_BUTTON_TO_STATE",
        this.modifiedCanEdit?.includes(this.currentTab)
      );
    },
    checkChangeStatus() {
      return Object.values(this.isPartnerUpdate)?.some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      if (this.isPartnerUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isPartnerUpdate).reduce(
        (acc, curr) => {
          if (this.isPartnerUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  created() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.commit("partnerModules/SET_EDIT_BUTTON_TO_STATE", false);
    this.currentTab = this.tabs[this.isCurrentTab];
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>

<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
.tab-button.disabled {
  cursor: not-allowed;
}
</style>
