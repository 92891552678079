<template>
  <section>
    <div class="w-full">
      <!-- Start coding here -->
      <div
        class="relative overflow-hidden bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg"
      >
        <div
          class="flex flex-col items-center justify-between p-3 px-6 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
        >
          <div class="w-1/2 py-2 text-2xl">
            <div class="flex items-center justify-between">
              <h3 class="H500">
                {{ $t("requestFundsTab.requestItems") }}
              </h3>
            </div>
          </div>
          <div
            class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3"
          ></div>
        </div>

        <div class="overflow-x-auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            aria-describedby=""
          >
            <thead class="bg-white">
              <tr style="height: 40px">
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-left"
                >
                  {{ $t("requestFundsTab.nameOfBudgetItem") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ $t("requestFundsTab.planAmount") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ $t("requestFundsTab.remainingAmount") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ $t("requestFundsTab.requestAmount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="height: 50px"
                class="odd:bg-white even:bg-gray-200"
                v-for="fund in funds"
                :key="fund.id"
              >
                <td class="text-left">
                  {{ fund.position }}
                </td>
                <td class="text-right pr-5">
                  {{ getLanguageCurrency(fund.plan_amount) }}
                </td>
                <td class="text-right pr-5">
                  {{ getLanguageCurrency(fund.remaining_amount) }}
                </td>
                <td class="pa-0">
                  <input
                    v-if="disabled"
                    class="w-full h-[50px] py-2 px-5 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
                    type="text"
                    :disabled="disabled"
                    pattern="^[1-9]\d*$"
                    :class="[
                      {
                        'text-right': true,
                      },
                    ]"
                    :value="getLanguageCurrency(fund.requested_amount)"
                    :placeholder="$t('requestFundsTab.enterAmount')"
                  />
                  <input
                    v-else
                    class="w-full text-right pr-5 h-[50px] py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
                    type="text"
                    :disabled="disabled"
                    pattern="^[1-9]\d*$"
                    :placeholder="$t('requestFundsTab.enterAmount')"
                    @input="handleInputData($event, fund.budget_item, fund.remaining_amount)"
                    v-model="fund.inputValue"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot class="bg-white">
              <tr style="height: 40px">
                <th
                  class="px-5 text-base !text-[#344054] !font-medium text-left"
                >
                  Total
                </th>
                <th
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ getLanguageCurrency(tableStats?.total_planned_amount) }}
                </th>
                <th
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ getLanguageCurrency(tableStats?.total_remaining_amount) }}
                </th>
                <th
                  class="px-5 text-base !text-[#344054] !font-medium text-right"
                >
                  {{ getLanguageCurrency(tableStats?.total_requested_amount) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { currencyFormatter } from "@/services/helpers";
import { mapState, mapGetters } from "vuex";

export default defineComponent({
  name: "BudgetItemTable",
  props: {
    mode: {
      type: String,
      default: "view",
    },
    data: {
      type: Array,
      default: () => [],
    },

    tableStats: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      funds: [],
    };
  },
  computed: {
    ...mapGetters("pmLocalRequestFund", [
      "getIsFundTable",
    ]),
    disabled() {
      if (this.mode === "view") {
        return true;
      } else {
        return false;
      }
    },
    appLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },
  watch: {
    getIsFundTable: {
      handler: function (val) {
        if (val) {
          this.clearInputs();
        }
      },
      deep: true,
    },
  },
  methods: {
    getLanguageCurrency(amount) {
      return this.appLang === "de"
        ? this.germanCurrency(amount)
        : amount?.formatCurrency();
    },

    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    handleInputData($event, budget_item, remaining_amount) {

      let amount = $event.target.value || 0;

      let requested_amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount);
      if (isNaN(parseFloat(amount))) {
        $event.target.value = 0;
      }
      let tr = $event.target.parentNode.parentNode;
      if (requested_amount.toFixed(2) > remaining_amount) {
        tr?.classList.add("red-border");
      }else{
        tr?.classList.remove("red-border");
      }
      //persist the requested amount to the store
      this.$store.commit(
        "pmLocalRequestFund/ASSIGN_REQUESTED_AMOUNT_TO_BUDGET_ITEM",
        {
          budget_item: budget_item,
          requested_amount: requested_amount,
        }
      );

      this.$store.commit(
        "pmLocalRequestFund/CLEAN_REQUESTED_FUNDS_BODY", true
      );
      //build the body for the request funds api
      this.$store.commit(
        "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_PROJECT_FUNDS",
        {
          budget_item: budget_item,
          requested_amount: requested_amount,
          general_fund_request: false,
        }
      );
      //sum up the total requested amount
      this.$store.commit(
        "pmLocalRequestFund/SUM_REQUESTED_AMOUNTS_IN_BUDGET_ITEMS"
      );
    },
    clearInputs() {
      this.funds?.forEach(fund => {
        if( "inputValue" in fund) {
          fund.inputValue = '';
        }
      });
    },
  },
  created() {},
  mounted() {
    this.funds = this.data?.map((fund) => {
      return {
        ...fund,
        inputValue: '',
      };
    })
  },
});
</script>
<style scoped>
table {
  color: #344054;
}
tr > th {
  background-color: #f2f4f7;
}

tfoot > tr > th {
  background-color: #ffffff;
}
tbody tr:nth-of-type(even) {
  background-color: #f9fafb;
}

td {
  padding-left: 24px;
}
tr {
  padding: 16px 24px;
  height: 56px;
  width: 100%;
}
.tablecard {
  border: 2px solid #eaecf0;
}

.red-border {
  border: 2px solid #fdc6c2;
}
</style>
