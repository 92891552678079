<template>
    <div>
      <app-header
        v-if="currentPath !== '/profile'"
        :name="$t('advancePaymentRequest.title')"
        :show-arrow-back="false"
      />
    </div>
    <div :class="currentPath === '/profile' ? 'mt-3 filter_parent SPC-MT-400' : 'filter_parent SPC-MT-400'">
      <v-row>
        <v-col cols="4" md="3" class="">
          <app-search-field
            @search-event="fetchAgain(currentPage)"
            @search-function="searchAdvancePaymentRequest"
            placeholder="Search by requester or cost center"
            :callback="true"
          />
        </v-col>
      </v-row>
  
      <div
        v-if="appLoading"
        class="d-flex justify-center h-[500px] flex items-center"
      >
        <app-loader></app-loader>
      </div>
      <div v-else class="SPC-MT-400">
        <app-custom-table
          title="All Advance Payments"
          :headers="headers"
          :items="allPaymentRequest"
          :show-legend="true"
          row-styles="cursor-pointer"
          @item-click="editAdvancePaymentRequest"
        >
          <template #title>
            <h3 class="H500">{{ $t("advancePaymentRequest.tableTitle") }}</h3>
          </template>
         
          <template #footer>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
              v-if="totalPages > 10"
            />
          </template>
        </app-custom-table>
        <delete-advance-payment-request ref="deleteAdvancePaymentRequest" />
      </div>
    </div>
  </template>
  <script>
  import { defineComponent } from "vue";
  import Paginator from "@/components/shared/PaginationComponent.vue";
  import DeleteAdvancePaymentRequest from "./DeleteRequest.vue";
  import { mapState } from "vuex";
 

  
  export default defineComponent({
    name: "AdvancePaymentRequestList",
    components: {
      DeleteAdvancePaymentRequest,
      Paginator,
    },
    data() {
      return {
        currentPages: 1,
        country: "",
      };
    },
    watch: {
     
    },
    computed: {
      allPaymentRequest() {
        const modifiedData =
          this.$store.getters["advancePaymentModules/allAdvancePaymentRequest"];
        return modifiedData.map((data) => {
          return {
            id: data.id,
            cost_center: data.cost_center,
            contract: data.contract,
            request_date: data.request_date?.formattedDate(),
            payment_date: data.payment_date?.formattedDate(),
            requested: data.amount,
            condition_on_account: data.condition_on_account,
            status: data.status?.name,
            status_action: data.status?.status,
          };
        });
      },
      // allRequests(){
      //   return this.currentUser.is_partner ? this.allCompanyPaymentRequest : this.allAdvancePaymentRequest;
      // },
      headers() {
        // const header =['Cost Center', 'Contract', 'Date', 'Amount', 'Condition on Account', 'Status'];
        const header=[
          {key: "cost_center", label: "Cost Center"},
          {key: "contract", label: "Contract"},
          {key: "request_date", label: "Request Date"},
          {key: "payment_date", label: "Payment Date"},
          {key: "requested", label: "Amount", type: "digit"},
          {key: "condition_on_account", label: "Condition on Account"},
          {key: "status", label: "Status"},

        ]
        return header;
        // return this.currentUser.is_partner ? header :  this.$store.getters["advancePaymentModules/headers"];
      },
      canEdit() {
        const modifiedData = this.$store.getters["auth/canEdit"];
        const findModules = modifiedData?.filter(
          (module) => module.module_name === "Hospitality"
        );
        return findModules?.length !== 0;
      },
      currentPage() {
        return this.$store.getters["advancePaymentModules/isCurrentPage"];
      },
      totalPages() {
        return this.$store.getters["advancePaymentModules/isTotalPages"];
      },
  
      appLoading() {
        return this.$store.getters["advancePaymentModules/appLoading"];
      },
      appLocaleLang() {
        return this.translation.locale;
      },
      ...mapState(["translation"]),
      currentPath() {
        return this.$route.path;
      },
      currentUser() {
        return this.$store.getters["auth/isUserDetails"];
      },
    },
    methods: {
      addAdvancePaymentRequest() {
        this.currentPath === '/profile'? this.$router.push({ name: "profileAddPaymentRequest" }): this.$router.push({ name: "addPaymentRequest" });
      },
      editAdvancePaymentRequest(data) {
        this.$store.commit(
          "advancePaymentModules/SET_CURRENT_ADVANCE_PAYMENT_ID_TO_STATE",
          data.id
        );
         this.$router.push({ name: "viewAdvancePayments" });
      },
     
      getAllAdvancePaymentRequest(page) {
        const payload = {
          load_page: false,
          page_number: page,
          type: this.currentUser.is_partner ?  'partner' : 'expert',
        };
        this.$store.dispatch(
          "advancePaymentModules/getAllAdvancePaymentRequest",
          payload
        );
      },
  
      searchAdvancePaymentRequest(search_query) {
        const payload = {
          search_query: search_query,
        };
        this.$store.dispatch(
          "advancePaymentModules/searchAdvancPayment",
          payload
        );
      },
      fetchAgain(page) {
        this.getAllAdvancePaymentRequest(page);
      },
  
      loadMore() {
        const page = this.currentPages;
        this.getAllAdvancePaymentRequest(page);
      },
    },
    created() {
      // this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
      this.$store.commit("advancePaymentModules/SET_CURRENT_ADVANCE_PAYMENT_TO_STATE", {});
      this.$store.commit("advancePaymentModules/SET_CURRENT_ADVANCE_PAYMENT_ID_TO_STATE", null);
      this.getAllAdvancePaymentRequest(1);
    },
    mounted() {
      // this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    },
  });
  </script>
  