import { computed } from "vue";
import store from "@/store";

export interface Permission {
  module__name: string;
  // Define other properties of the Permission type
  access_level: string;
}

export function extractModuleNames(permissions: Permission[]): string[] {
  if (!permissions || !Array.isArray(permissions)) {
    return []; // Return empty array if permissions is not iterable
  }

  return permissions.reduce((accumulator: string[], permission) => {
    if (permission.access_level !== "0") {
      accumulator.push(permission.module__name);
    }
    return accumulator;
  }, []);
}

export const permissions = computed(() => store.getters["auth/permissions"]);
export const moduleNames = computed(() =>
  extractModuleNames(permissions.value)
  // console.log(moduleNames.value)
);
