<template>
  <div>
    <app-header
      :name="isProjectID ? isProjectTitle : $t('projects.addProject')"
      class="mb-5 ml-n2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
      <!-- <template #action v-if="isProjectID">
        <app-secondary-button
          :buttonLabel="$t('employees.editProfile')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template> -->
    </app-header>
    <div class="">
      <span v-if="showTabs && isSubCostCenter" class="d-flex">
        <div v-for="(tab, index) in filteredTabs()" :key="index">
          <app-tooltip
            text="Create Project Contract first "
            :tooltip-show="
              (!isProjectID && tab === 'Project Contract') ||
              (tabListForContract?.includes(tab.name) && !isContractID)
            "
          >
            <template #content>
              <button
                :disabled="
                  !isProjectID ||
                  (tabListForContract?.includes(tab.name) && !isContractID)
                "
                :class="[
                  'tab-button',
                  { active: currentTab === tab.id },
                  { disabled: !isProjectID },
                  {
                    '!opacity-50':
                      tabListForContract?.includes(tab.name) && !isContractID,
                  },
                  {
                    '!opacity-50':
                      tablistForBasic?.includes(tab.name) && !isProjectID,
                  },
                ]"
                @click="handleProceed(tab.id, tab.name)"
              >
                <span class="tab-tooltip">
                  {{ $t(getTranslatedStringKey(tab.name)) }}
                </span>
              </button>
            </template>
          </app-tooltip>
        </div>
      </span>
      <span v-else>
        <button
          :disabled="!isProjectID"
          v-for="(tab, index) in excludedTabContent"
          :key="index"
          :class="[
            'tab-button',
            { active: currentTab === tab.id },
            { disabled: !isProjectID },
            { '!opacity-50': !isProjectID && tablistForBasic?.includes(tab.name) },
          ]"
          @click="currentTab = tab.id"
        >
          {{ $t(getTranslatedStringKey(tab.name)) }}
        </button>
      </span>
      <div class="mt-2 border-t-[1px]"></div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-10"
      :handleButtonRoute="handleButtonRoute"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
import BasicInformation from "@/components/dashboard/projects/BasicInformation.vue";
import ProjectContract from "@/components/dashboard/projects/ProjectContract.vue";
import ProjectBudget from "@/components/dashboard/projects/ProjectBudget.vue";
import ProjectCalculations from "@/components/dashboard/projects/ProjectCalculations.vue";
import ProjectMission from "@/components/dashboard/projects/ProjectMission.vue";
import Contracts from "@/components/dashboard/projects/contracts/Contracts.vue";
import ProjectFiles from "@/components/dashboard/projects/ProjectFiles.vue";
import ForeignEstablishment from "@/components/dashboard/projects/ForeignEstablishment.vue";
import ProjectLocalBudget from "@/components/dashboard/projects/ProjectLocalBudget.vue";
import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "Add Project",
  components: {
    BasicInformation,
    ProjectContract,
    ProjectBudget,
    ProjectCalculations,
    ProjectFiles,
    ProjectMission,
    Contracts,
    ForeignEstablishment,
    ProjectLocalBudget
  },
  data() {
    return {
      currentTab: "Personal",
      tabList: [
        "Basic Information",
        "Project Contract",
        "Foreign Establishment",
        "Calculations",
        "Budget",
        "Local Budget",
        "Contracts",
        "Missions",
        "Files",
      ],
      tabs: [
        "BasicInformation",
        "ProjectContract",
        "ForeignEstablishment",
        "ProjectCalculations",
        "ProjectBudget",
        "ProjectLocalBudget",
        "Contracts",
        "ProjectMission",
        "ProjectFiles",
      ],
      newTabList: [
        { id: "BasicInformation", name: "Basic Information" },
        { id: "ProjectContract", name: "Project Contract" },
        { id: "ForeignEstablishment", name: "Foreign Establishment" },
        { id: "ProjectCalculations", name: "Calculations" },
        { id: "ProjectBudget", name: "Budget" },
        { id: "ProjectLocalBudget", name: "Local Budget" },
        { id: "Contracts", name: "Contracts" },
        { id: "ProjectMission", name: "Missions" },
        { id: "ProjectFiles", name: "Files" },
        { id: "MilestonesData", name: "Milestones" },
      ],
      excludedTabNames: ["Basic Information", "Files"],
      excludedTabContent: [
        { id: "BasicInformation", name: "Basic Information" },
        { id: "ProjectFiles", name: "Files" },
      ],
      tabListForContract: ["Calculations", "Budget", "Contracts", "Missions", "Local Budget", "Foreign Establishment"],
      tablistForBasic: ["Files", "Project Contract"],
    };
  },

  computed: {
    isContractID() {
      return this.$store.getters["projectModules/isContractId"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isProjectTitle() {
      return this.$store.getters["projectModules/isProjectTitle"];
    },
    showTabs() {
      return this.$store.getters["projectModules/showTabs"];
    },
    isSubCostCenter() {
      return this.$store.getters["projectModules/isSubCostCenter"];
    },
    isProjectUpdate() {
      return this.$store.getters["projectModules/isProjectUpdate"];
    },
    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },
    showLocalBudget() {
      return this.isCurrentProject.show_local_budget;
    },

    ...mapState(["translation"]),
  },

  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    filteredTabs() {
      const hasMilestones = this.isSplitType === "milestone";
      let hasForeignEstablishment = this.isForeignEstablishment;
      let hasLocalBudget = this.showLocalBudget;
      let newTabs = this.newTabList;
      if (!hasForeignEstablishment) {
        newTabs = this.newTabList.filter(
          (singTab) => singTab.id !== "ForeignEstablishment"
        );
      }
      if (!hasMilestones) {
        newTabs = newTabs.filter((singTab) => singTab.id !== "MilestonesData");
      }

      if (!hasLocalBudget) {
        newTabs = newTabs.filter((singTab) => singTab.id !== "ProjectLocalBudget");
      }

      // this.tabsList = this.tabList?.filter((tab) => {
      //   return !(!hasMilestones && tab === "Milestones" || !hasForeignEstablishment && tab === "Foreign Establishment" );
      // });

      // return this.tabsList

      return newTabs;
    },
    checkChangeStatus() {
      return Object.values(this.isProjectUpdate)?.some((elt) => elt === true);
    },
    handleProceed(id, tabName) {
      this.newTabIndex = id;
      if (this.isProjectUpdate[tabName]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = id
      }
    },
    handleGoToBack() {
      if (Object.values(this.isProjectUpdate)?.some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.isProjectUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isProjectUpdate)?.reduce(
        (acc, curr) => {
          if (this.isProjectUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("projectModules/SET_PROJECT_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.newTabIndex;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    if (this.showTabs && this.isSubCostCenter) {
      this.currentTab = this.newTabList[this.isCurrentTab].id;
    } else {
      this.currentTab = this.excludedTabContent[this.isCurrentTab].id;
    }
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);
    this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", "");
    if (this.showTabs && this.isSubCostCenter) {
      this.currentTab = this.newTabList[0].id;
    } else {
      this.currentTab = this.excludedTabContent[0].id;
    }
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>
<style scoped>
button:disabled {
  pointer-events: none;
}
.tab-tooltip:hover .tooltip {
  visibility: show;
}
</style>
