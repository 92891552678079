<template>
  <div
    v-if="isAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <!-- project phase and status -->

    <div>
      <project-phase-status :projectData="isProject"></project-phase-status>
    </div>
    <!-- project forecast -->
    <project-forecast
      ref="forecastData"
      @forecast-data="handleData($event)"
      @emit-initial-forecast-data="handleInitialData"
      :projectData="isProject"
    ></project-forecast>
    <!-- project EOI -->
    <div>
      <project-eoi
        ref="projectEOI"
        @emit-eoi-data="handleData($event)"
        :projectData="isProject"
        v-if="showProjectPhase?.includes('EOI')"
      ></project-eoi>
      <project-eoi
        @emit-eoi-data="handleData($event)"
        :projectData="isProject"
        v-else-if="showProjectPhase?.includes('Project')"
      ></project-eoi>
    </div>
    <div>
      <project-shortlist
        ref="projectShorlist"
        @emit-shortlist-data="handleData($event)"
        :projectData="isProject"
        v-if="showProjectPhase?.includes('Shortlist')"
      ></project-shortlist>
      <project-shortlist
        @emit-shortlist-data="handleData($event)"
        :projectData="isProject"
        v-else-if="showProjectPhase?.includes('Project')"
      ></project-shortlist>
    </div>
    <!-- project proposal -->
    <div>
      <project-proposal
        ref="projectProposal"
        @emit-proposal-data="handleData($event)"
        :projectData="isProject"
        v-if="showProjectPhase?.includes('Proposal')"
      ></project-proposal>
      <project-proposal
        @emit-proposal-data="handleData($event)"
        :projectData="isProject"
        v-else-if="showProjectPhase?.includes('Project')"
      ></project-proposal>
    </div>

    <!-- project offer -->
    <div>
      <project-offer
        ref="projectOffer"
        :projectData="isProject"
        v-if="showProjectPhase?.includes('Offer')"
      ></project-offer>
      <project-offer
        :projectData="isProject"
        v-else-if="showProjectPhase?.includes('Project')"
      ></project-offer>
    </div>

    <div>
      <project-deadline
        ref="projectDeadline"
        @emit-deadline-data="handleData($event)"
        :projectData="isProject"
      ></project-deadline>
    </div>
    <!-- action buttons -->
    <v-sheet max-width="1118" class="bg-transparent mt-7">
      <v-row class="">
        <v-col cols="10" offset="" class="">
          <v-sheet max-width="" class="d-flex justify-end bg-transparent">
            <app-secondary-button
              v-show="isEditButton"
              :loading="loading"
              :buttonLabel="$t('projects.saveAndExit')"
              @click="addProject('exit')"
            >
            </app-secondary-button>
            <app-primary-button
              v-show="isEditButton"
              :buttonLabel="$t('projects.saveAndContinue')"
              class="ml-5"
              :loading="loading"
              @click="addProject('continue')"
            >
            </app-primary-button>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- display Close Project pop dialog for user -->
    <app-dialog
      ref="dialog"
      :title="$t('projects.closeProjectTitle')"
      :subtitle="$t('projects.closeProjectSubtitle')"
    >
      <template #content>
        <v-row class="mt-2 px-">
          <v-col cols="12" class="">
            <textarea
              rows="3"
              class="w-full p-2 text-gray-800 border rounded-lg resize-none focus:outline-none"
              :placeholder="$t('projects.addOptionalComment')"
              v-model="commentsData.comments"
            >
            </textarea>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-row class="px-3 py-0 mb-3">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('projects.cancel')"
              @click="hideDialog"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
              :loading="loading"
              :buttonLabel="$t('projects.closeProject')"
              @click="submitCloseProject"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import ProjectPhaseStatus from './ProjectPhaseStatus.vue';
import ProjectForecast from './ProjectForecast.vue';
import ProjectEoi from './ProjectEoi.vue'
import ProjectShortlist from './ProjectShortlist.vue'
import ProjectProposal from './ProjectProposal.vue'
import ProjectOffer from './ProjectOffer.vue'
import ProjectDeadline from './ProjectDeadline.vue'
import Api from "@/services/api"
import { getTransString,} from "@/services/helpers";


export default defineComponent({
  components: {
    ProjectPhaseStatus,
    ProjectForecast,
    ProjectEoi,
    ProjectShortlist,
    ProjectProposal,
    ProjectOffer,
    ProjectDeadline
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      phase: "",
      selectedPhase: [],
      selectedStatus: null,
      closeProject: '',
      loading: false,

      disabled: true,
      reqFields: ["project_title", "responsible_person_id", "client_id", "project_number", "currency", "project_manager_id"],
      commentsData: {
        comments: ''
      },
      initialCommentsData: {
        comments: ''
      },
      firstRun: true,
    }
  },

  watch:{
    isProject(data){
      this.$store.commit('projectModules/SET_SELECTED_PHASE_TO_STATE', data?.project_phase)
      this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", data?.project_contracts)
    },
    isSelectedStatus(newValue){
      if(newValue === 'Closed'){
        this.$refs.dialog.showDialog = true
      }
    }

  },
  computed: {
    isSelectedPhase() {
      return this.$store.getters["projectModules/isSelectedPhase"]
    },
    isAppLoading() {
      return this.$store.getters["projectModules/isAppLoading"]
    },
    isSelectedStatus() {
      return this.$store.getters["projectModules/isSelectedStatus"]
    },
    phaseOptions() {
      const modifiedData = this.$store.getters["projectModules/isPhaseOptions"];
      return modifiedData?.convertArrayToObject();
    },
    statusOptions() {
      const modifiedData =
        this.$store.getters["projectModules/isStatusOptions"];
      return modifiedData?.convertArrayToObject();
    },
    showProjectPhase(){
      let showPhase = []
      if(this.isSelectedPhase === 'EOI'){
        showPhase = ['Forecast', 'EOI']
      }
      else if(this.isSelectedPhase === 'Shortlist'){
        showPhase = ['EOI', 'Shortlist']
      }
      else if (this.isSelectedPhase === 'Proposal'){
        showPhase = ['EOI', 'Shortlist', 'Proposal']
      }
      else if(this.isSelectedPhase === 'Offer' || this.isSelectedPhase === 'Project'){
        showPhase = ['EOI', 'Shortlist', 'Proposal', 'Offer']
      }
      return showPhase
    },
    isProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isDisabled() {
      return this.$store.getters["projectModules/isDisabled"]
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditProject() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    hideDialog() {
      this.$refs.dialog.showDialog = false
      this.selectedStatus = ""
      this.commentsData.comments= ''
    },

    handleData(data) {

      // this.disabled = this.isSaveBtnDisabled()

      const id = {
        project_id: this.isProjectID

      }
      this.receiveForecastData = {
        ...this.receiveForecastData,
        ...data,
        project_phase: this.isSelectedPhase ? this.isSelectedPhase : this.phaseOptions[0]?.value,
        project_status: this.isSelectedStatus ?  this.isSelectedStatus : this.statusOptions[0]?.value,
        ...(this.isEditProject ? id : {}),

      };

    },

    // show close project dialog
    submitCloseProject() {
      this.$refs.dialog.showDialog = false
    },
    // display error alert
    checkAlertEror(message){
        this.alertState = true
        this.alertType = "error";
        this.alertTitle = message;
    },

    // create and update project
    addProject(data) {
      const forecastData =  this.$refs.forecastData? {...this.$refs.forecastData.projectsForm} : {}
      const eoiData =  this.$refs.projectEOI? {...this.$refs.projectEOI.projectsForm} : {}
      const shortlistData =  this.$refs.projectShorlist? {...this.$refs.projectShorlist.projectsForm} : {}
      const proposalData =  this.$refs.projectProposal? {...this.$refs.projectProposal.projectsForm} : {}
      const offerData =  this.$refs.projectOffer ? {...this.$refs.projectOffer.projectsForm} : {}
      const deadlineData =  this.$refs.projectDeadline? {...this.$refs.projectDeadline.projectsForm} : {}
      const phaseData = { project_phase: this.isSelectedPhase ? this.isSelectedPhase : this.phaseOptions[0]?.value }
      const statusData = { project_status: this.isSelectedStatus ?  this.isSelectedStatus : this.statusOptions[0]?.value }
      const id = this.isEditProject || (this.currentPath==='/add-project' && this.isProjectID) ? { project_id: this.isProjectID } : {}

      const requiredFields = [];
        let hasError = false

      Object.keys(forecastData)?.forEach((key) => {
        if (this.reqFields?.includes(key) && (forecastData[key] === "" || forecastData[key] === undefined  || forecastData[key] === null)) {
          requiredFields.push(key);

          this.$refs.forecastData.requiredFields = requiredFields
          hasError = true;
        }
      });

      Object.keys(offerData)?.forEach((key) => {
        if (this.reqFields?.includes(key) && (offerData[key] === "" || offerData[key] === undefined || offerData[key] === null)) {
          requiredFields.push(key);

          this.$refs.projectOffer.requiredFields = requiredFields
          hasError = true;
        }
      });

        if (hasError) {
          this.checkAlertEror('Please fill out the required fields');
          return hasError;
        }

        eoiData.partner_names = eoiData.partner_names?.getObjectValues() || []
        eoiData.client_names = eoiData.client_names?.getObjectValues() || []

        if (eoiData.project_start_date === null || eoiData.project_start_date === "" ){
          delete eoiData.project_start_date
        }

        if (eoiData.project_end_date  === null || eoiData.project_end_date === "" ){
          delete eoiData.project_end_date
        }
        if (shortlistData.shortlist_date  === null || shortlistData.shortlist_date === "" ){
          delete shortlistData.shortlist_date
        }
        if (offerData.offer_controller_id === null || offerData.offer_controller_id=== "" ){
          delete offerData.offer_controller_id
        }

        if (deadlineData.internal_deadline === null || deadlineData.internal_deadline === "" ){
          delete deadlineData.internal_deadline
        }
        if (deadlineData.external_deadline === null || deadlineData.external_deadline === ""){
          delete deadlineData.external_deadline
        }


      const payload = {...this.commentsData,...forecastData, ...eoiData, ...shortlistData, ...proposalData, ...offerData, ...deadlineData, ...phaseData, ...statusData, ...id }

      Api()
        .post('/projects/project/add-project/', payload)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$store.commit('projectModules/SET_PROJECT_ID_STATE', response?.data?.data?.project_id)
            this.$store.commit('projectModules/SET_CURRENT_PROJECT_TO_STATE', payload);
            this.$store.commit('projectModules/SET_PROJECT_TITLE_STATE', payload.project_title);
            this.alertState = true
            this.alertType = "success";
            this.alertTitle = response.data?.detail;
              // scrollToTop()
            setTimeout(() => {
              if (data === "exit") {
                this.$router.push({ name: "projects" });
              }
              if (data === "continue") {
                this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
              }
            }, 1500);
          }
        })
        .catch((error) => {

          if (error.response.status === 309) {
            const responseData = error.response?.data?.detail

            if(responseData?.includes('project_number is required')){
              this.checkAlertEror('Project Number is required')

            } else
            if(responseData?.includes('project_title is required')){

              this.checkAlertEror('Project Title is required')

            } else
            if(responseData?.includes('client_id is required')){

              this.checkAlertEror('Client is required')
            } else
            if(responseData?.includes('responsible_person_id is required')){

              this.checkAlertEror('Responsible person is required')
            }
          }
          if (error.response.status === 311) {
            this.checkAlertEror(error?.response?.data?.detail)
          }
          if (error.response.status === 312) {
            this.checkAlertEror(error?.response?.data?.detail)
          }
          if (error.response.status === 313) {
            this.checkAlertEror(error?.response?.data?.detail)
          }
        })
    },

    handleInitialData(data) {
      const id = {
        project_id: this.isProjectID

      }
      this.initialForecastData = {
        ...this.initialForecastData,
        ...data,
        project_phase: this.isSelectedPhase ? this.isSelectedPhase : this.phaseOptions[0]?.value,
        project_status: this.isSelectedStatus ?  this.isSelectedStatus : this.statusOptions[0]?.value,
        ...(this.isEditProject ? id : {})
      };

    },

  },
  mounted(){

    this.$store.commit('projectBudgetModules/SET_PROJECT_ID_STATE', this.isProjectID)
  },
  created() {
    this.phase = 'Forecast'
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    this.$store.commit("projectModules/SET_PROJECT_PAGE_TO_STATE", true);
    this.$store.commit('projectModules/SET_DISABLED_TO_STATE', true)
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);

    if(this.isProjectID !== ''){
      this.$store.dispatch("projectModules/getSingleProject", {
          projectID: this.isProjectID,
          setter: "SET_CURRENT_PROJECT_TO_STATE",
        });
    }
  }

})
</script>
<style scoped>
textarea {
  background: #ffffff;
  border: 2px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

textarea:focus {
  background: #ffffff;
  border: 2px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}
</style>
