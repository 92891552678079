<template>
  <div>
    <v-row>
      <v-col cols="12" class="">
        <form>
          <!-- Row 1 -->
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                type="text"
                id="1"
                :label="$t(getTranslatedStringKey('Account Holder'))"
                :placeholder="
                  $t(getTranslatedStringKey('Enter account holder'))
                "
                v-model:modelValue="basicInfo.account_owner"
                validation="lettersOnly"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="2"
                :label="$t(getTranslatedStringKey('Account Number'))"
                :placeholder="
                  $t(getTranslatedStringKey('Enter account number'))
                "
                v-model:modelValue="basicInfo.account_number"
              ></app-input-field>
            </div>
          </div>
          <!-- Row 2 -->
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="2"
                :label="$t(getTranslatedStringKey('Bank Name'))"
                :placeholder="$t(getTranslatedStringKey('Enter bank name'))"
                validation="lettersOnly"
                v-model:modelValue="basicInfo.bank_name"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="4"
                v-model="basicInfo.city_of_bank"
                :label="$t(getTranslatedStringKey('City of Bank'))"
                validation="lettersOnly"
                :placeholder="
                  $t(getTranslatedStringKey('Enter the city of the bank'))
                "
              ></app-input-field>
            </div>
          </div>
          <!-- Row 3 -->
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="5"
                :label="$t(getTranslatedStringKey('Sort Code'))"
                :placeholder="$t(getTranslatedStringKey('Enter sort number'))"
                validation="digitOnly"
                v-model:modelValue="basicInfo.sort_code"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="6"
                :label="$t(getTranslatedStringKey('Swift'))"
                :placeholder="$t(getTranslatedStringKey('Enter swift code'))"
                validation="digitsAndLettersOnly"
                v-model:modelValue="basicInfo.swift_code"
              ></app-input-field>
            </div>
          </div>
          <!-- Row 4 -->
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="7"
                :label="$t(getTranslatedStringKey('IBAN'))"
                :placeholder="$t(getTranslatedStringKey('Enter IBAN number'))"
                validation="digitsAndLettersOnly"
                v-model:modelValue="basicInfo.iban"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                type="text"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="3"
                :label="$t(getTranslatedStringKey('Transfer via'))"
                :placeholder="
                  $t(getTranslatedStringKey('Enter transfer means'))
                "
                validation="lettersOnly"
                v-model:modelValue="basicInfo.transfer_via"
              ></app-input-field>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <div class="w-[920px]">
                <div
                  v-if="currentPath !== '/edit-partner'"
                  class="flex justify-end mb-7"
                >
                  <div class="flex gap-3">
                    <div>
                      <app-secondary-button
                        :buttonLabel="$t('personal.saveAndExit')"
                        @click-event="savePartnerInfo('exit')"
                      >
                      </app-secondary-button>
                    </div>
                    <div>
                      <app-primary-button
                        @click-event="savePartnerInfo('continue')"
                        :buttonLabel="$t('personal.saveAndContinue')"
                      >
                      </app-primary-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-end gap-7">
                  <div v-show="isEditButton">
                    <app-secondary-button
                      :buttonLabel="$t('personal.cancel')"
                      @click="cancel"
                    ></app-secondary-button>
                  </div>
                  <app-primary-button
                    v-show="isEditButton"
                    :buttonLabel="$t('employment.saveChanges')"
                    @click-event="savePartnerInfo('edit')"
                  >
                  </app-primary-button>
                </div>
              </div>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
  <div class="modal-board" v-if="showmodal && basicInfo.official_email === ''">
    <div class="flex justify-between">
      <h3 class="H400">We recommend that you provide an email</h3>
      <v-icon icon="custom:closeIcon" @click="showmodal = false" />
    </div>
    <p class="P300">
      Leaving the email blank means employees won’t receive any invitation
      email.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import countries from "@/services/country_api";
import { getTransString } from "@/services/helpers";
import { validEmail } from "@/services/validation";
import Api from "@/services/api";
import { isEqual } from "lodash";
export default defineComponent({
  name: "Banking Details",
  components: {},
  props: {
    errors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isEdit: false,
      loadUserKey: null,
      firstRunRef: true,
      countryOptions: null,
      countryCitizensArray: [],
      originalFormData: {
        account_owner: "",
        bank_name: "",
        transfer_via: "",
        city_of_bank: "",
        sort_code: "",
        swift_code: "",
        iban: "",
        account_number: "",
      },
      basicInfo: {
        account_owner: "",
        bank_name: "",
        transfer_via: "",
        city_of_bank: "",
        sort_code: "",
        swift_code: "",
        iban: "",
        account_number: "",
      },
      allUserRoles: [],
      page: 1,
      roles: [],
      showmodal: true,
    };
  },
  computed: {
    isEditPartner() {
      return this.$store.getters["partnerModules/isEditPartner"];
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["partnerModules/isEditButton"] &&
        this.canEditPersonalModule?.includes("Bank Details")
      );
    },
    isPartnerData() {
      return this.$store.getters["partnerModules/isPartnerInfo"];
    },
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    isPartnerData(value) {
      Object.keys(value).forEach((elt) => {
        if (Object.keys(this.basicInfo).includes(elt)) {
          this.basicInfo[elt] = value[elt];
          if (this.firstRunRef) {
            this.originalFormData[elt] = value[elt];
          }
        }
      });
      if (this.firstRunRef) {
        this.firstRunRef = false;
      }
    },
    basicInfo: {
      deep: true,
      handler(value) {
        this.$store.commit("partnerModules/SET_PARTNER_UPDATE", {
          BankDetails: !isEqual(value, this.originalFormData),
        });
      },
    },
  },
  methods: {
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleNextClick() {
      this.$emit("next-clicked");
    },
    cancel() {
      this.$router.go(-1);
    },

    savePartnerInfo(data) {
      if (this.basicInfo.email && !validEmail(this.basicInfo.email)) {
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }
      const payload = { ...this.basicInfo };

      if (this.isPartnerId) {
        payload.partner_id = this.isPartnerId;
      }

      Api()
        .post("/partners/add-new-partner/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "partnerModules/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;

            this.$store.commit("partnerModules/SET_PARTNER_UPDATE", {
              BankDetails: false,
            });

            setTimeout(() => {
              if (data === "exit") {
                this.$router.push({ name: "partners" });
              }
              if (data === "continue") {
                this.$store.commit(
                  "partnerModules/SET_CURRENT_TAB_TO_STATE",
                  4
                );
              }
            }, 1500);
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    getPartnerInfo() {
      Api()
        .get(`/partners/get-partner-info/${this.isPartnerId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;
            this.$store.commit(
              "partnerModules/SET_PERSONAl_DATA_TO_STATE",
              data
            );
          }
        });
    },
  },
  created() {
    this.$store.commit(
      "partnerModules/SET_EDIT_PARTNER_TO_STATE",
      this.isPartnerId !== null
    );
    this.isEdit = this.isEditPartner;
    this.getPartnerInfo();
    this.$store.commit("partnerModules/SET_CURRENT_TAB_TO_STATE", 3);
  },
});
</script>

<style scoped>
.select-field {
  width: 440px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-board {
  position: fixed;
  top: 90%;
  left: 85%;
  transform: translate(-50%, -50%);
  right: 0;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 396px;
  height: 98px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}
.disabled {
  /* cursor: not-allowed; */
  color: gray;
  opacity: 0.5;
}
</style>
