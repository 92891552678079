<template>
    
    <!-- dialog for deleting and deactivating user -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
            :loading="loading"
            :buttonLabel="buttonLabel"
            @click="updateEmployeeStatus()"
          >
          </app-primary-button>
           
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  
</template>
<script>

import Api from "@/services/api";
import { mapState,} from "vuex";
import { getTransString } from "@/services/helpers";
import {  } from "@/services/endpoints";
export default {
  name: "ActivateDeactivateDialog",
 
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isActivate: false,
      currentPages: 1,
      userKey: null,
      userStatus: null,
      research: false,
      is_active: false,
      buttonLabel: ''
    };
  },

  computed: {
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {

    showDialog() {
     
     
        if (this.is_active === false) {
          this.title = "employees.activate";
          this.subTitle = "employees.activatingThis";
          this.isEdit = !this.isEdit;
          this.isActivate = true;
          this.buttonLabel="employees.activate"
          this.cancelLabel = "employees.noKeepIt";
          this.$refs.dialog.showDialog = true;
        } else {
          this.title = "employees.deactivateAccount";
          this.isActivate = false;
          this.subTitle = "employees.deactivatingThis";
          this.buttonLabel="employees.yesDeactivate"
          this.isEdit = !this.isEdit;
          this.cancelLabel = "employees.noKeepIt";
          this.$refs.dialog.showDialog = true;
        }
     
    },
  
    hideDialog() {
      this.$refs.dialog.showDialog = false;
     
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
       
      
    },
  
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
 
    updateEmployeeStatus() {
      const payload = {
        status: this.isActivate,
      };
      Api()
        .patch(`/hr/employees/update-employee-status/${this.userKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.$store.dispatch('hrResources/getAllForeignEmployees', this.currentPage)
            // this.getAllEmployees(this.currentPage);
            this.$refs.dialog.showDialog = false;
           
          }
          if (response.data.status === "error") {
            this.isEdit = !this.isEdit;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
   

  },
 
};
</script>

