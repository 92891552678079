<template>
  <!-- education -->
  <div class="container">
    <div class="card_container">
      <div class="flex items-center justify-between header">
        <h3 class="H600">{{ $t("education.education") }}</h3>
      
        <app-primary-button
          v-if="!showAndHideElement "
          :buttonLabel="$t('education.addEducation')"
          @click-event="showDialog({ data: 'add' })"
          
          id="addEducation"
          data-cy="button_addEducation"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </div>
      <div class="flex content SPC-MT-400">
        <div class="left_section w-50">
          <h3 class="H500">{{ $t("education.educationInformation") }}</h3>
          <p class="P300 desc">
            {{ $t("education.manageYour") }}
          </p>
        </div>
        <div class="flex justify-between w-100 flex-column">
          <div
            v-for="item in values"
            :key="item.id"
            class="flex justify-between my-2 right_section w-100"
          >
            <div>
              <h3 class="H500">{{ item.institution }}</h3>
              <p class="P300">{{ item.certificateTitle }}</p>
            </div>
            <div>
              <DropDown
              v-if="!showAndHideElement"
                @edit-event="showDialog({ $event, data: 'edit', id: item.id })"
                @delete-event="
                  showDeleteDialog({ $event, data: 'delete', id: item.id })
                "
                id="3-dotIcon"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style="border-bottom: 1px solid #eaecf0; width: 100%"
        class="SPC-MT-400"
      ></div>
    </div>

    <!-- dialog for add and update education -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <div>
          <app-input-field
            class="mb-2"
            :label="$t('education.institution')"
            :placeholder="$t('education.enterInstitution')"
            v-model="institution"
            @input="hideError"
            id="institution"
            data-cy="education_institution"
          ></app-input-field>

          <app-input-field
            class="mt-2"
            :label="$t('education.certificate')"
            type="text"
            :placeholder="$t('education.certificateTitle')"
            v-model="certificate"
            :hasError="hasCerficateError"
            :errorMessage="errorCerficateMessage"
            @input="hideError"
            id="course"
            data-cy="education_course"
          ></app-input-field>
        </div>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('education.cancel')"
              @click-event="hideDialog()"
              id="education_cancel"
              data-cy="education_cancel"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :buttonLabel="
                isEdit
                  ? $t('education.updateEducation')
                  : $t('education.addEducation')
              "
              :loading="loading"
              @click-event="isEdit ? updateItem() : addToEducation()"
              :disabled="institution === '' || certificate === ''"
              data-cy="button_Education"
              id="button_Education"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <!-- dialog for delete education -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('education.noKeepIt')"
              @click-event="hideDeleteDialog()"
              id="btn_keepit"
              data-cy="btn_keepit"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="confirmDeleteAction()"
              id="btn_confirmDelete"
              data-cy="btn_confirmDelete"
            >
              {{ $t("education.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
      id="alert"
      data-cy="response_alert"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { mapState } from "vuex";

import DropDown from "@/components/shared/DropDown.vue";
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: "Education",
  components: {
    DropDown,
  },
  props: {
    isEditProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      errorCerficateMessage: "",
      errorInstitutionMessage: "",
      hasInstitutionError: false,
      loading: false,
      hasCerficateError: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      header: ["Institution", "Certificate Title"],
      data: [],
      values: [],
      cancelLabel: "Cancel",
      buttonLabel: "Add Education",
      title: " ",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      placeholder: "",
      items: null,
      icon: "custom:hazardIcon",
      options: [""],
      institution: "",
      certificate: "",
      start_date: "",
      end_date: "",
    };
  },
  watch: {
    isEditProp(newVal) {
      this.isEdit = newVal;
    },
  },
  mounted() {
    this.getAllQualifications();
  },
  computed: {
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },

    hrConfigLang() {
      return this.translation.locale;
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    // isEditButton() {
    //   return this.currentPath === "/profile"
    //     ? this.$store.getters["hrResources/isEditButton"]
    //     : this.$store.getters["hrResources/isEditButton"] &&
    //         this.canEditPersonalModule?.includes("Qualification")|| this.canEditPersonalModule?.includes("Foreign Staff Qualification");
    // },
    isEditButton() {
      return this.currentPath === "/profile"
        ? this.$store.getters["hrResources/isEditButton"]
        : this.$store.getters["hrResources/isEditButton"] 
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isEmployeeUserKeyFromHome() {
      return this.$store.getters["hrResources/isEmployeeUserKeyFromHome"];
    },
    // canEdit() {
    //   return this.currentPath === "/edit-employee" || this.currentPath === "/edit-foreign-employee"
    //     ? this.canEditPersonalModule?.includes("Qualification") || this.canEditPersonalModule?.includes("Foreign Staff Qualification")
    //     : true;
    // },
    canEdit() {
      return this.canEditPersonalModule?.includes("Qualification") || this.canEditPersonalModule?.includes("Foreign Staff Qualification")
        
    },
    // showAndHideElement() {
    //   return (
    //     (this.currentPath === "/edit-employee" ||
    //       this.currentPath === "/edit-expert" ||
    //       this.currentPath === "/edit-foreign-employee" ||
    //       this.currentPath === "/profile") &&
    //     this.isEditEmployee &&
    //     this.isEditButton
    //   );
    // },
    showAndHideElement() {
      return (
        (this.currentPath === "/edit-employee" ||
          this.currentPath === "/edit-expert" ||
          this.currentPath === "/edit-foreign-employee" ||
          this.currentPath === "/profile") &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    ...mapState(["translation"]),
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideError() {
      this.hasCerficateError = false;
      this.errorCerficateMessage = "";
      this.hasInstitutionError = false;
      this.errorInstitutionMessage = "";
    },
    showDialog({ data, id }) {
      if (data === "add") {
        this.subTitle = "";
        this.title = "education.addEducation";
        this.isEdit = false;
        this.buttonLabel = "education.addEducation";
        this.institution = "";
        this.certificate = "";
        this.$refs.dialog.showDialog = true;
      } else if (data === "edit") {
        this.isEdit = true;
        this.buttonLabel = "education.update";
        this.label = "education.editTitle";
        const selectedItem = this.values.find((item) => item.id === id);
        if (selectedItem) {
          this.institution = selectedItem.institution;
          this.certificate = selectedItem.certificateTitle;
          this.title = "education.updateEducation";
          this.updateId = id; // Store the ID of the item to be updated
          this.$refs.dialog.showDialog = true;
        }
      }
    },
    showDeleteDialog({ data, id }) {
      if (data === "delete") {
        this.isDelete = true;
        this.title = "education.deleteEducation";
        this.subTitle = "education.areYouSure";
        this.confirmDelete = id; // Store the ID of the item to be deleted
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    confirmDeleteAction() {
      this.deleteItem(this.confirmDelete);
    },

    editItem() {
      // Perform the edit action
      const updatedItem = {
        id: this.editId,
        institute: this.institution,
        certificate_title: this.certificate,
      };

      // Call your editItem method passing the updatedItem object
      this.editItem(updatedItem);

      // Reset the edit state
      this.isEdit = false;
      this.editId = null;
      this.institution = "";
      this.$refs.dialog.showDialog = false;
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.addTitle = "";
        this.isEdit = false;
        this.isAdd = false;
        this.isDelete = false;
        this.subTitle = "";
        this.buttonLabel = "education.addEducation";
        this.label = "education.addTitle";
        this.cancelLabel = "education.cancel";
      }, 600);
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      this.isDelete = false;
      this.confirmDelete = null;
    },
    addToEducation() {
      this.loading = true;
      if (!this.institution) {
        this.hasInstitutionError = true;
        this.errorInstitutionMessage = "education.thisFieldIs";
        this.loading = false;
        return;
      }
      if (!this.certificate) {
        this.hasCerficateError = true;
        this.errorCerficateMessage = "education.thisFieldIs";
        this.loading = false;
        return;
      }
      const payload = {
        user: this.isUserKey,
        institute: this.institution,
        certificate_title: this.certificate,
      };
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/education/' : '/hr/employee/qualifications/education/'
      Api()
        .post(endpoint, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.getAllQualifications();
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllQualifications() {
      let newUserKey = this.isUserKey;
      if (this.isHome) {
        newUserKey = this.isEmployeeUserKeyFromHome;
      } else {
        newUserKey;
      }
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/user-education/' : '/hr/employee/qualifications/user-education/'
      Api()
        .get(`${endpoint}${newUserKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.headers = response.data.data.headers;
            const data = response.data.data;
            this.$refs.dialog.showDialog = false;
            this.values = data.map((obj) => {
              return {
                id: obj.id,
                institution: obj.institute,
                certificateTitle: obj.certificate_title,
              };
            });

            this.$refs.dialog.showDialog = false;
          }
        });
      // .catch((error) => {
      //   if (error) {
      //     this.alertType = "error";
      //     this.alertTitle = error.response.data.detail;
      //     this.alertState = !this.alertState;
      //   }
      // });
    },
    deleteItem(id) {
      this.selectedItemId = id;
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/education/' : '/hr/employee/qualifications/education/'
      Api()
        .delete(`${endpoint}${this.selectedItemId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllQualifications();
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.isDeleteDialog.showDialog = false;
            this.isDelete = false;
            this.confirmDelete = null;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.isDeleteDialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateItem(id) {
      this.loading = true;
      this.selectedItemId = id;
      const payload = {
        institute: this.institution,
        certificate_title: this.certificate,
      };
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/education/' : '/hr/employee/qualifications/education/'

      Api()
        .patch(
          `${endpoint}${this.updateId}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllQualifications();
            this.loading = false;
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.dialog.showDialog = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
  },
});
</script>

<style scoped>
label {
  color: #344054;
}

.header {
  padding-top: 10px;
}
.desc {
  width: 300px;
}
</style>
