<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="850px" persistent>
      <v-card class="!rounded-lg">
        <div class="px-6 mt-5 text-center">
          <p class="!text-[#101828] !text-[1.125rem] !font-inter !font-medium">
            {{ $t(`currencyExchange.rateMonth`) }}
            {{month}}
            {{ clientRateForm.year }}
          </p>
        </div>
        <div class="px-4">
<ExchangeRateModalTable></ExchangeRateModalTable>
</div>
<v-card-actions class="ml-auto">
    
  <v-col cols="12" class="py-5">
    <app-primary-button
      :buttonLabel="$t('currencyExchange.close')"
      @click-event="hideDialog"
    >
    </app-primary-button>
  </v-col>

</v-card-actions>
      </v-card>
      
    </v-dialog>
  </v-row>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import { getTransString, restrictCharactersByRegex } from "@/services/helpers";
import { updateClientRate } from "@/services/endpoints";
import ExchangeRateModalTable from "../currency-exchange/ExchangeRateModalTable.vue";
import Api from "@/services/api";
import {useI18n} from 'vue-i18n';
export default defineComponent({
  components: { ExchangeRateModalTable},
  name: "AddEditClientRate",
  data() {
    return {
      isEdit: false,
      alertType: "success",
      alertState: false,
      title: "",
      subTitle: "",
      alertTitle: "",
      dialog: false,
      month: "",
      allCurrencyRates: "",
      floatingNumber: /^(?![0-9.-]+$).*$/g,
      clientRateForm: {
        year: "",
        month: "",
        pm_local: null,
        client_rates: [],
      },
    };
  },
  setup() {
  const { t } = useI18n();
  return {
    t
  };
},
  computed: {
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    // singleClientRate() {
    //   return this.$store.getters["pmLocalModules/singleClientRate"];
    // },
    allCurrencies() {
      return this.$store.getters["pmLocalModules/allCurrencies"];
    },
  },
  methods: {
    monthName(month) {
  const translationKey = `calender.months.${month}`;
  return this.$t(translationKey);
},

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    transText(text) {
    return this.t(getTransString(text));
  },

    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      this.dialog = false;
      this.clientRateForm = {};
      this.allCurrencyRates = "";
    },

    filterInput(event) {
      restrictCharactersByRegex(event, this.floatingNumber);
      // Define the regular expression for valid input
    },
    addCurrency() {
      // this.clientRateForm.client_rates = this.allCurrencyRates;
      const payload = {
        year: this.allCurrencyRates.year,
        month: this.allCurrencyRates.month,
        rate_data: this.allCurrencyRates.rate_data?.map((data) => {
          return {
            from_currency: data.from_currency_id,
            to_currency: data.to_currency_id,
            rate: data.rate,
          };
        }),
      };
      Api()
        .post(`${updateClientRate}${this.pmLocalID}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$store.dispatch("pmLocalModules/getAllClientRate");
            this.hideDialog();
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error.response?.data?.detail;
            this.hideDialog();
          }
        });
    },
  },
});
</script>
<style scoped>
input:focus {
  background: #ffffff;
  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}
input {
  align-items: center;
  padding: 10px 14px;
  outline-width: 0;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  gap: 8px;
  border-radius: 8px;
  color: #101828;
}
</style>
