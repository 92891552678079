<template>
  <advance-payment-list></advance-payment-list>
</template>

<script>
import {defineComponent} from 'vue';
import AdvancePaymentList from '@/components/dashboard/profile/advance-payments/AdvancePaymentList.vue';

export default defineComponent({
  name: 'AdvancePayments',
  components: {AdvancePaymentList},
  data() {
    return {};
  },
  props: {
    tabId: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
  },
})
</script>
