<template>
  <div class="flex">
    <div class="toggle">{{ label }}</div>
    <div class="flex single_radio">
      <h3 class="mr-4 font-medium !text-[#344054]">
        {{ $t("projects.no") }}
      </h3>
      <label
        :for="id"
        :class=" ['inline-flex relative items-center ', {'!cursor-pointer': !disabled}]"
      >
        <input
          @change="toggledButton($event)"
          type="checkbox"
          :id="id"
          class="sr-only peer !cursor-pointer"
          :checked="isChecked"
          :disabled="disabled"
        />
        <div
          class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:bg-white after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#AE6A65]"
        ></div>
      </label>
      <h3 class="ml-4 font-medium !text-[#344054]">
        {{ $t("projects.yes") }}
      </h3>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppToggleButton",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isChecked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    toggledButton(event) {
      const value = event.target.checked;
      this.isChecked = value;
    },
  },
});
</script>

<style scoped>
.toggle {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
input:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
</style>
