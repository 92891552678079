<template>
    <div>
      <v-row>
        <v-col cols="12" class="pl-6 mt-n1">
          <form>
            <!-- Row 1 -->
            <div class="flex justify-start gap-12 mb-7">
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  id="1"
                  :label="$t(getTranslatedStringKey('Account Holder'))"
                  :placeholder="
                    $t(getTranslatedStringKey('Enter account holder'))
                  "
                  v-model="bankDetails.account_owner"

                  :disabled="shouldDisableInput"
                ></app-input-field>
              </div>
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="2"
                  :label="$t(getTranslatedStringKey('Account Number'))"
                  :placeholder="
                    $t(getTranslatedStringKey('Enter account number'))
                  "
                  v-model="bankDetails.account_number"
                ></app-input-field>
              </div>
            </div>
            <!-- Row 2 -->
            <div class="flex justify-start gap-12 mb-7">
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="2"
                  :label="$t(getTranslatedStringKey('Bank Name'))"
                  :placeholder="$t(getTranslatedStringKey('Enter bank name'))"
                  v-model="bankDetails.bank_name"
                ></app-input-field>
              </div>
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="4"
                  v-model="bankDetails.city_of_bank"
                  :label="$t(getTranslatedStringKey('City of Bank'))"

                  :placeholder="
                    $t(getTranslatedStringKey('Enter the city of the bank'))
                  "
                ></app-input-field>
              </div>
            </div>
            <!-- Row 3 -->
            <div class="flex justify-start gap-12 mb-7">
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="5"
                  :label="$t(getTranslatedStringKey('Sort Code'))"
                  :placeholder="$t(getTranslatedStringKey('Enter sort number'))"
                  validation="digitOnly"
                  v-model="bankDetails.sort_code"
                ></app-input-field>
              </div>
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="6"
                  :label="$t(getTranslatedStringKey('Swift'))"
                  :placeholder="$t(getTranslatedStringKey('Enter swift code'))"
                  validation="digitsAndLettersOnly"
                  v-model="bankDetails.swift_code"
                ></app-input-field>
              </div>
            </div>
            <!-- Row 4 -->
            <div class="flex justify-start gap-12 mb-7">
              <div class="w-[440px]">
                <app-input-field
                  type="text"
                  :disabled="shouldDisableInput"
                  id="7"
                  :label="$t(getTranslatedStringKey('IBAN'))"
                  :placeholder="$t(getTranslatedStringKey('Enter IBAN number'))"
                  validation="digitsAndLettersOnly"
                  v-model="bankDetails.iban"
                ></app-input-field>
              </div>
              <div class="w-[440px]">
                <app-single-select
              :label="$t('hospitalityPackage.currency')"
              :labelPlaceholder="$t('hospitalityPackage.selectCurrency')"
              :showCheckbox="false"
              :showSearch="true"
              :searchPlaceholder="$t('hospitalityPackage.searchCurrency')"
              :options="currencyOptions"
              v-model="bankDetails.currency"
              :disabled="shouldDisableInput "

            >
            </app-single-select>
              </div>
            </div>
            <div class="w-[920px]">
              <div
                v-if="
                  !isEditEmployee ||
                  (currentPath !== '/edit-employee' &&
                    currentPath !== '/edit-expert' &&
                    currentPath !== '/profile')
                "
                class="flex justify-end gap-3 mb-7"
              >
                <div></div>
                <div class="flex gap-3">
                  <div>
                    <app-secondary-button
                      @click="previous"
                      :buttonLabel="$t('contact.previous')"
                      id="previous"
                      data-cy="contact_previous"
                    ></app-secondary-button>
                  </div>
                  <div>
                    <app-primary-button
                      :buttonLabel="$t('employment.finish')"
                      @click-event="handleFinish"
                      v-show="disableSaveButton()"
                      id="next"
                      data-cy="contact_next"
                    >
                    </app-primary-button>
                  </div>
                  <div>
                    <app-primary-button
                      :buttonLabel="$t('contact.saveAndContinue')"
                      @click-event="savePersonalToState('continue')"
                      v-show="!disableSaveButton()"
                      id="saveAndContinue"
                      data-cy="contact_saveAndContinue"
                    >
                    </app-primary-button>
                  </div>
                </div>
              </div>
              <div v-else class="flex justify-end gap-7">
                <div v-show="isEditButton">
                  <app-secondary-button
                    :buttonLabel="$t('personal.cancel')"
                    @click="previous"
                    data-cy="contact_cancel"
                    id="cancel"
                  ></app-secondary-button>
                </div>
                <app-primary-button
                  v-show="isEditButton"
                  :buttonLabel="$t('contact.save')"
                  @click-event="savePersonalToState"
                  id="save"
                  data-cy="contact_save"
                >
                </app-primary-button>
              </div>
            </div>
          </form>
        </v-col>
      </v-row>

      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </div>
  </template>

  <script>
  import { defineComponent } from "vue";
  import currencies from "@/services/currencies_api";
  import { getTransString } from "@/services/helpers";
  import { addEmployeeInfo } from "@/services/endpoints";
  import Api from "@/services/api";
  import { isEqual } from "lodash";
  export default defineComponent({
    name: "BankDetails",
    components: {},
    props: {
      errors: {
        type: Boolean,
        default: true,
      },
      handleButtonRoute: {
        type: Function,
      },
      tabId: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        alertType: "success",
        alertState: false,
        alertTitle: "",
        isEdit: false,
        loadUserKey: null,
        originalFormData: {
          account_owner: "",
          bank_name: "",
          city_of_bank: "",
          sort_code: "",
          swift_code: "",
          iban: "",
          account_number: "",
          currency: "",
        },
        bankDetails: {
          account_owner: "",
          bank_name: "",
          city_of_bank: "",
          sort_code: "",
          swift_code: "",
          iban: "",
          account_number: "",
          currency: "",
        },
        allUserRoles: [],
        page: 1,
        roles: [],
        showmodal: true,
      };
    },
    computed: {
      canEditPersonalModule() {
        return this.$store.state.auth.canEditModuleSections;
      },
      currentPath() {
        return this.$route.path;
      },
      isEditEmployee() {
        return this.$store.getters["hrResources/isEditEmployee"];
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      userKey() {
        return this.$store.getters["hrResources/isUserKey"];
      },
      isPersonalData() {
        return this.$store.getters["hrResources/isPersonalData"];
      },
      isCurrentTab() {
        return this.$store.getters["hrResources/isCurrentTab"];
      },
      shouldDisableInput() {
        return (
          (this.currentPath === "/edit-employee" ||
            this.currentPath === "/edit-expert"|| this.currentPath === "/profile") &&
            this.isEditEmployee && !this.isEditButton
        );
      },
      currencyOptions() {
        return currencies?.map((currencies) => {
          return {
            text: `${currencies?.name} (${currencies?.symbol})`,
            value: currencies?.code,
          };
        });
      },
    },
    watch: {
      isPersonalData(newValue) {
        this.populateFieldsWithData(newValue, this.bankDetails);
        if (this.firstRun) {
          this.populateFieldsWithData(newValue, this.originalFormData);
          this.firstRun = false;
        }
      },
      bankDetails: {
        deep: true,
        handler(value) {
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            BankDetails: !isEqual(value, this.originalFormData),
          });
        },
      },
    },
    methods: {
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
      handleNextClick() {
        this.$emit("next-clicked");
      },

      handleFinish() {
        this.$router.push({ name: "employees" });
        //   this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
      },
      previous() {
        const getCurrentTab = this.isCurrentTab - 1;
        this.handleButtonRoute(getCurrentTab);
      },
      disableSaveButton() {
        const personalFormObj = { ...this.bankDetails };
        const excludes = ["user_key", "isCommunication", "country_of_birth"];
        const modifiedObject = Object.keys(personalFormObj)?.reduce(
          (prev, curr) => {
            if (excludes.includes(curr)) {
              return prev;
            } else {
              prev[curr] = personalFormObj[curr];
              return prev;
            }
          },
          {}
        );
        return Object.values(modifiedObject)?.every((c) => c === "" || c === null);
      },
      savePersonalToState(data) {


        const updatedPayload = {};
        for (const field in this.isPersonalData) {
          if (this.isPersonalData[field] !== this.bankDetails[field]) {
            updatedPayload[field] = this.bankDetails[field];
          }
        }

        updatedPayload.user_key = this.userKey;

        Api()
          .post(`${addEmployeeInfo}`, updatedPayload)
          .then((response) => {
            if (response.data.status === "success") {
              this.$store.commit(
                "hrResources/SET_USER_KEY_TO_STATE",
                response.data.data.user_key
              );

              this.alertType = "success";
              this.alertTitle = response.data.detail;
              this.alertState = !this.alertState;

              this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
                Contact: false,
              });

              if (this.currentPath === "/add-employee" ) {
                if (data === "continue") {

                  this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
                }
              }
              if (this.currentPath === "/add-expert" ) {
                if (data === "continue") {

                  this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 4);
                }

              }

            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      },
      populateFieldsWithData(data, saveTo) {
        saveTo.account_owner = data?.account_owner;
        saveTo.account_number = data?.account_number;
        saveTo.bank_name = data?.bank_name;
        saveTo.city_of_bank = data?.city_of_bank;
        saveTo.sort_code = data?.sort_code;
        saveTo.swift_code = data?.swift_code;
        saveTo.iban = data?.iban;
        saveTo.user_key = this.$store.state.hrResources?.userKey;

      },
    },
    created() {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
      this.populateFieldsWithData(this.isPersonalData, this.bankDetails)
      this.populateFieldsWithData(this.isPersonalData, this.originalFormData)
      //   if(this.currentPath === '/edit-employee' || this.currentPath === '/add-employee'){
      //   }
    },
  });
  </script>

  <style scoped></style>
