<template>
  <div>
    <div
      v-if="apploader"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else>
      <div class="p-4 mx-1 my-10 border rounded-lg">
        <div class="text-lg font-bold">{{ $t("cheques.bankBalance") }}</div>
        <div class="flex-container">
          <div
            v-for="data in chequeData"
            :key="data.currency"
            class="flex-item"
          >
            <div class="mt-4">
              <div class="currency">{{ data.currency__currency }}</div>
              <div class="amount">
                {{
                  appLang === "de"
                    ? germanCurrency(data.bank_balance)
                    : amountFormatting(data.bank_balance)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-table
        :headers="headers"
        :items="isAllCheques"
        pageName="Cheques"
        message="Cheques"
        :showCheck="false"
        @delete-event="showDialog({ $event, data: 'delete' })"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @pay-in-event="payInCheque"
        @paid-in-by="viewPaidInBy"
        :isCheques="true"
      >
        <template #title>
          <h3 class="H500">{{ $t("cheques.allRecordCheques") }}</h3>
        </template>
        <template #actions>
          <app-primary-button
            @click-event="showDialog({ data: 'add' })"
            :buttonLabel="$t('Add Cheque'.getTranslationKey())"
            v-if="!shouldDisableInput"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
  </div>

  <!-- Pay in modal -->
  <app-dialog
    ref="dialogState"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #content>
      <app-date-field
        type="date"
        :label="$t('cheques.documentDate')"
        v-model="documentDate"
        :max="maxStartDate(payInDate)"
        class="hidden"
      ></app-date-field>
      <app-date-field
        type="date"
        :label="$t('cheques.paidInDate')"
        v-model="payInDate"
        :min="minEndDate(documentDate)"
      ></app-date-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hidePayIn()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#8b2923] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="confirmPayInCheque()"
          >
            {{ $t("cheques.yesIn") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <!-- Paid in By Modal -->

  <app-dialog
    ref="dialogStates"
    :title="title"
    :subtitle="''"
    height="302"
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('cheques.lockedBy')"
        v-model="paidByName"
        :disabled="true"
      ></app-input-field>
      <app-date-field
        type="date"
        :label="$t('cheques.paidInDate')"
        v-model="paidByDate"
        :disabled="true"
      ></app-date-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="12" class="">
          <app-primary-button
            :buttonLabel="$t('leaves.close')"
            @click-event="hidePaidInBy()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <!-- Delete modal -->
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#8b2923] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteAll ? deleteAllCheques() : deleteCheque()"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import {
  currencyFormatter,
  formatAmount,
  getTransString,
  maximumStartDate,
  minimumEndDate,
} from "@/services/helpers";
import Api from "@/services/api";
import {
  getAllCheques,
  deleteProjectCheque,
  payInCheque,
  deleteAllProjectCheques,
  getSingleCheque,
} from "@/services/endpoints";
import moment from "moment";
import Paginator from "@/components/shared/PaginationComponent.vue";

export default defineComponent({
  name: "Cheques",
  components: {
    Paginator,
  },
  data() {
    return {
      currentPages: 1,
      apploader: true,
      chequeData: [],
      headers: [
        "Title",
        "Doc. Date",
        "Pay Date",
        "Supplier",
        "Currency",
        "Amt Excl. Tax",
        "Amt Incl. Tax",
        "Budget Position",
        "Files",
        "Status",
      ],
      deleteId: null,
      title: "",
      subTitle: "",
      isEdit: false,
      payInId: null,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      deleteAll: false,
      documentDate: "",
      payInDate: "",
      paidByDate: "",
      paidByName: "",
    };
  },
  computed: {
    isAllCheques() {
      return this.$store.getters["chequeModules/isAllCheques"];
    },
    totalPages() {
      return this.$store.getters["chequeModules/isTotalPages"];
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    currentPage() {
      return this.$store.getters["contractModules/isCurrentPage"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    hidePayIn() {
      this.$refs.dialogState.showDialog = false;
    },
    hidePaidInBy() {
      this.$refs.dialogStates.showDialog = false;
    },
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    showDialog(data) {
      if (data.data === "add") {
        this.$store.commit("chequeModules/SET_ADD_CHEQUE_TO_STATE", true);
        this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
      } else if (data.data === "delete") {
        this.deleteId = data.$event.id;
        this.$refs.dialog.showDialog = true;
        this.title = "cheques.deleteCheq";
        this.subTitle = "cheques.confirmDeleteCheq";
      } else if (data.data === "delete-all") {
        this.deleteAll = true;
        this.$refs.dialog.showDialog = true;
        this.title = "cheques.deleteAllCheqs";
        this.subTitle = "cheques.confirmAllDeleteCheq";
      } else if (data.data === "edit") {
        this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
        this.$store.commit("chequeModules/SET_ADD_CHEQUE_TO_STATE", true);
        this.$store.commit("chequeModules/SET_EDIT_CHEQUE_TO_STATE", true);
        this.$store.commit(
          "chequeModules/SET_CHEQUE_EDIT_ID_TO_STATE",
          data.$event.id
        );
      }
    },
    payInCheque(data) {
      if (data.status === "Pay in") {
        this.payInId = data.id;
        this.$refs.dialogState.showDialog = true;
        this.title = "cheques.yesInTitle";
        this.subTitle = "cheques.confirmYes";
        this.payInDate = moment(data["pay date"], "D.M.YYYY").format(
          "YYYY-MM-DD"
        );
        this.documentDate = moment(data["doc. date"], "D.M.YYYY").format(
          "YYYY-MM-DD"
        );
      }
    },
    viewPaidInBy(data) {
      if (data.status === "Paid") {
        this.payInId = data.id;
        this.getSingleCheque();
        this.$refs.dialogStates.showDialog = true;
        this.title = "cheques.chequeDetails";
      }
    },
    getSingleCheque() {
      Api()
        .get(`${getSingleCheque}${this.payInId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.paidByName = response.data.data.paid_in_by__first_name
              ? `${response.data.data.paid_in_by__first_name} ${response.data.data.paid_in_by__last_name}`
              : "";
            this.paidByDate = response.data.data.payment_date;
          }
        })
        .catch();
    },
    getAllProjectCheques(page) {
      this.apploader = true;
      Api()
        .get(`${getAllCheques}${this.isForeignEstablishmentId}/${page}`)
        .then((response) => {
          if (response.data.cheque_data.status === "success") {
            this.apploader = false;
            this.$store.commit('chequeModules/SET_CHEQUE_START_DATE_TO_STATE', response?.data?.project_details?.project_start_date)
            this.$store.commit('chequeModules/SET_CHEQUE_END_DATE_TO_STATE', response?.data?.project_details?.project_end_date)
            const responseData = response.data.cheque_data.data;
            this.chequeData = response.data.bank_balance;
            this.$store.commit('chequeModules/SET_CHEQUE_BALANCE_TO_STATE', this.chequeData )
            const transformedData = responseData.map((data) => {
              const formattedDocumentDate = moment(data.document_date).format(
                "D.M.YYYY"
              );
              const formattedPaymentDate = moment(data.payment_date).format(
                "D.M.YYYY"
              );
              return {
                id: data.id,
                title: data.title,
                "doc. date": `${formattedDocumentDate}`,
                "pay date": `${formattedPaymentDate}`,
                supplier: data.supplier,
                currency: data.currency__currency,
                "amt incl. tax":
                  this.appLang === "de"
                    ? this.germanCurrency(data.total_amount_taxed)
                    : this.amountFormatting(data.total_amount_taxed),
                "amt excl. tax":  this.appLang === "de"
                    ? this.germanCurrency(data.total_amount_not_taxed)
                    : this.amountFormatting(data.total_amount_not_taxed),
                "budget position": data.budget__budget_item__position,
                files:
                  data.file_title.length > 10
                    ? data.file_title.substring(0, 10) + "..."
                    : data.file_title,
                status: data.is_paid_in === true ? "Paid" : "Pay in",
              };
            });
            this.$store.commit(
              "chequeModules/SET_CHEQUES_TO_STATE",
              transformedData
            );
            this.$store.commit(
              "chequeModules/SET_TOTAL_PAGES_TO_STATE",
              response.data.cheque_data.total_data
            );
          }
        })
        .catch();
    },
    confirmPayInCheque() {
      const payload = {
        paid_in_date: this.payInDate,
      };
      Api()
        .put(`${payInCheque}${this.payInId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllProjectCheques(this.currentPage);
          }
        })
        .catch((error) => {
          this.$refs.dialogState.showDialog = false;
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error?.response?.data?.detail;
        });
    },
    deleteCheque() {
      Api()
        .delete(`${deleteProjectCheque}${this.deleteId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAllProjectCheques(this.currentPage);
          }
        })
        .catch();
    },
    deleteAllCheques() {
      Api()
        .delete(`${deleteAllProjectCheques}${this.isForeignEstablishmentId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.details;
            this.getAllProjectCheques(this.currentPage);
          }
        })
        .catch();
    },
    loadMore() {
      const page = this.currentPages;
      this.getAllProjectCheques(page);
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 2);
    this.$store.commit("chequeModules/SET_ADD_CHEQUE_TO_STATE", false);
    this.$store.commit("chequeModules/SET_EDIT_CHEQUE_TO_STATE", false);
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    
  },
  mounted() {
    this.getAllProjectCheques(this.currentPages);
    this.$store.commit("pmLocalApproveFund/SET_IS_APPROVAL_OVERVIEW", true);
  },
});
</script>
<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.flex-item {
  flex: 1;
  padding: 10px;
  padding-left: 0px;
  min-width: 200px;
}

.amount {
  font-weight: bold;
}

.currency {
  font-size: 16px;
  color: #667085;
}
</style>
