<template>
  <v-sheet max-width="1125" class="mt-5 bg-transparent mb-7">
    <!-- project forecast -->
    <form>
      <fieldset :disabled="shouldDisableInput ? 'disabled' : null">
        <v-row>
          <v-col cols="10" class="mt-5 !divide-y">
            <p class="pb-3 font-semibold text-[1.125rem]">
              {{ $t("projects.forecast") }}
            </p>
            <div></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="justify-between pr-4 ma-0 d-flex align-center">
            <p class="font-medium text-[1rem]">
              {{ $t("projects.framework") }}
            </p>
            <div class="flex single_radio">
              <h3 class="mr-4 font-medium !text-[#344054]">
                {{ $t("projects.no") }}
              </h3>
              <label
                for="framework"
                class="1 inline-flex relative items-center !cursor-pointer"
                :class="{ '!cursor-default': shouldDisableInput }"
              >
                <input
                  type="checkbox"
                  id="framework"
                  class="sr-only peer !cursor-pointer"
                  v-model="isFramework"
                />
                <div
                  class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:bg-white after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#AE6A65]"
                ></div>
              </label>
              <h3 class="ml-4 font-medium !text-[#344054]">
                {{ $t("projects.yes") }}
              </h3>
            </div>
          </v-col>
          <v-col cols="5">
            <div
              v-if="isFramework && isSelectedPhase !== 'Forecast'"
              class="d-flex"
            >
              <div class="flex items-center SPC-MT-100">
                <input
                  class="cursor-pointer"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault01"
                  v-model="selectedOption"
                  value="main"
                />
                <label
                  class="H400"
                  for="radioDefault01"
                  :class="{ '!cursor-default': shouldDisableInput }"
                >
                  {{ $t("projects.mainCostCenter") }}
                </label>
              </div>
              <div class="flex items-center SPC-MT-100 ml-9">
                <input
                  class="cursor-pointer"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault02"
                  value="sub"
                  v-model="selectedOption"
                />
                <label
                  class="H400"
                  :class="{ '!cursor-default': shouldDisableInput }"
                  for="radioDefault02"
                >
                  {{ $t("projects.subCostCenter") }}
                </label>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id="projectNumber"
              type="text"
              :placeholder="$t('projects.projectNumberPlaceholder')"
              :label="$t('projects.projectNumberReq')"
              v-model="projectsForm.project_number"
              :hasError="isFieldInvalid('project_number')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="projectTitle"
              type="text"
              :placeholder="$t('projects.projectTitlePlaceholder')"
              :label="$t('projects.projectTitle')"
              v-model="projectsForm.project_title"
              :hasError="isFieldInvalid('project_title')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id="vnLotExtensionComponentNumber"
              type="text"
              :placeholder="$t('projects.componentNumberPlaceholder')"
              :label="$t('projects.componentNumber')"
              v-model="projectsForm.vn_lot_extension_component_number"
              validation="digitOnly"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="vnLotExtensionComponentTitle"
              type="text"
              :placeholder="$t('projects.componentTitlePlaceholder')"
              :label="$t('projects.componentTitle')"
              v-model="projectsForm.vn_lot_extension_component_title"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-multi-select
              id="country"
              :options="getCountryByName"
              :showSearch="true"
              :showCheckbox="true"
              :searchPlaceholder="$t('projects.searchCountry')"
              :labelPlaceholder="$t('projects.countryPlaceholder')"
              :label="$t('projects.country')"
              :optionItems="selectedCountries"
              :disabled="shouldDisableInput"
              @selected-options="handleCountryData"
            ></app-multi-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="region"
              type="text"
              :placeholder="$t('projects.regionPlaceholder')"
              :label="$t('projects.region')"
              v-model="projectsForm.region"
              validation="allowSpaces"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-single-select
              id="businessArea"
              ref="businessArea"
              :showSearch="true"
              :searchPlaceholder="$t('projects.searchBusinessArea')"
              :disabled="shouldDisableInput"
              :showCheckbox="false"
              :options="businessAreaOptions"
              :labelPlaceholder="$t('projects.businessAreaPlaceholder')"
              :label="$t('projects.businessArea')"
              v-model="projectsForm.business_area_id"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="projectSubmittedTo"
              :placeholder="$t('projects.submittedToPlaceholder')"
              :label="$t('projects.proposalSubmitted')"
              v-model="projectsForm.submitted_to"
              validation="allowSpaces"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-single-select
              id="clients"
              :showCheckbox="false"
              :searchPlaceholder="$t('projects.searchClients')"
              :labelPlaceholder="$t('projects.clientPlaceholder')"
              :label="$t('projects.client')"
              :options="clientOptions"
              :showAddMore="true"
              :addMoreText="$t('projects.addClient')"
              :disabled="shouldDisableInput"
              @add-More="showDialogClient"
              v-model="projectsForm.client_id"
              :hasError="isFieldInvalid('client_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id=""
              :placeholder="$t('projects.beneficiaryPlaceholder')"
              :label="$t('projects.beneficiary')"
              v-model="projectsForm.beneficiary"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id=""
              :placeholder="$t('projects.cMaxPlaceholder')"
              :label="$t('projects.cMax')"
              v-model="projectsForm.ta_volume"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              id="responsiblePerson"
              :disabled="shouldDisableInput"
              :showCheckbox="false"
              :showSearch="true"
              :searchPlaceholder="$t('projects.searchResponsiblePerson')"
              :labelPlaceholder="$t('projects.responsiblePersonPlaceholder')"
              :label="$t('projects.responsiblePerson')"
              :options="responsiblePersonOptions"
              v-model="projectsForm.responsible_person_id"
              :hasError="isFieldInvalid('responsible_person_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-single-select
              id="coResponsiblePerson"
              :disabled="shouldDisableInput"
              :showCheckbox="false"
              :showSearch="true"
              :searchPlaceholder="$t('projects.searchCoResponsiblePerson')"
              :options="responsiblePersonOptions"
              :labelPlaceholder="$t('projects.responsiblePersonPlaceholder')"
              :label="$t('projects.coResponsiblePerson')"
              v-model="projectsForm.co_responsible_person_id"
            ></app-single-select>
          </v-col>
        </v-row>
      </fieldset>
    </form>
    <!-- dialog for adding and updating task areas -->
    <app-dialog
      ref="clientDialog"
      :title="$t('projects.addPartners')"
      subtitle=""
      height="302"
      width="408"
      :showButtons="false"
    >
      <template #content>
        <general-client-tab
          @hide-dialog="hideClientDialog"
        ></general-client-tab>
      </template>
    </app-dialog>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { getSelectOptionList, getTransString } from '@/services/helpers'
import countries from "@/services/country_api";
import GeneralClientTab from './clients/GeneralModalTab.vue';
import AppSingleSelect from '@/components/shared/AppSingleSelect.vue';
// import CreateEditCostCenters from '../hr-settings/CreateEditCostCenters.vue';
import { isEqual } from 'lodash';

export default defineComponent({
    name: 'Project Forecast',
    props: {
      projectData: {
        type: Object
      }
    },
    components:{
      GeneralClientTab,
      AppSingleSelect
      // CreateEditCostCenters
    },
    data(){
        return{

          firstRun: true,
          selectOptionFirstRun: true,
          selectedOption: 'main',
          originalSelectedOption: 'main',
            isFramework: true,
            responsiblePerson: null,
            client: null,
            projectTitle: null,
            country: [],
            selectedCountries: [],
            hasError: false,
            errorMessage: '',
            projectsForm:{
              project_number: "",
              project_title: "",
              countries: [],
              region: "",
              is_framework: !this.isFramework,
              is_main_cost_centre: true,
              is_sub_cost_centre: false,
              business_area_id: null,
              submitted_to: '',
              client_id: "",
              beneficiary: '',
              ta_volume: "",
              vn_lot_extension_component_number: '',
              vn_lot_extension_component_title: '',
              responsible_person_id: "",
              co_responsible_person_id: null,
            },
            originalProjectsForm:{
              project_number: "",
              project_title: "",
              countries: [],
              region: "",
              is_framework: !this.isFramework,
              is_main_cost_centre: true,
              is_sub_cost_centre: false,
              business_area_id: null,
              submitted_to: '',
              client_id: "",
              beneficiary: '',
              ta_volume: "",
              vn_lot_extension_component_number: '',
              vn_lot_extension_component_title: '',
              responsible_person_id: "",
              co_responsible_person_id: null,
            },
            requiredFields: ''
        }
    },
    watch: {
      projectData (data) {

        // this.populateProjectForm(this.projectsForm, data);
        // if(this.firstRun) {
        //   this.populateProjectForm(this.originalProjectsForm, data);
        //   this.firstRun = false
        // }

        this.selectedClient =  {text: data?.client__company_name, value: data?.client}
        this.selectedCountries = data.countries?.map((country)=>({
          text: country,
          value: country
        }))
        this.selectedSubCostCenter= this.costCentersOptions?.find((center_id)=>(center_id?.value === data?.sub_cost_centre))

        if(data?.is_main_cost_centre){
          this.selectedOption = 'main'
        }
        if(data?.is_sub_cost_centre){
          this.selectedOption = 'sub'
        }

        this.originalProjectsForm.business_area_id = data?.business_area
        this.originalProjectsForm.client_id = data?.client

        this.originalProjectsForm.responsible_person_id = data.responsible_person
        this.originalProjectsForm.co_responsible_person_id = data.co_responsible_person
      },
      selectedOption(val){
        if(val=== 'sub'){
          this.projectsForm.is_sub_cost_centre = true
          this.projectsForm.is_main_cost_centre = false
        
          this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", true);
        } else{
          this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", false);
          this.projectsForm.is_main_cost_centre = true
          this.projectsForm.is_sub_cost_centre = false
         
        }
       
      },

      isFramework(newVal){

        if(newVal === false){
          this.selectedOption = 'main'
          this.projectsForm.is_framework = false
          this.projectsForm.is_main_cost_centre = false
          this.projectsForm.is_sub_cost_centre = false
          if(this.selectOptionFirstRun){
            this.originalProjectsForm.is_framework = false
            this.originalProjectsForm.is_main_cost_centre = false
            this.originalProjectsForm.is_sub_cost_centre = false
            this.selectOptionFirstRun = false
          }
        } else{
          this.projectsForm.is_main_cost_centre = true
          if(this.selectOptionFirstRun){
            this.originalProjectsForm.is_main_cost_centre = true
            this.selectOptionFirstRun = false
          }
        }

      },
      watchDependencies: {
        immediate: true,
        handler() {
          this.isProjectButtonDisabled();
        },
      },
      projectsForm: {
        deep: true,
        handler(value) {

          // this.updateForecateData()
          if(!this.projectsForm.is_framework && value.is_framework){
            this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", true);
          }
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "BasicInformation": !isEqual(value, this.originalProjectsForm)
        });

        },
      }
    },
   computed:{
    watchDependencies(){
      return [
        this.projectTitle,
        this.responsiblePerson,
        this.client
      ]
    },

    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },

    getCountryByName(){
      return countries.map((country)=>{
        return{
          text: country.name,
          value: country.name
        }
      })
    },
    businessAreaOptions(){
      const optionList = getSelectOptionList('hrConfigurations/isBusinessArea')
      return optionList.map((area) => {
        return {
          text: area.id,
          value: area.value
        }
      })

    },

    clientOptions(){
      const optionList = getSelectOptionList('expertModules/isAllPartners')
      return optionList.map((client) => {
        return {
          text: client.id,
          value: client.value
        }
      })
    },
    responsiblePersonOptions(){
     const optionList = getSelectOptionList('hrConfigurations/isDefaultCollaborators')
     return optionList.map((person) => {
        return {
          text: person.id,
          value: person.value
        }
      })
   },
   controllerOptions(){
      return getSelectOptionList('hrResources/isAllEmployees')
    },
    isSelectedPhase(){
        return this.$store.getters["projectModules/isSelectedPhase"]
      },
    isSelectedStatus(){
        return this.$store.getters["projectModules/isSelectedStatus"]
      },
      currentPath() {
      return this.$route.path;
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
      shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
   },
   methods:{
  // check the required fields
    isFieldInvalid(fieldName) {
      return this.requiredFields?.includes(fieldName) || false;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showDialogClient() {
      this.$store.commit('partnerModules/SET_CURRENT_TAB_TO_STATE', 0);
      this.$store.commit('partnerModules/SET_PARTNER_KEY_TO_STATE', null);
      this.$refs.clientDialog.showDialog = true
    },
    showDialog() {
      this.dialogState = true
    },
    hideClientDialog() {
      this.$refs.clientDialog.showDialog  = false
    },
    handleCountryData(data){
      this.country = data
      this.projectsForm.countries = data

      this.originalProjectsForm.countries = data
    },
    isProjectButtonDisabled(){
      this.$store.commit('projectModules/SET_DISABLED_TO_STATE',false)
    },
    handleDataEmit() {
      this.$emit('emit-forecast-data', this.projectsForm)
    },

    populateProjectForm(saveTo, data) {
      if(data?.is_main_cost_centre){
          this.selectedOption = 'main'
        }
        if(data?.is_sub_cost_centre){
          this.selectedOption = 'sub'
        }
        this.selectedCountries = data.countries?.map((country)=>({
          text: country,
          value: country
        }))
        saveTo.is_framework = data.is_framework
        saveTo.is_main_cost_centre = data.is_main_cost_centre
        saveTo.is_sub_cost_centre = data.is_sub_cost_centre
        saveTo.project_title = data.project_title
        saveTo.project_number = data.project_number
        saveTo.business_area_id = data?.business_area
        saveTo.client_id = data?.client
        saveTo.region = data.region
        saveTo.submitted_to = data.submitted_to
        saveTo.beneficiary = data.beneficiary
        saveTo.ta_volume = data.ta_volume
        saveTo.vn_lot_extension_component_number = data.vn_lot_extension_component_number
        saveTo.vn_lot_extension_component_title = data.vn_lot_extension_component_title
        saveTo.responsible_person_id = data.responsible_person
        saveTo.co_responsible_person_id = data.co_responsible_person
    },
   },
   created(){
    this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", false);
    this.populateProjectForm(this.projectsForm, this.projectData)
    this.populateProjectForm(this.originalProjectsForm, this.projectData)
    const payload= {
      configType: 'Business Areas',
      setter: 'SET_BUSINESS_AREA_TO_STATE'
    }
    this.$store.dispatch('hrConfigurations/getAllConfigurationTypes', payload)
    this.$store.dispatch('expertModules/getAllPartners')
    this.$store.dispatch('hrConfigurations/getAllDefaultCollaborators')

   },
   mounted(){
    this.populateProjectForm(this.projectsForm, this.projectData)
    this.populateProjectForm(this.originalProjectsForm, this.projectData)

   }

})
</script>
<style scoped>
label {
  color: #344054;
}
input {
  gap: 8px;
  border: none;
  outline-width: 0;
  width: 402px;
  height: 44px;
}
.input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background-color: white;
}
/* unselected radio button */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 0.5rem;
  position: relative;
}

/* middle fill color */
input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* selected radio button */
input[type="radio"]:checked::before {
  background-color: #8b2923;
  border: 2px solid #8b2923;
  width: 8px;
  height: 8px;
}
input[type="radio"]:checked {
  background-color: #f4eae9;
  border: 1px solid #ae6a65;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  outline: none;

  position: relative;
}
fieldset:disabled {
  opacity: 0.9;
}
</style>
