<template>
  <div v-if="multiple" class="">
    <span v-for="(title, index) in multipleTitles" :key="index">
      <div
        class="d-flex justify-end z-[10000000] my-3 fixed"
        :style="{ top: `${index * 80}px`, right: '75px' }"
        v-if="isState"
      >
        <div :class="alertClasses">
          <div class="flex justify-between items-center">
            <div class="px-2">
              <v-icon :icon="getIconByType" />
            </div>
            <p class="mx-1">{{$t(title.message.getTranslationKey()) }} {{title.key}}. {{$t('Available amount'.getTranslationKey()) }}: {{title.amount}}</p>
            <!-- <p >{{$t('Available amount'.getTranslationKey()) }} {{title.amount}}</p> -->
            <v-icon
              :icon="getCloseIconByType"
              class="cursor-pointer"
              @click="closeDialog"
            />
          </div>
        </div>
      </div>
    </span>
  </div>
  <div v-else>
    <div
        class="d-flex justify-end fixed top-20 right-10 z-[10000]"
        v-if="isState"
      >
        <div :class="alertClasses">
          <div class="flex justify-between items-center">
            <div class="px-2">
              <v-icon :icon="getIconByType" />
            </div>
            <p class="mx-1">{{ title }}</p>
            <v-icon
              :icon="getCloseIconByType"
              class="cursor-pointer"
              @click="closeDialog"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="js">
import { defineComponent,} from "vue";

export default defineComponent({
  name: "AppAlert",
  props: {
    title: {
      type: String,
      default: "Text",
    },
    multipleTitles: {
      type: Array,
    },
    state: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "success",
      validator: (value) => ["success", "error"].includes(value),
    },
    timeout: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isState: false,
      timer: null,
    };
  },
  computed: {
    alertClasses() {
      return {
        alert: true,
        'alert--success': this.type === "success",
        'alert--error': this.type === "error",
        'h-full': true,
      };
    },
    getIconByType() {
      return this.type === "success" ? "custom:successIcon" : "custom:errorIcon";
    },
    getCloseIconByType() {
      return this.type === "success" ? "custom:greenClose" : "custom:redClose";
    },
  },
  methods: {
    closeDialog() {
      clearTimeout(this.timer);
      this.isState = false;
      this.$emit("close-alert");
    },
    startTimer() {
      if (this.timeout > 0) {
        this.timer = setTimeout(() => {
          this.closeDialog();
        }, this.timeout);
      }
    },
  },
  watch: {
    state(newVal) {
      this.isState = newVal;
      if (newVal) {
        this.startTimer();
      } else {
        clearTimeout(this.timer);
      }
    },
  },
  mounted() {
    this.isState = this.state;
    this.startTimer();
  },
});
</script>

<style scoped>
.alert {
  width: 340px;
  /* height: 52px; */
  border-radius: 8px;
  padding: 0.5rem 1rem;
}

.alert--success {
  background: #f6fef9;
  border: 1px solid #6ce9a6;
  color: #027a48;
}

.alert--error {
  background: #ffebed;
  border: 1px solid #ff556b;
  color: #ff556b;
}
</style>
