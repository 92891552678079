<template>
    <div class="w-full mt-15">
      <div
        class="relative overflow-hidden bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg"
      >
        <div
          class="flex flex-col items-center justify-between p-4 px-6 space-y-3 tablecard md:flex-row md:space-y-0 md:space-x-4"
        >
          <div class="w-full py-2 font-medium text-l">
            <p>{{$t('currencyExchange.averageRate')}}</p>
            <div class="flex items-center justify-between"></div>
          </div>
          <div
            class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3"
          >
            <div class="bg-[#F6FEF9] rounded-xl flex px-4">
              <v-badge dot offset-y="8" size="2" color="#12B76A"></v-badge>
  
              <p class="text-[#027A48] pl-2">{{$t('currencyExchange.available')}}</p>
            </div>
            <div class="bg-[#F2F4F7] rounded-xl flex px-4">
              <v-badge dot offset-y="8" size="2" color="#667085"></v-badge>
  
              <p class="text-[#344054] pl-2">{{$t('currencyExchange.notAvailable')}}</p>
            </div>
          </div>
        </div>
        <div class="rounded-lg">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            aria-describedby=""
          >
            <thead class="bg-white">
              <tr class="bg-gray-200">
                <th class="p-4 text-base !text-[#344054] !font-medium">{{$t('currencyExchange.year')}}</th>
                <th class="pl-7 py-4 text-base !text-[#344054] !font-medium">
                 {{$t('currencyExchange.month')}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in monthRate" :key="item.year" class="">
                <td class="">{{ item.year }}</td>
                <td class="flex items-center w-full py-4 pl-0 pr-7">
                  <template v-for="month in item.months" :key="month.month_name">
                    <td
                      :class="[
                        ' pl-6 pr-5 ',
                        {
                          'text-[#12B76A] ': month.has_rate,
                          'cursor-pointer': !shouldDisableInput,
                        },
                      ]"
                      @click="
                        !shouldDisableInput
                          ? showDialog(month.month_name, month, item)
                          : ''
                      "
                    >
                      {{ formatMonth(month.month_name) || "-" }}
                    </td>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="empty_state" v-if="monthRate?.length === 0">
          <EmptyState />
          <h3 class="H500">
            {{ $t("employees.no") }} {{ pagename }} {{ $t("employees.found") }}
          </h3>
          <p class="P300">
            {{ $t("employees.youWillSee") }} {{ message }}
            {{ $t("employees.hereWhen") }}
          </p>
        </div>
      </div>
    </div>
    <add-edit-client-rate ref="addEditClientRate"></add-edit-client-rate>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import EmptyState from "@/assets/icons/EmptyState.vue";
  import AddEditClientRate from "@/components/dashboard/projects/pm-local/dialogs/ViewExchange.vue";
  import { getSingleExchange,currencyMonthRate } from "@/services/endpoints";
  import Api from "@/services/api";
  
  export default defineComponent({
    components: { AddEditClientRate, EmptyState },
    data(){
      return{
       monthRate:[],
       payload: {
      year: null,
      month: null,
    },
      }
    },
    computed: {
      allClientRates() {
        return this.$store.getters["pmLocalModules/allClientRates"];
      },
      isExchangeCurrency() {
      return this.$store.getters["pmLocalModules/isExchangeCurrency"];
    },
      isPmlocalSubId() {
      return this.$store.getters["pmLocalModules/isPmlocalSubId"];
    },
      pmLocalID() {
        return this.$store.getters["pmLocalModules/isPmLocalID"];
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      isEditPmLocal() {
        return this.$store.getters["hrResources/isEditEmployee"];
      },
      currentPath() {
        return this.$route.path;
      },
      shouldDisableInput() {
        // Add your condition to disable the input element here
        return (
          this.currentPath === "/edit-pm-local" &&
          this.isEditPmLocal &&
          !this.isEditButton
        );
      },
    },
    methods: {
      formatMonth(month) {
        return month?.substring(0, 3);
      },
      showDialog(month, data, items) {
        this.payload.year = items.year;
    this.payload.month = data.month;
        this.getSingleExchange(this.payload);
        this.$refs.addEditClientRate.clientRateForm.year = items.year;
        this.$refs.addEditClientRate.clientRateForm.month = data.month;
        this.$refs.addEditClientRate.clientRateForm.pm_local = data.pmLocalID;
        this.$refs.addEditClientRate.month = month;
        this.$refs.addEditClientRate.showDialog();
      },


      getSingleExchange(payload) {
       let data = {
        local_currency :this.isExchangeCurrency
        };
        Api()
          .post(
            `${getSingleExchange}${this.isPmlocalSubId}/${payload.year}/${payload.month}/`,data
          )
          .then((response) => {
            if (response.data.status === 'success') {
              const responseData = response.data.data;
              this.$store.commit("pmLocalModules/SET_SINGLE_EXCHANGE_RATE_TO_STATE",
                responseData
              );
            }
          })
          .catch((error) => {
            if (error) {
              this.$store.commit("SET_APP_LOADING_TO_STATE", false);
            }
          });
      },

      currencyMonthRate() {
        Api()
          .get(`${currencyMonthRate}${this.isPmlocalSubId}/`)
          .then((response) => {
            if (response.data.status ==='success') {
             this.monthRate = response.data.data
        
            }
          })
          .catch((error) => {
            if (error) {
              this.monthRate = ''
            }
          });
    },
    },
    watch: {
      isExchangeCurrency() {
        this.getSingleExchange(this.payload);
      },
    },
    mounted() {
      this.currencyMonthRate();
    },
  });
  </script>
  
  <style scoped>
  table {
    color: #344054;
    border-radius: 16px;
  }
  tr > th {
    background-color: #f2f4f7;
  }
  tbody tr:nth-of-type(even) {
    background-color: #f9fafb;
  }
  td {
    padding-left: 24px;
  }
  tr {
    padding: 16px 24px;
    height: 56px;
    width: 100%;
  }
  .tablecard {
    border: 1px solid #eaecf0;
  }
  .legend > span {
    display: flex;
    background-color: #2e90fa;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  </style>
  