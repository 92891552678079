<template>
    <section>
      <app-signature-dialog
        ref="signatureDialog"
        :title="$t('advancePaymentRequest.advancePaymentDetails')"
        width="65%"
      >
        <template #content>
          <!-- {{ currentPaymentRequests }} -->
          <div class="mt-8">
            <app-custom-table
              
              :headers="headers"
              :items="tableItems"
              :hideFooterHeader="true"
            >
              <template #title>
                <h3 class="H500">
                  {{ $t("advancePaymentRequest.advancePaymentDetails") }}
                </h3>
              </template>
            </app-custom-table>
          </div>
          <div class="ml-1 mt-9 text-[14px] text-[#101828] font-['inter']">
            <v-row>
              <v-col cols="5" class="py-1">
                <v-row>
                  <v-col cols="5" class="">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.accountHolder") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class=" ml-n3">
                    <p class="">{{ currentPaymentRequests.account_holder }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.bankName") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class=" ml-n5">
                    <p class="">{{ currentPaymentRequests.bank_name }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.cityOfBank") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class=" ml-n3">
                    <p class="">{{ currentPaymentRequests.city_of_bank }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.bic/swift") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class=" ml-n5">
                    <p class="">{{ currentPaymentRequests.swift_code }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.accountNumber") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class=" ml-n3">
                    <p class="">{{ currentPaymentRequests.account_number }} </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("advancePaymentRequest.iban") }}
                    </p>
                  </v-col>
                  <v-col cols="1" class=" font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class=" ml-n5">
                    <p class="">{{ currentPaymentRequests.iban }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="flex gap-5">
            <div class="w-[21%]">
  
              <app-upload-signature
                :signee="currentUserDetails.is_partner? currentPaymentRequests.company_name || '' : currentPaymentRequests.expert_name || '' "
                :is-signed="currentPaymentRequests.is_requested_for_signed"
                :signed-url="currentPaymentRequests.requested_for_signature"
                :date-signed="currentPaymentRequests.date_signed"
                position="Requester"
              ></app-upload-signature>
            </div>
            <!-- <div class="w-[20%]"></div> -->
            <div class="w-[21%]">
              <app-upload-signature
                 v-if="currentPaymentRequests.is_requested_for_signed"
                 :signee="currentPaymentRequests.ceo_name || ''"
                 :is-signed="currentPaymentRequests.ceo_signed"
                 :signed-url="currentPaymentRequests.ceo_signature"
                 :date-signed="currentPaymentRequests.ceo_date_signed"
                 position="CEO"
                 ></app-upload-signature>
            </div>
            <!-- <app-upload-signature :signee="currentPaymentRequests.expert_name"  ></app-upload-signature> -->
          </div>
        </template>
        <template #actions>
          <v-row>
            <v-col cols="12" class="justify-end my-2 d-flex">
              <app-secondary-button
                :buttonLabel="$t('hospitalityPackage.cancel')"
                @click="hideDialog"
              >
              </app-secondary-button>
              <app-primary-button
                :buttonLabel="$t('hospitalityPackage.send')"
                class="mx-6"
                :loading="loading"
                @click="saveAndSendRequest"
              >
              </app-primary-button>
            </v-col>
          </v-row>
        </template>
      </app-signature-dialog>
    </section>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Api from "@/services/api";
  import { advancePaymentSignatureRequest } from "@/services/endpoints";
  import { getTransString, formatDate, getSignee } from "@/services/helpers";
  import { mapState } from "vuex";
  import moment from "moment";
  
  export default defineComponent({
    name: "SignatureForm",
    data() {
      return {
        alertType: "success",
        alertState: false,
        alertTitle: "",
        showSignature: false,
      };
    },
    computed: {
      currentPaymentRequests() {
        return this.$store.getters[
          "advancePaymentModules/currentPaymentRequests"
        ];
      },
      currentAdvancePaymentId() {
        return this.$store.getters[
          "advancePaymentModules/currentAdvancePaymentId"
        ];
      },
      getArrayOfObjects() {
        return Object.entries(this.currentPaymentRequests)?.map(
          ([key, value]) => ({ [key]: value })
        );
      },
      items() {
        const modifiedData = this.getArrayOfObjects;
        // Extracting specific key-value pairs
        const extractedData = {
          request_date: "",
          requester_name: "",
          amount: 0,
          approved_amount: 0,
          currency: "",
        };
        modifiedData.forEach((obj) => {
          if ("request_date" in obj)
            extractedData.request_date = obj.request_date;
          if ("requester_name" in obj)
            extractedData.requester_name = obj.requester_name;
          if ("amount" in obj) extractedData.amount = obj.amount;
          if ("approved_amount" in obj)
            extractedData.approved_amount = obj.approved_amount;
          if ("currency" in obj) extractedData.currency = obj.currency;
        });
        return [extractedData];
      },
  
      tableItems() {
      return this.items.map((item) => {
        return {
          date: formatDate(item.request_date, this.appLocaleLang),
          requester: item.requester_name,
          requested: Number(item.amount || 0),
          approved: Number(item.approved_amount || 0),
        };
      });
    },
      headers() {
      return [
        {key: "date",
        label: "Date",
      },
      {key: "requester",
        label: "Requester",
      },
      {key: "requested",
        label: "Requested",
        type: "digit",
      },
      {key: "approved",
        label: "Approved",
        type: "digit",
      },
      ];
      
    },
      signature() {
        return this.$store.getters["commonResources/signatureImageUrl"];
      },
      signatureDate() {
        return this.$store.getters["commonResources/signatureDate"];
      },
      appLocaleLang() {
        return this.translation.locale;
      },
      ...mapState(["translation"]),
      currentUserDetails() {
        return this.$store.getters["auth/isUserDetails"];
      },
    },
    methods: {
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
      hideDialog() {
        this.$refs.signatureDialog.showDialog = false;
      },
      showSignatureDialog() {
        this.$refs.signatureDialog.showDialog = true;
      },
      getSingleAdvancementPaymentRequest() {
        const payload = {
          load_page: true,
        };
        this.$store.dispatch(
          "advancePaymentModules/getSingleAdvancementPaymentRequest",
          payload
        );
      },
      saveAndSendRequest() {
        const payload = {
          signee: getSignee(this.currentUserDetails),
          signature: this.signature,
          date_signed: moment(this.signatureDate, "DD/MM/YYYY")?.format("YYYY-MM-DD"),
        };
  
        Api()
          .post(
            `${advancePaymentSignatureRequest}${this.currentAdvancePaymentId}/`,
            payload
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.hideDialog();
              this.getSingleAdvancementPaymentRequest()
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.detail;
            }
          })
  
          .catch((error) => {
            if (error) {
              this.hideDialog();
              this.alertType = "error";
              this.alertState = true;
              this.alertTitle = error.response?.data?.detail;
            }
          });
      },
    },
    created() {
      
    },
  });
  </script>
  
  <style scoped></style>
  