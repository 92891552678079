<template>
  <div>
    <div class="py-2 label" v-if="isLabel">
      <label :for="id" class="text-[#344054] text-[14px] font-medium">{{
          label
        }}</label>
    </div>

    <div class="parent-wrapper" :class="{ 'above': shouldDisplayAbove }">
      <!-- <input type="hidden" :name="name" :value="modelValue"  /> -->
      <div
          class="dropdown"
          :class="{ open: isOpen }"
          @click="!disabled ? toggleDropdown() : ''"
      >
        <div
            :class="[
            {disabled: disabled,
            'wrapper-error': error,
            'border border-gray-300 rounded-lg': !isOpen,

            'bg-transparent border-collapse !border-none !shadow-none'
            :noOutline},

            'wrapper',
          ]"
            :style="{ height: containerHeight }"
        >
          <div
              v-if="!selectedOptions || !displayText"
              class="ml-2 text-[16px] !text-[#667085]"
          >
            <span :class="{'text-sm text-gray-400': noOutline}">{{
                isTranslation
                    ? $t(getTranslatedStringKey(labelPlaceholder))
                    : labelPlaceholder
              }}</span>
          </div>
          <div v-else class="pr-5 ml-2 text-truncate">
            <span>{{
                isTranslation
                    ? $t(getTranslatedStringKey(displayText?.text))
                    : displayText?.text
              }}</span>
          </div>

          <span v-if="isOpen" class="dropup-arrow" v-show="!error">
            <v-icon icon="custom:expandLess" />
          </span>
          <span v-else class="dropdown-arrow" v-show="!error">
            <v-icon icon="custom:expandIcon" v-if="!disabled" />
          </span>
        </div>
        <div
            class="absolute right-0 flex mr-2 transform -translate-y-1/2 item-center top-1/2"
            v-if="error"
        >
          <v-icon size="20" icon="custom:errorIcon" />
        </div>
        <div class="mt-2 dropdown-list" v-show="isOpen" @click.stop>
          <div class="divide-y">
            <div class="d-flex justify-space-between">
              <div v-if="showSearch" class="mt-2 mb-2 ml-2">
                <span class="mr-1"
                ><v-icon
                    size="small"
                    color="#c5c9d3"
                    icon="custom:searchIcon"
                /></span>
                <input
                    width=""
                    class="w-3/4 pr-4 placeholder text-truncate"
                    :placeholder="searchPlaceholder"
                    v-model="searchTerm"
                    @input="debounceFilterOptions"
                    @click.stop
                />
              </div>
              <div
                  v-if="showAddMore"
                  @click="handleAddMore"
                  class="mt-2 mb-2 mr-3 ml-2 cursor-pointer hover:bg-[#f7fafc] pr-1"
              >
                <p class="!text-[#101828] !font-inter !font-normal">
                  <span class="mr-1"
                  ><v-icon size="small" color="#667085" icon="mdi-plus"
                  /></span>
                  {{
                    isTranslation
                        ? $t(getTranslatedStringKey(addMoreText))
                        : addMoreText
                  }}
                </p>
              </div>
            </div>
            <div></div>
          </div>
          <div v-if="filteredOptions?.length > 0">
            <div
                v-for="option in filteredOptions"
                :key="option?.value"
                @click.stop="toggleOption(option)"
                :class="['dropdown-option', { selected: isSelected(option) }]"
            >
              <div class="flex items-center">
                <div v-if="showCheckbox">
                  <input
                      type="checkbox"
                      class="option-checkbox"
                      :id="'option-checkbox-' + option?.text"
                      :value="option?.value ? option?.value : modelValue?.value"
                      :checked="isSelected(option)"
                      @change="updateValue"
                  />
                </div>
                <div>
                  <label :for="'option-checkbox-' + option?.value">
                    {{
                      isTranslation
                          ? $t(getTranslatedStringKey(option?.text))
                          : option?.text
                              ? option?.text
                              : modelValue?.text
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="justify-center py-2 d-flex align-center">
            <p>{{ $t("No data available"?.getTranslationKey()) }}</p>
          </div>
        </div>
      </div>
    </div>

    <p v-if="error" class="text-red-500 text-[14px]">
      {{ $t(`${errorMessage}`) }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "SingleSelect",
  props: {
    id: {
      type: String,
      default: "single-select",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "Single select",
    },
    labelPlaceholder: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    showAddMore: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    optionItems: {
      type: Object,
    },
    addMoreText: {
      type: String,
      default: "Add text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isTranslation: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object || String,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    containerHeight: {
      type: String,
      default: "44px",
    }
  },
  data() {
    return {
      selectedOptions: this.modelValue || null,
      searchTerm: "",
      isOpen: false,
      error: false,
      shouldDisplayAbove: false,
    };
  },
  watch: {
    modelValue(val) {
      this.selectedOptions = val;
    },
    hasError(val) {
      this.error = val;
    },
    // isOpen(newValue) {
    //   if (newValue) {
    //     this.$nextTick(this.calculatePosition);
    //   }
    // }
  },
  computed: {
    filteredOptions() {
      if (this.searchTerm) {
        const term = this.searchTerm?.toLowerCase();
        return this.options?.filter((option) =>
            option?.text?.toLowerCase()?.includes(term)
        );
      }

      return this.options;
    },
    displayText() {
      if (Array.isArray(this.options)) {
        return this.options?.find(
            (option) => option?.value === this.modelValue
        );
      }
      return {};
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    activate() {
      alert("activate");
      return {
        background: "#ffffff",
        border: "1px solid #fdc6c2",
        "box-shadow":
            "0 1px 2px rgba(16, 24, 40, 0.05),0 0 0 4px rgba(139, 41, 35, 0.04)",
        "border-radius": "8px",
      };
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    isSelected(option) {
      // this.isOpen = false;
      return this.compareOptions(this.selectedOptions, option);
    },
    toggleOption(option) {
      this.selectedOptions = option;
      this.isOpen = false;
      this.error = false;
      this.returnObject
          ? this.$emit("update:modelValue", option)
          : this.$emit("update:modelValue", option.value);
      // this.$emit("update:modelValue", option);
    },

    updateValue(option) {
      this.$emit("update:modelValue", option);
    },
    compareOptions(option1, option2) {
      return (
          option1?.value === option2?.value && option1?.text === option2?.text
      );
    },
    filterOptions() {
      this.isOpen = true;
    },
    closeDropdown(event) {
      if (!this.$el?.contains(event?.target)) {
        this.isOpen = false;
      }
    },
    preventDropdownClose(event) {
      event.stopPropagation();
    },
    handleAddMore() {
      this.$emit("add-More");
    },
    // calculateSpaceAbove() {
    //   const dropdownHeaderRect = this.$el.querySelector('.dropdown-header').getBoundingClientRect();
    //   return dropdownHeaderRect.top;
    // },
    // calculateSpaceBelow() {
    //   const dropdownHeaderRect = this.$el.querySelector('.dropdown-header').getBoundingClientRect();
    //   const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    //   return windowHeight - dropdownHeaderRect.bottom;
    // },
  },
});
</script>

<style scoped>
.parent-wrapper {
  position: relative;

}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  width: 100%;
  background: #ffff;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

}
.wrapper-error {
  border-color: red !important;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
  font-size: 14px;
  transition: transform 0.3s;
}
.dropup-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-53%);
  pointer-events: none;
  color: #333;
  font-size: 14px;
  transition: transform 0.3s;
}

.selected-item {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin: 2px 3px;
  padding: 0 2px;
  color: #344054;
  height: 24px;
}

.selected-item span {
  margin-right: 4px;
}

.selected-item button {
  padding: 2px;
  background: none;
  border: none;
  cursor: pointer;

  color: #718096;
}

.placeholder {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

.dropdown-list {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
  0 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.dropdown-list .dropdown-option {
  padding: 4px;
  cursor: pointer;
}
.dropdown-option {
  padding-left: 12px !important;
}

.dropdown-list .dropdown-option.selected {
  background-color: #fbf7f6;
}

.dropdown-list .dropdown-option:hover {
  background-color: #f7fafc;
}

.option-checkbox,
.dropdown-list .dropdown-option {
  cursor: pointer;
}

.option-checkbox:hover,
.dropdown-list .dropdown-option:hover {
  background-color: #f7fafc;
}

/* Added styles */
.dropdown-option label {
  cursor: pointer;
  color: #101828;
  font-size: 16px;
}

.dropdown-list {
  pointer-events: all;
}

.dropdown-list.hide {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
  position: relative;
  margin-right: 5px;
  margin-top: 5px;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
  border-color: #ae6a65;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  background: #fafafb;
  color: #667085;
  cursor: default;
}
.open {
  background: #ffffff;
  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
  0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}
.above .dropdown-options {
  bottom: 100%;
  top: auto;
}
</style>
