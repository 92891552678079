/* eslint-disable no-useless-escape */
export function validEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function validURL(url) {
  const re = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return re.test(url);
}

export function minLength(password) {
  const re = /[0-9a-zA-Z\cX]{6,}/;
  return re.test(password);
}
export function sixDigitsCode(code) {
  const re = /^[^\d]*?(\d|\d[^\d]+){6,6}$/;
  return re.test(code);
}
export function onlyDigits(digits) {
  const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
  return re.test(digits);
}
export function passwordMatch(password, confirmPassword) {
  if (password === confirmPassword) return true;
}
