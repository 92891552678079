<template>
  <div>
    <app-table
      :headers="header"
      :items="isAddendumFile"
      @edit-event="editFileDialog"
      @delete-event="deleteFileDialog"
      :showCursor="false"
    >
      <template #title>
        <h3 class="H500">{{ $t("leaves.allContractAddenda") }}</h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('leaves.addContractAddenda')"
          @click="addFileDialog"
          :disabled="shouldDisableInput ? 'disabled' : null"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
      <!-- <template #footer >
        <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
        
      </template> -->
    </app-table>
  </div>
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteProjectFile"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-file-upload
    ref="appFileUpload"
    @url-from-file="handleFileUrl"
    :showUploadOnEdit="true"
    :showSelect="false"
    :is-edit="isEdit"
    :file-size="fileSize"
  ></app-file-upload>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue";
import api from "@/services/api";
import {  getTransString } from "@/services/helpers";
import {  fetchSingleProjectFile, manageProjectFile } from "@/services/endpoints";

export default defineComponent({
    name: "ContractAddenda",
    props: {},
    data() {
        return {
          header: ['Title', 'File Type', 'Date Uploaded', 'User'],
          fileArray: [],
          alertType: "success",
          alertState: false,
          alertTitle: "",
          addendaDate: "",
          addendaTitle: "",
          fileName: "",
          fileSize: "",
          filePath: '',
          uploadedContractFiles: [],
          dialogState: false,
          contractAddendumId:"",
          isDelete: false,
          title: "",
          subTitle: "",
          cancelDelete: "Cancel",
          isEdit: false,

          // currentPages: 1,
        }
    },
    computed: {
      isProjectID(){
        return this.$store.getters["projectModules/isProjectID"]
      },
     currentPath() {
        return this.$route.path;
      },
      isAllProjectFiles() {
        return this.$store.getters["projectModules/isAllProjectFiles"];
      },
      isAddendumFile(){
        return this.isAllProjectFiles.filter(addenda =>  addenda.file_type === "Addendum" ).map(({ title, file_type, date_uploaded, user , id, file_path, file_url}) => ({
          title: title,
          'file type': file_type,
          'date uploaded': date_uploaded,
          user: user,
          id: id,
          file_path: file_path,
          file_url: file_url

        }));
      },
      // totalPages() {
      //   return this.$store.getters["projectModules/isContractAddendaTotalPages"];
      // },
      isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      shouldDisableInput() {
      // Add your condition to disable the input element here
       return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
      },
    },
    watch:{
      isAllProjectFiles(value) {
        if (value.length) {
          const lastItem = value[0];
          this.addendaTitle = lastItem.title;
          this.addendaDate = lastItem.date;
        }
      },
    },
    methods: {
      handleFileUrl(data){
        this.fileArray = data
      },
      getTranslatedStringKey(field) {
        return getTransString(field);
      },

      addFileDialog(){
        this.isEdit = false
        this.$refs.appFileUpload.fileType='Addendum'
        this.$refs.appFileUpload.showDialog()
      },
      deleteFileDialog(event){
          this.contractAddendumId = event.id
          this.$refs.appFileUpload.editObject = event
          this.$refs.dialog.showDialog = true
          this.subTitle = "projects.deleteFileConfirm";
          this.title = "projects.deleteFile";
          this.isDelete = !this.isDelete;
          this.cancelDelete = "employees.noKeepIt"

      },
      editFileDialog(event){
        this.contractAddendumId = event.id
        this.$refs.appFileUpload.isEdit = true
        this.$refs.appFileUpload.editObject = event

        this.$refs.appFileUpload.showEditDialog()
        this.getSingleFile()
        this.isEdit = true

      },
      hideDeleteDialog(){
      this.$refs.dialog.showDialog = false
      setTimeout(()=>{
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelDelete = "employees.cancel";
      },600)
    },
    getSingleFile(){
      api()
      .get(`${manageProjectFile}${this.contractAddendumId}/`)
      .then(response => {
        if(response.data.status === "success"){
          const data = response?.data?.data
          this.filePath = data?.file_path
          this.title = data?.title
          this.fileSize = data?.file_size
          this.fileUrl = data?.file_url
          this.filetype = data?.file_type
        }
      })
      .catch()
    },
      getNewProjectFiles(){
        api()
          .get(`${fetchSingleProjectFile}${this.isProjectID}/`)
          .then((response) => {
            if(response.data.status === 'success'){
              const payload = {
              project_id : this.isProjectID,
              load_page: false
            }
              this.$store.dispatch("projectModules/getAllProjectFiles", payload);
            }
        })
      },
      deleteProjectFile() {
        api()
        .delete(`${manageProjectFile}${this.contractAddendumId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response?.data?.details;
            this.getNewProjectFiles();
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.isDelete = !this.isDelete;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertState = response?.data?.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch(error => {
          if (error) {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertState = error?.response?.data?.detail;
            this.alertState = !this.alertState;
          }
        });
      },
      // loadMore() {
      //   // let page = this.currentPages;

      // },

    },
    created(){
      const payload = {
      project_id : this.isProjectID,
      load_page: false
    }
      this.$store.dispatch("projectModules/getAllProjectFiles", payload);
    }

});
</script>
<style scoped></style>
