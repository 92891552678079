<template>
  <div
    v-if="apploader"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <div v-if="!overview">
      <v-col cols="4" class="pl-0">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchMisions"
          :callback="true"
        />
      </v-col>
      <app-table
        :headers="headers"
        :items="isAllMissions"
        :showCheck="false"
        :hideMenu="true"
        @expert-overview="showMissionOverview({ $event, data: 'expert-overview' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("projects.allMissions") }}</h3>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <div v-else>
    <ExpertMissionOverview @showAllMissions=" showAllMissions"/>
    </div>
    

    <!-- delete dialog -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteMision()"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import CreateMission from "@/components/dashboard/projects/CreateMission.vue";
import API from "@/services/api";
import {
  getAllExpertMission,
  getSingleMission,
  searchExpertMission,
} from "@/services/endpoints";
import { formatDate, getTransString } from "@/services/helpers";
import Paginator from "@/components/shared/PaginationComponent.vue";
import ExpertMissionOverview from "./ExpertMissionOverview.vue";

export default defineComponent({
  name: "Mission",
  components: {
    // CreateMission,
    Paginator,
    ExpertMissionOverview,
  },
  data() {
    return {
      showCreateMission: false,
      overview: false,
      headers: [
        "Mission ID",
        "Contract ID",
        "Start Date",
        "End Date",
        "Country",
        "Location",
        "Status",
      ],
      apploader: true,
      title: "",
      subTitle: "",
      currentPages: 1,
      isEdit: false,
      deleteMissionId: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
    };
  },
  computed: {
    isAllMissions() {
      return this.$store.getters["contractModules/isAllMissions"];
    },
    totalPages() {
      return this.$store.getters["contractModules/isMissionPage"];
    },
    currentPage() {
      return this.$store.getters["contractModules/isCurrentPage"];
    },
    currentPath() {
      return this.$route.path;
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    editProject() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-project" &&
        this.editProject &&
        !this.isEditButton
      );
    },
    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },
    projectId() {
      return this.$store.getters["projectModules/isProjectID"];
    }
  },
  methods: {
    toggleShowCreateMission() {
      this.showCreateMission = true;
    },
    showAllMissions() {
      this.overview = false;
      this.$store.commit("contractModules/SET_EDIT_MISSION_ID_TO_STATE", "");
      this.$store.commit("contractModules/SET_EDIT_MISSION_TO_STATE", false);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    showMissionOverview(data){
      if (!this.isEditButton) return;
      this.overview = true;
      this.$store.commit(
          "contractModules/SET_EDIT_MISSION_ID_TO_STATE",
          data.$event.id
        );
        this.$store.commit("projectModules/SET_FEE_INVOICE", data.$event.is_fee_invoice);
        this.$store.commit("projectModules/SET_TRAVEL_EXPENSE", data.$event.is_travel);
        this.$store.commit("projectModules/SET_TICKET_INVOICE", data.$event.is_ticket);
        this.$store.commit("projectModules/SET_REPORT_INVOICE", data.$event.is_report);
    },
    showDialog(data) {
      if (data.data === "edit") {
        this.$store.commit(
          "contractModules/SET_EDIT_MISSION_ID_TO_STATE",
          data.$event.id
        );
        this.$store.commit("contractModules/SET_EDIT_MISSION_TO_STATE", true);
        this.toggleShowCreateMission();
      } else if (data.data === "delete") {
        this.$refs.dialog.showDialog = true;
        this.title = this.$t("contracts.deleteMissionTitle");
        this.subTitle = this.$t("contracts.deleteMissionTitleConfirm");
        this.deleteMissionId = data.$event.id;
      }
    },
    fetchAgain(page) {
      this.getAllMissions(page);
    },
    searchMisions(search_query) {
      API()
        .post(`${searchExpertMission}${this.currentPage}/`, { search_query })
        .then((response) => {
          if (response.data.status === "success") {
            const responsedata = response.data.data;
            this.apploader = false;
            const modifiedResponse = responsedata?.map((resData) => {
              const formattedMissionStartDate = formatDate(
                resData.mission_start_date
              );
              const formattedMissionEndDate = formatDate(
                resData.mission_end_date
              );
              return {
                id: resData.id,
                expert: resData.expert,
                "contract id": resData.contract_id,
                "mission id": resData.id,
                "start date": `${formattedMissionStartDate}`,
                "end date": `${formattedMissionEndDate}`,
                country: resData.travel_country,
                location: resData.assignment_place,
                status: resData.is_signed ? "Signed" : "Not signed",
                contract_type: resData.contract_type,
                is_fee_invoice: resData.is_fee_invoice,
                is_travel: resData.is_travel,
                is_report: resData.is_report,
                is_ticket: resData.is_ticket,
              };
            });

            this.$store.commit(
              "contractModules/SET_ALL_MISSIONS_TO_STATE",
              modifiedResponse
            );
            this.$store.commit(
              "contractModules/SET_MISSION_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch();
    },
    getAllMissions(page) {
      this.apploader = true;
      API()
        .get(`${getAllExpertMission}${this.projectId}/${page}`)
        .then((response) => {
          if (response.data.status === "success") {
            const responsedata = response.data.data;
            this.apploader = false;
            const modifiedResponse = responsedata?.map((resData) => {
              const formattedMissionStartDate = formatDate(
                resData.mission_start_date
              );
              const formattedMissionEndDate = formatDate(
                resData.mission_end_date
              );
              return {
                id: resData.id,
                expert: resData.expert,
                "contract id": resData.contract_id,
                "mission id": resData.id,
                "start date": `${formattedMissionStartDate}`,
                "end date": `${formattedMissionEndDate}`,
                country: resData.travel_country,
                location: resData.assignment_place,
                status: resData.is_signed ? "Signed" : "Not signed",
                contract_type: resData.contract_type,
                is_fee_invoice: resData.is_fee_invoice,
                is_travel: resData.is_travel,
                is_report: resData.is_report,
                is_ticket: resData.is_ticket,
              };
            });

            this.$store.commit(
              "contractModules/SET_ALL_MISSIONS_TO_STATE",
              modifiedResponse
            );
            this.$store.commit(
              "contractModules/SET_MISSION_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch(() => {
          this.apploader = false;
        });
    },
    deleteMision() {
      API()
        .delete(`${getSingleMission}${this.deleteMissionId}`)
        .then((response) => {
          this.$refs.dialog.showDialog = false;
          this.alertState = true;
          this.alertTitle = response?.data?.detail;
          this.getAllMissions(this.currentPage);
        })
        .catch(() => {
          this.$refs.dialog.showDialog = false;
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllMissions(page);
    },
  },
  mounted() {
    this.getAllMissions(this.currentPage);
  },
  created() {
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    this.$store.commit("contractModules/SET_EDIT_MISSION_ID_TO_STATE", "");
    this.$store.commit("contractModules/SET_EDIT_MISSION_TO_STATE", false);
  },
});
</script>

<style></style>
