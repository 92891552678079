<template>
  <v-sheet class="bg-transparent">
    <!-- dialog for adding and updating cost center -->
    <app-dialog
      ref="dialog"
      :title="
        isEdit
          ? $t('projectConfiguration.updateCostCenterTitle')
          : $t('projectConfiguration.addNewCostCenter')
      "
      subtitle=""
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          class="mb-3"
          :placeholder="$t('projectConfiguration.enterTitle')"
          :label="$t('projectConfiguration.costCenterTitle')"
          v-model.trim="addTitle"
        >
        </app-input-field>
        <app-input-field
          class="mb-3"
          :placeholder="$t('projectConfiguration.enterNumber')"
          :label="$t('projectConfiguration.costCenterNumber')"
          v-model.trim="addNumber"
        >
        </app-input-field>
        <app-select-field-object
          class="mb-3"
          :label="$t('projectConfiguration.costCenterType')"
          v-model.trim="addType"
          :placeholder="$t('projectConfiguration.selectType')"
          :options="typeOptions"
          :isTranslation="false"
        >
        </app-select-field-object>
        <app-select-field-object
          :isTranslation="false"
          :label="$t('projectConfiguration.costCenterICONCompany')"
          v-model.trim="addCompany"
          :placeholder="$t('projectConfiguration.selectCompany')"
          :options="companiesOption"
        >
        </app-select-field-object>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
              :buttonLabel="
                isEdit ? $t('generalConfig.update') : $t('generalConfig.add')
              "
              @click-event="addToCostCenter()"
              :disabled="addTitle === '' || addType === '' || addCompany === ''"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for deleting config types -->

    <app-alert
      :title="$t(this.getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import Api from "@/services/api";
import { getTransString, getSelectOptionList } from "@/services/helpers"
export default defineComponent({
  name: "Create and Edit Cost Centers",

  data() {
    return {
      isEdit: false,
      title: "",
      subTitle: "",
      alertType: "success",
      alertState: false,
      addCompany: '',
      addType: '',
      addTitle: '',
      addNumber: '',
      typeOptions: [
        { id: "Regular Project Cost Center", value: "Regular Project Cost Center" },
        { id: "Framework Project Main Cost Center", value: "Framework Project Main Cost Center" },
        { id: "Fixed Cost Center", value: "Fixed Cost Center" },
      ],
      editObject: {}
    }
  },


  computed: {
    isCostCenters() {
      return this.$store.getters['hrConfigurations/isCostCenters'];
    },
    companiesOption() {
      const optionList = getSelectOptionList('hrConfigurations/isCompanies')
      return optionList.map((company) => {
        return {
          id: company?.id,
          value: company?.value,
        }
      })
    },
    currentPage() {
      return this.$store.getters["hrConfigurations/isCurrentPage"];
    }
  },
  methods: {

    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showCreateCostCenterDialog(){
      this.isEdit = false
      this.addCompany = ''
      this.addType = ''
      this.addTitle = ''
      this.addNumber = ''
      this.$refs.dialog.showDialog = true
    },

    showEditCostCenterDialog() {
      if (this.editObject?.data === 'edit') {
        const newdata = this.editObject?.$event;
        const modifiedData = {};
        for (const key in newdata) {
          const newKey = key.replace(/ /g, "_");
          modifiedData[newKey] = newdata[key];
        }

        const icon_company = this.companiesOption?.filter((company) => company?.id === modifiedData?.icon_company);
        this.addCompany = icon_company[0]?.value
        this.addType = this.editObject?.$event?.type;
        this.addTitle = this.editObject?.$event?.title
        this.addNumber = this.editObject?.$event?.number
        this.costCenterID = this.editObject?.$event?.id;
        this.isEdit = true
        this.$refs.dialog.showDialog = true
      }
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false
    },
    addToCostCenter() {
      const payload = {
        title: this.addTitle,
        cost_center_number: this.addNumber,
        cost_center_type: this.addType,
        icon_company_id: this.addCompany,
        cost_centre_id: this.costCenterID
      };
      if (!this.isEdit) {
        delete payload.cost_centre_id
      }

      Api()
        .post("/configuration/projects/add-cost-center/", payload)
        .then((response) => {

          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response?.data?.detail;
            this.$store.dispatch('hrConfigurations/getAllCostCenters', 1)
            this.hideDialog()
          }
          if (response.data.status === "error") {

            this.hideDialog()
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response?.data?.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {

          if (error) {
            this.hideDialog()
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error?.response?.data?.detail;
            this.alertState = !this.alertState;
          }
        });
    },

  },

  mounted() {
    // this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 4)
    const payload = {
      configType: 'Companies',
      setter: 'SET_COMPANIES_TO_STATE'
    }
    this.$store.dispatch('hrConfigurations/getAllConfigurationTypes', payload)

  },

})
</script>
