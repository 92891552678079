<template>
  <div>
    <div
      v-if="appLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else class="">
      <app-table
        :showEditPage="true"
        :headers="tableHeaders"
        :items="allOfficeStaff"
        :showCheck="false"
        :showCursor="false"
        :hideMenu="shouldDisableInput"
        @edit-event="showEditDialog"
        @delete-event="showDeleteDialog"
      >
        <template #title>
          <h3 class="H500">{{ $t("foreignOffices.allStaffs") }}</h3>
        </template>
        <template #actions>
          <app-primary-button
            :buttonLabel="$t('foreignOffices.addStaff')"
            @click="addOfficeStaff"
            v-show="!shouldDisableInput"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </template>
      </app-table>
      <delete-staff ref="deleteStaff"></delete-staff>
      <create-edit-staff ref="createEditStaff"></create-edit-staff>
    </div>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";
import DeleteStaff from "./DeleteStaff.vue";
import CreateEditStaff from "./CreateEditStaff.vue";
import { searchForeignOffice } from "@/services/endpoints";

export default defineComponent({
  name: "Office Staff List",
  components: {
    Paginator,
    DeleteStaff,
    CreateEditStaff,
  },
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      currentPages: 1,
    };
  },

  watch: {},
  computed: {
    allOfficeStaff() {
      const modifiedData =
        this.$store.getters["foreignOfficeModule/allOfficeStaff"];
      return modifiedData?.map((data) => {
        return {
          id: data.id,
          employee_id: data.employee_id,
          'employee name': `${data.employee__first_name} ${data.employee__last_name}`,
          'employee position': data.position,
        };
      });
    },
    tableHeaders() {
      return this.$store.getters["foreignOfficeModule/staffTableHeaders"];
    },

    currentPage() {
      return this.$store.getters["foreignOfficeModule/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["foreignOfficeModule/isTotalPages"];
    },
    appLoading() {
      return this.$store.getters["foreignOfficeModule/appLoading"];
    },
    filtersBy() {
      return this.$store.getters["foreignOfficeModule/isFilterBys"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditOffice() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-foreign-office" &&
        this.isEditOffice &&
        !this.isEditButton
      );
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    addOfficeStaff() {
      this.$refs.createEditStaff.showDialog();
    },
    showEditDialog(event) {
      this.$refs.createEditStaff.isEdit = true;
      this.$refs.createEditStaff.staffId = event.id;
      this.$refs.createEditStaff.officeStaffForm.employee_id = event.employee_id;
      this.$refs.createEditStaff.officeStaffForm.position = event['employee position']
      this.$refs.createEditStaff.showDialog();
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDeleteDialog(event) {
      this.$refs.deleteStaff.staffId = event.employee_id;
      this.$refs.deleteStaff.showDialog();
    },
    getAllOfficeStaff(page) {
      const payload = {
        load_page: true,
        page_number: page,
      };
      this.$store.dispatch("foreignOfficeModule/getAllOfficeStaff", payload);
    },
    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllOfficeStaff(page);
    },

    searchForeignOffice(search_query) {
      const payload = {
        search_query: search_query,
      };

      Api()
        .post(`${searchForeignOffice}${this.currentPage}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "foreignOfficeModule/SET_OFFICES_TO_STATE",
              response.data.data
            );
          }
        });
    },

    loadMore() {
      const page = this.currentPages;
      this.getAllOfficeStaff(page);
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    const page = 1;
    this.getAllOfficeStaff(page);
    this.$store.dispatch("foreignOfficeModule/getAllEmployees");
    this.$store.commit(
      "foreignOfficeModule/SET_CURRENT_FOREIGN_OFFICE_TO_STATE",
      {}
    );
  },
});
</script>
