<template>
    <div>
      <div class="filter_parent SPC-MT-400">
        <v-row>
          <v-col cols="4" md="3" class="">
            <app-search-field
              :label="$t('Search'.getTranslationKey())"
              @search-event="fetchAgain(1)"
              @search-function="searchFlightRequest"
              :callback="true"
              placeholder="Search by requester or cost center"
            />
          </v-col>
        </v-row>
        <div
          v-if="isAppLoading"
          class="d-flex justify-center h-[500px] flex items-center"
        >
          <app-loader></app-loader>
        </div>
        <div class="SPC-MT-400" v-else>
          <app-table
            :headers="headers"
            :items="isAllFlights"
            hideMenu="true"
            :showCheck="false"
            :showCursor="true"
            :isAdvancePaymentRequest="true"
            @edit-event="showDialog({ $event, data: 'edit' })"
            @delete-event="showDialog({ $event, data: 'delete' })"
            @view-file="editFlightRequest"
          >
            <template #title>
              <h3 class="H500">{{ $t("flights.allFlight") }}</h3>
            </template>
            <template #footer>
              <div>
                <Paginator
                  :total-items="totalPages"
                  v-model="currentPages"
                  :items-per-page="10"
                  :max-pages-shown="5"
                  :on-click="loadMore"
                  v-if=" totalPages > 10"
                />
              </div>
            </template>
          </app-table>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { defineComponent } from "vue";
  import Api from "@/services/api";
  import { getEmployeeFlightRequests } from "@/services/endpoints";
  import moment from "moment";
  import { currencyFormatter, formatAmount } from "@/services/helpers";
  import Paginator from "@/components/shared/PaginationComponent.vue";

  export default defineComponent({
    name: "FlightRequests",
    components: {
      Paginator,
    },
    data() {
      return {
        headers: [
          "Cost Center",
          "Requester",
          "Route",
          "No. of Flight",
          "Departure Date",
          "Return Date",
          "Net Amount",
          "Gross Amount",
          "Status",
        ],
        isAppLoading: false,
        currentPages: 1,
        deleteId: null,
        title: "",
        subTitle: "",
        isEdit: false,
        alertType: "success",
        alertState: false,
        alertTitle: "",
      };
    },
    props: {
      tabId: {
        type: Number,
        default: 0,
      }
    },
    computed: {
      isAllFlights() {
        return this.$store.getters["flightModule/isAllFlights"];
      },
      totalPages() {
        return this.$store.getters["flightModule/isTotalPages"];
      },
      currentPath() {
        return this.$route.path;
      },
      germanCurrency(curr) {
        return currencyFormatter(curr);
      },
    },
    methods: {
      requestFlight() {
        this.$router.push({ name: "add-flight" });
      },
      showDialog(data){
        if(data.data === 'delete'){
          this.deleteId = data.$event.id
          this.$refs.dialog.showDialog = true;
          this.title = this.$t("Delete Request?".getTranslationKey());
          this.subTitle = "flights.delReg"
        } else if(data.data === 'edit'){
          this.$store.commit("flightModule/SET_EDIT_FLIGHT_TO_STATE", true);
          this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", data.$event.id);
          this.$router.push({name: "employeePreviewAndSign" });
        }
      },
      editFlightRequest(data) {
        this.$store.commit("flightModule/SET_EDIT_FLIGHT_TO_STATE", true);
        this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", data.id);
        this.$router.push({ name: "flightRequestDetails" });
      },
      getEmployeeFlights(page) {
        this.isAppLoading = true;
        Api()
          .get(`${getEmployeeFlightRequests}${page}/`)
          .then((response) => {
            if (response.data.status === "success") {
              this.isAppLoading = false;
              const responseData = response.data.data;
              const modifiedData = responseData?.map((item) => {
                const formattedDepartureDate = moment(item.departure_date).format(
                  "D.M.YYYY"
                );
                const formattedReturnDate = moment(item.return_date).format(
                  "D.M.YYYY"
                );
                return {
                  id: item.id,
                  "cost center": item.cost_centre__cost_center_number,
                  requester: `${item.requester__first_name} ${item.requester__last_name}`,
                  "contract id": item.contract_id,
                  route: item.route,
                  "no. of flight": item.number_of_flights,
                  "departure date": formattedDepartureDate,
                  "return date": formattedReturnDate,
                  "net amount":
                    this.appLang === "de"
                      ? this.germanCurrency(item.net_amount)
                      : formatAmount(item.net_amount),
                  "gross amount":
                    this.appLang === "de"
                      ? this.germanCurrency(item.gross_amount)
                      : formatAmount(item.gross_amount),
                      status: item.status.name,
                      status_action: item.status.status,
                };
              });
              this.$store.commit(
                "flightModule/SET_ALL_FLIGHTS_TO_STATE",
                modifiedData
              );
              this.$store.commit(
                "flightModule/SET_TOTAL_PAGES_TO_STATE",
                response.data.total_pages
              );
            }
          })
          .catch(() => {
            this.isAppLoading = false;
          });
      },
      loadMore() {
        const page = this.currentPages;
        this.getEmployeeFlights(page);
      },
    },
    mounted() {
      this.getEmployeeFlights(this.currentPages);
      this.$store.commit("flightModule/SET_EDIT_FLIGHT_TO_STATE", false);
      this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", null);
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
      this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    },
    created() {},
  });
  </script>
