<template>
  <div class="flex items-center">
    <div>
      <div>
        <div class="flex items-center justify-between">
          <div class="py-4">
            <div class="flex items-center">
              <div class="H400">{{ title }}</div>
              <div class="px-4 P200">• {{ time }}</div>
              <div :class="[draft === '' ? 'none' : 'draft']">{{ draft }}</div>
            </div>
            <div class="flex items-center">
              <div v-html="content" class="P300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyAnnouncementsCard",
  components: {},
  props: {
    title: {
      type: String,
    },
    time: {
      type: String,
    },
    draft: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  date() {
    return {};
  },
  computed: {},
  methods: {},
});
</script>

<style scoped>
.draft {
  height: 18px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #363f72;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  background-color: #f8f9fc;
  padding: 2px;
}
</style>
