const countries = [
  {
    id: 1,
    name: "Afghanistan",
    code: "+93",
    flag: "//upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
    callingCode: "AF",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 2,
    name: "Albania",
    code: "+355",
    flag: "//upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
    callingCode: "AB",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 3,
    name: "Algeria",
    code: "+213",
    flag: "//upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 4,
    name: "Andorra",
    code: "+376",
    flag: "//upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 5,
    name: "Angola",
    code: "+244",
    flag: "//upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 6,
    name: "Antigua and Barbuda",
    code: "+000",
    flag: "//upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 7,
    name: "Argentina",
    code: "+54",
    flag: "//upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 8,
    name: "Armenia",
    code: "+374",
    flag: "//upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 9,
    name: "Australia",
    code: "+61",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 10,
    name: "Austria",
    code: "+43",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 11,
    name: "Azerbaijan",
    code: "+994",
    callingCode: null,
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 12,
    name: "Bahamas",
    code: "+242",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 13,
    name: "Bahrain",
    code: "+973",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 14,
    name: "Bangladesh",
    code: "+880",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 15,
    name: "Barbados",
    code: "+246",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 16,
    name: "Belarus",
    code: "+375",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 17,
    name: "Belgium",
    code: "+32",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 18,
    name: "Belize",
    code: "+501",
    callingCode: null,
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 19,
    name: "Benin",
    code: "+229",
    callingCode: null,
    file_url: "//upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 20,
    name: "Bhutan",
    code: "+975",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 21,
    name: "Bolivia",
    code: "+591",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/d/de/Flag_of_Bolivia_%28state%29.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 22,
    name: "Bosnia and Herzegovina",
    code: "+387",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 23,
    name: "Botswana",
    code: "+267",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 24,
    name: "Brazil",
    code: "+55",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 25,
    name: "Brunei",
    code: "+673",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 26,
    name: "Bulgaria",
    code: "+395",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 27,
    name: "Burkina Faso",
    code: "+226",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 28,
    name: "Burundi",
    code: "+257",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 31,
    name: "Cambodia",
    code: "+855",
    callingCode: null,
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 32,
    name: "Cameroon",
    code: "+237",
    callingCode: null,
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 33,
    name: "Canada",
    code: "+1",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 30,
    name: "Cape Verde",
    code: "+238",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 34,
    name: "Central African Republic",
    code: "+236",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 35,
    name: "Chad",
    code: "+235",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 36,
    name: "Chile",
    code: "+56",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 37,
    name: "China",
    code: "+86",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 38,
    name: "Colombia",
    code: "+57",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 39,
    name: "Comoros",
    code: "+269",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 40,
    name: "Congo",
    code: "+242",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 41,
    name: "Costa Rica",
    code: "+506",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 29,
    name: "Côte d'Ivoire",
    code: "+225",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_C%C3%B4te_d%27Ivoire.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 42,
    name: "Croatia",
    code: "+385",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 43,
    name: "Cuba",
    code: "+53",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 44,
    name: "Cyprus",
    code: "+357",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 45,
    name: "Czech",
    code: "+420",
    callingCode: null,
    flag: "//upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 46,
    name: "Democratic Republic of the Congo",
    code: "+243",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 47,
    name: "Denmark",
    code: "+45",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 48,
    name: "Djibouti",
    code: "+253",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 49,
    name: "Dominica",
    code: "+767",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 50,
    name: "Dominican Republic",
    code: "+809",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 51,
    name: "Ecuador",
    code: "+593",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 52,
    name: "Egypt",
    code: "+20",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 53,
    name: "El Salvador",
    code: "+240",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 54,
    name: "Equatorial Guinea",
    code: "+263",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 55,
    name: "Eritrea",
    code: "+291",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 56,
    name: "Estonia",
    code: "+372",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 57,
    name: "Eswatini",
    code: "+268",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 58,
    name: "Ethiopia",
    code: "+251",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 59,
    name: "Fiji",
    code: "+679",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 60,
    name: "Finland",
    code: "+358",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 61,
    name: "France",
    code: "+33",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 62,
    name: "Gabon",
    code: "+241",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 63,
    name: "Gambia",
    code: "+220",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 64,
    name: "Georgia",
    code: "+995",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 65,
    name: "Germany",
    code: "+49",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 66,
    name: "Ghana",
    code: "+233",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 67,
    name: "Greece",
    code: "+30",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 68,
    name: "Grenada",
    code: "+473",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 69,
    name: "Guatemala",
    code: "+502",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 70,
    name: "Guinea",
    code: "+224",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 71,
    name: "Guinea-Bissau",
    code: "+245",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 72,
    name: "Guyana",
    code: "+592",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 73,
    name: "Haiti",
    code: "+509",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 74,
    name: "Holy See",
    code: "+379",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 75,
    name: "Honduras",
    code: "+504",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 76,
    name: "Hungary",
    code: "+36",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 77,
    name: "Iceland",
    code: "+354",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 78,
    name: "India",
    code: "+91",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 79,
    name: "Indonesia",
    code: "+62",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 80,
    name: "Iran",
    code: "+98",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 81,
    name: "Iraq",
    code: "+964",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 82,
    name: "Ireland",
    code: "+353",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 83,
    name: "Israel",
    code: "+972",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 84,
    name: "Italy",
    code: "+39",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 85,
    name: "Jamaica",
    code: "+876",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 86,
    name: "Japan",
    code: "+81",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 87,
    name: "Jordan",
    code: "+962",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 88,
    name: "Kazakhstan",
    code: "+7",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 89,
    name: "Kenya",
    code: "+254",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 90,
    name: "Kiribati",
    code: "+686",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 91,
    name: "Kuwait",
    code: "+965",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 92,
    name: "Kyrgyzstan",
    code: "+996",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 93,
    name: "Laos",
    code: "+856",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 94,
    name: "Latvia",
    code: "+371",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 95,
    name: "Lebanon",
    code: "+961",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 96,
    name: "Lesotho",
    code: "+266",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 97,
    name: "Liberia",
    code: "+231",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 98,
    name: "Libya",
    code: "+218",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 99,
    name: "Liechtenstein",
    code: "+423",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 100,
    name: "Lithuania",
    code: "+370",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 101,
    name: "Luxembourg",
    code: "+352",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 102,
    name: "Madagascar",
    code: "+261",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 103,
    name: "Malawi",
    code: "+265",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 104,
    name: "Malaysia",
    code: "+60",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 105,
    name: "Maldives",
    code: "+960",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 106,
    name: "Mali",
    code: "+223",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 107,
    name: "Malta",
    code: "+356",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 108,
    name: "Marshall Islands",
    code: "+692",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 109,
    name: "Mauritania",
    code: "+222",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 110,
    name: "Mauritius",
    code: "+230",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 111,
    name: "Mexico",
    code: "+52",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 112,
    name: "Micronesia",
    code: "+691",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 113,
    name: "Moldova",
    code: "+373",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 114,
    name: "Monaco",
    code: "+377",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 115,
    name: "Mongolia",
    code: "+976",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 116,
    name: "Montenegro",
    code: "+382",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 117,
    name: "Morocco",
    code: "+212",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 118,
    name: "Mozambique",
    code: "+258",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 119,
    name: "Myanmar",
    code: "+95",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 120,
    name: "Namibia",
    code: "+264",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 121,
    name: "Nauru",
    code: "+674",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 122,
    name: "Nepal",
    code: "+977",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 123,
    name: "Netherlands",
    code: "+31",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 124,
    name: "New Zealand",
    code: "+64",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 125,
    name: "Nicaragua",
    code: "+505",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 126,
    name: "Niger",
    code: "+227",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 127,
    name: "Nigeria",
    code: "+234",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 128,
    name: "North Korea",
    code: "+850",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 129,
    name: "North Macedonia",
    code: "+389",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 130,
    name: "Norway",
    code: "+47",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 131,
    name: "Oman",
    code: "+968",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 132,
    name: "Pakistan",
    code: "+92",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 133,
    name: "Palau",
    code: "+680",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 134,
    name: "Palestine",
    code: "+970",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 135,
    name: "Panama",
    code: "+507",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 136,
    name: "Papua New Guinea",
    code: "+675",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 137,
    name: "Paraguay",
    code: "+595",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 138,
    name: "Peru",
    code: "+51",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 139,
    name: "Philippines",
    code: "+63",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 140,
    name: "Poland",
    code: "+48",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 141,
    name: "Portugal",
    code: "+351",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 142,
    name: "Qatar",
    code: "+974",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 143,
    name: "Romania",
    code: "+40",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 144,
    name: "Russia",
    code: "+7",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 145,
    name: "Rwanda",
    code: "+250",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 146,
    name: "Saint Kitts and Nevis",
    code: "+869",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 147,
    name: "Saint Lucia",
    code: "+758",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 148,
    name: "Saint Vincent and the Grenadines",
    code: "+784",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 149,
    name: "Samoa",
    code: "+685",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 150,
    name: "San Marino",
    code: "+378",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 151,
    name: "Sao Tome and Principe",
    code: "+239",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 152,
    name: "Saudi Arabia",
    code: "+996",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 153,
    name: "Senegal",
    code: "+221",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 154,
    name: "Serbia",
    code: "+381",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 155,
    name: "Seychelles",
    code: "+248",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 156,
    name: "Sierra Leone",
    code: "+232",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 157,
    name: "Singapore",
    code: "+65",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 158,
    name: "Slovakia",
    code: "+421",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 159,
    name: "Slovenia",
    code: "+386",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 160,
    name: "Solomon Islands",
    code: "+677",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 161,
    name: "Somalia",
    code: "+252",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 162,
    name: "South Africa",
    code: "+27",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 163,
    name: "South Korea",
    code: "+82",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 164,
    name: "South Sudan",
    code: "+211",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 165,
    name: "Spain",
    code: "+34",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 166,
    name: "Sri Lanka",
    code: "+94",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 167,
    name: "Sudan",
    code: "+249",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 168,
    name: "Suriname",
    code: "+597",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 169,
    name: "Sweden",
    code: "+46",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 170,
    name: "Switzerland",
    code: "+41",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 171,
    name: "Syria",
    code: "+963",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 172,
    name: "Tajikistan",
    code: "+7",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 173,
    name: "Tanzania",
    code: "+255",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 174,
    name: "Thailand",
    code: "+66",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 175,
    name: "Timor-Leste",
    code: "+670",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 176,
    name: "Togo",
    code: "+228",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 177,
    name: "Tonga",
    code: "+676",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 178,
    name: "Trinidad and Tobago",
    code: "+868",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 179,
    name: "Tunisia",
    code: "+216",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 180,
    name: "Turkey",
    code: "+90",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 181,
    name: "Turkmenistan",
    code: "+993",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 182,
    name: "Tuvalu",
    code: "+688",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 183,
    name: "Uganda",
    code: "+256",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 184,
    name: "Ukraine",
    code: "+380",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 185,
    name: "United Arab Emirates",
    code: "+971",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 186,
    name: "United Kingdom",
    code: "+44",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 187,
    name: "United States of America",
    code: "+1",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 188,
    name: "Uruguay",
    code: "+598",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 189,
    name: "Uzbekistan",
    code: "+7",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 190,
    name: "Vanuatu",
    code: "+678",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 191,
    name: "Venezuela",
    code: "+58",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 192,
    name: "Vietnam",
    code: "+84",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 193,
    name: "Yemen",
    code: "+381",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 194,
    name: "Zambia",
    code: "+260",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 195,
    name: "Zimbabwe",
    code: "+263",
    callingCode: null,
    flag: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
];
export default countries;
