import { add } from "date-fns";

//login user endpoint
export const loginUser = "/auth/login/";
export const resetAuthUserPassword = "/auth/reset-password/";
export const changeAuthUserPassword = "/auth/change-password?token=";
export const refreshTokenEndpoint = "auth/token/refresh/";

// termination and absence
export const addEmployeeInfo = "/hr/employees/add-personal-info/";
export const getEmployeeContactInfo =
    "/hr/employees/get-employee-personal-contact-info/";
export const employeeTermination = "/hr/employee/add-termination/";
export const employeeAbsence = "/hr/employee/add-employee-absence/";
export const getEmployeeAbsence = "/hr/employee/get-employee-absences/";
export const getAllEmployeeAbsent = "/hr/employee/get-all-absences/";
export const getAllEmployeeAbsentByTeamLeader =
    "/hr/employee/get-all-absences-by-team-leader/";
export const deleteAbsence = "/hr/employee/delete-absence/";

//employee signature

export const uploadEmployeeSignature = "/hr/user/upload-signature/";
export const getEmployeeSignature = "/hr/user/retrieve-signature/";

//announcements endpoint
export const getMyAnnouncements = "/hr/announcements/";
export const saveAnnouncementAsDraft = "/hr/announcements/";
export const saveAndSendAnnouncement = "/hr/announcements/send/";
export const updateAnnouncement = "/hr/announcements/modification/";
export const updateAndSendAnnouncement = "/hr/announcements/send/";
export const deleteAnnouncement = "/hr/announcements/modification/";

// project modules endpoints
export const addOrUpdateProject = "/projects/project/add-project/";
export const fetchAllProject = "/projects/project/all-projects/";
export const fetchSingleProject = "/projects/project/single-project/";
export const searchProject = "/projects/project/search-projects/";
export const deleteProject = "/projects/project/archive-project/";
export const deleteMultipleProjects = "/projects/project/archive-projects/";


// project  files
export const addProjectFile = "/projects/project/project-save-files/";
export const fetchSingleProjectFile = "/projects/project/files/";
export const fetchAllProjectFile = "";
export const manageProjectFile = "/projects/project/manage-files/";
export const deleteFile = "/projects/project/delete-upload/";
export const updateFile = "/projects/project/project-update-addenda/";
export const fetchProjectMangers = "/hr/employees/get-project-managers/";

// project contract

// project contract
export const addOrUpdateContractDetails =
    "/projects/project/add-contract-details/";
export const fetchContractDetails =
    "/projects/project/contract/get-contract-details/";
export const addOrUpdatePartnerWPS =
    "/projects/project/contract/add-partner-with-profit-share/";
export const fetchAllPartnerWPS =
    "/projects/project/contract/get-all-partners-with-profit-share/";
export const deletePartnerWPS =
    "/projects/project/contract/delete-partner-with-profit-share/";
export const fetchSinglePartnerWPS =
    "/projects/project/contract/get-single-partner-with-profit-share/";
export const addOrUpdateBankGuarantee =
    "/projects/project/contract/add-bank-guarantee/";
export const fetchSingleBankGuarantee =
    "/projects/project/contract/get-single-bank-guarantee/";
export const addOrUpdateAdvancePayment =
    "/projects/project/contract/add-advance-payment/";
export const deleteAdvancePayment =
    "/projects/project/contract/delete-advance-payment/";
export const fetchSingleAdvancePayment =
    "/projects/project/contract/get-single-advance-payment/";
export const fetchAllAdvancePayment =
    "/projects/project/contract/get-all-advance-payments/";
export const addOrUpdateProjectNote =
    "/projects/project/contract/add-project-note/";
export const addOrUpdateInterimPayment =
    "/projects/project/contract/add-interim-payment/";
export const fetchInterimPayment =
    "/projects/project/contract/get-all-interim-payments/";
export const deleteInterimPayment =
    "/projects/project/contract/delete-interim-payment/";

// project budget
export const addOrUpdateBudgetSection = "/projects/project/add-budget-section/";
export const deleteBudgetSection = "/projects/project/delete-budget-section/";
export const fetchAllBudgetSection =
    "/projects/project/get-project-budget-sections/";
export const fetchSingleBudgetSection =
    "/projects/project/get-single-budget-section/";
export const addOrUpdateBudgetItem = "/projects/project/add-budget-item/";
export const deleteBudgetItem = "/projects/project/delete-budget-item/";
export const fetchSingleBudgetItem = "/projects/project/get-budget-item/";
export const postBudgetSectionUrl = "/projects/project/budget-section/";
export const patchBudgetSectionUrl = "/projects/project/budget-section/";
export const deleteBudgetSectionUrl = "/projects/project/budget-section/";
export const updateBudgetSectionLineItemUrl =
    "/projects/project/project-budget-section/";
export const getAllBudgetSectionUrl =
    "/projects/project/project-budget-section/";

// project local budget
export const getAllProjectLocalBudgetUrl = "/projects/local_budget/";
export const putProjectLocalBudgetUrl = "/projects/local_budget/";

// Leaves management
export const employeeRequestLeave = "/hr/employee/request-leave/";
export const getSingleLeave = "/hr/employee/get-leave/";
export const getEmployeeLeave = "/hr/employee/get-employee-leaves/";
export const getUnpaginatedData = "/hr/employee/unpaginated-employee-leaves/";
export const getEmployeeLeaveByHrOrApprover = "/hr/hr/get-employee-leaves/";
export const approverLeaveApproval = "/hr/approver/leaves-approval/";
export const hrLeaveApproval = "/hr/hr/leaves-approval/";
export const hrLeaveRequest = "/hr/hr/create-employee-leave/";
export const getAllPendingLeaves = "/hr/hr/get-pending-employee-leaves/";
export const setApprover = "/hr/set-employee-leave-approver/";
export const getDefaultApprover = "/hr/get-user-leave-approver/";
export const editLeave = "/hr/employee/update-leave-request/";
export const filterLeaves = "/hr/hr/filter-all-leaves/";
export const getRecipientAnnouncements =
    "/hr/announcements/recipient-announcements/";
export const viewRecipientAnnouncements =
    "/hr/announcements/view-announcement/";
export const publicHolidays =
    "/configuration/public-holiday/get-all-public-holidays/";
export const searchPublicHolidays =
    "/configuration/public-holiday/search-public-holiday/";

//project configurations contract tasks

export const postContractTaskUrl = "/configuration/projects/add-contract-task/";
export const getAllContractTaskUrl = "/configuration/projects/get-tasks/";
export const putContractTaskUrl =
    "/configuration/projects/manage-contract-task/";
export const getOneContractTaskUrl =
    "/configuration/projects/manage-contract-task/";
export const deleteContractTaskUrl =
    "/configuration/projects/manage-contract-task/";

export const searchContractTaskUrl =
    "/configuration/projects/search-contract-tasks/";

// User Documents
export const getUserDocumentsUrl = "/hr/expert/documents/get-documents/";
export const manageUserDocumentsUrl = "/hr/expert/documents/manage-documents/";

//project configurations contract approvers
export const getAllEmployeesUrl = "/configuration/projects/list-hr-employees";

export const getAllContractApproversUrl =
    "/configuration/projects/get-contract-approvers/";

export const postContractApproversUrl =
    "/configuration/projects/add-contract-approvers/";

// Contract modules endpoint
export const getAllProjectContract =
    "/projects/project/employee-contracts/get-contracts/";
export const getAllProjectContracts =
    "/projects/project/employee-contracts/manage-contract/";
export const deleteContractEndpoint =
    "/projects/project/employee-contracts/manage-contract/";

// contract addenda
export const getContractAddendumInfo =
    "projects/project/employee-contracts/get-single-addendum/";
export const getAddendumDeliverables =
    "projects/project/employee-contracts/get-addendum-deliverables/";

//contract travel
export const addNewTravelItem =
    "/projects/project/employee-contracts/add-travel/";
export const updateTravel =
    "/projects/project/employee-contracts/manage-travel/";
export const getSingleTravel =
    "/projects/project/employee-contracts/manage-travel/";
export const deleteTravelItem =
    "/projects/project/employee-contracts/manage-travel/";
export const getContractTravels =
    "/projects/project/employee-contracts/get-contract-travels/";

// freelancer Deliverables
export const addDeliverablesInformation =
    "/projects/project/employee-contracts/add-deliverable-information/";
export const deleteDeliverable =
    "/projects/project/employee-contracts/delete-project-deliverable/";
export const getSingleDeliverable =
    "/projects/project/employee-contracts/get-project-deliverable/";
export const addOrUpdateProjectDeliverable =
    "/projects/project/employee-contracts/add-update-project-deliverable/";
export const getDeliverablePerContract =
    "/projects/project/employee-contracts/get-project-deliverables/";

// basic information
export const addBasicInformation =
    "/projects/project/employee-contracts/add-contract-basic-info";

// Project funds
export const addProjectFund =
    "/projects/project/employee-contracts/add-update-project-fund/";
export const getProjectFund =
    "/projects/project/employee-contracts/get-project-funds/";
export const deleteProjectFund =
    "/projects/project/employee-contracts/delete-project-fund/";
export const updateFunds =
    "/projects/project/employee-contracts/get-project-funds/employment/";

// link budget item
export const linkBudgetItem =
    "projects/project/employee-contracts/link-to-budget-item/";

// project other allowance
export const addOrUpdateOtherAllowance =
    "/projects/project/employee-contracts/add-update-other-allowance/";
export const getOtherAllowance =
    "/projects/project/employee-contracts/get-contract-allowances/";
export const getSingleAllowance =
    "/projects/project/employee-contracts/get-contract-allowance/";
export const deleteOtherAllowance =
    "/projects/project/employee-contracts/delete-other-allowance/";

export const getLinkedBudgetItem =
    "/projects/project/employee-contracts/get_linked_budget_items/";
export const getTravelCountry =
    "/projects/project/employee-contracts/get-travels-unpaginated/";
export const getsingleLinkedItem =
    "/projects/project/employee-contracts/get-travel-links-data/";

// send for approval
export const sendForApproval =
    "/projects/project/employee-contracts/send-contract-approval/";

//delete basic information file
export const deleteBasicInformationFile =
    "/projects/project/employee-contracts/delete-contract-file/";
// get addenda
export const getAddenda = "/projects/project/employee-contracts/get-addenda/";
export const sendAddenda = "/projects/contract/create-addendum/";
export const deliverableAddenda =
    "/projects/project/employee-contracts/add-deliverable-for-addendum/";
export const travelAddenda =
    "/projects/project/employee-contracts/add-travel-for-addendum/";
export const getTravelAddenda =
    "projects/project/employee-contracts/get-addendum-travels/";
export const approveAddenda =
    "/projects/project/employee-contracts/send-addendum-for-approval/";
export const ceoSignsture =
    "/projects/project/employee-contracts/ceo-signature/";
export const previewApproval =
    "/projects/project/employee-contracts/contract-approval/";
export const saveContractDocument =
    "/projects/project/employee-contracts/save-contract-document/";
export const getDocument =
    "/projects/project/employee-contracts/save-contract-document/";
export const sendExpertMail =
    "/projects/project/employee-contracts/send-email-to-expert/";
export const pmSign =
    "/projects/project/employee-contracts/project-manager-signature/";
export const expertSign =
    "/projects/project/employee-contracts/expert-signature/";
export const unrestrictedContract =
    "/projects/project/employee-contracts/get-single-contract-unrestricted/";
//mission endpoints
export const createMissionPlan = "/projects/project/missions/";
export const getMissionsPaginated = "/projects/project/missions/";
export const getSingleMission = "/projects/project/mission/";
export const updateMission = "/projects/project/mission/";
export const getDeploymentAgreement =
    "/projects/project/mission/deployment-agreement/";
export const searchMissionsEndpoint = "/projects/project/missions/search/";
export const getApprovedContracts =
    "/projects/project/employee-contracts/get-approved-contracts/";
//double taxation data
export const getAllDoubleTaxation =
    "/configuration/projects/double-taxation-status/";
export const addDoubleTaxation =
    "/configuration/projects/bulk-update-taxation-status/";
export const getSingleDoubleTaxation =
    "/configuration/projects/get-single-double-taxation-status/";

// project foreign establishment
export const getProjectForeignEstablishmentEndpoint =
    "/projects/project/foreign-establishment/";
export const getCountryWithForeignOffice =
    "/projects/foreign-office/get-country-foreign-offices/";
export const getAllSignedContractExpert =
    "/projects/project/employee-contracts/get-all-contracts/";
export const createForeignEstablishment =
    "/projects/project/foreign-establishment/";
export const updateForeignEstablishment =
    "/projects/project/foreign-establishment/establishment/";
export const approveEstablishment =
    "/projects/project/foreign-establishment/approve-request/";

// foreign offices endpoints
export const createForeignOffice =
    "/projects/foreign-office/add-foreign-office/";
export const UdateForeignOffice =
    "/projects/foreign-office/edit-foreign-office/";
export const getAllForeignOffice =
    "/projects/foreign-office/get-all-foreign-offices/";
export const getSingleForeignOffice =
    "/projects/foreign-office/get-single-foreign-office/";
export const deleteForeignOffice =
    "/projects/foreign-office/delete-foreign-office/";
export const createOfficeStaff = "/projects/foreign-office/add-office-staff/";
export const deleteOfficeStaff =
    "/projects/foreign-office/delete-office-staff/";
export const getAllOfficeStaff =
    "/projects/foreign-office/get-all-office-staff/";
export const getProjectForeignOfficesEndpoint = "/projects/project/offices/";
export const updateForeignOffice =
    "/projects/foreign-office/edit-foreign-office/";

export const searchForeignOffice =
    "/projects/foreign-office/search-foreign-offices/";
export const searchForeignEmployees =
    "/projects/foreign-office/search-foreign-employees/";
export const filterForeignEmployees =
    "/projects/foreign-office/filter-foreign-employees/";

export const updateOfficeStaff =
    "/projects/foreign-office/update-office-staff/";

export const getAllForeignEmployees =
    "/projects/foreign-office/get-all-foreign-employees/";
export const getAllBanks = "/configuration/projects/all-banks-unpaginated/";
export const getAllEmployees =
    "/projects/foreign-office/foreign-staff-dropdown/";

// PM Local endpoints
export const getAllPmlocals = "/projects/pm-local/all-projects/";
export const getSinglePmlocal =
    "/projects/project/pm-local/add-or-get-single-project/";
export const updatePmlocal =
    "/projects/project/pm-local/add-or-get-single-project/";
export const searchPmlocals = "/projects/pm-local/projects/search/";
export const getAllCurrencies = "/projects/project/pm-local/currencies/";
export const addCurrency = "/projects/project/pm-local/currencies/";
export const getAllClientRate = "/projects/project/pm-local/client-rate/";
export const updateClientRate = "/projects/project/pm-local/client-rate/";
export const getSingleClientRate =
    "/projects/project/pm-local/manage-client-rate/";
export const getAllBudgetDefinition =
    "/projects/project/pm-local/budget-definitions/";
export const updateBudgetDefinition =
    "/projects/project/pm-local/budget-definitions/";
export const getSingleCurrency = "/projects/pm-local/manage-currency/";
export const updateSingleCurrency = "/projects/pm-local/manage-currency/";
export const deleteSingleCurrency = "/projects/pm-local/manage-currency/";
export const deleteSingleClientRate =
    "/projects/project/pm-local/manage-client-rate/";
export const getAllExpertsNonPaginated =
    "/projects/project/employee-contracts/get-all-experts/Expert/";
export const getAllLanguagesNonPaginated =
    "/configuration/general/get-general/Languages/";
export const getAllRequestedFundUrl =
    "/projects/pm_local/foreign-establishment-funds/";
export const getOneRequestedFundUrl = "/projects/pm_local/project-fund/";
export const getRequestedFundStatsUrl =
    "/projects/pm_local/foreign-establishment-funds-stats/";
export const postRequestedFundsUrl =
    "/projects/pm_local/foreign-establishment-funds/";
export const getOneRequestedFundForApprovalUrl =
    "/projects/pm_local/project-fund-model-data/";
export const postOneRequestedFundForApprovalUrl =
  "/projects/pm_local/project-fund-approval/";
export const getAllPMLocalCashToolProjects ="/projects/pm-local/list-all-projects-with-pm-local-cash-tool/"
// Project Cheques

export const getAllCheques = "/projects/cash-tool/get-all-cheques/";
export const getSingleCheque = "/projects/cash-tool/get-single-cheques/";
export const addCheques = "/projects/cash-tool/add-cheque/";
export const currencyBudgetPosition =
    "/projects/cash-tool/currency-budget-dropdown/";
export const deleteProjectCheque = "/projects/cash-tool/delete-cheque/";
export const deleteAllProjectCheques =
    "/projects/cash-tool/delete-all-cheques/";
export const payInCheque = "/projects/cash-tool/pay-in-cheque/";
export const uploadChequeFile = "/projects/cash-tool/upload-cheque-file/";
export const updateCheque = "/projects/cash-tool/update-cheque/";

//bank/cash transfer
export const getBankCah = "/projects/pm-local/bank-cash-transfers/";
export const addBankCah = "/projects/pm-local/bank-cash-transfers/add/";
export const manageBankCah = "/projects/pm-local/bank-cash-transfers/manage/";
// export const editBankCah="/projects/pm-local/bank-cash-transfers/manage/"

// currency transaction
export const getCurrencyTransaction =
    "/projects/pm-local/currency-transactions/";
export const AddExchange = "/projects/pm-local/currency-transactions/add/";
export const manageExchange =
    "/projects/pm-local/currency-transactions/manage/";
export const currencyMonthRate =
    "/projects/pm-local/currency-transactions/get-rate-months/";
export const getSingleExchange =
    "/projects/pm-local/currency-transactions/get-rate-data/";
export const exchangeFile =
    "/projects/pm-local/currency-transactions/upload-file/";

// Project Expenses

export const getAllExpenses = "/projects/cash-tool/get-all-expenses/";
export const getSingleExpense = "/projects/cash-tool/get-single-expense/";
export const addExpense = "/projects/cash-tool/add-expense/";
export const deleteExpense = "/projects/cash-tool/delete-expense/";
export const uploadExpenseFile = "/projects/cash-tool/upload-expense-file/";
export const updateExpense = "/projects/cash-tool/update-expense/";

// pmlocal Project overview
export const getPmLocalOverview = "/projects/cash-tool/total-budget/";
export const getYearlyOverview = "/projects/cash-tool/yearly-budget/";
export const getMonthlyOverview = "/projects/cash-tool/monthly-budget/";
export const lockPmLocalMonth = "/projects/cash-tool/lock-month/";
export const unlockPmLocalMonth = "/projects/cash-tool/unlock-month/";

// flight request
export const getAllFlights =
    "/accounting/flight-request/get-all-flight-requests/";
export const addFlightRequest =
    "/accounting/flight-request/add-flight-request/";
export const getSingleFlightRequest =
    "/accounting/flight-request/get-flight-request/";
export const updateFlightRequest =
    "/accounting/flight-request/update-flight-request/";
export const deleteFlightRequest =
    "/accounting/flight-request/delete-flight-request/";
export const costCentersEndpoint =
    "/accounting/flight-request/get-cost-centres/";
export const getFlightDropdowns =
    "/accounting/flight-request/get-flight-request-dropdowns/";
export const getExpertContracts =
    "/accounting/flight-request/get-expert-contracts/";
export const payingCompany = "configuration/general/get-general/Companies/";
export const searchFlight =
    "/accounting/flight-request/search-flight-requests/";
export const getFirmExperts = "/accounting/flight-request/get-firm-experts/";
export const sendEmail = "/accounting/flight-request/send-email-to-agency/";
export const getOpenInvoices = "/accounting/get-open-invoices/";
export const addFlightSettlement =
    "/accounting/flight-request/add-flight-settlement/";
export const getEmployeeFlightRequests =
    "/accounting/flight-request/get-user-flight-requests/";
export const getFlightRequesDetails =
    "/accounting/flight-request/get-flight-signing-details/";
export const signFlightRequest =
    "/accounting/flight-request/sign-flight-request/";

// hospitality package endpoints

export const getAllHospitalityPackage =
    "/hr/hospitality/list-hospitality-requests/";
export const getSingleHospitalityPackage =
    "/hr/hospitality/manage-hospitality-request/";
export const createHospitalityPackage =
    "/hr/hospitality/add-hospitality-request/";
export const updateHospitalityPackage =
    "/hr/hospitality/manage-hospitality-request/";
export const deleteHospitalityPackage =
    "/hr/hospitality/manage-hospitality-request/";
export const searchHospitalityPackages =
    "/hr/hospitality/search-hospitality-requests/";
export const settleHospitalityPackages =
    "/hr/hospitality/settle-hospitality-request/";
export const getBudgetItems =
    "/hr/hospitality/get-cost-center-related-budget-items/";

export const signatureRequest = "/hr/hospitality/request-signature/";

// advancement payment request endpoints
export const getAllAdvancementPaymentRequest =
    "/accounting/advance-payment-request/list-advance-payment-requests/";
export const getSingleAdvancementPaymentRequest =
    "/accounting/advance-payment-request/manage-advance-payment-request/";
export const createAdvancementPaymentRequest =
    "/accounting/advance-payment-request/add-advance-payment-request/";
export const updateAdvancementPaymentRequest =
    "/accounting/advance-payment-request/manage-advance-payment-request/";
export const deleteAdvancementPaymentRequest =
    "/accounting/advance-payment-request/manage-advance-payment-request/";
export const searchAdvancementPaymentRequest =
    "/accounting/advance-payment-request/search-advance-payment-requests/";
export const getAllEmployeesNonPaginated =
    "/projects/project/employee-contracts/get-all-experts/";

//Endpoints for invoicing and settlements
export const createExpertMissionInvoice = "/accounting/invoices/expert-create-mission-invoice/"
export const getExpertMissionPlanningInvoices = "/accounting/invoices/get-expert-mission-planning-invoices/";
export const getMissionPlanningInvoice = "/accounting/invoices/get-mission-planning-invoice/";
export const getInvoiceDetailsForSettlement = "/accounting/invoices/generate-mission-planning-invoice/";

export const getAllLocalExperts = "/projects/cash-tool/get-all-expert-projects/"
export const searchExpertProject = "/projects/cash-tool/search-expert-projects/"
export const getAllLocalContracts = "/projects/cash-tool/get-all-project-contracts/"
export const searchProjectContract = "/projects/cash-tool/search-project-contracts/"
export const getAllExpertMission = "/projects/cash-tool/get-all-expert-missions/"
export const searchExpertMission = "/projects/cash-tool/search-expert-missions/"


export const getAllExpertOrCompanyContract =
    "/accounting/advance-payment-request/get-expert-assigned-contracts/";
export const uploadResquestFile =
    "/configuration/file-uploads/request-file-uploads/";
export const advancePaymentSignatureRequest =
    "/accounting/advance-payment-request/request-expert-signature/";

export const settleAdvancementPaymentRequest ="/accounting/advance-payment-request/advance-payment-request-settlement/"




//Incoming Invoice endpoints
export const getIcomingInvoices = "/accounting/invoices/icoming-invoices/";
export const deletTemporaryInvoices = "/accounting/invoices/icoming-invoices/"
export const addIncomingInvoice = "/accounting/invoices/icoming-invoices/";
export const allInvoices = "/accounting/invoices/";
export const addInvoices = "/accounting/invoices/";
export const invoiceMarkedAsSeen ="/accounting/invoices/mark-new-invoice-as-seen/"
export const getAllInvoices = "/accounting/invoices/";
export const uploadInvoiceFile = "/accounting/invoices/upload_file/";
export const deleteInvoiceFile = "/accounting/invoices/delete_file/";

//PROJECT HOURS ENDPOINTS
export const getAllProjectHours = "/projects/project-hours/get-all-project-hours/"
export const lockAndUnlockProjectHours = "/projects/project-hours/lock-unlock-project-hours/"
export const getProjectHoursDetails = "/projects/project-hours/get-user-project-hours-details/"
export const createProjectHour = '/projects/project-hours/create-update-project-hours/'
