<template>
  <div>
    <div class="py-2 label">
      <label class="text-sm text-[#344054] font-medium">{{ label }}</label>
    </div>
    <div>
      <textarea
        rows="3"
        class="w-full p-2 text-gray-800 border rounded-lg resize-none outline-none"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: " App text area",
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
});
</script>

<style scoped>

</style>
