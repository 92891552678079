<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="flex gap-4 mb-6" v-if="filterBy !== 'Total'">
        <div v-for="radButton in cashOrBank" :key="radButton.text">
          <div class="flex items-center SPC-MT-100">
            <input
              type="radio"
              :name="radButton.text"
              :value="radButton.value"
              :checked="radButton.value === selectedType"
              v-model="selectedType"
              :disabled="shouldDisableInput ? 'disabled' : null"
            />
            <label class="pl-2 text-sm font-medium text-gray-700">{{
              radButton.text
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="flex items-center !justify-end gap-4 mb-6 pr-4"
        style="flex-grow: 1"
      >
        <div class="flex" v-for="currency in currencies" :key="currency.id">
          <app-radio-button
            :name="currency.currency"
            v-model="selectedCurrency"
            :value="selectedCurrency"
            :label="currency.currency"
            @change="$emit('selected-currency', selectedCurrency)"
            :disabled="shouldDisableInput ? 'disabled' : null"
          >
          </app-radio-button>
        </div>
      </div>
    </div>
    <app-table
      :headers="headers"
      :items="tableItems"
      :hideMenu="true"
      :totals="flattenedTotals"
      :isTotalExpenses="true"
      :isFilter="true"
    >
      <template #title>
        <h3 class="H500">{{ $t("projectOverview.budgetOverview") }}</h3>
      </template>
      <template #filter v-if="filterBy === 'Monthly'">
        <filter-table-list @monthChanged="isSelectedMonth"></filter-table-list>
      </template>
      <template #actions>
        <app-single-select
          v-if="filterBy !== 'Total'"
          :isLabel="false"
          :isTranslation="false"
          v-model="filterByYear"
          :options="filterByYearOptions"
          :disabled="shouldDisableInput ? 'disabled' : null"
          class="!w-[120px]"
        >
        </app-single-select>
        <app-single-select
          :isLabel="false"
          v-model="filterBy"
          :options="filterOptions"
          :disabled="shouldDisableInput ? 'disabled' : null"
          class="!w-[120px]"
          :isTranslation="true"
        >
        </app-single-select>
      </template>
    </app-table>
  </div>
</template>

<script>
import { currencyBudgetPosition } from "@/services/endpoints";
import FilterTableList from "./FilterTableList.vue";
import Api from "@/services/api";
import { formatAmount } from "@/services/helpers";
export default {
  name: "ProjectOverviewList",
  components: { FilterTableList },
  data() {
    return {
      cash: "",
      bank: "",
      filterBy: "Total",
      filterByYear: new Date().getFullYear(),
      headers: [],
      individualMonthHeaders: [
        "Budget Item",
        "Budget Position",
        "Doc. Date",
        "Pay Date",
        "Supplier",
        "Currency",
        "Files",
        "Amt Excl. Tax",
        "Amt Incl. Tax",
        "Amt(EUR) Excl. Tax",
        "Amt(EUR) Incl. Tax",
      ],
      monthlyHeaders: [
        "Budget Item",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "Year's Total",
      ],
      TotalHeaders: [
        "Budget Item",
        "Quantity",
        "Unit Amt",
        "Plan Total",
        "Plan Monthly",
        "Yearly",
        "Total",
        "Remaining Amt",
      ],
      currencies: [],
      cashOrBank: [
        { text: "Cash", value: "cash" },
        { text: "Bank", value: "bank" },
      ],
      selectedCurrency: null,
      selectedType: null,
      selectedMonth: null,
    };
  },
  watch: {
    selectedCurrency: function (val) {
      if (this.filterBy === "Total") {
        this.getAllPmLocalOverviews(val);
      } else if (this.filterBy === "Monthly") {
        this.getIndividualMonthPmLocalOverview({
          year: this.filterByYear,
          currency: val,
          expense_type: this.selectedType,
          month: this.selectedMonth || this.getMonth(),
        });
      } else {
        this.getDivisionOverview({
          year: this.filterByYear,
          currency: val,
          expense_type: this.selectedType,
        });
      }
    },
    filterBy: function (val) {
      if (val === "Total") {
        this.headers = this.TotalHeaders;
        this.getAllPmLocalOverviews(this.selectedCurrency);
      } else if (val === "Yearly") {
        this.headers = this.monthlyHeaders;
        this.getDivisionOverview({
          year: this.filterByYear,
          currency: this.selectedCurrency,
          expense_type: this.selectedType,
        });
      } else {
        this.headers = this.individualMonthHeaders;
        this.getIndividualMonthPmLocalOverview({
          year: this.filterByYear,
          currency: this.selectedCurrency,
          expense_type: this.selectedType,
          month: this.getMonth(),
        });
      }
      this.$emit("update:filterBy", val);
    },
    selectedType: function (val) {
      if (this.filterBy === "Monthly") {
        this.getIndividualMonthPmLocalOverview({
          year: this.filterByYear,
          currency: this.selectedCurrency,
          expense_type: val,
          month: this.selectedMonth || this.getMonth(),
        });
      } else {
        this.getDivisionOverview({
          year: this.filterByYear,
          currency: this.selectedCurrency,
          expense_type: val,
        });
      }
    },
    filterByYear: function (val) {
      this.getDivisionOverview({
        year: val,
        currency: this.selectedCurrency,
        expense_type: this.selectedType,
      });
      this.$store.commit("projectOverview/SET_YEAR_TO_STATE", val);
    },
  },
  computed: {
    filterOptions() {
      return [
        { text: "Total", value: "Total" },
        { text: "Yearly", value: "Yearly" },
        { text: "Monthly", value: "Monthly" },
      ];
    },
    filterByYearOptions() {
      return this.getYearsFromProject(
        this.isOverViewStartDate,
        this.isOverViewEndDate
      );
    },

    isAllProjectOverview() {
      return this.$store.getters["projectOverview/isAllOverviews"];
    },
    isTotalsOverview() {
      return this.$store.getters["projectOverview/isTotalsOverview"];
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    isProjectId() {
      return this.$store.getters["pmLocalModules/isProjectId"];
    },
    isOverViewStartDate() {
      return this.$store.getters["projectOverview/isOverViewStartDate"];
    },
    isOverViewEndDate() {
      return this.$store.getters["projectOverview/isOverViewEndDate"];
    },
    isTransformedYearlyData() {
      return this.$store.getters["projectOverview/isAllOverviews"];
    },
    isAllTransformedYearlyData() {
      return this.$store.getters["projectOverview/isAllOverviews"];
    },

    isProcessedObject() {
      let years = [];
      this.isAllProjectOverview.forEach((item) => {
        if (item.yearly_data) {
          years = Object.keys(item.yearly_data);
        }
      });
      years = [...new Set(years)].sort();
      this.modifyHeaders(this.TotalHeaders, years);

      return this.isAllProjectOverview.map((item) => {
        let formatter = this.flattenYears(item);
        const formatted_yearly_data = {};
        Object.keys(item.yearly_data || {}).forEach((year) => {
          formatted_yearly_data[year] = this.amountFormatting(
            item.yearly_data[year]
          );
        });

        return { ...formatter, ...formatted_yearly_data };
      });
    },
    flattenedTotals() {
      return this.isTotalsOverview.map((item) => {
        let formatter = this.flattenYears(item);
        const formatted_yearly_data = {};
        Object.keys(item.yearly_data || {}).forEach((year) => {
          formatted_yearly_data[year] = this.amountFormatting(
            item.yearly_data[year]
          );
        });

        return { ...formatter, ...formatted_yearly_data };
      });
    },
    tableItems() {
      if (this.headers === this.TotalHeaders) {
        return this.isProcessedObject;
      } else if (this.headers === this.monthlyHeaders) {
        return this.isTransformedYearlyData;
      } else {
        return this.isAllTransformedYearlyData;
      }
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
  },
  methods: {
    handleFilterChange(newValue) {
      this.$emit("filter-change", newValue);
    },
    getMonth() {
      const currentDate = new Date();
      return new Intl.DateTimeFormat("en-US", { month: "long" })?.format(
        currentDate
      );
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    getYearsFromProject(startDate, endDate) {
      const startYear = new Date(startDate).getFullYear();
      const endYear = new Date(endDate).getFullYear();
      const years = [];
      for (let i = startYear; i <= endYear; i++) {
        years.push({ text: i, value: i });
      }
      return years;
    },
    isSelectedMonth(val) {
      this.selectedMonth = val;
      this.getIndividualMonthPmLocalOverview({
        year: this.filterByYear,
        currency: this.selectedCurrency,
        expense_type: this.selectedType,
        month: this.selectedMonth,
      });
      this.$store.commit(
        "projectOverview/SET_MONTH_TO_STATE",
        this.selectedMonth
      );
      this.$emit("lock-data", {
        month: this.selectedMonth || this.getMonth(),
        year: this.filterByYear || new Date().getFullYear(),
      });
    },
    getCurrency() {
      Api()
        .get(`${currencyBudgetPosition}3/${this.isForeignEstablishmentId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.currencies = response.data.data;
            this.$store.commit(
              "projectOverview/SET_DEFAULT_CURRENCY_TO_STATE",
              this.currencies[0]?.currency
            );
            this.selectedCurrency = this.currencies[0]?.currency;
            this.getAllPmLocalOverviews(this.selectedCurrency);
          }
        })
        .catch();
    },
    getAllPmLocalOverviews(data) {
      this.$store.dispatch("projectOverview/getAllPmLocalOverviews", {
        projectId: this.isProjectId,
        foreignEstablishmentId: this.isForeignEstablishmentId,
        currency: data,
      });
    },
    getDivisionOverview(data) {
      this.$store.dispatch("projectOverview/getPmLocalYearOverviews", {
        projectId: this.isProjectId,
        foreignEstablishmentId: this.isForeignEstablishmentId,

        currency: data.currency,
        year: data.year,
        expense_type: data.expense_type,
      });
    },
    getIndividualMonthPmLocalOverview(data) {
      this.$store.dispatch("projectOverview/getPmLocalMonthlyOverviews", {
        projectId: this.isProjectId,
        foreignEstablishmentId: this.isForeignEstablishmentId,
        ...data,
      });
    },
    flattenYears(itemOrArray) {
      const flattenItem = (obj) => {
        if (obj.yearly_data) {
          const flattened = { ...obj, ...obj.yearly_data };
          delete flattened.yearly_data;
          return flattened;
        }
        return obj;
      };

      if (Array.isArray(itemOrArray)) {
        return itemOrArray.map((item) => flattenItem(item));
      } else {
        return flattenItem(itemOrArray);
      }
    },

    modifyHeaders(headers, yearsArray) {
      const index = headers.indexOf("Yearly");
      if (index !== -1) {
        headers.splice(index, 1, ...yearsArray);
      }
      return headers;
    },
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    const startYear = new Date(this.isOverViewStartDate).getFullYear();
    const endYear = new Date(this.isOverViewEndDate).getFullYear();

    if (currentYear < startYear) {
      this.filterByYear = startYear;
    } else if (currentYear > endYear) {
      this.filterByYear = endYear;
    }
    this.getCurrency();
    this.selectedType = this.cashOrBank[0].value;
    this.getDivisionOverview({
      year: this.filterByYear,
      currency: this.selectedCurrency,
      expense_type: this.selectedType,
    });
    this.$emit("lock-data", {
      month: this.selectedMonth || this.getMonth(),
      year: this.filterByYear || new Date().getFullYear(),
    });
  },
  created() {
    this.headers = this.TotalHeaders;
  },
};
</script>

<style scoped>
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;
}

/* middle fill color */
input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* selected radio button */
input[type="radio"]:checked::before {
  background-color: #ae6a65;
  border: 2px solid #ae6a65;
  width: 8px;
  height: 8px;
}
input[type="radio"]:checked {
  background-color: #f4eae9;
  border: 2px solid #ae6a65;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  outline: none;

  position: relative;
}
input[type="radio"]:disabled {
  cursor: default;
}
</style>
