<template>
  <div class="container">
    <v-col cols="12">
      <div>
        <!-- education -->
        <div class="H500 SPC-MT-400"></div>
        <div class="flex inputs gap-7">
          <div class="w-[440px]">
            <app-select-field-object
              placeholder="Select employment type"
              v-model="employment_type"
              :label="$t('employment.employmentType') + '*'"
              :options="staffOptions"
              type="text"
              :disabled="
                currentPath === '/edit-employee' &&
                isEditEmployee &&
                !isEditButton
              "
              id="employmentType"
              data-cy="employmentType"
            />
          </div>
          <div class="w-[440px] flex flex-column">
            <app-select-field-object
              placeholder="Select probation period"
              v-model="probation_period"
              :label="$t('employment.probationPeriod')"
              :options="probationOptions"
              type="text"
              :disabled="
                currentPath === '/edit-employee' &&
                isEditEmployee &&
                !isEditButton
              "
              id="probationPeriod"
              data-cy="probationPeriod"
            />
            <p
              v-if="
                probation_period_date !== null && probation_period_date !== ''
              "
            >
              {{ $t("employment.probationEndsOn") }} {{ formattedDate }}
            </p>
          </div>
        </div>
        <div class="flex inputs gap-7">
          <div class="w-[440px]">
            <app-select-field
              :disabled="
                currentPath === '/edit-employee' &&
                isEditEmployee &&
                !isEditButton
              "
              :label="$t('addcustomrole.accessLevel')"
              :placeholder="$t('employment.addAccess')"
              :options="roles"
              param="module_name"
              v-model="accessLevel"
              id="accessLevel"
              data-cy="accessLevel"
            ></app-select-field>
          </div>
          <div class="w-[500px] flex flex-column"></div>
        </div>
      </div>
      <!-- language -->
      <div>
        <div class="flex H500 SPC-MT-400">
          <h3>{{ $t("employment.workingTime") }}</h3>
        </div>

        <div class="py-2 working_hours">
          <ul class="hours_layout">
            <li v-for="(day, index) in days" :key="index" class="flex">
              <div class="day">{{ $t(getTranslatedStringKey(day)) }}</div>
              <div class="flex select_input">
                <div class="relative flex items-center">
                  <select
                    v-model="selectedTimes[day].startTime"
                    @change="updateEndTime(day)"
                    class="custom-select"
                    :disabled="
                      currentPath === '/edit-employee' &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    id="starTime_hours"
                  >
                    <option
                      class="custom-option"
                      v-for="time in timeOptions"
                      :key="`start-${time}`"
                      :value="time"
                      data-cy="startTime"
                    >
                      {{ formatTime(time) }}</option>
                  </select>
                  <v-icon class="icon" icon="custom:expandIcon" />
                </div>
                <p class="px-4">{{ $t("employment.to") }}</p>
                <div class="relative flex items-center mr-1">
                  <select
                    v-model="selectedTimes[day].endTime"
                    class="w-4"
                    :disabled="
                      currentPath === '/edit-employee' &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    data-cy="endTime_hours"
                  >
                    <option
                      v-for="time in validEndTimeOptions(day)"
                      :key="`end-${time}`"
                      :value="time"
                      data-cy="endTime"
                    >
                      {{ formatTime(time) }}
                    </option>
                  </select>
                  <v-icon class="icon" icon="custom:expandIcon" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- termination -->
      <div v-show="currentPath === '/edit-employee'">
        <form>
          <fieldset :disabled="shouldDisableInput ? 'disabled' : null">
            <div class="flex H500 SPC-MT-400">
              <h3>{{ $t("tablefields.contractTermination") }}</h3>
            </div>

            <div class="flex items-center py-2">
              <div class="w-[440px]">
                <p class="text-sm font-medium">
                  {{ $t("leaves.terminateEmployeeContract") }}
                </p>
                <p class="text-sm">
                  {{ $t("leaves.terminateEmployeeContractPrompt") }}
                </p>
              </div>
              <div class="flex single_radio ml-[30px]">
                <h3 class="mr-4 font-medium !text-[#344054]">
                  {{ $t("projects.no") }}
                </h3>
                <label
                  for="terminateContract"
                  class="1 inline-flex relative items-center !cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="terminateContract"
                    class="sr-only peer !cursor-pointer"
                    v-model="isTerminateContract"
                    :disabled="
                      currentPath === '/edit-employee' &&
                      isEditEmployee &&
                      !isEditButton
                    "
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:bg-white after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#AE6A65]"
                  ></div>
                </label>
                <h3 class="ml-4 font-medium !text-[#344054]">
                  {{ $t("projects.yes") }}
                </h3>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </v-col>

    <div
      style="border-bottom: 1px solid #eaecf0; width: 100%"
      class="SPC-MT-200"
    ></div>

    <div class="table py-13">
      <app-table
        :headers="headers"
        :items="isAllJobHistories"
        :dropDisabled="
          (currentPath === '/edit-employee' ||
            currentPath === '/edit-expert' ||
            currentPath === '/profile') &&
          isEditEmployee &&
          !isEditButton
        "
        :pagename="$t('employment.positions')"
        :message="$t('employment.position')"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDeleteDialog({ $event, data: 'delete' })"
        :showCursor="false"
        :hideMenu="!canEdit"
        id="postion_table"
        data-cy="position_table"
      >
        <template #title>
          <h3 class="H500">{{ $t("employment.positions") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            @click-event="showDialog({ data: 'add' })"
            :buttonLabel="$t('employment.addPosition')"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
            id="btn_addPosition"
            data-cy="btn_addPosition"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPage"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <!-- dialog  for add and update position-->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="662"
      width="408"
      id="alert"
      data-cy="response_alert"
    >
      <template #content>
        <div>
          <app-select-field-object
            :label="$t('employment.positonAsterisk')"
            :options="isPositionsTitle"
            :placeholder="$t('employment.positionPlaceholder')"
            v-model="position"
            type="text"
            class="mb-2"
            :isTranslation="false"
            id="employment_position"
            data-cy="employment_position"
          ></app-select-field-object>
          <app-select-field-object
            class="mb-2"
            :label="$t('employment.company')"
            :options="isCompaniesTitle"
            :placeholder="$t('employment.companyPlaceholder')"
            v-model="company"
            :isTranslation="false"
            type="text"
            id="employment_company"
            data-cy="employment_company"
          ></app-select-field-object>
          <app-select-field-object
            class="mb-2"
            :label="$t('employment.team')"
            :placeholder="$t('employment.teamPlaceholder')"
            :options="isTeamsTitle"
            :isTranslation="false"
            type="text"
            v-model="team"
            id="employment_team"
            data-cy="employment_team"
          ></app-select-field-object>
          <app-input-field
            class="mb-2"
            type="number"
            :label="$t('employment.weeklyWorkingTime')"
            placeholder="40 hours"
            v-model="weekly_working_time"
            id="employment_workTime"
            data-cy="employment_workTime"
          ></app-input-field>
          <div class="flex items-center justify-between cursor-pointer">
            <div>
              <app-date-field
                :label="$t('employment.startDate')"
                type="date"
                v-model="start_date"
                id="start-date"
                name="start-date"
                :max="maxStartDate"
                data-cy="startDate"
              ></app-date-field>
            </div>
            <div>
              <app-date-field
                :label="$t('employment.endDate')"
                type="date"
                v-model="end_date"
                id="end-date"
                name="end-date"
                :min="minEndDate"
                data-cy="endDate"
              ></app-date-field>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <v-row class="px-4 mt-1 mb-2">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employment.cancel')"
              @click-event="hideDialog()"
              id="position_cancel"
              data-cy="btn_positionCancel"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :buttonLabel="
                isEdit
                  ? $t('employment.updatePosition')
                  : $t('employment.addPosition')
              "
              :disabled="!isEdit && positionBtnDisabled"
              :loading="loading"
              @click-event="isEdit ? updatePosition() : addToPosition()"
              id="btn_Position"
              data-cy="btn_Position"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <!-- dialog for delete position -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
      id="alert"
      data-cy="response_alert"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employment.noKeepIt')"
              @click-event="hideDeleteDialog()"
              data-cy="btn_keepIt"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteJobHistory"
              data-cy="btn_confirmDelete"
            >
              {{ $t("employment.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <v-row>
      <v-col cols="12" class="mt-7">
        <div
          v-if="
            !isEditEmployee ||
            (currentPath !== '/edit-employee' &&
              currentPath !== '/edit-expert' &&
              currentPath !== '/profile')
          "
          class="flex justify-end gap-3 pr-2 mb-7"
        >
          <div class="flex gap-3">
            <div>
              <app-secondary-button
                :buttonLabel="$t('employment.previous')"
                @click="previous"
              ></app-secondary-button>
            </div>
            <div v-if="probation_period !== ''">
              <app-primary-button
                @click-event="addToEmploymentInfo('continue')"
                :buttonLabel="$t('personal.saveAndContinue')"
                data-cy="btn_saveAndContinue"
                :disabled="probation_period === ''"
              >
              </app-primary-button>
            </div>
            <div v-else>
              <app-primary-button
                @click="handleNextClick"
                :buttonLabel="$t('employment.next')"
                @click-event="next"
                data-cy="btn_Next"
              >
              </app-primary-button>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-end gap-7">
          <div v-show="isEditButton">
            <app-secondary-button
              :buttonLabel="$t('employment.cancel')"
              @click="cancel()"
              data-cy="btn_cancel"
            ></app-secondary-button>
          </div>
          <app-primary-button
            v-show="isEditButton"
            :buttonLabel="$t('employment.saveChanges')"
            @click-event="addToEmploymentInfo()"
            :disabled="isSaveBtnDisabled()"
            data-cy="btn_saveChanges"
          >
          </app-primary-button>
        </div>
      </v-col>
    </v-row>
  </div>

  <app-dialog
    ref="dialogState"
    :title="$t('employment.endEmployment')"
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('employment.terminationDate')"
        v-model.trim="terminationDate"
        type="date"
      >
      </app-input-field>
      <app-select-field-object
        :label="$t('employment.reasonForTermination')"
        :options="terminateReason.convertArrayToObject()"
        placeholder="Select reason"
        v-model="selectedReason"
        :isTranslated="true"
        class="color-[#b2b5ba]"
      >
      </app-select-field-object>
      <app-text-area
        :label="$t('employment.comment')"
        :placeholder="$t('employment.addComment')"
        v-model="terminationComment"
        @updateValue="onTextAreaChange"
      ></app-text-area>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideTerminateDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :disabled="terminateReason === '' || selectedReason === ''"
            :buttonLabel="$t('employment.terminateNow')"
            @click="terminateEmployeeContract()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
    id="alert"
    data-cy="response_alert"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";
import Api from "@/services/api";
import moment from 'moment'
import Paginator from "@/components/shared/PaginationComponent.vue";
import { getTransString } from "@/services/helpers";
import { employeeTermination } from "@/services/endpoints"
import {isEqual} from "lodash";
export default defineComponent({
  name: "employment",
  components: {
    Paginator,
  },
  props: {
    handleButtonRoute: {
      type: Function,
    }
  },
  data() {
    return {
      headers: [
        "Position",
        "Company",
        "Team",
        "Time (Wk)",
        "Start Date",
        "End Date",
      ],
      positionID : null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      data: [],
      values: [],
      options: [""],
      cancelLabel: "Cancel",
      buttonLabel: "Add position",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      probation_period_date:"",
      placeholder: "",
      accessLevel:"",
      allUserRoles: [],
      firstRun: true,
      roles: [],
      url: "/configuration/roles/all-user-roles",
      page: 1,
      username: null,
      staffOptions:[{
        id: "Internal Staff",
        value: "Internal Staff"
      },
      {
        id: "External Staff",
        value: "External Staff"
      }],
      probationOptions:[
        {
        id: "3 Months",
        value: "3 Months"
      },
      {
        id: "6 Months",
        value: "6 Months"
      }],
      items: null,
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      selectedTimes: {
        Monday: { startTime: "09:00", endTime: "18:00" },
        Tuesday: { startTime: "09:00", endTime: "18:00" },
        Wednesday: { startTime: "09:00", endTime: "18:00" },
        Thursday: { startTime: "09:00", endTime: "18:00" },
        Friday: { startTime: "09:00", endTime: "18:00" },
      },
      defaultSelectedTimes: {},
      defaultInputs: [],
      startTime: "09:00",
      endTime: "18:00",
      timeOptions: [],
      employment_type: "",
      probation_period: "",
      position: "",
      company: "",
      team: "",
      start_date: "",
      end_date: "",
      weekly_working_time: 0.0,
      positionBtnDisabled: true,
      isTerminateContract: false,
      dialogState: false,
      selectedReason: "",
      terminationDate: "",
      terminationComment: "",
      terminateReason: ["End of contract", "Resignation (Voluntary)", "Termination (Involuntary)", "Death", "Poor performance"],
      initialProbationPeriod: '',
      initialEmploymentType: '',
      initialIsTerminateContract: '',
      initialAccessLevel: '',
      isSaved: true,
    };

  },

  computed: {

    formattedDate() {
      return moment(this.probation_period_date).format("Do, MMM YYYY");
    },
    minEndDate() {
      if (this.start_date) {
        const minDate = new Date(this.start_date);
        minDate.setDate(minDate.getDate() + 1);
        return minDate.toISOString().split('T')[0];
      }
      return null;
    },
    maxStartDate() {
      if (this.end_date) {
        const minDate = new Date(this.end_date);
        minDate.setDate(minDate.getDate() - 1);
        return minDate.toISOString().split('T')[0];
      }
      return null;
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isCompaniesTitle() {
      const modCompanies =  this.$store.getters["hrConfigurations/isCompanies"];
      return modCompanies?.map((companyObject) => {
        const {id: value, "title": id} = companyObject
        return {id, value}
      })
    },
    isPositionsTitle() {
      const modPosition = this.$store.getters["hrConfigurations/isPositions"];
      return modPosition?.map((positionObject) => {
        const {id: value, "title": id} = positionObject
        return {id, value}
      })
    },
    isTeamsTitle() {
      const modTeam = this.$store.getters["hrConfigurations/isTeams"];
      return modTeam?.map((teamObject) => {
        const {id: value, "title": id} = teamObject
        return {id, value}
      })
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    isAllJobHistories() {
      return this.$store.getters["hrResources/isAllJobHistories"];
    },

    watchDependencies() {
      return [
        this.start_date,
        this.company,
        this.position,
        this.team,
        this.weekly_working_time,
      ];
    },
    inputDependencies() {
      return [
        this.accessLevel,
        this.employment_type,
        this.probation_period,
        this.isTerminateContract,
      ];
    },

    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections
    },
    isEditButton() {
      return  this.$store.getters["hrResources/isEditButton"] && this.canEditPersonalModule?.includes('Employment');
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    shouldDisableInput() {
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
    currentPath() {
      return this.$route.path;
    },
    canEdit(){
     return this.currentPath ==='/edit-employee'?  this.canEditPersonalModule?.includes('Employment') : true
    },
    showAndHideElement(){
      return  (this.currentPath === '/edit-employee' ||
                this.currentPath === '/edit-expert' ||
                this.currentPath === '/profile') &&
             this.isEditEmployee &&
              this.isEditButton
    }
  },
  watch: {
    startDate(newStartDate) {
      if (this.end_date && this.end_date < newStartDate) {
        this.end_date = null;
      }
    },
    isPersonalData(value) {
      if (value) {
        this.probation_period_date = value?.probation_period_date
        this.employment_type = value.employment_type;
        this.isTerminateContract = value.is_terminated;

        if (this.firstRun) {
          this.defaultInputs = [
            "",
            value.employment_type,
            "",
            value.is_terminated
          ]
        }

        const initialEmploymentType = this.employment_type
        const initialIsTerminateContract = this.isTerminateContract

        this.initialEmploymentType = initialEmploymentType
        this.initialIsTerminateContract = initialIsTerminateContract

        if (value.probation_period) {
          this.probation_period = `${value.probation_period} Months`;
          if (this.firstRun) {
            this.defaultInputs[2] = `${value.probation_period} Months`;
          }
          this.initialProbationPeriod = this.probation_period
        }

        if (value?.working_time) {
          this.selectedTimes = value?.working_time?.reduce((acc, curr) => {
            return { ...acc, [curr.day_of_week]: { startTime: curr.start_time.substring(0, 5), endTime: curr.end_time.substring(0, 5)} }
          }, {});
          if (!Object.keys(this.defaultSelectedTimes).length) {
            this.defaultSelectedTimes =  value?.working_time?.reduce((acc, curr) => {
              return { ...acc, [curr.day_of_week]: { startTime: curr.start_time.substring(0, 5), endTime: curr.end_time.substring(0, 5)} }
            }, {})
          }
        }
        if (this.accessLevel==="") {
          this.accessLevel = value?.role_id
          if (this.firstRun) {
            this.defaultInputs[0] = value?.role_id;
          }
          this.initialAccessLevel = this.accessLevel
        }

        this.firstRun = false;
      }
    },
    end_date(newStartDate) {
      if (newStartDate && this.start_date && (this.start_date > newStartDate)) {
        this.start_date = null;
      }
    },
    watchDependencies: {
      immediate: true,
      handler() {
        this.isPositionButtonDisabled();
      },
    },
    inputDependencies: {
      immediate: true,
      handler(newValue) {
        if (!this.firstRun) {
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            "Employment": !isEqual(newValue, this.defaultInputs)
          });
        }
      },
    },
    selectedTimes: {
      deep: true,
      handler(value) {
        if (!this.firstRun) {
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            "Employment": !isEqual(value, this.defaultSelectedTimes)
          });
        }
      }
    },
    isTerminateContract(val){
      this.$refs.dialogState.showDialog = this.isTerminateContract = val
      if(this.isPersonalData.is_terminated === true){
        this.$refs.dialogState.showDialog = false
      }
    },
    employment_type(newVal) {
      this.isSaved &&= this.initialEmploymentType === newVal
    },

    accessLevel(newVal) {
      this.isSaved &&= this.initialAccessLevel === newVal
    },

    probation_period(newVal) {
      this.isSaved &&= this.initialProbationPeriod === newVal
    },

    isSaved(newVal){
      this.$emit('is-saved', newVal);
    }
  },
  methods: {
    isSaveBtnDisabled () {
      return this.accessLevel
      && this.employment_type
      && this.probation_period
      && this.isTerminateContract
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    onTextAreaChange(text){
      this.terminationComment = text
    },
    isPositionButtonDisabled () {
      this.positionBtnDisabled = this.watchDependencies.some((elt) => (String(elt).trim() === "") || (String(elt).trim() === "0.0"));
    },
    handleNextClick() {
      this.$emit("next-clicked", 3);
    },
    next(){
      this.handleButtonRoute(3);
    },
    previous(){
      const getCurrentTab = this.isCurrentTab - 1
      this.handleButtonRoute(getCurrentTab);
    },
    cancel(){
      this.$router.push("/employees");
    },
    showDialog(data) {
      this.positionID = data?.$event?.id;
      this.modelValue = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "add") {
        this.title = "employment.addPosition";
        this.$refs.dialog.showDialog = true;
      } else if (data.data === "edit") {
        const transformedObj = {};
      const obj = data?.$event

      Object.keys(obj).forEach(key => {
        const transformedKey = key.replace(/[\s()]/g, '_');
        transformedObj[transformedKey] = obj[key];
      });
        this.isEdit = true;
        this.buttonLabel = "employment.saveChanges";
        this.position= transformedObj?.position.id
        this.company= transformedObj?.company.id
        this.team= transformedObj?.team.id
        this.weekly_working_time = transformedObj?.time__wk_
        this.start_date= transformedObj?.start_date
        this.end_date= transformedObj?.end_date
        this.title = "employment.editEmployee";
        this.$refs.dialog.showDialog = true;
      }
    },
    hideTerminateDialog(){
      this.$refs.dialogState.showDialog = false
      this.isTerminateContract = false
    },
    hideDialog() {
        this.$refs.dialog.showDialog = false;
        setTimeout(()=>{
          this.addTitle = "";
          this.isEdit = false;
          this.isAdd = false;
          this.isDelete = false;
          this.subTitle = "";
          this.buttonLabel = "employment.addPosition";
          this.cancelLabel = "employment.cancel";
        }, 600)

    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      this.isDelete = false;
      this.confirmDelete = null;
      this.subTitle = "";
    },
    showDeleteDialog(data) {
      this.positionID = data?.$event?.id;
      if (data.data === "delete") {
        this.isDelete = !this.isDelete;
        this.title = "employment.deletePosition";
        this.subTitle =
          "employment.areYousure";
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    updateTimeOptions() {
      const options = [];
      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 30) {
          const time = `${h.toString().padStart(2, "0")}:${m
            .toString()
            .padStart(2, "0")}`;
          options.push(time);
        }
      }
      this.timeOptions = options;
    },
    updateEndTime(day) {
      const start = new Date(`1970-01-01T${this.selectedTimes[day].startTime}`);
      const end = new Date(start.getTime() + 30 * 60 * 1000);
      this.selectedTimes[day].endTime = end.toISOString().slice(11, 16);
    },
    validEndTimeOptions(day) {
      const start = new Date(`1970-01-01T${this.selectedTimes[day].startTime}`);
      return this.timeOptions.filter((time) => {
        const end = new Date(`1970-01-01T${time}`);
        return end > start;
      });
    },
    formatTime(time) {

      const [hour, minute] = time.split(":");
      const h = parseInt(hour, 10);
      const m = parseInt(minute, 10);
      // const ampm = h >= 24 ? "PM" : "AM";
      const h12 = h % 24 || 24;
      return `${h12.toString().padStart(2, "0")}:${m
        .toString()
        .padStart(2, "0")}`;
    },

    resetData () {
      this.position = "";
      this.company = "";
      this.team = "";
      this.start_date = null;
      this.end_date = null;
      this.weekly_working_time = 0.0;
    },

    terminateEmployeeContract(){
      this.loading = true
      const payload = {
        date: this.terminationDate,
        reason: this.selectedReason,
        comment: this.terminationComment
      }
      Api()
      .post(employeeTermination + `${this.isUserKey}/`, payload )
      .then(response => {
        if (response.data.status === 'success'){
          this.loading = false
          this.alertType = "success";
          this.alertTitle = response.data.detail;
          this.alertState = !this.alertState;
          this.$refs.dialogState.showDialog = false
          this.getPersonalContactInfo()
        }
      })
      .catch()
    },

    addToPosition() {
      this.loading = true
      let weeklyTime = parseFloat(Number(this.weekly_working_time));
      const payload = {
        position: this.position,
        company: this.company,
        team: this.team,
        start_date: this.start_date,
        weekly_working_time: weeklyTime,
      };

      if (this.end_date) {
        payload.end_date = this.end_date;
      }

      Api()
        .post(`/hr/employees/add-job-history/${this.isUserKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false
            this.getAllJobHistories(this.currentPage);
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.dialog.showDialog = false;
            this.isEdit = false
            this.resetData();
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.dialog.showDialog = false;
            this.resetData();
          }
        });
    },
    async getAllUserRoles() {
      try {
        const response = await Api().get(`${this.url}/`);
        this.allUserRoles = response.data.data;

        const outputArray = [];

        this.allUserRoles.forEach((roleObj) => {
          Object.entries(roleObj).forEach(([roleName, roleDetails]) => {
            const module_name = roleName;
            const id = roleDetails[0].role_id;
            outputArray.push({ module_name, id });
          });
        });
        this.roles = outputArray

      } catch (error) {
        //
      }
    },
    assignRole() {
      const payload = {
        user_key: this.isUserKey,
        role_id:Number(this.accessLevel)
      };
      Api()
        .post("/hr/employee/assign-access-role/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
          }
        });
    },
    getAllJobHistories(page) {
      Api()
        .get(`/hr/employees/get-all-job-histories/${this.isUserKey}/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;
            this.$refs.dialog.showDialog = false;
            this.values = data.map((obj) => {
              return {
                id: obj.id,
                company: obj.company,
                'end date': obj.end_date,
                position: obj.position,
                'start date': obj.start_date,
                team: obj.team,
                'time (wk)': obj.weekly_working_time,
              };
            });
            this.$refs.dialog.showDialog = false;
            this.$store.commit("hrResources/SET_JOB_HISTORIES_TO_STATE", this.values);
            this.$store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    updatePosition() {
      this.loading = true
      let weeklyTime = parseFloat(Number(this.weekly_working_time));

      const payload = {
        position: this.position,
        company: this.company,
        end_date: this.end_date || null,
        team: this.team,
        start_date: this.start_date,
        weekly_working_time: weeklyTime,
      };

      Api()
        .patch(`/hr/employees/update-job-history/${this.positionID}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllJobHistories(this.currentPage);
            this.loading = false
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            setTimeout(() => {
              this.addTitle = "";
              this.$refs.dialog.showDialog = false;
              this.resetData();
            }, 1000);
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.resetData();
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
            this.resetData();
          }
        });
    },
    deleteJobHistory() {
      Api()
        .delete(`/hr/employees/delete-job-history/${this.positionID}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.getAllJobHistories(this.currentPage);
            this.alertTitle = response.data.detail;
            this.$refs.isDeleteDialog.showDialog = false;
            this.isDelete = false;
            this.subTitle = "";
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    addToEmploymentInfo(data) {

      this.addloading = true
      const payload = {
        employment_type: this.employment_type,
        probation_period: Number(this.probation_period.split(" ")[0]),
        working_time: {},
      };
      for (const day of this.days) {
        const startTime = this.selectedTimes[day].startTime;
        const endTime = this.selectedTimes[day].endTime;
        if (startTime && endTime) { // Only add non-empty values to working_time
          payload.working_time[day] = {
            start_time: startTime,
            end_time: endTime,
          };
        }
      }
      Api()
        .post(`/hr/employees/add-employment-info/${this.isUserKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.assignRole()
            this.addloading = false
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
              "Employment": false
            });
            this.$emit('is-saved',true)
            this.getPersonalContactInfo();
            setTimeout(() => {
              if (this.currentPath === "/add-employee") {
                if (data === "continue") {
                  // this.$router.push({ name: "employees" });
                  this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 3);
                }
              }
            }, 1500);

          }
        })
        .catch((error) => {
          if (error) {
            this.addloading = false
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllSettings(type, stateType) {
      Api()
        .get(`/configuration/general/get-general/${type}/`)
        .then((response) => {
          const payload = response.data;
          this.items = payload;
          this.$store.commit(
            `hrConfigurations/${stateType}`,
            payload.data
          );
        });

    },
    getPersonalContactInfo() {
      Api()
        .get(
          `/hr/employees/get-employee-personal-contact-info/${this.isUserKey}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    loadMore() {
      const page = this.currentPage + 1;
      this.getAllJobHistories(page);
    },
  },
  mounted() {
    this.getAllJobHistories(this.currentPage);
    this.getAllUserRoles();

    if (this.$route.name === "addEmployee") {
      this.initialProbationPeriod = this.probation_period;
      this.initialEmploymentType = this.employment_type;
      this.initialAccessLevel = this.accessLevel;
    }
  },
  created(){
    this.getAllSettings("Positions", "SET_POSITIONS_TO_STATE");
    this.getAllSettings("Companies", "SET_COMPANIES_TO_STATE");
    this.getAllSettings("Teams", "SET_TEAMS_TO_STATE");

    this.updateTimeOptions();
    this.getPersonalContactInfo();

    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
  }
});
</script>

<style scoped>
select {
  padding: 10px;
  background: #f2f4f7;
  width: 75px;
  border: none;
  border-radius: 8px;
  outline-width: 0;
  cursor: pointer;
}
select:disabled {
  cursor: default;
}
.day {
  width: 96.47px;
}
.select_input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  background: #f2f4f7;
  border-radius: 8px;
  cursor: pointer;
}
li {
  padding: 10px;
  align-items: center;

  display: flex;
}
.working_hours {
  display: grid;
  gap: 10px;
}

.hours_layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

option {
  background-color: white;

  border-radius: 10px;
}

option:hover {
  background-color: #a65f46;
  color: white;
}
.table {
  width: 100%;
}
.icon {
  position: absolute;
  right: 0;
  font-size: 12px;
  margin-left: -25px;
  pointer-events: none;
}
</style>
