<template>
  <v-sheet max-width="" class="bg-transparent">
    <!-- end of parent-module -->
    <app-dialog
      ref="dialog"
      :title="
        isEdit ? $t('projects.editFilesTitle') : $t('projects.addFilesTitle')
      "
      subtitle=""
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('projects.fileTitleLabel')"
          :placeholder="$t('projects.enterFileTitle')"
          v-model.trim="fileTitle"
          :required="true"
          v-if="showTitle"
        ></app-input-field>
        <app-select-field
          v-if="showSelect"
          :options="fileTypeOptions"
          :placeholder="$t('projects.selectFileType')"
          v-model="selectedFileType"
        >
        </app-select-field>

        <!-- drag and drop -->
        <div
          class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
          @dragover="dragover"
          @drop.prevent
          @drop="dragFile"
          @click="pickFile"
          v-if="isEdit && editFileUrl === ''"
        >
          <span>
            <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
          </span>
          <input
            ref="file"
            @change="selectedFile"
            type="file"
            accept="*"
            hidden
            multiple
          />
          <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
            {{ $t("projects.clickToUpload") }}
            <!-- <span class="!font-normal text-[#667085]">{{
              $t("projects.orDragAndDrop")
            }}</span> -->
          </p>
          <p
            class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
          >
            {{ $t("projects.acceptedFiles") }}
          </p>
        </div>
        <div
          :class="{ '!border-red-500': payload.file_url ? false : hasError }"
          class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
          @dragover="dragover"
          @drop.prevent
          @drop="dragFile"
          @click="pickFile"
          v-if="!isEdit"
          :hasError="isFieldInvalid('file_url')"
          :isRequired="true"
        >
          <span>
            <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
          </span>
          <input
            ref="file"
            @change="selectedFile"
            type="file"
            accept="*"
            hidden
            multiple
          />
          <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
            {{ $t("projects.clickToUpload") }}
            <!-- <span class="!font-normal text-[#667085]">{{
              $t("projects.orDragAndDrop")
            }}</span> -->
          </p>
          <p
            class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
          >
            {{ $t("projects.acceptedFiles") }}
          </p>
        </div>
        <div v-if="!isEdit">
          <p
            v-if="payload.file_url ? false : hasError"
            class="text-red-500 text-[14px]"
          >
            {{ $t(`${errorMessage.getTranslationKey()}`) }}
          </p>
        </div>

        <div v-if="isEdit && editFileUrl !== ''">
          <div
            class="border w-full h-[72px] mt-6 rounded-lg p-4 justify-between text-[#344054] font-semibold text-sm"
          >
            <div class="flex justify-between">
              <p class="truncate">{{ fileName }}</p>
              <v-icon
                class="ml-2 cursor-pointer"
                icon="mdi:mdi-close-circle"
                color="#AE6A65"
                @click="deleteFile()"
              ></v-icon>
            </div>
            <p class="font-normal">{{ fileSize }}</p>
          </div>
        </div>

        <div v-if="uploading">
          <div v-for="(singleUpload, index) of uploadedFiles" :key="index">
            <div
              class="p-4 mt-6 text-[#667085] !rounded-lg border border-gray-200 w-full h-full"
            >
              <v-row v-if="showUploadOnEdit">
                <v-col cols="10">
                  <p
                    class="!font-inter !font-medium !text-[0.875rem] text-[#344054]"
                    @click="pickFile"
                  >
                    {{ singleUpload?.name }}
                  </p>
                  <p
                    class="mt-0 !font-inter !font-normal !text-[0.875rem] text-[#667085]"
                  >
                    {{ singleUpload?.size }} - {{ singleUpload?.progress }}%
                    {{
                      singleUpload?.progress === 100 ? "uploaded" : "uploading"
                    }}
                  </p>
                </v-col>
                <v-col cols="2" class="ml-auto">
                  <v-icon
                    v-if="singleUpload?.progress === 100"
                    icon="mdi:mdi-check-circle"
                    color="#AE6A65"
                  ></v-icon>
                  <v-progress-circular
                    v-else
                    :model-value="singleUpload?.progress"
                    :width="3"
                    color="#AE6A65"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <app-input-field
          class="mt-3"
          :label="$t('projects.filePath')"
          :placeholder="$t('projects.enterFilePath')"
          v-model="filePath"
          v-if="showPath"
        ></app-input-field>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              v-if="currentPath === '/incoming-invoices'"
              :buttonLabel="$t('Save'.getTranslationKey())"
            ></app-primary-button>
            <app-primary-button
              v-else
              :loading="isLoading"
              :buttonLabel="
                isEdit
                  ? $t('addcustomrole.saveChanges')
                  : $t('projects.addFile')
              "
              @click-event="isEdit ? editProjectFile() : handleFileSave()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { formatBytes, getFileNameFromURL, getTransString } from '@/services/helpers'
import Queue from '@/services/Queue'
import UploadApi from '@/services/fileUploadApi';
import api from '@/services/api';
import { addProjectFile, deleteFile, updateFile } from '@/services/endpoints';

export default defineComponent({
  props: {
    // fileType: {
    //   type: String,
    //   default: 'Addendum'
    // },

    fileNameProp: {
      type: String,
      default: "Tech requirement"
    },
    fileSize: {
      type: String,
    },

    showSelect: {
      type: Boolean,
      default: false
    },
    showUploadOnEdit: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showPath: {
      type: Boolean,
      default: true
    },

  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: false,
      count: 0,
      payload: {
        file_title: '',
        file_type: '',
        file_url: '',
        file_path: '',
      },
      fileTitle: '',
      filePath: '',
      fileUrl: '',
      fileName: "",
      fileId: "",
      selectedFileType: "",
      fileDate: "",
      uploading: false,
      uploadedFiles: [],
      queueUpload: new Queue(),
      queueSize: null,
      lastItem: null,
      filesDetails: [],
      urlArray: [],
      editEventProp: Function,
      fileTypeOptions: ["Tender", "Proposal", "Clients", "Partners", "Experts", "Addendum", "Others"],
      isUploaded: false,
      editFileUrl: '',
      editObject: {},
      isEdit: false,
      fileType: '',
      requiredFields: ['file_url'],
      isDirty: [],
      hasError: false,
      errorMessage: "This field is required"
    }
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFile()
      }
    },
    editFileEventProp(newEvent) {
      this.editEventProp = newEvent
    }
  },
  computed: {
    // isDisabled() {
    //   return this.fileTitle === '' && this.lastItem?.progress !== 100
    // },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isAllProjectFiles() {
        return this.$store.getters["projectModules/isAllProjectFiles"];
    },
    isAddendumFile(){
      return this.isAllProjectFiles.filter(addenda =>  addenda.file_type === "Addendum" ).map(({ title, date_uploaded, user , id, file_path, file_url}) => ({
        title: title,
        'date uploaded': date_uploaded,
        user: user,
        id: id,
        file_path: file_path,
        file_url: file_url

      }));
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation(){
    this.hasError = false
    const filteredFields = this.isEdit? this.requiredFields?.filter(field => field !== 'file_url'): this.requiredFields;
    Object.keys(this.payload).forEach((key) => {
        if (filteredFields.includes(key) && (this.payload[key] === "" ||  this.payload[key] === undefined || this.payload[key] === null)) {
          this.isDirty.push(key);

          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {
          return this.hasError;
        }
  },
  checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
      // this.isDirty= []
    },
    showDialog() {
      this.isEdit = false
      this.fileTitle =  ''
      this.filePath = ''
      this.fileName =  ''
      this.fileId =  ''
      this.editFileUrl =  ''
      this.$refs.dialog.showDialog = true
      // this.selectedFileType = ''
    },
    showEditDialog() {

      this.fileTitle = this.editObject.title
      this.filePath =  this.editObject.file_path
      this.fileName = getFileNameFromURL(this.editObject.file_url)
      this.fileId = this.editObject.id
      this.editFileUrl = this.editObject.file_url
      this.$refs.dialog.showDialog = true
      this.selectedFileType = this.isEdit === true && this.showSelect === true ? this.editObject['file type'] : ''
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false
      this.fileTitle = '';
      this.filePath = '';
      this.fileUrl = '';
      this.fileName = "";
      this.fileId = "";
      this.hasError = false
      this.uploadedFiles = []
    },
    pickFile() {
      this.$refs.file.click()
    },
    dispatchProjectFiles(){
      const payload = {
              project_id : this.isProjectID,
              load_page: false
            }
            this.$store.dispatch("projectModules/getAllProjectFiles", payload );
    },

    /**
     * Handle the file creation functionality.
     */
    handleFileSave() {

      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;

      const projectFiles = [...this.filesDetails]

      let modProjectFiles = projectFiles.reduce((prev, curr) => [...prev, { ...curr, file_path: this.filePath, file_type: this.showSelect ? this.selectedFileType : this.fileType, file_url: this.fileUrl, date: '2023-07-11', title: this.fileTitle, upload_from: this.showSelect ? "Calculation" : "" }], [])

      if (projectFiles.length === 1) {
        const currentProjectFile = { ...modProjectFiles[0] };
        currentProjectFile.title = this.fileTitle;
        modProjectFiles = [currentProjectFile];
      }
      const payload = {
        project_id: this.isProjectID,
        project_files: modProjectFiles
      }

      api()
        .post(addProjectFile, payload)
        .then((response) => {
          if (response.data.status === 'success') {
            this.hideDialog()
            this.isLoading = false
            this.alertState = true
            this.alertType = "success"
            this.alertTitle = response.data.details
            this.selectedFileType = '';
            this.fileTitle = '';
            this.filePath = '';
            this.dispatchProjectFiles()
          }
        })
        .catch((error) => {
          if (error) {
            this.isLoading = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
        });

    },
    editProjectFile() {

      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;
      const projectFiles = [...this.filesDetails]
      let modProjectFiles = projectFiles.reduce((prev, curr) => [...prev, { ...curr, file_path: this.filePath, file_type: this.showSelect ? this.selectedFileType : this.editObject['file type'], file_url:  this.editFileUrl, date: '2023-07-11', title: this.fileTitle, upload_from: this.showSelect ? "Calculation" : "" }], [])

      if (projectFiles.length === 1) {
        const currentProjectFile = { ...modProjectFiles[0] };
        currentProjectFile.title = this.fileTitle;
        modProjectFiles = [currentProjectFile];
      }
      const newPayload = {
        project_id: this.isProjectID,
        project_files: modProjectFiles
      }


      api()
        .put(`${updateFile}${this.editObject.id}/`, newPayload)
        .then((response) => {
          if (response.data.status === 'success') {
            this.hideDialog()
            this.isLoading = false
            this.alertState = true
            this.alertType = "success"
            this.alertTitle = response.data.details
            this.dispatchProjectFiles()
          }
        })
        .catch(error => {
          if (error) {
            this.isLoading = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.details;
          }
        })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('ae-bg-green-300')) {
        event.currentTarget.classList.remove('ae-drag-window')
        event.currentTarget.classList.add('ae-bg-green-300')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('ae-bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      this.filelist = [...event.dataTransfer.files]

    },
    dragFile(event) {
      this.uploading = true

      this.filelist = ''
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      event.currentTarget.classList.add('ae-drag-window')
      this.filelist = [...event.dataTransfer.files]

      this.validateAndInitialUpload(this.filelist)

    },
    selectedFile(event) {
      this.uploading = true
      this.filelist = ''
      this.filelist = [...event.target.files]
      this.validateAndInitialUpload(this.filelist)
    },

    validateAndInitialUpload() {
      const SIZE = 16777216
      for (let i = 0; i < this.filelist.length; i++) {
        const file = this.filelist[i];
        this.queueUpload.enqueue(file)
        this.queueSize = this.queueUpload.getSize()

        if (file.size > SIZE) {
          this.$refs.dialog.showDialog = !this.isDialog
          this.isLoading = false
          this.alertState = true
          this.alertType = "error"
          this.alertTitle = "You cannot upload file size more than 16mb";

          return
        } else {
          this.uploadedFiles.push({ name: file.name, progress: 0, size: formatBytes(file.size) });
        }
        const formData = new FormData();
        formData.append("project_file", file);
        formData.append("title", this.fileTitle);

        this.config = {
          onUploadProgress: (progressEvent) => {
            this.uploadedFiles[this.count % this.uploadedFiles?.length].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        };
      }
      this.lastItem = this.uploadedFiles?.length > 0 ? this.uploadedFiles[this.uploadedFiles?.length - 1] : undefined;

    },
    uploadFile() {
      const file = this.queueUpload.peek()
      const formData = new FormData();
      formData.append("project_file", file);
      formData.append("title", this.fileTitle)

      const details = {};
      this.filesDetails = []

      UploadApi().patch(`/projects/project/file-upload/${this.isProjectID}/`, formData, this.config)
        .then((response) => {
          if (response.data.status === 'success') {
            // Handle the response from the server if needed
            this.fileUrl = response.data.data.file_url
            details.title = response.data.data.title
            this.fileTitle = response.data.data.title
            details.file_url = this.fileUrl;
            // to be refactor
            this.payload.file_url = response.data.data.file_url

            this.urlArray.push(this.fileUrl)
            this.isUploaded = true;

            this.$emit("url-from-file", this.urlArray)

            const isEmpty = this.queueUpload.isEmpty()
            if (!isEmpty) {
              this.queueUpload.removeItem(0)
              this.queueSize = this.queueUpload.getSize()
              this.count++

            } else {
              this.count = 0
              this.uploadedFiles = []
            }
            this.filesDetails.push(details)
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the upload
          if (error) {
            this.uploadedFiles = []

          }
        });
    },
    deleteFile() {
      api()
        .delete(`${deleteFile}${this.editObject.id}`)
        .then(response => {
          if(response.data.status === "success"){
            this.editFileUrl = ""
            this.alertState = true
            this.alertType = "success"
            this.alertTitle = response.data.details
            // this.dispatchProjectFiles()
          }
        })
        .catch()
    }
  }
})
</script>
<style scoped>
.ae-drag-window {
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}

.ae-drag-window:hover {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border: 1px solid #fdc6c2 !important;
}

.ae-upload-progress {
  border-radius: 5px;
}

.ae-bg-green-300 {
  border: 5px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}
</style>
