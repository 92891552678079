<script>
import {defineComponent, toRef} from 'vue'
import {checkNumberInput, monthNames} from '@/services/helpers'
import Api from "@/services/api";
import {createProjectHour, getProjectHoursDetails, lockAndUnlockProjectHours} from "@/services/endpoints";
import AppSingleSelect from "@/components/shared/AppSingleSelect.vue";

export default defineComponent({
  name: "ProjectHoursTable",
  components: {AppSingleSelect},
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
    isView: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      monthNames,
      tableData: [],
      without_project: {},
      leave: {},
      working_hours: {},
      travel: {},
      allProjects: [],
      dailyWorkingHours: {},
      loadingData: false,
      alertState: {
        show: false,
        message: '',
        type: ''
      },
      savingData: false,
      submittingData: false,
      projectDetails: {
        is_locked: false,
        is_approved: false,
        is_draft: false
      },
      hoveredItemIndex: null,
      submitTooltipMessage: ''
      // savingDataPayload: []
      // month: 11,
      // year: 2023
    }
  },
  computed: {
    disabled() {
      return this.projectDetails.is_locked
    },
    getDays() {
      const numberOfDaysInMonth = new Date(this.year, this.month, 0).getDate();
      let dayNames = []
      let dayNumbers = []
      for (let i = 1; i <= numberOfDaysInMonth; i++) {
        const day = i
        const name = new Date(this.year, this.month - 1, i).toLocaleString('en-us', {weekday: 'short'}).slice(0, 2)
        dayNames.push(name)
        dayNumbers.push(day)
      }
      return {dayNames, dayNumbers}
    },
    getLeaveHours() {
      return (dayIndex) => {
        return this.leave.project_hours[dayIndex].hours || 0
      }
    },
    getTotalHours() {
      return ({tableObject, index, type = ''}) => {
        let totalHours = 0
        if (Object.keys(tableObject).length > 0) {
          for (let i = 0; i < tableObject?.project_hours.length; i++) {
            totalHours += Number(tableObject?.project_hours[i].hours)
          }
          if (type) {
            this[type].totalHours = totalHours
          }
          else {
            this.tableData[index].totalHours = totalHours
          }
        }
        return totalHours
      }
    },
    getTotalDailyWorkingHours() {
      return (dayIndex) => {
        const staticFieldsColumnTotal = this.staticRows.reduce((acc, curr) => {
          if(curr.type === 'working_hours' || curr.type === 'travel') return acc
          acc += Number(curr?.data?.project_hours[dayIndex].hours)
          return acc
        }, 0)
        const withProjectsColumTotal = this.tableData.reduce((acc, curr) => {
          acc += Number(curr?.project_hours[dayIndex].hours)
          return acc
        }, 0)

        return staticFieldsColumnTotal + withProjectsColumTotal
      }
    },
    staticRows() {
      return [{
        name: "Without Projects",
        type: "without_project",
        data: this.without_project
      },
        {
          name: "On Leave",
          type: "leave",
          data: this.leave
        },
        {
          name: "Working Hours",
          type: "working_hours",
          data: this.working_hours
        },
        {
          name: "Travel",
          type: "travel",
          data: this.travel
        }]
    },
    userKey() {
      if(this.$route.name === 'editEmployee' || this.$route.name === 'editExpert') {
        return this.$store.getters["hrResources/isUserKey"];
      }
      return this.$store.getters["auth/isPersonalKey"];
    },
    isAdmin() {
      return this.$store.getters["auth/role"];
    },
  },
  methods: {
    checkNumberInput,
    disableSubmit() {
      if(this.isView) return this.disabled

      const today = new Date(Date.now())
      const lastDay = new Date(this.year, this.month, 0)
      if(today >= lastDay) {
        this.submitTooltipMessage = 'Project hours is locked by admin'
        return this.projectDetails.is_locked
      }
      this.submitTooltipMessage = 'You can only submit project hours at the end of the month'
      return true
    },
    handleShowTooltip(event, type) {
      if (type === 'submit' && !this.disableSubmit()) return
      else if (type === 'save' && !this.disabled) return

      const tooltip = event.target.nextElementSibling
      tooltip.style.display = 'block'
      const tooltipWidth = tooltip.offsetWidth

      if(event.pageX + tooltipWidth > window.innerWidth) {
        tooltip.style.left = `${event.pageX - tooltipWidth}px`
      } else {
        tooltip.style.left = `${event.pageX}px`
      }
      tooltip.style.top = `${event.pageY + 5}px`
    },
    hideToolTip(event) {
      const tooltip = event.target.nextElementSibling
      tooltip.style.display = 'none'
    },
    getProjectHours({existingProjectHours, isTravel = false}) {
      const projectHours = []
      for (let i = 1; i <= this.getDays.dayNumbers.length; i++) {
        const name = new Date(this.year, this.month - 1, i).toLocaleString('en-us', {weekday: 'long'})
        const day = i

        const alreadyLogged = existingProjectHours.find((item) => item.day === day)
        if(alreadyLogged) {
          projectHours.push({...alreadyLogged, name, travelled: isTravel})
          continue
        }
        projectHours.push({day, name, hours: '', travelled: false})
      }
      return projectHours
    },
    addMore({
              projectId,
              title,
              costCenterNumber,
              type,
              staticFieldType,
              project_hours
            }) {
      const tableObject = {
        project_id: projectId || '',
        project_title: title || '',
        cost_center_number: costCenterNumber || '',
        totalHours: '',
        // travel: staticFieldType === 'travel',
        // without_project: staticFieldType === 'without_project',
        // working_hours: staticFieldType === 'working_hours',
        // leave: staticFieldType === 'leave',
        project_hours: [],
        is_error: false
      }
      for (let i = 1; i <= this.getDays.dayNumbers.length; i++) {
        const day = i
        const name = new Date(this.year, this.month - 1, i).toLocaleString('en-us', {weekday: 'long'})

        // check if time has already been logged for the day
        const projectHour = project_hours?.find((item) => item.day === day)
        if(projectHour) {
          tableObject.project_hours.push({...projectHour, name, travelled: false})
          continue
        }
        tableObject.project_hours.push({day, name, hours: '', travelled: false})
      }
      this.tableData.push(tableObject)
    },
    deleteRow(index) {
      this.tableData.splice(index, 1)
      this.hoveredItemIndex = null
    },
    // toggleTravelled(index) {
    //   this.travel.project_hours[index].travelled = !this.travel.project_hours[index].travelled
    // },
    handleLock() {
      this.$refs.lockModal.showDialog = true;
    },
    lockAndUnlock() {
      Api()
          .put(lockAndUnlockProjectHours, {
            user_key: this.userKey,
            year: this.year,
            month: this.month
          })
          .then((response) => {
            this.alertState.show = true
            this.alertState.message = response.data?.detail
            this.alertState.type = 'success'
            this.$refs.lockModal.showDialog = false
            this.projectDetails.is_locked = !this.projectDetails.is_locked
            this.$emit('onUpdate')
          })
          .catch((error) => {
            this.alertState.show = true
            this.alertState.message = error.response.data?.detail
            this.alertState.type = 'error'
          })
          .finally(() => {
            setTimeout(() => {
              this.alertState.show = false
            }, 3000)
          })
    },
    initiate() {
      this.tableData = []
      this.loadingData = true
      Api()
          .post(getProjectHoursDetails, {
            user_key: this.userKey,
            year: this.year,
            month: this.month
          })
          .then((response) => {
            this.allProjects = response.data?.data?.all_projects.map((project) => {
              return {value: project.id, text: `${project.cost_centre__cost_center_number} - ${project.project_title}`}
            })
            this.dailyWorkingHours = response.data?.data?.working_hours
            this.projectDetails = toRef({
              is_locked: response.data?.data?.project_details[0]?.is_locked || false,
              is_approved: response.data?.data?.project_details[0]?.is_approved || false,
              is_draft: response.data?.data?.project_details[0]?.is_draft || false,
              ...response.data?.data?.project_details[0]
            })

            this.tableData = response.data?.data?.user_projects.map(project => {
              if(response.data?.data?.project_hours.length > 0) {
                const existingProject = response.data?.data?.project_hours.find((projectHour) => projectHour.project_id === project.id)
                if(existingProject) {
                  return {
                    project_id: existingProject.project_id,
                    project_hours: this.getProjectHours({existingProjectHours: existingProject.project_hours}),
                    project_title: existingProject.project_title,
                    cost_center_number: existingProject.cost_center_number
                  }
                }
              }
              return {
                project_id: project.id,
                project_title: project.project_title,
                cost_center_number: project.cost_centre__cost_center_number,
                project_hours: this.getProjectHours({existingProjectHours: []})
              }
            })

            //Add static fields
            const staticFields = ['without_project', 'leave', 'working_hours', 'travel']
            staticFields.forEach((field) => {
              const staticField = response.data?.data?.project_hours.find((project) => !project.project_id && project[field])
              if(staticField) {
                this[field] = {...staticField, project_hours: this.getProjectHours({existingProjectHours: staticField.project_hours, isTravel: field === 'travel'})}
              } else {
                this[field].project_id = ''
                this[field].project_title = ''
                this[field].cost_center_number = ''
                this[field].project_hours = this.getProjectHours({existingProjectHours: [], isTravel: field === 'travel'})
                this[field][field] = true
              }
            })
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingData = false
          })
    },
    handleClick() {
      if (this.isView) {
        this.$emit('close')
      } else {
        this.saveProjectHours({isDraft: true})
      }
    },
    validateProjectFields() {
      this.tableData.forEach((project) => {
        project.is_error = project.project_id === '';
      })
      return this.tableData.every((project) => project.project_id)
    },
    saveProjectHours({isDraft = true}) {

      if(!this.validateProjectFields()) {
        this.alertState.show = true
        this.alertState.message = 'Please select a project for each row'
        this.alertState.type = 'error'
        setTimeout(() => {
          this.alertState.show = false
        }, 3000)
        return
      }

      const payload = this.tableData.map((project) => {
        return {
          project_id: project.project_id,
          project_hours: project.project_hours.filter((project) => project.hours !== '')
        }
      }).filter((project) => project.project_id !== '')
      payload.push({
        without_project: true,
        project_hours: this.without_project.project_hours.filter((hour) => hour.hours !== '')
      })
      isDraft ? this.savingData = true : this.submittingData = true
      Api()
          .post(createProjectHour, {
            user_key: this.userKey,
            year: this.year,
            month: this.month,
            project_hours_details: payload,
            is_draft: isDraft ? 1 : 2
          })
          .then((response) => {
            this.alertState.show = true
            this.alertState.message = response.data?.detail
            this.alertState.type = 'success'
            if (!isDraft) {
              this.$emit('onUpdate')
            }
          })
          .catch((error) => {
            this.alertState.show = true
            this.alertState.message = error.response.data?.detail
            this.alertState.type = 'error'
          })
          .finally(() => {
            // this.initiate()
            isDraft ? this.savingData = false : this.submittingData = false
            setTimeout(() => {
              this.alertState.show = false
            }, 3000)
          })
    }
  },
  mounted() {
    this.initiate()
  },
  watch: {
    year() {
      this.initiate()
    },
    month() {
      this.initiate()
    }
  }
})
</script>

<template>
  <app-alert
      :state="alertState.show"
      :type="alertState.type"
      :title="$t(alertState.message.getTranslationKey())"
      :timeout="3000"
      @close="alertState.show = false"
  ></app-alert>
  <app-dialog
      class="!m-0"
      ref="lockModal"
      :title="`${$t('Lock'.getTranslationKey())} ${$t(monthNames[month - 1].getTranslationKey())} ${year}?`"
      :subtitle="$t('Are you sure you want to lock this month and year? You won’t be able to edit this data again.'.getTranslationKey())"
  >
    <template #actions>
      <div class="w-full flex items-center">
        <app-secondary-button
            class="w-full"
            :button-label="$t('No, Keep it'.getTranslationKey())"
            @click="$refs.lockModal.showDialog = false"
        ></app-secondary-button>
        <v-col cols="6" class="">
          <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal w-full"
              @click="lockAndUnlock()"
          >
            {{projectDetails.is_locked ?  $t("Yes , Unlock.".getTranslationKey()) : $t("Yes , Lock.".getTranslationKey()) }}
          </v-btn>
        </v-col>
      </div>
    </template>
  </app-dialog>
  <div
      class="bg-white py-6"
      v-if="!loadingData"
  >
    <div v-if="!isView" class="mb-[18px] flex justify-between items-center">
      <span class="text-[#344054] font-semibold text-[16px]">{{`${$t(monthNames[month - 1].getTranslationKey())}, ${year}`}}</span>
      <button
          v-if="isAdmin"
          form=""
          class="text-sm text-[#667085] flex items-center gap-2 border border-[#EAECF0] rounded-lg px-4 py-[10px]"
          @click="handleLock"
      >
        <v-icon :icon="projectDetails.is_locked ? 'custom:LockedIcon' : 'custom:UnlockedIcon'" size="small"></v-icon>
        <span>{{projectDetails.is_locked ? $t('Unlock this month'.getTranslationKey()) : $t('Lock this month'.getTranslationKey())}}</span>
      </button>
    </div>
    <table :class="(isView && disabled) || projectDetails.is_locked ? 'bg-[#F9FAFB]' : ''">
      <thead>
      <tr>
        <th rowspan="2" class=" text-[14px]">{{$t('Project / Cost Center'.getTranslationKey())}}</th>
        <th rowspan="2" class="w-[64px] text-wrap text-[14px]">{{$t('Total Hours'.getTranslationKey())}}</th>
        <th class="text-[12px]" v-for="(day, index) in getDays.dayNames" :key="index">
          {{day}}
        </th>
      </tr>
      <tr>
        <th class="text-[12px]" v-for="(day, index) in getDays.dayNumbers" :key="index">
          {{day}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(tableObject, index) in tableData"
          :key="index"
      >
        <td :class="[{'error': tableObject.is_error}, 'px-2 font-medium']">
          <div
              @mouseenter="!projectDetails.is_locked ? hoveredItemIndex = index : ''"
              @mouseleave="!projectDetails.is_locked ? hoveredItemIndex = null : ''"
              class="flex items-center gap-3">
            <div class="!w-[300px]">
              <app-single-select
                  no-outline
                  :options="allProjects"
                  v-model="tableObject.project_id" class="!w-full"
                  :is-label="false" container-height="40px"
                  :label-placeholder="$t('Select Project / Cost Center'.getTranslationKey())"
                  show-search
                  :disabled="disabled"
                  :has-error="tableObject.is_error"
                  error-message=""
                  @update:modelValue="tableObject.is_error = false"
                  :styles="{background: disabled ? '#F9FAFB !important' : 'white'}"
              >

              </app-single-select>
            </div>
            <div class="!w-[15px]">
              <v-icon @click="deleteRow(index)" v-if="hoveredItemIndex === index" class="cursor-pointer opacity-50" color="error" icon="custom:redClose"></v-icon>
            </div>
          </div>
        </td>
        <td class="text-center bg-gray-50">
          <span class="w-full">{{getTotalHours({tableObject, index})}}</span>
        </td>
        <td
            v-for="(day, dayIndex) in tableObject.project_hours"
            :key="dayIndex"
            class="text-center text-[#344054] text-[12px]"
        >
          <input
              class="!w-full !h-full border-none outline-none"
              type="number"
              v-model="day.hours"
              @keydown="checkNumberInput"
              :disabled="disabled"
              min="0"
          >
        </td>
      </tr>
      <tr
          v-for="(rowData, index) in staticRows"
          :key="rowData.type"
          :class="[{'bg-gray-50': rowData.type !== 'without_project'}, 'whitespace-nowrap']"
      >
        <td class="px-4 py-[10px] font-medium">{{$t(rowData.name.getTranslationKey())}}</td>
        <td class="text-center bg-gray-50">
          <span class="w-full">{{rowData.type === 'working_hours' ? 0 : getTotalHours({tableObject: rowData.data, type: rowData.type})}}</span>
        </td>
        <td
            v-for="(project_hour, dayIndex) in rowData.data.project_hours" :key="project_hour.day"
            class="text-center text-[12px]"
        >
          <input
              v-if="rowData.type === 'without_project' "
              class="!w-full h-full text-center text-[#344054] text-[12px] border-none outline-none"
              type="number"
              v-model="project_hour.hours"
              @keydown="checkNumberInput"
              :disabled="disabled"
              min="0"
          >
          <div v-else-if="rowData.type === 'travel'" class="w-full text-[12px] border-none outline-none flex items-center justify-center">
            <v-icon v-if="project_hour.travelled" class="" icon="custom:grayCheck" ></v-icon>
          </div>
          <div v-else-if="rowData.type === 'working_hours'">
            {{`${getTotalDailyWorkingHours(dayIndex)}/${dailyWorkingHours[project_hour.name] ? dailyWorkingHours[project_hour.name] : 0}`}}
          </div>
          <div v-else-if="rowData.type === 'leave'">
            <span>{{project_hour.hours}}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="!(isView && disabled)">
      <button
          class="text-[#667085] flex items-center gap-2 ml-auto mt-4 disabled:cursor-not-allowed"
          form=""
          :disabled="disabled"
          @click.prevent="addMore({type: 'dynamic'})"

      >
        <v-icon icon="mdi-plus"></v-icon>
        <span>{{$t('Add More'.getTranslationKey())}}</span>
      </button>
      <div class="ml-auto w-max flex items-center gap-3 mt-6">
        <button v-if="!isView" @click="$router.go(-1)" form="" class="text-[#667085]">
          {{$t('Cancel'.getTranslationKey())}}
        </button>
        <div>
          <app-secondary-button
              :button-label="!isView ? $t('Save'.getTranslationKey()) : $t('Cancel'.getTranslationKey())"
              class="w-max"
              @click="handleClick"
              :loading="savingData"
              :disabled="disabled"
              @mouseenter="handleShowTooltip($event, 'save')"
              @mouseleave="hideToolTip"
          ></app-secondary-button>
          <div
              style="display: none; position: absolute;"
              class="bg-gray-100 text-gray-500 text-xs border border-black w-[200px]"
          >
            {{$t(submitTooltipMessage.getTranslationKey())}}
          </div>
        </div>
        <div>
          <app-primary-button
              :button-label="!isView ? $t('Submit'.getTranslationKey()) : $t('Save'.getTranslationKey())"
              class="w-max"
              @click="saveProjectHours({isDraft: disabled})"
              :loading="submittingData"
              :disabled="disableSubmit()"
              @mouseenter="handleShowTooltip($event, 'submit')"
              @mouseleave="hideToolTip"
              :style="{cursor: disableSubmit() ? 'not-allowed' : 'pointer'}"
          >
          </app-primary-button>
          <div
              style="display: none; position: absolute;"
              class="bg-gray-100 text-gray-500 text-xs border border-black w-[200px]"
          >
            {{$t(submitTooltipMessage.getTranslationKey())}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full h-80 flex items-center justify-center">
    <app-loader></app-loader>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  color: #344054;
}
th {
  font-weight: 500;
  text-align: center;
  border: 1px solid #EAECF0;
}
td {
  height: 40px;
  border-top: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  border-left: 1px solid #EAECF0;
}

tr:last-child {
  border-bottom: 1px solid #EAECF0;
}

input[type=number] {
  border: none;
  outline: none;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.error {
  z-index: 10;
  border: 1px solid red;
}
</style>