<!-- eslint-disable vue/valid-v-model -->
<template>
  <div v-for="(section, index) in budgetSections" :key="index">
    <v-sheet max-width="" class="px-3 pb-3 mt-5 bg-white rounded-lg border">
      <!-- accordion header -->
      <v-row class="pl-4" v-if="section.title === 'Mandatory'">
        <v-col cols="10" class="d-flex jusitfy-start align-center">
          <div
            class="w-full py-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
            type="text"
          >
            {{ section.title }}
          </div>
        </v-col>
      </v-row>
      <v-row class="" v-else>
        <v-col cols="9" class="d-flex align-center">
          <div class="d-flex align-center cursor-pointer">
            <div
              @click="showAccordionContent = !showAccordionContent"
              class="d-flex align-center cursor-pointer"
            >
              <v-icon
                size="20"
                :icon="
                  showAccordionContent
                    ? 'custom:expandLess'
                    : 'custom:expandIcon'
                "
              />
              <!-- header name -->
              <div
                class="w-full py-1 px-4 ml-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
                type="text"
              >
                {{ section.title }}
              </div>
            </div>
            <!-- action button -->
            <v-btn
              class="ma-2"
              variant="text"
              icon="custom:editIcon"
              color="#667085"
              size="small"
              @click="$emit('edit-budget-section', section)"
              :disabled="!isEditButton"
            ></v-btn>
            <v-btn
              class="ml-n4"
              variant="text"
              icon="custom:trashIcon"
              color="#667085"
              size="small"
              :disabled="section?.budget_items?.length > 0 || !isEditButton"
              @click="$emit('delete-budget-section', section)"
            ></v-btn>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end">
          <v-btn
            variant="text"
            color="#667085"
            class="!normal-case float-right !text-[0.875rem] !font-medium"
            @click="addBudgetItem(index)"
            :disabled="!isEditButton"
          >
            <v-icon icon="mdi-plus" size="20" class="mr-2"></v-icon>
            {{ $t("projectBudget.addBudgetItem") }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- {{ section }} -->
      <!-- accordion content -->
      <div v-show="showAccordionContent" class="mt- mb-7">
        <budget-table
          ref="budgetTableComponent"
          :headers="isTableHeaders"
          :budgetItems="section?.budget_items"
          :section-id="section?.id"
          :section-title="section?.title"
          :tableIndex="index"
          @total-items="handleTotals()"
        ></budget-table>
      </div>

      <!-- accordion footer-->
      <v-row class="bg-[#F9FAFB] border-t-[1px] rounded-b-lg pl-2 pr-1">
        <v-col cols="6">
          <div class="d-flex align-center text-[#1D2939]">
            <p class="text-[0.875rem] pr-1">
              {{ $t("projectBudget.margin") }}:
            </p>
            <p class="text-[0.875rem] text-bold font-bold">
              {{ isCurrentProject.currency }}
              {{
                appLang === "de"
                  ? germanCurrency(isBudgetData[index]?.profit_margin)
                  : isBudgetData[index]?.profit_margin?.formatCurrency()
              }}
              ({{
                isBudgetData[index]?.profit_margin_percentage > 0
                  ? isBudgetData[index]?.profit_margin_percentage?.toFixed(2)
                  : 0
              }}%)
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="d-flex align-center text-[#1D2939] float-right">
            <p class="text-[0.875rem] pr-1">
              {{ $t("projectBudget.income") }}:
            </p>
            <p class="text-[0.875rem] text-bold font-bold">
              {{ isCurrentProject.currency }}
              {{
                appLang === "de"
                  ? germanCurrency(isBudgetData[index]?.total_income)
                  : isBudgetData[index]?.total_income?.formatCurrency()
              }}
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="d-flex align-center text-[#1D2939] float-right">
            <p class="text-[0.875rem] pr-1">{{ $t("projectBudget.cost") }}:</p>
            <p class="text-[0.875rem] text-bold font-bold">
              {{ isCurrentProject.currency }}
              {{
                appLang === "de"
                  ? germanCurrency(isBudgetData[index]?.total_cost)
                  : isBudgetData[index]?.total_cost?.formatCurrency()
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import { getTransString,  currencyFormatter } from '@/services/helpers'
import { mapState } from "vuex";
import BudgetTable from './BudgetTable.vue';

export default defineComponent({
  components: {
    BudgetTable
  },
  props: {
    accordionInfo: {
      type: Object,
    },
  },
  data() {
    return {
      showAccordionContent: false,
      title: 'Accommodation',
      totalItems: {},
      result: 0,
      backstopping: 0,
    }
  },
  computed: {
    budgetSections() {
      return this.$store.getters['projectBudgetModules/isBudgetSections']
    },
    isBudgetData() {
      return this.$store.getters['projectBudgetModules/isBudgetData']
    },
    getBackstopping() {
      return this.$store.getters['projectBudgetModules/getBackstopping']
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    contractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),

    totalStatistics() {
      return this.$store.getters["projectBudgetModules/isTotalStatistics"];
    },
    getIncomeCostPair() {
      return this.$store.getters["projectBudgetModules/getIncomeCostPair"];
    },
  },

  watch: {
    getBackstopping(newVal){
      this.backstopping = newVal
    }
  },

  methods: {
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleTotals() {

      let grandTotalCost = 0;
      let grandTotalIncome = 0;
      this.$store.commit('projectBudgetModules/SET_BACKSTOPPING')
      this.backstopping = this.getBackstopping

      for (const key in this.getIncomeCostPair) {

          grandTotalCost += this.getIncomeCostPair[key].total_cost;
          grandTotalIncome += this.getIncomeCostPair[key].total_income;
      }

      let payload = {
        total_cost: grandTotalCost,
        total_income: grandTotalIncome,
        profit_margin_1: (grandTotalIncome - grandTotalCost),
        profit_margin_2: (grandTotalIncome - grandTotalCost) + this.backstopping,
        total_budget: grandTotalIncome,//same as total budget == grandTotalIncome
      }

      this.$store.commit('projectBudgetModules/SET_ALL_PROJECT_BUDGETS_STATISTICS', payload)

    },

    addBudgetItem(index) {
      this.$refs.budgetTableComponent[index]?.addNewRow()
    },

  },
  created() {
    this.showAccordionContent = true
    this.$store.commit('projectBudgetModules/SET_BACKSTOPPING')
    this.backstopping = this.getBackstopping
  }
})
</script>
<style scoped></style>
