<template>
  <div class="input_box">
    <div class="py-2 label">
      <label>{{ label }}</label>
    </div>
    <div class="relative">
      <select
        :disabled="disabled"
        :class="className"
        :required="requireTag"
        class="placeholder selectborder bg-gray-50 cursor-pointer hover:border-[#d8dae5] focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] focus:ring peer block dark:bg-gray-700 rounded-md border-1 border-solid border-gray-300 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);"
        :placeholder="placeholder"
        :value="modelValue"
        @change="[$emit('update:modelValue', $event.target?.value)]"
        autocapitalize="on"
      >
        <option
          v-if="placeholder"
          id="default"
          data-cy="optionType"
          value=""
          selected
        >
          {{
            isTranslation
              ? $t(getTranslatedStringKey(placeholder))
              : placeholder
          }}
        </option>
        <option
          v-for="(option, i) in options"
          :key="option.id || i"
          :value="option.id ? option.id : option"
        >
          {{ option[param] ? option[param] : option }}
        </option>
      </select>
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
      >
        <v-icon icon="custom:expandIcon" />
      </div>
      <div
        class="flex item-center absolute right-0 mr-2 transform -translate-y-1/2 top-1/2"
        v-if="error"
      >
        <v-icon size="20" icon="custom:errorIcon" />
      </div>
    </div>
    <p v-if="error" class="text-red-500 text-[14px]">
      {{ $t(`${errorMessage.getTranslationKey()}`) }}
    </p>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {getTransString} from "@/services/helpers";
// import type { PropType } from 'vue'

export default defineComponent({
  name: "App select field",
  props: {
    type: {
      type: String,
      default: "text",
    },
    className: {
      type: String,
      default: "select",
    },
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    requireTag: {
      type: Boolean,
      default: false,
    },
    param: {
      type: String,
      default: "name",
    },
    options: {
      type: Array,
    },
    param_id: {
      type: String,
    },
    isTranslation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      error: false,
    }
  },
  watch:{
    hasError(val){
      this.error = val
    }
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
  }
});
</script>

<style scoped>
.input_box {
  width: 100%;
}

.select {
  background-color: white;
  /* background-color: #f9fafb;
  color: #344054; */
  color: #101828;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
select::placeholder {
  color: #667085;
}
.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

select {
  width: 400px;
  padding: 10px;
}
.select:focus {
  background: #ffffff;

  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}

.select:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
</style>
