<template>
  <div>
    <div class="flex items-center inputs gap-7 SPC-MT-200">
      <div class="w-[440px]">
        <app-single-select
          id="selectApprove"
          :showCheckbox="false"
          :showSearch="true"
          :options="getAllEmployees"
          :labelPlaceholder="selectedName || $t('leaves.selectApprover')"
          :label="$t('leaves.leaveApprover')"
          v-model="selectedName"
        ></app-single-select>
      </div>
      <div class="w-[440px]">
        <app-date-field
          :label="$t('leaves.carryoverEndDate')"
          :placeholder="$t('employment.addAccess')"
          type="date"
          :min="minDate"
          v-model="carryover"
        ></app-date-field>
      </div>
    </div>
    <div class="flex items-center inputs gap-7 SPC-MT-200">
      <div class="w-[440px]">
        <app-input-field
          :label="$t('leaves.earned')"
          :placeholder="$t('leaves.addEarned')"
          type="number"
          v-model="earned"
        ></app-input-field>
      </div>
      <div class="w-[440px]">
        <app-input-field
          :label="$t('leaves.carried')"
          :placeholder="$t('leaves.addCarried')"
          type="number"
          v-model="carried"
        ></app-input-field>
      </div>
    </div>
    <div class="w-[906px] SPC-MT-200">
      <app-text-area
        :label="$t('leaves.comments')"
        :placeholder="$t('leaves.addComment')"
        type="text"
        v-model="comment"
      ></app-text-area>
    </div>
    <div class="w-[906px] flex justify-between items-center">
      <div>
        <h3
          @click="show = false"
          v-if="show"
          class="text-gray-600 cursor-pointer"
        >
          <u>{{ $t("leaves.hideComment") }}</u>
        </h3>
        <h3
          @click="show = true"
          v-if="!show"
          class="text-gray-600 cursor-pointer"
        >
          <u>{{ $t("leaves.showComment") }}</u>
        </h3>
      </div>

      <app-primary-button
        class="mt-9"
        @click-event="addApprover()"
        :buttonLabel="$t('leaves.save')"
      />
    </div>
    <div class="" v-if="show">
      <div
        class="py-2"
        v-for="(comments, index) in existingComments"
        :key="index"
      >
        <div class="flex items-center">
          <h3>{{ comments.user }}</h3>
          <p class="P200">• {{ formattedDate(comments.date) }}</p>
        </div>
        <p class="P200">{{ comments.comment }}.</p>
      </div>
    </div>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { setApprover, getDefaultApprover } from "@/services/endpoints";
import { isEqual } from "lodash";
export default defineComponent({
  data() {
    return {
      show: false,
      defaultCarryOver: "",
      currentPages: null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      options: [""],
      firstRun: true,
      placeholder: "",
      carryover: " ",
      selectedEmployee: null,
      selectedItems: "",
      approvers: [],
      selectedName: "",
      isLeavedeleteId: "",
      originalDate: new Date("2023-03-31"),
      carried: "",
      earned: "",
      comment: "",
      existingComments: [],
    };
  },
  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    isHr() {
      return this.$store.getters["auth/ishr"];
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isEmployeeName() {
      return this.$store.getters["hrResources/isEmployeeName"];
    },
    getAllEmployees() {
      const employees = this.$store.getters["hrResources/isAllEmployees"];
      return employees.map((employee) => ({
        text: employee.first_name + " " + employee.last_name,
        value: employee.employee_id,
      }));
    },
  },
  watch: {
    inputDependencies: {
      immediate: true,
      handler(newValue) {
        if (!this.firstRun) {
          this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
            Leaves: !isEqual(newValue, [null, this.defaultCarryOver]),
          });
        }
      },
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    formattedDate(dateString) {
      return moment(dateString).format("Do MMM YYYY");
    },
    transText(text) {
      const { t } = useI18n();
      return t(getTransString(text));
    },
    handleselect(data) {
      this.selectedEmployee = data?.$event?.value;
    },
    addApprover() {
      const processedComments = [];
      let fetchProcessedComments = [];
      for (const comment of this.existingComments) {
        const transformedComment = {
          user: comment.user,
          comment: comment.comment,
          date: comment.date,
        };
        processedComments.push(transformedComment);
        fetchProcessedComments.push(transformedComment);
      }

      const currentDate = moment().format("YYYY-MM-DD");
      const newUserComment = {
        user: this.isEmployeeName,
        comment: this.comment,
        date: currentDate,
      };
      processedComments.unshift(newUserComment);
      const payload = {
        user_key: this.isUserKey,
        leave_approver_key: this.selectedName,
        carry_over_end_date: this.carryover,
        earned_leaves: this.earned,
        carried_over_leaves: this.carried,
        comments:
          processedComments[0].comment === ""
            ? fetchProcessedComments?.length === 0
              ? []
              : fetchProcessedComments
            : processedComments,
      };
      Api()
        .post(`${setApprover}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.getAllApprovers();
            this.alertTitle = response.data.detail;
            this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", {
              Leaves: false,
            });
            this.$emit("is-saved", true);
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    getAllApprovers() {
      const userKey = this.isUserKey;
      const payload = {
        user_key: userKey,
      };
      return Api()
        .post(`${getDefaultApprover}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.approvers = response.data.data;

            this.selectedName = this.approvers.leave_approver_user_key;
            const defaultDate =
              this.approvers.carry_over_end_date == null
                ? this.sameDate
                : new Date(this.approvers.carry_over_end_date);
            this.carried = this.approvers.carried_over_leaves;
            this.earned = this.approvers.earned_leaves;
            this.existingComments = this.approvers.comments;
            this.carryover = defaultDate.toISOString().slice(0, 10);
            if (this.firstRun) {
              this.defaultCarryOver = defaultDate.toISOString().slice(0, 10);
              this.firstRun = false;
            }
            this.minDate = this.sameDate;
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("hrResources/getAllEmployees");
    this.getAllApprovers();
  },
});
</script>
<style scoped></style>
