
import Api from "@/services/api";
import { ActionContext } from "vuex";
import {getAllExpenses}from "@/services/endpoints";
export default {
    namespaced: true,
    state: {
      currentPage: 1,
      totalPages: null,
      totalData: null,
      allExpenses: [],
      addExpense: false,
      appLoading: false,
      chequeCurrency: [],
      chequeBudgetPosition: [],
      isEditExpense: false,
      isExpenseEditId: null,
      headers: [
        "Title",
        "Doc. Date",
        "Pay Date",
        "Supplier",
        "Currency",
        "Amt Excl. Tax",
        "Amt Incl. Tax",
        "Budget Position",
        "File",
        "Audit Status",
        "",
      ],
    },
    mutations: {
      SET_EXPENSES_TO_STATE(state: any, payload: any) {
        state.allExpenses = payload;
      },
      SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
        state.currentPage = payload;
      },
      SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
        state.totalPages = payload;
      },
      SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
        state.totalData = payload;
      },
      SET_ADD_EXPENSE_TO_STATE(state: any, payload: any) {
        state.addExpense = payload;
      },
      SET_APP_LOADING_TO_STATE(state: any, payload: any) {
        state.appLoading = payload;
      },
      SET_CHEQUE_CURRENCY_TO_STATE(state: any, payload: any) {
        state.chequeCurrency = payload;
      },
      SET_CHEQUE_BUDGET_POSITION_TO_STATE(state: any, payload: any) {
        state.chequeBudgetPosition = payload;
      },
      SET_EDIT_EXPENSE_TO_STATE(state: any, payload: any) {
        state.isEditExpense = payload;
      },
      SET_EXPENSE_EDIT_ID_TO_STATE(state: any, payload: any) {
        state.isExpenseEditId = payload;
      }
    },
    getters: {
      isAllExpenses(state: any) {
        return state.allExpenses;
      },
      isCurrentPage(state: any) {
        return state.currentPage;
      },
      isTotalPages(state: any) {
        return state.totalPages;
      },
      isTotalData(state: any) {
        return state.totalData;
      },
      isAddExpense(state: any) {
        return state.addExpense;
      },
      appLoading(state: any) {
        return state.appLoading;
      },
      isChequeCurrency(state: any) {
        return state.chequeCurrency;
      },
      isChequeBudgetPosition(state: any) {
        return state.chequeBudgetPosition;
      },
      isEditExpense(state: any) {
        return state.isEditExpense;
      },
      isExpenseEditId(state: any) {
        return state.isExpenseEditId;
      },
      isHeaders(state: any) {
        return state.headers;
      }
    },
    actions: {
      getAllExpenses(context: ActionContext<any, any>, payload: any) {
        if (payload.load_page) {
          context.commit("SET_APP_LOADING_TO_STATE", true);
        }
        Api()
          .post(`${getAllExpenses}${payload.foreign_establishment_id}/${payload.expense_type}/`, {month: payload.month, year: payload.year})
          .then((response: any) => {
            if (response) {
              context.commit("SET_EXPENSES_TO_STATE", response.data);
              
              if(response.data.expense_data?.length > 0){
                context.commit("SET_EDIT_EXPENSE_TO_STATE", true)
              } else {     
                context.commit("SET_EDIT_EXPENSE_TO_STATE", false)
                     }
              context.commit(
                "SET_CURRENT_PAGE_TO_STATE",
                response.data.expense_data.current_page
              );
              context.commit(
                "SET_TOTAL_PAGES_TO_STATE",
                response.data.expense_data.total_data
              );
              context.commit("SET_APP_LOADING_TO_STATE", false);
            }
          })
          .catch((error: unknown) => {
            if (error) {
              context.commit("SET_APP_LOADING_TO_STATE", false);
            }
          });
      },
      filterAllExpensesByMonthAndYear(context: ActionContext<any, any>, payload: any) {
        if (payload.load_page) {
          context.commit("SET_APP_LOADING_TO_STATE", true);
        }
        Api()
          .post(`${getAllExpenses}${payload.foreign_establishment_id}/${payload.expense_type}/`, {month: payload.month, year: payload.year})
          .then((response: any) => {
            if (response) {
              context.commit("SET_EXPENSES_TO_STATE", response.data);
              
              if(response.data.expense_data?.length > 0){
                context.commit("SET_EDIT_EXPENSE_TO_STATE", true)
              } else {     
                context.commit("SET_EDIT_EXPENSE_TO_STATE", false)
                     }
              context.commit(
                "SET_CURRENT_PAGE_TO_STATE",
                response.data.expense_data.current_page
              );
              context.commit(
                "SET_TOTAL_PAGES_TO_STATE",
                response.data.expense_data.total_data
              );
              context.commit("SET_APP_LOADING_TO_STATE", false);
            }
          })
          .catch((error: unknown) => {
            if (error) {
              context.commit("SET_APP_LOADING_TO_STATE", false);
            }
          });
      },
    }
  };
  
  