<template>
  <v-container fluid>
    <v-row>
      <v-col class="py-1">
        <h3 class="H700">{{ $t("homeview.welcome") }} {{ isUsername }}</h3>
      </v-col>
    </v-row>
    <v-row class="justify-baseline">
      <v-col md="9">
        <v-sheet
          class="flex-wrap p-2 mb-6 align-center"
          elevation="0"
          height="auto"
          rounded
          width="100%"
        >
          <div class="flex-column">
            <h3 class="py-3 H500">{{ $t("homeview.todoTitle") }}</h3>
            <div
              v-if="visibleTodos?.length === 0"
              style="border-bottom: 1px solid #eaecf0; width: 100%"
            ></div>
            <div>
              <div v-if="visibleTodos?.length > 0">
                <div v-for="item in visibleTodos" :key="item.id">
                  
                  <todo-card
                  @open-event="openTodo(item)"
                  :title="item?.title"
                  :due-date="item?.due_date"
                  :todoType="item?.category"
                  :content="item?.content"
                  :created-date="item?.created_at"
                  @delete-event="deleteTodo(item?.id)"
                  />
                </div>
              </div>
              <div
                class="text-center flex items-center justify-center flex-col h-[136px]"
                v-else
              >
                <h3 class="H500">{{ $t("homeview.notodos") }}</h3>
                <p class="P300">{{ $t("homeview.notodosMessage") }}</p>
              </div>
            </div>

            <button v-if="showMoreButton1" @click="toggleTodos">
              {{ expanded1 ? $t("homeview.seeLess") : $t("homeview.showMore") }}
            </button>
          </div>
        </v-sheet>
        <v-sheet
          class="flex-wrap p-2 mb-6 align-center"
          elevation="0"
          height="auto"
          rounded
          width="100%"
        >
          
            
            <PmLocals/>
          
        </v-sheet>

        <v-sheet
          class="flex-wrap p-2 mb-6 align-center"
          elevation="0"
          height="auto"
          rounded
          width="100%"
          v-if="isTeamSchedule?.length"
        >
        <!-- {{ isTeamSchedule  }} -->
          <div class="flex-column">
            
            <TeamSchedule
            :schedule="isTeamSchedule"
          />

          </div>
        </v-sheet>
        <v-sheet
          class="flex-wrap p-2 mb-6 align-center"
          elevation="0"
          height="auto"
          rounded
          width="100%"
          v-if="isTeamSchedule?.length"
        >
       
          <div class="flex-column">
            <!-- <h3 class="py-3 H500">{{ $t("homeview.teamSchedule") }}</h3> -->
            <AbsenceCard
            :schedule="isTeamSchedule"
          />

           
          </div>
        </v-sheet>
      </v-col>

      <v-col md="3">
       
      </v-col>
    </v-row>
  </v-container>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";
import TodoCard from '@/components/dashboard/home/TodosCard.vue'

import { getTransString } from "@/services/helpers";
import Api from "@/services/api";
import moment from 'moment';
import AbsenceCard from "@/components/dashboard/home/AbsenceCard.vue";
import TeamSchedule from "@/components/dashboard/home/TeamSchedule.vue";
// import api from "@/services/api";
import { deleteAnnouncement } from "@/services/endpoints";
import PmLocals from "../pm-locals/PmLocals.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    TodoCard,
    // NotificationCard,
    // Announcement,
    // Celebration,
    PmLocals,
    AbsenceCard,
    TeamSchedule
  },
  data() {
    return {
      maxVisibleItems: 5,
      maxVisibleItemsTodos: 5,
      maxVisibleItemsAnouncement: 5,
      maxVisibleItemsToday_leave: 5,
      maxVisibleItems_tomorrow: 5,
      maxVisibleItems_week: 5,
      data: [],
      announcementsArray:[],
      expanded: false,
      expanded1: false,
      expanded2: false,
      expanded3: false,
      expanded4: false,
      expanded5: false,
      expandAbsentToday: false,
      expandAbsentTomorrow: false,
      absentData: [],
      initialAbsentToday: 5,
      initialAbsentTomorrow: 5,
      defaultCardItemsLength: 1,
      defaultCardItems: {
        teamSchedule: 1
      },
      alertType: "success",
      alertState: false,
      alertTitle: "",
      projectId: this.$route.query.id,
      currentPages: 1,
    };
  },

  computed: {
    modifiedText() {
      return this.text.replace("Approval", "");
    },

    isUsername() {
      return this.$store.getters["auth/isUsername"];
    },
    isTeamSchedule() {
      return this.$store.getters["auth/isTeamSchedule"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    // for notification
    visibleNotifications() {
      return this.data.user_notification?.slice(0, this.maxVisibleItems);
    },
    showMoreButton() {
      return this.maxVisibleItems < this.data.user_notification?.length || this.expanded;
    },
    // for todos
    visibleTodos() {
      return this.data.user_to_dos?.slice(0, this.maxVisibleItemsTodos);
    },
    showMoreButton1() {
      return this.maxVisibleItemsTodos < this.data.user_to_dos?.length || this.expanded1;
    },
    isAllAnnouncements() {
      const announce = [];
      const announcementsArray =
        this.$store.getters["hrResources/isAllAnnouncements"];
      announcementsArray.map((singleAnnouncement) => {
        announce.push(singleAnnouncement);
      });
      return announce;
    },

    visibleAnouncement() {
      return this.data.announcements?.slice(0, this.maxVisibleItemsAnouncement);
    },
    showMoreButton2() {
      return this.maxVisibleItemsAnouncement < this.data.announcements?.length || this.expanded2;
    },
    visibleleaveToday() {
      return this.data.on_leave_day?.slice(0, this.maxVisibleItemsToday_leave);
    },
    showMoreButton3() {
      return this.maxVisibleItemsToday_leave < this.data.on_leave_day?.length || this.expanded3;
    },
    visibleleaveTomorrow() {
      return this.data.on_leave_tomorrow?.slice(0, this.maxVisibleItems_tomorrow);
    },
    showMoreButton4() {
      return this.maxVisibleItems_tomorrow < this.data.on_leave_tomorrow?.length || this.expanded4;
    },
    visibleleaveNextweek() {
      return this.data.on_leave_next_week?.slice(0, this.maxVisibleItems_week);
    },
    showMoreButton5() {
      return this.maxVisibleItems_week < this.data.on_leave_next_week?.length || this.expanded5;
    },
    absenceSlice() {
      return this.isAllEmployeesAbsent?.slice(0, this.initialAbsence)
    },
    showMoreAbsence() {
      return this.initialAbsentToday < this.absentData?.absences_today?.length || this.expandAbsentToday
    },
    showMoreAbsentTommorow() {
      return this.initialAbsentTomorrow < this.absentData?.absences_tomorrow?.length || this.expandAbsentTomorrow
    },
    isAllEmployeesAbsent() {
      const absentArray = []
      const absentData = this.$store.getters["hrResources/isAllEmployeesAbsent"]
      absentData.map(transformedData => {
        absentArray.push(transformedData)
      })
      return absentArray
    },
    isAllEmployeesAbsentToday() {
      return this.absentData?.absences_today?.slice(0,this.initialAbsentToday)
    },
    isAllEmployeesAbsentTomorrow() {
      return this.absentData?.absences_tomorrow?.slice(0,this.initialAbsentTomorrow)
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getCurrentData(data, type) {
      return (data || []).slice(0, this.defaultCardItems[type])
    },
    isMoreActive(data, type) {
      return data?.length > this.defaultCardItems[type];
    },
    seeMore(data, type) {
      if (data?.length > this.defaultCardItems[type]) {
        this.defaultCardItems[type] = data?.length;
      } else {
        this.defaultCardItems[type] = this.defaultCardItemsLength;
      }
    },
    formattedDate(dateString) {
      return moment(dateString).format('MMM DD');
    },
    showAllItems() {
      this.maxVisibleItems = this.data.user_notification?.length;
    },
    toggleExpand() {
      this.expanded = !this.expanded;

      if (this.expanded) {
        this.maxVisibleItems = this.data.user_notification.length;
      } else {
        this.maxVisibleItems = 5;
      }

    },
    formattedAbsenceDate(dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    // user todos
    showAllTodos() {
      this.maxVisibleItemsTodos = this.data.user_to_dos?.length;
    },
    toggleTodos() {
      this.expanded1 = !this.expanded1;

      if (this.expanded1) {
        this.maxVisibleItemsTodos = this.data.user_to_dos.length;
      } else {
        this.maxVisibleItemsTodos = 5;
      }

    },
    // showAllAnouncement() {
    //   this.maxVisibleItemsAnouncement = this.isAllAnnouncements?.length;
    // },
    // toggleAnnouncement() {
    //   this.expanded2 = !this.expanded2;

    //   if (this.expanded2) {
    //     this.maxVisibleItemsAnouncement = this.isAllAnnouncements.length;
    //   } else {
    //     this.maxVisibleItemsAnouncement = 5;
    //   }

    // },
    showAllAnouncement() {
      this.maxVisibleItemsAnouncement = this.data.announcements?.length;
    },
    toggleAnnouncement() {
      this.expanded2 = !this.expanded2;

      if (this.expanded2) {
        this.maxVisibleItemsAnouncement = this.data.announcements.length;
      } else {
        this.maxVisibleItemsAnouncement = 5;
      }

    },
    toggleAbsence() {
      this.expandAbsentToday = !this.expandAbsentToday
      if (this.expandAbsentToday) {
        this.initialAbsentToday = this.absentData?.absences_today?.length
      } else {
        this.initialAbsentToday = 5
      }
    },
    toggleAbsentTomorrow() {
      this.expandAbsentTomorrow = !this.expandAbsentTomorrow
      if (this.expandAbsentTomorrow) {
        this.initialAbsentTomorrow = this.absentData?.absences_tomorrow?.length
      } else {
        this.initialAbsentTomorrow = 5
      }
    },

    showAlltodayLeave() {
      this.maxVisibleItemsToday_leave = this.data.on_leave_day?.length;
    },
    toggleToday() {
      this.expanded3 = !this.expanded3;

      if (this.expanded3) {
        this.maxVisibleItemsToday_leave = this.data.on_leave_day.length;
      } else {
        this.maxVisibleItemsToday_leave = 5;
      }

    },
    showAlltommoroweLeave() {
      this.maxVisibleItems_tomorrow = this.data.on_leave_tomorrow?.length;
    },
    toggleTommorow() {
      this.expanded4 = !this.expanded4;

      if (this.expanded4) {
        this.maxVisibleItems_tomorrow = this.data.on_leave_tomorrow.length;
      } else {
        this.maxVisibleItems_tomorrow = 5;
      }
    },
    showAllWeekLeave() {
      this.maxVisibleItems_week = this.data.on_leave_next_week?.length;
    },
    toggleweek() {
      this.expanded5 = !this.expanded5;

      if (this.expanded5) {
        this.maxVisibleItems_week = this.data.on_leave_next_week.length;
      } else {
        this.maxVisibleItems_week = 5;
      }
    },
    getUserHomePage() {
      Api()
        .get(`/auth/get-user-information/`)
        .then(response => {
          if (response.data.status === "success") {
            this.data = response.data.data;
            this.$store.commit("auth/SET_AUTH_USER_INFO", this.data);

          }
        })
    },
    // getAnnoucements() {
    //   Api()
    //     .get(`${getMyAnnouncements}`)
    //     .then((response) => {
    //       if (response.data.status === "success") {
    //         const announcements = response.data?.data;
    //         this.$store.commit(
    //           "hrResources/SET_ALL_ANNOUNCEMENTS_TO_STATE",
    //           announcements
    //         );
    //       }
    //     })
    //     .catch();
    // },
    deleteSingleAnnouncement(id) {
      Api()
        .delete(`${deleteAnnouncement}${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getUserHomePage()
          } else if(response.data.status === "error"){
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
          }
        })
        .catch();
    },
    deleteNotification(id) {

      Api()
        .delete(
          `/hr/user/notifications/${id}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getUserHomePage()
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.alertType = "success";

          }
        })
        .catch((error) => {
          if (error) {
            // this.alertType = "error";
            // this.alertTitle = error.response.data.detail;
            // this.alertState = !this.alertState;

          }
        });
    },
    deleteTodo(id) {
      const payload = {
        completed: true
      }
      Api()

     .patch(
       `/hr/user/to-dos/${id}/`, payload )
     .then((response) => {
       if (response.data.status === "success") {
         this.getUserHomePage()
         this.alertTitle = response.data.detail;
         this.alertState = !this.alertState;
         this.alertType = "success";
       }
     })
     .catch((error) => {
       if (error) {
         this.alertType = "error";
         this.alertTitle = error.response.data.detail;
         this.alertState = !this.alertState;
       }
     });
 },

 removeApproval(text) {
      return text.replace("Approval","").trim();
    },


    openTodo(item) {
  function extractNameFromNotification(notification) {
  const match = notification.match(/from (.+)$/);
  return match ? match[1] : null;
  }
  const notification =item.content
  const name = extractNameFromNotification(notification);
      const Year = moment(item.due_date).format('YYYY');
      const DueDate=item.due_date

      if (item.link_to_action === '/edit-project') {
        this.$store.commit('projectModules/SET_PROJECT_ID_STATE', item.item_id)
        this.$router.push(item.link_to_action)
      } else if (item.link_to_action === '/leaves') {
        const modifiedText = this.removeApproval(item.title);
        this.$store.commit("hrResources/SET_LEAVE_ID_TO_STATE", item.item_id);
        this.$store.commit("hrResources/SET_HOME_TO_STATE", true);
        this.$store.commit("hrResources/SET_LEAVE_TYPE_TO_STATE", `${modifiedText}`);
        this.$store.commit("hrResources/SET_LEAVE_STATUS_TO_STATE", "Pending");
        this.$store.commit("hrResources/SET_LEAVE_MONTHHOME_TO_STATE",DueDate);
        this.$store.commit("hrResources/SET_LEAVE_YEARHOME_TO_STATE",Year);
        this.$store.commit("hrResources/SET_LEAVE_REQUEST_NAME_TO_STATE",name);
        this.$router.push(item.link_to_action)
      }else if (item.category === 'freelancer'|| item.category === 'personnel'|| item.category === 'employment') {
        this.$router.push('/contract-approval')
        this.$store.commit('projectModules/SET_PROJECT_ID_STATE', item.link_to_action)
        this.$store.commit(
          "contractModules/SET_CONTRACT_TYPE_TO_STATE",
       item.category
        );
        this.$store.commit(
          "contractModules/SET_CONTRACT_EDIT_ID_TO_STATE",
          item.item_id
        );
      }
       else if (item?.employee__employee__user_key) {
        this.$store.commit("hrResources/SET_HOME_TO_STATE", true);
        this.$store.commit('hrResources/SET_EMPLOYEE_USER_KEY_FROM_HOME', item?.employee__employee__user_key)
        this.$router.push('/profile')
      }
      else if(item?.link_to_action === '/advance-payment-request/profile'){
        this.$store.commit("advancePaymentModules/SET_CURRENT_ADVANCE_PAYMENT_ID_TO_STATE", item?.item_id);
        this.$router.push('/view-advance-payments')
     
     }
     else if(item?.link_to_action === '/profile/flight-requests'){
        this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", item?.item_id);
        this.$router.push('/view-flight-request-details')
      }
      else if(item?.link_to_action.includes('/accounting/mission-planning-invoice')){
        // this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
        const missionId = item.link_to_action.split('?')[1].split("=")[1]
        const projectId = item.link_to_action.split('?')[2].split("=")[1]
        this.$store.commit('projectModules/SET_PROJECT_ID_STATE', projectId)
        this.$store.commit('contractModules/SET_EDIT_MISSION_ID_TO_STATE', missionId)
        this.$store.commit("projectModules/SET_FROM_HOME_TO_EDIT_PROJECT", true)
        this.$router.push('/edit-project')
      }
    },
    

    async getUserInfo() {
      const userInfoResp = await Api().get("/auth/get-user-information/");
      this.$store.commit("auth/SET_AUTH_USER_INFO", userInfoResp?.data?.data);
    }

  },
  mounted() {
    this.getUserHomePage()
    // this.getUserInfo()

    // this.getAnnoucements()
    this.$store.commit("hrResources/SET_HOME_TO_STATE", false);
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);

  },
  // created() {
  //   location.reload()
  // },
});
</script>
<style scoped>
button {
  color: #8b2923;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}
</style>
