import Api from "@/services/api"
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    isAuthToken: null,
    currentUser: null,
    userPermissions: null,
    token: null,
    refreshToken: null,
    rail: false,
    canEditModuleSections: "",
  },

  mutations: {
    SET_AUTH_USER(state: any, payload: any) {
      state.isAuthToken = payload.token;
      state.currentUser = payload.userDetails;
      state.userPermissions = payload.userPermissions;
    },
    CLEAR_CURRENT_USER(state: any) {
      state.isAuthToken = null;
      state.currentUser = null;
      state.userPermissions = null;
    },
    SET_AUTH_USER_INFO(state: any, payload: any) {
      state.currentUser = { ...state.currentUser, ...payload };
    },
    SET_AUTH_TOKEN(state: any, payload: any) {
      state.isAuthToken = payload.token;
    },
    SET_REFRESH_TOKEN(state: any, payload: any) {
      state.refreshToken = payload;
    },
    SET_RAIL_TO_STATE(state: any, payload: any) {
      state.rail = payload;
    },
    SET_CAN_EDIT_TO_STATE(state: any, payload: any) {
      state.canEditModuleSections = payload;
    },
  },
  getters: {
    isRail(state: any) {
      return state.rail;
    },
    isUserDetails(state: any) {
      return state?.currentUser;
    },
    isAuthenticated(state: any) {
      return !!state.isAuthToken;
    },
    isCurrentUserEmail(state: any) {
      return state?.currentUser?.email;
    },
    isUsername(state: any) {
      return state?.currentUser?.username;
    },
    role(state: any) {
      return state?.currentUser?.is_admin;
    },
    isPersonalKey(state: any) {
      return state?.currentUser?.user_key;
    },
    isTeamSchedule(state: any) {
      const modifiedData =state?.currentUser?.team_schedule;
      return modifiedData?.map((data:any)=> {
        return{
          name: `${data.first_name} ${data.last_name}`,
          monday: `${data?.working_days?.length > 0 ?data?.working_days[0]?.start_time: ''}-${data?.working_days?.length > 0 ?data?.working_days[0]?.end_time: ''}`,
          tuesday: `${data?.working_days?.length > 0 ?data?.working_days[1]?.start_time: ''}-${data?.working_days?.length > 0 ?data?.working_days[1]?.end_time: ''}`,
          wednesday: `${data?.working_days?.length > 0 ?data?.working_days[2]?.start_time: ''}-${data?.working_days?.length > 0 ?data?.working_days[2]?.end_time: ''}`,
          thursday: `${data?.working_days?.length > 0 ?data?.working_days[3]?.start_time: ''}-${data?.working_days?.length > 0 ?data?.working_days[3]?.end_time: ''}`,
          friday: `${data?.working_days?.length > 0 ?data?.working_days[4]?.start_time: ''}-${data?.working_days?.length > 0 ?data?.working_days[4]?.end_time: ''}`,
        }
      })
    },
    ishr(state: any) {
      return state?.currentUser?.is_hr_approver;
    },
    isContractApprover(state: any) {
      return state?.currentUser?.is_contract_approver;
    },
    isfullName(state: any) {
      return state?.currentUser?.full_name;
    },
    isCeo(state: any) {
      return state?.currentUser?.is_ceo;
    },
    isLeaveRole(state: any) {
      return state?.currentUser?.role === "Hr Manger";
    },
    permissions(state: any) {
      return state?.userPermissions;
    },
    token(state: any) {
      return state?.isAuthToken;
    },
    getRefreshToken(state: any) {
      return state?.refreshToken;
    },
    noAccess(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "0") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
    canView(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "1") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
    canEdit(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "2") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
  },
  actions: {
    getUserHomePage(context: ActionContext<any, any>) {
      Api()
        .get(`/auth/get-user-information/`)
        .then(response => {
          if (response.data.status === "success") {
            
            context.commit("auth/SET_AUTH_USER_INFO", response.data.data);

          }
        })
    }
  }
};
