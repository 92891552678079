<template>
  <div
    v-if="isAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <v-sheet v-else class="bg-transparent SPC-MT-200">
    <split-header ref="splitHeader" @show-dialog="showDialog"></split-header>
    <div v-if="isEmptyBudgetState">
      <NoAvailableData page-name="Budget section" messageTypes="bugdets" />
    </div>
    <div v-else>
      <!-- view budget -->
      <div v-if="isBudgetMode">
        <!-- budget acccordion -->
        <budget-accordion
          @edit-budget-section="UpdateBudgetSection($event)"
          @delete-budget-section="deleteBudgetSection"
          @send-totals="handleTotals($event)"
        ></budget-accordion>
        <!-- budget total -->
        <budget-total :receiveTotals="receiveTotals"></budget-total>
      </div>

      <!-- view split -->

      <div v-else class="mt-8">
        <monthly-split-table v-if="isSplit === 'monthly'"></monthly-split-table>
        <quaterly-split-table
          v-if="isSplit === 'quarterly'"
        ></quaterly-split-table>
        <milestone-split-table
          v-if="isSplit === 'milestone'"
        ></milestone-split-table>
      </div>
    </div>
    <create-update-budget-section
      ref="createUpdateBudgetSection"
      :is-update="isUpdate"
      :editData="editData"
    ></create-update-budget-section>
    <delete-budget-section
      ref="deleteBudgetSection"
      :payload="payload"
    ></delete-budget-section>
    <app-prompt-alert ref="alert" />

    <!--  action buttons on budget page -->
    <span v-show="isBudgetMode">
      <div v-if="!isEmptyBudgetState" class="justify-end mt-12 d-flex">
        <app-secondary-button
          class="mr-4"
          :disabled="isEnabledButton"
          :buttonLabel="$t('projectBudget.cancel')"
          @click="cancelEditBudgetItem"
        >
        </app-secondary-button>
        <app-primary-button
          :disabled="isEnabledButton"
          :buttonLabel="$t('projectBudget.save')"
          @click="
            budgetLineItemChanged && isSplitted
              ? showConfirmChanges()
              : createUpdateBudgetLineItem()
          "
        >
        </app-primary-button>
      </div>
    </span>
  </v-sheet>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />

  <app-dialog ref="confirmChanges" :title="$t('projectBudget.confirmChanges')">
    <template #content>
      <div class="confirmList">
        <ul>
          <li>{{ $t("projectBudget.confirmItem1") }}</li>
          <li>{{ $t("projectBudget.confirmItem2") }}</li>
          <li>{{ $t("projectBudget.confirmItem3") }}</li>
        </ul>
      </div>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('projectBudget.confirm')"
            @click="createUpdateBudgetLineItem"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import SplitHeader from './splitBudgetComponents/SplitHeader.vue'
import BudgetAccordion from './budget-components/BudgetAccordion.vue';
import BudgetTotal from './budget-components/BudgetTotal.vue';
import MonthlySplitTable from './splitBudgetComponents/MonthlySplitTable.vue'
import QuaterlySplitTable from './splitBudgetComponents/QuaterlySplitTable.vue'
import MilestoneSplitTable from './splitBudgetComponents/MileStoneSplitTable.vue'
import NoAvailableData from '@/components/shared/NoAvailableData.vue';
import CreateUpdateBudgetSection from './budget-components/CreateUpdateBudgetSection.vue';
import DeleteBudgetSection from './budget-components/DeleteBudgetSection.vue';
import { getTransString } from "@/services/helpers";
import { mapActions, mapGetters } from 'vuex';
import { isEqual, omitBy } from 'lodash';


export default defineComponent({
  components:{
    BudgetAccordion,
    BudgetTotal,
    SplitHeader,
    MonthlySplitTable,
    QuaterlySplitTable,
    MilestoneSplitTable,
    NoAvailableData,
    CreateUpdateBudgetSection,
    DeleteBudgetSection,
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: false,
      isUpdate: false,
      payload: {},
      editData: {},
      receiveTotals: '',
      show: true,
    }
  },

  computed: {
    isAppLoading() {
      return this.$store.getters["projectBudgetModules/isAppLoading"]
    },

    ...mapGetters("projectBudgetModules", ["getError", "getResponse", "isBudgetData", "isInitialBudgetData", "getShowLocalBudget"]),

    isTableHeaders() {
      return this.$store.getters["projectModules/isTableHeaders"];
    },

    isSplitMode() {
       return this.$store.getters["projectBudgetModules/isSplitMode"];
     },
     isBudgetMode() {
       return this.$store.getters["projectBudgetModules/isBudgetMode"];
     },
     isEmptyBudgetState() {
       return this.$store.getters["projectBudgetModules/isEmptyBudgetState"];
     },
     isSplit() {
      return this.$store.getters["projectBudgetModules/isSplit"];
    },
    isBankGuarantee() {
      return this.$store.getters["projectBudgetModules/isBankGuarantee"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEnabledButton() {
      return this.$store.getters["projectBudgetModules/isEnabledButton"];
    },
    isProjectUpdate() {
      return this.$store.getters["projectModules/isProjectUpdate"];
    },
    budgetLineItemChanged(){
      if("ProjectBudget" in this.isProjectUpdate) {
        return this.isProjectUpdate.ProjectBudget
      }
      return false
    },
    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },
    isSplitted() {
      return this.$store.getters["projectBudgetModules/isSplitted"];
    },
  },
  watch:{
    isBudgetData: {
      handler: function(newData) {//omit(obj, ignoreAttribute)
        const filteredArr = newData?.map(obj => ({
              budget_section_id : obj.budget_section_id,
              profit_margin : obj.profit_margin,
              profit_margin_percentage : obj.profit_margin_percentage,
              total_cost : obj.total_cost,
              total_income : obj.total_income,
              budget_items : this.filterAttribute(obj.budget_items, "total_amount")
            }
          )
        );

        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": !isEqual(filteredArr, this.isInitialBudgetData)
        });
      },
      deep:true
    }
  },
  methods: {
    ...mapActions("projectBudgetModules", ["updateBudgetSectionLineItems", "fetchAllBudgetSection"]),
    filterAttribute(obj, ignoreAttr) {
      if (Array.isArray(obj)) {
        return obj.map(item => this.filterAttribute(item, ignoreAttr));
      } else if (typeof obj === 'object') {
        return omitBy(obj, (value, key) => key === ignoreAttr);
      }
      return obj;
    },
    handleTotals(data){
      this.receiveTotals = data
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog(){
      this.isUpdate = false
      setTimeout(()=>{
       this.$refs?.createUpdateBudgetSection?.showDialog()
      }, 50)
    },
   UpdateBudgetSection(data){

      this.isUpdate = true
      this.editData = data
      setTimeout(()=>{
       this.$refs?.createUpdateBudgetSection?.showDialog()
      }, 50)
    },
    deleteBudgetSection(data){
      this.$refs?.deleteBudgetSection?.showDialog()
      this.payload = { id: data?.id, section: 0 }
    },
    cancelEditBudgetItem() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
    },
    async createUpdateBudgetLineItem(){
      let budgetSectionData = this.assignRowIndexToBudgetSection(this.isBudgetData);
      this.$refs.confirmChanges.showDialog = false;
      await this.updateBudgetSectionLineItems({ project: this.isProjectID, request: budgetSectionData})

      if(this.getResponse.status === 'success'){
        this.alertType = "success";
        this.alertState = !this.alertState;
        this.alertTitle = this.getResponse.detail;
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });

        await this.fetchAllBudgetSection({project : this.isProjectID})
        this.$store.commit("projectModules/SET_LOCAL_BUDGET_TAB", this.getShowLocalBudget);
      }else {

        const error = this.getBudgetLineItemError(this.getError)

        this.alertType = "error";
        this.alertState = !this.alertState;
        this.alertTitle = error?.message;

        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });
      }

    },

    closeDialog() {
      this.$refs.confirmChanges.showDialog = false;
    },

    showConfirmChanges() {
      this.$refs.confirmChanges.showDialog = true;
    },

    getBudgetLineItemError (errorObject){
      let indexOfNonEmpty = null;
      let data = errorObject.data
      for (let i = 0; i < data.length; i++) {
          if (Object.keys(data[i]).length > 0) { // Check if the object is not empty
              indexOfNonEmpty = i;
              break;
          }
      }

      // If a non-empty object is found, get the budget_items and find the first non-empty item
      if (indexOfNonEmpty !== null) {
          const nonEmptyObject = data[indexOfNonEmpty];
          const budgetItems = nonEmptyObject["budget_items"] || [];

          // Find the first non-empty item in budget_items
          const nonEmptyItem = budgetItems.find(item => Object.keys(item).length > 0);

          if ('structure' in nonEmptyItem) {
            return {
              field: "structure",
              sectionIndex: indexOfNonEmpty,
              message: "Please Select a Structure"
            }
          }else if("amount_per_unit"  in nonEmptyItem) {
            return {
              field: "amount_per_unit",
              sectionIndex: indexOfNonEmpty,
              message: "Amount Per Unit is Required"
            }
          }else if("number_of_units"  in nonEmptyItem) {
            return {
              field: "number_of_units",
              sectionIndex: indexOfNonEmpty,
              message: "Number of Units is Required"
            }
          }
          return {
              field: "unknown",
              sectionIndex: 0,
              message: "Some Required Fields are Missing"
          }
      }

    },

    assignRowIndexToBudgetSection(budgetSectionDataFromStore) {
      if (budgetSectionDataFromStore.length > 0) {
       // Clone the budget_sections array to avoid modifying the original data
         const clonedBudgetSections = [...budgetSectionDataFromStore];

         // Assign row index to each element in the cloned array
         clonedBudgetSections.forEach((section) => {

           section?.budget_items?.forEach((item, index )=> {
            item.row_index = index
           })
         });

        return clonedBudgetSections;
      }
      return budgetSectionDataFromStore;
    }
  },
  async created() {

    this.showmenu = true
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 4)
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.$store.commit('projectBudgetModules/SET_BUDGET_MODE_TO_STATE', true );
    this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE',false );
    await this.fetchAllBudgetSection({project : this.isProjectID})
    this.$store.commit('projectBudgetModules/SET_ENABLED_BUTTON_TO_STATE', true)
    const payload = {
      projectID: this.isProjectID
    }
    this.$store.dispatch('projectModules/getContractDetails', payload)
  },

})
</script>
<style scoped>
.confirmList > ul {
  list-style-type: disc;
  color: #667085;
  padding-left: 1rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
