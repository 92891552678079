<template>
  <div class="mt-8">
    <div class="flex justify-between">
      <div class="w-2/5 upload">
        <p class="text-[#344054] font-medium">
          {{ $t("contracts.uploadYourSignature") }}
        </p>
        <p class="text-[14px]">
          {{ $t("contracts.uploadText") }}
        </p>
      </div>
      <input
        type="file"
        @change="handleFileUpload"
        style="display: none"
        ref="fileInput"
        accept="image/*"
      />
      <app-primary-button
        v-if="isEditButton && !imageUrl"
        @click="openFileInput"
        :buttonLabel="$t('contracts.uploadSignature')"
        :disabled="selectedFile"
      >
      </app-primary-button>
      <app-primary-button
        v-if="isEditButton && imageUrl"
        @click="clearImage"
        :buttonLabel="$t('contracts.removeSignature')"

      >
      </app-primary-button>
    </div>
    <div class="flex flex-col items-center mt-16">
      <div
        class="h-[160px] border rounded-lg w-2/4 p-4 flex justify-center items-center bg-white"
      >
        <img
          :src="imageUrl"
          alt=""
          class="max-h-[70px] min-w-[300px] max-w-[400px]"
          v-if="imageUrl"
        />
        <p class="text-[#667085] text-sm" v-if="!imageUrl">
          {{ $t("contracts.signatureAppear") }}
        </p>
      </div>
      <p class="text-[14px] mt-2">{{ $t("contracts.forBest") }}</p>
    </div>
  </div>
  <app-dialog
    ref="dialogState"
    width="586"
    height="800"
    :title="$t('contracts.cropAnduploadSiganture')"
    :subtitle="``"
  >
    <template #content>
      <VueCropper
        v-if="selectedFile"
        ref="cropper"
        :src="imageUrl"
        view-mode="1"
        :guides="true"
        :auto-crop="true"
        :auto-crop-area="0.5"
        :movable="true"
        :zoomable="true"
        :drag-mode="'crop'"
        :data="{width: 400, height: 400}"
      ></VueCropper>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('projects.cancel')"
            @click-event="cancelUpload()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('contracts.uploadSignature')"
            :loading="isLoading"
            @click-event="cropImage()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import API from "@/services/api";
import {
  getEmployeeSignature,
  uploadEmployeeSignature,
} from "@/services/endpoints";
import { getTransString } from "@/services/helpers";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default defineComponent({
  name: "Signature",
  components: {
    VueCropper,
  },
  data() {
    return {
      imageUrl: null,
      selectedFile: null,
      alertType: "success",
      alertState: false,
      alertTitle: "",
    };
  },
  props: {
    tabId: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isEditButton() {
      return this.currentPath === "/profile"
        ? this.$store.getters["hrResources/isEditButton"]
        : this.$store.getters["hrResources/isEditButton"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const validateImageType = ["image/jpeg", "image/png", "image/jpg"];
      if (!validateImageType.includes(file.type)) {
        this.alertType = "error";
        this.alertState = true;
        this.alertTitle = "Please upload a valid image file.";
        return;
      }
      this.selectedFile = file;
      this.imageUrl = URL.createObjectURL(file);
      // this.uploadImage();
      this.$refs.dialogState.showDialog = true;
    },
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.selectedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        this.uploadImage();
      }, "image/jpeg");
      this.$refs.dialogState.showDialog = false;
    },
    clearImage() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogState.showDialog = false;
      this.deleteSignature();
    },
    cancelUpload() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogState.showDialog = false;
    },
    uploadImage() {
      if (!this.selectedFile) {
        alert("Please select an image to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("signature", this.selectedFile);
      API()
        .post(`${uploadEmployeeSignature}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
          }
        })
        .catch((error) => {
          this.alertType = "error";
          this.alertState = true;
          this.alertTitle = "Error uploading image. Please try again.";
        });
    },
    getSignature() {
      API()
        .get(`${getEmployeeSignature}${this.isUserKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.imageUrl = response.data.data.signature;
          }
        });
    },
    deleteSignature() {
      API()
        .delete(`${getEmployeeSignature}${this.isUserKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.imageUrl = null;
          }
        });
    },
  },
  created() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", this.tabId);
    this.getSignature();
  },
});
</script>

<style></style>
