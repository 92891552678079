<template>
  <!-- dialog for password reset -->
  <app-dialog
    ref="resetPasswordDialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('employees.newPassword')"
        :placeholder="$t('employees.setNewPassword')"
        v-model="newPassword"
        :hasError="hasPasswordError"
        :errorMessage="$t(errorPasswordMessage)"
        @input="hideError"
      ></app-input-field>
      <app-input-field
        class="mt-5"
        :label="$t('employees.confirmNewPassword')"
        :placeholder="$t('employees.confirmPassword')"
        v-model="confirmPassword"
        :hasError="hasConfirmPasswordError"
        :errorMessage="errorConfirmPassword"
        @input="hideError"
      ></app-input-field>
    </template>

    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="5" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideResetPasswordDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="7" class="">
          <app-primary-button
            :loading="loading"
            :buttonLabel="$t('employees.resetPassword')"
            @click-event="resetEmployeePassword()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script>
import Api from "@/services/api";

import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";

import {} from "@/services/endpoints";
export default {
  name: "ResetPasswordDialog",
  components: {},

  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      isActivate: false,
      currentPages: 1,
      userKey: null,
      userStatus: null,
      research: false,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",

      status: "Active",
      country: "",
    };
  },

  computed: {
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    hideError() {
      this.hasConfirmPasswordError = false;
      this.errorConfirmPassword = "";
      this.hasPasswordError = false;
      this.errorPasswordMessage = "";
    },

    showDialog() {
        this.$refs.resetPasswordDialog.showDialog = true;

      this.newPassword = "";
      this.confirmPassword = "";
      this.subTitle = "";
      this.cancelLabel = "employees.cancel";
      this.title = "employees.resetPassword";
      this.buttonLabel = "employees.resetPassword";
    },

    hideResetPasswordDialog() {
      this.$refs.resetPasswordDialog.showDialog = false;
      setTimeout(() => {
        this.isReset = false;
        this.subTitle = "";
        this.addTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    resetEmployeePassword() {
      this.loading = true;
      if (!this.newPassword) {
        this.loading = false;
        this.hasPasswordError = true;
        this.errorPasswordMessage = "employees.thisFieldIs";
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.loading = false;
        this.hasConfirmPasswordError = true;
        this.errorConfirmPassword = "employees.passwordDoesNot";
        return;
      }
      const payload = {
        password: this.newPassword,
        confirm_password: this.confirmPassword,
      };
      Api()
        .put(`/hr/employees/reset-password/${this.userKey}/`, payload)
        .then((response) => {
           
          if (response.data.status === "success") {
            this.loading = false;
            this.alertState = true;
            this.alertType= "success",
            this.alertTitle = response.data.detail;
            // this.getAllEmployees(this.currentPage);
            this.$store.dispatch('hrResources/getAllForeignEmployees', this.currentPage)
            this.$refs.resetPasswordDialog.showDialog = false;
          }
          if (response.data.status === "error") {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = false;
          }
        })
        .catch((error) => {
            
          if (error) {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = false;
          }
        });
    },
   
  },
};
</script>

