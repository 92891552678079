<template>
  <div class="px-1 mb-5">
    <div class="flex items-center justify-between mb-8">
      <h3 class="H600">{{ $t("Overview".getTranslationKey()) }}</h3>
      <app-primary-button
        v-if="!shouldDisableInput && !isAllBalanceInfoData?.locked_month_details?.is_locked"
        :buttonLabel="$t('Add Expense'.getTranslationKey())"
        @click="addNewExpense()"
      >
        <template #icon> <v-icon icon="mdi-plus"></v-icon> </template
      ></app-primary-button>
    </div>
    <div class="flex flex-col gap-6 p-4 border rounded-lg">
      <div>
        <p class="text-[#667085] text-sm">{{ $t('Project Name'.getTranslationKey()) }}</p>
        <p class="mt-1 font-bold">{{ projectDetails?.project_name ? projectDetails?.project_name : "N/A" }}</p>
      </div>
      <div class="flex justify-between w-4/5 gap-8">
        <div>
          <p class="text-[#667085] text-sm">{{ $t('Project Office Location'.getTranslationKey()) }}</p>
          <p class="mt-1 font-bold">{{ projectDetails?.project_office_location ? projectDetails?.project_office_location : "N/A" }}</p>
        </div>
        <div>
          <p class="text-[#667085] text-sm">{{ $t('Project Start Date'.getTranslationKey()) }}</p>
          <p class="mt-1 font-bold">
            {{ formattedDate(projectDetails?.project_start_date) }}
          </p>
        </div>
        <div>
          <p class="text-[#667085] text-sm">{{ $t('Project End Date'.getTranslationKey()) }}</p>
          <p class="mt-1 font-bold">
            {{ formattedDate(projectDetails?.project_end_date) }}
          </p>
        </div>
        <div>
          <p class="text-[#667085] text-sm">{{ $t('Cost Center'.getTranslationKey()) }}</p>
          <p class="mt-1 font-bold">{{ projectDetails?.cost_centre ? projectDetails?.cost_centre : "N/A" }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { getPmLocalOverview } from "@/services/endpoints";
import { formatDate } from "@/services/helpers";

export default defineComponent({
  name: "OverviewHeading",
  components: {},
  props: {
    selectedCurrency: String,
  },
  data() {
    return {
      currencies: null,
      projectDetails: null,
    };
  },
  watch: {
    selectedCurrency(newValue) {
      this.fetchBankOverviewData(newValue);
    },
  },
  computed: {
    canEditPmLocalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"] && this.canEditPmLocalModule?.includes("Project Overview")
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    isProjectId() {
      return this.$store.getters["pmLocalModules/isProjectId"];
    },
    isDefaultCurrency(){
      return this.$store.getters["projectOverview/isDefaultCurrency"]
    },
    isAllBalanceInfoData() {
     return this.$store.getters["projectOverview/isAllBalanceInfoData"];
      // return balanceData.locked_month_details.is_locked
    },
    // appLang() {
    //   return this.$store.getters["translation/getLocale"];
    // },
    
  },
  methods: {
    addNewExpense() {
      this.$store.commit("projectOverview/SET_ADD_EXPENSE_TO_STATE", true);
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    },
    formattedDate(date) {
      return formatDate(date);
    },
    fetchBankOverviewData(currency) {
      const payload = {
        currency: currency,
      };
      Api()
        .post(
          `${getPmLocalOverview}${this.isProjectId}/${this.isForeignEstablishmentId}/`,
          payload
        )
        .then((response) => {
          this.projectDetails = response.data.data.project_details;
          const transformedData = response.data?.data?.budget_overview?.map(
            (item) => {
              return {
                "budget item": item.budget__budget_item__position,
                quantity: item.budget_quantity,
                "unit amt": item.amount_per_unit,
                "plan total": item.plan_total,
                "plan monthly": item.plan_monthly,
                yearly_data: item.yearly_data,
                total: item.totals,
                "remaining amt": item.remaining_amount,
              };
            }
          );
          const transformedBottomData = response.data?.data?.bottom_data
            ?.map((item) => {
              return {
                "budget item": item.budget__budget_item__position,
                quantity: item.budget_quantity,
                "unit amt": item.amount_per_unit,
                "plan total": item.plan_total,
                "plan monthly": item.plan_monthly,
                yearly_data: item.yearly_data,
                total: item.totals,
                "remaining amt": item.remaining_amount,
              }
            })
          this.$store.commit(
            "projectOverview/SET_ALL_OVERVIEWS_TO_STATE",
            transformedData
          );
          this.$store.commit("projectOverview/SET_TOTALS_OVERVIEW_TO_STATE",  transformedBottomData);
        })
        .catch();
    },
  },
  mounted() {
    this.fetchBankOverviewData(this.isDefaultCurrency)
  },
});
</script>
