<template>
  <!-- add and update bank details -->
  <app-dialog
    ref="dialogState"
    :title="
      isEdit
        ? $t('projectConfiguration.updateBankTitle')
        : $t('projectConfiguration.addBankTitle')
    "
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('projectConfiguration.bankName')"
        placeholder="Enter bank name"
        v-model.trim="bankFormValues.bankName"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.accountNo')"
        placeholder="Enter account number"
        v-model.trim="bankFormValues.bankAcc"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.accountTitle')"
        placeholder="Enter account title"
        v-model.trim="bankFormValues.bankTitle"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.swiftCode')"
        placeholder="Enter swift code"
        v-model.trim="bankFormValues.bankSwiftCode"
      >
      </app-input-field>
      <app-input-field
        :label="$t('partners.iban')"
        placeholder="Enter iban"
        v-model.trim="bankFormValues.bankIban"
      >
      </app-input-field>
      <app-input-field
        :label="$t('projectConfiguration.bankAddress') + '*'"
        placeholder="Enter bank address"
        v-model.trim="bankFormValues.bankAddress"
      >
      </app-input-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit
                ? $t('addcustomrole.saveChanges')
                : $t('projectConfiguration.save')
            "
            @click-event="isEdit ? updateBankDetails() : addBankDetails()"
            :disabled="
              bankFormValues.bankName === '' ||
              bankFormValues.bankAcc === '' ||
              bankFormValues.bankAddress === ''
            "
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import { getTransString } from "@/services/helpers";
import api from "@/services/api";

export default defineComponent({
  name: "CreateEditBankDetails",

  data() {
    return {
      currentPages: 1,
      isEdit: false,

      title: "",
      subTitle: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
      dialogState: false,
      bankID: null,
      bankFormValues: {
        bankName: "",
        bankAcc: "",
        bankTitle: "",
        bankSwiftCode: "",
        bankIban: "",
        bankAddress: "",
      },
      errroMessage: "",
      editObject:{},
      editData:{}
    };
  },
  computed: {
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog() {
      this.$refs.dialogState.showDialog = true;
      this.subTitle = "";
    },
    showEditDialog() {
      this.$refs.dialogState.showDialog = true;
      
      for (const key in this.editData) {
        const newKey = key.replace(/ /g, "_");
        this.editObject[newKey] = this.editData[key];
      }
      this.bankID = this.editObject.id;
      this.subTitle = "";
      this.isEdit = true;
      this.$refs.dialogState.showDialog = true;

      this.bankFormValues.bankName = this.editObject.bank_name;
      this.bankFormValues.bankAcc = this.editObject.account_number;
      this.bankFormValues.bankTitle = this.editObject.account_title;
      this.bankFormValues.bankSwiftCode = this.editObject.swift_code;
      this.bankFormValues.bankIban = this.editObject.iban;
      this.bankFormValues.bankAddress = this.editObject.bank_address;
    },

    hideDialog() {
        this.$refs.dialogState.showDialog = false;
        this.bankFormValues.bankName = "";
        this.bankFormValues.bankAcc = "";
        this.bankFormValues.bankSwiftCode = "";
        this.bankFormValues.bankTitle = "";
        this.bankFormValues.bankIban = "";
        this.bankFormValues.bankAddress = "";
        this.isEdit = false;
    },

    addBankDetails() {
      const payload = {
        bank_name: this.bankFormValues.bankName,
        account_number: this.bankFormValues.bankAcc,
        account_title: this.bankFormValues.bankTitle,
        swift_code: this.bankFormValues.bankSwiftCode,
        iban: this.bankFormValues.bankIban,
        bank_address: this.bankFormValues.bankAddress,
      };
      api()
        .post("/configuration/projects/add-bank/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.dispatch('foreignOfficeModule/getAllBanks')
            this.$emit('prefill-input-field', response?.data?.data)
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.hideDialog();
          }
        });
    },
    updateBankDetails() {
      const payload = {
        bank_id: this.bankID,
        bank_name: this.bankFormValues.bankName,
        account_number: this.bankFormValues.bankAcc,
        account_title: this.bankFormValues.bankTitle,
        swift_code: this.bankFormValues.bankSwiftCode,
        iban: this.bankFormValues.bankIban,
        bank_address: this.bankFormValues.bankAddress,
      };
      api()
        .post("/configuration/projects/add-bank/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertTitle = response?.data?.detail;

            this.hideDialog();
          }
        });
    },
  },
});
</script>
