<template>
  <v-sheet max-width="1125" class="bg-transparent">
    <v-row>
      <v-col cols="10" class="mt-5 !divide-y">
        <p class="pb-3 font-semibold text-[1.125rem]">
          {{ $t("projects.offer") }}
        </p>
        <div></div>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-single-select
          id="currencies"
          :showCheckbox="false"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchCurrency')"
          :options="currencyOptions"
          :labelPlaceholder="$t('projects.currencyPlaceholder')"
          :label="$t('projects.currency')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.currency"
          :hasError="isFieldInvalid('currency')"
          :isRequired="true"
        ></app-single-select>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-single-select
          id="controller"
          :showCheckbox="false"
          :options="controllerOptions"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchController')"
          :labelPlaceholder="$t('projects.controllerPlaceholder')"
          :label="$t('projects.controller')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.offer_controller_id"
        ></app-single-select>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-single-select
          id="project_manager"
          :showCheckbox="false"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchProjectManager')"
          :options="projectManagerOptions"
          :labelPlaceholder="$t('projects.projectManagerPlaceholder')"
          :label="$t('projects.projectManager')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.project_manager_id"
          :hasError="isFieldInvalid('project_manager_id')"
          :isRequired="true"
        ></app-single-select>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-single-select
          id="assistant_project_manager"
          :showCheckbox="false"
          :options="assistantProjectManagerOptions"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchAssistantProjectManager')"
          :labelPlaceholder="$t('projects.assistantProjectManagerPlaceholder')"
          :label="$t('projects.assistanrProjectManager')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.assistant_project_manager_id"
        ></app-single-select>
      </v-col>
    </v-row>

    <v-row
      class="mt-5 mb-10"
      :class="{ 'pointer-events-none': shouldDisableInput }"
    >
      <v-col cols="10">
        <QuillEditor
          :placeholder="$t('projectConfiguration.enterDescription')"
          :class="contentStyles"
          contentType="text"
          v-model:content="projectsForm.project_description"
          :options="options"
          :style="editorStyles"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
// import { getSelectOptionList } from '@/services/helpers'
import currencies from "@/services/currencies_api"
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { isEqual } from 'lodash';

export default defineComponent({
  name: 'Project Offer',
  props: {
    projectData: {
      type: Object
    }
  },
  components: {
    QuillEditor,
  },
  data() {
    return {
      selectedCurrency: [],
      selectedController: [],
      projectsForm: {
        currency: {text: 'Euro (€)', value: '€'},
        offer_controller_id: '',
        project_manager_id: '',
        assistant_project_manager_id: '',
        project_description: ''
      },
      originalProjectsForm: {
        currency: '',
        offer_controller_id: '',
        project_manager_id: '',
        assistant_project_manager_id: '',
        project_description: ''
      },
      firstRun: true,
      options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: this.placeholder,
      },
      editorStyles: {
        minHeight: "190px",
        padding: "10px, 14px",
        fontSize: "16px",

      },
      requiredFields: ''
    }
  },

  watch: {
    // projectData(data) {

    //   this.populateProjectForm(this.projectsForm, data);
    //    if(this.firstRun) {
    //      this.populateProjectForm(this.originalProjectsForm, data);
    //      this.firstRun = false
    //    }


    // },
    currentProject(data){
      this.populateProjectForm(this.projectsForm, data);
    },

    projectsForm: {
      deep: true,
      handler(value) {
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
        "BasicInformation": !isEqual(value, this.originalProjectsForm)
      });

      },
    }
  },
  computed: {
    contentStyles(){
      return this.shouldDisableInput ? 'bg-[#F9FAFB] !text-[#667085] pointer-events-none' : 'bg-white !border !rounded-b-lg'
    },
    controllerOptions() {
      const optionList = this.$store.getters['hrResources/isAllControllers']

      return optionList?.map((controller) => {
        return {
          text: `${controller?.first_name} ${controller?.last_name}`,
          value: controller?.id
        }
      })
    },
    projectManagerOptions() {
      const optionList = this.$store.getters['projectModules/isProjectMangers']

      return optionList?.map((manager) => {
        return {
          text: `${manager?.employee__employeeinformation__first_name} ${manager?.employee__employeeinformation__last_name}`,
          value: manager?.employee__employeeinformation__id
        }
      })
    },
    assistantProjectManagerOptions() {
      const optionList = this.$store.getters['projectModules/isAssistantProjectMangers']

      return optionList?.map((manager) => {
        return {
          text: `${manager?.employee__employeeinformation__first_name} ${manager?.employee__employeeinformation__last_name}`,
          value: manager?.employee__employeeinformation__id
        }
      })
    },
    currencyOptions(){
    return currencies?.map((currencies)=>{
      return{
        text: `${currencies?.name} (${currencies?.symbol})`,
        value: currencies?.symbol
      }
    });
  },
  currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },

  },
  methods: {
   // check the required fields
    isFieldInvalid(fieldName) {
      return this.requiredFields?.includes(fieldName) || false;
    },
    populateProjectForm(saveTo, data) {
      saveTo.currency = data?.currency
      saveTo.offer_controller_id = data?.offer_controller
      saveTo.project_manager_id = data?.project_manager
      saveTo.assistant_project_manager_id = data?.assistant_project_manager
      saveTo.project_description = data?.project_description
    }
  },
  created() {
    this.$store.dispatch('hrResources/getAllControllers')
    this.$store.dispatch('projectModules/getProjectManagers')
    this.populateProjectForm(this.projectsForm, this.projectData);
    this.populateProjectForm(this.originalProjectsForm, this.projectData);

  }
})
</script>
