<template>
  <div>
    <app-table
      :isTranslation="false"
      :hideTableHeader="true"
      :hideMenu="true"
      :showCursor="false"
      :headers="transformHeaders"
      :items="getBalanceInfo"
    >
    </app-table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {currencyFormatter} from "@/services/helpers";
export default defineComponent({
  data() {
    return {};
  },
  computed: {
    allExpenses() {
      return this.$store.getters["expenseModules/isAllExpenses"];
    },
    
    getBalanceInfo() {
      const modifiedData = this.allExpenses.expense_overview;
      if (modifiedData.length === 0) return
      const currencyKeys = Object.keys(modifiedData[0])?.filter(
        (key) => key !== "title" && key !== "amount_in_eur"
      );
   
      const currencyObjects = [];
      modifiedData
    ?.filter((item) => item.title === "Balance" || item.title === "Pending Cheques")
    ?.forEach((item) => {
      const existingObject = currencyObjects?.find((obj) => obj.title === item.title);
      if (existingObject) {
        currencyKeys.forEach((currency) => {
          existingObject[currency] = item[currency];
        });
      } else {
        const newObject = { title: item.title, 'amount in eur': Number(item.amount_in_eur)?.formatCurrency(), };
        currencyKeys.forEach((currency) => {
          newObject[currency] =   Number(item[currency])?.formatCurrency();
        });
        currencyObjects.push(newObject);
      }
    });

      return currencyObjects;
      
    },
    headers() {
      const modifiedData = this.allExpenses.overview_headers;
      return modifiedData?.map((data) => {
        return data?.replace(/_/g, " ");
      });
    },
    transformHeaders() {
      const transformedArray = this.headers?.map((str) => {
        if (str === "EUR") {
          return str?.toUpperCase();
        } else if (str === "amount in eur") {
          // Split the string at spaces, capitalize the first word, and keep "EUR" in uppercase
          const parts = str?.split(" ");
          if (parts?.length === 3) {
            const firstPart =
              parts[0]?.charAt(0)?.toUpperCase() + parts[0]?.slice(1);
            return [firstPart, parts[1], parts[2]?.toUpperCase()]?.join(" ");
          }
        }
        return str;
      });
      return transformedArray;
    },
  },
  methods: {
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
  },
});
</script>

<style scoped></style>
