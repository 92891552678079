<template>
  <div>
    <app-header
        :name="isHome ? employeeNameFromHome : isEmployeeName"
        class="mb-5"
        :show-arrow-back="false"
    >
      <template #action>
        <app-secondary-button
          v-show="hideEditButton()"
          :buttonLabel="$t('employees.editProfile')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
    </app-header>
    <div>
      <div>
        <button
            v-for="(tab, index) in tabList"
            :key="index"
            :class="['tab-button', { active: currentTab === tabs[index] }]"
            @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component
        :is="currentTab"
        v-bind="isCurrentTab"
        class="pt-3"
        :tabId="
          getTabIndex(
            tabs,
            currentTab
          )
        "
    ></component>
  </div>
  <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>
<script>
import {defineComponent} from "vue";
import Personal from "@/components/dashboard/employees/Personal.vue";
import Contact from "@/components/dashboard/employees/Contact.vue";
import BasicInformation from "@/components/dashboard/partners/BasicInformation.vue";
import Address from "@/components/dashboard/partners/Address.vue";
import PartnerContact from "@/components/dashboard/partners/Contact.vue";
import PartnerBankDetails from "@/components/dashboard/partners/BankingDetails.vue";
import AdditionalInformation from "@/components/dashboard/partners/AdditionalInformation.vue";
import Signature from "@/components/dashboard/employees/Signature.vue";
import BankDetails from "@/components/dashboard/employees/BankDetails.vue";
import FlightRequests from "./FlightRequest.vue";
import AdvancePayments from "./AdvancePayment.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";
import ProjectHours from "@/views/dashboard/profile/ProjectHours.vue";
import {getTabIndex} from "@/services/helpers";

export default defineComponent({
  name: "Profile",
  components: {
    AppUnsavedChangesDialog,
    Personal,
    Contact,
    BasicInformation,
    Address,
    PartnerContact,
    PartnerBankDetails,
    AdditionalInformation,
    BankDetails,
    AdvancePayments,
    FlightRequests,
    Signature,
    ProjectHours,
  },
  data() {
    return {
      currentTab: "Personal",
    };
  },
  watch: {
    isUserKey(newVal) {
      if (newVal && this.isCurrentPath !== "/profile") {
        const currentIndex = this.tabs.indexOf(this.currentTab);
        if (currentIndex !== -1 && currentIndex < this.tabs.length - 1) {
          this.currentTab = this.tabs[currentIndex + 1];
        }
      }
    },
  },
  computed: {
    isPreviewToProfile() {
      return this.$store.getters["contractModules/isPreviewToProfile"];
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEmployeesUpdate() {
      return this.$store.getters["hrResources/isEmployeesUpdate"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    currentUserLoginKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },
    isEmployeeName() {
      const userDetails = this.$store.getters["auth/isUserDetails"];
      return `${userDetails?.last_name}, ${userDetails?.first_name}`;
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isEmployeeUserKeyFromHome() {
      return this.$store.getters["hrResources/isEmployeeUserKeyFromHome"];
    },
    employeeNameFromHome() {
      return `${this.isPersonalData?.last_name}, ${this.isPersonalData?.first_name} ${this.isPersonalData?.middle_name}`;
    },
    isCurrentPath() {
      return this.$route.path;
    },
    isUserDetails() {
      return this.$store.getters["auth/isUserDetails"];
    },
    tabList () {
      const tabs = {
        Expert: [
          "Personal",
          "Contact",
          "Bank Details",
          "Advance Payments",
          "Flight Requests",
          "Project Hours"
        ],
        Partner: [
          "Basic Information",
          "Address",
          "Contact",
          "Bank Details",
          "Additional Information",
          "Advance Payments",
          "Flight Requests",
        ]
      }

      let currentTabs = tabs.Expert

      if (Object.keys(tabs).includes(this.isUserDetails?.user_type)) {
        currentTabs = tabs[this.isUserDetails?.user_type]
      }

      return [...currentTabs, "Signature"]
    },

    tabs () {
      const tabs = {
        Expert: [
          "Personal",
          "Contact",
          "BankDetails",
          "AdvancePayments",
          "FlightRequests",
          "ProjectHours"
        ],
        Partner: [
          "BasicInformation",
          "Address",
          "PartnerContact",
          "PartnerBankDetails",
          "AdditionalInformation",
          "AdvancePayments",
          "FlightRequests",
        ]
      }

      let currentTabs = tabs.Expert

      if (Object.keys(tabs).includes(this.isUserDetails?.user_type)) {
        currentTabs = tabs[this.isUserDetails?.user_type]
      }

      return [...currentTabs, "Signature"]
    },
  },
  methods: {
    getTabIndex,
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    hideEditButton() {
      return !this.isEditButton && this.currentTab !== 'AdvancePayments' && this.currentTab !== 'FlightRequests' && !this.isHome;
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
            "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isEmployeesUpdate).some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      const employeeUpdated = this.isEmployeesUpdate[this.currentTab];
      if (employeeUpdated) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isEmployeesUpdate).reduce(
          (acc, curr) => {
            if (this.isEmployeesUpdate[curr]) {
              acc[curr] = false;
            }
            return acc;
          },
          {}
      );
      this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  mounted() {
    this.currentTab = this.tabs[this.isCurrentTab];
    this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  created() {
    if (this.isUserDetails?.user_type === "Partner") {
      this.$store.commit("partnerModules/SET_PARTNER_KEY_TO_STATE", this.isUserDetails?.partner_id);
      this.$store.dispatch("partnerModules/getSinglePartner", {
        partnerID: this.isUserDetails?.partner_id
      });
    }
    this.currentTab = this.tabs[this.isCurrentTab]
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.$store.commit(
        "hrResources/SET_USER_KEY_TO_STATE",
        this.currentUserLoginKey
    );
  },
});
</script>
<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}

.tab-button:hover {
  background: #f8f8f8;
}

.tab-button.active {
  background: #efefef;
  color: #352c2c;
}

.tab-button.disabled {
  cursor: not-allowed;
}
</style>
