<template>
  <v-dialog v-model="showDialog" width="408px" persistent>
    <v-card class="!rounded-lg">
      <div class="px-6 mt-5 text-center">
        <p class="!text-[#101828] !text-[1.125rem] !font-inter !font-medium">
          {{ $t(`${title}`) }}
        </p>
        <p
          class="mt-3 text-wrap !text-[#667085] !text-[0.875rem] !font-normal !font-inter"
        >
          {{ $t(`${subtitle}`) }}
        </p>
      </div>
      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions v-if="showButtons">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      showDialog: false,
    };
  }
});
</script>
