// import Api from "@/services/api"
import Api from "@/services/api";
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    experts: [],
    partners: [],
    qualifications: [],
    pesronalData: null,
    userKey: null,
    tabId: 0,
    tableHeaders: null,
    personalInfo: null,
    isEmployeesUpdate: {},
    editButton: false,
  },

  mutations: {
    SET_EXPERTS_TO_STATE(state: any, payload: any) {
      state.experts = payload;
    },
    SET_PARTNERS_TO_STATE(state: any, payload: any) {
      state.partners = payload;
    },
    SET_TABLE_HEADERS_TO_STATE(state: any, payload: any) {
      state.tableHeaders = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_PERSONAl_DATA_TO_STATE(state: any, payload: any) {
      state.personalData = payload;
    },
    SET_PERSONAl_INFO_TO_STATE(state: any, payload: any) {
      state.personalInfo = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_EMPLOYEE_UPDATE(state: any, payload: any) {
      state.isEmployeesUpdate = { ...state.isEmployeesUpdate, ...payload };
    },
    SET_SHOW_EDIT_BUTTON_TO_STATE(state: any, payload: any) {
      state.editButton = payload;
    },
  },
  getters: {
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isAllEmployees(state: any) {
      return state.experts;
    },
    isAllPartners(state: any) {
      return state.partners;
    },

    isFilters(state: any) {
      return state.personalInfo;
    },
    isTableHeaders(state: any) {
      return state.tableHeaders;
    },
    isAllQualification(state: any) {
      return state.qualifications;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isExpertsUpdate(state: any) {
      return state.isEmployeesUpdate;
    },
    isEditButton(state: any) {
      return state.editButton;
    },
  },
  actions: {
    getAllPartners(context: ActionContext<any, any>) {
      Api()
        .get("/partners/get-all-partners/")
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_PARTNERS_TO_STATE", responseData.data);
          }
        });
    },
  },
};
