<template>
  <v-sheet
    max-width=""
    class="px-10 pt-6 pb-7 mt-5 bg-white border rounded-lg"
    v-if="isAllProjectFiles?.length > 0"
  >
    <div class="flex">
      <div class="flex H400 w50 mt-n1">
        <v-icon
          v-if="showAccordionContent"
          icon="custom:expandLess"
          @click="showAccordionContent = false"
        />
        <v-icon
          v-else
          icon="custom:expandIcon"
          @click="showAccordionContent = true"
        />
        <h4
          class="ml-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
        >
          {{ $t(getTranslatedStringKey(accordionInfo?.name)) }}
        </h4>
      </div>
    </div>
    <div v-show="showAccordionContent" class="mt-6 mb-5">
      <app-table
        :headers="header"
        :items="isAllProjectFiles"
        @edit-event="editFileDialog"
        @delete-event="deleteFileDialog"
        @view-file="viewFileInPath"
        :hideMenu="shouldDisableInput"
      >
        <template #title>
          <h3 class="H500">{{ $t("projects.allFiles") }}</h3>
        </template>
      </app-table>
    </div>
    <!-- delete projec file dialog -->
    <delete-project-files ref="deleteProjectFiles" :file-id="fileID">
    </delete-project-files>
    <!-- create and update project files dailog-->
    <create-project-files
      ref="createProjectFiles"
      :fileType="accordionInfo?.name"
      :isDialog="dialogState"
      :file-size="fileSize"
      :edit-object="payload"
    ></create-project-files>
  </v-sheet>
  <!-- display feedback to user -->
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import { defineComponent } from 'vue';
import api from '@/services/api';
import { getTransString} from "@/services/helpers";
import CreateProjectFiles from "./CreateProjectFiles.vue"
import DeleteProjectFiles from "./DeleteProjectFiles.vue"


export default defineComponent({
  components: {
      CreateProjectFiles,
      DeleteProjectFiles
  },
  props: {
      accordionInfo: {
      type: Object,
      },
      },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: false,
      showAccordionContent: false,
      dialogState: false,
      header: ['Title', 'Date Uploaded', 'User'],
      fileID: null,
      fileTitle: "",
      fileType: "",
      filePath: "",
      fileName: "",
      fileUrl: "",
      fileSize: "",
      isDelete: false,
      isEdit: false,
      payload: {
        title: "" ,
        file_type: "",
        file_path: "" ,
        file_url: "",
        id: null
      }
    }
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFile()
      }
    }
  },
  computed: {
    isDisabled(){
      return this.lastItem?.progress !== 100
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isAllProjectFiles() {
      const allFiles = this.$store.getters["projectModules/isAllProjectFiles"];
      return allFiles
        .filter(curr => curr?.file_type === this.accordionInfo?.name)
        .map(({ title, date_uploaded, user , id, file_path, file_url, file_type}) => ({
          title: title,
          'date uploaded': date_uploaded,
          user: user,
          id: id,
          file_path: file_path,
          file_url: file_url,
          file_type: file_type

        }));
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
  },
  methods: {
  getTranslatedStringKey(field) {
    return getTransString(field);
  },

    getSingleFile(){
      api()
      .get(`/projects/project/manage-files/${this.fileID}/`)
      .then(response => {
        if(response.data.status === "success"){
          const data = response?.data?.data
          this.filePath = data?.file_path
          this.title = data?.title
          this.fileSize = data?.file_size
          this.fileUrl = data?.file_url
          this.filetype = data?.file_type
        }
      })
      .catch()
    },
    viewFileInPath(data){
      if(data.file_url){
        window.open(data.file_url)
      }

    },
     // show delete mode for file
    deleteFileDialog(event) {
      this.fileID = event.id
      this.$refs.deleteProjectFiles.showDialog()
    },
     // show edit mode for file
    editFileDialog(event){
      this.fileID = event.id
      this.$refs.createProjectFiles.isEdit = true
      this.$refs.createProjectFiles.editObject = event
      this.$refs.createProjectFiles.showEditDialog()
      this.getSingleFile()
    },

  getNewProjectFiles(){
  api()
    .get(`/projects/project/files/${this.isProjectID}/`)
    .then((response) => {
      if(response.data.status === 'success'){
        this.$store.dispatch("projectModules/getAllProjectFiles", this.isProjectID);
      }
  })
  },

  },
  created() {
      if(this.accordionInfo?.name === "Tender"){
          this.showAccordionContent = true
      }

  }
})
</script>
<style scoped>
.ae-drag-window {
  border: 1px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}

.ae-drag-window:hover {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border: 1px solid #fdc6c2 !important;
}

.ae-upload-progress {
  border-radius: 5px;
}

.ae-bg-green-300 {
  border: 5px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}
</style>
