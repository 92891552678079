<template>

  <div>
    <app-header
      :name="pmLocalID ? pmLocalTitle : $t('pmLocals.editPmLocal')"
      class="mb-5 ml-n2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
      <template #action>
        <app-secondary-button
          
          :buttonLabel="$t('pmLocals.editPmLocal')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
    </app-header>
    <div class="">
      <button
        v-for="(tab, index) in filteredTabs()"
        :key="index"
        :class="['tab-button', { active: currentTab === tab.id }]"
        @click="handleProceed(tab.id)"
      >
        <span class="tab-tooltip">
          {{ $t(getTranslatedStringKey(tab.name)) }}
        </span>
      </button>

      <div class="mt-2 border-t-[1px]"></div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-10"
      :handleButtonRoute="handleButtonRoute"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
// import BasicInformation from "@/components/dashboard/projects/pm-local/BasicInformation.vue";
// import DefinitionOfBudgetItems from "@/components/dashboard/projects/pm-local/DefinitionOfBudgetItems.vue";
import ProjectOverview from "@/components/dashboard/projects/pm-local/projectOverview/ProjectOverview.vue";
import ProjectOverviewExpense from "@/components/dashboard/projects/pm-local/expense/ProjectOverviewExpense.vue";
import Cheques from "@/components/dashboard/projects/pm-local/cheques/Cheques.vue";
import AddNewCheque from "@/components/dashboard/projects/pm-local/cheques/AddNewCheque.vue";
import BankCah from "@/components/dashboard/projects/pm-local/bank-cash/BankCash.vue";
import Currency from "@/components/dashboard/projects/pm-local/currency-exchange/CurrencyExchange.vue";

import RequestedFunds from "@/components/dashboard/projects/pm-local/RequestedFunds.vue";
import RequestNewFund from "@/components/dashboard/projects/pm-local/RequestNewFund.vue";


import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: "EditPmLocal",
  components: {
    // BasicInformation,

    ProjectOverview,
    ProjectOverviewExpense,
    Cheques,
    AddNewCheque,
    BankCah,
    Currency,
    RequestedFunds,
    // DefinitionOfBudgetItems,
    RequestNewFund,
  },
  data() {
    return {
      currentTab: "Personal",
      tabList: [
        // "Basic Information",
       
        "Project Overview",
        "Expenses",
        "Cheques",
        "Add / Update Cheque",
        "Bank/Cash",
        "Currency Exchange",
        "Requested Funds",
        //  "Definition of Budget Items",
        "Request New Fund",
      ],
      tabs: [
        // "BasicInformation",
        // "DefinitionOfBudgetItems",
        "ProjectOverview",
        "ProjectOverviewExpense",
        "Cheques",
        "AddNewCheque",
        "BankCah",
        "Currency",
        "RequestedFunds",
        // "DefinitionOfBudgetItems",
        "RequestNewFund",
      ],
      newTabList: [
        // {
        //   id: "BasicInformation",
        //   name: "Basic Information",
        // },
        // {
        //   id: "DefinitionOfBudgetItems",
        //   name: "Definition of Budget Items",
        // },
        {
          id: "ProjectOverview",
          name: "Project Overview",
        },
        {
          id: "ProjectOverviewExpense",
          name: "Expenses",
        },
        {
          id: "Cheques",
          name: "Cheques",
        },
        {
          id: "AddNewCheque",
          name: "Add / Update Cheque",
        },
        {
          id: "BankCah",
          name: "Bank/Cash",
        },
        {
          id: "Currency",
          name: "Currency Exchange",
        },
        {
          id: "RequestedFunds",
          name: "Requested Funds",
        },
        //  {
        //   id: "DefinitionOfBudgetItems",
        //   name: "Definition of Budget Items",
        // },
        {
          id: "RequestNewFund",
          name: "Request New Fund",
        },
       
      ],
      // tabListForExcluded: ["Definition of Budget Items"],
    };
  },

  computed: {
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    pmLocalUpdate() {
      return this.$store.getters["pmLocalModules/isPmLocalUpdate"];
    },
    pmLocalTitle() {
      return this.$store.getters["pmLocalModules/isPmLOcalTitle"];
    },
    isCheckAcceptance() {
      return this.$store.getters["pmLocalModules/isCheckAcceptance"];
    },
    isAddCheque() {
      return this.$store.getters["chequeModules/isAddCheque"];
    },
    isAddExpense() {
      return this.$store.getters["projectOverview/isAddExpense"];
    },
    getShowRequestNewFund() {
      return this.$store.getters["pmLocalModules/getShowRequestNewFund"];
    },
    isEditCheque() {
      return this.$store.getters["chequeModules/isEditCheque"];
    },

    ...mapState(["translation"]),
  },

  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    getSingleOffice(id) {
      if (this.pmLocalID) {
        const payload = {
          load_page: true,
          pmLocalID: id,
        };
        this.$store.dispatch("pmLocalModules/getSingleOffice", payload);
      }
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.pmLocalUpdate)?.some((elt) => elt === true);
    },
    handleProceed(id) {
      this.newTabIndex = id;
      if (this.pmLocalUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = id;
      }
    },
    handleGoToBack() {
      if (Object.values(this.pmLocalUpdate)?.some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.pmLocalUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    filteredTabs() {
      let hasCheckAcceptance = this.isCheckAcceptance;
      let hasAddCheque = this.isAddCheque;
      let hasAddExpense = this.isAddExpense;
      let hasRequestNewFund = this.getShowRequestNewFund;

      if (
        hasCheckAcceptance &&
        hasAddCheque &&
        hasAddExpense &&
        hasRequestNewFund
      ) {
        return this.newTabList;
      } else if (hasCheckAcceptance && hasAddCheque && hasAddExpense) {
        return this.newTabList.filter((tab) => tab.id !== "RequestNewFund");
      } else if(hasCheckAcceptance && hasAddExpense && hasRequestNewFund){
        return this.newTabList.filter((tab) => tab.id !== "AddNewCheque");}
      else if (hasCheckAcceptance && hasAddExpense) {
        return this.newTabList.filter(
          (tab) => tab.id !== "AddNewCheque" && tab.id !== "RequestNewFund"
        );
      } else if (hasCheckAcceptance && hasAddCheque && this.isEditCheque) {
        return this.newTabList.filter(
          (tab) => tab.id !== "ProjectOverviewExpense"
        );
      } else if (hasCheckAcceptance && hasAddCheque ) {
        return this.newTabList.filter(
          (tab) => tab.id !== "ProjectOverviewExpense"
        );
      }
      else if (hasAddExpense && hasRequestNewFund) {
        return this.newTabList.filter(
          (tab) => tab.id !== "AddNewCheque"
        );
      } else if (hasCheckAcceptance) {
        return this.newTabList.filter(
          (tab) =>
            tab.id !== "AddNewCheque" &&
            tab.id !== "ProjectOverviewExpense" &&
            tab.id !== "RequestNewFund"
        );
      } else if (hasAddExpense) {
        return this.newTabList.filter(
          (tab) =>
            tab.id !== "Cheques" &&
            tab.id !== "AddNewCheque" &&
            tab.id !== "RequestNewFund"
        );
      } else if (hasRequestNewFund) {
        return this.newTabList.filter(
          (tab) =>
            tab.id !== "Cheques" &&
            tab.id !== "AddNewCheque" &&
            tab.id !== "ProjectOverviewExpense"
        );
      } else {
        return this.newTabList.filter(
          (tab) =>
            tab.id !== "Cheques" &&
            tab.id !== "AddNewCheque" &&
            tab.id !== "ProjectOverviewExpense" &&
            tab.id !== "RequestNewFund"
        );
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.foreignOfficeUpdate).reduce(
        (acc, curr) => {
          if (this.foreignOfficeUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("pmLocalModules/SET_PM_LOCAL_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  created() {
    // this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    // this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);

    this.currentTab = this.tabs[this.isCurrentTab];

    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>
<style scoped>
button:disabled {
  pointer-events: none;
}
</style>
