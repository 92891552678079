<template>
  <app-dialog
    ref="isBudgetSection"
    :title="
      isUpdate
        ? $t('projectBudget.updateBudgetSection')
        : $t('projectBudget.addSection')
    "
    subtitle=""
  >
    <template #content>
      <div>
        <app-input-field
          class="mt-2"
          type="text"
          :label="$t('projectBudget.sectionName')"
          :placeholder="$t('projectBudget.enterSectionName')"
          v-model="addBudgetSection"
        ></app-input-field>
      </div>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="5" class="">
          <app-secondary-button
            :buttonLabel="$t('projects.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="7" class="">
          <app-primary-button
            :buttonLabel="
              isUpdate ? $t('projectBudget.update') : $t('projectBudget.add')
            "
            :loading="loading"
            :disabled="addBudgetSection === ''"
            @click-event="
              this.isUpdate ? editBudgetSection() : addNewBudgetSection()
            "
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue"
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import {addOrUpdateBudgetSection} from '@/services/endpoints';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'Create and Update Budget section',
  props: {
      isUpdate: {
          type: Boolean,
          default: false
      },
      editData: {
          type: Object,
      }
  },
  data() {
    return {
      loading: false,
        addloading: false,
        alertType: "success",
        alertState: false,
        alertTitle: "",
       addBudgetSection: '',
    }
  },

  computed: {
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    contractDetails(){
    return this.$store.getters["projectModules/isContractDetails"];
  },
  isMandatorySection(){
    return this.$store.getters["projectBudgetModules/isMandatorySection"];
  },
  ...mapGetters("projectBudgetModules", ["getError", "getResponse"]),

  },

  methods: {
    ...mapActions("projectBudgetModules", ["createBudgetSection", "updateBudgetSection"]),
    getTranslatedStringKey(field) {
        return getTransString(field);
      },

    showDialog() {
      this.addBudgetSection = this.isUpdate === true ? this.editData.title :  ''
      this.$refs.isBudgetSection.showDialog = true;
    },
    hideDialog() {
      this.$refs.isBudgetSection.showDialog = false;
      this.addBudgetSection = ''
    },

    createUpdateBudgetSection() {
      const payload = {
        title : this.addBudgetSection,
        project_id: this.isProjectID,
        budget_section_id: this.editData?.id
      }
      if(this.isUpdate === false){
          delete payload.budget_section_id
      }
      Api()
      .post(addOrUpdateBudgetSection, payload)
      .then((response)=>{
        if(response.data.status === 'success'){

          this.alertType = "success";
          this.alertState = !this.alertState;
          this.alertTitle = response.data.detail;
          this.hideDialog()
          this.$store.commit('projectBudgetModules/SET_PROJECT_BUDGET_SECTION_ID_TO_STATE', response?.data?.data?.id)
          // this.$store.dispatch('projectBudgetModules/getAllProjectBudgets', this.isProjectID)
        }
      })

    },

    async addNewBudgetSection() {
      await this.createBudgetSection( {
        title : this.addBudgetSection,
        project: this.isProjectID
      });

      if(this.getResponse.status === 'success'){

        this.alertType = "success";
        this.alertState = !this.alertState;
        this.alertTitle = this.getResponse.detail;
        this.hideDialog();
        this.addBudgetSection = '';
        this.$store.commit('projectBudgetModules/SET_PROJECT_BUDGET_SECTION_ID_TO_STATE', this.getResponse?.data?.id)
        this.$store.commit('projectBudgetModules/APPEND_NEW_BUDGET_SECTION_TO_STORE',{ id : this.getResponse?.data?.id, title: this.getResponse?.data?.title})
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });
      }
    },

    async editBudgetSection() {
      if (this.editData?.id) {
        await this.updateBudgetSection( {
          title : this.addBudgetSection,
          budgetSectionId: this.editData?.id
        });

        if(this.getResponse.status === 'success'){

          this.alertType = "success";
          this.alertState = !this.alertState;
          this.alertTitle = this.getResponse.detail;
          this.hideDialog();
          this.addBudgetSection = '';
          this.$store.commit('projectBudgetModules/SET_PROJECT_BUDGET_SECTION_ID_TO_STATE', this.getResponse?.data?.id)
          this.$store.dispatch('projectBudgetModules/getAllProjectBudgets', this.isProjectID)
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });
        }
      }
    }

  },
 async mounted() {
    const payload = {
      projectID: this.isProjectID
    }
    this.$store.dispatch('projectModules/getContractDetails', payload)
    this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE', '')
    // if(this.contractDetails?.is_bank_guarantee && this.isMandatorySection === ''){
    //   this.addBudgetSection = 'Mandatory'
    //   this.$store.commit('projectBudgetModules/SET_BUDGET_SECTION_TO_STATE', this.addBudgetSection)
    //   await this.addNewBudgetSection()
    // } else{
    //   this.$store.commit('projectBudgetModules/SET_BUDGET_SECTION_TO_STATE', '')
    // }
  },
})
</script>
<style scoped></style>
