<template>
  <div class="w-full table-container">
    <div
      v-for="(table, index) in filteredRows"
      :key="table.id"
      class="mx-auto mb-2"
    >
      <h1 class="py-2 H500">{{ convertToFormattedString(index) }}</h1>
      <div :class="['w-full  mb-6 ']">
        <table
          :class="[
            'table-fixed w-[2065px] ',
            isSplitEditMode === false ? 'cant-edit mb-5' : 'edit',
          ]"
          aria-describedby=""
        >
          <thead>
            <tr>
              <th rowspan="2" class="w-[3.1970rem] bg-[#F2F4F7]">
                <table class="w-full border-collapse">
                  <tr
                    class="flex items-center justify-between H400 labels_menu"
                  >
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? ['px-2', 'w-[60%]']
                          : ['px-2', 'w-full']
                      "
                    >
                      {{ $t("projectBudget.position") }}
                    </td>
                    <td
                      v-if="index !== 'unallocated_cost'"
                      class="border_side"
                    ></td>
                    <td
                      v-if="index !== 'unallocated_cost'"
                      :class="
                        index !== 'unallocated_cost'
                          ? ['px-1', 'w-[140px]']
                          : ''
                      "
                    >
                      {{ $t("projectBudget.noOfUnits") }}
                    </td>
                    <td
                      v-if="index !== 'unallocated_cost'"
                      class="border_side"
                    ></td>
                    <td
                      v-if="index !== 'unallocated_cost'"
                      :class="
                        index !== 'unallocated_cost'
                          ? ['px-3', 'w-[140px]']
                          : ''
                      "
                    >
                      {{ $t("projectBudget.amountPerUnit") }}
                    </td>
                  </tr>
                </table>
              </th>

              <th
                rowspan="2"
                v-for="(column, columnIndex) in table.months_row"
                :key="columnIndex"
                class="w-5 px-1 border-t border-b border-l bg-[#D1FADF] H400 text-center"
                :class="{
                  'bg-[#F2F4F7]': index == 'unallocated_cost',
                }"
              >
                {{ column }}
              </th>
              <th
                colspan="1"
                class="w-10 px-1 py-2 border-b border-l bg-[#F9FAFB] H400 text-center"
              >
                {{ $t("projectBudget.subtotal") }}
              </th>
              <th
                colspan="2"
                class="w-10 px-1 border-b border-l bg-[#F9FAFB] H400 text-center"
              >
                {{ $t("projectBudget.total") }}
              </th>
            </tr>
            <tr>
              <!-- <th
               v-for="(column, columnIndex) in table.months_row"
               :key="columnIndex"
               class="w-4 px-2 border-b border-l bg-[#F9FAFB] H400 text-center"
             >
               {{ column }}
             </th> -->
              <!-- <th
               class="w-4 px-1 border-b border-l bg-[#F9FAFB] py-2 H400 text-center"
             >
               2022
             </th> -->
              <th
                class="w-4 px-1 border-b border-l bg-[#F9FAFB] py-2 H400 text-center"
              >
                {{ $t("projectBudget.tillNow") }}
              </th>
              <th
                class="w-4 px-1 border-b border-l bg-[#F9FAFB] py-2 H400 text-center"
              >
                {{ $t("projectBudget.total") }}
              </th>
              <th
                class="w-4 px-1 border-b border-l bg-[#F9FAFB] py-2 H400 text-center"
              >
                {{ $t("projectBudget.balance") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in table.data.length"
              :key="rowIndex"
              :class="[
                index !== 'unallocated_cost'
                  ? ({
                      'highlighted-row': isNaN(
                        table.data[rowIndex]?.amount_per_unit
                      ),
                    },
                    { 'highlighted-row-yellow': rowIndex === 'approved' },
                    { 'highlighted-row-yellow': rowIndex === 'approved' })
                  : 'none',
              ]"
            >
              <td v-if="index === 'unallocated_cost'" :class="['H400']">
                <table class="border-collapse">
                  <tr
                    class="flex items-center justify-between H400 labels_menu"
                  >
                    <td class="px-2 py-1 unit position">
                      {{ table.data[rowIndex]?.position }}
                    </td>
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? { 'border-row': rowIndex >= 4 && rowIndex <= 7 }
                          : 'none'
                      "
                      class="item_border_side"
                    ></td>
                    <td
                      :class="
                        index !== 'unallocated_cost' ? ['unit'] : 'unalocated'
                      "
                    >
                      {{
                        table.data[rowIndex]?.units === ""
                          ? table.data[rowIndex]?.amount_per_unit
                          : table.data[rowIndex]?.units
                      }}
                    </td>
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? { 'border-row': rowIndex >= 4 && rowIndex <= 7 }
                          : 'none'
                      "
                      class="item_border_side"
                    ></td>
                    <td
                      :class="
                        index !== 'unallocated_cost' ? ['unit'] : 'unalocated'
                      "
                    >
                      {{
                        table.data[rowIndex]?.units === ""
                          ? ""
                          : table.data[rowIndex]?.amount_per_unit
                      }}
                    </td>
                  </tr>
                </table>
              </td>
              <td
                v-else
                :class="[
                  'H400',
                  {
                    'highlighted-row': isNaN(
                      table.data[rowIndex]?.amount_per_unit
                    ),
                  },
                  'w-16',
                ]"
              >
                <table class="w-full border-collapse">
                  <tr
                    :class="[
                      'flex items-center H400 labels_menu',
                      {
                        'bg-[#FEFBE8]':
                          table.data[rowIndex]?.structure === 'BOP - Fixed Fee',
                      },
                      {
                        'bg-[#F2F4F7]':
                          table.data[rowIndex]?.structure ===
                          'BOP - Against Receipt',
                      },
                      {
                        'bg-[#F4EAE9]':
                          table.data[rowIndex]?.position == 'Backstopping' ||
                          table.data[rowIndex]?.position == 'Bank guarantee',
                      },
                    ]"
                  >
                    <td
                      class="px-2 w-[51.2%]"
                      v-if="table.data[rowIndex]?.position !== ''"
                    >
                      {{ table.data[rowIndex]?.position }}
                    </td>
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? {
                              'border-row': isNaN(
                                table.data[rowIndex]?.amount_per_unit
                              ),
                            }
                          : 'none'
                      "
                      class="item_border_side"
                    ></td>
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? [
                              'px-1',
                              {
                                'w-[119px]': table.data[rowIndex]?.units,
                              },

                              {
                                'w-full': !table.data[rowIndex]?.units,
                              },
                            ]
                          : 'none'
                      "
                    >
                      {{
                        table.data[rowIndex]?.units === ""
                          ? table.data[rowIndex]?.amount_per_unit
                          : table.data[rowIndex]?.units
                      }}
                    </td>
                    <td
                      :class="
                        index !== 'unallocated_cost'
                          ? {
                              'border-row': isNaN(
                                table.data[rowIndex]?.amount_per_unit
                              ),
                            }
                          : 'none'
                      "
                      class="item_border_side"
                    ></td>
                    <td
                      v-if="!isNaN(table.data[rowIndex]?.amount_per_unit)"
                      :class="
                        isNaN(table.data[rowIndex]?.amount_per_unit)
                          ? ['']
                          : 'none'
                      "
                    >
                      {{
                        table.data[rowIndex]?.units === ""
                          ? ""
                          : table.data[rowIndex]?.amount_per_unit
                      }}
                    </td>
                  </tr>
                </table>
              </td>
              <td
                v-for="(cell, cellIndex) in table.months_row?.length"
                :key="cellIndex"
                :class="[
                  'w-2 px-2 border',
                  index !== 'unallocated_cost'
                    ? {
                        'highlighted-row': isNaN(
                          table.data[rowIndex]?.amount_per_unit
                        ),
                      }
                    : 'none',
                  index === 'unallocated_cost' ? 'cant-edit' : 'edit',
                  {
                    'bg-[#FEFBE8]':
                      table.data[rowIndex]?.structure === 'BOP - Fixed Fee',
                  },
                  {
                    'bg-[#F2F4F7]':
                      table.data[rowIndex]?.structure ===
                      'BOP - Against Receipt',
                  },
                  {
                    'bg-[#F4EAE9]':
                      table.data[rowIndex]?.position == 'Backstopping' ||
                      table.data[rowIndex]?.position == 'Bank guarantee',
                  },
                ]"
              >
                <input
                  :id="
                    table.data[rowIndex]?.item_amounts_cols[cellIndex].split_id
                  "
                  :value="
                    getCell(table, rowIndex, cellIndex) ||
                    (table.data[rowIndex]?.item_amounts_cols[cellIndex]
                      .value === '-'
                      ? '-'
                      : `${
                          table.data[rowIndex]?.amount_per_unit === 'Margin %'
                            ? ''
                            : isCurrentProject.currency || '€'
                        }` +
                        `${
                          table.data[rowIndex]?.amount_per_unit ===
                            'Total Profit Margin 2' ||
                          table.data[rowIndex]?.amount_per_unit ===
                            'Total Profit Margin 1'
                            ? `${handleMarginAndPercentTranslation(
                                table.data[rowIndex]?.item_amounts_cols[
                                  cellIndex
                                ].value,
                                appLang
                              )}`
                            : convertCurrencyFormat(
                                appLang === 'de'
                                  ? germanCurrency(
                                      table.data[rowIndex]?.item_amounts_cols[
                                        cellIndex
                                      ].value
                                    )
                                  : parseFloat(
                                      table.data[rowIndex]?.item_amounts_cols[
                                        cellIndex
                                      ].value
                                    )?.formatCurrency()
                              )
                        } ${
                          table.data[rowIndex]?.amount_per_unit === 'Margin %'
                            ? '%'
                            : ''
                        }`)
                  "
                  @input="
                    handleCellInput(
                      $event,
                      index,
                      rowIndex,
                      cellIndex,
                      $event.target.value,
                      table.data[rowIndex]?.item_amounts_cols[cellIndex]
                        .split_id
                    )
                  "
                  :class="[
                    'w-full  H400',
                    table.data[rowIndex]?.item_amounts_cols[cellIndex].value ===
                    '-'
                      ? 'cant-edit'
                      : 'edit',
                  ]"
                />
              </td>
              <!-- <td :class="['w-4 px-2 border H400 text-center',index !=='unallocated_cost'?{'highlighted-row':isNaN(table.data[rowIndex]?.amount_per_unit)}:'none']">{{table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 0].value==='-' ? '-' : `${table.data[rowIndex]?.amount_per_unit==='Margin %'?'%':isCurrentProject.currency}`+table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 0].value}}</td>
             <td :class="['w-4 px-2 border H400 text-center',index !=='unallocated_cost'?{'highlighted-row':isNaN(table.data[rowIndex]?.amount_per_unit)}:'none']">{{table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 1].value==='-' ? '-' : `${table.data[rowIndex]?.amount_per_unit==='Margin %'?'%':isCurrentProject.currency}`+table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 1].value}}</td>
             <td :class="['w-4 px-2 border H400 text-center',index !=='unallocated_cost'?{'highlighted-row':isNaN(table.data[rowIndex]?.amount_per_unit)}:'none']">{{table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 2].value==='-' ? '-' : `${table.data[rowIndex]?.amount_per_unit==='Margin %'?'%':isCurrentProject.currency}`+table.data[rowIndex]?.item_amounts_cols[table.months_row?.length + 2].value}}</td> -->

              <td
                :class="[
                  'w-4 px-2 border H400 ',
                  index !== 'unallocated_cost'
                    ? {
                        'highlighted-row': isNaN(
                          table.data[rowIndex]?.amount_per_unit
                        ),
                      }
                    : 'none',
                  {
                    'bg-[#FEFBE8]':
                      table.data[rowIndex]?.structure === 'BOP - Fixed Fee',
                  },
                  {
                    'bg-[#F2F4F7]':
                      table.data[rowIndex]?.structure ===
                      'BOP - Against Receipt',
                  },
                  {
                    'bg-[#F4EAE9]':
                      table.data[rowIndex]?.position == 'Backstopping' ||
                      table.data[rowIndex]?.position == 'Bank guarantee',
                  },
                ]"
              >
                {{
                  table.data[rowIndex]?.item_amounts_cols[
                    table.months_row?.length + 0
                  ].value === "-"
                    ? "-"
                    : `${
                        table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? ""
                          : isCurrentProject.currency || "€"
                      }` +
                      `${
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 2" ||
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 1"
                          ? `${handleMarginAndPercentTranslation(
                              table.data[rowIndex]?.item_amounts_cols[
                                table.months_row?.length + 0
                              ].value,
                              appLang
                            )}`
                          : table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? table.data[rowIndex]?.item_amounts_cols[
                              table.months_row?.length + 0
                            ].value + "%"
                          : convertCurrencyFormat(
                              appLang === "de"
                                ? germanCurrency(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 0
                                    ].value
                                  )
                                : parseFloat(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 0
                                    ].value
                                  )?.formatCurrency()
                            )
                      }`
                }}
              </td>
              <td
                :class="[
                  'w-4 px-2 border H400 ',
                  index !== 'unallocated_cost'
                    ? {
                        'highlighted-row': isNaN(
                          table.data[rowIndex]?.amount_per_unit
                        ),
                      }
                    : 'none',
                  {
                    'bg-[#FEFBE8]':
                      table.data[rowIndex]?.structure === 'BOP - Fixed Fee',
                  },
                  {
                    'bg-[#F2F4F7]':
                      table.data[rowIndex]?.structure ===
                      'BOP - Against Receipt',
                  },
                  {
                    'bg-[#F4EAE9]':
                      table.data[rowIndex]?.position == 'Backstopping' ||
                      table.data[rowIndex]?.position == 'Bank guarantee',
                  },
                ]"
              >
                {{
                  table.data[rowIndex]?.item_amounts_cols[
                    table.months_row?.length + 1
                  ].value === "-"
                    ? "-"
                    : `${
                        table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? ""
                          : isCurrentProject.currency || "€"
                      }` +
                      `${
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 2" ||
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 1"
                          ? `${handleMarginAndPercentTranslation(
                              table.data[rowIndex]?.item_amounts_cols[
                                table.months_row?.length + 1
                              ].value,
                              appLang
                            )}`
                          : table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? table.data[rowIndex]?.item_amounts_cols[
                              table.months_row?.length + 1
                            ].value + "%"
                          : convertCurrencyFormat(
                              appLang === "de"
                                ? germanCurrency(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 1
                                    ].value
                                  )
                                : parseFloat(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 1
                                    ].value
                                  )?.formatCurrency()
                            )
                      }`
                }}
              </td>
              <td
                :class="[
                  'w-4 px-2 border H400 ',
                  index !== 'unallocated_cost'
                    ? {
                        'highlighted-row': isNaN(
                          table.data[rowIndex]?.amount_per_unit
                        ),
                      }
                    : 'none',
                  {
                    'bg-[#FEFBE8]':
                      table.data[rowIndex]?.structure === 'BOP - Fixed Fee',
                  },
                  {
                    'bg-[#F2F4F7]':
                      table.data[rowIndex]?.structure ===
                      'BOP - Against Receipt',
                  },
                  {
                    'bg-[#F4EAE9]':
                      table.data[rowIndex]?.position == 'Backstopping' ||
                      table.data[rowIndex]?.position == 'Bank guarantee',
                  },
                ]"
              >
                {{
                  table.data[rowIndex]?.item_amounts_cols[2].value === "-"
                    ? "-"
                    : `${
                        table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? ""
                          : isCurrentProject.currency || "€"
                      }` +
                      `${
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 2" ||
                        table.data[rowIndex]?.amount_per_unit ===
                          "Total Profit Margin 1"
                          ? `${handleMarginAndPercentTranslation(
                              table.data[rowIndex]?.item_amounts_cols[
                                table.months_row?.length + 2
                              ].value,
                              appLang
                            )}`
                          : table.data[rowIndex]?.amount_per_unit === "Margin %"
                          ? table.data[rowIndex]?.item_amounts_cols[
                              table.months_row?.length + 2
                            ].value + "%"
                          : convertCurrencyFormat(
                              appLang === "de"
                                ? germanCurrency(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 2
                                    ].value
                                  )
                                : parseFloat(
                                    table.data[rowIndex]?.item_amounts_cols[
                                      table.months_row?.length + 2
                                    ].value
                                  )?.formatCurrency()
                            )
                      }`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-alert
      :title="alertTitle"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  watch,
  reactive,
} from "vue";
import {
  convertCurrencyFormat,
  convertToFormattedString,
  formatAsPercentage,
} from "@/services/budgetSplitformatters";
import { getYear } from "date-fns";
import Api from "@/services/api";
import { getTransString, currencyFormatter } from "@/services/helpers";
import { useStore } from "vuex";

export default defineComponent({
  name: "MileStoneSplitTable",
  setup() {
    let alertState = ref(false);
    let alertTitle = ref("");
    let alertType = ref("success");
    let curentPage = ref(1);
    let totalPages = ref(null);
    const startDate = new Date("2019-09-01");
    const endDate = new Date("2023-09-09");
    const currentYear = ref(getYear(new Date()));
    const monthlySplit = ref([]);
    const monthlySplitAll = ref([]);
    const Headers = ref([]);
    const store = useStore();
    const changesArray = ref([]);
    const allTillNow = ref({});
    const allSubTotal = ref({});

    const isProjectID = computed(() => {
      return store.getters["projectModules/isProjectID"];
    });
    const isEditSplit = computed(() => {
      return store.getters["projectBudgetModules/isEditSplit"];
    });
    const isSplitEditMode = computed(() => {
      return store.getters["projectBudgetModules/isSplitEditMode"];
    });
    const isCurrentProject = computed(() => {
      return store.getters["projectModules/isCurrentProject"];
    });
    const appLang = computed(() => {
      return store.state.translation.locale;
    });
    const isCurrentPage = computed(() => {
      return store.getters["projectBudgetModules/isCurrentPages"];
    });

    const isStructure = computed(() => {
      return store.getters["projectBudgetModules/isStructure"];
    });

    const isIncome = computed(() => {
      return [isStructure.value[0], isStructure.value[1]];
    });

    const isCost = computed(() => {
      return [
        isStructure.value[1],
        isStructure.value[2],
        isStructure.value[3],
        isStructure.value[4],
      ];
    });

    const isInternalCost = computed(() => {
      return [isStructure.value[4]];
    });

    const getYearlyCostSubtotals = computed(() => {
      return store.getters["projectBudgetModules/getYearlyCostSubtotals"];
    });

    const getYearlyIncomeSubtotals = computed(() => {
      return store.getters["projectBudgetModules/getYearlyIncomeSubtotals"];
    });

    const getAllCostSubtotals = computed(() => {
      return store.getters["projectBudgetModules/getAllCostSubtotals"];
    });

    const getAllIncomeSubtotals = computed(() => {
      return store.getters["projectBudgetModules/getAllIncomeSubtotals"];
    });

    const getAllInternalCostSubtotals = computed(() => {
      return store.getters["projectBudgetModules/getAllInternalCostSubtotals"];
    });

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const columns = ref(months);
    const filteredRows = ref({});
    const filteredAllRows = ref({});
    const startYear = getYear(startDate);
    const endYear = getYear(endDate);
    const state = reactive({
      isCurrentPage: isCurrentPage,
    });
    const click = reactive({
      isEditSplit: isEditSplit,
    });

    watch(
      () => state.isCurrentPage,
      () => {
        getMonthlySplit();
      }
    );
    const yearRange = Array(endYear - startYear + 1)
      .fill()
      .map((_, index) => startYear + index);
    let rowlength;
    const rows = ref();
    //  Array(9)
    //    .fill()
    //    .map(() => Array(months.length).fill(""))
    const computedRows = computed(() => rows.value);
    const getTranslationKey = (key) => {
      return getTransString(key);
    };
    const rowData = ref(
      monthlySplit.value.map((table) =>
        table.data.map((row) =>
          row.cells.map((cell) => (cell !== "" ? cell : "-"))
        )
      )
    );
    function getCell(table, rowIndex, cellIndex) {
      if (
        !table ||
        !table.data ||
        !Array.isArray(table.data[rowIndex]?.cells)
      ) {
        return null;
      }

      const cellValue = table.data[rowIndex].cells[cellIndex];
      return cellValue !== "" ? cellValue : null;
    }

    const filterInput = (data) => {
      const filteredData = {};
      for (const key in data) {
        if (key !== "unallocated_cost") {
          filteredData[key] = data[key];
        }
      }
      return filteredData;
    };

    const getRandomAttribute = (obj) => {
      const keys = Object.keys(obj);
      const randomKey = keys[Math.floor(Math.random() * keys.length)];
      return obj[randomKey];
    };

    const handleMarginAndPercentTranslation = (inputString, appLang) => {
      // Input string with a dynamic currency symbol
      //"€9.99(8.91%)";

      // Define a regular expression pattern to match numbers with or without decimal points
      var regexPattern = /[0-9]+(\.[0-9]+)?/g;

      // Use the regular expression to find all matches in the input string
      var numbers = inputString.match(regexPattern);
      let margin = 0;
      let marginPercent = 0;
      // Check if there are at least two numbers in the array
      if (numbers && numbers.length >= 2) {
        // Extract the first two numbers into variables
        margin =
          appLang === "de"
            ? germanCurrency(parseFloat(numbers[0]))
            : parseFloat(numbers[0])?.formatCurrency();
        marginPercent =
          appLang === "de"
            ? germanCurrency(parseFloat(numbers[1]))
            : parseFloat(numbers[1])?.formatCurrency();

        // Output the extracted numbers
        return `${margin}(${marginPercent}%)`;
      }

      return `${margin}(${marginPercent}%)`;
    };

    const calculate = (monthlySplitValue, $event = null) => {
      let filteredMonthlySplit = filterInput(monthlySplitValue);
      let unallocated_cost = monthlySplitValue["unallocated_cost"];
      //grab any object
      const randomSection = getRandomAttribute(filteredMonthlySplit);
      let columnLength = randomSection?.data[0]?.item_amounts_cols?.length;

      let totalUnallocattedCost = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let totalUnallocattedIncome = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let totalUnallocattedInternalCost = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let totalUnallocattedCostWithoutInternal = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let totalUnallocattedProfitMargin2 = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let totalUnallocattedCashflow = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);
      let backstopping = Array(
        randomSection?.data[0]?.item_amounts_cols?.length
      ).fill(0);

      //Assign backstopping values
      if ("Mandatory" in monthlySplitValue) {
        let data = monthlySplitValue["Mandatory"]?.data;
        // Iterate through the data array to find the "Backstopping" position
        let backstoppingPosition = null;

        for (const item of data) {
          if (item.position === "Backstopping") {
            backstoppingPosition = item;
            break; // Found "Backstopping," exit the loop
          }
        }

        if (backstoppingPosition != null) {
          let item_amounts_cols = backstoppingPosition.item_amounts_cols;
          for (let i = 0; i < backstopping.length; i++) {
            let value = item_amounts_cols[i].value;
            if (typeof value === "number") {
              backstopping[i] = value;
            }
          }
        }
      }

      //Collect data in respective section and line item and add
      for (const key of Object.keys(filteredMonthlySplit)) {
        let element = filteredMonthlySplit[key];
        const dataArray = element?.data;
        let totalIncomeArr = Array(
          dataArray[0]?.item_amounts_cols?.length
        ).fill(0);
        let totalCostArr = Array(dataArray[0]?.item_amounts_cols?.length).fill(
          0
        );
        let totalInternalCostArr = Array(
          dataArray[0]?.item_amounts_cols?.length
        ).fill(0);
        let allIncomeTotal = 0;
        let allCostTotal = 0;
        let allInternalCostTotal = 0;

        dataArray?.forEach((item, i) => {
          if ("structure" in item) {
            //calcualte all costs
            if (isCost.value.includes(item.structure)) {
              let curentYearCostSubTotal = 0;
              let currentPage = "" + isCurrentPage.value;

              for (const indx in item.item_amounts_cols) {
                if (typeof item.item_amounts_cols[indx].split_id === "number") {
                  //column Subtotal
                  totalCostArr[indx] += item.item_amounts_cols[indx].value;
                  curentYearCostSubTotal += item.item_amounts_cols[indx].value;
                } else {
                  totalCostArr[indx] = 0;
                  //row subTotal
                  allSubTotal.value[key][i]["Page " + currentPage] =
                    curentYearCostSubTotal;
                  store.commit(
                    "projectBudgetModules/SET_YEARLY_COST_SUBTOTALS",
                    {
                      section: key,
                      lineItem: i,
                      currentYear: currentPage,
                      subTotal: curentYearCostSubTotal,
                    }
                  );

                  if (item.item_amounts_cols[indx].split_id == "Till now") {
                    let tillNow = 0;

                    let filterYearlyCostSubTotal = Object.keys(
                      getYearlyCostSubtotals.value
                    ).reduce((filtered, key) => {
                      const page = key.split("-")[2];
                      if (parseInt(page) <= currentPage) {
                        filtered[key] = getYearlyCostSubtotals.value[key];
                      }
                      return filtered;
                    }, {}); //remove all year above current year

                    for (const k in filterYearlyCostSubTotal) {
                      const parts = k.split("-");
                      let section = parts[0];
                      let lineItemIndex = parts[1];
                      // let year = parts[2];
                      if (i == lineItemIndex && section == key) {
                        tillNow += filterYearlyCostSubTotal[k];
                      }
                    }
                    item.item_amounts_cols[indx].value = tillNow;
                  }

                  if (item.item_amounts_cols[indx].split_id == "Total") {
                    let sum = 0;

                    for (const year in allSubTotal.value[key][i]) {
                      sum += allSubTotal.value[key][i][year];
                    }

                    let tr = $event?.target?.parentNode?.parentNode; //select table row

                    if (sum.toFixed(2) > item.item_amounts_cols[indx].value) {
                      tr?.classList.add("red-border");
                    }
                    //All Cost Total
                    allCostTotal += item.item_amounts_cols[indx].value;
                  }

                  if (item.item_amounts_cols[indx].split_id == "Balance") {
                    item.item_amounts_cols[indx].value =
                      item.item_amounts_cols[indx - 1].value -
                      item.item_amounts_cols[indx - 2].value;
                    //total                          - // tillNow
                  }
                }
              }
            }

            //calcualte all income
            if (isIncome.value.includes(item.structure)) {
              let curentYearIncomeSubTotal = 0;
              let currentPage = "" + isCurrentPage.value;
              for (const indx in item.item_amounts_cols) {
                if (typeof item.item_amounts_cols[indx].split_id === "number") {
                  //column Subtotal
                  totalIncomeArr[indx] += item.item_amounts_cols[indx].value;
                  curentYearIncomeSubTotal +=
                    item.item_amounts_cols[indx].value;
                } else {
                  totalIncomeArr[indx] = 0;
                  //row subTotal

                  allSubTotal.value[key][i]["Page " + currentPage] =
                    curentYearIncomeSubTotal;
                  store.commit(
                    "projectBudgetModules/SET_YEARLY_INCOME_SUBTOTALS",
                    {
                      section: key,
                      lineItem: i,
                      currentYear: currentPage,
                      subTotal: curentYearIncomeSubTotal,
                    }
                  );

                  if (item.item_amounts_cols[indx].split_id == "Till now") {
                    let tillNow = 0;

                    let filterYearlyIncomeSubTotal = Object.keys(
                      getYearlyIncomeSubtotals.value
                    ).reduce((filtered, key) => {
                      const page = key.split("-")[2];
                      if (parseInt(page) <= currentPage) {
                        filtered[key] = getYearlyIncomeSubtotals.value[key];
                      }
                      return filtered;
                    }, {}); //remove all year above current year

                    for (const k in filterYearlyIncomeSubTotal) {
                      const parts = k.split("-");
                      let section = parts[0];
                      let lineItemIndex = parts[1];
                      // let year = parts[2];
                      if (i == lineItemIndex && section == key) {
                        tillNow += filterYearlyIncomeSubTotal[k];
                      }
                    }
                    item.item_amounts_cols[indx].value = tillNow;
                  }

                  if (item.item_amounts_cols[indx].split_id == "Total") {
                    let sum = 0;

                    for (const year in allSubTotal.value[key][i]) {
                      sum += allSubTotal.value[key][i][year];
                    }

                    let tr = $event?.target?.parentNode?.parentNode; //select table row

                    if (sum.toFixed(2) > item.item_amounts_cols[indx].value) {
                      tr?.classList.add("red-border");
                    }

                    //All Cost Total
                    allIncomeTotal += item.item_amounts_cols[indx].value;
                  }

                  if (item.item_amounts_cols[indx].split_id == "Balance") {
                    item.item_amounts_cols[indx].value =
                      item.item_amounts_cols[indx - 1].value -
                      item.item_amounts_cols[indx - 2].value;
                    //total                          - // tillNow
                  }
                }
              }
            }

            //calculate all internal cost
            if (isInternalCost.value.includes(item.structure)) {
              let curentYearInternalCostSubTotal = 0;
              let currentPage = "" + isCurrentPage.value;
              for (const indx in item.item_amounts_cols) {
                if (typeof item.item_amounts_cols[indx].split_id === "number") {
                  //column Subtotal
                  totalInternalCostArr[indx] +=
                    item.item_amounts_cols[indx].value;
                  curentYearInternalCostSubTotal +=
                    item.item_amounts_cols[indx].value;
                } else {
                  totalCostArr[indx] = 0;
                  //row subTotal
                  allSubTotal.value[key][i]["Page " + currentPage] =
                    curentYearInternalCostSubTotal;
                  store.commit(
                    "projectBudgetModules/SET_YEARLY_COST_SUBTOTALS",
                    {
                      section: key,
                      lineItem: i,
                      currentYear: currentPage,
                      subTotal: curentYearInternalCostSubTotal,
                    }
                  );

                  if (item.item_amounts_cols[indx].split_id == "Till now") {
                    let tillNow = 0;

                    let filterYearlyCostSubTotal = Object.keys(
                      getYearlyCostSubtotals.value
                    ).reduce((filtered, key) => {
                      const page = key.split("-")[2];
                      if (parseInt(page) <= currentPage) {
                        filtered[key] = getYearlyCostSubtotals.value[key];
                      }
                      return filtered;
                    }, {}); //remove all year above current year

                    for (const k in filterYearlyCostSubTotal) {
                      const parts = k.split("-");
                      let section = parts[0];
                      let lineItemIndex = parts[1];
                      // let year = parts[2];
                      if (i == lineItemIndex && section == key) {
                        tillNow += filterYearlyCostSubTotal[k];
                      }
                    }
                    item.item_amounts_cols[indx].value = tillNow;
                  }

                  if (item.item_amounts_cols[indx].split_id == "Total") {
                    let sum = 0;

                    for (const year in allSubTotal.value[key][i]) {
                      sum += allSubTotal.value[key][i][year];
                    }

                    let tr = $event?.target?.parentNode?.parentNode; //select table row

                    if (sum.toFixed(2) > item.item_amounts_cols[indx].value) {
                      tr?.classList.add("red-border");
                    }
                    //All Internal Cost Total
                    allInternalCostTotal += item.item_amounts_cols[indx].value;
                  }
                }
              }
            }
          }
        });

        //unallocatted calculate cost without internal cost

        let subTotalCost = 0;
        let subTotalIncome = 0;
        let subTotalInternalCost = 0;

        for (let i = 0; i <= columnLength - 1; i++) {
          subTotalCost += totalCostArr[i];
          subTotalIncome += totalIncomeArr[i];
          subTotalInternalCost += totalInternalCostArr[i];
        }
        // totalCostArr[12] = subTotalCost
        // // totalCostArr[13] = subTotalCost //Till Now value==> May reconsider

        // totalIncomeArr[12] = subTotalIncome
        // // totalIncomeArr[13] = subTotalIncome //Till Now value==> May reconsider

        // totalInternalCostArr[12] = subTotalInternalCost
        // // totalInternalCostArr[13] = subTotalCost //Till Now value==> May reconsider

        //Reassign calculated values to each sections
        dataArray.forEach((item, i) => {
          if (typeof item?.amount_per_unit == "string") {
            if (item?.amount_per_unit == "Income") {
              store.commit("projectBudgetModules/SET_ALL_INCOME_SUBTOTALS", {
                section: key,
                lineItem: i,
                currentYear: "" + isCurrentPage.value,
                subTotal: subTotalIncome,
              });

              let tillNow = 0;

              let filterAllIncomeSubTotal = Object.keys(
                getAllIncomeSubtotals.value
              ).reduce((filtered, key) => {
                const page = key.split("-")[2];
                if (parseInt(page) <= isCurrentPage.value) {
                  filtered[key] = getAllIncomeSubtotals.value[key];
                }
                return filtered;
              }, {}); //remove all year above current year

              for (const k in filterAllIncomeSubTotal) {
                const parts = k.split("-");
                let section = parts[0];
                let lineItemIndex = parts[1];
                // let year = parts[2];
                if (i == lineItemIndex && section == key) {
                  tillNow += filterAllIncomeSubTotal[k];
                }
              }
              totalIncomeArr[columnLength - 3] = tillNow;
              totalIncomeArr[columnLength - 2] = allIncomeTotal; //total column
              totalIncomeArr[columnLength - 1] =
                totalIncomeArr[columnLength - 2] -
                totalIncomeArr[columnLength - 3];
              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "income") {
                  item.item_amounts_cols[indx].value = isNaN(
                    totalIncomeArr[indx]
                  )
                    ? 0
                    : totalIncomeArr[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Cost") {
              store.commit("projectBudgetModules/SET_ALL_COST_SUBTOTALS", {
                section: key,
                lineItem: i,
                currentYear: "" + isCurrentPage.value,
                subTotal: subTotalCost,
              });
              store.commit(
                "projectBudgetModules/SET_ALL_INTERNAL_COST_SUBTOTALS",
                {
                  section: key,
                  lineItem: i,
                  currentYear: "" + isCurrentPage.value,
                  subTotal: subTotalInternalCost,
                }
              );

              let tillNow = 0;

              let filterAllCostSubTotal = Object.keys(
                getAllCostSubtotals.value
              ).reduce((filtered, key) => {
                const page = key.split("-")[2];
                if (parseInt(page) <= isCurrentPage.value) {
                  filtered[key] = getAllCostSubtotals.value[key];
                }
                return filtered;
              }, {}); //remove all year above current year

              for (const k in filterAllCostSubTotal) {
                const parts = k.split("-");
                let section = parts[0];
                let lineItemIndex = parts[1];
                // let year = parts[2];
                if (i == lineItemIndex && section == key) {
                  tillNow += filterAllCostSubTotal[k];
                }
              }

              totalCostArr[columnLength - 3] = tillNow;

              totalCostArr[columnLength - 2] = allCostTotal; //total column
              totalCostArr[columnLength - 1] =
                totalCostArr[columnLength - 2] - totalCostArr[columnLength - 3];

              tillNow = 0;

              let filterAllInternalCostSubTotal = Object.keys(
                getAllInternalCostSubtotals.value
              ).reduce((filtered, key) => {
                const page = key.split("-")[2];
                if (parseInt(page) <= isCurrentPage.value) {
                  filtered[key] = getAllInternalCostSubtotals.value[key];
                }
                return filtered;
              }, {}); //remove all year above current year

              for (const k in filterAllInternalCostSubTotal) {
                const parts = k.split("-");
                let section = parts[0];
                let lineItemIndex = parts[1];
                // let year = parts[2];
                if (i == lineItemIndex && section == key) {
                  tillNow += filterAllInternalCostSubTotal[k];
                }
              }
              totalInternalCostArr[columnLength - 3] = tillNow;

              totalInternalCostArr[columnLength - 2] = allInternalCostTotal; //total column
              totalInternalCostArr[columnLength - 1] =
                totalInternalCostArr[columnLength - 2] -
                totalInternalCostArr[columnLength - 3];

              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "cost") {
                  item.item_amounts_cols[indx].value = isNaN(totalCostArr[indx])
                    ? 0
                    : totalCostArr[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Margin") {
              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "margin") {
                  let difference = totalIncomeArr[indx] - totalCostArr[indx];
                  item.item_amounts_cols[indx].value = isNaN(difference)
                    ? 0
                    : difference;
                }
              }
            }

            if (item?.amount_per_unit == "Margin %") {
              for (const indx in item.item_amounts_cols) {
                if (
                  item.item_amounts_cols[indx].split_id === "margin_percent"
                ) {
                  item.item_amounts_cols[indx].value =
                    totalIncomeArr[indx] > 0
                      ? (
                          ((totalIncomeArr[indx] - totalCostArr[indx]) /
                            totalIncomeArr[indx]) *
                          100
                        ).toFixed(2)
                      : 0;
                  //handle negative numbers
                  if (item.item_amounts_cols[indx].value < 0) {
                    item.item_amounts_cols[indx].value = 0;
                  } else if (isNaN(item.item_amounts_cols[indx].value)) {
                    item.item_amounts_cols[indx].value = 0;
                  }
                }
              }
            }
          }
        });

        //Unallocatted Table Data

        for (const index in totalUnallocattedCost) {
          totalUnallocattedCost[index] += totalCostArr[index];
          totalUnallocattedIncome[index] += totalIncomeArr[index];
          totalUnallocattedInternalCost[index] += totalInternalCostArr[index];
          totalUnallocattedCostWithoutInternal[index] =
            totalUnallocattedCost[index] - totalUnallocattedInternalCost[index];
        }

        //Reassign calculated values to each unallocated
        unallocated_cost?.data?.forEach((item) => {
          if (typeof item?.amount_per_unit == "string") {
            if (item?.amount_per_unit == "Total Income") {
              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "total_income") {
                  item.item_amounts_cols[indx].value = isNaN(
                    totalUnallocattedIncome[indx]
                  )
                    ? 0
                    : totalUnallocattedIncome[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Total Cost") {
              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "total_costs") {
                  item.item_amounts_cols[indx].value = isNaN(
                    totalUnallocattedCost[indx]
                  )
                    ? 0
                    : totalUnallocattedCost[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Total Cost Internal") {
              for (const indx in item.item_amounts_cols) {
                if (
                  item.item_amounts_cols[indx].split_id ===
                  "total_costs_internal"
                ) {
                  item.item_amounts_cols[indx].value = isNaN(
                    totalUnallocattedInternalCost[indx]
                  )
                    ? 0
                    : totalUnallocattedInternalCost[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Total Cost (without Internal)") {
              for (const indx in item.item_amounts_cols) {
                if (
                  item.item_amounts_cols[indx].split_id ===
                  "total_costs_no_internal"
                ) {
                  item.item_amounts_cols[indx].value = isNaN(
                    totalUnallocattedCostWithoutInternal[indx]
                  )
                    ? 0
                    : totalUnallocattedCostWithoutInternal[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Total Profit Margin 1") {
              for (const indx in item.item_amounts_cols) {
                if (
                  item.item_amounts_cols[indx].split_id === "profit_margin_one"
                ) {
                  let margin =
                    totalUnallocattedIncome[indx] - totalUnallocattedCost[indx];
                  let margin_percentage =
                    totalUnallocattedIncome[indx] > 0
                      ? (
                          (margin / totalUnallocattedIncome[indx]) *
                          100
                        ).toFixed(2)
                      : 0;
                  if (margin_percentage < 0) {
                    margin_percentage = 0;
                  } else if (isNaN(margin_percentage)) {
                    margin_percentage = 0;
                  }
                  item.item_amounts_cols[indx].value = isNaN(margin)
                    ? `0.0(${margin_percentage}%)`
                    : `${margin.toFixed(2)}(${margin_percentage}%)`;

                  //calculate cashflow
                  if (indx == 0) {
                    totalUnallocattedCashflow[indx] = margin;
                  } else {
                    totalUnallocattedCashflow[indx] =
                      totalUnallocattedCashflow[indx - 1] + margin;
                  }
                  //Calculatiog Total profit margin 2 here since it depends on profit margin 2, that is, profit margin 2 = profit margin 1 + backstopping
                  totalUnallocattedProfitMargin2[indx] =
                    margin + backstopping[indx];
                }
              }
            }

            if (item?.amount_per_unit == "Total Profit Margin 2") {
              for (const indx in item.item_amounts_cols) {
                if (
                  item.item_amounts_cols[indx].split_id === "profit_margin_two"
                ) {
                  let margin =
                    totalUnallocattedIncome[indx] -
                    totalUnallocattedCost[indx] +
                    backstopping[indx];
                  let margin_percentage =
                    totalUnallocattedIncome[indx] > 0
                      ? (
                          (margin / totalUnallocattedIncome[indx]) *
                          100
                        ).toFixed(2)
                      : 0;
                  if (margin_percentage < 0) {
                    //find out how to calculate profit margin 2 percentage
                    margin_percentage = 0;
                  } else if (isNaN(margin_percentage)) {
                    margin_percentage = 0;
                  }

                  item.item_amounts_cols[indx].value = isNaN(margin)
                    ? `0.0(${margin_percentage}%)`
                    : `${margin.toFixed(2)}(${margin_percentage}%)`;
                }
              }
            }

            if (item?.amount_per_unit == "Cashflow") {
              for (const indx in item.item_amounts_cols) {
                if (item.item_amounts_cols[indx].split_id === "cashflow") {
                  item.item_amounts_cols[indx].value =
                    totalUnallocattedCashflow[indx];
                }
              }
            }
          }
        });
      }
    };

    //  get the value by row , colummn and specific index the meet
    function handleCellInput(
      $event,
      tableIndex,
      rowIndex,
      cellIndex,
      value,
      split_id
    ) {
      let tr = $event.target.parentNode.parentNode; //select table row
      if (tr.classList.contains("red-border")) {
        tr.classList.remove("red-border");
      }

      rowData.value[tableIndex] = rowData.value[tableIndex] || [];
      rowData.value[tableIndex][rowIndex] =
        rowData.value[tableIndex][rowIndex] || [];
      rowData.value[tableIndex][rowIndex][cellIndex] = value;
      changesArray.value.push({ split_id: split_id, value: value });
      if (
        filteredRows.value[tableIndex]?.data &&
        Array.isArray(filteredRows.value[tableIndex].data[rowIndex]?.cells)
      ) {
        filteredRows.value[tableIndex].data[rowIndex].cells[cellIndex] = value;
      } else {
        filteredRows.value[tableIndex].data[rowIndex].cells = Array(
          columns.value.length
        ).fill("");
        filteredRows.value[tableIndex].data[rowIndex].cells[cellIndex] = value;
      }

      const regex = /[^0-9.]/g;
      let resultString = "" + value.replace(regex, "");

      if (appLang.value === "de") {
        resultString = "" + value.replace(/[^0-9,]/g, "").replace(",", ".");
      }

      let extractedNumber = isNaN(parseFloat(resultString))
        ? 0
        : parseFloat(resultString);

      filteredRows.value[tableIndex].data[rowIndex].item_amounts_cols[
        cellIndex
      ].value = extractedNumber;

      calculate(filteredRows.value, $event);
      updateRows(filteredRows.value);
    }
    updateRows(filteredRows.value);
    watch(filteredRows, (newVal) => {
      updateRows(newVal);
    });

    function germanCurrency(curr) {
      return currencyFormatter(curr);
    }

    function updateRows(newMonthlySplit) {
      if (typeof newMonthlySplit === "object") {
        const tableKeys = Object.keys(newMonthlySplit);
        rows.value = tableKeys.map((key) => {
          const table = newMonthlySplit[key];
          if (table && table.data && Array.isArray(table.data)) {
            return Array(table.data.length)
              .fill()
              .map(() => Array(months.length).fill(""));
          } else {
            return [];
          }
        });
      } else {
        rows.value = [];
      }
    }
    // update the rows as the user visit the cells
    updateRows(filteredRows.value);
    const getMonthlySplit = async () => {
      try {
        const response = await Api().get(
          `/projects/project/budget-split/${isProjectID.value}/?page=${isCurrentPage.value}`
        );

        if (response.data && response.data.status === "successful") {
          monthlySplit.value = response.data.data;
          curentPage.value = response.data.current_page;
          totalPages.value = response.data.total_pages;
          store.commit(
            "projectBudgetModules/SET_TOTALPAGES_TO_STATE",
            totalPages.value
          );
          // store.commit(
          //   "projectBudgetModules/SET_CURRENTPAGE_TO_STATE",
          //   curentPage.value
          // );
          for (const section in monthlySplit.value) {
            if (Array.isArray(monthlySplit.value[section].header_row)) {
              const filteredMonthsRow = monthlySplit.value[
                section
              ].header_row.filter((row) => {
                const includesMilestone = ![
                  "Till Now",
                  "Total",
                  "Balance",
                ].includes(row);
                return includesMilestone;
              });
              const plainDataArray = JSON.parse(
                JSON.stringify(monthlySplit.value[section].data)
              );

              filteredRows.value[section] = {
                ...monthlySplit.value[section],
                months_row: filteredMonthsRow,
                data: plainDataArray,
              };
            }
          }
          calculate(filteredRows.value);
        }
      } catch (error) {
        // Handle the error as needed
      }
    };

    const extractAllTillNow = (monthlySplitAll) => {
      for (const key in monthlySplitAll) {
        for (const ke in monthlySplitAll[key]) {
          if (ke !== "unallocated_cost") {
            let dataArray = monthlySplitAll[key][ke].data;
            dataArray.forEach((item, index) => {
              if (typeof item.amount_per_unit == "number") {
                let section = ke;
                let lineItem = index;
                let indx = item.item_amounts_cols.findIndex(
                  (col) => col.split_id == "Till now"
                );

                if (indx !== -1) {
                  let result = item.item_amounts_cols[indx];

                  allTillNow.value[section] = allTillNow.value[section] || {};
                  allTillNow.value[section][lineItem] =
                    allTillNow.value[section][lineItem] || {};
                  allTillNow.value[section][lineItem][key] = result.value;
                }

                for (const indx in item.item_amounts_cols) {
                  if (
                    typeof item.item_amounts_cols[indx].split_id === "number"
                  ) {
                    allSubTotal.value[section] =
                      allSubTotal.value[section] || {};
                    allSubTotal.value[section][lineItem] =
                      allSubTotal.value[section][lineItem] || {};

                    if (allSubTotal.value[section][lineItem][key]) {
                      allSubTotal.value[section][lineItem][key] +=
                        item.item_amounts_cols[indx].value;
                    } else {
                      allSubTotal.value[section][lineItem][key] = 0;
                      allSubTotal.value[section][lineItem][key] +=
                        item.item_amounts_cols[indx].value;
                    }
                  }
                }
              }
            });
          }
        }
      }
    };

    const getMonthlySplitAll = async () => {
      try {
        const response = await Api().get(
          `/projects/project/get-budget-split/${isProjectID.value}/`
        );

        if (response.data && response.data.status === "successful") {
          monthlySplitAll.value = response.data.data;
          extractAllTillNow(monthlySplitAll.value);
        }
      } catch (error) {
        // Handle the error as needed
      }
    };

    // edit split
    const getLatestUpdates = function (items_splits) {
      const latestUpdatesMap = new Map();
      // Reverse the iteration order to pick the latest value first
      for (let i = items_splits.length - 1; i >= 0; i--) {
        const item = items_splits[i];
        const { split_id, value } = item;
        // If the value is a valid number or contains non-numeric characters, proceed with the update
        if (!latestUpdatesMap.has(split_id)) {
          const numericValue = !isNaN(value)
            ? parseFloat(value)
            : parseFloat(value.replace(/[^0-9.]/g, "")); // Remove non-numeric characters, except decimal point
          latestUpdatesMap.set(split_id, { split_id, value: numericValue });
        }
      }

      const latestUpdates = Array.from(latestUpdatesMap.values());

      return latestUpdates;
    };

    const LatestPayload = getLatestUpdates(changesArray.value);
    const updateSplit = () => {
      const payload = {
        items_splits: getLatestUpdates(changesArray.value),
      };
      Api()
        .post(
          `/projects/project/update-budget-split/${isProjectID.value}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "successful") {
            alertType.value = "success";
            alertTitle.value = response.data.detail;
            alertState.value = !alertState.value;
            getMonthlySplit();
            store.commit(
              "projectBudgetModules/SET_SAVE_EDIT_SPLIT_TO_STATE",
              false
            );
            store.commit(
              "projectBudgetModules/SET_SAVE_EDIT_SPLIT_MODE_TO_STATE",
              false
            );
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.status === "error"
          ) {
            alertType.value = "error";
            alertTitle.value = error.response.data.detail;
            store.commit(
              "projectBudgetModules/SET_SAVE_EDIT_SPLIT_TO_STATE",
              false
            );
            store.commit(
              "projectBudgetModules/SET_SAVE_EDIT_SPLIT_MODE_TO_STATE",
              false
            );
            alertState.value = !alertState.value;
          }
        });
    };

    watch(
      () => click.isEditSplit,
      () => {
        if (isEditSplit.value) {
          updateSplit();
        }
        getMonthlySplit();
      }
    );

    onMounted(() => {
      getMonthlySplit();
      store.commit("projectBudgetModules/SET_CURRENTPAGE_TO_STATE", 1);
      store.commit("projectBudgetModules/SET_SAVE_EDIT_SPLIT_TO_STATE", false);
      store.commit(
        "projectBudgetModules/SET_SAVE_EDIT_SPLIT_MODE_TO_STATE",
        false
      );
      getMonthlySplitAll();
    });

    return {
      LatestPayload,
      convertToFormattedString,
      updateSplit,
      convertCurrencyFormat,
      totalPages,
      curentPage,
      Headers,
      formatAsPercentage,
      isSplitEditMode,
      filteredRows,
      currentYear,
      columns,
      rows,
      rowlength,
      computedRows,
      getCell,
      handleCellInput,
      getMonthlySplit,
      monthlySplit,
      yearRange,
      alertType,
      alertTitle,
      alertState,
      isCurrentProject,
      getTranslationKey,
      germanCurrency,
      appLang,
      handleMarginAndPercentTranslation,
    };
  },
});
</script>

<style scoped>
.red-border {
  border: 2px solid #fdc6c2;
}
table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
}

input {
  outline-width: 0;
  background-color: transparent;
}

.w50 {
  width: 200px;
}

.w50 > td {
  border: none;
}

.header_label > tr > td {
  border: none;
}

.position {
  margin-left: 5px;
}

.border_side {
  width: 1px;
  background-color: #d0d5dd;
  height: 65px;
}

.labels_menu > td {
  border: none;
  text-align: center;
}

.item_border_side {
  width: 1px;
  background-color: #d0d5dd;
  height: 30px;
}

.unit {
  width: 100px;
}

.item_labels > td {
  border: none;
}

.highlighted-row {
  background-color: #f2f4f7;
  pointer-events: none;
}

.border-row {
  background-color: #f2f4f7;
}

.highlighted-row-yellow {
  background-color: #fefbe8;
}

.position {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-container {
  width: 100%;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
}

.cant-edit {
  pointer-events: none;
}

.none {
  background-color: transparent;

  width: 60px;
}

.unalocated {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 200px;
  margin-left: -230px;
}

/* width */
::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.table-container {
  overflow: auto;
  height: 54.5vh;
  width: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

/* .table-container::-webkit-scrollbar {
  width: 0;
  display: none;
} */
</style>
