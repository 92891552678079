import store from "@/store";

export interface Permission {
  module__name: string;
  access_level: string;
  // Define other properties of the Permission type
}

export function extractModuleNames(permissions: Permission[]): string[] {
  const moduleNames: string[] = [];

  if (!permissions || !Array.isArray(permissions)) {
    return moduleNames; // Return empty array if permissions is not iterable
  }

  for (const permission of permissions) {
    if (permission.module__name === "Human Resource") {
      moduleNames.push(permission.module__name);
    }
  }

  return moduleNames;
}

export const permissions: Permission[] = store.getters["auth/permissions"];
export const moduleNames: string[] = extractModuleNames(permissions);
