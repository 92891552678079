import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import railIconLeft from "@/assets/icons/RailIconLeft.vue";
import railIconRight from "@/assets/icons/RailIconRight.vue";
import logoIcon from "@/assets/icons/LogoIcon.vue";
import homeIcon from "@/assets/icons/HomeIcon.vue";
import financeIcon from "@/assets/icons/FinanceIcon.vue";
import projectsIcon from "@/assets/icons/ProjectsIcon.vue";
import usersIcon from "@/assets/icons/UsersIcon.vue";
import dashboardIcon from "@/assets/icons/DashboardIcon.vue";
import trashIcon from "@/assets/icons/TrashIcon.vue";
import searchIcon from "@/assets/icons/SearchIcon.vue";
import bellIcon from "@/assets/icons/BellIcon.vue";
import editIcon from "@/assets/icons/EditIcon.vue";
import calendarIcon from "@/assets/icons/CalendarIcon.vue";
import errorIcon from "@/assets/icons/ErrorInfoIcon.vue";
import expandIcon from "@/assets/icons/ExpandIcon.vue";
import englishIcon from "@/assets/icons/EnglishIcon.vue";
import germanIcon from "@/assets/icons/GermanIcon.vue";
import settingsIcon from "@/assets/icons/SettingsIcon.vue";
import menuIcon from "@/assets/icons/MenuIcon.vue";
import barChartIcon from "@/assets/icons/BarChartIcon.vue";
import expandLess from "@/assets/icons/ExpandLess.vue";
import arrowBackIcon from "@/assets/icons/ArrowBack.vue";
import dotVertical from "@/assets/icons/DotVertical.vue";
import hazardIcon from "@/assets/icons/HazardIcon.vue";
import successIcon from "@/assets/icons/SuccessIcon.vue";
import greenClose from "@/assets/icons/GreenClose.vue";
import infoIcon from "@/assets/icons/InfoIcon.vue";
import redClose from "@/assets/icons/RedClose.vue";
import minusIcon from "@/assets/icons/MinusIcon.vue";
import checkIcon from "@/assets/icons/CheckIcon.vue";
import closeIcon from "@/assets/icons/CloseIcon.vue";
import uploadIcon from "@/assets/icons/UploadIcon.vue";
import userIcon from "@/assets/icons/UserIcon.vue";
import notificationCloseIcon from "@/assets/icons/notificationClose.vue";
import markIcon from "@/assets/icons/MarkIcon.vue";
import viewIcon from "@/assets/icons/ViewIcon.vue";
import eyeIcon from "@/assets/icons/EyeIcon.vue";
import linkIcon from "@/assets/icons/LinkIcon.vue";
import chevronLeft from "@/assets/icons/ChevronLeft.vue";
import chevronsLeft from "@/assets/icons/ChevronsLeft.vue";
import chevronRight from "@/assets/icons/ChevronRight.vue";
import chevronsRight from "@/assets/icons/ChevronsRight.vue";
import info from "@/assets/icons/Info.vue";
import lockedIcon from "@/assets/icons/LockedIcon.vue";
import unLockedIcon from "@/assets/icons/UnLockedIcon.vue";
import grayCheck from "@/assets/icons/GrayCheck.vue";


const customSvgNameToComponent: any = {
  railIconLeft,
  railIconRight,
  markIcon,
  viewIcon,
  logoIcon,
  closeIcon,
  greenClose,
  homeIcon,
  userIcon,
  financeIcon,
  projectsIcon,
  usersIcon,
  dashboardIcon,
  trashIcon,
  searchIcon,
  bellIcon,
  editIcon,
  calendarIcon,
  errorIcon,
  expandIcon,
  englishIcon,
  germanIcon,
  minusIcon,
  settingsIcon,
  menuIcon,
  barChartIcon,
  arrowBackIcon,
  expandLess,
  dotVertical,
  hazardIcon,
  successIcon,
  redClose,
  checkIcon,
  infoIcon,
  uploadIcon,
  notificationCloseIcon,
  eyeIcon,
  linkIcon,
  chevronLeft,
  chevronsLeft,
  chevronRight,
  chevronsRight,
  info,
  lockedIcon,
  unLockedIcon,
  grayCheck,

};

const customSVGs: IconSet = {
  component: (props: IconProps) =>
    h(customSvgNameToComponent[props.icon as string]),
};

export { customSVGs /* aliases */ };
