<template>
  <div>
    <app-header
      :name="$t('foreignEmployees.employees')"
      :show-arrow-back="false"
    />
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchForeignEmployees"
          endpoint="/projects/foreign-office/search-foreign-employees/"
          employmentType="Foreign Office Staff"
        />
      </v-col>
      <v-col cols="4" class="">
        <app-select-field
          class="px-2"
          :options="countryOptions"
          :label="$t('foreignEmployees.country')"
          placeholder="All"
          v-model="country"
        />
      </v-col>
      <v-col cols="3" class="">
        <app-select-field-object
          class="px-2"
          :options="isStatusTitle"
          :label="$t('employees.status')"
          :isTranslation="true"
          v-model="status"
        />
      </v-col>
    </v-row>
    <div class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="isTableHeaders"
        :items="isAllEmployees"
        :showCheck="canEdit"
        :isEmployees="true"
        :hideMenu="!canEdit"
        @welcome-email="sendEmail({ $event, data: 'welcome-email' })"
        @edit-event="showActivateDeactivateDialog"
        @reset-event="showResetPasswordDialog"
        @delete-event="deleteEmployee"
        @delete-multiple-event="showDeleteMultipleEmployeesDialog"
      >
        <template #title>
          <h3 class="H500">{{ $t("foreignEmployees.allEmployees") }}</h3>
        </template>
        <template #actions>
          <app-primary-button
            :buttonLabel="$t('foreignEmployees.addEmployee')"
            @click="addEmployee"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
    <delete-employee ref="deleteEmployee"></delete-employee>
    <delete-multiple-employees
      ref="deleteMultipleEmployees"
    ></delete-multiple-employees>
    <activate-deactivate-employee
      ref="activateDeactivateEmployee"
    ></activate-deactivate-employee>
    <reset-password ref="resetPassword"></reset-password>
  </div>
</template>
<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { useI18n } from "vue-i18n";
import { mapState, useStore } from "vuex";
import { getTransString } from "@/services/helpers";
import countries from "@/services/country_api";
import {
  searchForeignEmployees,
  getAllForeignEmployees,
} from "@/services/endpoints";
import DeleteEmployee from "../employees/dialogs/DeleteEmployee.vue";
import DeleteMultipleEmployees from "../employees/dialogs/DeleteMultipleEmployees.vue";
import ActivateDeactivateEmployee from "../employees/dialogs/ActivateDeactivateEmployee.vue";
import ResetPassword from "../employees/dialogs/ResetPassword.vue";
export default {
  name: "EmployeeLayout",
  components: {
    DeleteEmployee,
    DeleteMultipleEmployees,
    ActivateDeactivateEmployee,
    ResetPassword,
    AppHeader,
    Paginator,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const addAllPlaceholder = (data) => {
      return ["All", ...data];
    };
    const handleFilterSet = (type, args, removeFilter, setFilter) => {
      if (
        t(getTransString(args[0])) === t(getTransString("All")) ||
        args[0] === ""
      ) {
        store.commit(removeFilter, type);
        // getAllEmployees(1);
      } else {
        store.commit(setFilter, [type, args[0]]);
      }
    };

    const getAllEmployees = (page) => {
      Api()
        .get(`${getAllForeignEmployees}${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data?.map((data) => {
              return {
                name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
                title: data.title,
                country: data.country_of_birth,
                email: data.employee__email,
                user_key: data.employee__user_key,
                is_active: data.employee__is_active,
                profile_picture: data.profile_picture,
              };
            });

            store.commit("hrResources/SET_EMPLOYEES_TO_STATE", data);

            store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    };

    return {
      addAllPlaceholder,
      handleFilterSet,
      getAllEmployees,
    };
  },
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",

      isDelete: false,
      isActivate: false,
      currentPages: 1,
      isReset: false,
      headers: null,
      tableData: [],
      userKey: null,
      userStatus: null,
      research: false,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",

      status: "Active",
      country: "",
    };
  },
  watch: {
    country(...args) {
      this.handleFilterSet(
        "country",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
    status(...args) {
      // const filter = args === 'Active'? true : false
      this.handleFilterSet(
        "is_active",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
  },
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Foreign Employee"
      );
      return findModules?.length !== 0;
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllEmployees"];
    },
    isTableHeaders() {
      // return this.$store.getters["hrResources/isTableHeaders"];
      return ["Name", "Title", "Country", "Email"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    countryOptions() {
      return this.getCountryByName();
    },
    isStatusTitle() {
      return [
        { id: "Active", value: "Active" },
        { id: "Inactive", value: "Inactive" },
      ];
    },
    isFilterBys() {
      return this.$store.getters["hrConfigurations/isFilterBys"];
    },

    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    updateCurrentPages() {
      this.currentPages = 1;
    },
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },

    addEmployee() {
      this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);
      this.$store.commit("hrResources/SET_USER_KEY_TO_STATE", null);
      this.$router.push({ name: "addForeignEmployee" });
    },
    getFiltersData(fields) {
      return this.isTableHeaders?.reduce((acc, curr) => {
        if (fields?.includes(curr)) return [...acc, { id: curr, value: curr }];
        else return acc;
      }, []);
    },

    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllEmployees(page);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    searchForeignEmployees(search_query) {
      const payload = {
        search_query: search_query,
      };

      Api()
        .post(`${searchForeignEmployees}${this.currentPage}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_EMPLOYEES_TO_STATE",
              response.data.data
            );
            this.$store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },

    deleteEmployee(event) {
      this.$refs.deleteEmployee.userKey = event?.user_key;
      this.$refs.deleteEmployee.showDialog();
    },
    showActivateDeactivateDialog(event) {
      this.$refs.activateDeactivateEmployee.is_active = event?.is_active;
      this.$refs.activateDeactivateEmployee.userKey = event?.user_key;
      this.$refs.activateDeactivateEmployee.showDialog();
    },
    showDeleteMultipleEmployeesDialog(event) {
      this.$refs.deleteMultipleEmployees.userKey = event;
      this.$refs.deleteMultipleEmployees.showDialog();
    },

    showResetPasswordDialog(event) {
      this.$refs.resetPassword.userKey = event?.user_key;
      this.$refs.resetPassword.showDialog();
    },

    loadMore() {
      let page = this.currentPages;
      this.getAllEmployees(page);
    },
    sendEmail(data) {
      this.userKey = data?.$event?.user_key;
      const payload = {
        user_key: data?.$event?.user_key,
      };
      Api()
        .post("/auth/send-employee-credentials-email/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.detail;
            this.getAllEmployees(this.currentPage);
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error?.response?.data?.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    checkIfInactiveEmployeesDisplayed() {
      const employees = this.isAllEmployees.find(
        (elt) => elt?.is_active === false
      );
      if (Object.keys(employees || {}).length) {
        this.$store.commit("hrResources/SET_EMPLOYEES_TO_STATE", []);
      }
    },
  },
  created() {
    // this.$store.commit("hrResources/SET_EMPLOYEES_TO_STATE", []);
    this.$store.commit("hrResources/RESET_FILTER_BYS_FROM_STATE", []);
    this.checkIfInactiveEmployeesDisplayed();
    this.getAllEmployees(this.currentPage);
  },
};
</script>

<style scoped>
label {
  color: #344054;
}
</style>
