<template>
  <div>
    <app-header :name="$t('projects.projects')" :show-arrow-back="false" />
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchProjects"
          @filter-event="filterProjects"
          :callback="true"
        />
      </v-col>
      <!-- <v-col cols="2" class="">
        <app-select-field-object
          class="px-2"
          placeholder="All"
          :options="phaseOptions"
          :label="$t('projects.phase')"
          v-model="phase"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field-object
          class="px-2"
          placeholder="All"
          :options="statusOptions"
          :label="$t('projects.status')"
          v-model="status"
        />
      </v-col> -->
    </v-row>
    <div
      v-if="isAppLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="isTableHeaders"
        :items="isAllProjects"
      
        :hideMenu="true"
        
        @edit-event="routeToEditProject({ $event, data: 'edit' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        @delete-multiple-event="
          showDeleteMultipleDialog({ $event, data: 'multiple' })
        "
      >
        <template #title>
          <h3 class="H500">{{ $t("projects.allProjects") }}</h3>
        </template>
        <template #footer>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </template>
      </app-table>
    </div>

    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteProject"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="deleteMultipleDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hidemultipleDelete()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteMultipleProjects"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { mapState } from "vuex";
import {
  getTransString,
  handleFilterSet,
  addUnderscores,
formatDate,
} from "@/services/helpers";
import { getAllLocalExperts, searchExpertProject } from "@/services/endpoints";

export default defineComponent({
  name: "Project List",
  components: {
    AppHeader,
    Paginator,
  },
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      tableData: [],
      status: "",
      selectedPhase: "",
      phase: "",
      projectID: null,
      currentPages: 1,
      isAppLoading: false,
    };
  },

  watch: {
    phase(...args) {
      handleFilterSet(
        "phase",
        args,
        "projectModules/REMOVE_FILTER_BYS_FROM_STATE",
        "projectModules/SET_FILTER_BYS_TO_STATE"
      );
    },
    status(...args) {
      handleFilterSet(
        "status",
        args,
        "projectModules/REMOVE_FILTER_BYS_FROM_STATE",
        "projectModules/SET_FILTER_BYS_TO_STATE"
      );
    },
  },
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Project"
      );
      return findModules?.length === 0 ? false : true;
    },
    isAllProjects() {
      return this.$store.getters["projectModules/isAllProjects"];
    },
    isTableHeaders() {
      return this.$store.getters["projectModules/isTableHeaders"];
    },
    phaseOptions() {
      const modifiedData = this.$store.getters["projectModules/isPhaseOptions"];
      return modifiedData?.convertArrayToObject();
    },
    statusOptions() {
      const modifiedData =
        this.$store.getters["projectModules/isStatusOptions"];
      return modifiedData?.convertArrayToObject();
    },
    currentPage() {
      return this.$store.getters["projectModules/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["projectModules/isTotalPages"];
    },
    filtersBy() {
      return this.$store.getters["projectModules/isFilterBys"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    addProject() {
      this.$store.commit("projectModules/SET_USER_KEY_TO_STATE", null);
      this.$store.commit("projectModules/SET_CURRENT_PROJECT_TO_STATE", {});
      this.$router.push({ name: "addProject" });
    },
    routeToEditProject(data) {
      const dataObject = addUnderscores(data?.$event);
      this.$store.commit(
        "projectModules/SET_PROJECT_TITLE_STATE",
        dataObject?.project_title
      );
      this.$store.commit("projectModules/SET_PROJECT_ID_STATE", dataObject?.id);
      this.$router.push({ name: "editProject" });
    },
    showDialog(data) {
      this.projectID = data?.$event.id;
      if (data.data === "delete") {
        this.subTitle = "projects.areYouSure";
        this.title = "projects.deleteProject";
        this.isDelete = !this.isDelete;
        this.cancelLabel = "employees.noKeepIt";
        this.$refs.dialog.showDialog = true;
      }
    },
    showDeleteMultipleDialog(data) {
      if (data.data === "multiple") {
        this.projectID = data?.$event;
        this.subTitle = "projects.areYouSure";
        this.title = "projects.deleteProjects";
        this.cancelLabel = "employees.noKeepIt";
        this.$refs.deleteMultipleDialog.showDialog = true;
      }
    },

    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },
    hidemultipleDelete() {
      this.$refs.deleteMultipleDialog.showDialog = false;
      setTimeout(() => {
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },

    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllProjects(page);
    },
    filterProjects(data) {
      const payloadKeys = {
        phase: "project_phase",
        status: "project_status",
      };

      const payload = data.reduce((prev, curr) => {
        const currKey = Object.keys(curr)[0];
        return [...prev, { [payloadKeys[currKey]]: curr[currKey] }];
      }, []);

      Api()
        .post(`/projects/project/search-projects/1/`, { filters: payload })
        .then((response) => {
          if (response.data.status === "success") {
            this.setResponseData(response);
          }
        });
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getAllProjects(page) {
      this.isAppLoading = true;
      Api()
        .get(`${getAllLocalExperts}${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.isAppLoading = false;
            this.setResponseData(response);
          }
        })
        .catch((error) => {
          if (error) {
            this.isAppLoading = false;
          }
        });
    },

    deleteProject() {
      Api()
        .put(`/projects/project/archive-project/${this.projectID}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllProjects(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.isDelete = !this.isDelete;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteMultipleProjects() {
      const payload = {
        project_ids: this.projectID,
      };

      Api()
        .post("/projects/project/archive-projects/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllProjects(this.currentPage);
            this.$refs.deleteMultipleDialog.showDialog = false;
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
            }, 1000);
          }
          if (response.data.status === "error") {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    searchProjects(search_query) {
      const payload = {
        search_query: search_query,
      };

      Api()
        .post(`${searchExpertProject}${this.currentPage}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.setResponseData(response);
          }
        });
    },
    setResponseData(response) {
      const data = response.data.data;
      const transformedData = data.map(curr => {
        
        const startDate = formatDate(curr.project__project_start_date);
        const endDate = formatDate(curr.project__project_end_date);
        const managerName = curr.project__project_manager__first_name ? `${curr.project__project_manager__first_name} ${curr.project__project_manager__last_name} ` : 'N/A';
        return {
          id: curr.project_id,
            'project title': curr.project__project_title,
            'project manager': managerName,
            'start date': startDate,
            'end date': endDate,
            status: curr.project__project_status,
            contract_type: curr.contract_type,
        } 
      })
      this.$store.commit("projectModules/SET_PROJECTS_TO_STATE",  transformedData);
      this.$store.commit(
        "projectModules/SET_CURRENT_PAGE_TO_STATE",
        response.data.current_page
      );
      this.$store.commit(
        "projectModules/SET_TOTAL_PAGES_TO_STATE",
        response.data.total_data
      );
    },
    loadMore() {
      const page = this.currentPages;
      this.getAllProjects(page);
    },
  },
  created() {
    this.getAllProjects(this.currentPage);
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.commit("projectModules/SET_SELECTED_PHASE_TO_STATE", "");
    this.$store.commit("projectModules/SET_SELECTED_STATUS_TO_STATE", "");
    this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", false);
    this.$store.commit("projectModules/SET_PROJECT_ID_STATE", "");
    this.$store.commit("projectModules/SET_CONTRACT_DETAILS_TO_STATE", {});
  },
});
</script>
