import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";
import auth from "./modules/auth";
import hrConfigurations from "./modules/hrConfigurations";
import translation from "./modules/translation";
import hrResources from "./modules/hrResources";
import commonResources from "./modules/commonResources";
import expertModules from "./modules/expertModules";
import projectModules from "./modules/projectModules";
import partnerModules from "./modules/partnerModules";
import projectCalculationsModule from "./modules/projectCalculationsModule";
import projectBudgetModules from "./modules/projectBudgetModules";
import accessRoleModule from "./modules/accessRoleModule";
import projectConfigurations from "./modules/projectConfigurations";
import contractModules from "./modules/contractModules";
import foreignOfficeModule from "./modules/foreignOfficeModule";
import projectLocalBudgetModule from "./modules/projectLocalBudgetModule";
import pmLocalModules from "./modules/pmLocalModules";
import projectOverview from "./modules/projectOverview";
import chequeModules from "./modules/chequeModules";
import pmLocalRequestFund from "./modules/pmLocalRequestFund";
import expenseModules from "./modules/expenseModules";
import advancePaymentModules from "./modules/advancePaymentModules";
import flightModule from "./modules/flightModule";
import projectHours from "@/store/modules/projectHours";


export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    commonResources,
    projectModules,
    expertModules,
    partnerModules,
    hrResources,
    auth,
    hrConfigurations,
    translation,
    projectCalculationsModule,
    projectBudgetModules,
    accessRoleModule,
    projectConfigurations,
    contractModules,
    foreignOfficeModule,
    projectLocalBudgetModule,
    pmLocalModules,
    projectOverview,
    chequeModules,
    pmLocalRequestFund,
    expenseModules,
    advancePaymentModules,
    flightModule,
    projectHours
  },
  plugins: [createPersistedState()],
});
