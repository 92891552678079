<template>
  <section class="p-1">
    <div class="tableCover">
      <!-- Start coding here -->
      <div
        class="relative bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg"
      >
        <div
          class="flex flex-col items-center justify-between p-4 px-6 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
          :class="{ hide: hideTableHeader }"
        >
          <div class="flex items-center justify-between w-full py-2">
            <slot v-if="selectedCount <= 0" name="title"></slot>

            <div
              v-if="showCheck && selectedCount > 0"
              class="flex items-center justify-between w-full"
            >
              <h3 class="H500">{{ selectedCount }} Selected</h3>
              <v-btn
                v-if="selectedCount > 0"
                variant="default"
                size="large"
                class="bg-[#D92D20] text-white !normal-case rounded-lg font-['inter'] !text-[0.875rem] !font-normal"
                @click="emitMultiple()"
              >
                <v-icon icon="mdi-trash-can-outline" />
                {{ $t(`${getDeleteMultipleText()}`) }}
              </v-btn>
            </div>

            <div v-if="isFilter" class="flex justify-center w-full py-2 pl-16">
              <slot name="filter"></slot>
            </div>

            <div
              class="flex items-center w-full space-x-3 md:w-auto"
              v-if="selectedCount <= 0"
            >
              <div class="flex flex-col">
                <div v-if="isRequestedFunds || isApproveFunds">
                  <span class="mr-1 status-approved"
                    >&#x2022; {{ $t("requestFundsTab.approved") }}</span
                  >
                  <span class="mr-1 status-pending"
                    >&#x2022; {{ $t("requestFundsTab.pending") }}</span
                  >
                  <span class="mr-1 status-disapproved"
                    >&#x2022; {{ $t("requestFundsTab.disapproved") }}</span
                  >
                </div>
              </div>
              <div class="flex flex-col text-[14px]">
                <div
                  v-if="
                    isAdvancePaymentRequest || isHosptalityPackage || isFlight
                  "
                >
                  <span class="mr-1 status-pending"
                    >&#x2022;
                    {{ $t("advancePaymentRequest.pendingAction") }}</span
                  >

                  <span class="mr-1 status-approved"
                    >&#x2022; {{ $t("advancePaymentRequest.paid") }}</span
                  >
                </div>
              </div>
              <slot name="actions"></slot>
            </div>
          </div>
        </div>

        <div>
          <table
            class="overflow-visible text-sm text-left text-gray-500 dark:text-gray-400"
            aria-describedby=""
          >
            <thead class="bg-white">
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  scope="col"
                  class="px-6 py-3 text-sm !text-[#344054] !font-medium !font-['inter']"
                  :class="{
                    'text-center': centerAlignedHeaders.includes(
                      header.toLowerCase()
                    ),
                    'text-right': rightAlignedHeaders.includes(
                      header.toLowerCase()
                    ),
                    'text-left': ![
                      ...centerAlignedHeaders,
                      ...rightAlignedHeaders,
                    ].includes(header.toLowerCase()),
                  }"
                >
                  <div class="flex" v-if="showCheck">
                    <div v-if="index === 0">
                      <input
                        type="checkbox"
                        :checked="headerCheck"
                        class="grid content-center mt-1 mr-6"
                        @click="toggleSelectAll()"
                      />
                    </div>
                    {{ $t(yearHeaders(header)) }}
                  </div>
                  <div v-else :class="[{ capitalize: !isTranslation }]">
                    {{ isTranslation ? $t(yearHeaders(header)) : header }}
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
                :class="[
                  'font-[inter] !font-normal',
                  {
                    'selected-row': item.selected,
                    '!font-bold':
                      item?.title === 'Carryover from Previous Month' ||
                      item?.title === 'Balance',
                    'hover:cursor-pointer': showCursor,
                  },
                ]"
              >
                <td
                  v-for="(header, index) in headers"
                  :key="index"
                  :class="[{ disabled: item.is_active === false }]"
                  @click="
                    showEditPage ? goToUpdatePage(item) : '',
                      emitViewFile(item),
                      emitPayIn(item),
                      emitPaidInBy(item),
                      emitExpertOverview(item)
                  "
                  class=""
                >
                  <div class="container">
                    <template v-if="index === 0">
                      <input
                        type="checkbox"
                        v-if="showCheck"
                        class="mr-6"
                        v-model="item.selected"
                        @click.stop="checkboxClick()"
                      />
                    </template>
                    <div
                      :class="[
                        getItemColorClass(item, index),
                        applyContractStatus(item, index),
                        applyChequeColor(item, index),
                        getRequestedFundsColorClass(item, index),

                        'flex justify-start items-center',
                      ]"
                      v-if="checkProfilePicture(header)"
                    >
                      <div>
                        <div class="relative" v-if="item.profile_picture">
                          <img
                            :src="item.profile_picture"
                            alt=""
                            :class="[
                              'item.profile_picture object-cover',
                              !item.profile_picture ? 'hidden' : 'block',
                              'w-[40px] h-[40px] mr-3 rounded-full',
                            ]"
                            @load="profileImgLoading = false"
                          />
                          <div
                            class="spinner-container"
                            v-if="profileImgLoading"
                          >
                            <div class="spinner"></div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="mr-3 avatar"
                          :style="getAvatarStyle()"
                        >
                          {{ getNameInitials(item.name) }}
                        </div>
                      </div>
                      {{ item[header.toLowerCase()] || "-" }}
                    </div>
                    <span
                      :class="[
                        getItemColorClass(item, index),
                        applyContractStatus(item, index),
                        applyChequeColor(item, index),
                        getRequestedFundsColorClass(item, index),
                       
                      ]"
                      v-else-if="
                        header.toLowerCase() !== 'working time' &&
                        header.toLowerCase() !== 'countries' &&
                        header.toLowerCase() !== 'position' &&
                        header.toLowerCase() !== 'company' &&
                        header.toLowerCase() !== 'team' &&
                        header.toLowerCase() !== 'person on site'
                      "
                    >
                      <div
                        v-if="
                          (isRequestedFunds || isApproveFunds) &&
                          header.toLowerCase() === 'approved amount' &&
                          'comments' in item &&
                          item['comments'].length > 0
                        "
                        class="icon-container"
                      >
                        <v-icon icon="custom:info" class=""></v-icon>
                        <div class="info-message w-[400px]">
                          <div
                            class="mt-2"
                            v-for="comment in item['comments']"
                            :key="comment"
                          >
                            <h1
                              class="font-sans text-sm font-semibold leading-5 text-gray-900"
                            >
                              {{ comment?.author }}
                            </h1>
                            <p
                              class="font-sans text-sm font-normal leading-5 text-gray-500"
                            >
                              {{ comment?.comment }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <span v-else></span>

                      <div v-if="isApproveFunds">
                        <div v-if="header.toLowerCase() === 'status'">
                          <div
                            v-if="item['status'] === 'Pending'"
                            class="tooltip-container"
                          >
                            <button>{{ item["pending_to"] }}</button>
                            <div class="tooltip-bubble">
                              {{ $t("approveFundTab.sendReminder") }}
                              <div class="tooltip-arrow"></div>
                            </div>
                          </div>
                          <div v-else>
                            {{ item["pending_to"] }}
                          </div>
                        </div>
                        <div v-else>
                          {{ item[header.toLowerCase()] || "-" }}
                        </div>
                      </div>

                      <div v-else-if="isRequestedFunds">
                        {{
                          header.toLowerCase() === "status"
                            ? item["pending_to"]
                            : item[header.toLowerCase()] || "-"
                        }}
                      </div>
                      <div
                        v-else
                        :class="[
                          {
                            'text-right px-6':
                              item[header.toLowerCase()] &&
                              String(item[header.toLowerCase()])?.isDecimal(),
                          },
                        ]"
                      >
                        {{
                          header.toLowerCase() === "double taxation status"
                            ? item[header.toLowerCase()]
                            : item[header.toLowerCase()] || "-"
                        }}
                      </div>
                    </span>
                  </div>
                  <template v-if="header.toLowerCase() === 'working time'">
                    <div
                      v-if="
                        item[header.toLowerCase()] &&
                        item[header.toLowerCase()].length > 0
                      "
                    >
                      <div
                        class="time_header"
                        @mouseenter="showItem(item)"
                        @mouseleave="hideItem()"
                      >
                        <p class="day_time">
                          {{
                            $t(
                              getTranslatedStringKey(
                                item[header.toLowerCase()][0].day_of_week || "-"
                              )
                            )
                          }}:
                          {{
                            getFormattedTime(
                              item[header.toLowerCase()][0].start_time
                            ) || "-"
                          }}
                          -
                          {{
                            getFormattedTime(
                              item[header.toLowerCase()][0].end_time
                            ) || "-"
                          }}
                        </p>
                        <p class="count">
                          +{{ item[header.toLowerCase()].length - 1 }}
                        </p>
                      </div>
                      <div v-if="show === item" class="modal">
                        <div
                          v-for="schedule in item[header.toLowerCase()]"
                          :key="schedule.id"
                        >
                          <p class="py-1">
                            {{
                              $t(
                                getTranslatedStringKey(
                                  schedule.day_of_week || "-"
                                )
                              )
                            }}:
                            {{ getFormattedTime(schedule.start_time || "-") }} -
                            {{ getFormattedTime(schedule.end_time) || "-" }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p>-</p>
                    </div>
                  </template>
                  <template v-if="header.toLowerCase() === 'countries'">
                    <div
                      v-if="
                        item[header.toLowerCase()] &&
                        item[header.toLowerCase()].length > 0
                      "
                    >
                      <div class="time_header">
                        <p class="">
                          {{ item[header.toLowerCase()][0] }}
                        </p>
                        <p
                          @mouseenter="showItem(item)"
                          @mouseleave="hideItem()"
                          class="count"
                          v-if="item[header.toLowerCase()].length - 1 !== 0"
                        >
                          +{{ item[header.toLowerCase()].length - 1 }}
                        </p>
                      </div>
                      <div v-if="show === item" class="countries-modal w-35">
                        <div
                          v-for="(country, index) in item[header.toLowerCase()]"
                          :key="index"
                        >
                          <p
                            v-if="item[header.toLowerCase()][0] !== country"
                            class="py-1"
                          >
                            {{ country }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p>-</p>
                    </div>
                  </template>
                  <template v-if="header.toLowerCase() === 'person on site'">
                    <div
                      v-if="
                        item[header.toLowerCase()] &&
                        item[header.toLowerCase()].length > 0
                      "
                    >
                      <div class="time_header">
                        <p class="">
                          {{ item[header.toLowerCase()][0].text }}
                        </p>
                        <p
                          @mouseenter="showItem(item)"
                          @mouseleave="hideItem()"
                          class="count"
                          v-if="item[header.toLowerCase()].length - 1 !== 0"
                        >
                          +{{ item[header.toLowerCase()].length - 1 }}
                        </p>
                      </div>

                      <div v-if="show === item" class="countries-modal w-35">
                        <div
                          v-for="(person, index) in item[header.toLowerCase()]"
                          :key="index"
                        >
                          <p
                            v-if="item[header.toLowerCase()][0] !== person"
                            class="py-1"
                          >
                            {{ person.text }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div v-else>
                      <p>-</p>
                    </div>
                  </template>

                  <template v-if="header.toLowerCase() === 'position'">
                    <div>
                      <p>
                        {{ item.position?.title }}
                      </p>
                    </div>
                  </template>
                  <template v-if="header.toLowerCase() === 'company'">
                    <div>
                      <p>
                        {{ item.company?.title }}
                      </p>
                    </div>
                  </template>
                  <template v-if="header.toLowerCase() === 'team'">
                    <div>
                      <p>
                        {{ item.team?.title }}
                      </p>
                    </div>
                  </template>
                  <template v-if="header.toLowerCase() === 'person on site'">
                    <div>
                      <p>
                        <!-- {{ item['person on site'] }} -->
                      </p>
                    </div>
                  </template>
                  <template
                    v-if="
                      header.toLowerCase() === 'currency' &&
                      item?.is_transaction_currency
                    "
                  >
                    <div class="flex">
                      <div class="bg-[#F6FEF9] rounded-xl flex px-4 ml-9 mt-n5">
                        <v-badge
                          dot
                          offset-y="8"
                          size="2"
                          color="#12B76A"
                        ></v-badge>

                        <p class="text-[#027A48] pl-2">Transaction Currency</p>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="header.toLowerCase() === 'double taxation status'"
                  >
                    <app-toggle-button
                      @update:modelValue="
                        $emit('table-toggle', { country: item.country, $event })
                      "
                      :modelValue="changeCountry[item.country]"
                    ></app-toggle-button>
                  </template>
                </td>

                <td
                  v-if="isCalender"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg item">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("leaves.viewDetails") }}
                      </v-list-item>
                      <v-list-item
                        v-if="!item.is_approver_approved"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("apptable.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- default -->
                <td
                  v-if="
                    !showCheck &&
                    !isCalender &&
                    !hideMenu &&
                    !isContracts &&
                    !isTravel &&
                    !isMissions &&
                    !isDoubleTaxation &&
                    !isForeignEstablishment &&
                    !isCheques &&
                    !isHosptalityPackage
                  "
                  :class="[
                    'flex items-center justify-end mt-3 mr-6',
                    dropDisabled ? 'pointer-events-none' : '',
                  ]"
                >
                  <v-menu
                    location="start"
                    width="130"
                    v-if="item.currency !== 'EUR'"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-show="
                          Object.keys(item || {})?.includes('is_editable')
                            ? item?.is_editable
                            : true
                        "
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg hover:bg-gray-200">
                      <v-list-item class="" @click="emitEdit(item)"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item class="" @click="emitDelete(item)"
                        >{{ $t("apptable.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- projects -->
                <td
                  v-if="showCheck && isProjects"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg item">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("projects.edit") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("projects.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- bank details -->
                <td
                  v-if="showCheck && isBankDetails"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg item">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("projects.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- employees -->
                <td
                  v-if="showCheck && isEmployees && !hideMenu"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <!-- <v-list-item
                      v-if="item.has_received_email && checkMail(item.official_email,item.communication_email,item.email)"
                      class="my-n1 hover:bg-gray-200"
                      @click="welComeEmail(item)"
                      >{{ $t("employees.sendEmail") }}</v-list-item
                    >
                     -->
                      <v-list-item
                        :disabled="
                          !checkMail(
                            item.official_email,
                            item.communication_email,
                            item.email
                          )
                        "
                        class="my-n1 hover:bg-gray-200"
                        @click="welComeEmail(item)"
                        >{{ $t("employees.sendEmail") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitReset(item)"
                        >{{ $t("resetpassword.resetPassword") }}
                      </v-list-item>
                      <v-list-item
                        v-if="item.is_active"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("employees.deactivateAccount") }}
                      </v-list-item>
                      <v-list-item
                        v-else
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("employees.activate") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("employees.deleteUser") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- contracts -->
                <td
                  v-if="isContracts"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("contracts.editContract") }}
                      </v-list-item>
                      <v-list-item
                        v-if="item?.approvalStatus !== 'Approved'"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("contracts.deleteContract") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitPreviewContract(item)"
                        v-if="item?.approvalStatus !== ''"
                        >{{ $t("contracts.previewContract") }}
                      </v-list-item>
                      <v-list-item
                        v-if="item?.approvalStatus === 'Approved'"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitAddendum(item)"
                        >{{ $t("contracts.contractAdden") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- missions -->
                <td
                  v-if="isMissions"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("contracts.editMission") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("contracts.deleteMission") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="viewMissionInvoice(item)"
                        >{{ $t("contracts.missionInvoice") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDeployment(item)"
                        >{{ $t("contracts.deploy") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- foreignEstablishment -->
                <td
                  v-if="isForeignEstablishment"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        v-if="isCurrentTab === 1"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("projectBudget.edit") }}
                      </v-list-item>
                      <v-list-item
                        v-if="isCurrentTab === 1"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("projectConfiguration.delete") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitApprove(item)"
                        v-if="isCurrentTab !== 1"
                        >{{
                          item["approval status"] === "No"
                            ? $t("projects.approveEst")
                            : $t("projects.rejectEst")
                        }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <!-- Travels -->
                <td
                  v-if="isTravel"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("apptable.delete") }}
                      </v-list-item>
                      <v-list-item class="my-n1 hover:bg-gray-200"
                        >{{ $t("accessrole.view") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- isCheques -->
                <td
                  v-if="isCheques"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start" v-if="item.status === 'Pay in'">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        >{{ $t("apptable.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- isRequestedFunds -->
                <td
                  v-if="isRequestedFunds"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitViewRequestedFund(item)"
                        >{{ $t("accessrole.view") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!-- isApproveFunds -->
                <td
                  v-if="isApproveFunds"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitViewRequestedFund(item)"
                        >{{ $t("accessrole.view") }}
                      </v-list-item>
                      <v-list-item
                        v-if="item['status'] === 'Pending'"
                        class="my-n1 hover:bg-gray-200"
                        @click="emitSendReminder(item)"
                        >{{ $t("approveFundTab.sendReminder") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <!--  isHosptalityPackage-->
                <td
                  v-if="isHosptalityPackage"
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start" width="130">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg hover:bg-gray-200">
                      <v-list-item class="" @click="emitEdit(item)"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item class="" @click="emitDelete(item)"
                        >{{ $t("apptable.delete") }}
                      </v-list-item>
                      <div class="mt-2 divide-y">
                        <div></div>

                        <v-list-item
                          class="my-n1 hover:bg-gray-200"
                          @click="viewSettlement(item)"
                          >{{ $t("hospitalityPackage.viewSettlement") }}
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-menu>
                  <!-- <v-menu location="start" v-if="item.status">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      icon="custom:dotVertical"
                      v-bind="props"
                      class="mt-2"
                    ></v-icon>
                  </template>
                  <v-list class="rounded-lg items">
                    <v-list-item
                      class="my-n1 hover:bg-gray-200"
                      @click="emitEdit(item)"
                      >{{ $t("apptable.edit") }}</v-list-item
                    >
                    <v-list-item
                      class="my-n1 hover:bg-gray-200"
                      @click="emitDelete(item)"
                      >{{ $t("apptable.delete") }}</v-list-item
                    >
                    <div class="mt-2 divide-y">
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="viewSettlement(item)"
                        >{{ $t("hospitalityPackage.viewSettlement") }}</v-list-item
                      >
                    </div>

                  </v-list>
                </v-menu> -->
                </td>

                <!--  General purpose slot -->
                <td
                  v-if="
                    showCheck &&
                    !hideMenu &&
                    isGeneralMenu &&
                    extraCheckListMenu(item)
                  "
                  class="flex items-center justify-end mt-3 mr-6"
                >
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="custom:dotVertical"
                        v-bind="props"
                        class="mt-2"
                      ></v-icon>
                    </template>
                    <v-list class="rounded-lg items">
                      <v-list-item
                        :disabled="
                          !checkMail(
                            item.official_email,
                            item.communication_email,
                            item.email
                          )
                        "
                        class="my-n1 hover:bg-gray-200"
                        @click="welComeEmail(item)"
                        v-if="listMenus.includes('send-email')"
                        >{{ $t("employees.sendEmail") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitReset(item)"
                        v-if="listMenus.includes('reset-password')"
                        >{{ $t("resetpassword.resetPassword") }}
                      </v-list-item>
                      <v-list-item
                        class=""
                        @click="emitEdit(item)"
                        v-if="listMenus.includes('view')"
                        >{{ $t("apptable.view") }}
                      </v-list-item>
                      <v-list-item
                        class="my-n1 hover:bg-gray-200"
                        @click="emitDelete(item)"
                        v-if="listMenus.includes('delete')"
                        >{{ $t("generalConfig.delete") }}
                      </v-list-item>
                      <v-list-item
                        v-if="
                          item.is_active && listMenus.includes('deactivate')
                        "
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("partners.deactivate") }}
                      </v-list-item>
                      <v-list-item
                        v-else-if="
                          !item.is_active && listMenus.includes('activate')
                        "
                        class="my-n1 hover:bg-gray-200"
                        @click="emitEdit(item)"
                        >{{ $t("employees.activate") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="isTotalExpenses && items.length > 0">
              <tr
                v-for="(item, index) in totals"
                :key="index"
                class="!h-[40px]"
              >
                <td
                  v-for="(header, index) in headers"
                  :key="index"
                  class="font-bold"
                >
                  {{
                    item[header.toLowerCase()] === "Total Expenses" ||
                    item[header.toLowerCase()] === "Funds Transferred" ||
                    item[header.toLowerCase()] === "Liquidity"
                      ? $t(
                          getTranslatedStringKey(
                            item[header.toLowerCase()] || ""
                          )
                        )
                      : item[header.toLowerCase()] || ""
                  }}
                </td>
              </tr>
            </tfoot>
          </table>
          <div
            class="border-t-2 min-h-[40px] max-h-[200px] flex-cols items-center"
            v-if="showTotal && items?.length > 0"
          >
            <div class="flex bg-[#F9FAFB]">
              <div
                class="bg-[#F9FAFB] font-bold text-[14px] px-6 py-4 flex justify-between w-[62%]"
              >
                <p>Total</p>
                <p>{{ currencyInterim }} {{ totalInterim }}</p>
                <p>{{ currencyReduction }} {{ reductionTotal }}</p>
              </div>
              <div></div>
            </div>
            <div class="w-[70%] text-[14px] pb-[5px]">
              <!-- <div class="">
                {{ $t(reductionTotalLabel) }}
                <span class="font-bold"
                  >{{ currencyReduction }} {{ reductionTotal }}</span
                >
              </div> -->
              <div class="flex justify-between py-2">
                <div class="flex justify-between px-3">
                  {{ $t(totalAdvanceLabel) }}
                  <span class="px-4 font-bold"
                    >{{ currencyReduction }} {{ totalAdvance }}</span
                  >
                </div>
                <div class="flex justify-between px-3">
                  {{ $t(advanceTotalLabel) }}
                  <span class="px-4 font-bold text-red-600"
                    >{{ currencyReduction }} {{ advanceTotal }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          v-if="items?.length > 0"
          class="flex items-start justify-end p-4 pr-5 space-y-3 md:flex-row md:items-center md:space-y-0 mb-n5"
          :class="{ hide: hideTableFooter }"
          aria-label="Table navigation"
        >
          <slot name="footer"></slot>
        </nav>
        <div class="empty_state" v-else>
          <EmptyState />
          <h3 class="H500">
            {{ $t("employees.no") }} {{ pagename }} {{ $t("employees.found") }}
          </h3>
          <p class="P300">
            {{ $t("employees.youWillSee") }} {{ message }}
            {{ $t("employees.hereWhen") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import {defineComponent} from "vue";
import EmptyState from '../../assets/icons/EmptyState.vue'
import {getTransString, getInitial} from "@/services/helpers";
import {useI18n} from 'vue-i18n';

// import Paginator from "@/components/shared/PaginationComponent.vue";

export default defineComponent({
  name: "App table",
  components: {EmptyState,},
  props: {
    items: {
      type: Array,
    },
    totals: {
      type: Array,
    },
    pagename: {
      type: String,
    },
    message: {
      type: String,
    },
    headers: {
      type: Array,
      default: null,
    },
    showCheck: {
      type: Boolean,
      default: false,
    },
    isCalender: {
      type: Boolean,
      default: false,
    },
    isDoubleTaxation: {
      type: Boolean,
      default: false,
    },
    isEmployees: {
      type: Boolean,
      default: false,
    },
    isProjects: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    dropDisabled: {
      type: Boolean,
      default: false,
    },
    isBankDetails: {
      type: Boolean,
      default: false
    },
    isPartners: {
      type: Boolean,
      default: false,
    },
    isContracts: {
      type: Boolean,
      default: false
    },
    isMissions: {
      type: Boolean,
      default: false
    },
    isForeignEstablishment: {
      type: Boolean,
      default: false
    },
    isTravel: {
      type: Boolean,
      default: false
    },
    isCheques: {
      type: Boolean,
      default: false
    },
    isRequestedFunds: {
      type: Boolean,
      default: false
    },
    isApproveFunds: {
      type: Boolean,
      default: false
    },
    isFlight: {
      type: Boolean,
      default: false
    },
    showEditPage: {
      type: Boolean,
      default: false,
    },
    callBack: {
      type: Function
    },
    totalPages: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    updateCallback: {
      type: Boolean,
      default: false
    },
    showCursor: {
      type: Boolean,
      default: true
    },
    plannedTotal: {
      type: String,
      default: ""
    },
    plannedTotalLabel: {
      type: String,
      default: ""
    },
    reductionTotal: {
      type: String,
      default: ""
    },
    reductionTotalLabel: {
      type: String,
      default: ""
    },
    advanceTotal: {
      type: String,
      default: ""
    },
    advanceTotalLabel: {
      type: String,
      default: ""
    },
    totalBudget: {
      type: String,
      default: ""
    },
    totalBudgetLabel: {
      type: String,
      default: ""
    },
    totalInterim: {
      type: String,
      default: ""
    },
    totalInterimLabel: {
      type: String,
      default: ""
    },
    totalAdvance: {
      type: String,
      default: ""
    },
    totalAdvanceLabel: {
      type: String,
      default: ""
    },
    currency: {
      type: String,
      default: ""
    },
    currencyInterim: {
      type: String,
      default: ""
    },
    currencyBudget: {
      type: String,
      default: ""
    },
    currencyReduction: {
      type: String,
      default: ""
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    hideTableHeader: {
      type: Boolean,
      default: false
    },
    hideTableFooter: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    changeCountry: {
      type: Object,
      default: () => {
      }
    },
    isTotalExpenses: {
      type: Boolean,
      default: false
    },
    isTranslation: {
      type: Boolean,
      default: true
    },
    deleteMultiplyButton: {
      type: Boolean,
      default: true
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    isHosptalityPackage: {
      type: Boolean,
      default: false
    },
    isAdvancePaymentRequest: {
      type: Boolean,
      default: false
    },
    listMenus: {
      type: Array,
      default: () => ['activate', 'deactivate', 'delete']
    },
    isGeneralMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: this.page,
      selectedCount: 0,
      multipleSelected: null,
      headerCheck: false,
      show: null,
      profileImgLoading: true,
      threeDotsDisabled: false,
      checkSelected: false
    };
  },

  watch: {
    items: {
      handler: function (newItems) {

        if (typeof newItems === "object") {
          this.selectedCount = newItems?.filter((item) => item.selected).length;
          this.multipleSelected = newItems?.filter((item) => item.selected)?.map((item) => item.user_key ? item.user_key : item.id);
        }
      },
      deep: true,
    },
  },
  computed: {

    getItemColorClass() {
      return (item, index) => {
        if (this.headers[index] === this.transText('Status')) {
          if (item.status === this.transText('Pending')) {
            return 'status-pending';
          } else if (item.status === this.transText('Approved')) {
            return 'status-approved';
          } else if (item.status === this.transText('Declined')) {
            return 'status-rejected';
          } else if (item.status === this.transText('Draft')) {
            return 'status-draft';
          }else if (item.status_action === 'Pending') {
            return 'pending';
          }else if (item.status_action ==='Paid') {
            return 'paid';
          }
        }
        return '';
      };
    },
    getRequestedFundsColorClass() {
      return (item, index) => {
        if (this.headers[index] === this.transText('Status')) {
          if (item.pending_to === this.transText('Controller Signature') || item.pending_to === this.transText('Accountant') || item.pending_to === this.transText('Payment') || item.pending_to === this.transText('PM Signature') || item.pending_to === this.transText('CEO Signature')) {
            return 'status-pending';
          } else if (item.pending_to === this.transText('Paid')) {
            return 'status-approved-amt';
          } else if (item.status === this.transText('Disapproved')) {
            return 'status-rejected';
          }
        }
        return '';
      };
    },
    applyContractStatus() {
      return (item, index) => {
        if (this.headers[index] === 'Signature Status') {
          if (item['signature status'] === this.transText('Signed')) {
            return 'status-approved';
          } else if (item['signature status'] === this.transText('Not signed')) {
            return 'status-not-signed';
          } else if (item['signature status'] === this.transText('Draft')) {
            return 'status-draft';
          }
        }
        return '';
      };
    },
    applyChequeColor() {
      return (item, index) => {
        if (this.headers[index] === this.transText('Status')) {
          if (item['status'] === this.transText('Paid')) {
            return 'status_paid'
          } else if (item['status'] === this.transText('Pay in')) {
            return 'status_pay_in'
          }
        }
        return '';
      }
    },

    currentPath() {
      return this.$route.path
    },
    isUserAuthDetails() {
      return this.$store.getters["auth/isUserDetails"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    rightAlignedHeaders() {
      return [
        'requested amount',
        'reduction',
        'approved amount',
        'planned amount',
        'advance payment reduction',
        'total amount of the offer',
        'amount of the margin',
        'margin percentage',
        'requested'
      ]
    },
    centerAlignedHeaders() {
      return [
        "sr. no."
      ]
    }
  },
  methods: {
    showItem(item) {
      this.show = item;
    },
    hideItem() {
      this.show = null;
    },
    loadMore() {
      this.callBack()
    },
    getNameInitials(name) {
      return getInitial(name);
    },
    getFormattedTime(time) {
      const options = {hour: 'numeric', minute: 'numeric', hour12: false};
      const formattedTime = new Date(`2000-01-01T${time}`)?.toLocaleTimeString([], options);
      return formattedTime?.replace(/\s/g, '')?.toLowerCase();
    },
    transText(text) {
      const {t} = useI18n();
      return t(getTransString(text));
    },
    emitEdit(data) {
      this.$emit("edit-event", data);
    },
    emitPayIn(data) {
      this.$emit("pay-in-event", data);
    },
    emitPaidInBy(data) {
      this.$emit("paid-in-by", data);
    },
    emitDeleteAllCheques(data) {
      this.$emit("delete-all-cheques-event", data);
    },
    emitViewFile(data) {
      this.$emit("view-file", data);
    },
    emitPreviewContract(data) {
      this.$emit("preview-contract", data)
    },
    emitDeployment(data) {
      this.$emit("deployment-agreement", data)
    },
    emitApprove(data) {
      this.$emit("approve-event", data)
    },
    emitAddendum(data) {
      this.$emit("create-addendum", data)
    },
    emitReset(data) {
      this.$emit("reset-event", data);
    },
    welComeEmail(data) {
      this.$emit("welcome-email", data);
    },
    emitDelete(data) {
      this.$emit("delete-event", data);
    },
    viewMissionInvoice(data) {
      this.$emit("view-mission-invoice", data);
    },
    emitExpertOverview(data) {
      this.$emit("expert-overview", data);
    },
    emitMultiple() {
      this.$emit("delete-multiple-event", this.multipleSelected);
      this.items?.forEach((item) => {
        item.selected = false;
      });
      this.selectedCount = 0;
      this.selectAll = false;
      this.headerCheck = false;
    },
    emitDropdown(data) {
      const payload = {
        data: data,
      };
      this.$emit("dropdown-event", payload);
    },
    emitViewRequestedFund(data) {
      this.$emit("view-requested-fund-event", data);
    },
    emitSendReminder(data) {
      this.$emit("send-reminder-event", data);
    },
    viewSettlement(item) {
      this.$emit("view-settlement", item);
    },
    checkboxClick(event) {
      event?.preventDefault();
    },
    getTranslatedStringKey(field) {
      return getTransString(field)
    },
    getAvatarStyle() {
      // Define the background style for the avatar
      return {
        backgroundColor: '#f2f2f2', // Set the desired background color
        color: '#333', // Set the desired text color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: 'bold',
        width: '40px', // Set the desired width
        height: '40px', // Set the desired height
        borderRadius: '50%',
        marginRight: '10px' // Add margin-right value to separate avatar from details
      };
    },

    checkMail(official_email, communication_email, email) {
      return this.checkNullOrEmpty(official_email) || this.checkNullOrEmpty(communication_email) || this.checkNullOrEmpty(email)
    },
    checkNullOrEmpty(email) {
      return email !== null && email !== ''
    },
    toggleSelectAll() {
      if (!this.selectAll) {
        this.items?.forEach((item) => {
          item.selected = true;
          this.selectedCount++;
        });
      } else {
        this.items?.forEach((item) => {
          item.selected = false;
        });
        this.selectedCount = 0;
      }
      this.selectAll = !this.selectAll;
      this.headerCheck = !this.headerCheck;
    },
    getCurrentUpdatePage() {
      const pages = {
        "/experts": "editExpert",
        "/foreign-office": "editForeignOffice",
        "/foreign-employees": "editForeignEmployee",
        "/projects": "editProject",
        "/home": "editPmLocal",
        "/employees": "editEmployee",
        "/partners": "editPartner",
        "/profile": "viewAdvancePayments"
      }

      return Object.keys(pages).includes(this.currentPath) ? pages[this.currentPath] : ''
    },
    goToUpdatePage(item) {

      const transformedObj = Object.keys(item).reduce((acc, curr) => {
        const transformedKey = curr.replace(/\s+/g, '_');
        return {...acc, [transformedKey]: item[curr]}
      }, {});

      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);

      if (this.updateCallback) {
        this.$emit("proceed-to-update", item)
      } else {
        const employeeName = transformedObj?.name;
        const projectTitle = transformedObj?.project_title ? transformedObj?.project_title : ''
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
        this.$store.commit('hrResources/SET_USER_KEY_TO_STATE', item.user_key)
        this.$store.commit('hrResources/SET_EMPLOYEE_NAME_STATE', employeeName)
        this.$store.commit('projectModules/SET_PROJECT_TITLE_STATE', projectTitle)
        this.$store.commit('projectModules/SET_PROJECT_ID_STATE', item.id)
        this.$store.commit('foreignOfficeModule/SET_OFFICE_ID_STATE', item.id)
        this.$store.commit("foreignOfficeModule/SET_OFFICE_TITLE_STATE", transformedObj.name_of_office);
        this.$store.commit('pmLocalModules/SET_PM_LOCAL_ID_STATE', item.id)
        this.$store.commit("pmLocalModules/SET_PM_LOCAL_TITLE_STATE", transformedObj.project_title);
        this.$store.commit('hrResources/SET_EDIT_EMPLOYEE_STATE', true)
        this.$store.commit(
          "advancePaymentModules/SET_CURRENT_ADVANCE_PAYMENT_ID_TO_STATE",
          item.id
        );
      }

      if (item) {
        this.$store.commit("hrResources/RESET_FILTER_BYS_FROM_STATE", []);
        this.$router.push({name: this.getCurrentUpdatePage()})
      }
    },
    getDeleteMultipleText() {
      const texts = {
        "/employees": "employees.deleteEmployees",
        "/foreign-employees": "employees.deleteEmployees",
        "/projects": "projects.deleteMultiple",
        "/partners": "partners.deletePartners",
        "/experts": "experts.expertsDelete"
      }
      return texts[this.currentPath];
    },
    checkProfilePicture(header) {
      return ((this.currentPath === '/employees' || this.currentPath === '/experts' || this.currentPath === '/foreign-employees') && header === 'Name')
    },
    yearHeaders(header) {

      if (header === "Email") {
        return this.getTranslatedStringKey("Email Address")
      } else if (parseInt(header)) {
        return header
      } else {
        return this.getTranslatedStringKey(header)
      }
    },
    //   general purpose list menu check
    extraCheckListMenu(item) {
      if (Object.keys(item || {}).includes("is editable")) {
        return item["is editable"] === "Yes"
      }

      return true
    }
  }
});
</script>
<style scoped>
.tableCover {
  width: fit-content;
  min-width: 100% !important;
  max-width: fit-content !important;
  /* border: 2px solid green; */
}

table {
  color: #344054;
  min-width: 100%;
  max-width: fit-content !important;
  /* border: 2px solid red; */
}

tr > th {
  background-color: #f2f4f7;
}

tbody tr:nth-of-type(even) {
  background-color: #f9fafb;
}

.status-pending {
  width: 30px;
}

.app-menu {
  display: inline-flex;
}

td {
  padding-left: 24px;
}

tr {
  padding: 16px 24px;
  height: 56px;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
}

.pending {
  color: #b54708;
  text-align: center;
  padding: 2px 8px;
  height: 22px;
  background: #fffcf5;
  border-radius: 16px;
}
.paid {
  color: #027a48;
  text-align: center;
  padding: 2px 8px;
  height: 22px;
  background: #ecfdf3;
  border-radius: 16px;
}

.status-pending {
  color: #b54708;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #fffcf5;
  border-radius: 16px;
}
.status-rejected,
.status-disapproved {
  color: #c01048;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
}

.status-draft {
  color: #3538cd;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #eef4ff;
  border-radius: 16px;
}

.status-approved {
  color: #027a48;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #ecfdf3;
  border-radius: 16px;
}
.status-approved-amt {
  color: #027a48;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #ecfdf3;
  border-radius: 16px;
}

.status-created {
  color: #b54708;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #fffaeb;
  border-radius: 16px;
}

.status-not-signed {
  color: #c01048;
  width: 60%;
  text-align: center;
  padding: 2px 8px;

  width: 93px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
}

.status_pay_in {
  color: #c01048;
}

.status_paid {
  color: #027a48;
}
.status-approved-amt {
  color: #027a48 !important;
  width: 50%;
  text-align: center;
  padding: 2px 8px;

  width: 83px;
  height: 22px;
  background: #ecfdf3;
  border-radius: 16px;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 30px;
  padding-top: 0;
  cursor: pointer;
  padding-bottom: 0;

  height: 30px;
  background-color: #ffff;
  border-radius: 8px;
}

.selector {
  width: 20px;
  height: 20px;
  background: #f4eae9;
  border: 1px solid #ae6a65;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
  border: 1px solid #ae6a65;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected-row {
  background: #fbf7f6 !important;
}

.tablecard {
  border: 2px solid #eaecf0;
}

.empty_state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding: 20px;

  flex-direction: column;
  min-height: 400px;
}

.time_header {
  display: flex;
  align-items: center;
}

.time_header > p {
  margin: 5px;
  border-radius: 8px;
}

.count {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  background: #f2f4f7;
  border-radius: 16px;
}

.day_time {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  background: #f9fafb;
  border-radius: 16px;
}

.modal,
.countries-modal {
  position: absolute;
  z-index: 80;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}

.modal {
  padding: 20px;
}

.countries-modal {
  left: auto;
  padding: 10px;
}

nav {
  z-index: 7;
}

.disabled {
  color: #a8adb6 !important;
}

.spinner {
  position: absolute;
  top: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  font-size: 16px;
  border: 3px dotted #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-container {
  width: 20px;
  height: 20px;
}

.hide {
  display: none;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 5px;
  display: none;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 10px;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.info-message {
  display: none;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%) translateY(-30%);
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
}

.icon-container:hover .info-message {
  display: block;
}

.icon-container .info-message:active {
  display: block;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-bubble {
  visibility: hidden;
  background-color: #fff;
  color: #555;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px; /* Use half of the width to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-bubble::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip-bubble::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
}

.tooltip-container:hover .tooltip-bubble {
  visibility: visible;
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    border-top-color: #fff;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    border-top-color: #000;
  }
}
</style>
