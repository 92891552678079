import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllEmployeesNonPaginated,
  getEmployeeSignature,
  getOpenInvoices,
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    tabId: 0,
    allEmployees: [],
    allExperts: [],
    allCostCenters: [],
    AllLanguages: [],
    allContracts: [],
    allBudgetItems: [],
    allCountries: [],
    settlementStatusOptions: [
      {
        text: "Pending PM Signature",
        value: "Pending PM Signature",
      },
      {
        text: "Pending Team Lead Signature",
        value: "Pending Team Lead Signature",
      },
      {
        text: "Pending Requester Signature",
        value: "Pending Requester Signature",
      },
      {
        text: "Pending CEO Signature",
        value: "Pending CEO Signature",
      },
      {
        text: "Pending Controller Action",
        value: "Pending Controller Action",
      },
      {
        text: "Ready For Payment",
        value: "Ready For Payment",
      },
    ],
    signatureImageUrl: "",
    signatureDate: null,
    documentUrl: "",
    userKey: null,
    openInvoices: [],
    isSignedEvent: false,
  },

  mutations: {
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_SHARED_RESOURCES_TO_STATE(state: any, payload: any) {
      state.allEmployees = payload.data;
      state.totalPages = payload.meta.total_pages;
      state.totalData = payload.meta.total;
      state.currentPage = payload.meta.current_page;
    },
    SET_SIGNATURE_IMAGE_URL_TO_STATE(state: any, payload: any) {
      state.signatureImageUrl = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_SIGNATURE_DATE_TO_STATE(state: any, payload: any) {
      state.signatureDate = payload;
    },
    SET_OPEN_INVOICES_TO_STATE(state: any, payload: any) {
      state.openInvoices = payload;
    },
    SET_DOCUMENT_URL_TO_STATE(state: any, payload: any) {
      state.documentUrl = payload;
    },
    SET_SIGNED_EVENT_TO_STATE(state: any, payload: any) {
        state.isSignedEvent = payload;
    }
  },
  getters: {
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isSettlementStatusOptions(state: any) {
      return state.settlementStatusOptions;
    },
    isTotalData(state: any) {
      return state.totalData;
    },
    signatureImageUrl(state: any) {
      return state.signatureImageUrl;
    },
    signatureDate(state: any) {
      return state.signatureDate;
    },

    openInvoices(state: any) {
      return state.openInvoices.cost_centres
    },
    documentUrl(state: any) {
      return state.documentUrl;
    },
    getIsSignedEvent(state: any) {
      return state.isSignedEvent;
    },
  },
  actions: {
    // getAllEmployeesNonPaginated
    getSharedResources(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(`${getAllEmployeesNonPaginated}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_SHARED_RESOURCES_TO_STATE", response.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSignature(context: ActionContext<any, any>) {
      Api()
        .get(`${getEmployeeSignature}${context.state.userKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const imageUrl = response.data.data.signature;
            context.commit("SET_SIGNATURE_IMAGE_URL_TO_STATE", imageUrl);
            context.commit("SET_SIGNED_EVENT_TO_STATE", true);
          }
        });
    },
    getOpenInvoices(context: ActionContext<any, any>) {
      Api()
        .get(`${getOpenInvoices}`)
        .then((response) => {
          if (response.data.status === "success") {
            const invoices = response.data.data;
            context.commit("SET_OPEN_INVOICES_TO_STATE", invoices);
          }
        });
    },
  },
};
