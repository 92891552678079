<template>
  <div class="has-tooltip">
    <span
      v-if="tooltipShow"
      class="tooltip rounded shadow-lg p-1 bg-gray-950 text-white text-xs -mt-8"
    >
      {{ text }}
    </span>
    <slot name="content"></slot>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "Props Tooltip Text",
    },
    tooltipShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
