<template>
  <div class="input-wrapper">
    <div class="py-2 label" v-if="isLabel">
      <label :for="id">{{ label }}</label>
    </div>
    <div class="relative">
      <input
        :class="[
          className,
          [isCheque ? 'bg-transparent border-none !shadow-none':''],
          {
            'border-red-500': error,
            'cursor-pointer': type === 'date',
          },
        ]"
        :disabled="disabled"
        :type="type"
        :name="id"
        :id="id"
        :min="isBirthdate ? birthdateMin : min"
        :max="isBirthdate ? birthdateMax : max"
        :placeholder="placeholder"
        :value="modelValue"
        @keydown="hidError"
        @input="updateValue"
        @blur="validateBirthdate"
      />
      <div
        class="absolute right-0 flex mr-2 transform -translate-y-1/2 item-center top-1/2"
        v-if="error"
      >
        <!-- <v-icon size="20" icon="custom:errorIcon" /> -->
      </div>
    </div>
    <p v-if="error" class="text-red-500 text-[14px]">
      {{ $t(`${errorMessage.getTranslationKey()}`) }}
    </p>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputField",
  props: {
    id: {
      type: String,
      default: "input-field",
    },
    isBirthdate: {
      type: Boolean,
      default: false,
    },
    birthdateMin: {
      type: String,
      default: () => {
        const currentDate = new Date();
        const minDate = new Date(currentDate);
        minDate.setFullYear(currentDate.getFullYear() - 120);
        return minDate.toISOString().split("T")[0]; // Format as yyyy-mm-dd
      },
    },
    birthdateMax: {
      type: String,
      default: () => {
        const currentDate = new Date();
        return currentDate.toISOString().split("T")[0]; // Format as yyyy-mm-dd
      },
    },
    label: {
      type: String,
      default: "Label",
    },
    className: {
      type: String,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    isCheque: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // errorMessages: this.errorMessage,
      error: false,
    };
  },
  watch: {
    hasError(val) {

      this.error = val
    }
  },

  methods: {
    updateValue(event) {
      this.error = this.isRequired && !event.target.value?.trim() && this.hasError

      this.$emit('update:modelValue', event.target.value)
    },
    
      }
    
 
});
</script>

<style scoped>
/*
 input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
} 
*/
.input-wrapper {
  position: relative;
  width: 100%;
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

input {
  align-items: center;
  padding: 10px 14px;
  outline-width: 0;
  gap: 8px;
  width: 100% !important;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

input:focus {
  background: #ffffff;

  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}

input:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}

.border-red-500 {
  border-color: red;
}
</style>
