<template>
  <div>
    <div
      v-if="apploader"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else>
     
      <v-sheet max-width="" class="px-3 pb-3 mt-5 bg-white border rounded-lg">
        <BankCashBalance></BankCashBalance>
      </v-sheet>
      <div class="table py-13">
        <app-table
          :showCursor="false"
          :hideMenu="shouldDisableInput"
          :headers="headers"
          :items="isBankCashData"
          :pagename="$t('bankcash.cashTransfer')"
          :message="$t('bankcash.cashTransfer')"
          @delete-event="showDelete({ $event, data: 'delete' })"
          @edit-event="showDialog({ $event, data: 'detail' })"
          data-cy="leavesTable"
        >
          <template #title>
            <h3 class="H500">{{ $t("bankcash.allCashBalance") }}</h3>
          </template>
          <template #actions>
            <app-primary-button
              @click-event="showDialog({ data: 'add' })"
              :buttonLabel="$t('bankcash.newCashBalance')"
              data-cy="btn_cash_bank"
              v-if="!shouldDisableInput"
            >
              <template #icon>
                <v-icon icon="mdi-plus"></v-icon>
              </template>
            </app-primary-button>
          </template>
          <template #footer>
            <div>
              <Paginator
                :total-items="totalPages"
                v-model="currentPages"
                :items-per-page="10"
                :max-pages-shown="5"
                :on-click="loadMore"
              />
            </div>
          </template>
        </app-table>
      </div>
      <app-dialog
        ref="dialog"
        :title="isEdit ? $t('bankcash.editCash') : $t('bankcash.addBankCash')"
        :subtitle="``"
      >
        <template #content>
          <div class="flex items-center">
            <div class="flex items-center pr-10 SPC-MT-100">
              <input
                class="cursor-pointer"
                :class="{ '!cursor-default': shouldDisableInput }"
                type="radio"
                name="flexRadioDefault"
                id="radioDefault01"
                v-model="selectedOption"
                value="Cash to Bank"
                checked
              />
              <label
                class="H400"
                for="radioDefault01"
                :class="{ '!cursor-default': shouldDisableInput }"
              >
                {{ $t("bankcash.cashBank") }}
              </label>
            </div>
            <div class="flex items-center SPC-MT-100 ml-9">
              <input
                class="cursor-pointer"
                :class="{ '!cursor-default': shouldDisableInput }"
                type="radio"
                name="flexRadioDefault"
                id="radioDefault02"
                value="Bank to Cash"
                v-model="selectedOption"
              />
              <label
                class="H400"
                :class="{ '!cursor-default': shouldDisableInput }"
                for="radioDefault02"
              >
                {{ $t("bankcash.bankToCash") }}
              </label>
            </div>
          </div>
          <app-date-field
            v-model="form.date"
            type="date"
            :label="$t('bankcash.date')"
            :hasError="isFieldInvalid('date')"
            :isRequired="true"
          ></app-date-field>
          <app-single-select
            id="currencies"
            :showCheckbox="false"
            :showSearch="true"
            :options="fetchCurrencies"
            :searchPlaceholder="$t('bankcash.search')"
            :labelPlaceholder="$t('projects.currencyPlaceholder')"
            :label="$t('projects.currency')"
            v-model="form.currency"
            :hasError="isFieldInvalid('currency')"
            :isRequired="true"
          ></app-single-select>
          <app-input-field
            :placeholder="$t('bankcash.enterAmount')"
            v-model="amount"
            :label="$t('bankcash.amount')"
            validation="floatingNumber"
          ></app-input-field>
          <p v-if="isAmountAvailable" class="text-red-500">
            {{ $t("currencyExchange.availableAmount") }}: {{
              selectedOption === "Bank to Cash"
                ?getLanguageCurrency(totalBankBalance)
                : getLanguageCurrency(totalCashBalance)
            }}
          </p>
        </template>
        <template #actions>
          <v-row class="px-4 mt-1 mb-5">
            <v-col cols="6">
              <app-secondary-button
                :buttonLabel="$t('projects.cancel')"
                @click-event="hideDialog()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="6">
              <app-primary-button
                :buttonLabel="isEdit ? $t('bankcash.savechanges') : 'bankcash.add'"
                :loading="loading"
                @click-event="isEdit ? updateBankCash() : addBankCah()"
              >
              </app-primary-button>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
      <!-- delete mile stone  -->
      <app-dialog
        ref="delete"
        :title="$t(`bankcash.deleteCash`)"
        :subtitle="`bankcash.deleteMessage`"
      >
        <template #actions>
          <v-row class="px-4 mt-1 mb-5">
            <v-col cols="5">
              <app-secondary-button
                :buttonLabel="$t('bankcash.noKeepIt')"
                @click-event="hideDelete()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="7">
              <v-btn
                variant="default"
                min-width="100%"
                min-height="100%"
                class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
                @click="deleteBankCash()"
              >
              {{$t('currencyExchange.delete')}}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
    
      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Api from "@/services/api"; import { getBankCah, addBankCah, getAllCurrencies, manageBankCah } from "@/services/endpoints";
import Paginator from "@/components/shared/PaginationComponent.vue";
import { useI18n } from 'vue-i18n';
import { mapGetters, mapState } from 'vuex';
import { currencyFormatter } from "@/services/helpers";
import { getTransString, } from "@/services/helpers";
import BankCashBalance from "../bank-cash/BankCashBalance.vue";
import moment from "moment";
export default defineComponent({
  name: "BankCah",
  components: {
    Paginator,
    BankCashBalance
  },
  created() {

    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 4);
  },
  data() {
    return {
      headers: [
        "No.",
        "Date",
        "Transfer Type",
        "Currency",
        "Amount",
      ],
      apploader: true,
      currentPages: null,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      values: [],

      cancelLabel: "Cancel",
      buttonLabel: "Add position",
      title: "",
      deadline: "",
      description: "",
      itemId: null,
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      amount: 0,
      form: {
        currency: null,
        date: "",
      },
      fetchCurrencies: [],
      selectedOption: "Cash to Bank",
      isAmountAvailable: false,
      totalAmount: null,
      totalCashBalance: 0,
      totalBankBalance: 0,
      isDirty: [],
      requiredFields: [
        "date",
        "currency",
      ],
    };

  },

  computed: {
    ...mapGetters("translation", ["getLocale"]),
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    currentPath() {
      return this.$route.path;
    },

    role() {
      return this.$store.getters["auth/role"]
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isAllBankCash() {
      return this.$store.getters["pmLocalModules/isAllBankCash"];
    },
    currentPage() {
      return this.$store.getters["pmLocalModules/isCurrentPage"];
    },
    isPmlocalSubId() {
      return this.$store.getters["pmLocalModules/isPmlocalSubId"];
    },
    isForeignEstablishMentID() {
      return this.$store.getters["pmLocalModules/isForeignEstablishMentID"];
    },
    isBankCashData() {
      return this.$store.getters["pmLocalModules/isBankCashData"];
    },
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    totalPages() {
      return this.$store.getters["pmLocalModules/isTotalPages"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    canEditPmLocalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"] && this.canEditPmLocalModule?.includes("Bank/Cash")
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },

    shouldDisableInput() {
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
  },
  setup() {
    const { t } = useI18n();

    function transText(text) {
      return t(getTransString(text));
    }

    return {
      transText,

    };
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.form).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.form[key] === "" ||
            this.form[key] === undefined ||
            this.form[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },

    getLanguageCurrency(amount) {
      return this.appLang === "de" ? this.germanCurrency(amount) : amount?.formatCurrency()
    },

    germanCurrency(curr) {
      return currencyFormatter(curr);
    },

    getAllCurrencies() {
      Api()
        .get(`${getAllCurrencies}${this.pmLocalID}`)
        .then((res) => {
          this.fetchCurrencies = res.data.data.map((currency) => ({
            text: currency.currency,
            value: currency.id,
          }));
        })
    },
    showDialog(data) {
      this.itemId = data?.$event?.id;
      this.modelValue = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "add") {
        this.currency = "";
        this.amount = 0;
        this.form.date = "";
        this.title = data?.$event?.title;
        this.$refs.dialog.showDialog = true;
        this.isEdit = false
      } else
        if (data.data === "detail") {

          this.amount = this.removeComma(data?.$event?.amount);
          this.selectedOption = data?.$event?.['transfer type'];
          this.form.date = moment(data?.$event?.date).format("YYYY-MM-DD");
          this.form.currency = data?.$event?.currency_id;
          this.isEdit = true
          this.$refs.dialog.showDialog = true;
        }
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.form.currency = "";
      this.amount = 0;
      this.form.date = "";

    },

    showDelete(data) {
      this.itemId = data?.$event?.id;
      this.modelValue = data?.$event?.title;
      this.subTitle = "";
      if (data.data === "delete") {
        this.title = data?.$event?.title;
        this.deadline = data?.$event?.deadline;
        this.description = data?.$event?.description;
        this.$refs.delete.showDialog = true;
      }
    },
    hideDelete() {
      this.$refs.delete.showDialog = false;


    },
    removeComma(valueWithComma) {
      return valueWithComma.replace(/,/g, "");
    },

    calculateTotalBalance() {
      this.totalCashBalance = 0;
      this.totalBankBalance = 0;
      for (const item of this.isAllBankCash.balances || []) {
        if (item.currency === this.form.currency) {
          if (this.selectedOption === "Cash to Bank") {
            this.totalCashBalance += item.cash_balance;
          } else if (this.selectedOption === "Bank to Cash") {
            this.totalBankBalance += item.bank_balance;
          }
        }
      }
    },


    addBankCah() {
      if(Number(this.amount) < 0) return this.checkAlertEror("Amount must be greater than 0")
      if (this.isAmountAvailable) {
        this.checkAlertEror("Insufficient balance, cannot perform money transfer transaction")
        return this.isAmountAvailable
      }
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      const payload = {
        amount: Number(this.amount),
        transfer_currency: this.form.currency,
        date: this.form.date,
        transfer_type: this.selectedOption
      }
      Api()
        .post(`${addBankCah}${this.isPmlocalSubId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllBankCash(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    updateBankCash() {
      if (this.isAmountAvailable) {
        this.checkAlertEror("Insufficient balance, cannot perform money transfer transaction")
        return this.isAmountAvailable
      }
      if(Number(this.amount) < 0) return this.checkAlertEror("Amount must be greater than 0")
      const payload = {
        amount: Number(this.amount),
        transfer_currency: this.form.currency,
        date: this.form.date,
        transfer_type: this.selectedOption
      }
      Api()
        .put(`${manageBankCah}${this.itemId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllBankCash(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteBankCash() {
      Api()
        .delete(`${manageBankCah}${this.itemId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.delete.showDialog = false;
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllBankCash(this.currentPage)

          }
          if (response.data.status === "error") {
            this.$refs.delete.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }


        }).catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    getAllBankCash(page) {
      this.apploader = true;
      Api()
        .get(`${getBankCah}${this.isPmlocalSubId}/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.apploader = false;
            const data = response.data.data;
            this.values = data.map((obj, index) => {
              const formattedDate = moment(obj.date).format(
                "D.M.YYYY"
              );
              return {
                id: obj.id,
                amount: this.getLanguageCurrency(obj.amount),
                currency: obj.transfer_currency_name,
                'transfer type': obj.transfer_type,
                date: formattedDate,
                'no.': index + 1,
                currency_id: obj.transfer_currency

              };
            });
            this.$store.commit("pmLocalModules/SET_ALL_BANK_CASH_ITEM_TO_STATE", response.data);
            this.$store.commit("pmLocalModules/SET_ALL_BANK_CASH_TO_STATE", this.values);
            this.$store.commit(
              "pmLocalModules/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "pmLocalModules/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    //     close() {
    //     this.$router.push({name: "employees"})
    //   },
    loadMore() {
      const page = this.currentPages;
      this.getAllBankCash(page);
    },
  },
  watch: {
    selectedOption(newValue) {
      if (newValue) {
        this.calculateTotalBalance();
      }
    },

    amount(newValue) {
      if (this.selectedOption === "Cash to Bank") {
        this.isAmountAvailable = newValue > Number(this.totalCashBalance)
        this.calculateTotalBalance();
      } else if (this.selectedOption === "Bank to Cash") {
        this.isAmountAvailable = newValue > this.totalBankBalance
        this.calculateTotalBalance();
      }

    },
  },
  mounted() {
    this.calculateTotalBalance();

    this.getAllCurrencies()
    this.getAllBankCash(this.currentPage)
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
  },

});
</script>

<style scoped>
.table {
  width: 100%;
}

.input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background-color: white;
}

/* unselected radio button */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 0.5rem;
  position: relative;
}

/* middle fill color */
input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* selected radio button */
input[type="radio"]:checked::before {
  background-color: #8b2923;
  border: 2px solid #8b2923;
  width: 8px;
  height: 8px;
}

input[type="radio"]:checked {
  background-color: #f4eae9;
  border: 1px solid #ae6a65;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  outline: none;

  position: relative;
}
</style>
