<template>
  <v-sheet max-width="1125" class="bg-transparent">
    <v-row>
      <v-col cols="10" class="mt-5 !divide-y">
        <p class="pb-3 font-semibold text-[1.125rem]">
          {{ $t("projects.proposal") }}
        </p>
        <div></div>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-single-select
          id="category"
          :showCheckbox="false"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchIconCategory')"
          :options="iconCategoryOptions"
          :labelPlaceholder="$t('projects.iconCategoryPlaceholder')"
          :label="$t('projects.iconCategory')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.icon_category_id"
        ></app-single-select>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-input-field
          id=""
          :placeholder="$t('projects.iconKeywordsPlaceholder')"
          :label="$t('projects.iconKeywords')"
          v-model="projectsForm.keywords"
          :disabled="shouldDisableInput"
        ></app-input-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { getSelectOptionList } from '@/services/helpers'
import { isEqual } from 'lodash';

export default defineComponent({
  props: {
    projectData: {
      type: Object
    }
  },
  data() {
    return {
      selectedCategory: [],
      selectedController: [],
      projectsForm: {
        icon_category_id: '',
        keywords: '',
      },
      originalProjectsForm: {
        icon_category_id: '',
        keywords: '',
      },
      firstRun: true
    }
  },
  watch: {
    // projectData(data) {
    //   this.populateProjectForm(this.projectsForm, data);
    //    if(this.firstRun) {
    //      this.populateProjectForm(this.originalProjectsForm, data);
    //      this.firstRun = false
    //    }
    // },
    projectsForm: {
      deep: true,
      handler(value) {
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
        "BasicInformation": !isEqual(value, this.originalProjectsForm)
      });
      },
    }
  },
  computed: {
    iconCategoryOptions() {
      const optionList = getSelectOptionList('hrConfigurations/isIconCategory')
      return optionList.map((category) => {
        return {
          text: category.id,
          value: category.value
        }
      })
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },

  },
  methods: {

    populateProjectForm(saveTo, data) {
      saveTo.icon_category_id = data?.icon_category;
      saveTo.keywords = data?.keywords
    }
  },
  created() {
    const payload = {
      configType: 'ICON Category',
      setter: 'SET_ICON_CATEGORY_TO_STATE'
    }
    this.$store.dispatch('hrConfigurations/getAllConfigurationTypes', payload)
    // this.$store.dispatch('hrResources/getAllEmployees')
    this.populateProjectForm(this.projectsForm, this.projectData);
    this.populateProjectForm(this.originalProjectsForm, this.projectData);
  }
})
</script>
