<template>
  <div>
    <div class="py-2 label">
      <label>{{ $t("appsearchfield.search") }}</label>
    </div>
    <div
      class="search flex bg-gray-50 border border-[#d8dae5] hover:border-[#d8dae5] gray:focus:border-[#d8dae5] focus:outline-[#d8dae5] focus:ring-[none] focus:ring peer block dark:bg-gray-700 p-2 rounded-md border-1 border-solid border-gray-300 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);"
    >
      <v-icon icon="custom:searchIcon" class="mr-2" />
      <input
        type="text"
        :placeholder="$t(placeholder.getTranslationKey())"
        v-model.trim="search"
        @keyup.enter="searchRide"
        @click:clear="clearAndFetchData"
      />
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import debounce from 'lodash.debounce'
import Api from "@/services/api"
import { getTransString } from "@/services/helpers";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "AppSearchField",
  props:{
      employmentType:{
        type: String,
        default: "Employee"
      },
      research: {
        type: Boolean
      },
      callback: {
        type: Boolean,
        default: false
      },
      endpoint: {
        type: String,
        default: '/hr/employees/search-employees/'
      },
      placeholder: {
        type: String,
        default: 'Search'
      }
    },
    setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  data() {
    return{
      search: '',
    }
  },
  watch: {
    search (newVal) {
      this.initiateSearch(newVal, "search");
    },
    filtersBy (val) {
      this.initiateSearch(val, "filter");
    },
    projectFiltersBy(val) {
      this.initiateFilter(val, "projectFilter");
    },
    partnerFiltersBy(val) {
      this.initiateFilter(val, "partnerFilter");
    }
  },
  computed:{
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    filtersBy() {
      return this.$store.getters["hrResources/isFilterBys"];
    },
    projectFiltersBy() {
      return this.$store.getters["projectModules/isFilterBys"];
    },
    partnerFiltersBy() {
      return this.$store.getters["partnerModules/isFilterBys"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkFilterType(type) {
      return this.filtersBy.find((elt) => {
        return Object.keys(elt || {}).includes(type)
      }) || {};
    },
    async searchUsers(type){
      const payload = {
        employment_type: this.employmentType
      }

      if (type === "search") {
        if(this.search === null) return;
        payload.search_query = this.search;
        if (Object.keys(this.checkFilterType("is_active")).length) {
          payload.status = this.checkFilterType("is_active")["is_active"] === "Active";
        }
      }

      else if (type === "filter") {
        if(this.filtersBy === null || !this.filtersBy.length) return;
        payload.filters = this.filtersBy.reduce((accumulator, filter) => {
          if (!Object.keys(filter).includes(undefined) && !Object.values(filter).includes(undefined)) {
            accumulator.push(filter);
          }
          return accumulator;
        }, []);
      }

      if ((type === "filter" && payload.filters.length) || (type === "search" && payload.search_query.length)) {
        Api()
            .post(`${this.endpoint}${this.currentPage}/`, payload)
            .then((response) =>{
              if (response.data.status === "success") {
                // this.headers = response.data.headers;
                const data = response.data.data;
                const foreignEmployees = data?.map((data) => {
              return {
                name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
                title: data.title,
                country: data.country_of_birth,
                email: data.employee__email,
                user_key: data.employee__user_key,
                is_active: data.employee__is_active,
                profile_picture: data.profile_picture,
              };
            });
                // convert the keys of the objects to lowercase
                const payload = data.map(function (obj) {
                  return Object.keys(obj).reduce(function (newObj, key) {
                    newObj[key.toLowerCase()] = obj[key];
                    return newObj;
                  }, {});
                });

                this.$store.commit("hrResources/SET_EMPLOYEES_TO_STATE", this.endpoint ==='/projects/foreign-office/search-foreign-employees/'? foreignEmployees :payload);
                this.$store.commit("hrResources/SET_TABLE_HEADERS_TO_STATE", response.data.headers);
                this.$store.commit(
                    "hrResources/SET_CURRENT_PAGE_TO_STATE",
                    response.data.current_page
                );
                this.$store.commit(
                    "hrResources/SET_TOTAL_PAGES_TO_STATE",
                    response.data.total_data
                );
                this.$emit("update-pages")
              }
            })
      }
    },

    initiateSearch (newVal, type) {
      if (newVal.length < 1){
        this.$emit('search-event', type)
      }
      else {
        this.debounceSearchResult(type);
      }
    },

    initiateFilter (newVal) {
      this.$emit('filter-event', newVal);
    }
  },

  created () {
    this.debounceSearchResult = debounce((type) => {
      if (this.callback) {
        if (this.search) {
          this.$emit('search-function', this.search);
        }
      } else {
        this.searchUsers(type)
      }
    }, 1000);
  }
});
</script>

<style scoped>
input {
  gap: 8px;
  border: none;
  outline: none;
  outline-width: 0;
  width: 100%;
  background-color: white;
  height: 26px;
}
label {
  color: #344054;
}
.search {
  background-color: white;
  border-radius: 8px;
}
</style>
