<template>
  <div class="flex items-center">
    <div class="p-2 bg-white rounded shadow-sm">
      <span
        class="h-10 py-2 text-gray-600 rounded-sm rounded-l hover:bg-gray-100"
        @click.prevent="onClickFirstPage"
      >
        <v-icon variant="text" icon="custom:chevronsLeft"></v-icon>
      </span>
      <span
        @click.prevent="goBackward"
        class="py-2 text-gray-600 no-underline rounded-sm rounded-l hover:bg-gray-100"
        href="#"
        role="button"
        rel="prev"
      >
        <v-icon variant="text" icon="custom:chevronLeft"></v-icon>
      </span>
    </div>

    <div class="text-base font-medium text-[#344054] cursor-default px-2">
      {{ currentWeek }}
    </div>

    <div class="p-2 bg-white rounded shadow-sm">
      <span
        class="h-10 py-2 text-gray-600 rounded-sm rounded-l cursor-pointer"
        @click="goForward"
        :disabled="isInLastPage"
      >
        <v-icon variant="text" icon="custom:chevronRight"></v-icon>
      </span>
      <span
        @click.prevent="onClickLastPage"
        :disabled="isInLastPage"
        class="py-2 text-gray-600 no-underline rounded-sm rounded-l hover:bg-gray-100"
        href="#"
        role="button"
        rel="prev"
      >
        <v-icon variant="text" icon="custom:chevronsRight"></v-icon>
      </span>
    </div>
  </div>
</template>
<script>
import {
  getFormattedFirstDatesForNextAndPastNWeeks,
  getFormattedFirstDatesForNextNWeeks,
} from "@/services/helpers";
export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },

    currentDate: {
      type: String,
      required: true,
    },
    currentPosition: {
      type: Number,
      default: 1,
      required: true,
    },
    currentDirection: {
      type: String,
      default: "current",
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      current_page: "Current week",
      weeks: [
        "4 weeks back",
        "3 weeks back",
        "2 weeks back",
        "1 week back",
        "Current week",
        "1 week forward",
        "2 weeks forward",
        "3 weeks forward",
        "4 weeks forward",
      ],
      currentWeekIndex: 4,
      countBackward: 0,
      countforward: 0,
    };
  },
  watch: {
    currentWeek(val) {
      if (val === "Current week") {
        // this.$emit("currentpage");
        const data = {
          position: 5,
          direction: "current",
        };
        this.$emit("pagechanged", data);
        return;
      }

      const position = this.extractNumberFromString(val);

      const direction = this.extractLastWordFromString(val);
      const data = {
        position: position,
        direction: direction,
      };
      this.$emit("pagechanged", data);
    },
  },

  computed: {
    currentWeek() {
      return this.weeks[this.currentWeekIndex];
    },

    isInFirstPage() {
      return this.currentWeekIndex === 0;
    },
    isInLastPage() {
      return this.currentWeekIndex === 8;
    },
  },

  methods: {
    formatDate(data) {
      return getFormattedFirstDatesForNextAndPastNWeeks(0, data);
    },
    futureDate(data) {
      return getFormattedFirstDatesForNextNWeeks(0, data);
    },
    pastDate(data) {
      const { past } = getFormattedFirstDatesForNextAndPastNWeeks(0, data);
      return past;
    },
    onClickFirstPage() {
      this.currentWeekIndex = 0;
      const data = {
        position: 4,
        direction: "back",
      };
      this.$emit("pagechanged", data);
    },
    goBackward() {
      this.countforward = 0;
      if (this.currentWeekIndex > 0) {
        // Skip empty weeks when moving backward
        do {
          this.currentWeekIndex--;
          this.countBackward++;

          if (this.currentWeekIndex === 0) {
            // this.currentWeekIndex = 4
            this.countBackward = 0;
          }
        } while (this.weeks[this.currentWeekIndex] === "");
      }
    },

    goForward() {
      this.countBackward = 0;
      if (this.currentWeekIndex < this.weeks.length - 1) {
        this.currentWeekIndex++;
        this.countforward++;

        if (this.currentWeekIndex === 8) {
          // this.currentWeekIndex = 4
          this.countforward = 0;
        }
      }
    },
    onClickLastPage() {
      this.currentWeekIndex = 8;
      const data = {
        position: 4,
        direction: "forward",
      };

      this.$emit("pagechanged", data);
    },
    extractNumberFromString(input) {
      const match = input?.match(/\d+/); // Use a regular expression to match one or more digits
      if (match) {
        return parseInt(match[0], 10); // Parse the matched number as an integer
      } else {
        return null; // Return null if no number is found
      }
    },
    extractLastWordFromString(input) {
      const words = input?.split(" "); // Split the input string into an array of words

      if (words.length > 0) {
        return words[words?.length - 1]; // Return the last word
      } else {
        return null; // Return null if the input string is empty
      }
    },
  },
  created() {
    // const future = getFormattedFirstDatesForNextNWeeks(0, 1);
  },
};
</script>

<style scoped>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}
</style>
