const documentData = `
<h2 class="flex items-center H500 py-2">Stable Establishment Abroad:<p style="color: #344054; font-size: 15px;" class="px-4 ">[country]</p></h2><br>
<h2 class="flex items-center H500 py-2">Regarding the Project:<p style="color: #344054; font-size: 15px;" class="px-4 ">[project]</p></h2><br>
<h2 class="flex items-center H500 py-2">No.:<p style="color: #344054; font-size: 15px;" class="px-4 ">[number]</p></h2><br>
<h2 class="flex items-center H500 py-2">Expert:<p style="color: #344054; font-size: 15px;" class="px-4 ">[expert]</p></h2><br>
<h2 class="flex items-center H500 py-2">Location of Mission:<p style="color: #344054; font-size: 15px;" class="px-4 ">[assignment]</p></h2><br>
<h2 class="flex items-center H500 py-2">Period of Accounts:<p style="color: #344054; font-size: 15px;" class="px-4 ">[accountingperiod]</p></h2><br>
<h3  style="color: #344054; font-size: 15px;">•The ticket is provided by ICON Public [iconticketprovided].</h3><br>
<h3 style="color: #344054; font-size: 15px;">•Expenses for **) Visa Fees and Flight Penalties will be reimbursed upon proof up to a [cost].</h3><br>
<h2 class="flex items-center py-2 H500">It is agreed for this Mission:</h2><br>
<table class="table-auto w-full py-2"><br>
    <thead>
        <tr>
        <th class="px-2 py-1 text-left border H500">Agreement</th>
            <th class="px-2 py-1 text-left border H500">Number</th>
            <th class="px-2 py-1 text-left border H500">Amount</th>
            <th class="px-2 py-1 border text-left H500">Total</th>
        </tr>       
    </thead>
    <tbody>
    </tbody>
            </table>
            <h2 class="flex items-center H500 py-2">Payment Amount:<p style="color: #344054; font-size: 15px;" class="px-4 ">[paymentamount].</p></h2><br>
            <h2 class="flex items-center H500 py-2">Task:</h2><br>
<h3 class="text-left " style="color: #344054; font-size: 15px;">[missiontaskreport]</h3><br>
<h2 class="flex items-center H500">At the end of the month in which the mission takes place the following original documents must 
be submitted to ICON Public:</h2><br>
<h3 style="color: #344054; font-size: 15px;">• Invoice</h3><br>
<h3 style="color: #344054; font-size: 15px;">• Travel Documents (Tickets and Boarding Passes)</h3><br>
<h3 "style="color: #344054; font-size: 15px;">• Timesheets</h3><br>
<h3 style="color: #344054; font-size: 15px;">• Supporting documents for: Visa Fees and Flight Penalties</h3><br>

`;
export default documentData;
