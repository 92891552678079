<template>
  <v-sheet max-width="" class="px-3 pt-4 pb-4 mt-10 bg-white rounded-lg border">
    <v-row>
      <v-col cols="10" class="d-flex align-center">
        <div class="d-flex align-center">
          <div
            class="w-full py-1 px-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
          >
            BOP
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-2 mb-3">
      <table
        class="border-collapse border border-slate-400 w-full"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th
              class="text-left border border-slate-300 px-4 py-2 bg-[#F9FAFB] capitalize"
              v-for="(header, index) in headers"
              :key="index"
            >
              <!-- {{ convertHeader(header) }} -->
              {{ $t(`projectBudget.budgetTableHeader.${index}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(section, sectionIndex) in filteredBudgetSections"
            :key="sectionIndex"
          >
            <tr
              v-for="(item, itemIndex) in section.budget_items"
              :key="itemIndex"
              :class="getCellClass('structure', item.structure)"
            >
              <td class="border border-slate-300 px-4 py-2">
                {{ item.position }}
              </td>
              <td
                class="border border-slate-300 px-4 py-2"
                :class="getCellClass('structure', item.structure)"
              >
                {{ item.structure }}
              </td>
              <td class="border border-slate-300 px-4 py-2">
                {{ item.account }}
              </td>
              <td class="border border-slate-300 px-4 py-2 text-center">
                {{ item.number_of_units }}
              </td>
              <td class="border border-slate-300 px-4 py-2 text-center">
                {{ item.amount_per_unit }}
              </td>
              <td class="border border-slate-300 px-4 py-2">
                <div class="mt-1 text-center">
                  <input
                    :disabled="!isEditButton"
                    class="w-full"
                    type="checkbox"
                    v-model="item.is_planning"
                  />
                </div>
              </td>
              <td class="border border-slate-300 px-4 py-2 text-right">
                {{ isCurrentProject.currency }}
                {{
                  appLang === "de"
                    ? germanCurrency(
                        item?.number_of_units * item?.amount_per_unit
                      )
                    : (
                        item?.number_of_units * item?.amount_per_unit
                      )?.formatCurrency()
                }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <p class="text-[14px] text-[#1D2939] text-right mr-8 font-bold">
      {{ $t("projectBudget.total") }}: {{ isCurrentProject.currency }}
      {{
        appLang === "de"
          ? germanCurrency(totalIncome)
          : totalIncome?.formatCurrency()
      }}
    </p>
  </v-sheet>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import { mapState } from "vuex";
import { getTransString,  currencyFormatter } from '@/services/helpers'

export default defineComponent({
  components: {},
  props: {
  receiveTotals: {
    type: Object
  }
},
  data() {
    return {
      headers: ['position', 'structure', 'account', 'number_of_units', 'amount_per_unit', 'is_planning', 'total_amount'],
    };
  },
  computed: {

  isCurrentProject() {
    return this.$store.getters["projectModules/isCurrentProject"];
  },
  totalStatistics() {
    return this.$store.getters["projectBudgetModules/isTotalStatistics"];
  },
  totalIncome(){
    return Object.keys(this.receiveTotals)?.length === 0 ? this.totalStatistics?.total_income  : this.receiveTotals?.total_income
  },
  budgetSections(){
    return this.$store.getters['projectBudgetModules/isBudgetSections']

  },
  filteredBudgetSections() {
    return this.budgetSections?.map(section => {
      return {
        ...section,
        budget_items: section.budget_items?.filter(item => item?.structure === 'BOP - Fixed Fee' || item?.structure === 'BOP - Against Receipt')
      }
    }).filter(section => section.budget_items?.length > 0);
  },
  appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
  },
  methods: {
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    convertHeader(header) {
      const headers = {
        number_of_units: 'number of units',
        amount_per_unit: 'amount per unit',
        is_planning: 'planning',
        total_amount: 'total amount',
      };
      return Object.keys(headers)?.includes(header) ? headers[header] : header;
    },
    getCellClass(header, value) {
      return {
        'bg-[#FEFBE8]': header === 'structure' && value === 'BOP - Fixed Fee',
        'bg-[#F2F4F7]': header === 'structure' && value === 'BOP - Against Receipt',
      };
    },
  }
});
</script>
<style scoped>
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #943b36;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: #fbf7f6;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #943b36;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
