<template>
    <div class="">
      <div>
        <div>
          <button
            v-for="(tab, index) in tabList"
            :key="index"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="changeTab(tab)"
          >
            {{ $t(tab.getTranslationKey()) }}
          </button>
        </div>
      </div>
      <div  v-if="currentTab === 'Details'" class="pt-3 h-[200px]">
            <app-input-field
              id="Subject"
              :placeholder="selectedName || $t('Enter email subject')"
              :label="$t('Subject*')"
              v-model="subject"
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-input-field>
            <multi-select
              :label="$t('Send Copy to')"
              :labelPlaceholder="selectedName || $t('contracts.selectWhoTosubmit')"
              :showCheckbox="true"
              :options="expert"
              v-model="send_copy"
              @selected-options="selectedOptions({ $event, data: 'submit' })"
            >
            </multi-select>
     
      </div>

      <div v-if="currentTab === 'Email Content'" class="pt-3 h-[200px]">
        <p>Email*</p>
        <QuillEditor
        contentType="html"
        v-model:content="email"
        :options="options"
        :style="editorStyles"
      />
      </div>
      <div v-if="currentTab === 'Attach Files'" class="pt-3 ">
        <ContractEmailfile></ContractEmailfile>
      </div>
    </div>
    

    <v-row  class="mt-12 pt-8">
      <v-col cols="6">
        <app-secondary-button
          :buttonLabel="$t('projects.cancel')"
          @click-event="emitHideMailDialog()"
        >
        </app-secondary-button>
      </v-col>
      <v-col cols="6">
        <app-primary-button
        :disabled="!hasNextTab && ( !email || !subject || !send_copy.length)"
          :buttonLabel="$t(isEdit ? 'Update' : hasNextTab?'Continue':'Send Email')"
          @click-event="hasNextTab?switchToNextTab():sendMail()"
        >
        </app-primary-button>
      </v-col>
    </v-row>
    <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { QuillEditor } from "@vueup/vue-quill";
  import ContractEmailfile from "./ContractEmailfile.vue";
  import API from "@/services/api";
  import { getTransString } from "@/services/helpers";
  import { sendExpertMail,getAllProjectContracts} from "@/services/endpoints";
  export default defineComponent({
    name: "Expert Mail",
    components: {
      QuillEditor,
      ContractEmailfile,
    },
    data() {
      return {
        currentTab: "Details",
        tabList: ["Details", "Email Content", "Attach Files"],
        options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: "Enter a comment",
      },
      editorStyles: {
        minHeight: "180px",
        fontSize: "13px",
        lineHeight: "1.5",
      },
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      title: "",
      file: "",
      subject: "",
      send_copy: [],
      expert: [],
      email:`<h5>Dear [expert name],</h5>
<p></p>We are pleased to offer you the opportunity to collaborate with our company on [projectName]. </p>
<p>Attached to this email is the contract outlining the terms and conditions of our engagement. </p>
  <p>Kindly review the contract, and if you find it satisfactory, please sign and return it to us at your earliest convenience.</p>
    <p>If you have any questions or require further clarifications, feel free to reach out to us.</p>
      <p><a href="https://icon.amalitech-dev.net/expert-contract-approval?contractId=[contractId]&contractType=[contractType]&projectId=[projectId]">Click here to access the contract.</a></p>
        <p>Thank you for considering this partnership with us. We look forward to a successful collaboration.</p>
          <p>Best regards,</p>
            <p>[Projectmanager]</p>
              <p>[Your Position]</p>
                <p>[Company Name]</p>`,
      }
    },
    computed: {
      isContractEditId() {
      return this.$store.getters["contractModules/isContractEditId"];
    },
    isContractType() {
      return this.$store.getters["contractModules/isContractType"];
    },
      isCurrentTab() {
        return this.$store.getters["hrConfigurations/isCurrentTab"];
      },
      isSignatureFile() {
      return this.$store.getters["contractModules/isSignatureFile"];
    },
      hasNextTab() {
      const currentIndex = this.tabList.indexOf(this.currentTab);
      return currentIndex < this.tabList.length - 1;
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    },
    methods: {
      updateDocumentWithFetchedData() {
        this.email = this.email.replaceAll(
        "[contractId]",
        this.data.id || "null"
      );
      this.email = this.email.replaceAll(
        "[contractType]",
        this.data.contract_type || "null"
      );
        this.email = this.email.replaceAll(
        "[expert name]",
        this.data.expert_name || "[EXPERT NAME]"
      );
      this.email = this.email.replaceAll(
        "[projectName]",
        this.isCurrentProject.project_title || "[Project Name]"
      );
      this.email = this.email.replaceAll(
        "[projectName]",
        this.isCurrentProject.contract_manager || "[Project manager]"
      );
      this.email = this.email.replaceAll(
        "[projectName]",
        this.isCurrentProject.contract_manager || "[Project manager]"
      );
      this.email = this.email.replaceAll(
        "[projectId]",
        this.isCurrentProject.id || null
      );
      },
      changeTab(tab) { 
        this.currentTab = tab;
      },
      getTranslatedStringKey(field) {
      return getTransString(field);
    },
      switchToNextTab() {
      const currentIndex = this.tabList.indexOf(this.currentTab);
      if (this.hasNextTab) {
        this.currentTab = this.tabList[currentIndex + 1];
      }
    },
    emitHideMailDialog() {
    this.$emit("hide-dialog");
    },
    selectedOptions(data) {
      this.send_copy = data.$event;
    },
    sendMail() {
    const formData = new FormData();
    let originalArray = [];
    for (let item of this.isSignatureFile) {
        originalArray.push(item);
    }
    formData.append("contract_id", this.isContractEditId);
    formData.append("contract_type", this.isContractType);
    formData.append("subject", this.subject);
    formData.append("message", this.email);
    // formData.append('additional_recipients[]', this.send_copy.getObjectValues());
    originalArray.forEach(file => {
    formData.append('attachments', file);
    });
    this.send_copy.getObjectValues().forEach(mail => {
    formData.append('additional_recipients[]',mail);
    });
    API().post(sendExpertMail, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(res => {
    if (res.data.status === "success") {
        this.emitHideMailDialog(),
        this.alertType = "success";
        this.alertTitle = res.data.detail;
        this.alertState = true;
        this.loading = false;
    }
})
.catch(error => {
    this.alertType = "error";
    this.alertTitle = (error.response && error.response.data && error.response.data.detail) ? error.response.data.detail : 'An error occurred';
    this.alertState = true;
    this.loading = false;
});

},
getAllExpects() {
  API()
    .get("/projects/project/employee-contracts/get-all-experts/all/")
    .then((res) => {
      this.expert = res.data.data
        .filter(expert => expert.employee__communication_email) // This will remove experts without email
        .map((expert) => {
          return {
            text: expert.employee__communication_email,
            value: expert.employee__communication_email,
          }
        });
    });
},
getAllpreFixData() {
      API()
        .get(
          `${getAllProjectContracts}${this.isContractType}/${this.isContractEditId}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.data = response.data.data;
            this.updateDocumentWithFetchedData();
          }
        })
        .catch();
    },
    },

    created() {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
      this.$store.commit("contractModules/SET_SIGNATURE_FILES_TO_STATE", []);
    },

    mounted() {
      this.getAllpreFixData()
      this.getAllExpects();
    },
  });
  </script>
  <style scoped>

  </style>