import API from "@/services/api";
import {getAllExpertMission, getExpertMissionPlanningInvoices} from "@/services/endpoints";
import {formatDate} from "@/services/helpers";
import {useStore} from "vuex";
import Api from "@/services/api";


export const getAllExpertMissionPlanningInvoices = async (missionId) => {
    try {
        const response = await Api().get(`${getExpertMissionPlanningInvoices}${missionId}`)
        if (response.status === 200) {
                return response.data;
        }
    } catch (error) {
        return error.response.data;
    }
};