<template>
  <div
    v-if="getAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else  class="scrollable-content">
    <div  class="scrollable-inner">
     <div class="px-5 py-4 bg-white border rounded-lg mt-7">
        <v-row class="">
          <v-col cols="12">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t("projects.projectName") }}
            </p>
            <p class="mt-2 font-bold">{{ getProjectLocalBudget?.project_title }}</p>
          </v-col>
          <v-col cols="3">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t("projects.projectStartAndEndDate") }}
            </p>
            <p class="mt-2 font-bold">{{ formatDate(getProjectLocalBudget?.project_start_date) }} - {{ formatDate(getProjectLocalBudget?.project_end_date) }}</p>
          </v-col>
          <v-col cols="2">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t("projects.costCenterNumber") }}
              <br />
            </p>
            <p class="mt-2 font-bold">{{ getProjectLocalBudget?.cost_centre }}</p>
          </v-col>
        </v-row>
      </div>
      <div class="px-5 py-4 bg-white border rounded-lg mt-7">
        <v-row class="">
          <v-col cols="3">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t("projects.bill") }}
              <br />
            </p>
            <p class="font-bold mt-n4 ml-n3 w-[200px]"><app-select-field-object @change="handleOtherInputData()" :disabled="!isEditButton" :placeholder="$t('projects.selectBillingType')" v-model="bill" :isTranslation="true" :options="billOptions" :class="'border-none rounded shadow-none'"></app-select-field-object></p>
          </v-col>
          <v-col cols="3">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t("projects.inputLocally") }}
              <br />
            </p>
            <p class="mt-2 font-bold"><app-toggle-button :disabled="!isEditButton"  @change="handleOtherInputData()"  v-model="inputLocally"></app-toggle-button></p>
          </v-col>
          <v-col cols="3">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t('projects.subBudgetForPMLocal') }}
              <br />
            </p>
            <p class="mt-2 font-bold"><app-toggle-button :disabled="!isEditButton"  @change="handleOtherInputData()" v-model="subBudget"></app-toggle-button></p>
          </v-col>
          <v-col cols="3">
            <p class="text-[14px] text-[#667085] font-medium">
              {{ $t('projects.PMLocalDivision') }}
              <br />
            </p>
            <p class="mt-2 font-bold"><app-toggle-button :disabled="!isEditButton"  @change="handleOtherInputData()" v-model="division"></app-toggle-button></p>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-sheet
          max-width="" 
          class="px-3 pt-4 pb-4 mt-10 mb-6 bg-white border rounded-lg w-fit"
        >
          <v-row class="justify-space-between">
            <v-col class="d-flex align-center">
              <div class="d-flex align-center">
                <div
                  class="w-full py-1 px-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
                >
                {{$t('projects.allLocalBudgets')}}
                </div>
              </div>
            </v-col>
          </v-row>
          <div
            class="w-full mt-2 mb-3 table-container"
          >
            <table
              class="w-full border border-collapse border-slate-400"
              aria-describedby=""
            >
              <thead>
                <tr>
                  <th
                    class="text-left border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    rowspan="2"
                  >
                    {{ $t("projectBudget.position") }}
                  </th>
                  <th
                    class="text-left border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    rowspan="2"
                  >
                    {{ $t("projects.account") }}
                  </th>
                  <th
                    class="text-left border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    rowspan="2"
                  >
                    {{ $t("projects.noOfUnits") }}
                  </th>
                  <th
                    class="text-left border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    rowspan="2"
                  >
                    {{ $t("projects.amountPerUnit") }} ({{ getProjectLocalBudget?.currency }})
                  </th>
                  <th
                    class="text-left border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    rowspan="2"
                  >
                    {{ $t("projectBudget.total") }}
                  </th>
                  <th
                    class="text-center border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                    colspan="2"
                    v-for="office in getProjectLocalBudget?.local_budgets[0]?.offices"
                    :key="office"
                  >
                    {{ office.country }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(header, index) in alternatingHeaders"
                    :key="index"
                    class="text-center border border-slate-300 px-4 py-2 bg-[#F2F4F7] capitalize"
                  >
                    {{ $t("projects."+header) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(local_budget, index) in getProjectLocalBudget.local_budgets" :key="local_budget"
                  :class="[
                        {
                          'bg-[#FEFBE8]':
                          local_budget?.structure === 'BOP - Fixed Fee',
                        },
                        {
                          'bg-[#F2F4F7]':
                          local_budget?.structure ===
                            'BOP - Against Receipt',
                        },
                        {
                          'bg-[#F4EAE9]':
                          local_budget?.position == 'Backstopping' ||
                          local_budget?.position == 'Bank guarantee',
                        },
                    ]"
                >
                  <td class="px-4 py-2 border border-slate-300">{{ local_budget?.position }}</td>
                  <td class="px-4 py-2 border border-slate-300">
                    {{ local_budget?.account }}
                  </td>
                  <td class="px-4 py-2 border border-slate-300">{{ local_budget?.number_of_units }}</td>
                  <td class="px-4 py-2 text-center border border-slate-300">
                    {{ local_budget?.amount_per_unit }}
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-300">
                    {{ local_budget?.total_amount }}
                  </td>

                  <td 
                    class="text-right border border-slate-300"
                    v-for="(header, ind) in alternatingHeaders"
                    :key="ind"
                  >
                    <input
                        :disabled="!isEditButton || header == 'amount' || local_budget.offices[Math.floor(ind / 2)]['country'] == 'Main Office : Cologne'"
                        class="w-[150px] py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
                        :value="local_budget.offices[Math.floor(ind / 2)][header]"
                        @input="handleInputData(
                          $event, 
                          local_budget.offices[Math.floor(ind / 2)].amount, 
                          local_budget.id,
                          local_budget.offices[Math.floor(ind / 2)].local_budget_id, 
                          index, 
                          ind
                        )"
                        pattern="[0-9]*"
                      />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-[14px] text-[#1D2939] text-right mr-8 font-bold"></p>
        </v-sheet>

      </div>
    </div>
   
  </div>
  
</template>
<script lang="js">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
    name: "ProjectLocalBudget",
    components: {},
    data() {
        return { 
          bill: '',
          showSaveCancelButton: false,
          division: false,
          subBudget: false,
          inputLocally: false,
          billOptions: [
            {
              id:"Cash and Bank",
              value: "Cash and Bank"           
            },
            {
              id:"Checkout only",
              value: "Checkout only"           
            },
          ],
          requestBody: {},
          local_budgets: [],
          offices: [],
        }
    },
    computed: {
      ...mapGetters("projectLocalBudgetModule", ["getError", "getResponse", "getAppLoading", "getProjectLocalBudget", "getProjectLocalBudgetData", "getAmountPerUnitArr"]),
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      isProjectID() {
        return this.$store.getters["projectModules/isProjectID"];
      },
      isProjectCurrency() {
        return this.$store.getters["projectModules/isCurrentProject"];
      },
      numberOfOffices() {
        // return this.getProjectLocalBudget?.offices?.length
        return this.getProjectLocalBudget?.local_budgets[0]?.offices?.length
      },
      numberOfHeaderQuantityAndAmount() {
        return this.numberOfOffices*2
      },
      alternatingHeaders() {
        const headers = [];
        for (let i = 0; i < this.numberOfHeaderQuantityAndAmount; i++) {
          if (i % 2 === 0) {
            headers.push("quantity");
          } else {
            headers.push("amount");
          }
        }
        return headers;
      },
    },
    watch: {},
    methods: {
      ...mapActions("projectLocalBudgetModule", ["fetchAllLocalBudget", "updateLocalBudget"]),
      closeDialog() {
        this.$refs.editBudgetDetails.showDialog = false;
      },

      showEditBudgetDetails() {
        this.$refs.editBudgetDetails.showDialog = true;
      },
      showSaveCancel() {
        this.showSaveCancelButton = true
        this.$refs.editBudgetDetails.showDialog = false;
      },
      hideSaveCancel() {
        this.showSaveCancelButton = false
      },
      getOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      },
      formatDate(inputDateStr) {
        const inputDate = new Date(inputDateStr);
        
        const monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];
        
        const day = inputDate.getDate();
        const month = monthNames[inputDate.getMonth()];
        const year = inputDate.getFullYear();
        const ordinalSuffix = this.getOrdinalSuffix(day);
        
        return `${day}${ordinalSuffix} ${month}, ${year}`;
      },

      calculate(event, local_budgets, rowIndex) {
        local_budgets?.forEach(local_budget => {
          local_budget.offices.forEach(office => {
             office.amount = office?.quantity * this.getAmountPerUnitArr[office?.rowIndex]
          });
         
        });

        // //reassign calculated values
        this.$store.commit("projectLocalBudgetModule/RE_SET_PROJECT_LOCAL_BUDGETS");
        let sum = 0;
       
        let total_amount = this.getProjectLocalBudget.local_budgets[rowIndex].total_amount
        let total_number_of_units = this.getProjectLocalBudget.local_budgets[rowIndex].number_of_units 
        let main_office_quantity = 0
        let offices = this.getProjectLocalBudget.local_budgets[rowIndex].offices
        let main_office = {}
        const main_office_name = "Main Office : Cologne"

        let index  = offices?.findIndex(office => office.country === main_office_name);

        if (index !== -1) {
          main_office = offices[index];
          let sum_of_office_quantity_without_main_office = 0

          for (const item of this.getProjectLocalBudget.local_budgets[rowIndex].offices) {
            
            if (item.country !== main_office_name) {
              sum_of_office_quantity_without_main_office += item.quantity
            }
            
          }
          main_office_quantity = total_number_of_units - sum_of_office_quantity_without_main_office
          main_office.quantity = main_office_quantity
          main_office.amount = main_office_quantity * this.getAmountPerUnitArr[rowIndex]

        }

        for (const item of this.getProjectLocalBudget.local_budgets[rowIndex].offices) {
            sum += item.amount; 
        }

        let tr = event.target.parentNode.parentNode;

        if (sum.toFixed(2) > total_amount) {
          tr?.classList.add("red-border");
        }

        if (main_office_quantity < 0) {
          tr?.classList.add("red-border");
        }
      },

      handleInputData(event, amount, budgetItemId, localBudgetId, rowIndex, columnIndex) {
        let quantity = event.target.value || 0;

        let tr = event.target.parentNode.parentNode; //select table row
        if (tr.classList.contains("red-border")) {
          tr.classList.remove("red-border");
        }

        if(isNaN(parseFloat(quantity))) {
          event.target.value = 0
        }

        this.requestBody = {
            bill: this.bill,
            input_locally : this.inputLocally,
            create_sub_budget_for_pm_local: this.subBudget,
            change_pm_local_division: this.division,
            local_budgets: []
        }

        let local_budget = {
          budget_item_id: budgetItemId,
          offices: []
        }

        let office = {
          local_budget_id: localBudgetId,
          quantity: isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity),
          amount: amount,
          rowIndex: rowIndex
        }

        let budget_index = this.local_budgets?.findIndex(loc_budget => loc_budget.budget_item_id == budgetItemId)

        if (budget_index !== -1) {
          let local_budget_index = this.local_budgets[budget_index].offices.findIndex(item => item.local_budget_id === office.local_budget_id);
          if (local_budget_index !== -1) {
            this.local_budgets[budget_index].offices[local_budget_index] = office;
          } else {
            this.local_budgets[budget_index].offices.push(office);
          }
        } else {
          // If the object with the specified id is not found, you can choose to add it to the array.
          local_budget.offices.push(office);
          this.local_budgets.push(local_budget);
        }


        this.requestBody["local_budgets"] = [...this.local_budgets]
        this.$store.commit("projectLocalBudgetModule/SET_ALL_PROJECT_LOCAL_BUDGET_DATA", this.requestBody);

        const updatedLocalBudgets = this.getProjectLocalBudgetData?.local_budgets
        this.calculate(event, updatedLocalBudgets, rowIndex)

      },

      handleOtherInputData() {
       
        this.requestBody = {
            bill: this.bill,
            input_locally : this.inputLocally,
            create_sub_budget_for_pm_local: this.subBudget,
            change_pm_local_division: this.division,
            local_budgets: [ ...this.getProjectLocalBudgetData.local_budgets ]
        }

        this.$store.commit("projectLocalBudgetModule/SET_ALL_PROJECT_LOCAL_BUDGET_DATA", this.requestBody);
      }
    },
    async created(){
        this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 5);
        this.$store.commit("auth/SET_RAIL_TO_STATE", true);
        await this.fetchAllLocalBudget({project : this.isProjectID})
        this.bill = this.getProjectLocalBudget.bill
        this.inputLocally = this.getProjectLocalBudget.input_locally
        this.subBudget = this.getProjectLocalBudget.create_sub_budget_for_pm_local
        this.division = this.getProjectLocalBudget.change_pm_local_division
    },
    mounted() {
    },
})
</script>
<style scoped>
.scrollable-content {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  height: 100vh; /* Set the height to 100% of the viewport height */
}

.scrollable-inner {
  overflow-x: auto; /* Hide the vertical scrollbar */
  white-space: nowrap;
  height: 66%; /* Set the height to 100% of the viewport height */
}
/* width */
.scrollable-inner::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
}

/* Track */
.scrollable-inner::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.scrollable-inner::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px; 
}

.table-container {
  /* overflow: auto;
  width: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none; */
}

.notice{
  background-color: #fdf6ec;
  border-radius: 10px;
  color: #CA8504;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.red-border {
  border: 2px solid #fdc6c2;
}
</style>
