<template>
  <div
    v-if="appLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <v-sheet v-else class="bg-transparent" max-width="1440">
    <div v-for="(accordion, index) of filterAccordion" :key="index">
      <app-accordion :accordionInfo="accordion">
        <template #content>
          <component :is="accordion.component"></component>
        </template>
      </app-accordion>
    </div>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import ContractDetails from "./ContractDetails.vue";
import BankGuarantee from "./BankGuarantee.vue";
import ContractAddenda from "./ContractAddenda.vue";
import Partners from "./Partners.vue";
import AdvancePayments from "@/components/dashboard/projects/AdvancePayments.vue";
import InterimPayments from "@/components/dashboard/projects/InterimPayements.vue";
import ProjectNote from "@/components/dashboard/projects/ProjectNote.vue";
import ProjectForeignEstablishment from "./ProjectForeignEstablishment.vue";

export default defineComponent({
  name: "ProjectContract",
  components: {
    ContractDetails,
    ProjectForeignEstablishment,
    BankGuarantee,
    ContractAddenda,
    Partners,
    AdvancePayments,
    InterimPayments,
    ProjectNote,
  },
  props: {},
  data() {
    return {
      accordionInfo: {
        ContractDetails: {
          name: "Contract Details",
          component: ContractDetails,
        },
        ProjectForeignEstablishment: {
          name: "Foreign Establishment",
          component: ProjectForeignEstablishment,
        },
        ContractAddenda: {
          name: "Contract Addenda",
          component: ContractAddenda,
        },
        Partners: {
          name: "Partners",
          component: Partners,
        },
        BankGuarantee: {
          name: "Bank Guarantee",
          component: BankGuarantee,
        },
        AdvancePayments: {
          name: "Advance Payments",
          component: AdvancePayments,
        },
        InterimPayments: {
          name: "Interim Payments",
          component: InterimPayments,
        },
        ProjectNote: {
          name: "Project Note",
          component: ProjectNote,
        },
      },
      excluded: {
        ContractDetails: {
          name: "Contract Details",
          component: ContractDetails,
        },
        
      },
    };
  },
  methods: {},
  computed: {
    appLoading() {
      return this.$store.getters["projectModules/isAppLoading"];
    },
    projectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    contractID() {
      return this.$store.getters["projectModules/isContractId"];
    },
    filterAccordion() {
      return this.contractID ? this.accordionInfo : this.excluded;
    },
    isForeignEstablishment() {
      return this.$store.getters["projectModules/isForeignEstablishment"];
    },
  },
  created() {
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    const firstAccordionKey = Object.keys(this.accordionInfo)[0];
    this.accordionInfo[firstAccordionKey].showAccordionContent = true;
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    const payload = {
      projectID: this.projectID,
      load_page: true,
    };
    this.$store.dispatch("projectModules/getContractDetails", payload);
  },
});
</script>

<style></style>
