<template>
  <div>
    <app-header
      :name="$t('leaves.LeaveManagement')"
      class="mb-5"
      :show-arrow-back="false"
    >
    </app-header>
    <div class="mb-10">
      <div>
        <button
          v-for="tab in tabList"
          :key="tab.id"
          :class="['tab-button', { active: currentTab === tab.id }]"
          @click="currentTab = tab.id"
        >
          {{ $t(getTranslatedStringKey(tab.name)) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>

    <component :is="currentTab" v-bind="isCurrentTab"></component>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";
import PublicHolidays from "@/components/dashboard/hr-settings/PublicHolidays.vue";
import LeaveRequest from "@/components/dashboard/hrleave/LeaveRequests.vue";
import AllLeaves from "@/components/dashboard/hrleave/AllLeaves.vue";
import { extractModuleNames } from "@/services/permissions";
export default defineComponent({
  name: "HrLeave",
  components: {
    PublicHolidays,
    LeaveRequest,
    AllLeaves,
  },
  data() {
    return {
      currentTab: "",
      tabList: [],
      leave_Id: "",
    };
  },
  computed: {
    isLeaveId() {
      return this.$store.getters["hrResources/isLeaveId"];
    },
    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isCurrentTab() {
      return this.$store.getters["hrConfigurations/isCurrentTab"];
    },
    hrConfigurationLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    allowedRoutes() {
      const permissions = this.$store.getters["auth/permissions"];
      const extractedModuleNames = extractModuleNames(permissions);
      const tabList = [
        { id: "PublicHolidays", name: "Public Holidays" },
        { id: "LeaveRequest", name: "Leave Requests" },
        { id: "AllLeaves", name: "All Leaves" },
      ];
      return tabList.filter((elt) => extractedModuleNames.includes(elt.name));
    },
  },

  created() {
    this.tabList = this.allowedRoutes();

    if (this.isHome && this.tabList.length > 1) {
      this.currentTab = this.tabList[1].id;
    } else if (this.tabList.length > 0) {
      this.currentTab = this.tabList[0].id;
    }
  },
});
</script>

<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
.tab-button.disabled {
  cursor: not-allowed;
}
</style>
