<template>
  <div>
    <app-heading
      :title="$t('projectConfiguration.title')"
      class="mb-5"
    ></app-heading>
    <div class="demo">
      <div>
        <button
          v-for="(tab, index) in visibleTabs"
          :key="index"
          :class="['tab-button', { active: currentTab === tabs[index] }]"
          @click="handleProceed(index)"
        >
          {{ $t(getTranslatedStringKey(tab)) }}
        </button>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              elevation="0"
              v-bind="props"
              class="!normal-case bg-transparent"
            >
              <div class="text-[#667085] text-[15px]">
                {{ $t("hrconfiguration.more") }}
              </div>
              <v-icon right class="mt-1 ml-1 text-[#667085]"
                >mdi-chevron-down</v-icon
              >
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(tab, index) in dropdownTabs"
              :key="index"
              @click="handleDropdownTabClick(index)"
            >
              <v-list-item-title class="text-[#344054]">{{
                $t(getTranslatedStringKey(tab))
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="mt-2 border-t-[1px]"></div>
      <General
        :is="currentTab"
        tabType="projectConfig"
        v-bind="isCurrentTab"
        type="tab"
        class="pt-10"
        :tabsInfo="tabsInfo[currentTab]"
        v-if="!excluded.includes(currentTab)"
      ></General>
      <DefaultCollaborators
        :is="currentTab"
        type="tab"
        class="pt-10"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'DefaultCollaborators'"
      ></DefaultCollaborators>
      <ResponsibleHR
        :is="currentTab"
        type="tab"
        class="pt-10"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'ResponsibleHR'"
      ></ResponsibleHR>
      <CostCenters
        :is="currentTab"
        type="tab"
        class="pt-10"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'CostCenters'"
      ></CostCenters>
      <BankDetails
        :is="currentTab"
        type="tab"
        class="pt-10"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'BankDetails'"
      ></BankDetails>
      <ContractTasks
        :is="currentTab"
        type="tab"
        class="pt-5"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'ContractTasks'"
      ></ContractTasks>
      <ContractApprovers
        :is="currentTab"
        type="tab"
        class="pt-5"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'ContractApprovers'"
      ></ContractApprovers>
      <DoubleTaxation
        :is="currentTab"
        type="tab"
        class="pt-5"
        v-bind="isCurrentTab"
        v-else-if="currentTab === 'DoubleTaxation'"
      ></DoubleTaxation>
    </div>
    <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="closeDialog"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="discardChanges"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-unsaved-changes-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import AppHeading from "@/components/shared/AppHeading.vue";
import DefaultCollaborators from "@/components/dashboard/hr-settings/DefaultCollaborators.vue";
import General from "@/components/dashboard/hr-settings/General.vue";
import ResponsibleHR from "@/components/dashboard/hr-settings/ResponsibleHR.vue"
import CostCenters from "@/components/dashboard/hr-settings/CostCenters.vue";
import BankDetails from "@/components/dashboard/hr-settings/BankDetails.vue";
import ContractTasks from "@/components/dashboard/hr-settings/ContractTasks.vue";
import ContractApprovers from "@/components/dashboard/hr-settings/ContractApprovers.vue";
import DoubleTaxation from "@/components/dashboard/hr-settings/DoubleTaxation.vue";
import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";



export default defineComponent({
  name: "ProjectConfiguration",
  components: {
    AppHeading,
    DefaultCollaborators,
    General,
    ResponsibleHR,
    CostCenters,
    BankDetails,
    ContractTasks,
    ContractApprovers,
    DoubleTaxation
  },
  data() {
    return {
      showHiddenTabs: true,
      currentTab: "",
      tabList: [
        "Business Areas",
        "ICON Category",
        "Default Collaborators",
        "Responsible HR",
        "Cost Centers",
        "Bank Details",
        "Contract Tasks",
        "Contract Approvers",
        "Double Taxation"

      ],
      tabs: [
        "BusinessAreas",
        "IconCategory",
        "DefaultCollaborators",
        "ResponsibleHR",
        "CostCenters",
        "BankDetails",
        "ContractTasks",
        "ContractApprovers",
        "DoubleTaxation"
      ],
      tabsInfo: {
        BusinessAreas: {
          name: "Business Areas",
          routeIndex: 0,
          singular: "Business Area",
          getter: "isBusinessArea",
          setter: "SET_BUSINESS_AREA_TO_STATE"
        },
        IconCategory: {
          name: "ICON Category",
          singular: "ICON Category",
          routeIndex: 1,
          getter: "isIconCategory",
          setter: "SET_ICON_CATEGORY_TO_STATE"
        },
      },
      excluded: [
        "DefaultCollaborators",
        "ResponsibleHR",
        "BankDetails",
        "CostCenters",
        "ContractTasks",
        "ContractApprovers",
        "DoubleTaxation"
      ]
    };
  },

  computed: {
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    hrConfigurationLang() {
      return this.translation.locale;
    },
    visibleTabs() {
      return this.tabList.slice(0, 6);
    },
    dropdownTabs() {
      return this.tabList.slice(6);
    },
    ...mapState(["translation"]),
    isConfigurationsUpdate() {
      return this.$store.getters["projectConfigurations/isConfigurationsUpdate"];
    },
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    handleDropdownTabClick(index) {
      this.currentTab = this.tabs[this.visibleTabs.length + index];
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isConfigurationsUpdate).some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      const currentTabStatus = this.currentTab;
      if (currentTabStatus === "ContractApprovers" && this?.isConfigurationsUpdate?.projectConfiguration) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.isConfigurationsUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrConfigurations/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    handleGoToBack() {
      if (Object.values(this.isConfigurationsUpdate).some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isConfigurationsUpdate).reduce(
        (acc, curr) => {
          if (this.isConfigurationsUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("projectConfigurations/SET_CONFIGURATIONS_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },

  created() {
    this.currentTab = this.tabs[this.isCurrentTab];
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  mounted() {
    this.$refs.unsavedChanges.showDialog = false;
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>
<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
</style>
