<template>
  <v-sheet class="bg-transparent">
    <v-row>
      <v-col cols="12" class="bg-transparent">
        <span>
          <slot name="icon"></slot>
        </span>
        <h1 class="text-[1.875rem] text-[#101828] font-medium">{{ title }}</h1>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App heading",
  props: {
    title: {
      type: String,
    },
  },
});
</script>
