<template>
  <v-sheet class="bg-transparent" max-width="1440">
    <div v-for="(accordion, index) of accordionInfo" :key="index">
      <app-accordion
        :accordionInfo="accordion"
        v-if="shouldShowAccordion(accordion)"
      >
        <template #content>
          <component :is="accordion.component"></component>
        </template>
      </app-accordion>
    </div>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import Leaves from "@/components/dashboard/hrleave/Leaves.vue";
import Absence from "@/components/dashboard/employees/Absence.vue";
import LeaveConfiguations from "../hrleave/LeaveConfiguations.vue";
export default defineComponent({
  name: "EmployeeTimeOff",
  components: {
    Leaves,
    Absence,
    LeaveConfiguations,
  },
  props: {},
  data() {
    return {
      accordionInfo: {
        LeaveConfiguations: {
          name: "Leave Settings",
          component: LeaveConfiguations,
        },
        PersonalLeaves: {
          name: "Leaves",
          component: Leaves,
        },
        Absence: {
          name: "Absence",
          component: Absence,
        },
      },
    };
  },
  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    isHr() {
      return this.$store.getters["auth/isLeaveRole"];
    },
  },
  methods: {
    shouldShowAccordion(accordion) {
      const userIsAdmin = this.role || this.isHr;
      return userIsAdmin || accordion !== this.accordionInfo.LeaveConfiguations;
    },
  },

  created() {
    const timeOffAccordionKey = Object.keys(this.accordionInfo)[0];
    this.accordionInfo[timeOffAccordionKey].showAccordionContent = true;
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
  },
});
</script>

<style></style>
