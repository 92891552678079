<template>
  <v-app>
    <!-- <app-sidebar /> -->
    <app-navbar />
    <v-main class="">
      <div v-show="showClosedProject" class="mx-[0.8rem] mt-4 mb-5">
        <display-closed-project></display-closed-project>
      </div>
      <ContractTerminated v-show="contractTerminated" />
      <!-- @slot Use this slot to display dashboard content -->
      <div class="mx-8 mt-7">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script lang="js">
import { defineComponent } from "vue";
// import AppSidebar from "@/components/shared/AppSidebar.vue";
import AppNavbar from "@/components/shared/AppNavbar.vue";
import DisplayClosedProject from "@/components/dashboard/projects/DisplayClosedProject.vue";
import ContractTerminated from "../dashboard/employees/ContractTerminated.vue";
export default defineComponent({
  name: "Dashboard Layout",
  components: {
    AppNavbar,
    // AppSidebar,
    DisplayClosedProject,
    ContractTerminated
  },
  data(){
    return{

    }
  },
  computed:{
    showClosedProject(){
      const closedProject = this.$store.getters["projectModules/isCurrentProject"]
      const checkStatus = this.$route.path=== "/edit-project" && closedProject.project_status === 'Closed'
      if(checkStatus){
        this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
      }
      return  checkStatus
    },
    contractTerminated(){
     const terminated = this.$store.getters["hrResources/isPersonalData"];
      return this.$route.path === '/edit-employee' && terminated?.is_terminated === true ? true : false

    }
   //
  },

});
</script>
