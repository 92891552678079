<template>
  <div class="d-flex align-center">
    <div>
      <v-avatar :icon="selectedLanguage.image" size="24"> </v-avatar>
      <v-label class="ml-2 mr-1">{{ selectedLanguage.title }}</v-label>
    </div>
    <div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn color="#98A2B3" v-bind="props" icon="mdi-chevron-down">
          </v-btn>
        </template>

        <div>
          <v-list class="language-modal">
            <div v-if="appLocaleLang === 'en'">
              <v-list-item
                class="pr-n2 cursor-pointer hover:bg-gray-100 active:bg-[#EDDEDC]"
                v-for="(language, index) in languages"
                :key="index"
                :prepend-icon="language.image"
                @click="setLocaleAction(language)"
              >
                <v-list-item-title>{{ language.title }}</v-list-item-title>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item
                class="pr-n2 cursor-pointer hover:bg-gray-100 active:bg-[#EDDEDC]"
                v-for="(language, index) in deLanguages"
                :key="index"
                :prepend-icon="language.image"
                @click="setLocaleAction(language)"
              >
                <v-list-item-title>{{ language.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script lang="">
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default defineComponent({
  name: "App Locale",

  data() {
    return {
      languages: [
        {
          title: "English",
          locale: "en",
          image: "custom:englishIcon",
        },
        {
          title: "German",
          locale: "de",
          image: "custom:germanIcon",
        },
      ],
      deLanguages: [
        {
          title: "Englisch",
          locale: "en",
          image: "custom:englishIcon",
        },
        {
          title: "Deutsch",
          locale: "de",
          image: "custom:germanIcon",
        },
      ],
    };
  },

  computed: {
    selectedLanguage() {
      if (this.appLocaleLang === "en") {
        return this.languages.find((lang) => lang.locale === this.$i18n.locale);
      } else {
        return this.deLanguages.find(
          (lang) => lang.locale === this.$i18n.locale
        );
      }
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    setLocaleAction(language) {
      this.setLocale(language.locale);
    },
    ...mapActions("translation", ["setLocale"]),
  },
});
</script>
<style scoped>
.language-modal {
  position: relative;
  margin-left: -100px;
  height: 112px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
</style>
