<template>
  <div>
    <form class="mb-3">
      <div class="mb-8">
        <div v-for="(field, i) in tabsInfo.fields" class="mb-3" :key="i">
          <app-input-field
            v-if="field.fieldType === 'input'"
            id="1"
            :label="$t(getTranslatedStringKey(field.name))"
            :placeholder="$t(getTranslatedStringKey(field.placeholder))"
            v-model:modelValue="basicInfo[field.id]"
          ></app-input-field>
          <app-select-field
            v-else-if="field.fieldType === 'select'"
            v-model:modelValue="basicInfo[field.id]"
            :label="$t(getTranslatedStringKey(field.name))"
            :placeholder="$t(getTranslatedStringKey(field.placeholder))"
            :options="countryOptions"
          ></app-select-field>
        </div>
      </div>
      <div class="flex justify-end mb-7">
        <div class="flex gap-3">
          <div>
            <app-secondary-button
              :buttonLabel="$t('personal.cancel')"
              @click="cancel"
            >
            </app-secondary-button>
          </div>
          <div>
            <app-primary-button
              @click-event="savePartnerInfo()"
              :buttonLabel="
                tabsInfo?.tabId !== 2
                  ? $t('personal.next')
                  : $t('personal.finish')
              "
              :disabled="btnDisabled"
              :loading="loading"
            >
            </app-primary-button>
          </div>
        </div>
      </div>
      <!-- <div class="flex justify-end gap-7">
        <div>
          <app-secondary-button
            :buttonLabel="$t('personal.cancel')"
            @click="cancel"
          ></app-secondary-button>
        </div>
        <app-primary-button
          :buttonLabel="$t('employment.saveChanges')"
          @click-event="savePartnerInfo('edit')"
          :disabled="disableSaveButton()"
        >
        </app-primary-button>
      </div> -->
    </form>

    <!-- <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    /> -->
  </div>
  <div class="modal-board" v-if="showmodal && basicInfo.official_email === ''">
    <div class="flex justify-between">
      <h3 class="H400">We recommend that you provide an email</h3>
      <v-icon icon="custom:closeIcon" @click="showmodal = false" />
    </div>
    <p class="P300">
      Leaving the email blank means employees won’t receive any invitation
      email.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import countries from "@/services/country_api";
import { getTransString } from "@/services/helpers";
import { countryCitizens } from "@/services/citizen";
import Api from "@/services/api";
export default defineComponent({
  name: "Personal",
  components: {},
  props: {
    errors: {
      type: Boolean,
      default: true,
    },
    tabsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isEdit: false,
      loadUserKey: null,
      countryOptions: null,
      countryCitizensArray: [],
      loading: false,
      basicInfo: {},
      allUserRoles: [],
      page: 1,
      roles: [],
      showmodal: true,
      btnDisabled: true,
    };
  },
  computed: {
    isEditPartner() {
      return this.$store.getters["partnerModules/isEditPartner"];
    },
    isEditButton() {
      return this.$store.getters["partnerModules/isEditButton"];
    },
    isPartnerData() {
      return this.$store.getters["partnerModules/isPartnerInfo"];
    },
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    isTempClient() {
      return this.$store.getters["projectModules/isTempClient"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    basicInfo: {
      deep: true,
      handler() {
        this.disableSaveButton();
      },
    },
    tabsInfo: {
      deep: true,
      handler() {
        this.populateExistingData();
      },
    },
    isTempClient: {
      deep: true,
      handler(value) {
        this.basicInfo = value;
      },
    },
  },
  methods: {
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    disableSaveButton() {
      const personalFormObj = { ...this.basicInfo };
      const reqFields = this.tabsInfo.reqFields;
      const modifiedObject = Object.keys(personalFormObj).reduce(
        (prev, curr) => {
          if (!reqFields.includes(curr)) {
            return prev;
          } else {
            prev[curr] = personalFormObj[curr];
            return prev;
          }
        },
        {}
      );
      this.btnDisabled = Object.values(modifiedObject).some(
        (c) => c === "" || c === null
      );
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleNextClick() {
      this.$emit("next-clicked");
    },
    hideDialog() {
      this.$emit("hide-dialog", false);
    },
    populateExistingData() {
      const existingData = this.tabsInfo.fields.reduce((prev, curr) => {
        if (!Object.keys(this.basicInfo)?.includes(curr.id)) {
          prev[curr.id] = "";
        }
        return prev;
      }, {});

      this.basicInfo = { ...this.basicInfo, ...existingData };
    },
    cancel() {
      this.$emit("hide-dialog");
      this.basicInfo = {};
      this.$store.commit("projectModules/SET_TEMP_CLIENT_TO_STATE", {});
    },
    toggleModal() {
      this.showmodal = false;
    },
    savePartnerInfo() {
      this.loading = true;

      if (this.tabsInfo.tabId === 2) {
        Api()
          .post("/partners/add-new-partner/", this.basicInfo)
          .then((response) => {
            if (response.data.status === "success") {
              this.loading = false;
              this.basicInfo = {};
              this.$store.commit("projectModules/SET_TEMP_CLIENT_TO_STATE", {});
              this.cancel();
              this.$store.dispatch("expertModules/getAllPartners");
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      } else {
        this.loading = false;
        this.btnDisabled = true;
        this.$store.commit("projectModules/SET_TEMP_CLIENT_TO_STATE", {
          ...this.isTempClient,
          ...this.basicInfo,
        });
        this.$store.commit(
          "partnerModules/SET_CURRENT_TAB_TO_STATE",
          this.tabsInfo.tabId + 1
        );
      }
    },
  },
  mounted() {
    // Loop through the countryCitizens object
    for (const country in countryCitizens) {
      if (Object.prototype.hasOwnProperty.call(countryCitizens, country)) {
        const citizen = countryCitizens[country];
        this.countryCitizensArray.push({ id: citizen, value: citizen });
      }
    }

    this.countryOptions = this.getCountryByName();
    // this.basicInfo = {};
    this.populateExistingData();
    // this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
  },
  created() {
    this.$store.commit(
      "partnerModules/SET_EDIT_PARTNER_TO_STATE",
      this.isPartnerId !== null
    );
    this.isEdit = this.isEditPartner;
    this.$store.commit(
      "partnerModules/SET_CURRENT_TAB_TO_STATE",
      this.tabsInfo?.tabId
    );
  },
});
</script>

<style scoped>
.select-field {
  width: 440px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-board {
  position: fixed;
  top: 90%;
  left: 85%;
  transform: translate(-50%, -50%);
  right: 0;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 396px;
  height: 98px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}
.disabled {
  cursor: not-allowed;
  color: gray;
  opacity: 0.5;
}
</style>
