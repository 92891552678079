<template>
  <svg
    width="160"
    height="120"
    viewBox="0 0 160 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="82" cy="52" r="52" fill="#EAECF0" />
    <g filter="url(#filter0_dd_1841_34546)">
      <path
        d="M47.7267 79.11L80.9652 63.6107C82.634 62.8325 83.3561 60.8488 82.5779 59.1799L62.9486 17.0848L49.6564 12.2468L22.4613 24.9281C20.7924 25.7063 20.0704 27.69 20.8486 29.3588L43.296 77.4974C44.0742 79.1662 46.0579 79.8882 47.7267 79.11Z"
        fill="url(#paint0_linear_1841_34546)"
      />
      <path
        d="M49.657 12.2471L62.9491 17.085L53.8841 21.3121L49.657 12.2471Z"
        fill="#D0D5DD"
      />
    </g>
    <g filter="url(#filter1_dd_1841_34546)">
      <path
        d="M63.616 67.7831H100.291C102.132 67.7831 103.625 66.2904 103.625 64.4491V18.0022L93.6226 8H63.616C61.7747 8 60.282 9.49271 60.282 11.3341V64.4491C60.282 66.2904 61.7747 67.7831 63.616 67.7831Z"
        fill="url(#paint1_linear_1841_34546)"
      />
      <path d="M93.6226 8L103.625 18.0022H93.6226V8Z" fill="#D0D5DD" />
    </g>
    <g filter="url(#filter2_dd_1841_34546)">
      <path
        d="M82.4745 63.5909L115.713 79.0903C117.382 79.8685 119.366 79.1465 120.144 77.4777L139.773 35.3825L134.935 22.0903L107.74 9.40903C106.071 8.63085 104.087 9.35286 103.309 11.0217L80.8619 59.1602C80.0837 60.8291 80.8057 62.8128 82.4745 63.5909Z"
        fill="url(#paint2_linear_1841_34546)"
      />
      <path
        d="M134.936 22.0903L139.773 35.3825L130.708 31.1554L134.936 22.0903Z"
        fill="#D0D5DD"
      />
    </g>
    <circle cx="27" cy="11" r="5" fill="#F2F4F7" />
    <circle cx="24" cy="109" r="7" fill="#F2F4F7" />
    <circle cx="151" cy="35" r="7" fill="#F2F4F7" />
    <circle cx="140" cy="8" r="4" fill="#F2F4F7" />
    <g filter="url(#filter3_b_1841_34546)">
      <rect
        x="58"
        y="62"
        width="48"
        height="48"
        rx="24"
        fill="#344054"
        fill-opacity="0.4"
      />
      <path
        d="M92 93C92 93.5304 91.7893 94.0391 91.4142 94.4142C91.0391 94.7893 90.5304 95 90 95H74C73.4696 95 72.9609 94.7893 72.5858 94.4142C72.2107 94.0391 72 93.5304 72 93V79C72 78.4696 72.2107 77.9609 72.5858 77.5858C72.9609 77.2107 73.4696 77 74 77H79L81 80H90C90.5304 80 91.0391 80.2107 91.4142 80.5858C91.7893 80.9609 92 81.4696 92 82V93Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_1841_34546"
        x="-0.560425"
        y="8.01978"
        width="104.547"
        height="112.499"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1841_34546"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1841_34546"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1841_34546"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1841_34546"
          result="effect2_dropShadow_1841_34546"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1841_34546"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_1841_34546"
        x="40.282"
        y="8"
        width="83.3428"
        height="99.7832"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1841_34546"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1841_34546"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1841_34546"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1841_34546"
          result="effect2_dropShadow_1841_34546"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1841_34546"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_1841_34546"
        x="59.4529"
        y="8"
        width="104.547"
        height="112.499"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_1841_34546"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1841_34546"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_1841_34546"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1841_34546"
          result="effect2_dropShadow_1841_34546"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1841_34546"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_1841_34546"
        x="50"
        y="54"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1841_34546"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1841_34546"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1841_34546"
        x1="45.7739"
        y1="79.2398"
        x2="19.2855"
        y2="31.4525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1841_34546"
        x1="61.7914"
        y1="67.0755"
        x2="57.9804"
        y2="12.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1841_34546"
        x1="81.1199"
        y1="62.1785"
        x2="100.701"
        y2="11.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
