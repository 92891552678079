<template>
  <v-sheet max-width="" class="bg-transparent">
    <!-- end of parent-module -->
    <app-dialog
      ref="dialog"
      :title="
        isEdit ? $t('projects.editFilesTitle') : $t('projects.addFilesTitle')
      "
      subtitle=""
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          class="mb-2"
          :label="$t('projects.fileTitleLabel')"
          :placeholder="$t('projects.enterFileTitle')"
          v-model.trim="payload.file_title"
        ></app-input-field>

        <app-select-field-object
          :label="$t('projects.fileType')"
          placeholder="Select file type"
          :options="fileTypeOptions"
          v-model="payload.file_type"
          :hasError="hasError"
          :isRequired="true"
        ></app-select-field-object>
        <!-- drag and drop -->
        <div
          v-if="!isEdit"
          :class="{ '!border-red-500': payload.file_url ? false : hasError }"
          class="drag-window py-5 px-8 mt-6 text-center text-[#667085] !rounded-lg border border-gray-200 cursor-pointer w-full h-full"
          @dragover="dragover"
          @drop.prevent
          @drop="dragFile"
          @click="pickFile"
          :hasError="isFieldInvalid('file_url')"
          :isRequired="true"
        >
          <span>
            <v-icon size="x-large" icon="custom:uploadIcon"></v-icon>
          </span>
          <input
            ref="file"
            @change="selectedFile"
            type="file"
            accept="*"
            hidden
            multiple
          />
          <p class="!font-inter !font-medium !text-[0.875rem] text-[#A2544F]">
            {{ $t("projects.clickToUpload") }}
          </p>
          <p
            class="mt-2 !font-inter !font-normal !text-[0.5625rem] text-[#667085]"
          >
            {{ $t("projects.acceptedFiles") }}
          </p>
        </div>
        <div v-if="!isEdit">
          <p
            v-if="payload.file_url ? false : hasError"
            class="text-red-500 text-[14px]"
          >
            {{ $t(`${errorMessage.getTranslationKey()}`) }}
          </p>
        </div>
        <!-- <p v-if="hasError" class="text-red-500 text-[14px]">{{ errorMessage}}</p> -->

        <div v-if="isEdit">
          <div
            class="border w-full h-[72px] mt-6 rounded-lg p-4 justify-between text-[#344054] font-semibold text-sm"
          >
            <div class="d-flex justify-between align-center">
              <p class="truncate">{{ fileName }}</p>
              <v-icon icon="mdi:mdi-check-circle" color="#AE6A65"></v-icon>
            </div>
            <p class="font-normal">{{ fileSize }}</p>
          </div>
        </div>

        <div v-if="uploading">
          <div v-for="(singleUpload, index) of uploadedFiles" :key="index">
            <div
              class="p-4 mt-6 text-[#667085] !rounded-lg border border-gray-200 w-full h-full"
            >
              <v-row v-show="!isEdit">
                <v-col cols="10">
                  <p
                    class="!font-inter !font-medium !text-[0.875rem] text-[#344054]"
                    @click="pickFile"
                  >
                    {{ singleUpload?.name }}
                  </p>
                  <p
                    class="mt-0 !font-inter !font-normal !text-[0.875rem] text-[#667085]"
                  >
                    {{ singleUpload?.size }} - {{ singleUpload?.progress }}%
                    {{
                      singleUpload?.progress === 100
                        ? $t("projects.fileUploaded")
                        : $t("projects.fileUploading")
                    }}
                  </p>
                </v-col>
                <v-col cols="2" class="ml-auto">
                  <v-icon
                    v-if="singleUpload?.progress === 100"
                    icon="mdi:mdi-check-circle"
                    color="#AE6A65"
                  ></v-icon>
                  <v-progress-circular
                    v-else
                    :model-value="singleUpload?.progress"
                    :width="3"
                    color="#AE6A65"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <app-input-field
          class="mt-3"
          :label="$t('projects.filePath')"
          :placeholder="$t('projects.filePath')"
          v-model="payload.file_path"
        ></app-input-field>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :loading="isLoading"
              :buttonLabel="
                isEdit
                  ? $t('addcustomrole.saveChanges')
                  : $t('projects.addFile')
              "
              @click-event="isEdit ? editProjectFile() : createProjectFile()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import { getFileNameFromURL, formatBytes, getTransString  } from '@/services/helpers'
import Queue from '@/services/Queue'
import UploadApi from '@/services/fileUploadApi'
import Api from '@/services/api'

export default defineComponent({
  props: {

      isDialog:{
          type: Boolean,
          default: true
      },
      filePropTitle: {
        type: String
      },
      filePropType: {
        type: String
      },
      filePropPath: {
        type: String
      },
      isEditProp: {
        type: Boolean,
        default: false
      },
      fileNameProp:{
        type: String,
        default: "Tech requirement"
      },
      fileSize:{
        type: String,
      },
      editFileEventProp: {
        type: Function
      }


  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: false,
      count: 0,
      payload: {
        file_title: '',
        file_type: '',
        file_url: '',
        file_path: '',
      },
      fileName: "",
      uploading: false,
      uploadedFiles: [],
      queueUpload: new Queue(),
      queueSize: null,
      lastItem: null,
      isEdit: false,
      filesDetails: [],
      editObject: {},
      requiredFields: ['file_type','file_url'],
      isDirty: [],
      hasError: false,
      errorMessage: "This field is required"
    }
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFile()
      }
    },

  },
  computed: {
    // isDisabled(){
    //   return this.payload.file_title !== '' && this.payload.file_type !== '' && this.lastItem?.progress === 100 ? false : true
    // },
    // displayError(){
    //   return this.payload.file_url?
    // },
    fileTypeOptions(){
      const data =['Tender', 'Budget', 'Proposal', 'Clients', 'Partners', 'Experts','Calculations', 'Addendum', 'Others']
      return data?.convertArrayToObject();
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {

      return getTransString(field);
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation(){
    this.hasError = false
    const filteredFields = this.isEdit? this.requiredFields?.filter(field => field !== 'file_url'): this.requiredFields;
    Object.keys(this.payload).forEach((key) => {
        if (filteredFields.includes(key) && (this.payload[key] === "" ||  this.payload[key] === undefined || this.payload[key] === null)) {
          this.isDirty.push(key);
          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {
          return this.hasError;
        }
  },
  checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
      // this.isDirty= []
    },
     // show add or create mode for file
    showDialog() {
      this.$refs.dialog.showDialog = true
    },
    // show edit mode for file
    showEditDialog() {
      this.$refs.dialog.showDialog = true
      this.payload.file_title= this.editObject.title
      this.payload.file_type= this.editObject.file_type
      this.payload.file_path= this.editObject.file_path
      this.fileName = getFileNameFromURL(this.editObject.file_url)
    },
    // show edit mode for file
    hideDialog() {
      this.$refs.dialog.showDialog = false
      this.uploadedFiles = []
      this.payload.file_title= ''
      this.payload.file_type= ''
      this.payload.file_path= ''
      this.hasError = false
      this.filesDetails =[]
    },
    // select file
    pickFile() {
      this.$refs.file.click()
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('ae-bg-green-300')) {
        event.currentTarget.classList.remove('drag-window')
        event.currentTarget.classList.add('ae-bg-green-300')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('ae-bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      this.filelist = [...event.dataTransfer.files]

    },
    dragFile(event) {
      this.uploading = true
      // this.uploadedFiles = []
      this.filelist = ''
      event.preventDefault()
      event.currentTarget.classList.remove('ae-bg-green-300')
      event.currentTarget.classList.add('drag-window')
      this.filelist = [...event.dataTransfer.files]

      this.validateAndInitialUpload(this.filelist)

    },
    selectedFile(event) {
      this.uploading = true
      // this.uploadedFiles = []
      this.filelist = ''
      this.filelist = [...event.target.files]
      this.validateAndInitialUpload(this.filelist)
    },

    validateAndInitialUpload(){

      for (let i = 0; i < this.filelist.length; i++) {
        const file = this.filelist[i];
        this.queueUpload.enqueue(file)
        this.queueSize = this.queueUpload.getSize()

        if(file.size > 16777216) {
          this.$refs.dialog.showDialog = !this.isDialog
          this.isLoading = false
          this.alertState= true
          this.alertType= "error",
          this.alertTitle = "You cannot upload file size more than 16mb";

          return
        } else {
          this.uploadedFiles?.push({ name: file.name, progress: 0, size: formatBytes(file.size) });
        }


        const formData = new FormData();
        formData.append("project_file", file);
        formData.append("title", this.payload.file_title);

        this.config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);


            this.uploadedFiles[this.count % this.uploadedFiles?.length].progress = progress;

          },
        };
      }
      this.lastItem = this.uploadedFiles?.length > 0 ? this.uploadedFiles[this.uploadedFiles?.length - 1] : undefined;

    },
    uploadFile() {
      const file = this.queueUpload.peek()
      const formData = new FormData();
      formData.append("project_file", file);
      formData.append("title", this.payload.file_title)

      // const details = {

      // };

      UploadApi().patch(`/projects/project/file-upload/${this.isProjectID}/`, formData, this.config)
        .then((response) => {
          if (response.data.status === 'success') {
          // Handle the response from the server if needed
            this.payload.file_url= response.data.data.file_url
            this.payload.file_title = response.data.data.title

            const isEmpty = this.queueUpload.isEmpty()
            if (!isEmpty) {
              this.queueUpload.removeItem(0)
              this.queueSize = this.queueUpload.getSize()
              this.count++

            } else {
              this.count = 0
              this.uploadedFiles = []
            }
            this.filesDetails.push(this.payload)
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the upload
          if (error) {
            this.uploadedFiles = []

          }
        });
    },
    createProjectFile() {

      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;

      const projectFiles = [ ...this.filesDetails ]
      let modProjectFiles = projectFiles.reduce((prev, curr) => [ ...prev, { ...curr, file_path: this.payload.file_path, file_type: this.payload.file_type}], [])
      if (projectFiles.length === 1) {
        const currentProjectFile = { ...modProjectFiles[0] };
        currentProjectFile.title = this.payload.file_title;
        modProjectFiles = [ currentProjectFile ];
      }
     const payload={
      project_id: this.isProjectID,
      project_files: modProjectFiles
     }

      Api().post('/projects/project/project-save-files/', payload)
        .then((response) => {
          if (response.data.status === 'success') {
            this.hideDialog()
            this.isLoading = false
            this.alertState= true
            this.alertType= "success"
            this.alertTitle = response.data.details
            this.filesDetails =[]
            this.payload.file_url= ''
            // this.payload= {}
            const payload = {
              project_id : this.isProjectID,
              load_page: false
            }
            this.$store.dispatch("projectModules/getAllProjectFiles", payload);

          }
          // Handle the response from the server if needed
        })
        .catch((error) => {
          if (error) {
            this.isLoading = false
            this.alertState= true
            this.alertType= "error",
            this.alertTitle = error.response.data.detail;
          }
          // Handle any error that occurred during the file creation
        });
    },


    editProjectFile(){
      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;
    const newPayload = {
      title : this.payload.file_title,
      file_path : this.payload.file_path,
      file_type : this.payload.file_type,
      file_url : this.editObject.file_url
    }


      Api()
      .patch(`/projects/project/manage-files/${this.editObject.id}/`, newPayload)
      .then((response) => {
        if(response.data.status === 'success'){
          this.hideDialog()
          this.isLoading = false
          this.alertState= true
          this.alertType= "success"
          this.filesDetails =[]
          this.alertTitle = response.data.details
          const payload = {
              project_id : this.isProjectID,
              load_page: false
            }
          this.$store.dispatch("projectModules/getAllProjectFiles", payload);
        }
      })
      .catch(error => {
        if (error) {
            this.isLoading = false
            this.alertState= true
            this.alertType= "error",
            this.alertTitle = error.response.data.details;
          }
      })
    },

  },
  updated(){

      this.showDialog()
  }
})
</script>
<style scoped>
.drag-window {
  border-radius: 12px;
  padding-top: 50px;
}

.drag-window:hover {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(139, 41, 35, 0.04);
  border: 1px solid #fdc6c2 !important;
}

.ae-upload-progress {
  border-radius: 5px;
}

.ae-bg-green-300 {
  border: 5px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}
</style>
