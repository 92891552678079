<template>
  <div>
    <div class="flex-column">
      <div class="flex items-center SPC-MT-100">
        <input
          type="radio"
          name="radio"
          @change="toggledButton($event)"
          :value="name"
          :checked="isChecked"
          :disabled="disabled"
        />
        <label for="radio2" class="pl-2 text-sm font-medium text-gray-700">{{
          $t(`${name}`)
        }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppRadioButton",
  props: {
    name: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "Text",
    },
    value: {
      type: String,
    },
    id: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
   
    isChecked() {
      return this.value === this.name;
    }
  },
  methods: {
  
    toggledButton(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
});
</script>
<style scoped>
/* unselected radio button */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;
}

/* middle fill color */
input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* selected radio button */
input[type="radio"]:checked::before {
  background-color: #ae6a65;
  border: 2px solid #ae6a65;
  width: 8px;
  height: 8px;
}
input[type="radio"]:checked {
  background-color: #f4eae9;
  border: 2px solid #ae6a65;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  outline: none;

  position: relative;
}
input[type="radio"]:disabled {
  cursor: default;
}
</style>
