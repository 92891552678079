<template>
  <v-sheet class="bg-transparent">
    <table
      class="w-full border border-collapse border-slate-400"
      aria-describedby=""
    >
      <thead>
        <tr>
          <th
            class="text-left bg-[#F9FAFB] border border-slate-300 capitalize px-4 py-2"
            v-for="(header, index) in headers"
            :key="index"
            :colspan="
              sectionTitle == 'Mandatory' && header == 'total_amount' ? 2 : ''
            "
          >
            <!-- {{ $t(convertHeader(header)) }} -->
            {{ $t(`projectBudget.budgetTableHeader.${index}`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in isBudgetItems"
          :key="row.rowIndex ? row.rowIndex : rowIndex"
        >
          <!-- {{mandatoryTotal}}  -->
          <td
            class="border border-slate-300"
            :class="[
              { 'w-[10%] !capitalize': header === 'number_of_units' },
              { 'w-[12%]': header === 'amount_per_unit' },
              { 'w-[15%]': header === 'structure' },
              { 'w-[22%]': header === 'account' },
              { 'w-[5%]': header === 'is_planning' },
              { 'bg-[#FEFBE8]': row.structure === 'BOP - Fixed Fee' },
              { 'bg-[#F2F4F7]': row.structure === 'BOP - Against Receipt' },
              {
                'bg-[#F4EAE9]':
                  row.position == 'Backstopping' ||
                  row.position == 'Bank guarantee',
              },
              {
                'w-[14%]':
                  sectionTitle == 'Mandatory' && header == 'total_amount',
              },
            ]"
            v-for="(header, cellIndex) in headers"
            :key="cellIndex"
            :colspan="
              sectionTitle == 'Mandatory' && header == 'total_amount' ? 2 : ''
            "
          >
            <app-select-field-object
              v-if="header === 'structure'"
              :options="isStructureOptions"
              placeholder="Select structure"
              :disabled="!isEditButton ? 'disabled' : null"
              :isBudget="true"
            :isLabel="false"
              :budgetRow="row"
              v-model="row.structure"
              @change="handleInputChange($event, rowIndex, header)"
            ></app-select-field-object>

            <app-select-field-object
              v-else-if="header === 'account'"
              :options="isAccountOptions"
              placeholder="Select account"
              :disabled="!isEditButton ? 'disabled' : null"
              :isBudget="true"
              :budgetRow="row"
              :isLabel="false"
              v-model="row.account"
              @change="handleInputChange($event, rowIndex, header)"
            ></app-select-field-object>

            <div
              v-else-if="header === 'total_amount'"
              class="px-4 py-2 'w-[100px] text-right"
              :class="[
                {
                  'pr-16':
                    sectionTitle == 'Mandatory' && header == 'total_amount',
                },
              ]"
            >
              {{ isCurrentProject.currency }}

              {{
                appLang === "de"
                  ? germanCurrency(row?.total_amount)
                  : row?.total_amount?.formatCurrency()
              }}
            </div>
            <div
              v-else-if="header === 'is_planning'"
              class="justify-center px-4 pt-2 d-flex align-center"
            >
              <div>
                <input
                  class="w-full"
                  type="checkbox"
                  :disabled="!isEditButton"
                  @change="handleCheckboxChange($event, rowIndex)"
                  v-model="row.is_planning"
                />
              </div>
            </div>
            <input
              v-else-if="header === 'position'"
              class="w-full py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
              type="text"
              :disabled="
                !isEditButton ||
                row.position == 'Backstopping' ||
                row.position == 'Bank guarantee'
              "
              @input="handlePositionEdit($event, rowIndex)"
              v-model="row.position"
            />
            <input
              v-else-if="header === 'number_of_units'"
              class="w-full py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] text-center"
              type="text"
              pattern="^[1-9]\d*$"
              :disabled="!isEditButton"
              title="Please enter a positive number"
              :value="getCellData(rowIndex, header)"
              @input="handleInputChange($event, rowIndex, header)"
            />
            <input
              v-else-if="header === 'amount_per_unit'"
              class="w-full py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] text-center"
              type="text"
              pattern="^[0-9]*[1-9][0-9]*$"
              :disabled="!isEditButton"
              title="Please enter a positive number"
              :value="getCellData(rowIndex, header)"
              @input="handleInputChange($event, rowIndex, header)"
            />
            <input
              v-else
              class="w-full py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] text-center"
              type="text"
              :disabled="!isEditButton"
              title="Please enter a positive number"
              :value="getCellData(rowIndex, header)"
              @input="handleInputChange($event, rowIndex, header)"
            />
          </td>

          <td
            class="px-3 text-center border border-slate-300"
            :class="[
              { 'bg-[#FEFBE8]': row.structure === 'BOP - Fixed Fee' },
              { 'bg-[#F2F4F7]': row.structure === 'BOP - Against Receipt' },
              {
                'bg-[#F4EAE9]':
                  row.position == 'Backstopping' ||
                  row.position == 'Bank guarantee',
              },
            ]"
            v-if="sectionTitle !== 'Mandatory'"
          >
            <v-menu location="start">
              <template v-slot:activator="{ props }">
                <!-- <span v-if="sectionTitle == 'Mandatory'">&nbsp;&nbsp;&nbsp;</span>  -->
                <v-icon
                  icon="custom:dotVertical"
                  v-bind="props"
                  class=""
                ></v-icon>
              </template>
              <v-list class="rounded-lg items">
                <v-list-item
                  class="hover:bg-gray-200"
                  @click="insertRow(rowIndex)"
                  >{{ $t("projectBudget.addLineItem") }}
                </v-list-item>
                <v-list-item
                  class="hover:bg-gray-200"
                  @click="removeRow(rowIndex, row)"
                  >{{ $t("projectBudget.deleteLineItem") }}</v-list-item
                >
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </v-sheet>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { addUnderscores, formatAmount, currencyFormatter } from "@/services/helpers"
import { mapState } from "vuex";

export default defineComponent({
  name: "Budget table",
  props: {
    budgetItems:{
      type: Array,
    },
    sectionId:{
      type: Number,
    },
   sectionTitle: {
      type: String
    },
    tableIndex:{
      type: Number,
    },
  },
  data() {
    return {
        headers:['position', 'structure', 'account', 'number_of_units', 'amount_per_unit', 'is_planning', 'total_amount'],
        mandatoryTotal: 0,
        totalCost: [],
        totalIncome: [],
        totalItems: {
          section_id: 0,
          total: 0,
          total_income: 0,
          total_cost: 0,
          profit_margin: 0,
          profit_margin_percentage: 0
        },
        everyRowTotal: {

        },
        listOfNumberHeaders:['number_of_units', 'amount_per_unit', 'total_amount'],
        initialBudgetItems: null,
        modifiedBudgetItems: [],
        backstopping: 0,
        removedItemTotal: {},
    };
  },
  watch: {
    // Watch for changes in 'number_of_units' and 'amount_per_unit' properties
    isBudgetItems: {
      handler: function (newBudgetItems) {
        this.isBudgetItems = newBudgetItems?.map((row) => {
          if (
            this.listOfNumberHeaders?.includes('number_of_units') &&
            this.listOfNumberHeaders?.includes('amount_per_unit')
          ) {
            // Calculate and update the total_amount directly
            if (isNaN(row['number_of_units'])) {
              row['number_of_units'] = ''

            }
            // Calculate and update the total_amount directly
            if (isNaN(row['amount_per_unit'])) {

              row['amount_per_unit'] = ''
            }
            const total = (
                parseFloat(row['number_of_units']) *
                parseFloat(row['amount_per_unit'])
              )
            row.total_amount = isNaN(total) ? 0 : total;

          }
          if (row.position !== '' && row.structure !== '' && row.account !== '' && row.number_of_units !== 0 && row.amount_per_unit!== 0){
            this.$store.commit('projectBudgetModules/SET_ENABLED_BUTTON_TO_STATE', false)
          }

          return { ...row };
         });
      },
      deep: true,
    },
    getBackstopping(newVal){
      this.backstopping = newVal
    },
  },

  computed: {

    isBudgetData(){
      return this.$store.getters['projectBudgetModules/isBudgetData']
    },
    isStructureOptions(){
      const modifiedData = this.$store.getters['projectBudgetModules/isStructure']
      return modifiedData?.convertArrayToObject()
    },

    getBackstopping() {
      return this.$store.getters['projectBudgetModules/getBackstopping']
    },
    isAccountOptions(){
      const modifiedData = this.$store.getters['projectBudgetModules/isAccount']
      return modifiedData?.convertArrayToObject()
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    contractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    isBudgetItems(){
      return this.budgetItems
    },
    budgetLineItemAfterRowRemove(){
      return this.$store.getters['projectBudgetModules/getBudgetLineItemAfterRowRemove']
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
    getIncomeCostPair() {
      return this.$store.getters["projectBudgetModules/getIncomeCostPair"];
    },
  },
  methods: {
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    formatAmount(value){
      return formatAmount(value)
    },
    convertHeader(header){
      const headers = {
        number_of_units: 'number of units',
        amount_per_unit: 'amount per unit',
        is_planning: 'planning',
        total_amount: 'total amount'
      }
      return Object?.keys(headers)?.includes(header) ? headers[header] : header
    },

    getCellData(rowIndex, header) {
      // Retrieve the cell value from the computed data array
      return this.isBudgetItems[rowIndex][header];
    },
    handleInputChange(event, rowIndex, header) {
      let eventData = event?.target?.value

      let tr = event.target.closest('tr')
      if (!eventData) {
        tr.classList.add('red-border')
      }else{
        tr.classList.remove('red-border')
      }

      if (this.listOfNumberHeaders?.includes(header)){
        eventData = header === 'number_of_units'? parseInt(eventData) : parseFloat(eventData)
      }
      // Handle the input change and update the inputData array
      let budgetLineItems = [...this.isBudgetItems]

      let budgetItem = { ...this.isBudgetItems[rowIndex], row_index: rowIndex };

      // let newBudgetLineItems = ''
      let updateBudgetLineItems = ''

      budgetItem[header] = eventData;
      budgetItem = addUnderscores(budgetItem);
      budgetLineItems[rowIndex] = budgetItem

      // update  new budget line item with budget_item_id
      updateBudgetLineItems = budgetLineItems?.map((newItem)=>{
        return newItem?.id ? {
          "budget_item_id": newItem?.id,
          "structure": newItem?.structure,
          "position": newItem?.position,
          "number_of_units":newItem?.number_of_units,
          "amount_per_unit":newItem?.amount_per_unit,
          "account": newItem?.account,
          "is_planning": newItem?.is_planning
        } : newItem
      })

      this.isBudgetItems[rowIndex][header]= eventData ;
      const budgetSectionIndex = this.isBudgetData?.findIndex((budgetData) => budgetData?.budget_section_id === this.sectionId)
      const modifiedBudgetSection ={...this.isBudgetData[budgetSectionIndex]}

      // modifiedBudgetSection.budget_items = budgetLineItems
      modifiedBudgetSection.budget_items = updateBudgetLineItems
      const modifiedBudgetData = [...this.isBudgetData ]
      modifiedBudgetData[budgetSectionIndex] = modifiedBudgetSection


      for (const item of modifiedBudgetSection.budget_items) {
        item.total_amount = item.number_of_units * item.amount_per_unit;
      }
      // Function to update the result with new budget item

      const updateResult = (result, item) => {

        const totalAmount = item.number_of_units * item.amount_per_unit;

        this.mandatoryTotal =  totalAmount
          if (item.structure === "BOP - Fixed Fee" || item.structure === "BOP - Against Receipt") {
              result.bopTotal = (result.bopTotal || 0) + totalAmount;
            }
            if (["Costs BOP", "Cost Internal", "Costs", "BOP - Against Receipt"]?.includes(item.structure)) {
              result.costsTotal = (result.costsTotal || 0) + totalAmount;
          }
      };

      let newData = modifiedBudgetSection.budget_items?.reduce(
        (accumulator, item) => {
        updateResult(accumulator, item);
        return accumulator;
        },
        { bopTotal: 0, costsTotal: 0 }
        );
        if (isNaN(newData.bopTotal)) {

          newData.bopTotal = 0
        }
          if (isNaN(newData.costsTotal)) {

            newData.costsTotal = 0
          }

          this.totalItems.section_id = modifiedBudgetSection.budget_section_id
          this.totalItems.total_income = newData.bopTotal
          this.totalItems.total_cost = newData.costsTotal
          this.totalItems.profit_margin = newData.bopTotal - newData.costsTotal
          this.totalItems.profit_margin_percentage = parseFloat(this.totalItems.profit_margin / newData.bopTotal) * 100
          if(this.totalItems.profit_margin_percentage === -Infinity || isNaN(this.totalItems.profit_margin_percentage)){
            this.totalItems.profit_margin_percentage = 0
          }

          this.$store.commit('projectBudgetModules/SET_INCOME_COST_PAIR', {
            budget_section_id: this.totalItems.section_id, pair: {
            total_cost: this.totalItems.total_cost,
            total_income : this.totalItems.total_income
          }})
          this.$emit('total-items')

          modifiedBudgetData[budgetSectionIndex] = modifiedBudgetSection
          modifiedBudgetData[budgetSectionIndex].total_income = newData.bopTotal
          modifiedBudgetData[budgetSectionIndex].total_cost =  newData.costsTotal
          modifiedBudgetData[budgetSectionIndex].profit_margin = newData.bopTotal - newData.costsTotal
          modifiedBudgetData[budgetSectionIndex].profit_margin_percentage = this.totalItems.profit_margin_percentage
          this.$store.commit('projectBudgetModules/SET_BUDGET_DATA_TO_STATE', modifiedBudgetData)
    },

    handleCheckboxChange(event, rowIndex) {
      let is_planning = event.target._modelValue;

      let budgetItem = { ...this.isBudgetItems[rowIndex], row_index: rowIndex };
      budgetItem.is_planning = is_planning;
      this.isBudgetItems[rowIndex] = {...budgetItem};

      this.$store.commit('projectBudgetModules/SET_IS_PLANNING_ON_CHECKBOX_CHANGE', {budgetSectionId: this.sectionId, rowIndex: rowIndex, budgetItemId: budgetItem?.id,is_planning: is_planning })
    },

    handlePositionEdit(event, rowIndex) {
      // let position = event.target.value;

      // let budgetItem = { ...this.isBudgetItems[rowIndex], row_index: rowIndex };
      // budgetItem.position = position;
      // this.isBudgetItems[rowIndex] = {...budgetItem};
      // this.$store.commit('projectBudgetModules/SET_POSITION_ON_EDIT', {budgetSectionId: this.sectionId, rowIndex: rowIndex, budgetItemId: budgetItem?.id,position: position })
    },

    calculateIncomeCostMargin(budget_section_id, budget_line_items) {

          for (const item of budget_line_items) {
            item.total_amount = item.number_of_units * item.amount_per_unit;
          }
          // Function to update the result with new budget item

          const updateResult = (result, item) => {

            const totalAmount = item.number_of_units * item.amount_per_unit;

            this.mandatoryTotal =  totalAmount
              if (item.structure === "BOP - Fixed Fee" || item.structure === "BOP - Against Receipt") {
                  result.bopTotal = (result.bopTotal || 0) + totalAmount;
                }
                if (["Costs BOP", "Cost Internal", "Costs", "BOP - Against Receipt"]?.includes(item.structure)) {
                  result.costsTotal = (result.costsTotal || 0) + totalAmount;
              }
          };

          let newData = budget_line_items?.reduce(
            (accumulator, item) => {
              updateResult(accumulator, item);
              return accumulator;
            },
            { bopTotal: 0, costsTotal: 0 }
          );

          if (isNaN(newData.bopTotal)) {

            newData.bopTotal = 0
          }
          if (isNaN(newData.costsTotal)) {

            newData.costsTotal = 0
          }

          const totalItems = {};

          totalItems.section_id = budget_section_id
          totalItems.total_income = newData.bopTotal
          totalItems.total_cost = newData.costsTotal
          totalItems.profit_margin = newData.bopTotal - newData.costsTotal
          totalItems.profit_margin_percentage = parseFloat(totalItems.profit_margin / newData.bopTotal) * 100
          if(totalItems.profit_margin_percentage === -Infinity || isNaN(totalItems.profit_margin_percentage)){
            totalItems.profit_margin_percentage = 0
          }

          this.$store.commit('projectBudgetModules/SET_INCOME_COST_PAIR', {
            budget_section_id: totalItems.section_id, pair: {
            total_cost: totalItems.total_cost,
            total_income : totalItems.total_income
          }})

          const allBudgetData = this.isBudgetData;
          allBudgetData[this.tableIndex].budget_section_id = totalItems.section_id;
          allBudgetData[this.tableIndex].total_income = totalItems.total_income;
          allBudgetData[this.tableIndex].total_cost = totalItems.total_cost;
          allBudgetData[this.tableIndex].profit_margin = totalItems.profit_margin;
          allBudgetData[this.tableIndex].profit_margin_percentage = totalItems.profit_margin_percentage;

          this.$store.commit('projectBudgetModules/SET_ONE_BUDGET_DATA_TO_STATE', {tableIndex: this.tableIndex, sectionObject: allBudgetData[this.tableIndex]})
    },

    recalculateTotalAfterRowRemove(budget_section_id) {
          let modifiedBudgetSection = {}
          modifiedBudgetSection.budget_items = this.budgetLineItemAfterRowRemove

          for (const item of modifiedBudgetSection.budget_items) {
            item.total_amount = item.number_of_units * item.amount_per_unit;
          }
          // Function to update the result with new budget item

          const updateResult = (result, item) => {

            const totalAmount = item.number_of_units * item.amount_per_unit;

            this.mandatoryTotal =  totalAmount
              if (item.structure === "BOP - Fixed Fee" || item.structure === "BOP - Against Receipt") {
                  result.bopTotal = (result.bopTotal || 0) + totalAmount;
                }
                if (["Costs BOP", "Cost Internal", "Costs", "BOP - Against Receipt"]?.includes(item.structure)) {
                  result.costsTotal = (result.costsTotal || 0) + totalAmount;
              }
          };

          let newData = modifiedBudgetSection.budget_items?.reduce(
            (accumulator, item) => {
              updateResult(accumulator, item);
              return accumulator;
            },
            { bopTotal: 0, costsTotal: 0 }
          );

          if (isNaN(newData.bopTotal)) {

            newData.bopTotal = 0
          }
          if (isNaN(newData.costsTotal)) {

            newData.costsTotal = 0
          }

          const totalItems = {};

          totalItems.budget_section_id = budget_section_id
          totalItems.total_income = newData.bopTotal
          totalItems.total_cost = newData.costsTotal
          totalItems.profit_margin = newData.bopTotal - newData.costsTotal
          totalItems.profit_margin_percentage = parseFloat(totalItems.profit_margin / newData.bopTotal) * 100
          if(totalItems.profit_margin_percentage === -Infinity || isNaN(totalItems.profit_margin_percentage)){
            totalItems.profit_margin_percentage = 0
          }

          this.removedItemTotal = {total_income: totalItems.total_income, total_cost: totalItems.total_cost}

          this.$store.commit('projectBudgetModules/SET_RECALCULATED_RESULT_AFTER_REMOVED_LINE_ITEM', {...totalItems})

    },

    insertRow(rowIndex) {
      // Create a new empty row and insert it below the specified index in the inputData array
      const newRow = {};
      for (const header of this.headers) {
        newRow[header] = '';
        newRow['number_of_units'] = 0;
          newRow['amount_per_unit'] = 0;
          newRow['is_planning'] = false;
          newRow['total_amount'] = (parseInt(newRow['number_of_units']) * parseFloat(newRow['amount_per_unit'])).toFixed(2);
      }
      this.isBudgetItems.splice(rowIndex + 1, 0, newRow);

    },
    removeRow(rowIndex, row ) {

      // Remove the row at the specified index from the inputData array
      const budgetItemId = this.isBudgetItems[rowIndex].id;

      this.isBudgetItems.splice(rowIndex, 1);
      this.$store.commit('projectBudgetModules/SET_LINE_ITEM_ON_ROW_REMOVE',{ budgetSectionId : this.sectionId, rowIndex: row?.row_index})
      this.$store.commit('projectBudgetModules/REMOVE_SECTION_LINE_ITEM',{ budgetSectionId : this.sectionId, rowIndex: row?.row_index, budgetItemId: budgetItemId})//remove item fom budget data
      if ('id' in row && row?.id) {
        this.$store.dispatch('projectBudgetModules/deleteBudgetLineItem', row?.id)
        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });
      }
      this.recalculateTotalAfterRowRemove(this.sectionId)
      this.$store.commit('projectBudgetModules/SET_INCOME_COST_PAIR', {
            budget_section_id: this.sectionId, pair: {
            total_cost: this.removedItemTotal.total_cost,
            total_income : this.removedItemTotal.total_income
      }})

      this.calculateStatistics();
    },
    addNewRow() {
        // Create a new empty row and insert it at the end of the inputData array
        const newRow = {};
        for (const header of this.headers) {
          newRow[header] = '';
          newRow['number_of_units'] = 0;
          newRow['amount_per_unit'] = 0;
          newRow['is_planning'] = false;
          newRow['total_amount'] = (parseInt(newRow['number_of_units']) * parseFloat(newRow['amount_per_unit'])).toFixed(2);
        }
        this.isBudgetItems.push(newRow);
      },

    calculateStatistics() {
      let grandTotalCost = 0;
      let grandTotalIncome = 0;
      this.$store.commit('projectBudgetModules/SET_BACKSTOPPING')
      this.backstopping = this.getBackstopping

      for (const key in this.getIncomeCostPair) {

          grandTotalCost += this.getIncomeCostPair[key].total_cost;
          grandTotalIncome += this.getIncomeCostPair[key].total_income;
      }

      let payload = {
        total_cost: grandTotalCost,
        total_income: grandTotalIncome,
        profit_margin_1: (grandTotalIncome - grandTotalCost),
        profit_margin_2: (grandTotalIncome - grandTotalCost) + this.backstopping,
        total_budget: grandTotalIncome,//same as total budget == grandTotalIncome
      }

      this.$store.commit('projectBudgetModules/SET_ALL_PROJECT_BUDGETS_STATISTICS', payload)
    },

  },
  mounted(){
    this.initialBudgetItems = this.budgetItems
  },
  created(){

    this.calculateIncomeCostMargin(this.sectionId, this.budgetItems)
    this.$store.commit('projectBudgetModules/SET_BACKSTOPPING')
    this.backstopping = this.getBackstopping
  }
});
</script>
<style scoped>
.red-border {
  border: 2px solid #fdc6c2;
}
.table {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.cell {
  border: 1px solid black;
  padding: 5px;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 32px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #943b36;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: #fbf7f6;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #943b36;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
