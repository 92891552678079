<script>
import {defineComponent} from 'vue'
import ProjectHoursTable from "@/components/dashboard/profile/timesheet/ProjectHoursTable.vue";
import {monthsNameWithNumber} from "@/services/helpers";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "ViewDetailsModal",
  data() {
    return {
      monthsNameWithNumber,
      locked: false
    }
  },
  computed: {
    ...mapGetters(['projectHours/getViewTimesheetMonth', 'projectHours/getViewTimesheetYear']),
    year() {
      return this.$store.getters['projectHours/getViewTimesheetYear']
    },
    monthText() {
      return this.$store.getters['projectHours/getViewTimesheetMonth']
    },
    monthValue() {
      return monthsNameWithNumber.find(month => month.text === this.monthText).value
    }
  },
  methods: {
    showModal(isLocked) {
      this.locked = isLocked
      this.$refs.viewModal.showDialog = true
    }
  },
  props: {
    // filters: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       year: new Date().getFullYear(),
    //       month: new Date().getMonth() + 1
    //     }
    //   }
    // }
  },
  components: {ProjectHoursTable}
})
</script>

<template>
  <app-dialog
      class="m-0"
      ref="viewModal"
      :title="`${$t('Project Hours For'.getTranslationKey())} ${$t(monthText.getTranslationKey())}, ${year} (${locked ? $t('Locked'.getTranslationKey()) : $t('Unlocked'.getTranslationKey())})`"
      width="98%"
  >
    <template #content>
      <project-hours-table @close="() => $refs.viewModal.showDialog = false" is-view :is-locked="locked" :year="year" :month="monthValue">
      </project-hours-table>
    </template>
    <template v-if="locked" #actions>
      <app-primary-button
          class="ml-auto pr-3 mb-3"
          :button-label="$t('Close'.getTranslationKey())"
          @click="$refs.viewModal.showDialog = false"
      ></app-primary-button>
    </template>
  </app-dialog>
</template>

<style scoped>

</style>