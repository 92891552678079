<template>
  <div>
    <v-btn icon="custom:bellIcon" color="#667085"> </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App Notification",

  data() {
    return {};
  },
  methods: {},
});
</script>
