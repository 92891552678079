<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="flex justify-start gap-10">
          <div>
            <app-multi-select
              :label="$t('projectConfiguration.positions')"
              :options="getAllPositions"
              :labelPlaceholder="$t('projectConfiguration.selectPosition')"
              :searchPlaceholder="$t('projectConfiguration.searchEmployees')"
              :showSearch="true"
              :showCheckbox="true"
              :optionItems="positions"
              @selected-options="selectedOptions({ $event, data: 'positions' })"
            >
            </app-multi-select>
          </div>
        </div>

        <div class="h-[300px]"></div>

        <v-row>
          <v-col cols="12">
            <div class="w-[650px]">
              <div class="flex justify-end gap-3 mt-10">
                <div>
                  <app-secondary-button
                    @click="previous"
                    :buttonLabel="$t('projectConfiguration.previous')"
                  >
                  </app-secondary-button>
                </div>
                <div>
                  <app-primary-button
                    :buttonLabel="$t('projectConfiguration.save')"
                    @click="addUpdateResponsiblePersons"
                  ></app-primary-button>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import AppSecondaryButton from "@/components/shared/AppSecondaryButton.vue";
import AppPrimaryButton from "@/components/shared/AppPrimaryButton.vue";
import AppMultiSelect from "@/components/shared/AppMultiSelect.vue";

export default defineComponent({
  components: { AppSecondaryButton, AppPrimaryButton, AppMultiSelect },
  name: "Default Collaborators",
  data() {
    return {
      alertTitle: "",
      alertType: "success",
      alertState: false,
      positions: [],
      selectedPositions: [],
    };
  },
  computed: {
    getAllPositions() {
      const positions = this.$store.getters["hrConfigurations/isPositions"];
      return positions.map((position) => ({
        text: position.title,
        value: position.id,
      }));
    },

    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
  },
  methods: {
    getResponsiblePersons() {
      Api()
        .get(
          `/configuration/default-collaborators/get-responsible-persons-positions/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.data) {
              const data = response.data.data.positions;
              this.positions = this.getAllPositions.filter((position) =>
                data.includes(position.value)
              );
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    addUpdateResponsiblePersons() {
      const payload = {};
      if (this.selectedPositions.length > 0) {
        payload.positions = this.selectedPositions;
      }
      if (Object.keys(payload).length > 0) {
        Api()
          .post(
            `/configuration/responsible-hr/add-update-responsible-hr/`,
            payload
          )
          .then((response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
            }
          });
      }
    },

    selectedOptions(data) {
      if (data.data === "positions") {
        this.selectedPositions = data.$event;
      }
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    previous() {
      const getCurrentTab = this.isCurrentTab - 1;
      this.$store.commit(
        "commonResources/SET_CURRENT_TAB_TO_STATE",
        getCurrentTab
      );
    },
  },
  mounted() {
    this.getResponsiblePersons();
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
  },
});
</script>

<style scoped></style>
