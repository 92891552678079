<template>
  <div>
    <form>
      <v-container class="ml-0">
        <v-row>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.contractDetails.whoseInvoice')"
              :label="$t('projects.contractDetails.invoiceTo')"
              v-model="payload.invoice_to"
              :disabled="shouldDisableInput ? 'disabled' : null"
              :hasError="isFieldInvalid('invoice_to')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="$t('projects.contractDetails.enterProjectAmount')"
              :label="$t('projects.contractDetails.projectAmount')"
              v-model.number="payload.project_amount"
              :disabled="shouldDisableInput ? 'disabled' : null"
              validation="floatingNumber"
              :hasError="isFieldInvalid('project_amount')"
              :isRequired="true"
            ></app-input-field>
            <span v-if="currentPath === '/edit-project'" class="text-[12px]">{{
              $t("projects.interimPrompt")
            }}</span>
          </v-col>
          <v-col cols="6">
            <app-select-field-object
              :placeholder="$t('projects.selectSettlementType')"
              :label="$t('projects.contractDetails.settlementType')"
              v-model="payload.settlement_type"
              :options="settlementTypes"
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-select-field-object>
          </v-col>
          <v-col cols="6">
            <app-input-field
              :placeholder="
                $t('projects.contractDetails.enterPaymentTermsInDays')
              "
              :label="$t('projects.contractDetails.paymentTermsInDays')"
              v-model="payload.payment_terms"
              :disabled="shouldDisableInput ? 'disabled' : null"
              validation="digitOnly"
              type="number"
            ></app-input-field>
          </v-col>
          <v-col cols="6">
            <app-date-field
              :max="maxStartDate(payload.project_end_date)"
              :label="$t('projects.projectStartDate') + '*'"
              v-model.trim="payload.project_start_date"
              type="date"
              :disabled="shouldDisableInput ? 'disabled' : null"
              :hasError="isFieldInvalid('project_start_date')"
              :isRequired="true"
            >
            </app-date-field>
          </v-col>
          <v-col cols="6">
            <app-date-field
              :min="minEndDate(payload.project_start_date)"
              :label="$t('projects.projectEndDate') + '*'"
              v-model.trim="payload.project_end_date"
              type="date"
              :disabled="shouldDisableInput ? 'disabled' : null"
              :hasError="isFieldInvalid('project_end_date')"
              :isRequired="true"
            >
            </app-date-field>
          </v-col>
          <v-col cols="6">
            <app-toggle-button
              :id="audit"
              v-model="payload.is_audit"
              :label="$t('projects.contractDetails.audit')"
              :disabled="shouldDisableInput ? 'disabled' : null"
              class="justify-between"
            ></app-toggle-button>
          </v-col>
          <v-col cols="6">
            <app-toggle-button
              :id="guarantee"
              v-model="payload.is_bank_guarantee"
              :label="$t('projects.contractDetails.bankGuarantee')"
              :disabled="shouldDisableInput ? 'disabled' : null"
              class="justify-between"
            ></app-toggle-button>
          </v-col>
          <v-col cols="6">
            <app-toggle-button
              :id="foreign_establishment"
              v-model="payload.foreign_establishment"
              :label="$t('contracts.foreignEstablishment')"
              :disabled="shouldDisableInput ? 'disabled' : null"
              class="justify-between"
            ></app-toggle-button>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="2" class="flex justify-end pt-10"
            ><app-primary-button
              class="w-24"
              :buttonLabel="$t('projects.save')"
              :loading="isLoading"
              @click="
                this.isContractID === null || ''
                  ? saveContractDetails()
                  : updateContractDetails()
              "
              v-if="!shouldDisableInput"
            ></app-primary-button
          ></v-col>
        </v-row>
      </v-container>
    </form>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue";
import Api from '@/services/api'
import { addOrUpdateContractDetails } from "@/services/endpoints"
import { formatAmount, getTransString, currencyFormatter, maximumStartDate, minimumEndDate} from "@/services/helpers";
import { mapState } from "vuex";
import moment from "moment";


export default defineComponent({
  name: "ContractDetails",
  props: {},
  data() {
    return {
      payload: {
        project_id: this.$store.state.projectModules?.projectID,
        invoice_to: "",
        project_amount: "",
        settlement_type: "",
        payment_terms: 0,
        project_start_date: null,
        project_end_date: null,
        is_audit: false,
        is_bank_guarantee: false,
        foreign_establishment: false
      },
      alertType: "success",
      alertState: false,
      alertTitle: "",
      requiredFields: ['invoice_to', 'project_amount', 'project_start_date', 'project_end_date'],
      isDirty: [],
      originalProjectAmount: ''
    };
  },
  watch: {
    shouldDisableInput(){
      this.payload.project_amount = this.contractDetails.project_amount
    },
    "payload.foreign_establishment": function (val) {
      this.$store.commit("contractModules/SET_FOREIGN_ESTABLISHMENT_TO_STATE", val)
    },
  },
  computed: {

    settlementTypes() {
      return this.$store.getters["projectModules/isSettlementTypes"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isContractID() {
      return this.$store.getters["projectModules/isContractId"];
    },
    currentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    contractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton;
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },

    formattedDate(dateString) {
    return moment(dateString)?.format('MMM/Dd/YYYY');
  },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    minEndDate(date){
    return minimumEndDate(date)
  },
  maxStartDate(date){
    return maximumStartDate(date)
  },
  checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
    },
  inputFieldsValidation(){
    this.hasError = false
    Object.keys(this.payload).forEach((key) => {
        if (this.requiredFields?.includes(key) && ( this.payload[key] === "" ||  this.payload[key] === undefined || this.payload[key] === null)) {
          this.isDirty.push(key);
          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {

          return this.hasError;
        }

  },


    saveContractDetails() {
      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;
      Api()
        .post(addOrUpdateContractDetails, this.payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", response.data.data.id);
            const payload = {
                projectID: this.isProjectID,
                load_page: false
            }
           this.$store.dispatch('projectModules/getContractDetails', payload)

          }
          else if (response.data.status === "error") {
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch(error => {
          if (error) {
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
        });
    },
    updateContractDetails() {
      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;

      const updatePayload = {
        project_id: this.$store.state.projectModules?.projectID,
        invoice_to: this.payload.invoice_to,
        project_amount: this.payload.project_amount,
        settlement_type: this.payload.settlement_type,
        payment_terms: this.payload.payment_terms ? this.payload.payment_terms : 0,
        project_start_date: this.payload.project_start_date,
        project_end_date: this.payload.project_end_date,
        is_audit: this.payload.is_audit,
        is_bank_guarantee: this.payload.is_bank_guarantee,
        foreign_establishment: this.payload.foreign_establishment,
        contract_id: this.isContractID
      };
      Api()
        .post(addOrUpdateContractDetails, updatePayload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", response.data.data.id);
            const payload = {
               projectID: this.isProjectID
            }
            this.$store.dispatch('projectModules/getContractDetails', payload)

          }
          else if (response.data.status === "error") {
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

  },
  created() {
    if(Object.keys(this.contractDetails).length > 0){

    this.payload.invoice_to = this.contractDetails.invoice_to;
    if(this.shouldDisableInput){
      this.payload.project_amount = this.appLang === "de" ? currencyFormatter(this.contractDetails.project_amount) : Number(this.contractDetails.project_amount)?.formatCurrency()
    }else{
      this.payload.project_amount = this.contractDetails.project_amount
    }
    this.payload.settlement_type = this.contractDetails.settlement_type;
    this.payload.payment_terms = this.contractDetails.payment_terms;
    this.payload.project_start_date = this.contractDetails.project__project_start_date?  this.contractDetails.project__project_start_date : this.currentProject.project_start_date
    this.payload.project_end_date = this.contractDetails.project__project_end_date? this.contractDetails.project__project_end_date : this.currentProject.project_end_date
    this.payload.is_audit = this.contractDetails.is_audit;
    this.payload.is_bank_guarantee = this.contractDetails.is_bank_guarantee;
    this.payload.foreign_establishment = this.contractDetails.foreign_establishment;
    this.$store.commit("contractModules/SET_FOREIGN_ESTABLISHMENT_TO_STATE", this.contractDetails.foreign_establishment)
  } else{
    this.payload.project_start_date =  this.currentProject.project_start_date
    this.payload.project_end_date = this.currentProject.project_end_date
  }
  },
  // components: { AppAmountInput }
});
</script>
<style scoped>
.toggle-pointer:disabled {
  cursor: default;
}
</style>
