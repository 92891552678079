<template>
  <div>
    <div>
      <app-table
        :headers="advancepaymentHeaders"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        :showTotal="true"
        :showCursor="false"
        :items="isAdvancePayment"
        :plannedTotalLabel="$t('projects.plannedTotalAmt')"
        :plannedTotal="
          appLang === 'de'
            ? germanCurrency(isContractDetails.total_advance_payment)
            : amountFormatting(isContractDetails.total_advance_payment)
        "
        :currency="isCurrentProject.currency"
      >
        <template #title>
          <h3 class="H500">
            {{ $t("projectConfiguration.allAdvancePayment") }}
          </h3>
        </template>
        <template #actions>
          <app-primary-button
            :buttonLabel="$t('projectConfiguration.addAdvancePayment')"
            @click-event="showDialog({ data: 'add' })"
            :disabled="shouldDisableInput ? 'disabled' : null"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>

        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
  </div>
  <app-dialog
    ref="dialogState"
    :title="
      isEdit
        ? $t('projectConfiguration.updateAdvancePayment')
        : $t('projectConfiguration.addAdvancePayment')
    "
    width="408"
  >
    <template #content>
      <app-input-field
        :label="$t('projectConfiguration.plannedAmt') + '*'"
        :placeholder="$t('projects.enterPlannedAmount')"
        v-model.number="advancePayment.amount"
        validation="floatingNumber"
        :hasError="isFieldInvalid('amount')"
        :isRequired="true"
      >
      </app-input-field>
      <app-date-field
        class="mt-1"
        :label="$t('projectConfiguration.plannedDate') + '*'"
        v-model.trim="advancePayment.date"
        type="date"
        :hasError="isFieldInvalid('date')"
        :isRequired="true"
      >
      </app-date-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit
                ? $t('addcustomrole.saveChanges')
                : $t('projectConfiguration.save')
            "
            @click-event="isEdit ? updateAdvancePayment() : addAdvancePayment()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteAdvancePayment"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import api from "@/services/api";
import {
  addOrUpdateAdvancePayment,
  fetchAllAdvancePayment,
} from "@/services/endpoints";
import {
  currencyFormatter,
  extractUnderscore,
  formatAmount,
} from "@/services/helpers";
import { getTransString } from "@/services/helpers";
import { deleteAdvancePayment } from "@/services/endpoints";
import Paginator from "@/components/shared/PaginationComponent.vue";
import moment from "moment";
import { mapState } from "vuex";

export default defineComponent({
  name: "AdvancePayments",
  components: {
    Paginator,
  },
  data() {
    return {
      dialogState: false,
      advancepaymentHeaders: ["Sr. No.", "Planned Amount", "Planned Date"],
      advancePayment: {
        amount: "",
        date: "",
      },
      isEdit: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isDelete: false,
      currentPages: 1,
      title: "",
      subTitle: "",
      totalAmount: "",
      isCurrency: "",
      requiredFields: ["amount", "date"],
      isDirty: [],
      hasError: "",
    };
  },
  computed: {
    isAdvancePayment() {
      const newKey = "sr. no.";
      const modifiedData =
        this.$store.getters["projectModules/isAdvancePayment"];
      modifiedData?.map((obj) => {
        obj[newKey] = obj["sr no"];

        delete obj["sr no"];
        for (let key in obj) {
          if (typeof obj[key] === "boolean") {
            obj[key] = obj[key] ? "Yes" : "No";
          }
        }
      });
      return modifiedData;
    },
    isContractID() {
      return this.$store.getters["projectModules/isContractId"];
    },
    currentPage() {
      return this.$store.getters["projectModules/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["projectModules/isAdvancePaymentTotalPages"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    isContractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-project" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  watch: {},
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.advancePayment).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.advancePayment[key] === "" ||
            this.advancePayment[key] === undefined ||
            this.advancePayment[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },

    showDialog(data) {
      if (data.data === "add") {
        this.isEdit = false;
        this.advancePayment.amount = "";
        this.advancePayment.date = "";
        this.$refs.dialogState.showDialog = true;
      } else if (data.data === "delete") {
        this.advancePaymentID = data?.$event.id;
        this.$refs.dialog.showDialog = true;
        this.title = "projectConfiguration.deleteAdvancePayment";
        this.subTitle = "projectConfiguration.deleteAdvancePaymentConfirm";
      }
      const newdata = data?.$event;
      if (data.data === "edit") {
        this.advancePaymentID = data?.$event?.id;
        const modifiedData = {};
        for (const key in newdata) {
          const newKey = key?.replace(/ /g, "_");
          modifiedData[newKey] = newdata[key];
        }
        this.subTitle = "";
        this.isEdit = true;
        this.$refs.dialogState.showDialog = true;
        this.advancePayment.amount = parseFloat(
          modifiedData.planned_amount?.replace(/,/g, "")
        );
        this.advancePayment.date = moment(
          modifiedData.planned_date,
          "Do MMM YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.isEdit = false;
      this.isDirty = [];
      this.advancePayment.amount = "";
      this.advancePayment.date = "";
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    getAdvancePayment(page) {
      api()
        .get(fetchAllAdvancePayment + `${page}` + `/${this.isContractID}`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;

            const modifiedData = extractUnderscore(data);
            modifiedData.map((dateFormat) => {
              dateFormat["planned date"] = moment(
                dateFormat["planned date"]
              ).format("Do MMM YYYY");
              const formattedPlannedAmount = formatAmount(
                dateFormat["planned amount"]
              );
              dateFormat["planned amount"] = formattedPlannedAmount;

              return { dateFormat, formattedPlannedAmount };
            });
            this.$store.commit(
              "projectModules/SET_ADVANCE_PAYMENT_TO_STATE",
              modifiedData
            );
            this.$store.commit(
              "projectModules/SET_ADVANCE_PAYMENT_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    getContractDetails() {
      const payload = {
        projectID: this.isProjectID,
        load_page: false,
      };
      this.$store.dispatch("projectModules/getContractDetails", payload);
    },
    addAdvancePayment() {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      const payload = {
        contract_id: this.isContractID,
        planned_amount: this.advancePayment.amount,
        planned_date: this.advancePayment.date,
      };
      api()
        .post(addOrUpdateAdvancePayment, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "success";
            this.alertTitle = response?.data?.detail;
            this.getContractDetails();

            this.getAdvancePayment(this.currentPage);
          }
        })
        .catch((error) => {
          if (error.response.data.code === "313") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
        });
    },
    updateAdvancePayment() {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      const payload = {
        advance_payment_id: this.advancePaymentID,
        contract_id: this.isContractID,
        planned_amount: this.advancePayment.amount,
        planned_date: this.advancePayment.date,
      };
      api()
        .post(addOrUpdateAdvancePayment, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "success";
            this.alertTitle = response?.data?.detail;
            this.getContractDetails();

            this.getAdvancePayment(this.currentPage);
          }
        })
        .catch((error) => {
          if (error.response.data.code === "313") {
            this.$refs.dialogState.showDialog = false;
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
          }
        });
    },
    deleteAdvancePayment() {
      api()
        .delete(deleteAdvancePayment + `${this.advancePaymentID}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertState = true;
            this.alertType = "success";
            this.alertTitle = response?.data?.detail;
            this.getContractDetails();
            this.getAdvancePayment(this.currentPage);
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAdvancePayment(page);
    },
  },
  mounted() {
    this.$store.commit("projectModules/SET_ADVANCE_PAYMENT_TO_STATE", []);
  },
  created() {
    this.getAdvancePayment(this.currentPages);
  },
});
</script>

<style></style>
