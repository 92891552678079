<template>
  <div class="flex items-center">
    <div class="p-2 bg-white rounded shadow-sm">
      <span
        @click.prevent="goBackward"
        class="py-2 text-gray-600 no-underline rounded-sm rounded-l hover:bg-gray-100"
        href="#"
        role="button"
        rel="prev"
      >
        <v-icon variant="text" icon="custom:chevronLeft"></v-icon>
      </span>
    </div>

    <div class="text-base font-medium text-[#344054] cursor-default px-2">
      {{ currentMonth }}
    </div>

    <div class="p-2 bg-white rounded shadow-sm">
      <span
        class="h-10 py-2 text-gray-600 rounded-sm rounded-l cursor-pointer"
        @click="goForward"
      >
        <v-icon variant="text" icon="custom:chevronRight"></v-icon>
      </span>
    </div>
  </div>
</template>
<script>
import { monthNames } from "@/services/helpers";
export default {
  props: {},
  data() {
    return {
      page: 0,
      months: monthNames,
      currentMonthIndex: new Date().getMonth(),
      countBackward: 0,
      countforward: 0,
    };
  },
  watch: {
    currentMonth: function(val){
      this.$emit("monthChanged", val);
    }
  },

  computed: {
    currentMonth() {
      return this.months[this.currentMonthIndex];
    },
  },

  methods: {
    goBackward() {
      this.countforward = 0;
      if (this.currentMonthIndex > 0) {
        do {
          this.currentMonthIndex--;
          this.countBackward++;
          if (this.currentMonthIndex === 0) {
            this.countBackward = 0;
          }
        } while (this.months[this.currentMonthIndex] === "");
      }
    },

    goForward() {
      this.countBackward = 0;
      if (this.currentMonthIndex < this.months.length - 1) {
        this.currentMonthIndex++;
        this.countforward++;
        if (this.currentMonthIndex === 8) {
          this.countforward = 0;
        }
      }
    },
  },
 
  created() {
    if (this.currentMonthIndex < 0 || this.currentMonthIndex >= this.months.length) {
      this.currentMonthIndex = 0; 
    }
  },
};
</script>
