<template>
  <div>
    <div class="p-4 mx-1 mb-8 border rounded-lg">
      <div class="text-lg font-bold">{{ $t("cheques.bankBalance") }}</div>
      <div class="flex-container">
        <div
          v-for="data in isChequeBalance"
          :key="data.currency"
          class="flex-item"
        >
          <div class="mt-4">
            <div class="currency">{{ data.currency__currency }}</div>
            <div class="amount">
              {{
                appLang === "de"
                  ? germanCurrency(data.bank_balance)
                  : amountFormatting(data.bank_balance)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-3/5 gap-10 mb-8">
      <v-row>
        <v-col cols="5">
          <app-single-select
            :label="$t('pmLocals.year') + '*'"
            :labelPlaceholder="$t('cheques.selYear')"
            :options="yearOptions"
            :isRequired="true"
            v-model="dateForm.cheque_year"
            :hasError="isFieldInvalid('cheque_year')"
          ></app-single-select>
        </v-col>
        <v-col cols="5">
          <app-single-select
            :label="$t('pmLocals.month') + '*'"
            :labelPlaceholder="$t('cheques.selMonth')"
            :options="monthsOptions"
            :isRequired="true"
            v-model="dateForm.cheque_month"
            :hasError="isFieldInvalid('cheque_month')"
          ></app-single-select>
        </v-col>
      </v-row>
    </div>
    <div class="border rounded-lg">
      <div class="flex items-center justify-between p-4">
        <h3 class="H500">
          {{ isEditCheque ? $t("cheques.editCheque") : $t("cheques.newCheq") }}
        </h3>
        <app-secondary-button
          v-if="!isEditCheque"
          @click="addNewChequeRoll()"
          :buttonLabel="$t('Add New Cheque'.getTranslationKey())"
          class="border-none hover:bg-gray-200 hover:rounded-lg"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-secondary-button>
      </div>
      <table class="w-full text-sm text-left text-[#404b5e]">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header"
              class="!text-left bg-[#f2f4f7] border-slate-300 px-6 py-3 !text-[#344054] !font-medium"
              :class="{ 'no-padding': header === '', '!text-right': header === 'amt Excl. Tax' || header === 'amt Incl. Tax' }"
            >
            {{ $t(getTranslatedStringKey(header  || " ")) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(cheque, index) in cheque_data"
            :key="index"
            :class="rowErrorClass(cheque)"
          >
            <td>
              <input v-model="cheque.title" type="text" placeholder="Title" />
            </td>
            <td>
              <app-date-field
                type="date"
                :max="maxStartDate(cheque.payment_date)"
                :isLabel="false"
                v-model="cheque.document_date"
                class="!border-none bg-transparent !shadow-none"
                :isCheque="true"
              ></app-date-field>
            </td>
            <td>
              <app-date-field
                type="date"
                :min="minEndDate(cheque.document_date)"
                :isLabel="false"
                v-model="cheque.payment_date"
                class="!border-none bg-transparent"
                :isCheque="true"
              ></app-date-field>
            </td>
            <td>
              <input
                v-model="cheque.supplier"
                type="text"
                placeholder="Supplier"
              />
            </td>
            <td>
              <app-select-field-object
                :placeholder="$t('cheques.selectPost')"
                :options="chequeCurrency"
                v-model.number="cheque.currency_id"
                :isTranslation="false"
                :isLabel="false"
                :isCheque="true"
              ></app-select-field-object>
            </td>
            <td>
              <input
              v-model.number="cheque.total_amount_not_taxed"
              type="text"
              placeholder="0"
              class="text-right"
              />
            </td>
            <td>
              <input
                v-model.number="cheque.total_amount_taxed"
                type="text"
                placeholder="0"
                class="text-right"
              />
            </td>

            <td>
              <app-select-field-object
                :placeholder="$t('cheques.selectPost')"
                :options="chequeBudgetPosition"
                v-model.number="cheque.budget_id"
                :isTranslation="false"
                :isLabel="false"
                :isCheque="true"
              ></app-select-field-object>
            </td>
            <td>
              <div v-if="!cheque.file_title">
                <label for="fileInput" class="cursor-pointer">
                  <span class="text-blue-500">Click to upload</span>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  @change="handleFileUpload($event, index)"
                  style="display: none"
                />
              </div>
              <div v-else>
                {{
                  cheque.file_title.length > 10
                    ? cheque.file_title.substring(0, 10) + "..."
                    : cheque.file_title
                }}
              </div>
            </td>
            <td>
              <v-menu location="start">
                <template v-slot:activator="{ props }">
                  <v-icon
                    icon="custom:dotVertical"
                    v-bind="props"
                    class="mt-2"
                  ></v-icon>
                </template>
                <v-list class="rounded-lg items">
                  <v-list-item
                    @click="deleteCheque()"
                    class="my-n1 hover:bg-gray-200"
                    >{{ $t("projectConfiguration.delete") }}</v-list-item
                  >
                  <v-list-item
                    v-if="cheque.file_title"
                    class="cursor-pointer my-n1 hover:bg-gray-200"
                    @click="viewfile(file_path)"
                    >{{ $t("cheques.viewFile") }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-end gap-4 my-12">
      <app-secondary-button
        :buttonLabel="$t('Cancel'.getTranslationKey())"
        @click-event="cancelAddNewCheque()"
      ></app-secondary-button>
      <app-primary-button
        @click-event="isEditCheque ? updateSingleCheque() : saveCheque()"
        :buttonLabel="
          isEditCheque
            ? $t('Save Changes'.getTranslationKey())
            : $t('Save'.getTranslationKey())
        "
      >
      </app-primary-button>
    </div>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
    :multiple="multiple"
    :multipleTitles="multipleTitles"
  />
</template>

<script>
import {
  currencyFormatter,
  formatAmount,
  getTransString,
  maximumStartDate,
  minimumEndDate,
  monthsNameWithNumber,
} from "@/services/helpers";
import Api from "@/services/api";
import {
  addCheques,
  currencyBudgetPosition,
  getSingleCheque,
  updateCheque,
  uploadChequeFile,
} from "@/services/endpoints";

export default {
  data() {
    return {
      headers: [
        "Title",
        "Doc. Date",
        "Pay Date",
        "Supplier",
        "Currency",
        "Amt Excl. Tax",
        "Amt Incl. Tax",
        "Budget Position",
        "File Name",
        "",
      ],
      cheque_data: [],
      alertType: "success",
      alertState: false,
      alertTitle: "",
      chequeCurrency: [],
      transformedCurrency: [],
      chequeBudgetPosition: [],
      transformedBudgetPosition: [],
      currentDate: new Date(),
      saveClicked: false,
      hasErrors: false,
      isDirty: [],
      dateForm: {
        cheque_year: null,
        cheque_month: null,
      },
      multipleTitles: "",
      multipleErrors: "",
    };
  },
  watch: {
    cheque_data: {
      handler(val) {
        val.forEach((item) => {
          if (item.total_amount_not_taxed > item.total_amount_taxed) {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle =
              "Total amount not taxed should be less than total amount taxed";
          }
          if (item.total_amount_not_taxed < 0 || item.total_amount_taxed < 0) {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = "Amount cannot be less than 0";
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    isProjectId() {
      return this.$store.getters["pmLocalModules/isProjectId"];
    },
    isEditCheque() {
      return this.$store.getters["chequeModules/isEditCheque"];
    },
    isChequeEditId() {
      return this.$store.getters["chequeModules/isChequeEditId"];
    },
    isChequeStartDate() {
      return this.$store.getters["chequeModules/isChequeStartDate"];
    },
    isChequeEndDate() {
      return this.$store.getters["chequeModules/isChequeEndDate"];
    },
    yearOptions() {
      return this.getYearsFromProject(
        this.isChequeStartDate,
        this.isChequeEndDate
      );
    },
    monthsOptions() {
      return monthsNameWithNumber;
    },
    isChequeBalance() {
      return this.$store.getters["chequeModules/isChequeBalance"];
    },
    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    getMonth() {
      const currentDate = new Date();
      return new Intl.DateTimeFormat("en-US", { month: "long" })?.format(
        currentDate
      );
    },
    amountFormatting(amt) {
      return formatAmount(amt);
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    getYearsFromProject(startDate, endDate) {
      const startYear = new Date(startDate).getFullYear();
      const endYear = new Date(endDate).getFullYear();
      const years = [];
      for (let i = startYear; i <= endYear; i++) {
        years.push({ text: i, value: i });
      }
      return years;
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    handleFileUpload(event, index) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("cheque_file", file);
        Api()
          .post(`${uploadChequeFile}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              const fileData = response.data.data;
              const fileName =
                fileData.file_title.length > 10
                  ? fileData.file_title.substring(0, 10) + "..."
                  : fileData.file_title;
              this.file_title = fileName;
              this.cheque_data[index].file_title = fileData.file_title;
              this.cheque_data[index].file_path = fileData.file_path;
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.details;
            }
          })
          .catch((error) => {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.details;
          });
      }
    },
    addNewChequeRoll() {
      this.cheque_data.push({
        title: "",
        document_date: "",
        payment_date: "",
        supplier: "",
        currency_id: null,
        total_amount_taxed: null,
        total_amount_not_taxed: null,
        budget_id: null,
      });
    },
    viewfile() {
      window.open(this.cheque_data[0].file_path, "_blank");
    },
    deleteCheque(index) {
      this.cheque_data.splice(index, 1);
    },
    getCurrency() {
      Api()
        .get(`${currencyBudgetPosition}1/${this.isForeignEstablishmentId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.chequeCurrency = response.data.data?.map((curr) => {
              return {
                id: curr.currency,
                value: curr.id,
              };
            });
          }
        })
        .catch();
    },
    cancelAddNewCheque() {
      this.$store.commit("chequeModules/SET_ADD_CHEQUE_TO_STATE", false);
      this.$store.commit("chequeModules/SET_EDIT_CHEQUE_TO_STATE", false);
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 2);
    },
    getBudgetPosition() {
      Api()
        .get(`${currencyBudgetPosition}2/${this.isForeignEstablishmentId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.chequeBudgetPosition = response.data.data?.map((pos) => {
              return {
                id: pos.budget_item__position,
                value: pos.id,
              };
            });
          }
        })
        .catch();
    },
    saveCheque() {
      this.validateFields();
      if (this.hasErrors) {
        return;
      }
      const payload = {
        cheque_data: this.cheque_data,
        year: this.dateForm.cheque_year,
        month: this.dateForm.cheque_month,
        foreign_establishment_id: this.isForeignEstablishmentId,
        project_id: this.isProjectId,
      };
      Api()
        .post(`${addCheques}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.hasErrors = false;
            this.saveClicked = false;
            setTimeout(() => {
              this.cancelAddNewCheque();
            }, 2000);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 314) {
            this.multipleErrors = error?.response?.data.data;
            this.alertType = "error";
            this.alertState = true;
            this.multiple = true;
            this.hasErrors = true;

            this.multipleTitles = Object.values(this.multipleErrors).reduce(
              (accumulator, item) => {
                Object.keys(item).forEach((key) => {
                  if (key === "currency") {
                    accumulator.push({
                      key: item[key],
                      message: "Insufficient currency funds for",
                    });
                  } else if (key === "budget_position") {
                    accumulator.push({
                      key: item[key],
                      message: "Insufficient local budget funds for",
                    });
                  }
                });
                return accumulator;
              },
              []
            );
          } else {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.detail;
            this.hasErrors = false;
            this.saveClicked = false;
          }
        });
    },

    getSingleCheque() {
      Api()
        .get(`${getSingleCheque}${this.isChequeEditId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.cheque_data = [response.data.data];
            this.dateForm.cheque_month = response.data.data.month;
            this.dateForm.cheque_year = response.data.data.year;
          }
        })
        .catch();
    },
    updateSingleCheque() {
      this.validateFields();
      if (this.hasErrors) return;
      this.cheque_data[0].year = this.dateForm.cheque_year;
      this.cheque_data[0].month = this.dateForm.cheque_month;
      delete this.cheque_data[0].paid_in_by__first_name;
      delete this.cheque_data[0].paid_in_by__last_name;
      delete this.cheque_data[0].id;
      delete this.cheque_data[0].year;
      delete this.cheque_data[0].month;
      const updatedArray = []
      updatedArray.push(this.cheque_data[0])
      const payload = {
        
        cheque_data: updatedArray,
        year: this.dateForm.cheque_year,
        month: this.dateForm.cheque_month,
        foreign_establishment_id: this.isForeignEstablishmentId,
        project_id: this.isProjectId,
      };
      Api()
        .put(`${updateCheque}${this.isChequeEditId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.details;
            this.hasErrors = false;
            this.saveClicked = false;
            setTimeout(() => {
              this.cancelAddNewCheque();
            }, 2000);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 314) {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = "Insufficient funds";
            this.hasErrors = false;
            this.saveClicked = false;
          } else {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.details;
            this.hasErrors = false;
            this.saveClicked = false;
          }
        });
    },
    rowErrorClass(cheque_data) {
      if (!this.saveClicked) {
        return ""; // Do not validate if the save button hasn't been clicked
      }
      if (
        !cheque_data.title ||
        !cheque_data.document_date ||
        !cheque_data.payment_date ||
        !cheque_data.supplier ||
        !cheque_data.currency_id ||
        !cheque_data.total_amount_taxed ||
        !cheque_data.budget_id
      ) {
        return "border-red-300 border-2";
      }
      return "";
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    validateFields() {
      this.hasErrors = false;
      this.saveClicked = true;
      const fieldsConfig = {
        budget_id: "Budget position is required",
        total_amount_taxed: "Total amount taxed is required",
        currency_id: "Currency is required",
        supplier: "Supplier is required",
        payment_date: "Payment date is required",
        document_date: "Document date is required",
        title: "Title is required",
        // Add other fields as needed...
      };

      this.cheque_data?.forEach((cheque) => {
        for (const [field, errorMessage] of Object.entries(fieldsConfig)) {
          if (!cheque[field]) {
            this.hasErrors = true;
            this.checkAlertEror(errorMessage);
          }
        }
      });
    },
  },
  async created() {
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
    this.getCurrency();
    this.getBudgetPosition();
    this.dateForm.cheque_year = this.currentDate.getFullYear();
    this.dateForm.cheque_month = this.currentDate.getMonth() + 1;
    this.isEditCheque ? this.getSingleCheque() : "";
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 8px;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
  outline: none;
}
.no-padding {
  padding: 0px !important;
}
.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper select {
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-wrapper .icon-calendar {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.flex-item {
  flex: 1;
  padding: 10px;
  min-width: 200px;
}

.amount {
  font-weight: bold;
}

.currency {
  font-size: 18px;
}
</style>

<!-- this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3); -->
