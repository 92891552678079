<template>
  <div
    v-if="getAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <v-container fluid>
      <v-row>
        <v-col cols="5">
          <app-input-field
            class="pl-1"
            v-model="calculationsForm.total_offer_amount"
            :placeholder="$t('projects.enterTotalAmountOfTheOffer')"
            :label="$t('projects.totalAmountOfTheOffer*')"
            :disabled="!isEditButton"
            :hasError="isFieldInvalid('total_offer_amount')"
            :isRequired="true"
          ></app-input-field>
        </v-col>
        <v-col cols="5">
          <app-input-field
            class="pl-1"
            v-model="calculationsForm.margin_amount"
            :placeholder="$t('projects.enterAmountOfTheMargin')"
            :label="$t('projects.amountOfTheMargin*')"
            :disabled="!isEditButton"
            :hasError="isFieldInvalid('margin_amount')"
            :isRequired="true"
          ></app-input-field>
        </v-col>
        <v-col cols="2" class="flex justify-end align-end"
          ><app-primary-button
            @click="save"
            :loading="loading"
            v-if="isEditButton"
            class="w-24 pr-1"
            :buttonLabel="$t('projects.save')"
            :style="{ height: '3rem' }"
          ></app-primary-button
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-table
            :key="refreshKey"
            :headers="headers"
            :items="items"
            :hideMenu="true"
            :hideTableFooter="shouldHideFooter"
            :hideTableHeader="shouldHideHeader"
          >
          </app-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-table
            :headers="header"
            :items="modifiedFilesArray"
            @edit-event="editFileDialog"
            @delete-event="deleteFileDialog"
            @view-file="viewFileInPath"
            :hideMenu="!isEditButton"
          >
            <template #title>
              <h3 class="H500">{{ $t("projects.allFiles") }}</h3>
            </template>
            <template #actions>
              <app-primary-button
                :buttonLabel="$t('projects.addFile')"
                @click="addFileDialog"
                v-if="isEditButton"
              >
                <template #icon>
                  <v-icon icon="mdi-plus"></v-icon>
                </template>
              </app-primary-button>
            </template>
          </app-table>
          <app-file-upload
            :showUploadOnEdit="true"
            @url-from-file="handleFileUrl"
            ref="appFileUpload"
            :showSelect="false"
            :file-size="fileSize"
            :fileType="fileType"
          ></app-file-upload>
        </v-col>
      </v-row>
    </v-container>

    <!-- delete project files  -->
    <delete-project-files
      ref="deleteProjectFiles"
      :file-id="fileID"
    ></delete-project-files>

    <!-- create and update project files dailog-->
    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script lang="js">
import { getTransString, formatAmount, currencyFormatter } from "@/services/helpers";
import { manageProjectFile, fetchSingleProjectFile } from "@/services/endpoints";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from 'vuex';
import DeleteProjectFiles from './DeleteProjectFiles.vue'
import api from '@/services/api';
import { isEqual } from 'lodash';

export default defineComponent({
  name: "ProjectCalculations",
  components: {
    DeleteProjectFiles
  },
  data() {
    return {
      headers: ["Total Amount of the Offer", "Amount of the Margin", "Margin Percentage"],
      items: [],
      project_id: '',
      firstRun: true,
      calculationsForm: {
        total_offer_amount: '',
        margin_amount: '',
      },
      originalCalculationsForm: {
        total_offer_amount: '',
        margin_amount: '',
      },
      disableSaveButton: true,
      disableFileSaveButton: true,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      loading: false,
      isLoading: false,
      filesDetails: [],
      project_files: [],
      refreshKey: 0,
      dialogState: false,
      header: ['Title', 'File Type', 'Date Uploaded', 'User'],
      fileID: null,
      fileTitle: "",
      filePath: "",
      fileName: "",
      fileUrl: "",
      fileSize: "",
      isDelete: false,
      isEdit: false,
      title: "",
      subTitle: "",
      cancelDelete: "Cancel",
      isDirty: [],
      shouldHideFooter: true,
      shouldHideHeader: true,
      hasError: false,
      requiredFields: ['total_offer_amount', 'margin_amount']
    }
  },
  computed: {

    ...mapGetters("projectCalculationsModule", ["getCalculation", "getError", "getResponse", "getAppLoading"]),
    ...mapGetters("translation", ["getLocale"]),
    getProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"]
    },
    // activateSaveButton() {
    //   return !((this.total_offer_amount * this.margin_amount) > 0)
    // },

    allProjectFiles() {
      return this.$store.getters["projectModules/isAllProjectFiles"];
    },
    modifiedFilesArray() {
      return this.allProjectFiles?.filter(file => file.file_type === "Calculations")?.map(({ title, file_type, date_uploaded, user, id, file_path, file_url }) => ({
        title: title,
        'file type': file_type,
        'date uploaded': date_uploaded,
        user: user,
        id: id,
        file_path: file_path,
        file_url: file_url

      }));
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },

    inputDependencies() {
      return [
        this.calculationsForm.total_offer_amount,
        this.calculationsForm.margin_amount
      ]
    },
    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },

  },
  watch: {
    // activateSaveButton(newVal) {
    //   this.disableSaveButton = newVal
    // },
    async getLocale (newVal) {
      await this.fetchCalculation({
        project_id: this.getProjectID
      });
      if (this.getCalculation.total_offer_amount !== undefined && this.getCalculation.margin_amount !== undefined && this.getCalculation.margin_percentage !== undefined) {
        this.items = [{
          "total amount of the offer": newVal === 'de' ? currencyFormatter(this.getCalculation.total_offer_amount) : formatAmount(this.getCalculation.total_offer_amount),
          "amount of the margin": newVal === 'de' ? currencyFormatter(this.getCalculation.margin_amount) : formatAmount(this.getCalculation.margin_amount),
          "margin percentage": `${this.getCalculation.margin_percentage}%`
        }];
      } else {
        this.items = [];
      }

    },
    inputDependencies: {
      immediate: true,
      handler(newValue) {

        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectCalculations": !isEqual(newValue, ['', ''])
        });
      }
    },
  },
  methods: {
    ...mapActions("projectCalculationsModule", ["saveCalculation", "fetchCalculation", "saveUploadedFiles"]),

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
    },
  inputFieldsValidation(){
    this.hasError = false
    Object.keys( this.calculationsForm).forEach((key) => {
        if (this.requiredFields?.includes(key) && ( this.calculationsForm[key] === "" ||  this.calculationsForm[key] === undefined || this.calculationsForm[key] === null)) {
          this.isDirty.push(key);
          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {

          return this.hasError;
        }

  },

    remountComponent() {
      this.refreshKey += 1; // Update the key value to trigger a remount
    },

    handleFileUrl(event) {
      this.project_files = [...event];
      if (this.project_files.length > 0) {
        this.disableFileSaveButton = false;
      } else {
        this.disableFileSaveButton = true;
        this.isLoading = false;
      }
    },
    async save() {
      this.inputFieldsValidation()
      if (this.hasError) return this.hasError;

        try {
          await this.saveCalculation({
            project_id: this.getProjectID,
            total_offer_amount: this.calculationsForm.total_offer_amount,
            margin_amount: this.calculationsForm.margin_amount,

          });

          this.items = [{
            "total amount of the offer": this.getLocale === 'de' ? currencyFormatter(this.getResponse.data.total_offer_amount) : formatAmount(this.getResponse.data.total_offer_amount),
            "amount of the margin": this.getLocale === 'de' ? currencyFormatter(this.getResponse.data.margin_amount) :  formatAmount(this.getResponse.data.margin_amount),
            "margin percentage": `${this.getResponse.data.margin_percentage}%`
          }];

          this.alertType = this.getResponse.status;
          this.alertState = true;
          this.alertTitle = this.getResponse.detail;

        } catch (error) {
          this.alertType = this.getResponse.status;
          this.alertState = true;
          this.alertTitle = this.getResponse.detail;
        } finally {
          this.calculationsForm.total_offer_amount = '';
          this.calculationsForm.margin_amount = '';
          this.disableSaveButton = true;
          this.loading = false;
        }

    },

    async saveFiles() {

      this.isLoading = true
      const projectFiles = [...this.project_files]
      const modProjectFiles = projectFiles?.map((url) => {
        const path = new URL(url)?.pathname;
        const parts = path.split('/');
        const filename = parts[parts.length - 1];
        const title = filename.split('.')[0];

        return {
          title: title,
          file_type: "Calculations", // Modify as needed
          file_url: url
        };
      });

      const payload = {
        project_id: this.getProjectID,
        project_files: modProjectFiles
      }

      try {
        await this.saveUploadedFiles(payload);

        if (this.project_files.length > 0) {
          this.alertType = this.getResponse.status;
          this.alertState = true;
          this.alertTitle = this.getResponse.details;
        }

      } catch (error) {
        this.alertType = this.getResponse.status;
        this.alertState = true;
        this.alertTitle = this.getResponse.details;
      } finally {
        this.disableFileSaveButton = true;
        this.isLoading = false;
        this.project_files = [];
      }
    },

    hideDialog(event) {
      this.dialogState = event('hide-dialog')
    },

    addFileDialog() {
      this.isEdit = false
      this.$refs.appFileUpload.fileType='Calculations'
      this.$refs.appFileUpload.showDialog()
    },
    deleteFileDialog(event) {
      this.fileID = event.id
      this.$refs.deleteProjectFiles.showDialog()

    },
    editFileDialog(event){
        this.fileID = event.id
        this.$refs.appFileUpload.isEdit = true
        this.$refs.appFileUpload.editObject = event
        this.$refs.appFileUpload.showEditDialog()
        this.getSingleFile()
        this.isEdit = true
    },
    getSingleFile() {
      api()
        .get(`${manageProjectFile}${this.fileID}/`)
        .then(response => {
          if (response.data.status === "success") {
            const data = response?.data?.data
            this.filePath = data?.file_path
            this.title = data?.title
            this.fileSize = data?.file_size
            this.fileUrl = data?.file_url
            this.filetype = data?.file_type
          }
        })
        .catch()
    },
    viewFileInPath(data) {
      if (data.file_url) {
        window?.open(data.file_url)
      }

    },

    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false
      setTimeout(() => {
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelDelete = "employees.cancel";
      }, 600)
    },
    getNewProjectFiles() {
      api()
        .get(`${fetchSingleProjectFile}${this.isProjectID}/`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$store.dispatch("projectModules/getAllProjectFiles", this.isProjectID);
          }
        })
    },

  },

  async created() {
    
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
    // this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 2)
    await this.fetchCalculation({
      project_id: this.getProjectID
    });

    if (this.getCalculation.total_offer_amount !== undefined && this.getCalculation.margin_amount !== undefined && this.getCalculation.margin_percentage !== undefined) {
      this.items = [{
        "total amount of the offer": this.getLocale === 'de' ? currencyFormatter(this.getCalculation.total_offer_amount) : formatAmount(this.getCalculation.total_offer_amount),
        "amount of the margin": this.getLocale === 'de' ? currencyFormatter(this.getCalculation.margin_amount) : formatAmount(this.getCalculation.margin_amount),
        "margin percentage": `${this.getCalculation.margin_percentage}%`
      }];
    } else {
      this.items = [];
    }

  }
});
</script>
<style scoped></style>
