<template>
  <div class="input_box">
    <div class="py-2 label" v-if="isLabel">
      <label>{{ label }}</label>
      
    </div>

    <div class="relative">
      <select
        :disabled="disabled"
        :class="[
          className,
          [isBudget || isCheque ? 'bg-transparent border-none !shadow-none' : ''],
          [disabled ? 'bg-[#f9fafb] text-[#667085]' : 'bg-white'],
          { '!bg-[#FFFAEB]': budgetRow.structure === 'BOP - Fixed cost' },
          { 'bg-[#EAECF0]': budgetRow.structure === 'BOP - Against receipt' },
          { '!border-red-500': error },
        ]"
        :required="requireTag"
        class="select !px-4 border cursor-pointer !placeholder-gray-400 border hover:border-[#d8dae5] focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] focus:ring peer block dark:bg-gray-700 rounded-lg border-1 border-solid border-gray-300 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);"
        :placeholder="placeholder"
        :value="modelValue"
        @change="updateValue"
        autocapitalize="on"
        v-bind="$attrs"
      >
        <option
          v-if="placeholder"
          id="default"
          data-cy="optionType"
          value=""
          selected
          class="text-[#667085] "
        >
          {{
            isTranslation
              ? $t(getTranslatedStringKey(placeholder))
              : placeholder
          }}
        </option>
        <option
          v-for="(option, i) in options"
          :key="option.id || i"
          :value="option.value ? option.value : option"
        >
          <!-- {{ option.id }} -->
          {{
            isTranslation ? $t(getTranslatedStringKey(option?.id)) : option?.id
          }}
        </option>
      </select>
      <div
        v-if="!error"
        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
      >
        <v-icon icon="custom:expandIcon" />
      </div>
      <div
        class="absolute right-0 flex mr-2 transform -translate-y-1/2 item-center top-1/2"
        v-if="error"
      >
        <v-icon size="20" icon="custom:errorIcon" />
      </div>
    </div>
    <p v-if="error" class="text-red-500 text-[14px]">
      {{ $t(`${errorMessage.getTranslationKey()}`) }}
    </p>
  </div>
</template>

<script lang="js">
import { getTransString } from "@/services/helpers";
import { defineComponent } from "vue";


export default defineComponent({
  name: "App select field",
  props: {
    type: {
      type: String,
      default: "text",
    },
    className: {
      type: String,
      default: "select",
    },
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    requireTag: {
      type: Boolean,
      default: false,
    },
    param: {
      type: String,
      default: "name",
    },
    options: {
      type: Array,
    },
    param_id: {
      type: String,
    },
    isTranslation: {
      type: Boolean,
      default:true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isBudget: {
      type: Boolean,
      default: false,
    },
    isCheque:{
      type:Boolean,
      default:false
    },
    isLabel:{
      type:Boolean,
      default:true
    },
    budgetRow: {
      type: Object,
      default() {
        return {}
      }
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      error: false,
    };
  },
  watch:{
    hasError(val){
      this.error = val
    },
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    updateValue(event) {
   this.error = this.isRequired && !event.target.value?.trim() && this.hasError

    this.$emit('update:modelValue', event.target.value)
  },
  },
});
</script>

<style scoped>
.input_box {
  width: 100%;
}

.select {
  color: #101828;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  padding: 0 32px 0 16px !important;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

select {
  width: 400px;
  padding: 10px;
}


.select:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
.border-none {
  border: none;
}

.border-none:focus {
  box-shadow: none;
  border-radius: 0;
}
</style>
