<template>
  <div>
    <app-table :headers="headers" :items="items" :isFeeInvoice="true">
      <template #title>
        <h3 class="H500">{{ $t("All Fee Invoices".getTranslationKey()) }}</h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('Generate Invoice'.getTranslationKey())"
          data-cy="btn_feeInvoice"
          @click=" invoiceDialog()"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
    </app-table>
    <signature-form ref="signatureForm"></signature-form>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import SignatureForm from "./SignatureForm.vue";
export default defineComponent({
  name: "FeeInvoice",
  components: {
    SignatureForm
  },
  data() {
    return {
      headers: ["Sr. No.", "Requested", "Request Date", "Approved", "Status"],
      items: {},
    };
  },
  computed: {},
  methods: {
    invoiceDialog() {
      // console.log("this.$refs.signatureDialo", this.$refs.signatureForm)
      this.$refs.signatureForm.showSignatureDialog();
    },
  },
  created() {},
  mounted() {},
});
</script>
