<template>
  <v-sheet max-width="1125" class="mt-4 mb-4 bg-transparent">
    <v-row>
      <v-col cols="10" class="mt-5 !divide-y">
        <p class="pb-3 font-semibold text-[1.125rem]">
          {{ $t("projects.shortlist") }}
        </p>
        <div></div>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-single-select
          :showCheckbox="false"
          :labelPlaceholder="$t('projects.costCentersPlaceholder')"
          :label="$t('projects.costCenters')"
          :options="costCentersOptions"
          :showAddMore="true"
          addMoreText="Add cost center"
          @add-More="showDialog"
          :disabled="shouldDisableInput"
          v-model="projectsForm.cost_centre_id"
        ></app-single-select>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-input-field
          id=""
          type="date"
          :label="$t('projects.shortlistDate')"
          v-model="projectsForm.shortlist_date"
          :disabled="shouldDisableInput"
        ></app-input-field>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-select-field
          :placeholder="$t('projects.fundingPlaceHolder')"
          :options="fundingSourceOPtions"
          :label="$t('projects.funding')"
          v-model="projectsForm.funding_source"
          :disabled="shouldDisableInput"
        ></app-select-field>
      </v-col>
    </v-row>
    <!-- dialog for adding cost center -->
    <create-edit-cost-centers
      ref="createEditCostCenters"
    ></create-edit-cost-centers>
    <!-- display feedback to user -->
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
// import { getSelectOptionList } from '@/services/helpers'
import { getTransString } from "@/services/helpers";
import CreateEditCostCenters from '../hr-settings/cost-center-dialogs/CreateEditCostCenters.vue';
import { isEqual } from 'lodash';

export default defineComponent({
  components: {CreateEditCostCenters},
  props: {
      projectData: {
        type: Object
      }
    },

  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      dialogState: false,
      alertTitle: "",
      projectsForm: {
        shortlist_date: null,
        cost_centre_id: null,
        funding_source: null
      },
      originalProjectsForm: {
        shortlist_date: null,
        cost_centre_id: null,
        funding_source: null
      },
      firstRun: true,
      payload :{
      configType: 'Cost Centers',
      setter: 'SET_COST_CENTERS_TO_STATE'
    }
    }
  },
  watch: {
    // projectData (data) {
    //   alert('project shortlist')
    //   this.populateProjectForm(this.projectsForm, data);
    //    if(this.firstRun) {
    //      this.populateProjectForm(this.originalProjectsForm, data);
    //      this.firstRun = false
    //    }

    // },

     projectsForm: {
        deep: true,
        handler(value) {
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "BasicInformation": !isEqual(value, this.originalProjectsForm)
        });

        },
      }
  },
  computed: {
    costCentersOptions() {
      // :options="costCentersOptions"
      // const optionList = getSelectOptionList('hrConfigurations/isCostCenters')
      const optionList = this.$store.getters['hrConfigurations/isCostCenters']
      return optionList.map((costCenter) => {
        return {
          text: costCenter.title,
          value: costCenter.id
        }
      })
    },
    fundingSourceOPtions() {
      return this.$store.getters['projectModules/isFundingSource']
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog() {
      this.$refs.createEditCostCenters.showCreateCostCenterDialog()
    },

    populateProjectForm(saveTo, data) {
      saveTo.shortlist_date = data.shortlist_date
      saveTo.cost_centre_id = data.cost_centre
      saveTo.funding_source = data.funding_source
    }

  },
  created() {
    this.populateProjectForm(this.projectsForm,  this.projectData);
    this.populateProjectForm(this.originalProjectsForm,  this.projectData);
    this.$store.dispatch('hrConfigurations/getAllCostCenters', 1)
    // this.costCentersOptions

    // this.$store.dispatch('hrConfigurations/getAllConfigurationTypes', this.payload)
  }

})
</script>
