<template>
    <div>
        <app-header
        :name="$t('advancePaymentRequest.requestDetails')"
        class="mb-5 ml-n2"
        :clickEvent="handleGoToBack"
        :show-arrow-back="true"
      >
      </app-header>
        <new-request-form :disabledFormInputs="true"></new-request-form>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import NewRequestForm from '@/components/dashboard/profile/advance-payments/NewRequest.vue';
export default defineComponent ({
    name: 'RequestDetails',
    components: {NewRequestForm},
    data () {
        return {}
    },
    methods: {
        getSingleAdvancementPaymentRequest() {
      const payload = {
        load_page: true,
      };
      this.$store.dispatch(
        "advancePaymentModules/getSingleAdvancementPaymentRequest",
        payload
      );
    },
    },
    created() {
        this.getSingleAdvancementPaymentRequest();
    }
})
</script>

<style  scoped>

</style>