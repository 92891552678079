<template>
  <div>
    <app-table :headers="headers" :items="items" :isTicketInvoice="true">
      <template #title>
        <h3 class="H500">
          {{ $t("All Ticket Invoices".getTranslationKey()) }}
        </h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('Generate Invoice'.getTranslationKey())"
          data-cy="btn_feeInvoice"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
    </app-table>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InvoiceTickets",
  components: {},
  data() {
    return {
      headers: ["Sr. No.", "Requested", "Request Date", "Approved", "Status"],
      items: {},
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
});
</script>
