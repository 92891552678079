<template>
  <div class="">
    <!-- <span v-if="!loading">{{ $t(`${buttonLabel}`) }}</span> -->
    <div class="spinner-container text-center">
      <div class="spinner"></div>
      <p class="text-[#344054] text-[14px] ml-n5">Loading...</p>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppLoader",

  methods: {
    handleClickEvent() {
      this.$emit("click-event");
    },
  },
});
</script>
<style scoped>
.spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  font-size: 16px;
  border: 5px dotted #d8c4c3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-container {
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    border-top-color: #8b2923;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    border-top-color: #bcbaba;
  }
}
</style>
