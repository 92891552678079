<template>
  <div class="container">
    <div class="flex justify-between header">
      <div>
        <h3 class="H600">{{ isUserName }}</h3>
      </div>
      <div class="controls">
        <button class="px-16" @click="previousMonth" data-cy="prevMonth">
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 11.5L1.5 6.5L6.5 1.5"
              stroke="#667085"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="current-date">
          {{ currentMonthName }} {{ currentYearAndMonth }}
        </div>
        <button class="px-16" @click="nextMonth" data-cy="nextMonth">
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 11.5L6.5 6.5L1.5 1.5"
              stroke="#667085"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="flex status">
        <div class="flex px-3 approve" data-cy="approved">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.approved") }}</p>
        </div>
        <div class="flex pending" data-cy="pending">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.pending") }}</p>
        </div>
        <div class="flex px-3 rejected" data-cy="rejected">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.rejected") }}</p>
        </div>
        <div class="flex px-3 holiday-l" data-cy="holiday">
          <div class="px-2"></div>
          <p class="px-2">{{ $t("calender.holiday") }}</p>
        </div>
      </div>
    </div>

    <v-col cols="12">
      <div class="flex calender_container">
        <div class="flex flex-column labels">
          <div class="flex desc">
            <p class="holiday">{{ $t("calender.holidayDescription") }}</p>
            <p class="textleft total">{{ $t("calender.aplyTotal") }}</p>
          </div>
          <div :class="['flex', 'text']">
            <p class="leave">{{ $t("calender.annualLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours1 }}
            </p>
          </div>
          <div :class="['flex', 'text']">
            <p class="leave">{{ $t("calender.specialLeave") }}</p>
            <p class="textleft hour">
              {{ totalHours2 }}
            </p>
          </div>
        </div>
        <div class="calendar" @mouseleave="closeAll">
          <div
            v-for="(week, index) in weeks"
            :key="'week' + index"
            class="week"
          >
            <div class="day-row">
              <div
                v-for="(day, i) in getValidDays(week)"
                :key="'day' + i"
                :class="{ cell: true, weekend: day === 'Su' || day === 'Sa' }"
                :disabled="day === 'Su' || day === 'Sa'"
              >
                {{ day }}
              </div>
            </div>
            <div class="date-row">
              <div
                v-for="(date, i) in week"
                :key="'date' + i"
                :class="{
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',

                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'is-holiday': isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="flex flex-column">
                  {{ date.date || "" }}
                  <div
                    class="active"
                    v-if="
                      format(new Date(), 'd') === date.date &&
                      checkActiveMonth()
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input' + i"
                :class="{
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="['input-value', getStatusColor(getIndex(index, i))]"
                    @mouseenter="showCard(index, i)"
                  >
                    {{ inputValues[getIndex(index, i)] }}
                  </div>
                  <div v-if="showCardIndex === getIndex(index, i)" class="card">
                    <div
                      v-for="value in cardValues"
                      :key="value.value"
                      class="card-item"
                      @click="selectCardValue(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputValues[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div
                v-for="(date, i) in week.filter((date) => date.date)"
                :key="'input2' + i"
                :class="{
                  'is-holiday': isHoliday(date.date),
                  
                  cell: true,
                  weekend: date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  'active-date': format(new Date(), 'd') === date.date,
                  'red-text':
                    date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat',
                  holidays: isHoliday(date.date),
                }"
                :disabled="date.dayOfWeek === 'Sun' || date.dayOfWeek === 'Sat'"
              >
                <div class="input-card">
                  <div
                    :class="[
                      'input-value',
                      getStatusColor1(getIndex(index, i)),
                    ]"
                    @mouseenter="showCard2(index, i)"
                  >
                    {{ inputs[getIndex(index, i)] }}
                  </div>
                  <div
                    v-if="showCardIndex2 === getIndex(index, i)"
                    class="card"
                  >
                    <div
                      v-for="value in cardValues"
                      :key="value.value"
                      class="card-item"
                      @click="selectCardValue2(index, i, value.value)"
                    >
                      {{
                        value.label === "Remove"
                          ? inputs[getIndex(index, i)] === ""
                            ? ""
                            : "Remove"
                          : value.label
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>

    <div class="mt-1 mb-2 btn">
      <div cols="10"><div></div></div>

      <div class="flex items-center justify-between">
        <p
          class="px-2 P300 cursor-pointer"
          :buttonLabel="$t('employment.cancel')"
          @click="emitClose(item)"
          data-cy="cancel_leave"
        >
          Cancel
        </p>
        <app-secondary-button
          class="px-2 P300"
          :buttonLabel="$t('Save as Draft')"
          @click-event="saveDraft()"
          :disabled="totalHours2 === 0 && totalHours1 === 0"
          data-cy="draft_leave"
        >
        </app-secondary-button>
        <app-primary-button
          :buttonLabel="$t('leaves.LeaveRequests')"
          :loading="loading"
          @click-event="addLeave()"
          :disabled="totalHours2 === 0 && totalHours1 === 0"
          data-cy="request_leave"
        />
      </div>
    </div>
    <!-- leave statistics component -->
    <leave-statistics
      :previousYear="previousYear"
      :carrryOverEndDate="
        formatDatecaryOver(leave_stats && leave_stats.carry_over_end_date)
      "
      :carryOverLeaveDays="
        formatLeaveDays(leave_stats && leave_stats.carry_over_leave_days)
      "
      :totalLeaveDaysLeft="
        formatLeaveDays(leave_stats && leave_stats.total_leave_days_left)
      "
      :currentYearAndMonth="currentYearAndMonth"
      :holidayEntitlement="
        formatLeaveDays(leave_stats && leave_stats.holiday_entitlement)
      "
      :totalHolidays="
        formatLeaveDays(leave_stats && leave_stats.total_holiday_days)
      "
      :annuaLeaveRequested="
        formatLeaveDays(leave_stats && leave_stats.total_annual_leave_requested)
      "
      :totalLeaveTaken="
        formatLeaveDays(leave_stats && leave_stats.total_leave_days_taken)
      "
      :totalSpecialLeaveRequested="
        formatLeaveDays(leave_stats && leave_stats.total_special_leave)
      "
      :totalSpecialLeave="
        formatLeaveDays(leave_stats && leave_stats.total_special_leave)
      "
      :totalUnpaidLeave="
        formatLeaveDays(leave_stats && leave_stats.total_unpaid_leave)
      "
      :totalSickLeave="
        formatLeaveDays(leave_stats && leave_stats.total_sick_leave)
      "
      :totalChildSickLeave="
        formatLeaveDays(leave_stats && leave_stats.total_child_sick_leave)
      "
      :totalKcareLeave="
        formatLeaveDays(leave_stats && leave_stats.total_child_care_leave)
      "
      :deductedOrExtra="
        formatLeaveDays(leave_stats && leave_stats.extra_leave_days)
      "
    />
  </div>
  <app-alert
    :title="alertTitle"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { getCurrentInstance } from "vue";
import { setupLogic } from "@/components/dashboard/employees/leavesScript/LeaveCalender";
import LeaveStatistics from "./Leavestats.vue";
export default {
  name: "LeaveCalender",
  components: {
    LeaveStatistics,
  },
  setup() {
    const {
      loading,
      saveDraft,
      leave_stats,
      checkActiveMonth,
      pastDateAnyYear,
      currentYear,
      formatDatecaryOver,
      previousYear,
      isHoliday,
      formatLeaveDays,
      isUserName,
      addLeave,
      days,
      weeks,
      nextMonth,
      previousMonth,
      currentYearAndMonth,
      format,
      inputValues,
      inputs,
      getIndex,
      showCard,
      selectCardValue,
      showCard2,
      selectCardValue2,
      showCardIndex,
      showCardIndex2,
      cardValues,
      totalHours1,
      totalHours2,
      alertState,
      alertTitle,
      alertType,
      getValidDays,
      isName,
      isLeaveId,
      isDatePast,
      getStatusColor,
      getStatusColor1,
      annualLeaveDays,
      specialLeaveDays,
      closeAll,
      currentMonthName,
    } = setupLogic();

    const { emit } = getCurrentInstance();

    const emitClose = (data) => {
      emit("close-event", data);
    };

    return {
      loading,
      saveDraft,
      emitClose,
      leave_stats,
      checkActiveMonth,
      pastDateAnyYear,
      currentYear,
      formatDatecaryOver,
      previousYear,
      isHoliday,
      formatLeaveDays,
      isUserName,
      addLeave,
      days,
      weeks,
      nextMonth,
      previousMonth,
      currentYearAndMonth,
      format,
      inputValues,
      inputs,
      getIndex,
      showCard,
      selectCardValue,
      showCard2,
      selectCardValue2,
      showCardIndex,
      showCardIndex2,
      cardValues,
      totalHours1,
      totalHours2,
      alertState,
      alertTitle,
      alertType,
      getValidDays,
      isName,
      isLeaveId,
      isDatePast,
      getStatusColor,
      getStatusColor1,
      annualLeaveDays,
      specialLeaveDays,
      closeAll,
      currentMonthName,
    };
  },
};
</script>

<style scoped>
.container {
  margin-top: -60px;
  width: 100%;
}
.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;

  font-size: 12px;
  line-height: 18px;
}
.status {
  height: 28px;
  left: 780px;
  top: 23px;

  background: #fcfcfd;
  border-radius: 40px;
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px;
}

.current-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344054;
}

.week {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.day-row,
.date-row,
.input-row {
  display: flex;
  width: 100%;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.11rem;
  height: 2.11rem;
  font-size: 12px;
  border: 1px solid #eaecf0;
  padding: 2px;
  box-sizing: border-box;
}
.celll:hover {
  background: #a2554f6b;
}
.weekend {
  background-color: #eaecf0;
  cursor: not-allowed;
  pointer-events: none;
}

.active {
  border-radius: 50%;

  width: 5px;
  height: 5px;

  background: #a2544f;
}

input {
  outline-width: 0;
  width: 100px;
  height: 60px;
}

.input-card {
  position: relative;
  cursor: pointer;
}

.input-value {
  padding: 0.2em;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.card {
  position: absolute;
  top: 100%;
  left: 0;
  width: 110px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 0.5em;
  z-index: 1;
  justify-content: center;
}

.card-item {
  cursor: pointer;
  padding: 0.2em 0.5em;
}
.approve > div {
  width: 16px;
  height: 16px;
  background: #d0f8ab;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #eaecf0;
}
.pending > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #fef0c7;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.rejected > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #fda29b;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.holiday-l > div {
  width: 16px;
  padding: 5px;
  height: 16px;
  background: #f4eae9;
  border: 1px solid #eaecf0;
  border-radius: 50%;
}
.pending,
.rejected,
.holiday-l,
.approve {
  display: flex;
  align-items: center;
}
.leave_card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  width: 399px;
}
.header {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.desc {
  height: 4.22rem;
  border: 1px solid #eaecf0;
  width: 13.75rem;
  color: #344054;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.text {
  height: 2.11rem;
  border: 1px solid #eaecf0;
  width: 13.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.textleft {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100px;

  border-left: 1px solid #eaecf0;
}
.leave {
  color: #101828;
  width: 12.5rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 5px;
}
.holiday {
  width: 200px;
}
.total {
  height: 70px;
}
.hour {
  height: 35px;
}
.pending-class {
  background: #fef0c7 !important;
  pointer-events: none;
}
.approved-class {
  background: #d3f8df !important;
  pointer-events: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disabled {
  background-color: #f2f4f7;
  pointer-events: none;
  cursor: not-allowed;
}
.is-holiday {
  background: #f4eae9 !important;
}
.rejected-class {
  background: #fda29b !important;
  pointer-events: none;
}
.holidays {
  pointer-events: none;
  background-color: #f2f4f7;
}
.draft-class {
  background: #eef4ff;
  pointer-events: none;
}
.blocked {
  background-color: #f2f4f7;
  pointer-events: none;
}
</style>
