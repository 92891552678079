import Api from "@/services/api";
import { ActionContext } from "vuex";

import {
    getAllForeignOffice, getSingleForeignOffice,getAllOfficeStaff, getAllEmployees, getAllBanks
} from "@/services/endpoints";


export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    foreignOffices: [],
    foreignOfficeStaff: [],
    employees: [],
    bankDetails: [],
    tabId: 0,
    tableHeaders: [
      "Country",
      "Name of Office",
      "Telephone Number",
      "Office Start Date",
      "Office End Date",
    ],
    staffTableHeaders: [
      "Employee Name",
      "Employee Position",
     
    ],
    foreignOfficeTitle: "",
    foreignOfficeID: "",
    currentForeignOffice: {},
    appLoading: false,
    officeTitle: "",
    foreignOfficeUpdate:{}
  },

  mutations: {
    SET_OFFICES_TO_STATE(state: any, payload: any) {
      state.foreignOffices = payload;
    },
    SET_OFFICE_STAFF_TO_STATE(state: any, payload: any) {
      state.foreignOfficeStaff = payload;
    },
    SET_EMPLOYEES_TO_STATE(state: any, payload: any) {
      state.employees = payload;
    },
    SET_BANK_DETAILS_TO_STATE(state: any, payload: any) {
      state.bankDetails = payload;
    },
   
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_DISABLED_TO_STATE(state: any, payload: any) {
      state.disabled = payload;
    },

    SET_OFFICE_ID_STATE(state: any, payload: any) {
      state.foreignOfficeID = payload;
    },
    SET_CURRENT_FOREIGN_OFFICE_TO_STATE(state: any, payload: any) {
      state.currentForeignOffice = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_OFFICE_TITLE_STATE(state: any, payload: any) {
      state.officeTitle = payload;
    },
    SET_FOREIGN_OFFICE_UPDATE(state: any, payload: any) {
        state.foreignOfficeUpdate = { ...state.foreignOfficeUpdate, ...payload };
      },

  },
  getters: {
    isCurrentTab(state: any) {
      return state.tabId;
    },
  
    isCurrentForeignOffice(state: any) {
      return state.currentForeignOffice;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    allForeignOffices(state: any) {
      return state.foreignOffices;
    },
    allEmployees(state: any) {
      return state.employees;
    },
    allBanks(state: any) {
      return state.bankDetails;
    },
    allOfficeStaff(state: any) {
      return state.foreignOfficeStaff;
    },
   
    tableHeaders(state: any) {
      return state.tableHeaders;
    },
    staffTableHeaders(state: any) {
      return state.staffTableHeaders;
    },
   
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
  
    isDisabled(state: any) {
      return state.disabled;
    },
   
    isOfficeID(state: any) {
      return state.foreignOfficeID;
    },
    isOfficeTitle(state: any) {
      return state.officeTitle;
    },
    isForeignOfficeUpdate(state:any){
        return state.foreignOfficeUpdate
    }
    
  },
  actions: {
    getAllOffices (context: ActionContext<any, any>, payload: any) {
        if(payload.load_page){
            context.commit('SET_APP_LOADING_TO_STATE', true);
        }
      Api()
        .get(`${getAllForeignOffice}${payload.page_number}/`)
        .then((response: any) => {
          if (response) {
            context.commit(
              "SET_OFFICES_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
        .catch((error:unknown)=>{
          if(error){
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
    },
    
    getSingleOffice(context: ActionContext<any, any>, payload:any) {
        if(payload.load_page){
            context.commit("SET_APP_LOADING_TO_STATE", true);
        }
      Api()
        .get(`${getSingleForeignOffice}${payload.officeID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            // context.commit('SET_OFFICE_TITLE_STATE', responseData.data?.place);
            context.commit('SET_CURRENT_FOREIGN_OFFICE_TO_STATE', responseData.data);
            context.commit('SET_APP_LOADING_TO_STATE', false);
            // const project = context.state.currentProject
            // if(project.project_phase === 'Offer' && project.sub_cost_center!== null || project.project_phase === 'Project' && project.sub_cost_center!== null ){
             
            //   context.commit("SET_SUB_COST_CENTER_TO_STATE", true)
            // }
          }
        })
        .catch((error:unknown)=>{
          if(error){
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
    },

    getAllOfficeStaff (context: ActionContext<any, any>, payload: any) {
        if(payload.load_page){
            context.commit('SET_APP_LOADING_TO_STATE', true);
        }

      Api()
        .get(`${getAllOfficeStaff}${context.getters.isOfficeID}/${payload.page_number}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_OFFICE_STAFF_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
        .catch((error:unknown)=>{
          if(error){
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
    },
    getAllBanks (context: ActionContext<any, any>) {
        
      Api()
        .get(`${getAllBanks}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_BANK_DETAILS_TO_STATE",
              response.data.data
            );
           
          }
        })
        .catch((error:unknown)=>{
          if(error){
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
    },
    getAllEmployees (context: ActionContext<any, any>) {
        
      Api()
        .get(`${getAllEmployees}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_EMPLOYEES_TO_STATE",
              response.data.data
            );
           
          }
        })
        .catch((error:unknown)=>{
          if(error){
            context.commit('SET_APP_LOADING_TO_STATE', false);
          }
        })
    },
    
    
  }
};
