import Api from "@/services/api";
import {
  getAllProjectContracts,
  getContractAddendumInfo,
  getContractTravels,
} from "@/services/endpoints";
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    deliverablePages: null,
    contractPages: null,
    missionPages: null,
    allContracts: [],
    allMissions: [],
    allTravels: [],
    allDeliverables: [],
    amountTypeOptions: [
      {
        text: "Lump sum",
        value: "Lump sum",
      },
      {
        text: "Against receipt",
        value: "Against receipt",
      },
    ],
    modeOfSubmissionOptions: [
      {
        text: "Digital",
        value: "Digital",
      },
      {
        text: "Hard copy",
        value: "Hard copy",
      },
    ],
    submitTo: [
      {
        text: "ICON",
        value: "ICON",
      },
      {
        text: "Client",
        value: "Client",
      },
      {
        text: "Beneficiary",
        value: "Beneficiary",
      },
      {
        text: "Other",
        value: "Other",
      },
    ],
    retentionRate: [
      {
        text: "0%",
        value: "0%",
      },
      {
        text: "20%",
        value: "20%",
      },
      {
        text: "30%",
        value: "30%",
      },
      {
        text: "40%",
        value: "40%",
      },
      {
        text: "50%",
        value: "50%",
      },
    ],
    otherAllowancesDropdown: [
      {
        text: "Per day",
        value: "Per day",
      },
      {
        text: "Per month",
        value: "Per month",
      },
    ],
    projectFiles: [],
    contractFiles: [],
    contractIdFrelancer: null,
    contractIdEmployment: null,
    contractIdPersonnel: null,
    allFunds: [],
    travelcosts: null,
    allOtherAllowance: [],
    otherAllowancePages: null,
    linkedContract: null,
    contractView: null,
    contractEditId: null,
    contractType: "",
    editContract: false,
    createAddendum: false,
    addendumId: null,
    editAddendum: {},
    singleProjectUploadedFile: [],
    singleExpertUploadedFile: [],
    linkedItems: null,
    deleteFile: false,
    editMission: false,
    editMissionId: null,
    linkedItemId: null,
    travelFields: false,
    deploymentData: [],
    foreignEstablishment: false,
    doubleTaxation: [],

   edditContract:false,
   sinatureFiles:[],
   userHasSigned:false,
   fromPreviewToProfile:false,
  },

  mutations: {
    SET_ALL_CONTRACTS_TO_STATE(state: any, payload: any) {
      state.allContracts = payload;
    },
    SET_ALL_MISSIONS_TO_STATE(state: any, payload: any) {
      state.allMissions = payload;
    },
    SET_ALL_TRAVELS_TO_STATE(state: any, payload: any) {
      if (payload) {
        state.allTravels = payload;
      }
    },
    SET_ALL_DELIVERABLES_TO_STATE(state: any, payload: any) {
      state.allDeliverables = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_PROJECT_FILES_TO_STATE(state: any, payload: any) {
      state.projectFiles = payload;
    },
    SET_CONTRACT_FILES_TO_STATE(state: any, payload: any) {
      state.contractFiles = payload;
    },
    SET_CONTRACT_ID_FREELANCER_TO_STATE(state: any, payload: any) {
      state.contractIdFrelancer = payload;
    },
    SET_CONTRACT_ID_EMPLOYMENT_TO_STATE(state: any, payload: any) {
      state.contractIdEmployment = payload;
    },
    SET_CONTRACT_ID_PERSONNEL_TO_STATE(state: any, payload: any) {
      state.contractIdPersonnel = payload;
    },
    SET_EDIT_MISSION_ID_TO_STATE(state: any, payload: any) {
      state.editMissionId = payload;
    },
    SET_EDIT_MISSION_TO_STATE(state: any, payload: any) {
      state.editMission = payload;
    },

    SET_DELIVERABLES_PAGES_TO_STATE(state: any, payload: any) {
      state.deliverablePages = payload;
    },
    SET_OTHER_ALLOWANCE_PAGES_TO_STATE(state: any, payload: any) {
      state.otherAllowancePages = payload;
    },
    SET_CONTRACT_PAGES_TO_STATE(state: any, payload: any) {
      state.contractPages = payload;
    },
    SET_MISSION_PAGES_TO_STATE(state: any, payload: any) {
      state.missionPages = payload;
    },
    SET_ALL_FUNDS_TO_STATE(state: any, payload: any) {
      state.allFunds = payload;
    },
    SET_TRALVEL_COST_TO_STATE(state: any, payload: any) {
      state.travelcosts = payload;
    },
    SET_ALL_OTHER_ALLOWANCE_TO_STATE(state: any, payload: any) {
      state.allOtherAllowance = payload;
    },
    SET_CONTRACT_LINKED_TO_STATE(state: any, payload: any) {
      state.linkedContract = payload;
    },
    SET_CONTRACT_VIEW_TO_STATE(state: any, payload: any) {
      state.contractView = payload;
    },
    SET_CONTRACT_EDIT_ID_TO_STATE(state: any, payload: any) {
      state.contractEditId = payload;
    },
    SET_CONTRACT_TYPE_TO_STATE(state: any, payload: any) {
      state.contractType = payload;
    },
    SET_EDIT_CONTRACT_TO_STATE(state: any, payload: any) {
      state.editContract = payload;
    },
    SET_CONTRACT_ADDENDUM_TO_STATE(state: any, payload: any) {
      state.createAddendum = payload;
    },
    SET_CONTRACT_ADDENDUM_ID_TO_STATE(state: any, payload: any) {
      state.addendumId = payload;
    },
    SET_CONTRACT_EDIT_ADDENDUM_TO_STATE(state: any, payload: any) {
      state.editAddendum = payload;
    },
    SET_CONTRACT_SINGLE_FILE_PROJECT_ID_TO_STATE(state: any, payload: any) {
      state.singleProjectUploadedFile = payload;
    },
    SET_CONTRACT_SINGLE_FILE_EXPERT_ID_TO_STATE(state: any, payload: any) {
      state.singleExpertUploadedFile = payload;
    },
    SET_CONTRACT_LINKED_ITEMS_TO_STATE(state: any, payload: any) {
      state.linkedItems = payload;
    },
    SET_CONTRACT_DELETE_FILE_TO_STATE(state: any, payload: any) {
      state.deleteFile = payload;
    },
    SET_LINKED_ITEM_ID_TO_STATE(state: any, payload: any) {
      state.linkedItemId = payload;
    },
    SET_TRALVEL_FIELDS_TO_STATE(state: any, payload: any) {
      state.travelFields = payload;
    },
    SET_DEPLOYMENT_DATA_TO_STATE(state: any, payload: any) {
      state.deploymentData = payload;
    },
    SET_FOREIGN_ESTABLISHMENT_TO_STATE(state: any, payload: any) {
      state.foreignEstablishment = payload;
    },
    SET_COUNTRY_DOUBLE_TAXATION_TO_STATE(state: any, payload: any) {
      state.doubleTaxation = payload;
    },
    SET_EDDITING_CONTRACT_TO_STATE(state: any, payload: any) {
      state.edditContract = payload;
    },
    SET_SIGNATURE_FILES_TO_STATE(state: any, payload: any) {
      state.sinatureFiles = payload;
    },
    SET_HAS_SIGNED_TO_STATE(state: any, payload: any) {
      state.userHasSigned = payload;
    },
    SET_PREVIEW_PROFILE_TO_STATE(state: any, payload: any) {
      state.fromPreviewToProfile = payload;
    },

    
  },
  getters: {
    isAllContracts(state: any) {
      return state.allContracts;
    },
    isPreviewToProfile(state: any) {
      return state.fromPreviewToProfile;
    },
    isAllMissions(state: any) {
      return state.allMissions;
    },
    isAllTravels(state: any) {
      return state.allTravels;
    },
    isAllDeliverables(state: any) {
      return state.allDeliverables;
    },
    isRetentionRate(state: any) {
      return state.retentionRate;
    },
    isAmountTypeOptions(state: any) {
      return state.amountTypeOptions;
    },
    isModeOfSubmission(state: any) {
      return state.modeOfSubmissionOptions;
    },
    isSubmitTo(state: any) {
      return state.submitTo;
    },
    isOtherAllowancesDropdown(state: any) {
      return state.otherAllowancesDropdown;
    },
    isProjectFiles(state: any) {
      return state.projectFiles;
    },
    isContractFiles(state: any) {
      return state.contractFiles;
    },
    isContractIdFrelancer(state: any) {
      return state.contractIdFrelancer;
    },
    isContractIdEmployment(state: any) {
      return state.contractIdEmployment;
    },
    isContractIdPersonnel(state: any) {
      return state.contractIdPersonnel;
    },
    isEditMissionId(state: any) {
      return state.editMissionId;
    },
    isEditMission(state: any) {
      return state.editMission;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isDeliverablePage(state: any) {
      return state.deliverablePages;
    },
    isOtherAllowancePages(state: any) {
      return state.otherAllowancePages;
    },
    isContractPage(state: any) {
      return state.contractPages;
    },
    isMissionPage(state: any) {
      return state.missionPages;
    },
    isAllFunds(state: any) {
      return state.allFunds;
    },
    isTravelCosts(state: any) {
      return state.travelcosts;
    },
    isAllOtherAllowance(state: any) {
      return state.allOtherAllowance;
    },
    isContractLinked(state: any) {
      return state.linkedContract;
    },
    isContractView(state: any) {
      return state.contractView;
    },
    isContractEditId(state: any) {
      return state.contractEditId;
    },
    isContractType(state: any) {
      return state.contractType;
    },
    isEditContract(state: any) {
      return state.editContract;
    },
    isAddendum(state: any) {
      return state.createAddendum;
    },
    isAddendumId(state: any) {
      return state.addendumId;
    },
    isEditAddendum(state: any) {
      return state.editAddendum;
    },
    isSingleProjectFile(state: any) {
      return state.singleProjectUploadedFile;
    },
    isSingleExpertFile(state: any) {
      return state.singleExpertUploadedFile;
    },
    isLinkedItems(state: any) {
      return state.linkedItems;
    },
    isDeleteFile(state: any) {
      return state.deleteFile;
    },
    isLinkedItemId(state: any) {
      return state.linkedItemId;
    },
    isTravelFields(state: any) {
      return state.travelFields;
    },
    isDeploymentData(state: any) {
      return state.deploymentData;
    },
    isForeignEstablishment(state: any) {
      return state.foreignEstablishment;
    },
    isDoubleTaxation(state: any) {
      return state.doubleTaxation;
    },
    isEdditingContract(state: any) {
      return state.edditContract;
    },
    isSignatureFile(state: any) {
      return state.sinatureFiles;
    },
    userHasSigned(state: any) {
      return state.userHasSigned;
    }
  },

  actions: {
    getAllContracts(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        projectID: number;
      }
    ) {
      Api()
        .get(
          getAllProjectContracts + `${payload.projectID}/${payload.currentPage}`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit("SET_ALL_CONTRACTS_TO_STATE", responseData);
        })
        .catch();
    },
    getAllTravels(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          getContractTravels + `${payload.contractID}/${payload.currentPage}`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit("SET_ALL_TRAVELS_TO_STATE", responseData);
        })
        .catch();
    },
    getSingleContractAddendum(
      context: ActionContext<any, any>,
      payload: {
        contractType: number;
        addendumId: number;
      }
    ) {
      Api()
        .get(
          getContractAddendumInfo +
            `${payload.contractType}/${payload.addendumId}/`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit(
            "SET_CONTRACT_EDIT_ADDENDUM_TO_STATE",
            responseData.data
          );
        })
        .catch();
    },
  },
};
