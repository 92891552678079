<template >
    <div>
<!-- <p>Comment *</p>
              <QuillEditor
              contentType="html"
              v-model:content="comment"
              :options="options"
              :style="editorStyles"
            /> -->
     
            <app-text-area 
        :label="$t('Comment *')"
        :placeholder="$t('leaves.addComment')"
        type="text"
        v-model="comment"
      ></app-text-area>
            <v-row class=" mt-1 mb-2">
              <v-col cols="4">

              </v-col>
                <v-col cols="4">
                  <app-secondary-button
                    :buttonLabel="$t('projects.cancel')"
                    @click="emitHideDialog()"
                  >
                  </app-secondary-button>
                </v-col>
                <v-col cols="4">
                  <app-primary-button
                    :buttonLabel="$t('Disapprove')"
                    :loading="loading"
                    @click-event="disApproval()"
                    :disabled="!comment"
                  >
                  </app-primary-button>
                </v-col>
              </v-row>
              <app-alert
              :title="$t(getTranslatedStringKey(alertTitle))"
              @close-alert="alertState = false"
              :state="alertState"
              :timeout="5000"
              :type="alertType"
            />
    </div>
</template>
<script>
// import { QuillEditor } from "@vueup/vue-quill";
import API from "@/services/api";
import {previewApproval } from "@/services/endpoints";
import { getTransString } from "@/services/helpers";
export default {
    name: "DisapprovalComment",
    components: {
        // QuillEditor
    },

data() {
    return {
        options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: "Enter a comment",
      },
      editorStyles: {
        minHeight: "180px",
   
        fontSize: "13px",
      },
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      title: "",
      comment:""
    }
},
computed: {
    isContractEditId() {
      return this.$store.getters["contractModules/isContractEditId"];
    },
    isContractType() {
      return this.$store.getters["contractModules/isContractType"];
    },

    
  },
methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
emitHideDialog() {
    this.$emit("disapproved");
    },
    disApproval() {
    const payload = {
    contract_id:this.isContractEditId,
    contract_type: this.isContractType,
    approval_status:"Rejected",
    comment:this.comment
      };
      this.loading = true;
      API()
        .post(previewApproval, payload)
        .then((res) => {
          if (res.data.status === "success") {
            this.alertType = "success";
            this.alertTitle = res.data.detail;
            this.alertState = true;
            this.loading = false;
           this.emitHideDialog();
          }
        })
        .catch((err) => {
          this.alertType = "error";
          this.alertTitle = err.response.data.detail;
          this.alertState = true;
          this.loading = false;
        });
    },
  
},
}
</script>
<style scoped>
    
</style>