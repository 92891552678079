<template>
    <app-dialog
      ref="deleteMultipleDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteMultipleEmployees()"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  
</template>
<script>

import Api from "@/services/api";

import { mapState,} from "vuex";
import { getTransString } from "@/services/helpers";

import {  } from "@/services/endpoints";
export default {
  name: "DeleteMultipleEmployeeDialog",
  
 
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      isActivate: false,
      currentPages: 1,
      isReset: false,
      headers: null,
      tableData: [],
      userKey: null,
      userStatus: null,
      research: false,
     
     
      status: "Active",
      country: "",
    };
  },

  computed: {
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Foreign Employee"
      );
      return findModules?.length !== 0;
    },
  
  
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    
   
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    
  
    showDialog() {
        this.subTitle = "employees.areYouSure";
        this.title = "employees.deleteEmployees";
        this.cancelLabel = "employees.noKeepIt";
        this.$refs.deleteMultipleDialog.showDialog = true;
      
    },
  
    hideDialog() {
      this.$refs.deleteMultipleDialog.showDialog = false;
      
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";

    },
   

    deleteMultipleEmployees() {
      const payload = {
        user_keys: this.userKey,
      };

      Api()
        .post("/hr/employees/delete-multiple-employees/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            // this.getAllEmployees(this.currentPage);
            this.$store.dispatch('hrResources/getAllForeignEmployees', this.currentPage)

            this.$refs.deleteMultipleDialog.showDialog = false;
           
          }
          if (response.data.status === "error") {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
  
   
  },
 
};
</script>


