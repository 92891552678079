<template>
  <div
    v-if="getAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <v-sheet max-width="" class="bg-transparent mt-n5 mb-7">
  
      <v-row class="justify-space-between">
        <v-col>
          <div
            class="flex items-center h-full text-xl font-semibold leading-7 text-left text-gray-900 font-inter"
          >
            {{ $t("requestFundsTab.overview") }}
          </div>
        </v-col>
        <v-col cols="2">
          <div>
            
            <app-primary-button
              v-if="isEditButton"
              :buttonLabel="$t('requestFundsTab.requestFund')"
              @click="showRequestNewFundTab"
            >
              <template #icon>
                <v-icon icon="mdi-plus"></v-icon>
              </template>
            </app-primary-button>
          </div>
        </v-col>
      </v-row>
      <!-- <RequestOverview :overviewData="getOverviewStats"/> -->
      <funds-table
        class="mt-7"
        mode="view"
        :overViewData="getOverviewStats"
        :hideInput="true"
      ></funds-table>

      <v-row class="pa-0 mt-7">
        <v-col>
          <div>
            <app-table
              :headers="headers"
              :items="items"
              :showCheck="false"
              :showCursor="false"
              :hideMenu="true"
              :isRequestedFunds="true"
              @view-requested-fund-event="showDialog($event)"
            >
              <template #title>
                <h3 class="H500">
                  {{ $t("requestFundsTab.allFundRequests") }}
                </h3>
              </template>
              <template #actions>
                <app-single-select
                  class="mt-n4 w-[150px]"
                  :labelPlaceholder="$t('requestFundsTab.selectStatus')"
                  :options="statuses"
                  v-model="status"
                >
                </app-single-select>
              </template>
              <template #footer>
                <div class="w-full pb-2 pl-1">
                  <v-row justify="start">
                    <v-col
                      cols="1"
                      class="font-semibold text-center text-gray-700 font-inter text-14 leading-20"
                      >Total</v-col
                    >
                    <v-col
                      cols=""
                      class="pr-0 font-semibold text-right text-gray-700 font-inter text-14 leading-20"
                      >{{
                        getLanguageCurrency(
                          getTableStats?.total_requested_amount
                        )
                      }}</v-col
                    >
                    <v-col
                      cols=""
                      class="pr-0 font-semibold text-right text-gray-700 font-inter text-14 leading-20"
                      >{{
                        getLanguageCurrency(getTableStats?.total_reduced_amount)
                      }}</v-col
                    >
                    <v-col
                      cols=""
                      class="pr-0 font-semibold text-right text-gray-700 font-inter text-14 leading-20"
                      >{{
                        getLanguageCurrency(getTableStats?.total_amount)
                      }}</v-col
                    >
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                    <v-col
                      cols="1"
                      class="font-semibold text-gray-700 font-inter text-14 leading-20"
                    ></v-col>
                  </v-row>
                </div>
              </template>
            </app-table>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <app-dialog
      ref="dialogState"
      :title="$t('requestFundsTab.viewFundDetails')"
      subTitle="xjgjh"
      :width="1200"
    >
      <template #content>
        <div>
          <v-sheet max-width="" class="bg-transparent">
            <v-row class="justify-space-between">
              <v-col>
                <div
                  class="flex items-center h-full text-xl font-semibold leading-7 text-left text-gray-900 font-inter"
                >
                  {{ $t("requestFundsTab.period") }}
                </div>
              </v-col>
              <v-col cols="2">
                <div></div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <app-date-field
                  :label="$t('leaves.startDate')"
                  type="date"
                  :disabled="true"
                  :modelValue="getOneRequestedFundPeriod?.start_date"
                >
                </app-date-field>
              </v-col>
              <v-col cols="4">
                <app-date-field
                  :label="$t('leaves.endDate')"
                  type="date"
                  :disabled="true"
                  :modelValue="getOneRequestedFundPeriod?.end_date"
                >
                </app-date-field>
                {{ getOneRequestedFund?.end_date }}
              </v-col>
            </v-row>

            <v-row class="mt-4 pa-0">
              <v-col>
                <div>
                  <funds-table
                    mode="view"
                    :overViewData="getOneOverviewStats"
                  ></funds-table>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-4 pa-0">
              <v-col>
                <div>
                  <budget-item-table
                    mode="view"
                    :data="getOneRequestedFund.request_items"
                    :tableStats="getOneTableStats"
                  ></budget-item-table>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </template>
      <template #actions>
        <v-row class="flex justify-end px-4 mt-1 mb-5">
          <v-col cols="1" class="">
            <app-primary-button
              :buttonLabel="$t('employees.cancel')"
              @click="hideDialog()"
              class="cursor-pointer"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-dialog
      ref="dialog"
      :title="deleteTitle"
      :subtitle="deleteSubTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.noKeepIt')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="delContractTask"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
</template>
<script>
import { defineComponent } from "vue";
//import RequestOverview from "./requestedFunds/RequestOverview.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { currencyFormatter } from "@/services/helpers";
import FundsTable from "./requestedFunds/FundsTable.vue";
import BudgetItemTable from "./requestedFunds/BudgetItemTable.vue";
export default defineComponent({
  name: "RequestedFunds",
  components: {
    //  RequestOverview,
    FundsTable,
    BudgetItemTable,
  },
  data() {
    return {
      statuses: [
        {
          text: "All",
          value: "",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Disapproved",
          value: "Disapproved",
        },
      ],
      dialogState: false,
      dialog: false,
      deleteTitle: "Delete Task?",
      deleteSubTitle:
        "Are you sure you want to delete this task? This action can’t be undone.",
      status: "",
    };
  },
  computed: {
    ...mapGetters("pmLocalRequestFund", [
      "getAppLoading",
      "getAllRequestedFunds",
      "getOverviewStats",
      "getTableStats",
      "getOneRequestedFund",
      "getOneTableStats",
      "getOneOverviewStats",
      "getOneRequestedFundPeriod",
    ]),
    getRequestedFundsResponse() {
      return this.$store.getters["pmLocalRequestFund/getResponse"];
    },
    getRequestedFundsError() {
      return this.$store.getters["pmLocalRequestFund/getError"];
    },
    foreignEstablishment() {
      return this.$store.getters["foreignOfficeModule/isOfficeID"];
    },
    headers() {
      return [
        "Sr. No.",
        "Requested Amount",
        "Reduction",
        "Approved Amount",
        "Status",
        "Period",
        // "Invoice ID",
        "Transfer Date",
        "Transfer Via",
      ];
    },
    items() {
      return this.getAllRequestedFunds?.map((requestedFund, index) => {
        return {
          id: requestedFund?.id,
          "sr. no.": index + 1,
          "requested amount": this.getLanguageCurrency(
            requestedFund?.total_requested_amount
          ),
          reduction: this.getLanguageCurrency(requestedFund?.reduced_amount),
          "approved amount": this.getLanguageCurrency(
            requestedFund?.approved_amount
          ),
          comments: requestedFund?.comments,
          status: requestedFund?.approved_status,
          pending_to: requestedFund?.pending_to,
          period: `${this.formatDate(
            requestedFund?.start_date
          )} - ${this.formatDate(requestedFund?.end_date)}`,
          // "invoice id": requestedFund?.invoice_number,
          "transfer date": requestedFund?.transfer_date,
          "transfer via": requestedFund?.transfered_to,
        };
      });
    },
    appLang() {
      return this.translation.locale;
    },
    canEditPmLocalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"] && this.canEditPmLocalModule?.includes("Requested Funds")
    },
    ...mapState(["translation"]),
  },
  watch: {
    async status(newstatus) {
      await this.fetchAllRequestedFunds({
        foreignEstablishment: this.foreignEstablishment,
        status: newstatus,
      });
    },
  },
  methods: {
    ...mapActions("pmLocalRequestFund", [
      "fetchAllRequestedFunds",
      "fetchOneRequestedFund",
    ]),
    showRequestNewFundTab() {
      this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", true);
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 7);
    },
    formatDate(inputDate) {
      if(!inputDate){
        return ''
      }
      // Parse the input date
      const dateParts = inputDate?.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);

      // Format the date as "dd.mm.yyyy"
      const formattedDate = `${day}.${month}.${year}`;

      return formattedDate;
    },
    getLanguageCurrency(amount) {
      return this.appLang === "de"
        ? this.germanCurrency(amount)
        : amount?.formatCurrency();
    },

    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.$refs.dialog.showDialog = false;
    },
    async showDialog(payload) {
      await this.fetchOneRequestedFund({ foreignEstablishment: payload?.id });
      this.$refs.dialogState.showDialog = true;
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 6); //8
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
  },
  async mounted() {
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
    await this.fetchAllRequestedFunds({
      foreignEstablishment: this.foreignEstablishment,
      status: "",
    });

  },
});
</script>
