<template>
  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteProjectFile"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import { defineComponent } from 'vue'
import api from '@/services/api';
import { manageProjectFile } from "@/services/endpoints"
import { getTransString } from "@/services/helpers";

export default defineComponent({
    name: 'Delete Project Files',
    props: {
        fileId: {
            type: Number
        }
    },
    data() {
        return {
            alertType: "success",
            alertState: false,
            alertTitle: "",
            title: "",
            subTitle: "",
            cancelDelete: "Cancel",
        }
    },
    computed: {

        isProjectID() {
            return this.$store.getters["projectModules/isProjectID"];
        }
    },
    methods: {
        getTranslatedStringKey(field) {
            return getTransString(field);
        },
        showDialog() {
            this.$refs.dialog.showDialog = true
            this.subTitle = "projects.deleteFileConfirm";
            this.title = "projects.deleteFile";
            this.cancelDelete = "employees.noKeepIt";
        },

        hideDialog() {
            this.$refs.dialog.showDialog = false
        },
        deleteProjectFile() {
            api()
                .delete(`${manageProjectFile}${this.fileId}/`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.alertState = true;
                        this.alertTitle = response?.data?.details;

                        this.$refs.dialog.showDialog = false
                        const payload = {
                          project_id : this.isProjectID,
                          load_page: false
                        }
                        this.$store.dispatch("projectModules/getAllProjectFiles", payload);
                    }
                    if (response.data.status === "error") {
                        this.$refs.dialog.showDialog = false;
                        this.addTitle = "";
                        this.alertType = "error";
                        this.alertState = response?.data?.detail;
                        this.alertState = true;
                    }
                })
                .catch(error => {
                    if (error) {
                        this.$refs.dialog.showDialog = false;
                        this.alertType = "error";
                        this.alertState = error?.response?.data?.detail;
                        this.alertState = true;
                    }
                });
        }

    }
})
</script>
