<template>
  <div>
    <div>
      <app-header
        :name="$t('employees.myAnnouncements')"
        :show-arrow-back="false"
      >
        <template #action>
          <app-primary-button
            v-if="canEdit"
            :buttonLabel="$t('employees.newAnnouncements')"
            @click-event="showDialog({ data: 'add' })"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
      </app-header>
      <div class="mt-2 border-t-[1px]"></div>
      <div v-if="isSlicedAnnouncements?.length > 0">
        <div
          v-for="announcement in isSlicedAnnouncements"
          :key="announcement.dateCreated"
          class="flex items-center justify-between gap-52"
        >
          <MyAnnouncementsCard
            :title="announcement.title"
            :time="announcement.created_on"
            :content="announcement.description"
            :draft="announcement.status === 'Draft' ? announcement.status : ''"
          />
          <div>
            <DropDown
              @edit-event="
                showDialog({ $event, data: 'edit', id: announcement.id })
              "
              @delete-event="
                showDialog({ $event, data: 'delete', id: announcement.id })
              "
            />
          </div>
        </div>
        <button
          class="showMore"
          v-if="showMoreAnnoucement"
          @click="toggleAnnouncement"
        >
          {{
            expandAnnouncement
              ? $t("homeview.seeLess")
              : $t("homeview.showMore")
          }}
        </button>
      </div>
      <div class="empty_state" v-else>
        <EmptyState />
        <h3 class="H500">
          {{ $t("employees.noAnnouncementFound") }}
        </h3>
        <p class="P300">
          {{ $t("employees.youWillSee") }} {{ message }}
          {{ $t("employees.hereWhen") }}
        </p>
      </div>
    </div>

    <app-dialog
      ref="dialogState"
      :title="
        isEdit ? $t('employees.editAnnounce') : $t('employees.addAnnounce')
      "
      subTitle="xjgjh"
      :width="550"
    >
      <template #content>
        <app-input-field
          :placeholder="$t('projects.contractAddenda.enterTitle')"
          :label="$t('projects.contractAddenda.title') + '*'"
          v-model="announcementTitle"
        ></app-input-field>

        <div class="selectWrapper mt-[10px]">
          <label class="font-medium text-[14px] text-[#344054]">{{
            $t("employees.recipient") + "*"
          }}</label>
          <select
            name="recipient"
            id="recipient"
            v-model="selectedRecipient"
            class="mt-[8px]"
          >
            <option value="" selected>
              {{ $t("employees.selectRecipient") }}
            </option>
            <option
              v-for="(recipient, id) in selectRecipients"
              :key="id"
              :value="recipient.name"
            >
              {{ recipient.name }}
            </option>
          </select>
        </div>
        <multi-select
          :label="
            selectedRecipient === 'Team'
              ? $t('generalConfig.team') + '*'
              : $t('projectConfiguration.positions') + '*'
          "
          :labelPlaceholder="
            selectedRecipient === 'Team'
              ? $t('employees.selectTeam')
              : $t('projectConfiguration.selectPosition')
          "
          :searchPlaceholder="
            selectedRecipient === 'Team'
              ? $t('employees.searchTeam')
              : $t('employees.searchPosition')
          "
          :showSearch="true"
          :showCheckbox="true"
          v-model="receiveAnnouncement"
          :options="
            selectedRecipient === 'Team' ? getAllTeams : getAllPositions
          "
          @selected-options="
            selectedOptions({
              $event,
              data: selectedRecipient === 'Team' ? 'teams' : 'positions',
            })
          "
        ></multi-select>
        <div class="mt-[20px]">
          <p class="font-medium text-[14px] text-[#344054] mb-2">
            {{ $t("taskareas.content") + "*" }}
          </p>
          <QuillEditor
            contentType="html"
            v-model:content="editorContent"
            :options="options"
            :style="editorStyles"
          />
        </div>
      </template>
      <template #actions>
        <v-row class="flex items-center px-4 mt-1 mb-5">
          <v-col cols="4" class="flex justify-end">
            <p @click="hideDialog()" class="cursor-pointer">
              {{ $t("employees.cancel") }}
            </p>
          </v-col>
          <v-col cols="4" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.saveAsDraft')"
              @click-event="
                isEdit ? updateDraftAnnouncement() : saveAnnouncementAsDraft()
              "
              :disabled="
                editorContent === '' ||
                teams.legnth === 0 ||
                positions.legnth === 0 ||
                announcementTitle === ''
              "
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="4" class="">
            <app-primary-button
              :buttonLabel="$t('employees.saveAndSend')"
              @click-event="
                isEdit ? updateAnnouncementAndSend() : saveAndSendAnnouncement()
              "
              :disabled="
                editorContent === '' ||
                teams.legnth === 0 ||
                positions.legnth === 0 ||
                announcementTitle === '' ||
                selectedRecipient == ''
              "
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.noKeepIt')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteSingleAnnouncement()"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MyAnnouncementsCard from "@/components/dashboard/myannouncements/MyAnnouncementsCard.vue";
import DropDown from "@/components/shared/DropDown.vue";
import { getTransString } from "@/services/helpers";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Api from "@/services/api";
import {
  saveAnnouncementAsDraft,
  getMyAnnouncements,
  saveAndSendAnnouncement,
  updateAnnouncement,
  updateAndSendAnnouncement,
  deleteAnnouncement,
} from "@/services/endpoints";
import EmptyState from "@/assets/icons/EmptyState.vue";

export default defineComponent({
  name: "MyAnnouncement",
  components: {
    MyAnnouncementsCard,
    DropDown,
    QuillEditor,
    EmptyState,
  },
  data() {
    {
      return {
        announcementsArray: [],
        expandAnnouncement: false,
        initialAnnouncement: 6,
        isEdit: false,
        isDelete: false,
        title: "",
        subTitle: "",
        alertType: "success",
        alertState: false,
        alertTitle: "",
        dialogState: false,
        selectedRecipient: "",
        announcementId: "",
        selectRecipients: [
          {
            id: 1,
            name: "Team",
          },
          {
            id: 2,
            name: "Position",
          },
        ],
        announcementTitle: "",
        editorContent: "",
        options: {
          modules: {
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["blockquote"],
              ["link"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
          placeholder: "Enter announcement here",
        },
        editorStyles: {
          minHeight: "150px",
          padding: "10px, 14px",
          fontSize: "16px",
          maxHeight: "300px",
          "overflow-y": "auto",
        },
        positions: [],
        teams: [],
        selectedPositions: [],
        selectedTeams: [],
        receiveAnnouncement: []
      };
    }
  },
  watch: {},
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData.filter(
        (module) => module.module_name === "Announcement"
      );
      return findModules?.length === 0 ? false : true;
    },
    isSlicedAnnouncements() {
      return this.isAllAnnouncements?.slice(0, this.initialAnnouncement);
    },
    isAllAnnouncements() {
      const announce = [];
      const announcementsArray =
        this.$store.getters["hrResources/isAllAnnouncements"];
      announcementsArray.map((singleAnnouncement) => {
        announce.push(singleAnnouncement);
      });
      return announce;
    },
    getAllPositions() {
      const positions = this.$store.getters["hrConfigurations/isPositions"];
      return positions.map((position) => ({
        text: position.title,
        value: position.id,
      }));
    },
    getAllTeams() {
      const teams = this.$store.getters["hrConfigurations/isTeams"];
      return teams.map((team) => ({
        text: team.title,
        value: team.id,
      }));
    },
  },
  methods: {
    teamOrPosition() {
      return this.selectedRecipient === "Team" ? this.teams : this.positions;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showMoreAnnoucement() {
      return (
        this.announcementsArray.length > this.initialAnnouncement ||
        this.expandAnnouncement
      );
    },
    toggleAnnouncement() {
      this.expandAnnouncement = !this.expandAnnouncement;
      if (this.expandAnnouncement) {
        this.initialAnnouncement = this.isAllAnnouncements.length;
      } else {
        this.initialAnnouncement = 6;
      }
    },
    clearFields() {
      this.selectedRecipient = "";
      this.announcementTitle = "";
      this.editorContent = "";
      this.teams = [];
      this.positions = [];
      this.receiveAnnouncement= []
    },
    showDialog(data) {
      if (data.data === "add") {
        this.isEdit = false;
        this.$refs.dialogState.showDialog = true;
      } else if (data.data === "delete") {
        this.announcementId = data.id;
        this.$refs.dialog.showDialog = true;
        this.title = "employees.deleteAnnounce";
        this.subTitle = "employees.deleteAnnounceConfirm";
      }
      if (data.data === "edit") {
        this.isEdit = true;
        this.$refs.dialogState.showDialog = true;
        const selectedAnnouncement = this.isAllAnnouncements
          .map((announce) => announce)
          .find((announce) => announce.id === data.id);
        this.announcementTitle = selectedAnnouncement.title;
        this.editorContent = selectedAnnouncement.description;
        this.selectedRecipient = selectedAnnouncement.recipient;
        this.announcementId = selectedAnnouncement.id;
        if (this.selectedRecipient === "Team") {
          this.receiveAnnouncement = selectedAnnouncement.team_or_position || [];
        }
        if (this.selectedRecipient === "Position") {
          this.receiveAnnouncement = selectedAnnouncement.team_or_position || [];
        }
      }
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.clearFields();
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    selectedOptions(data) {
      if (data.data === "positions") {
        this.receiveAnnouncement = data?.$event;
      } else if (data.data === "teams") {
        this.receiveAnnouncement = data?.$event;
      }
    },
    getAnnoucements() {
      Api()
        .get(`${getMyAnnouncements}`)
        .then((response) => {
          if (response.data.status === "success") {
            const announcements = response.data?.data;
            this.$store.commit(
              "hrResources/SET_ALL_ANNOUNCEMENTS_TO_STATE",
              announcements
            );
          }
        })
        .catch();
    },
    saveAnnouncementAsDraft() {
      const payload = {
        title: this.announcementTitle,
        description: this.editorContent,
        recipient: this.selectedRecipient,
        team_or_position: this.receiveAnnouncement?.getObjectValues(),
      };
      Api()
        .post(`${saveAnnouncementAsDraft}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAnnoucements();
            this.clearFields();
          }
        })
        .catch();
    },
    saveAndSendAnnouncement() {
     
      const payload = {
        title: this.announcementTitle,
        description: this.editorContent,
        recipient: this.selectedRecipient,
        team_or_position: this.receiveAnnouncement?.getObjectValues(),
      };
      Api()
        .post(`${saveAndSendAnnouncement}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAnnoucements();
            this.clearFields();
          }
        })
        .catch();
    },
    updateDraftAnnouncement() {
      const payload = {
        title: this.announcementTitle,
        description: this.editorContent,
        recipient: this.selectedRecipient,
        team_or_position: this.receiveAnnouncement?.getObjectValues(),
      };
      Api()
        .put(`${updateAnnouncement}${this.announcementId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAnnoucements();
            this.clearFields();
          }
        })
        .catch();
    },
    updateAnnouncementAndSend() {
      const payload = {
        title: this.announcementTitle,
        description: this.editorContent,
        recipient: this.selectedRecipient,
        team_or_position: this.receiveAnnouncement?.getObjectValues(),
      };
      Api()
        .put(`${updateAndSendAnnouncement}${this.announcementId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAnnoucements();
            this.clearFields();
          }
        })
        .catch();
    },
    deleteSingleAnnouncement() {
      Api()
        .delete(`${deleteAnnouncement}${this.announcementId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.getAnnoucements();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.getAnnoucements();
  },
});
</script>

<style>
.showMore {
  color: #8b2923;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.selectWrapper > select {
  border: 1px solid #d0d5dd;
  padding: 0.4rem 0.5rem;
  color: #667085;
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 2.5rem;
}
.empty_state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding: 20px;
  flex-direction: column;
  min-height: 400px;
}
</style>
