<template>
  <app-alert :title="$t(alertState.message.getTranslationKey())" :state="alertState.show" :type="alertState.type"></app-alert>
  <div>
    <div class="flex">
      <div class="flex w-4/5 gap-4">
        <div v-for="(label, index) in buttonLabel" :key="label">
          <app-secondary-button
            :buttonLabel="label.getTranslationKey()"
            :class="[
              '!font-medium ',
              { 'active-button !text-[#A2544F]': activeButton === label },
            ]"
            @click="handleOnClick(label, index)"
          >
          </app-secondary-button>
        </div>
      </div>
      <app-secondary-button
        :buttonLabel="$t('All Missions'.getTranslationKey())"
        @click="$emit('showAllMissions', showAllMissions)"
      ></app-secondary-button>
    </div>
    <div class="mt-8">
        <div v-if="activeButton === 'Mission Overview'">
            <DeploymentAgreement/>
        </div>
        <div v-else-if="activeButton === 'Invoice Settlement'">
            <InvoiceSettlementAccordion/>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DeploymentAgreement from "@/views/dashboard/projects/DeploymentAgreement.vue";
import InvoiceSettlementAccordion from "./localExpert/InvoiceSettlementAccordion.vue";
import Api from "@/services/api";
import {getExpertMissionPlanningInvoices} from "@/services/endpoints";
import {getAllExpertMissionPlanningInvoices} from "@/services/missionsApi";
export default defineComponent({
  name: "ExpertMissionOverview",
  components: {
    DeploymentAgreement,
    InvoiceSettlementAccordion
  },
  props: {},
  data() {
    return {
      activeButton: null,
      buttonLabel: ["Mission Overview", "Invoice Settlement"],
      alertState: {
        show: false,
        type: "",
        message: "",
      }
    };
  },
  computed: {
    missionId() {
      return this.$store.getters["contractModules/isEditMissionId"];
    },
  },
  methods: {
    handleOnClick(label) {
      this.activeButton = label;
    },
  },
  mounted() {
    getAllExpertMissionPlanningInvoices(this.missionId).then((res) => {
      this.$store.dispatch("projectModules/formatInvoiceData", res)
    }).catch((err) => {
      this.alertState.show = true;
      this.alertState.type = "error";
      this.alertState.message = err.response?.data?.message || "Error occurred while fetching invoice data";

      setTimeout(() => {
        this.alertState.show = false;
      }, 3000);
    });
  },
  created() {
    this.activeButton = this.buttonLabel[0];
    this.handleOnClick(this.buttonLabel[0], 0);
  },
});
</script>

<style scoped>
.active-button {
  background-color: #f4eae9 !important;
  border-radius: 8px !important;
}
</style>
