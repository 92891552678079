export const countryCitizens = {
  Afghanistan: "Afghan",
  Albania: "Albanian",
  Algeria: "Algerian",
  Andorra: "Andorran",
  Abkhazia: "Abkhazian",
  Artsakh: "Artsakhi",
  Angola: "Angolan",
  AntiguaAndBarbuda: "Antiguan/Barbudan",
  Argentina: "Argentinian",
  Armenia: "Armenian",
  Australia: "Australian",
  Austria: "Austrian",
  Azerbaijan: "Azerbaijani",
  Bahamas: "Bahamian",
  Bahrain: "Bahraini",
  Bangladesh: "Bangladeshi",
  Barbados: "Barbadian",
  Belarus: "Belarusian",
  Belgium: "Belgian",
  Belize: "Belizean",
  Benin: "Beninese",
  Bhutan: "Bhutanese",
  Bolivia: "Bolivian",
  BosniaAndHerzegovina: "Bosnian/Herzegovinian",
  Botswana: "Botswanan",
  BritishIndianOceanTerritory: "Chagossian",
  BouvetIsland: "Norwegian",
  Brazil: "Brazilian",
  Brunei: "Bruneian",
  Bulgaria: "Bulgarian",
  BurkinaFaso: "Burkinabe",
  Burundi: "Burundian",
  Cambodia: "Cambodian",
  Cameroon: "Cameroonian",
  CookIslands: "Cook Islander",
  Canada: "Canadian",
  CapeVerde: "Cape Verdean",
  CentralAfricanRepublic: "Central African",
  Chad: "Chadian",
  Chile: "Chilean",
  China: "Chinese",
  Colombia: "Colombian",
  Comoros: "Comorian",
  Congo: "Congolese",
  CostaRica: "Costa Rican",
  Croatia: "Croatian",
  Cuba: "Cuban",
  Cyprus: "Cypriot",
  CzechRepublic: "Czech",
  Denmark: "Danish",
  Djibouti: "Djiboutian",
  Dominica: "Dominican",
  DominicanRepublic: "Dominican",
  EastTimor: "East Timorese",
  Ecuador: "Ecuadorian",
  Egypt: "Egyptian",
  ElSalvador: "Salvadoran",
  EquatorialGuinea: "Equatorial Guinean",
  Eritrea: "Eritrean",
  Estonia: "Estonian",
  Eswatini: "Swazi",
  Ethiopia: "Ethiopian",
  Fiji: "Fijian",
  Finland: "Finnish",
  FederatedStatesOfMicronesia: "Micronesian",
  France: "French",
  Gabon: "Gabonese",
  Gambia: "Gambian",
  Georgia: "Georgian",
  Germany: "German",
  Ghana: "Ghanaian",
  Greenland: "Greenlander",
  Greece: "Greek",
  Grenada: "Grenadian",
  Guatemala: "Guatemalan",
  Guinea: "Guinean",
  GuineaBissau: "Guinea-Bissauan",
  Guyana: "Guyanese",
  Haiti: "Haitian",
  Honduras: "Honduran",
  Hungary: "Hungarian",
  Iceland: "Icelander",
  India: "Indian",
  Indonesia: "Indonesian",
  Iran: "Iranian",
  Iraq: "Iraqi",
  Ireland: "Irish",
  Israel: "Israeli",
  Italy: "Italian",
  IvoryCoast: "Ivorian",
  Jamaica: "Jamaican",
  Japan: "Japanese",
  Jordan: "Jordanian",
  Kazakhstan: "Kazakhstani",
  Kenya: "Kenyan",
  Kiribati: "I-Kiribati",
  Kuwait: "Kuwaiti",
  Kyrgyzstan: "Kyrgyzstani",
  Laos: "Laotian",
  Latvia: "Latvian",
  Lebanon: "Lebanese",
  Lesotho: "Basotho",
  Liberia: "Liberian",
  Libya: "Libyan",
  Liechtenstein: "Liechtensteiner",
  Lithuania: "Lithuanian",
  Luxembourg: "Luxembourger",
  Madagascar: "Malagasy",
  Malawi: "Malawian",
  Malaysia: "Malaysian",
  Maldives: "Maldivian",
  Mali: "Malian",
  Malta: "Maltese",
  MarshallIslands: "Marshallese",
  Mauritania: "Mauritanian",
  Mauritius: "Mauritian",
  Mexico: "Mexican",
  Moldova: "Moldovan",
  Monaco: "Monégasque",
  Mongolia: "Mongolian",
  Montenegro: "Montenegrin",
  Morocco: "Moroccan",
  Mozambique: "Mozambican",
  Myanmar: "Burmese",
  Namibia: "Namibian",
  Nauru: "Nauruan",
  Nepal: "Nepali",
  Netherlands: "Dutch",
  NewZealand: "New Zealander",
  Nicaragua: "Nicaraguan",
  Niger: "Nigerien",
  Nigeria: "Nigerian",
  NorthKorea: "North Korean",
  NorthMacedonia: "Macedonian",
  Norway: "Norwegian",
  Oman: "Omani",
  Pakistan: "Pakistani",
  Palau: "Palauan",
  Palestine: "Palestinian",
  Panama: "Panamanian",
  PapuaNewGuinea: "Papua New Guinean",
  Paraguay: "Paraguayan",
  Peru: "Peruvian",
  Philippines: "Filipino",
  Poland: "Polish",
  Portugal: "Portuguese",
  Qatar: "Qatari",
  Romania: "Romanian",
  Russia: "Russian",
  Rwanda: "Rwandan",
  SaintKittsAndNevis: "Kittitian/Nevisian",
  SaintLucia: "Saint Lucian",
  SaintVincentAndTheGrenadines: "Saint Vincentian",
  Samoa: "Samoan",
  SanMarino: "Sammarinese",
  SaoTomeAndPrincipe: "São Toméan",
  SaudiArabia: "Saudi Arabian",
  Senegal: "Senegalese",
  Serbia: "Serbian",
  Seychelles: "Seychellois",
  SierraLeone: "Sierra Leonean",
  Singapore: "Singaporean",
  Slovakia: "Slovak",
  Slovenia: "Slovenian",
  SolomonIslands: "Solomon Islander",
  Somalia: "Somali",
  SouthAfrica: "South African",
  SouthKorea: "South Korean",
  SouthSudan: "South Sudanese",
  Spain: "Spanish",
  SriLanka: "Sri Lankan",
  Sudan: "Sudanese",
  Suriname: "Surinamese",
  Sweden: "Swedish",
  Switzerland: "Swiss",
  Syria: "Syrian",
  Tajikistan: "Tajikistani",
  Tanzania: "Tanzanian",
  Thailand: "Thai",
  TimorLeste: "East Timorese",
  Togo: "Togolese",
  Tonga: "Tongan",
  TrinidadAndTobago: "Trinidadian/Tobagonian",
  Tunisia: "Tunisian",
  Turkey: "Turkish",
  Turkmenistan: "Turkmen",
  Tuvalu: "Tuvaluan",
  Uganda: "Ugandan",
  Ukraine: "Ukrainian",
  UnitedArabEmirates: "Emirati",
  UnitedKingdom: "British",
  UnitedStates: "American",
  Uruguay: "Uruguayan",
  Uzbekistan: "Uzbekistani",
  Vanuatu: "Vanuatuan",
  VaticanCity: "Vatican",
  Venezuela: "Venezuelan",
  Vietnam: "Vietnamese",
  Yemen: "Yemeni",
  Zambia: "Zambian",
  Zimbabwe: "Zimbabwean",
};
