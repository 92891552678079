<template>
  <div>
    <app-header :name="$t('employees.employees')" :show-arrow-back="false" />
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="2" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @update-pages="updateCurrentPages"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field
          class="px-2"
          :options="isPositionsTitle"
          :isTranslation="true"
          placeholder="All"
          :label="$t('employment.positions')"
          v-model="position"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field
          class="px-2"
          :options="isCompaniesTitle"
          :isTranslation="true"
          placeholder="All"
          :label="$t('employees.companies')"
          v-model="company"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field
          class="px-2"
          :options="isEmploymentTypesTitle"
          :isTranslation="true"
          placeholder="All"
          :label="$t('employees.employment')"
          v-model="employmentType"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field
          class="px-2"
          :options="isTeamsTitle"
          :isTranslation="true"
          placeholder="All"
          :label="$t('employees.teams')"
          v-model="team"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field-object
          class="px-2"
          :options="isStatusTitle"
          :label="$t('employees.status')"
          :isTranslation="true"
          v-model="status"
        />
      </v-col>
    </v-row>
    <div class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="isTableHeaders"
        :items="isAllEmployees"
        :showCheck="canEdit"
        :isEmployees="true"
        :hideMenu="!canEdit"
        @welcome-email="sendEmail({ $event, data: 'welcome-email' })"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @reset-event="showResetPasswordDialog({ $event, data: 'reset' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        @delete-multiple-event="
          showDeleteMultipleDialog({ $event, data: 'multiple' })
        "
      >
        <template #title>
          <h3 class="H500">{{ $t("employees.allEmployees") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            :buttonLabel="$t('employees.addEmployee')"
            @click="addEmployee"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <!-- dialog for password reset -->
    <app-dialog
      ref="resetPasswordDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('employees.newPassword')"
          :placeholder="$t('employees.setNewPassword')"
          v-model="newPassword"
          :hasError="hasPasswordError"
          :errorMessage="$t(errorPasswordMessage)"
          @input="hideError"
        ></app-input-field>
        <app-input-field
          class="mt-5"
          :label="$t('employees.confirmNewPassword')"
          :placeholder="$t('employees.confirmPassword')"
          v-model="confirmPassword"
          :hasError="hasConfirmPasswordError"
          :errorMessage="errorConfirmPassword"
          @input="hideError"
        ></app-input-field>
      </template>

      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideResetPasswordDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :loading="loading"
              :buttonLabel="$t('employees.resetPassword')"
              @click-event="resetEmployeePassword()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for deleting and deactivating user -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="isDelete ? deleteEmployee() : updateEmployeeStatus()"
            >
              <!-- Yes , delete -->
              {{
                isActivate
                  ? $t("employees.activate")
                  : isDelete
                  ? $t("employees.yesDelete")
                  : $t("employees.yesDeactivate")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="deleteMultipleDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hidemultipleDelete()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteMultipleEmployees"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { useI18n } from "vue-i18n";
import { mapState, useStore } from "vuex";
import { getTransString } from "@/services/helpers";
import AppSelectFieldObject from "@/components/shared/AppSelectFieldWithObject.vue";

export default {
  name: "EmployeeLayout",
  components: {
    AppSelectFieldObject,
    AppHeader,
    Paginator,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const addAllPlaceholder = (data) => {
      return ["All", ...data];
    };
    const handleFilterSet = (type, args, removeFilter, setFilter) => {
      if (
        t(getTransString(args[0])) === t(getTransString("All")) ||
        args[0] === ""
      ) {
        store.commit(removeFilter, type);
        // getAllEmployees(1);
      } else {
        store.commit(setFilter, [type, args[0]]);
      }
    };

    const getAllEmployees = (page) => {
      Api()
        .get(`/hr/employees/get-all-employees/Employee/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            // this.headers = response.data.headers;
            const data = response.data.data;

            // convert the keys of the objects to lowercase
            const payload = data?.map(function (obj) {
              return Object.keys(obj).reduce(function (newObj, key) {
                newObj[key.toLowerCase()] = obj[key];
                return newObj;
              }, {});
            });

            store.commit("hrResources/SET_EMPLOYEES_TO_STATE", payload);
            store.commit(
              "hrResources/SET_TABLE_HEADERS_TO_STATE",
              response.data.headers
            );
            store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    };

    return {
      addAllPlaceholder,
      handleFilterSet,
      getAllEmployees,
    };
  },
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      isActivate: false,
      currentPages: 1,
      isReset: false,
      headers: null,
      tableData: [],
      userKey: null,
      userStatus: null,
      research: false,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",
      position: "",
      company: "",
      employmentType: "",
      team: "",
      status: "Active",
    };
  },
  watch: {
    position(...args) {
      this.handleFilterSet(
        "position",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
    team(...args) {
      this.handleFilterSet(
        "team",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
    company(...args) {
      this.handleFilterSet(
        "company",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
    employmentType(...args) {
      this.handleFilterSet(
        "employment_type",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
    status(...args) {
      this.handleFilterSet(
        "is_active",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
  },
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData.filter(
        (module) => module.module_name === "Employee"
      );
      return findModules?.length !== 0;
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllEmployees"];
    },
    isTableHeaders() {
      return this.$store.getters["hrResources/isTableHeaders"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    isCompaniesTitle() {
      return this.$store.getters["hrConfigurations/isCompaniesTitle"];
    },
    isPositionsTitle() {
      return this.$store.getters["hrConfigurations/isPositionsTitle"];
    },
    isEmploymentTypesTitle() {
      return this.$store.getters["hrConfigurations/isEmploymentTypesTitle"];
    },
    isTeamsTitle() {
      return this.$store.getters["hrConfigurations/isTeamsTitle"];
    },
    isStatusTitle() {
      return [
        { id: "Active", value: "Active" },
        { id: "Inactive", value: "Inactive" },
      ];
    },
    isFilterBys() {
      return this.$store.getters["hrConfigurations/isFilterBys"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    updateCurrentPages() {
      this.currentPages = 1;
    },
    getAllSettings(type, stateType) {
      Api()
        .get(`/configuration/general/get-general/${type}/`)
        .then((response) => {
          const payload = response.data;
          this.items = payload;
          this.$store.commit(`hrConfigurations/${stateType}`, payload.data);
        });
    },
    addEmployee() {
      this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);
      this.$store.commit("hrResources/SET_USER_KEY_TO_STATE", null);
      this.$router.push({ name: "addEmployee" });
    },
    getFiltersData(fields) {
      return this.isTableHeaders?.reduce((acc, curr) => {
        if (fields.includes(curr)) return [...acc, { id: curr, value: curr }];
        else return acc;
      }, []);
    },
    hideError() {
      this.hasConfirmPasswordError = false;
      this.errorConfirmPassword = "";
      this.hasPasswordError = false;
      this.errorPasswordMessage = "";
    },
    showDialog(data) {
      this.userKey = data?.$event?.user_key;
      this.userStatus = !data?.$event?.is_active;
      if (data.data === "edit") {
        if (data?.$event?.is_active === false) {
          this.title = "employees.activate";
          this.subTitle = "employees.activatingThis";
          this.isEdit = !this.isEdit;
          this.isActivate = true;
          this.cancelLabel = "employees.noKeepIt";
          this.$refs.dialog.showDialog = true;
        } else {
          this.title = "employees.deactivateAccount";
          this.isActivate = false;
          this.subTitle = "employees.deactivatingThis";
          this.isEdit = !this.isEdit;
          this.cancelLabel = "employees.noKeepIt";
          this.$refs.dialog.showDialog = true;
        }
      } else {
        this.subTitle = "employees.areYouSure";
        this.title = "employees.deleteEmployees";
        this.isDelete = !this.isDelete;
        this.cancelLabel = "employees.noKeepIt";
        this.$refs.dialog.showDialog = true;
        this.isActivate = false;
      }
    },
    showDeleteMultipleDialog(data) {
      if (data.data === "multiple") {
        this.userKey = data?.$event;
        this.subTitle = "employees.areYouSure";
        this.title = "employees.deleteEmployees";
        this.cancelLabel = "employees.noKeepIt";
        this.$refs.deleteMultipleDialog.showDialog = true;
      }
    },
    showResetPasswordDialog(data) {
      this.userKey = data?.$event?.user_key;
      this.newPassword = "";
      this.confirmPassword = "";
      this.subTitle = "";
      this.cancelLabel = "employees.cancel";
      if (data.data === "reset") {
        this.title = "employees.resetPassword";
        this.buttonLabel = "employees.resetPassword";
        this.isReset = !this.isReset;
        this.$refs.resetPasswordDialog.showDialog = true;
      }
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },
    hidemultipleDelete() {
      this.$refs.deleteMultipleDialog.showDialog = false;
      setTimeout(() => {
        this.isEdit = false;
        this.isDelete = false;
        this.isActivate = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },
    hideResetPasswordDialog() {
      this.$refs.resetPasswordDialog.showDialog = false;
      setTimeout(() => {
        this.isReset = false;
        this.subTitle = "";
        this.addTitle = "";
        this.buttonLabel = "employees.add";
        this.cancelLabel = "employees.cancel";
      }, 600);
    },
    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllEmployees(page);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    resetEmployeePassword() {
      this.loading = true;
      if (!this.newPassword) {
        this.loading = false;
        this.hasPasswordError = true;
        this.errorPasswordMessage = "employees.thisFieldIs";
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.loading = false;
        this.hasConfirmPasswordError = true;
        this.errorConfirmPassword = "employees.passwordDoesNot";
        return;
      }
      const payload = {
        password: this.newPassword,
        confirm_password: this.confirmPassword,
      };
      Api()
        .put(`/hr/employees/reset-password/${this.userKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            setTimeout(() => {
              this.addTitle = "";
              this.$refs.resetPasswordDialog.showDialog = false;
            }, 1000);
          }
          if (response.data.status === "error") {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateEmployeeStatus() {
      const payload = {
        status: this.userStatus,
      };
      Api()
        .patch(`/hr/employees/update-employee-status/${this.userKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
              this.isEdit = !this.isEdit;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isEdit = !this.isEdit;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteEmployee() {
      Api()
        .delete(`/hr/employees/delete-employee/${this.userKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.isDelete = !this.isDelete;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteMultipleEmployees() {
      const payload = {
        user_keys: this.userKey,
      };

      Api()
        .post("/hr/employees/delete-multiple-employees/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            this.$refs.deleteMultipleDialog.showDialog = false;
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
            }, 1000);
          }
          if (response.data.status === "error") {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllEmployees(page);
    },
    sendEmail(data) {
      this.userKey = data?.$event?.user_key;
      const payload = {
        user_key: data?.$event?.user_key,
      };
      Api()
        .post("/auth/send-employee-credentials-email/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.detail;
            this.getAllEmployees(this.currentPage);
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error?.response?.data?.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    checkIfInactiveEmployeesDisplayed() {
      const employees = this.isAllEmployees.find(
        (elt) => elt?.is_active === false
      );
      if (Object.keys(employees || {}).length) {
        this.$store.commit("hrResources/SET_EMPLOYEES_TO_STATE", []);
      }
    },
  },
  created() {
    this.$store.commit("hrResources/RESET_FILTER_BYS_FROM_STATE", []);
    this.checkIfInactiveEmployeesDisplayed();
    this.getAllEmployees(this.currentPage);
    this.getAllSettings("Positions", "SET_POSITIONS_TO_STATE");
    this.getAllSettings("Companies", "SET_COMPANIES_TO_STATE");
    this.getAllSettings("Teams", "SET_TEAMS_TO_STATE");
    this.getAllSettings("Employment Types", "SET_EMPLOYMENT_TYPES_TO_STATE");
  },
};
</script>

<style scoped>
label {
  color: #344054;
}
</style>
