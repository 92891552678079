import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllAdvancementPaymentRequest,
  searchAdvancementPaymentRequest,
  getSingleAdvancementPaymentRequest,
  getAllEmployeesNonPaginated,
  getAllExpertOrCompanyContract
} from "@/services/endpoints";
export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allAdvancePaymentRequests: [],
    currentAdvancePaymentRequests: {},
    currentAdvancePaymentRequestsId: null,
    advancePaymentRequestsPackageUpdate: {},
    allExpertOrCompanyContracts: [],
    allEmployees: [],
    allExperts: [],
    advancePaymentRequestForm: {},
    headers: [
      
      "Date",
      "Requester",
      "Requested",
      "Condition on Account",
      "Status",
    ],
  },
  mutations: {
    SET_ADVANCE_PAYMENT_TO_STATE(state: any, payload: any) {
      state.allAdvancePaymentRequests = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_ADVANCE_PAYMENT_TO_STATE(state: any, payload: any) {
      state.currentAdvancePaymentRequests = payload;
    },
    SET_CURRENT_ADVANCE_PAYMENT_ID_TO_STATE(state: any, payload: any) {
      state.currentAdvancePaymentRequestsId = payload;
    },
    SET_ALL_EMPLOYEES_TO_STATE(state: any, payload: any) {
      state.allEmployees = payload;
    },
    SET_ALL_EXPERTS_TO_STATE(state: any, payload: any) {
      state.allExperts = payload;
    },
    SET_ADVANCE_PAYMENT_UPDATE(state: any, payload: any) {
      state.advancePaymentRequestsPackageUpdate = {
        ...state.advancePaymentRequestsPackageUpdate,
        ...payload,
      };
    },
    SET_ALL_EXPERT_OR_COMPANY_CONTRACTS_TO_STATE(state: any, payload: any) {
      state.allExpertOrCompanyContracts = payload;
    },
    SET_ADVANCEMENT_PAYMENT_REQUEST_FORM(state: any, payload: any) {
      state.advancePaymentRequestForm = {
        ...state.advancePaymentRequestForm,
        ...payload,
      };
    }

  },
  getters: {
    allEmployees(state: any) {
      return state.allEmployees.map((employee: any) => {
        return {
          text: employee.full_name,
          value: employee.id,
        };
      });
    },
    allExperts(state: any) {
      return state.allExperts.map((expert: any) => {
        return {
          text: expert.full_name,
          value: expert.id,
        };
      });
    },
    allContracts(state: any) {
      return state.allExpertOrCompanyContracts
    },
    allAdvancePaymentRequest(state: any) {
      return state.allAdvancePaymentRequests;
    },
    headers(state: any) {
      return state.headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    currentPaymentRequests(state: any) {
      return state.currentAdvancePaymentRequests;
    },
    currentAdvancePaymentId(state: any) {
      return state.currentAdvancePaymentRequestsId;
    },

    advancePaymentUpdate(state: any) {
      return state.advancePaymentRequestsPackageUpdate;
    },
    advancePaymentRequestForm(state: any) {
      return state.advancePaymentRequestForm;
    },
  },
  actions: {
    getAllAdvancePaymentRequest(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllAdvancementPaymentRequest}${payload.type}/${payload.page_number}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ADVANCE_PAYMENT_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
   
    getAllExpertsOrEmployees(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getAllEmployeesNonPaginated}${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            if (payload === "Employee") {
              context.commit("SET_ALL_EMPLOYEES_TO_STATE", response.data.data);
            } else {
              context.commit("SET_ALL_EXPERTS_TO_STATE", response.data.data);
            }
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllExpertOrCompanyContract(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getAllExpertOrCompanyContract}${payload.name}/${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_EXPERT_OR_COMPANY_CONTRACTS_TO_STATE", response.data.data);
            
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    searchAdvancPayment(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(
          `${searchAdvancementPaymentRequest}${context.getters.isCurrentPage}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_ADVANCE_PAYMENT_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    getSingleAdvancementPaymentRequest(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSingleAdvancementPaymentRequest}${context.getters.currentAdvancePaymentId}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_CURRENT_ADVANCE_PAYMENT_TO_STATE",
              response.data.data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
