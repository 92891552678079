const documentData= `
<h3>* Zwischen der ICON-INSTITUT Sustain GmbH, Von-Groote-Straße 28, 50968 Köln, im Folgenden ICON genannt, und [expert name], Niederlande, werden die folgenden Verträge geschlossen:</h3>
<h2>Gegenstand des Vertrages</h2><br>
<h3>* [expert name] wird als [function] im Projekt [projectName] arbeiten</h3><br>
<h3>* Referenz-Nr.:[contract ID]</h3><br>
<h3>* Auftraggeber: [contracting authority from the project creation form],[project_id]</h3><br>
<h3 class="py-4">Die folgenden Anhänge sind integraler Bestandteil des Vertrags:</h3><br>
<h3>- ToR (Berater) [if section yes]</h3><br>
<h3>- Arbeitszeitnachweise [if section yes]</h3><br>
<h3>- Einsatzplan</h3><br>

<h2>Aufgaben</h2><br>
<h3>[number of working days] In ihrer Funktion als [function] wird [expert name] für das gesamte Projektmanagement und die Koordination der Projektaktivitäten verantwortlich sein. [expert name] wird auch für die Koordination des Expertenteams sowie für die Qualitätskontrolle, die Zusammenstellung und die rechtzeitige Einreichung der Berichte verantwortlich sein. [expert name] trägt zu allen Ad-Hoc-Berichten und Strategien bei sowie zu jedem zusätzlichen Ad-Hoc-Bericht, der vom Begünstigten und/oder ICON angefordert werden könnte. Die Beiträge zu den Berichten und die Einreichungstermine müssen vorab mit ICON besprochen und vereinbart werden. Alle Aufgaben müssen gemäß den Terms of Reference (ToR-Berater) [function] ausgeführt werden.</h3><br>
<h3>Insgesamt werden 8 Quartalsfortschrittsberichte [individual deliverable title] erstellt.</h3><br>
<h3>Eine Entwurfsversion des Abschlussberichts [individual deliverable title] wird erstellt.</h3><br>
<h3>Eine endgültige Version des Abschlussberichts [individual deliverable title] wird erstellt.</h3><br>
<h3>Eine kurze Version des Abschlussberichts [individual deliverable title] wird erstellt.</h3><br>
<h3>Entwicklung eines Kommunikations- und Sensibilisierungsplans [individual deliverable title].</h3><br>
<h3>Insgesamt werden 15 Monatsberichte erstellt.</h3><br>
<h3>[expert name] verpflichtet sich, Arbeitszeitnachweise zu führen. Die Arbeitszeitnachweise werden von [expert name] unterschrieben und im Original an ICON übergeben. Die Arbeitszeitnachweise dienen als Nachweis, dass die Dienstleistungen gemäß diesem Vertrag erbracht wurden und müssen von ICON unterschrieben werden. Die Anerkennung von Reisetagen, Feiertagen und Wochenenden als Arbeitstage erfordert eine individuelle Genehmigung.</h3><br>
<h3>Alle Aufgaben müssen gemäß den Terms of Reference (ToR-Berater) ausgeführt werden. Alle von [expert name] [expert name] erstellten Dokumente werden in [language] verfasst und in elektronischer Form [deliverable mode of submission] an ICON übermittelt. Die Einreichungstermine müssen mit GIZ/ICON abgestimmt werden. Die Dokumente müssen von [submited to eg.client] akzeptiert werden.</h3><br>

<h2>Leistungsort</h2><br>
<h3>[country to where the expect will travel to]</h3><br>

<h2>Dauer des Vertrags und der Missionen</h2><br>
<h3>Dieser Vertrag ist ein Rahmenvertrag, der durch detaillierte Einsatzpläne ergänzt wird. Dieser Vertrag tritt am [contract start date] in Kraft und endet am [contract end date]. Die Termine für die Missionen werden in Absprache mit [expert name] festgelegt und in den Einsatzplänen aufgenommen. Die Einsatzpläne sind integraler Bestandteil dieses Vertrags.</h3><br>

<h2>Festlegung des Preises</h2><br>
<h3>Für die erbrachten Leistungen wird [expert name] ein Honorar von [fees] pro Arbeitstag für maximal [number of working days] Arbeitstage gezahlt.</h3><br>
<h3>[expert name] erhält ein Festhonorar für</h3><br>
<h3>- 8 Quartalsprojektfortschrittsberichte in Höhe von jeweils EUR [fixed price per month/day] (insgesamt EUR 20.160)</h3><br>
<h3>- Entwurfsversion des Abschlussberichts [individual deliverable title] in Höhe von EUR [fixed price per month/day]</h3><br>
<h3>- Kurzversion des Abschlussberichts [individual deliverable title] in Höhe von EUR [fixed price per month/day]</h3><br>

<h3>-Entwicklung eines Kommunikations- und Sensibilisierungsplans [individual deliverable title] in Höhe von EUR [fixed price per month/day]</h3><br>
<h3>-15[Report for travel] Monatsberichte in Höhe von [individual deliverable title] jeweils EUR [fixed price per month/day] (insgesamt EUR 43.200)</h3><br>
<h3>- Alle weiteren Ausgaben gelten als in den Gebühren enthalten (z. B. Reisekosten, Tickets, Tagesgelder, Steuern usw.) [nur anzeigen, wenn der Vertrag Reisen beinhaltet].</h3>
<h2>Verwendung von Dokumenten und Ergebnissen</h2><br>
<h3>[expert name] überträgt ICON im Rahmen dieses Vertrages ein unwiderrufliches, übertragbares und nicht exklusives Nutzungsrecht in Bezug auf alle nationalen und internationalen Patent- (oder äquivalenten) Rechte, Erfindungen, Design-Dokumente, Prozesse und andere Dokumentationen, die sich aus dem Projekt und dem Vertrag für ICON ergeben.</h3><br>
<h3>Alle projektbezogenen Dokumentationen und Ergebnisse dürfen von [expert name] nicht an Dritte weitergegeben werden. Ausnahmen sind nur mit vorheriger schriftlicher Genehmigung von ICON möglich.</h3><br>
<h3>Alle projektbezogenen Dokumentationen und Ergebnisse dürfen von [expert name] nicht an Dritte weitergegeben werden. Ausnahmen sind nur mit vorheriger schriftlicher Genehmigung von ICON möglich.</h3><br>

<h3>Insgesamt werden 15 Monatsberichte erstellt.</h3><br>
<h3>[expert name] verpflichtet sich, Arbeitszeitnachweise zu führen. Die Arbeitszeitnachweise werden von [expert name] unterschrieben und im Original an ICON übergeben. Die Arbeitszeitnachweise dienen als Nachweis, dass die Dienstleistungen gemäß diesem Vertrag erbracht wurden und müssen von ICON unterschrieben werden. Die Anerkennung von Reisetagen, Feiertagen und Wochenenden als Arbeitstage erfordert eine individuelle Genehmigung.</h3><br>
<h3>Alle Aufgaben müssen gemäß den Terms of Reference (ToR-Berater) ausgeführt werden. Alle von [expert name] erstellten Dokumente werden auf Englisch verfasst und in elektronischer Form an ICON übermittelt. Die Einreichungstermine müssen mit GIZ/ICON abgestimmt werden. Die Dokumente müssen von ICON akzeptiert werden.</h3><br>

<h2>Allgemeine Vertragsbedingungen der ICON-INSTITUTE Consulting Gruppe</h2><br>
<h3>Die Allgemeinen Vertragsbedingungen der ICON-INSTITUTE Consulting Gruppe in der Version des [edit this section] werden diesem Vertrag als Anhang beigefügt und sind integraler Bestandteil des Vertrags. [expert name] erklärt hiermit, dass er die Allgemeinen Vertragsbedingungen zur Kenntnis genommen hat.</h3><br>

<h2>Erklärung</h2><br>
<h3>[expert name] bestätigt, dass er keinen gewöhnlichen Aufenthalt in der Bundesrepublik Deutschland hat.</h3><br>

<h2>Besondere Vereinbarung</h2><br>
<h3>Als Änderung zu Art. 8.3 der Allgemeinen Vertragsbedingungen der ICON-INSTITUTE Consulting Gruppe wird folgendes vereinbart: Der Vertrag endet, wenn das Projekt vor dem geplanten Endtermin beendet oder unterbrochen wird, unabhängig vom Grund für die Beendigung oder Unterbrechung des Projekts. Wenn die Beratungsdienste und anderen Leistungen (Berichte, Studien, Workshops, Schulungen usw.) gemäß dem Vertrag aufgrund der während der Vertragslaufzeit bestehenden und auftretenden Beschränkungen infolge der COVID-19-Pandemie nicht oder nur teilweise erbracht werden können, besteht nur für die erbrachten Leistungen ein Vergütungsanspruch.</h3><br>
`
export default documentData