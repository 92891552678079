<template>
  <!-- project forecast -->
  <v-sheet max-width="1125" class="bg-transparent">
    <!-- project forecast -->
    <form>
      <fieldset :disabled="shouldDisableInput ? 'disabled' : null">
        <legend></legend>
        <v-row>
          <v-col cols="5" class="pr-4 ma-0">
            <app-select-field-object
              placeholder="Select phase"
              :disabled="shouldDisableInput ? 'disabled' : null"
              :options="phaseOptions"
              :label="$t('projects.phase')"
              v-model="phase"
              @change="selectedPhase"
            ></app-select-field-object>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-select-field-object
              :disabled="shouldDisableInput ? 'disabled' : null"
              placeholder="Select status"
              :options="statusOptions"
              :label="$t('projects.status')"
              v-model="status"
              @change="selectedStatus"
            ></app-select-field-object>
          </v-col>
        </v-row>
      </fieldset>
    </form>
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { isEqual } from 'lodash';

export default defineComponent({
  name: 'Project Phase and Status',
  props: {
    projectData: {
      type: Object,
    }
  },
  data(){
    return{
      phase: '',
      status: '',
      firstRun: true,
      initialPhase: '',
      initialStatus: ''
    }
  },
  watch: {
    projectData(data) {
      this.phase = data?.project_phase;
      this.status = data?.project_status;
    },
    inputDependencies: {
      immediate: true,
      handler(newValue) {
        // if (!this.firstRun) {
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
            "BasicInformation": !isEqual(newValue, [this.initialPhase, this.initialStatus])
          });
        // }
      }
    }
  },
  computed:{
    phaseOptions() {
      const modifiedData = this.$store.getters["projectModules/isPhaseOptions"];
      return modifiedData?.convertArrayToObject();
    },
    statusOptions() {
      const modifiedData =
        this.$store.getters["projectModules/isStatusOptions"];
      return modifiedData?.convertArrayToObject();
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isSelectedPhase() {
      return this.$store.getters["projectModules/isSelectedPhase"]
    },
    isSelectedStatus() {
      return this.$store.getters["projectModules/isSelectedStatus"]
    },
    isSubData() {
      return this.$store.getters["projectModules/isSubData"]
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
    inputDependencies() {
      return [
        this.phase,
        this.status
      ]
    },
  },
  methods:{
    selectedPhase(){
      this.$store.commit('projectModules/SET_SELECTED_PHASE_TO_STATE', this.phase)
      // if(this.isSubData ==='sub'&& this.phase === 'Offer' || this.isSubData ==='sub'&& this.phase === 'Project'){
      //   this.$store.commit("projectModules/SET_SUB_COST_CENTER_TO_STATE", true);
      // } else {
      //   this.$store.commit('projectModules/SET_SELECTED_PHASE_TO_STATE', this.phase)
      // }
    },
    selectedStatus(){

      this.$store.commit('projectModules/SET_SELECTED_STATUS_TO_STATE', this.status)
    }
  },
  created(){
    this.phase =this.isSelectedPhase ? this.isSelectedPhase : this.phaseOptions[0]?.value
    this.$store.commit('projectModules/SET_SELECTED_PHASE_TO_STATE', this.phase)
    this.status =this.isSelectedStatus ?  this.isSelectedStatus : this.statusOptions[0]?.value
    this.$store.commit('projectModules/SET_SELECTED_STATUS_TO_STATE', this.status)
    this.initialPhase = this.phase
    this.initialStatus = this.status

  }

})
</script>
