<template>
  <div>
    <app-header :name="$t('experts.experts')" :show-arrow-back="false" />
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          employmentType="Expert"
        />
      </v-col>
      <v-col cols="2" class="">
        <app-select-field-object
          class="px-2"
          :options="isStatusTitle"
          :label="$t('employees.status')"
          :isTranslation="true"
          v-model="status"
        />
      </v-col>
    </v-row>
    <div class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="isTableHeaders"
        :items="isAllEmployees"
        :showCheck="canEdit"
        :isEmployees="true"
        :hideMenu="!canEdit"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @reset-event="showResetPasswordDialog({ $event, data: 'reset' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        @delete-multiple-event="
          showDeleteMultipleDialog({ $event, data: 'multiple' })
        "
      >
        <template #title>
          <h3 class="H500">{{ $t("experts.allExperts") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            :buttonLabel="$t('experts.addExpert')"
            @click="addEmployee"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <!-- dialog for password reset -->
    <app-dialog
      ref="resetPasswordDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('experts.newPassword')"
          :placeholder="$t('experts.setNewPassword')"
          v-model="newPassword"
          :hasError="hasPasswordError"
          :errorMessage="errorPasswordMessage"
          @input="hideError"
        ></app-input-field>
        <app-input-field
          class="mt-5"
          :label="$t('experts.confirmNewPassword')"
          :placeholder="$t('experts.confirmPassword')"
          v-model="confirmPassword"
          :hasError="hasConfirmPasswordError"
          :errorMessage="errorConfirmPassword"
          @input="hideError"
        ></app-input-field>
      </template>

      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('experts.cancel')"
              @click-event="hideResetPasswordDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :loading="loading"
              :buttonLabel="$t('experts.resetPassword')"
              @click-event="resetEmployeePassword()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for deleting and deactivating user -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('experts.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="isDelete ? deleteEmployee() : updateEmployeeStatus()"
            >
              <!-- Yes , delete -->
              {{
                isDelete ? $t("experts.yesDelete") : $t("experts.yesDeactivate")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="deleteMultipleDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('experts.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteMutipleEmployees"
            >
              <!-- Yes , delete -->
              {{ $t("experts.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import AppSelectFieldObject from "@/components/shared/AppSelectFieldWithObject.vue";

export default defineComponent({
  name: "EmployeeLayout",
  components: {
    AppSelectFieldObject,
    AppHeader,
    Paginator,
  },
  data() {
    return {
      currentPages: 1,
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      isReset: false,
      headers: null,
      tableData: [],
      userKey: null,
      userStatus: null,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",
      status: "Active",
    };
  },
  watch: {
    status(...args) {
      this.handleFilterSet(
        "is_active",
        args,
        "hrResources/REMOVE_FILTER_BYS_FROM_STATE",
        "hrResources/SET_FILTER_BYS_TO_STATE"
      );
    },
  },
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Expert"
      );
      return findModules?.length !== 0;
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllEmployees"];
    },
    isTableHeaders() {
      // const filterList =
      return this.$store.getters["hrResources/isTableHeaders"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    isFilterBys() {
      return this.$store.getters["hrConfigurations/isFilterBys"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },
    isStatusTitle() {
      return [
        { id: "Active", value: "Active" },
        { id: "Inactive", value: "Inactive" },
      ];
    },
  },

  methods: {
    handleFilterSet(type, args) {
      if (args[0] === "All" || args[0] === "Alle") {
        this.$store.commit("hrResources/REMOVE_FILTER_BYS_FROM_STATE", type);
      } else {
        this.$store.commit("hrResources/SET_FILTER_BYS_TO_STATE", [
          type,
          args[0],
        ]);
      }
    },
    getAllSettings(type, stateType) {
      Api()
        .get(`/configuration/general/get-general/${type}/`)
        .then((response) => {
          const payload = response.data;
          this.items = payload;
          this.$store.commit(`hrConfigurations/${stateType}`, payload.data);
        });
    },
    addEmployee() {
      this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);
      this.$store.commit("hrResources/SET_USER_KEY_TO_STATE", null);
      this.$router.push({ name: "addExpert" });
    },
    getFiltersData(fields) {
      return this.isTableHeaders?.reduce((acc, curr) => {
        if (fields.includes(curr)) return [...acc, { id: curr, value: curr }];
        else return acc;
      }, []);
    },

    hideError() {
      this.hasConfirmPasswordError = false;
      this.errorConfirmPassword = "";
      this.hasPasswordError = false;
      this.errorPasswordMessage = "";
    },
    showDialog(data) {
      this.userKey = data?.$event?.user_key;
      this.userStatus = !data?.$event?.is_active;
      if (data.data === "edit") {
        this.title = "experts.deactivateAccount";
        this.subTitle = "experts.deactivatingThis";
        this.isEdit = !this.isEdit;
        this.cancelLabel = "experts.noKeepIt";
        this.$refs.dialog.showDialog = true;
      } else {
        this.subTitle = "experts.areYouSure";
        this.title = "experts.deleteExperts";
        this.isDelete = !this.isDelete;
        this.cancelLabel = "experts.noKeepIt";
        this.$refs.dialog.showDialog = true;
      }
    },
    showDeleteMultipleDialog(data) {
      if (data.data === "multiple") {
        this.userKey = data?.$event;
        this.subTitle = "experts.areYouSure";
        this.title = "experts.deleteExperts";
        // this.isDelete = !this.isDelete;
        this.cancelLabel = "experts.noKeepIt";
        this.$refs.deleteMultipleDialog.showDialog = true;
      }
    },
    showResetPasswordDialog(data) {
      this.userKey = data?.$event?.user_key;
      this.newPassword = "";
      this.confirmPassword = "";
      this.subTitle = "";
      this.cancelLabel = "experts.cancel";
      if (data.data === "reset") {
        this.title = "experts.resetPassword";
        this.buttonLabel = "experts.resetPassword";
        this.isReset = !this.isReset;
        this.$refs.resetPasswordDialog.showDialog = true;
      }
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.$refs.deleteMultipleDialog.showDialog = false;
      setTimeout(() => {
        this.isEdit = false;
        this.isDelete = false;
        this.addTitle = "";
        this.subTitle = "";
        this.buttonLabel = "experts.add";
        this.cancelLabel = "experts.cancel";
      }, 600);
    },
    hideResetPasswordDialog() {
      this.$refs.resetPasswordDialog.showDialog = false;
      setTimeout(() => {
        this.isReset = false;
        this.subTitle = "";
        this.addTitle = "";
        this.buttonLabel = "experts.add";
        this.cancelLabel = "experts.cancel";
      }, 600);
    },
    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllEmployees(page);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getAllEmployees(page) {
      Api()
        .get(`/hr/employees/get-all-employees/Expert/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            // this.headers = response.data.headers;
            const data = response.data.data;

            // convert the keys of the objects to lowercase
            const payload = data.map(function (obj) {
              return Object.keys(obj).reduce(function (newObj, key) {
                newObj[key.toLowerCase()] = obj[key];
                return newObj;
              }, {});
            });

            this.$store.commit("hrResources/SET_EMPLOYEES_TO_STATE", payload);
            this.$store.commit(
              "hrResources/SET_TABLE_HEADERS_TO_STATE",
              response.data.headers
            );
            this.$store.commit(
              "hrResources/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },

    resetEmployeePassword() {
      this.loading = true;
      if (!this.newPassword) {
        this.loading = false;
        this.hasPasswordError = true;
        this.errorPasswordMessage = "experts.thisField";
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.loading = false;
        this.hasConfirmPasswordError = true;
        this.errorConfirmPassword = "experts.passwordDoesNot";
        return;
      }
      const payload = {
        password: this.newPassword,
        confirm_password: this.confirmPassword,
      };
      Api()
        .put(`/hr/employees/reset-password/${this.userKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.alertState = !this.alertState;
            this.alertTitle = this.getTranslatedStringKey(response.data.detail); // did not display text
            this.getAllEmployees(this.currentPage);
            setTimeout(() => {
              this.addTitle = "";
              this.$refs.resetPasswordDialog.showDialog = false;
            }, 1000);
          }
          if (response.data.status === "error") {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.$refs.resetPasswordDialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateEmployeeStatus() {
      const payload = {
        status: this.userStatus,
      };
      Api()
        .patch(`/hr/employees/update-employee-status/${this.userKey}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = this.getTranslatedStringKey(response.data.detail);
            this.getAllEmployees(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
              this.isEdit = !this.isEdit;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isEdit = !this.isEdit;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteEmployee() {
      Api()
        .delete(`/hr/employees/delete-employee/${this.userKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            this.$refs.dialog.showDialog = false;
            setTimeout(() => {
              this.isDelete = !this.isDelete;
            }, 600);
          }
          if (response.data.status === "error") {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.isDelete = !this.isDelete;
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deleteMutipleEmployees() {
      const payload = {
        user_keys: this.userKey,
      };

      Api()
        .post("/hr/employees/delete-multiple-employees/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = !this.alertState;
            this.alertTitle = response.data.detail;
            this.getAllEmployees(this.currentPage);
            this.$refs.deleteMultipleDialog.showDialog = false;
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
            }, 1000);
          }
          if (response.data.status === "error") {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.deleteMultipleDialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    loadMore() {
      const page = this.currentPages;
      this.getAllEmployees(page);
    },
  },
  created() {
    this.getAllEmployees(this.currentPage);
    this.getAllSettings("Positions", "SET_POSITIONS_TO_STATE");
    this.getAllSettings("Companies", "SET_COMPANIES_TO_STATE");
    this.getAllSettings("Teams", "SET_TEAMS_TO_STATE");
    this.getAllSettings("Employment Types", "SET_EMPLOYMENT_TYPES_TO_STATE");
  },
});
</script>

<style scoped>
label {
  color: #344054;
}
</style>
