<template>
  <div
    class="todos"
    @mouseenter="showClose = true"
    @mouseleave="showClose = false"
  >
    <div style="border-bottom: 1px solid #eaecf0; width: 100%"></div>
    <div class="flex justify-between items-center">
      <!-- <router-link :to="link" target="_self"> -->
      <div class="todos_card py-4 cursor-pointer" @click="emitOpen()">
        <div class="todos_title flex items-center">
          <div class="H400">{{ title }}</div>
          <div class="P200 px-4" v-if="toggleDate"><span >• Created:</span> {{ formattedDate(createdDate) }}</div>
          <div class="P200 px-4" v-else><span>• Due: </span>{{ formattedDate(dueDate) }}</div>
         
          <div class="type">{{ todoType }}</div>
        </div>
        <div class="todos_content flex items-center">
          <div class="P300">{{ content }}</div>
          <!-- <h1 class="H400 px-2 color-red">{{ userName }}</h1> -->
        </div>
      </div>
      <!-- </router-link> -->
      <div class="tooltip d-flex align-items-center">
        <!-- <div v-if="showClose" class="pr-2">
          <v-icon
            @click="emitDelete(item)"
            icon="custom:markIcon"
            class="icon"
            color="red"
          ></v-icon>
        </div> -->
        <div class="tooltip-text P200">{{ $t("homeview.mark") }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters, mapState } from 'vuex';
import { formatDate } from "@/services/helpers";
export default defineComponent({
  name: "TodosCard",

  data() {
    return {
      showClose: false
    }
  },

  props: {

    title: {
      type: String,
      default: 'Title',
    },

    createdDate: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'type',
    },
    content: {
      type: String,
      default: 'This is the content',
    },
    dueDate: {
      type: String,
      default: ' June 26, 2023',
    },
  },
  computed: {
    ...mapGetters("translation", ["getLocale"]),
    ...mapState(["translation"]),

    appLang() {
      return this.$store.getters["translation/getLocale"];
    },
    toggleDate() {
      const titleArray = ['Flight Request','Entertainment request','Advance Payment Request']
      return titleArray.includes(this.title)
    },
  },
  methods: {
    emitDelete(data) {

      this.$emit("delete-event", data);
    },
    emitOpen() {

      this.$emit("open-event");
    },
    formattedDate(date, lang) {
      lang = lang ? lang : this.appLang;
      return formatDate(date, lang);
    },
  },
});
</script>
<style scoped>
.todos {
  width: 100%;
}

h1 {
  color: #a2544f !important;
}

.type {
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #344054;
  font-size: 12px;
  border-radius: 16px;
  background-color: #f8f9fc;
  padding: 2px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.close-icon {
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
</style>
