<template>
  <div>
    <app-header
      :name="officeID ? officeTitle :$t('foreignOffices.newForeignOffice')"
      class="mb-5 ml-n2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
    </app-header>
    <div class="">
      <button
        v-for="(tab, index) in tabList"
        :key="index"
        :disabled="!officeID"
        :class="[
          'tab-button',
          { active: currentTab === tabs[index] },
          { disabled: !officeID },
          { '!opacity-50': tabListForExcluded?.includes(tab) && !officeID },
        ]"
        @click="handleProceed(index)"
      >
        <span class="tab-tooltip">
          {{ $t(getTranslatedStringKey(tab)) }}
        </span>
      </button>

      <div class="mt-2 border-t-[1px]"></div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-10"
      :handleButtonRoute="handleButtonRoute"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
import BasicInformation from "@/components/dashboard/foreign-office/BasicInformation.vue";
import StaffInformation from "@/components/dashboard/foreign-office/StaffInformation.vue";

import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "AddForeignOffice",
  components: {
    BasicInformation,
    StaffInformation,
  },
  data() {
    return {
      currentTab: "Personal",
      tabList: ["Basic Information", "Staff Information"],
      tabs: ["BasicInformation", "StaffInformation"],
      tabListForExcluded: ["Staff Information"],
    };
  },

  computed: {
    officeID() {
      return this.$store.getters["foreignOfficeModule/isOfficeID"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    officeTitle() {
        const modifiedData = this.$store.getters["foreignOfficeModule/isOfficeTitle"]
      return `${modifiedData} Office`;
    },

    foreignOfficeUpdate() {
      return this.$store.getters["foreignOfficeModule/isForeignOfficeUpdate"];
    },

    ...mapState(["translation"]),
  },

  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    getSingleOffice(id) {
      if (this.officeID) {
        const payload = {
          load_page: true,
          officeID: id,
        };
        this.$store.dispatch("foreignOfficeModule/getSingleOffice", payload);
      }
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.foreignOfficeUpdate)?.some((elt) => elt === true);
    },
    handleProceed(index) {
      this.newTabIndex = index;
      if (this.foreignOfficeUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    handleGoToBack() {
      if (Object.values(this.foreignOfficeUpdate)?.some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.foreignOfficeUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.foreignOfficeUpdate).reduce(
        (acc, curr) => {
          if (this.foreignOfficeUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("foreignOfficeModule/SET_FOREIGN_OFFICE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  created() {
    this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    this.$store.commit("hrResources/SET_EDIT_EMPLOYEE_STATE", false);

    this.currentTab = this.tabs[this.isCurrentTab];

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    if (this.officeID) {
        this.getSingleOffice(this.officeID)
    }
   
  },
});
</script>
<style scoped>
button:disabled {
  pointer-events: none;
}
</style>
