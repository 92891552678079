<template>
  <div>
    <div class="mb-6 flex">
      <app-radio-button
        name="Cash"
        v-model="selectedOption"
        :value="selectedOption"
        :disabled="shouldDisableInput"
      >
      </app-radio-button>
      <app-radio-button
        name="Bank"
        class="ml-10"
        v-model="selectedOption"
        :value="selectedOption"
        :disabled="shouldDisableInput"
      >
      </app-radio-button>
    </div>
    <v-row class="mb-8">
      <v-col cols="2">
        <app-single-select
          :label="$t('pmLocals.year') + '*'"
          :labelPlaceholder="$t('cheques.selYear')"
          v-model="expense_year"
          :options="yearOptions"
          :disabled="shouldDisableInput"
        ></app-single-select>
      </v-col>
      <v-col cols="2">
        <app-single-select
          :label="$t('pmLocals.month') + '*'"
          :labelPlaceholder="$t('cheques.selMonth')"
          :options="monthOptions"
          v-model="expense_month"
          :disabled="shouldDisableInput"
        ></app-single-select>
      </v-col>
    </v-row>
    <app-accordion
      class="mb-10"
      :accordionInfo="overViewInfo"
      :showFooter="true"
    >
      <template #content>
        <expense-info></expense-info>
      </template>
      <template #footer>
        <expense-balance-overview></expense-balance-overview>
      </template>
    </app-accordion>
    <div class="mt-10 border rounded-lg">
      <div class="flex items-center justify-between p-4">
        <h3 class="H500">
          {{
            isEditExpense
              ? $t("expenses.editExpense")
              : $t("expenses.newExpense")
          }}
        </h3>
        <app-secondary-button
          v-if="!shouldDisableInput"
          @click="addNewExpenseRow()"
          :buttonLabel="$t('Add New Row'.getTranslationKey())"
          class="border-none hover:bg-gray-200 hover:rounded-lg"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-secondary-button>
      </div>
     
      <table class="w-full text-sm text-left text-[#404b5e]">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header"
              class=" bg-[#f2f4f7] border-slate-300 px-3 py-3 !text-[#344054] !font-medium"
              :class="[{ 'no-padding': header === '' }, {'!text-right': header === 'amt Incl. Tax' || header === 'amt Excl. Tax'}]"
            >
              {{ $t(getTranslatedStringKey(header)) }}
             
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(expense, index) in expense_data"
            :key="index"
            :class="rowErrorClass(expense, index)"
          >
            <td>
              <input
                v-model="expense.title"
                type="text"
                placeholder="Title"
                class="!w-[130px]"
                :disabled="shouldDisableInput || expense.status"
              />
            </td>

            <td>
              <app-date-field
                type="date"
                :max="maxStartDate(expense.payment_date)"
                :isLabel="false"
                v-model="expense.document_date"
                class="!border-none bg-transparent !shadow-none !w-[150px]"
                :isCheque="true"
                :disabled="shouldDisableInput || expense.status"
              ></app-date-field>
            </td>
            <td>
              <app-date-field
                type="date"
                :min="minEndDate(expense.document_date)"
                :isLabel="false"
                v-model="expense.payment_date"
                class="!border-none bg-transparent !w-[150px]"
                :isCheque="true"
                :disabled="shouldDisableInput || expense.status"
              ></app-date-field>
            </td>
            <td>
              <input
                v-model="expense.supplier"
                type="text"
                placeholder="Supplier"
                class="!w-[110px]"
                :disabled="shouldDisableInput || expense.status"
              />
            </td>
            <td>
              <app-select-field-object
                :placeholder="$t('cheques.selectPost')"
                :options="allCurrencies"
                v-model.number="expense.currency_id"
                :isTranslation="false"
                :isLabel="false"
                :isCheque="true"
                :disabled="shouldDisableInput || expense.status"
              ></app-select-field-object>
            </td>
            <td>
              <input
                v-model.number="expense.total_amount_taxed"
                @input="filterInput"
                type="text"
                placeholder="0"
                class="text-right !w-[100px]"
                :disabled="shouldDisableInput || expense.status"
              />
            </td>
            <td>
              <input
                v-model.number="expense.total_amount_not_taxed"
                @input="filterInput"
                type="text"
                placeholder="0"
                class="text-right !w-[100px]"
                :disabled="shouldDisableInput || expense.status"
              />
            </td>
            <td>
              <app-select-field-object
                :placeholder="$t('cheques.selectPost')"
                :options="chequeBudgetPosition"
                v-model.number="expense.budget_id"
                :isTranslation="false"
                :isLabel="false"
                :isCheque="true"
                :disabled="shouldDisableInput || expense.status"
              ></app-select-field-object>
            </td>
            <td>
              <div v-if="!expense.file_title">
                <label
                  for="fileInput"
                  :class="{
                    'cursor-pointer': !expense.status || shouldDisableInput,
                  }"
                >
                  <span class="text-blue-500">{{$t('expenses.uploadFile')}}</span>
                </label>
                <!-- {{ shouldDisableInput || expense.status }} -->
                <input
                :disabled="shouldDisableInput || expense.status"
                  id="fileInput"
                  type="file"
                  @change="handleFileUpload($event, index)"
                  style="display: none"
                />
              </div>
              <div
                v-else
                @click="viewfile(index)"
                class="cursor-pointer hover:underline"
              >
                {{
                  expense.file_title.length > 10
                    ? expense.file_title?.substring(0, 10) + "..."
                    : expense.file_title
                }}
              </div>
            </td>
            <td :class="{ 'cursor-pointer': !shouldDisableInput }">
              <div
                @click="
                  shouldDisableInput ? '' : setStatus(expense.status, index)
                "
              >
                <p
                  :class="
                    expense.status || set_status
                      ? 'text-[#12B76A] font-normal cursor-default '
                      : 'text-[#2E90FA] font-normal'
                  "
                >
                  {{
                    expense.status || set_status
                      ?  $t('expenses.auditCleared')
                      : $t('expenses.auditClear')
                  }}
                </p>
              </div>
            </td>
            <td v-if="!shouldDisableInput">
              <v-menu location="start" v-if="!expense.status">
                <template v-slot:activator="{ props }">
                  <v-icon
                    icon="custom:dotVertical"
                    v-bind="props"
                    class="mt-2"
                  ></v-icon>
                </template>
                <v-list class="rounded-lg items">
                  <v-list-item
                    @click="confirmDeleteExpense(index, expense)"
                    class="my-n1 hover:bg-gray-200"
                    >{{ $t("projectConfiguration.delete") }}</v-list-item
                  >
                  <v-list-item
                    v-if="expense.file_title"
                    @click="viewfile(index)"
                    class="my-n1 hover:bg-gray-200"
                    >{{ $t("cheques.viewFile") }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-end gap-4 mt-12" v-if="!shouldDisableInput">
      <app-secondary-button
        :buttonLabel="$t('Cancel'.getTranslationKey())"
        @click-event="cancelAddNewExpense()"
      ></app-secondary-button>
      <app-primary-button
        @click-event="isEditExpense ? updateExpense() : saveExpense()"
        :buttonLabel="
          isEditExpense
            ? $t('Save Changes'.getTranslationKey())
            : $t('Save'.getTranslationKey())
        "
      >
      </app-primary-button>
    </div>
  </div>
  <app-dialog
  ref="dialog"
  :title="$t('expenses.deleteExpense')"
  :subtitle="$t('expenses.confirmDeleteExpense')"
  height="302"
  width="408"
>
  <template #actions>
    <v-row class="px-4 mt-1 mb-5">
      <v-col cols="6" class="">
        <app-secondary-button
          :buttonLabel="$t('employees.cancel')"
          @click-event="hideDialog"
        >
        </app-secondary-button>
      </v-col>
      <v-col cols="6" class="">
        <v-btn
          variant="default"
          min-width="100%"
          min-height="100%"
          class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
          @click="deleteExpense"
        >
          <!-- Yes , delete -->
          {{ $t("employees.yesDelete") }}
        </v-btn>
      </v-col>
    </v-row>
  </template>
</app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="10000"
    :type="alertType"
    :multiple="multiple"
    :multipleTitles="multipleTitles"
  />
</template>

<script>
import {
  getTransString,
  maximumStartDate,
  minimumEndDate,
  restrictCharactersByRegex,
  currencyFormatter,
  monthsNameWithNumber,
  getYearsFromProject,
  formatDate
} from "@/services/helpers";
import Api from "@/services/api";
import {
  addExpense,
  currencyBudgetPosition,
  updateExpense,
  uploadChequeFile,
  deleteExpense,
} from "@/services/endpoints";
import { mapState } from "vuex";
import ExpenseInfo from "./ExpenseInfo.vue";
import ExpenseBalanceOverview from "./ExpenseBalanceOverview.vue";
import { isEqual } from 'lodash';
export default {
  name: "CreateEditExpense",
  components: { ExpenseInfo, ExpenseBalanceOverview },
  data() {
    return {
      overViewInfo: {
        name: "Overview",
      },
      currentDate: new Date(),
      selectedOption: "Cash",
      expense_data: [],
      original_expense_data: [],
      alertType: "success",
      alertState: false,
      alertTitle: "",
      multipleTitles: "",
      transformedCurrency: [],
      chequeBudgetPosition: [],
      transformedBudgetPosition: [],
      expense_year: null,
      expense_month: null,
      expense_type: "cash",
      set_status: false,
      saveClicked: false,
      floatingNumber: /^(?![0-9.-]+$).*$/g,
      serverErrors: "",
      expenseItem: "",
      expenseItemIndex: null,
    };
  },
  watch: {
    selectedOption(val) {
      this.getAllExpenses(false, val.toLocaleLowerCase());
    },
    isEditExpense() {
      this.expense_data = this.allExpenses?.expense_data;
      this.expense_year =
        this.allExpenses?.duration?.year === ""
          ? this.currentDate.getFullYear()
          : this.allExpenses?.duration?.year;
      this.expense_month =
        this.allExpenses?.duration?.month === ""
          ? this.getMonth()
          : this.allExpenses?.duration?.month;
      this.generateRows();
    },
    expense_year(val) {
      this.filterAllExpensesByMonthAndYear(false, this.expense_month, val);
    },
    expense_month(val) {
      this.filterAllExpensesByMonthAndYear(false, val, this.expense_year);
    },
    allExpenses (val) {
      this.expense_data = val?.expense_data;
      this.original_expense_data = val?.expense_data;
      if(val.expense_data.length === 0){
        this.generateRows();
      }
    },
    expense_data: {
        deep: true,
        handler(value) {
          this.$store.commit("pmLocalModules/SET_PM_LOCAL_UPDATE", {
            "Expenses": !isEqual(value, this.original_expense_data)
          });

        },
    }, 
  },
  computed: {
    yearOptions() {
      const modifiedData = this.allExpenses?.project_details;

      return getYearsFromProject(
        modifiedData.project_start_date,
        modifiedData.project_end_date
      );
    },
    monthOptions() {
      return monthsNameWithNumber;
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    isProjectId() {
      return this.$store.getters["pmLocalModules/isProjectId"];
    },
    isEditExpense() {
      return this.$store.getters["expenseModules/isEditExpense"];
    },
    isExpenseEditId() {
      return this.$store.getters["expenseModules/isExpenseEditId"];
    },
    canEditPmLocalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["hrResources/isEditButton"] &&
        this.canEditPmLocalModule?.includes("Expenses")
      );
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    headers() {
      return this.$store.getters["expenseModules/isHeaders"];
    },
    allExpenses() {
      return this.$store.getters["expenseModules/isAllExpenses"];
    },
    allCurrencies() {
      const modifiedData = this.$store.getters["pmLocalModules/allCurrencies"];
      return modifiedData?.map((curr) => {
        return {
          id: curr.currency,
          value: curr.id,
        };
      });
    },
    balanceData() {
      const modifiedData = this.allExpenses.expense_overview;
      return modifiedData?.filter((item) => item.title === "Balance");
    },

    currentPage() {
      return this.$store.getters["expenseModules/isCurrentPage"];
    },
    currentPath() {
      return this.$route.path;
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
  },
  methods: {
    monthStringToNumber(monthString) {
      const date = new Date(`${monthString} 1, 2000`);
      const monthNumber = date.getMonth() + 1;

      if (!isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12) {
        return monthNumber;
      } else {
        return -1; // This can be used to indicate an invalid month string
      }
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    viewfile(index) {
      window.open(this.expense_data[index].file_path, "_blank");
    },
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },

    filterInput(event) {
      restrictCharactersByRegex(event, this.floatingNumber);
      // Define the regular expression for valid input
    },
    handleFileUpload(event, index) {
      alert("hello")
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("cheque_file", file);
        Api()
          .post(`${uploadChequeFile}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              const fileData = response.data.data;
              const fileName =
                fileData.file_title.length > 10
                  ? fileData.file_title?.substring(0, 10) + "..."
                  : fileData.file_title;
              this.file_title = fileName;
              this.expense_data[index].file_title = fileData.file_title;
              this.expense_data[index].file_path = fileData.file_path;
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.details;
            }
          })
          .catch((error) => {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.details;
          });
      }
    },
    addNewExpenseRow() {
      this.expense_data.push({
        title: "",
        document_date: "",
        payment_date: "",
        supplier: "",
        currency_id: null,
        status: this.set_status,
        total_amount_taxed: "",
        total_amount_not_taxed: "",
        budget_id: null,
      });
    },
    showDialog() {
        this.$refs.dialog.showDialog = true;
        this.subTitle = "pmLocals.confirmDeleteCurrency";
        this.title = "pmLocals.deleteCurrency";
        this.cancelLabel = "employees.noKeepIt";
      },
  
      hideDialog() {
        this.$refs.dialog.showDialog = false;
      },
    confirmDeleteExpense(index, expense) {
      if (!expense.id) {
        this.deleteRow(index)
      }else{
        this.$refs.dialog.showDialog = true;
        this.cancelLabel = "employees.noKeepIt";
        this.expenseItem = expense;
        this.expenseItemIndex = index;
      }
    },
    deleteRow(index) {
      this.expense_data.splice(index, 1);
      this.hideDialog()
    },

    deleteExpense() {
        Api()
          .delete(`${deleteExpense}${this.expenseItem.id}/`)
          .then((response) => {
            if (response.data.status === "success") {
              this.expense_data?.splice(this.expenseItemIndex, 1);
              this.hideDialog()
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.detail;
              this.getAllExpenses(
                false,
                this.selectedOption?.toLocaleLowerCase()
              );
            }
          })
          .catch((error) => {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.detail;
            this.hideDialog()
          });
    },
    setStatus(data, index) {
      if (data === false) {
        this.expense_data[index].status = !data;
      }
    },

    cancelAddNewExpense() {
      this.expense_data = [];
      this.getAllExpenses(false, this.selectedOption?.toLocaleLowerCase());
      this.expense_data = this.allExpenses?.expense_data;
    },
    getBudgetPosition() {
      Api()
        .get(`${currencyBudgetPosition}2/${this.isForeignEstablishmentId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.chequeBudgetPosition = response.data.data?.map((pos) => {
              return {
                id: pos.budget_item__position,
                value: pos.id,
              };
            });
          }
        })
        .catch();
    },
    saveExpense() {
      this.validateFields();
      if (this.hasErrors) return;
      this.expense_data.forEach((expense) => {
        if (expense.total_amount_not_taxed === "") {
          delete expense.total_amount_not_taxed;
        }
      });
      const payload = {
        expense_data: this.expense_data,
        month: +this.expense_month,
        year: +this.expense_year,
        expense_type: this.selectedOption?.toLocaleLowerCase(),
        foreign_establishment_id: this.isForeignEstablishmentId,
        project_id: this.isProjectId,
      };
      Api()
        .post(`${addExpense}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.multiple = false;
            this.alertTitle = response?.data?.detail;
            this.getAllExpenses(
              false,
              this.selectedOption?.toLocaleLowerCase()
            );
            this.hasErrors = false;
            this.saveClicked = false;
          }
        })
        .catch((error) => {
          if (error?.response?.status === 314) {
            this.serverErrors = error?.response?.data?.data;
            this.alertType = "error";
            this.alertState = true;
            this.multiple = true;
            this.hasErrors = true;

            this.multipleTitles = Object.values(this.serverErrors).reduce(
              (acc, item) => {
                Object.keys(item).forEach((key) => {
                  if (key === "currency") {
                    acc.push({
                      key: item[key],
                      message: "Insufficient currency funds for",
                    });
                  } else if (key === "budget_position") {
                    acc.push({
                      key: item[key],
                      message: "Insufficient local budget funds for",
                    });
                  }
                });
                return acc;
              },
              []
            );
          } else {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.detail;
            this.hasErrors = false;
            this.saveClicked = false;
          }
        });
    },
    updateExpense() {
      this.validateFields();
      if (this.hasErrors) return;
      this.expense_data.forEach((expense) => {
        if (expense.total_amount_not_taxed === "") {
          delete expense.total_amount_not_taxed;
        }
      });
      const payload = {
        expense_data: this.expense_data,
        month: this.monthStringToNumber(this.expense_month),
        year: +this.expense_year,
        expense_type: this.selectedOption?.toLocaleLowerCase(),
        foreign_establishment_id: this.isForeignEstablishmentId,
        project_id: this.isProjectId,
      };
      Api()
        .put(`${updateExpense}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.multiple = false;
            this.alertTitle = response?.data?.detail;
            this.getAllExpenses(
              false,
              this.selectedOption?.toLocaleLowerCase()
            );
            this.hasErrors = false;
            this.saveClicked = false;
          }
        })
        .catch((error) => {
          if (error?.response?.status === 314) {
            this.serverErrors = error?.response?.data?.data;
            this.alertType = "error";
            this.alertState = true;
            this.multiple = true;
            this.hasErrors = true;

            this.multipleTitles = Object.values(this.serverErrors).reduce(
              (acc, item) => {
                Object.keys(item).forEach((key) => {
                  if (key === "currency") {
                    acc.push({
                      key: item[key],
                      message: "Insufficient currency funds for",
                      amount: item.currency_amount,
                    });
                  } else if (key === "budget_position") {
                    acc.push({
                      key: item[key],
                      message: "Insufficient local budget funds for",
                      amount: item.budget_position_amount,
                    });
                  }
                });
                return acc;
              },
              []
            );
          } else {
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = error?.response?.data?.detail;
            this.hasErrors = false;
            this.saveClicked = false;
          }
        });
    },
    getAllExpenses(data, type) {
      const payload = {
        load_page: data,
        expense_type: type,
        foreign_establishment_id: this.isForeignEstablishmentId,
        month: +this.expense_month,
        year: +this.expense_year,
      };

      this.$store.dispatch("expenseModules/getAllExpenses", payload);
    },
    filterAllExpensesByMonthAndYear(data, month, year) {
      const payload = {
        load_page: data,
        expense_type: this.selectedOption?.toLocaleLowerCase(),
        foreign_establishment_id: this.isForeignEstablishmentId,
        month: +this.expense_month,
        year: +year,
      };

      this.$store.dispatch(
        "expenseModules/filterAllExpensesByMonthAndYear",
        payload
      );
    },
    getExpenseTotals() {
      const initialValue = { total_amount_taxed: 0, total_amount_not_taxed: 0 };
      const sum = this.expense_data?.reduce((accumulator, item) => {
        accumulator.total_amount_taxed += Number(item.total_amount_taxed);
        accumulator.total_amount_not_taxed += Number(
          item.total_amount_not_taxed
        );
        return accumulator;
      }, initialValue);
      return sum;
    },
    generateRows() {
      if (this.isEditExpense) {
        this.expense_data = this.allExpenses?.expense_data
        this.original_expense_data = this.allExpenses?.expense_data;
      } else {
        this.addNewExpenseRow();
      }
    },
    capitalizeFirstLetter(str) {
      return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    },
    getMonth() {
      const currentDate = new Date();
      return new Intl.DateTimeFormat("en-US", { month: "long" })?.format(
        currentDate
      );
    },
    // getCurrencyName(currency_id, index){
    // },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    rowErrorClass(expense, index) {
      if (!this.saveClicked) {
        return ""; // Do not validate if the save button hasn't been clicked
      }
      if (
        !expense.title ||
        !expense.document_date ||
        !expense.payment_date ||
        !expense.supplier ||
        !expense.currency_id ||
        !expense.total_amount_taxed ||
        !expense.budget_id ||
        expense.total_amount_not_taxed > expense.total_amount_taxed
        // Add checks for other fields if necessary
      ) {
        return this.hasErrors ? "border-red-300 border-2" : "";
      }
      if (this.serverErrors && this.serverErrors[index]) {
        return this.hasErrors ? "border-red-300 border-2" : "";
      }
      return "";
    },

    validateFields() {
      this.hasErrors = false;
      this.saveClicked = true;
      const fieldsConfig = {
        budget_id: "Budget position is required",
        total_amount_taxed:"Total amount taxed is required",
        // total_amount_taxed: [
        //   "Total amount taxed is required",
        //   "Invalid amount",
        // ],
        currency_id: "Currency is required",
        supplier: "Supplier is required",
        payment_date: "Payment date is required",
        document_date: "Document date is required",
        title: "Title is required",
        // Add other fields as needed...
      };
      // const orderedFields = ['title', 'document_date', 'payment_date', 'supplier', 'currency_id', 'total_amount_taxed', 'total_amount_not_taxed'];
      this.expense_data?.forEach((expense) => {
        for (const [field, errorMessage] of Object.entries(fieldsConfig)) {
          if (!expense[field]) {
            this.hasErrors = true;
            this.checkAlertEror(errorMessage);
          }
          if (
            expense["total_amount_not_taxed"] > expense["total_amount_taxed"]
          ) {
            this.hasErrors = true;
            this.checkAlertEror(
              "Amt Excl. Tax cannot be greater than Amt Incl. Tax"
            );
          }
        }
      });
      setTimeout(() => {
        this.hasErrors = false;
        this.saveClicked = false;
      }, 1500);
    },
  },
  created() {
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.getBudgetPosition();
    this.generateRows();
    this.expense_year =
      this.allExpenses?.duration?.year || this.currentDate.getFullYear();
    this.expense_month =
      this.allExpenses?.duration?.month || this.currentDate.getMonth() + 1;
    this.selectedOption =
      this.capitalizeFirstLetter(
        this.allExpenses?.expense_data[0]?.expense_type
      ) || "Cash";
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 8px;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
  outline: none;
}
input:focus {
  background: #ffffff;
  border: 1px solid #fdc6c2;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border-radius: 8px;
}

.no-padding {
  padding: 0px !important;
}
</style>
