<template>
  <div>
    <button
      :disabled="disabled"
      @click="handleClickEvent"
      type="button"
      :class="[
        'w-full py-[12px] px-[10px] inline-flex justify-center items-center gap-2 bg-transparent !font-inter !text-[1rem] !font-medium rounded-lg border border-transparent !font-medium  !text-[#344054] focus:outline-none text-sm',
        { disabled: disabled },
        $attrs.class
      ]"
    >
      <slot name="icon"></slot>
      {{ $t(`${buttonLabel}`) }}
    </button>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App Primary Button",
  props: {
    buttonLabel:{
      type: String,
      default: 'Button'
    },
    id: {
     type: String,
     default: ''
    },
    disabled:{
      type:Boolean,
      deafault: false
    },
  },
  methods: {
    handleClickEvent() {
      this.$emit("click-event");
    },
  },
});
</script>
<style scoped>
button {
  outline-width: 0;
  height: 40px;
}

/* Set the disabled background style rules */
.disabled {
  opacity: 0.6;
}
</style>
