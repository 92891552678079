<template>
  <div>
    <div class="scrollable">
      <div class="scrollable-content" v-dragScroll>
        <overview-heading
          :selected-currency="selectedCurrency"
        ></overview-heading>
        <project-overview-list
          @update:filterBy="handleFilterByChange"
          @selected-currency="handleSelectedCurrency"
          @lock-data="lockMonthData = $event"
        ></project-overview-list>
        <additional-info
          v-if="showAdditionalInfo"
          :show-lock-button="filteredMonth"
          :lock-month-data="lockMonthData"
        ></additional-info>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AdditionalInfo from "./AdditionalInfo.vue";
import OverviewHeading from "./OverviewHeading.vue";
import ProjectOverviewList from "./ProjectOverviewList.vue";

export default defineComponent({
  name: "ProjectOverview",
  components: { OverviewHeading, ProjectOverviewList, AdditionalInfo },
  data() {
    return {
      showAdditionalInfo: false,
      selectedCurrency: null,
      filteredMonth: false,
      lockMonthData: null,
    };
  },
  // directives: {
  //   dragScroll: {
  //     bind(el) {
  //       let isDown = false;
  //       let startX;
  //       let scrollLeft;

  //       el.addEventListener("mousedown", (e) => {
  //         isDown = true;
  //         startX = e.pageX - el.offsetLeft;
  //         scrollLeft = el.scrollLeft;
  //         el.style.cursor = "grabbing";
  //         el.style.userSelect = "none";
  //       });

  //       el.addEventListener("mouseleave", () => {
  //         isDown = false;
  //         el.style.cursor = "grab";
  //       });

  //       el.addEventListener("mouseup", () => {
  //         isDown = false;
  //         el.style.cursor = "grab";
  //       });

  //       el.addEventListener("mousemove", (e) => {
  //         if (!isDown) return;
  //         e.preventDefault();
  //         const x = e.pageX - el.offsetLeft;
  //         const walk = (x - startX) * 3; // The number 3 will decide the sensitivity of the scrolling
  //         el.scrollLeft = scrollLeft - walk;
  //       });
  //     },
  //   },
  // },
  computed: {
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditPmLocal() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    pmLocalID() {
      return this.$store.getters["pmLocalModules/isPmLocalID"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-pm-local" &&
        this.isEditPmLocal &&
        !this.isEditButton
      );
    },
  },
  methods: {
    handleFilterByChange(value) {
      this.showAdditionalInfo = value === "Monthly";
      this.filteredMonth = value === "Monthly";
    },
    handleSelectedCurrency(value) {
      this.selectedCurrency = value;
    },
    getSingleOffice(id) {
      if (this.pmLocalID) {
        const payload = {
          load_page: true,
          pmLocalID: id,
        };
        this.$store.dispatch("pmLocalModules/getSinglePmlocal", payload);
      }
    },
  },
  mounted() {},
  created() {
    this.getSingleOffice(this.pmLocalID)
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
  },
});
</script>

<style scoped>
.scrollable-content {
  position: relative;
  overflow-x: auto; /* Now this element is scrollable */
  overflow-y: auto;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 20px;
  height: 65vh; /* Adjust the height to accommodate scrollbar height */
  /* cursor: grab; */
}
.scrollable-content::-webkit-scrollbar {
  height: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 10px;
}
</style>
