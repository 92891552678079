<template>
  <form>
    <v-row class="pa-0 mt-4 mb-3 ">
      <v-col cols="12" class="">
        <div>
          <div>

            <div class="flex flex-col items-left mt-0">
              <div
                  class="h-[60px] border-b-2 border-dotted border-gray-400  w-full p-0 flex justify-left items-center bg-white"
              >

                <img
                    :src="isSigned ? signedUrl : imageUrl"
                    alt=""
                    class="max-h-[60px] min-w-[100px] max-w-[200px]"
                    v-if="imageUrl || signedUrl"
                />
                <p
                    @click="signOrUpload"
                    class="text-[#8B2923] text-sm cursor-pointer !font-['inter'] font-normal"
                    v-else
                >
                  {{ $t("requestFundsTab.clickToSign") }}
                </p>
              </div>
            </div>
            <input
                type="file"
                @change="handleFileUpload"
                style="display: none"
                ref="fileInput"
                accept="image/*"
            />
          </div>
          <div
              class="text-gray-700 truncate text-sm !font-semibold !font-['inter'] leading-7 h-[30px] w-full p-0 flex justify-left mt-2"
          >
            <p>
              {{ signee }}
            </p>

          </div>
          <p>( {{ $t(position.getTranslationKey()) }} )</p>

          <div
              class="mt-5 text-gray-700 text-sm !font-['inter'] leading-7 h-[40px]  w-full p-0 flex justify-left"
          >
            <div v-if="dateSigned">
              <p>{{$t('Signed on'.getTranslationKey())}} :</p>
              {{  formattedDate }}
            </div>
            <div v-else class="border-b-2 border-dotted border-gray-400">

              <input  @change="emitDate" class="w-full" type="date" v-model="date" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </form>
  <app-dialog
      ref="dialogUploadState"
      width="586"
      height="800"
      :title="$t('contracts.cropAnduploadSiganture')"
      :subtitle="``"
  >
    <template #content>
      <VueCropper
          v-if="selectedFile"
          ref="cropper"
          :src="imageUrl"
          view-mode="1"
          :guides="true"
          :auto-crop="true"
          :auto-crop-area="0.5"
          :movable="true"
          :zoomable="true"
          :drag-mode="'crop'"
          :data="{ width: 400, height: 400 }"
      ></VueCropper>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
              :buttonLabel="$t('projects.cancel')"
              @click-event="cancelUpload()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
              :buttonLabel="$t('contracts.uploadSignature')"
              :loading="isLoading"
              @click-event="cropImage()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import { getTransString, formatDate } from "@/services/helpers";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Api from "@/services/api";
import { uploadEmployeeSignature } from "@/services/endpoints";
import { mapState } from "vuex";

export default defineComponent({
  name: "UploadSignature",
  components: {
    VueCropper,
  },
  props: {
    signee: {
      type: String,
      default: "",
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
    signedUrl: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "",
    },
    dateSigned: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogUploadState: false,
      imageUrl: null,
      selectedFile: null,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      showSignature: false,
      date: new Date().toISOString()?.substr(0, 10),
      signed: false
    };
  },
  computed: {
    signature() {
      return this.$store.getters["commonResources/signatureImageUrl"];
    },
    signDate (){
      return formatDate(this.date, this.appLocaleLang)
    },
    formattedDate(){
      return formatDate(this.dateSigned, this.appLocaleLang)
    },

    appLocaleLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  methods: {
    emitDate(){
      this.$store.commit("commonResources/SET_SIGNATURE_DATE_TO_STATE", this.date);
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    openFileInput() {
      this.showSignature = true;
      this.$refs.fileInput.click();
    },
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.selectedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        this.uploadImage();
      }, "image/jpeg");
      this.$refs.dialogUploadState.showDialog = false;
    },
    clearImage() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogUploadState.showDialog = false;
      this.deleteSignature();
    },
    cancelUpload() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogUploadState.showDialog = false;
    },
    signOrUpload() {
      if(this.signature){
        this.imageUrl = this.signature;
        this.signed = true
      } else {
        this.showSignature = true;
        this.openFileInput();
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const validateImageType = ["image/jpeg", "image/png", "image/jpg"];
      if (!validateImageType.includes(file.type)) {
        this.alertType = "error";
        this.alertState = true;
        this.alertTitle = "Please upload a valid image file.";
        return;
      }
      this.selectedFile = file;
      this.imageUrl = URL.createObjectURL(file);
      // this.uploadImage();
      this.$refs.dialogUploadState.showDialog = true;
    },
    uploadImage() {
      if (!this.selectedFile) {
        alert("Please select an image to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("signature", this.selectedFile);
      Api()
          .post(`${uploadEmployeeSignature}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.detail;
              this.$store.dispatch(
                  "commonResources/getSignature"
              );
              this.signed = true
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertState = true;
              this.alertTitle = "Error uploading image. Please try again.";
            }
          });
    },
  },
  mounted(){
    this.$store.commit("commonResources/SET_SIGNATURE_DATE_TO_STATE", this.signDate);
    this.$store.dispatch(
        "commonResources/getSignature"
    );
  }
});
</script>

<style lang="scss" scoped></style>
