<template>
  <div>
    <button
      @click="handleClickEvent"
      type="button"
      :disabled="disabled"
      :class="[
        'bg-[#8b2923] w-full py-[10px] px-[10px] inline-flex justify-center items-center gap-2 !font-inter !text-[1rem] !font-medium rounded-lg border border-transparent !text-white hover:bg-[#A2544F] focus:ring-[#8B2923] focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800',
        { disabled: disabled },
      ]"
      :style="style"
    >
      <slot name="icon"></slot>
      <span v-if="!loading">{{ $t(`${buttonLabel}`) }}</span>
      <div class="spinner-container" v-else>
        <div class="spinner"></div>
      </div>
    </button>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App Primary Button",
  props: {
    buttonLabel:{
      type: String,
      default: 'Button'
    },
    id: {
     type: String,
     default: ''
    },
    disabled:{
      type:Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'button-width'
    },
    style: {
      type: Object
    }
  },
  methods: {
    handleClickEvent() {
      this.$emit("click-event");
    },
  },
});
</script>
<style scoped>
.disabled {
  background-color: #ae6a65;

  /* Set the desired disabled background color */
}

button {
  background-color: #8b2923;
  outline-width: 0;
  height: 40px;
  /* Set the desired normal background color */
}
.spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  font-size: 16px;
  border: 3px dotted #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-container {
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    border-top-color: #fff;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    border-top-color: #bcbaba;
  }
}

button:disabled:hover {
  background-color: #ae6a65;
}
</style>
