<template>
  <div :class="disabled ? 'pointer-events-none' : ''">
    <v-menu location="start" :disabled="disabled">
      <template v-slot:activator="{ props }">
        <v-icon icon="custom:dotVertical" v-bind="props" class="mt-4"></v-icon>
      </template>
      <v-list class="rounded-lg item">
        <v-list-item class="my-n1 hover:bg-gray-200" @click="emitEdit(item)">{{
          $t("apptable.edit")
        }}</v-list-item>
        <v-list-item
          class="my-n1 hover:bg-gray-200"
          @click="emitDelete(item)"
          >{{ $t("apptable.delete") }}</v-list-item
        >
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitEdit(data) {
      this.$emit("edit-event", data);
    },
    emitReset(data) {
      this.$emit("reset-event", data);
    },
    emitDelete(data) {
      this.$emit("delete-event", data);
    },
  },
};
</script>
<style scoped>
.v-menu:disabled {
  cursor: default;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 30px;
  padding-top: 0px;
  cursor: pointer;
  padding-bottom: 0px;
  width: 109px;
  height: 38px;
}
</style>
