export default class Queue {
  constructor() {
    this.items = [];
  }

  enqueue(item) {
    this.items.push(item);
    this.getItem();
  }

  dequeue() {
    return this.items.shift();
  }

  addMore(item) {
    this.items.push(item);
  }

  peek() {
    if (this.items.length > 0) {
      return this.items[0];
    }
  }

  getSize() {
    this.getItem();
    return this.items.length;
  }

  getItem() {
    return this.items;
  }

  isEmpty() {
    return this.getSize() === 0;
  }

  clearItems() {
    this.items = [];
  }
  removeItem(index) {
    this.items.splice(index, 1);
    this.getItem();
  }
}
