<template>
  <v-sheet class="bg-transparent">
    <app-table
      :showCursor="false"
      :items="isGeneral"
      :headers="header"
      @edit-event="showDialog({ $event, data: 'edit' })"
      @delete-event="showDeleteDialog({ $event, data: 'delete' })"
    >
      <template #title>
        <h3 class="H500">
          {{ $t("generalConfig.settings") }}
        </h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('generalConfig.add')"
          @click-event="showDialog({ data: 'add' })"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
      <template #footer>
        <Paginator
          :total-items="totalPages"
          v-model="currentPage"
          :items-per-page="10"
          :max-pages-shown="5"
          :on-click="loadMore"
        />
      </template>
    </app-table>
    <!-- dialog for adding and updating config types -->
    <app-dialog
      ref="dialog"
      :title="
        combineTranslation(
          $t(title),
          $t(getTranslatedStringKey(tabsInfo?.singular))
        )
      "
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('generalConfig.title')"
          v-model.trim="addTitle"
        >
        </app-input-field>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
              :buttonLabel="
                isEdit ? $t('generalConfig.update') : $t('generalConfig.add')
              "
              @click-event="isEdit ? updateSettings() : addToSettings()"
              :disabled="addTitle === ''"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for deleting config types -->
    <app-dialog
      ref="isDeleteDialog"
      :title="
        combineTranslation(
          $t(title),
          $t(getTranslatedStringKey(tabsInfo?.singular))
        )
      "
      :subtitle="
        combineTranslation(
          $t(subTitle1),
          $t(getTranslatedStringKey(tabsInfo?.singular)).toLowerCase(),
          $t(subTitle2)
        )
      "
      height="302"
      width="408"
    >
      <template #content>
        <app-select-field-object
          :isTranslation="false"
          class="mb-3"
          type="text"
          :options="currentTypes"
          v-model="modelValue"
        ></app-select-field-object>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteSettings()"
            >
              {{ $t("generalConfig.replace") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="
        getAlertMessage(
          $t(this.getTranslatedStringKey(tabsInfo?.singular)),
          $t(this.getTranslatedStringKey(alertTitle)),
          $t(alertTitle)
        )
      "
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "General",
  components: {
    Paginator,
  },
  props: {
    tabsInfo: {
      type: Object,
    },
    tabType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      buttonLabel: "Add",
      label: "Title",
      title: "",
      subTitle: "",
      subTitle1: "",
      subTitle2: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      header: ["Title"],
      placeholder: "",
      items: null,

      options: [""],
      currentTypes: [],
    };
  },
  computed: {
    isGeneral() {
      const generalData =
        this.$store.getters[`hrConfigurations/${this.tabsInfo?.getter}`];
      const reduceJobData = generalData?.reduce((prev, next) => {
        return next.id !== this.generalTypeID
          ? [...prev, { ...next, value: next.id, id: next.title }]
          : prev;
      }, []);
      return reduceJobData;
    },
    currentPage() {
      return this.$store.getters["hrConfigurations/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrConfigurations/isTotalPages"];
    },
  },
  watch: {
    tabsInfo() {
      this.getAllSettings(this.currentPage);
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    combineTranslation(...args) {
      return args.reduce((acc, curr) => acc + " " + curr, "");
    },
    getAlertMessage(configType, alertKeyTextTrans, alertText) {
      if (this.alertType === "success") {
        return this.combineTranslation(configType, alertKeyTextTrans);
      } else {
        return alertText;
      }
    },
    showDialog(data) {
      this.jobID = data?.$event?.value;
      this.modelValue = data?.$event?.title;
      if (data.data === "add") {
        this.title = "generalConfig.add";
        this.$refs.dialog.showDialog = true;
      } else if (data.data === "edit") {
        this.isEdit = !this.isEdit;
        this.buttonLabel = "generalConfig.update";
        this.label = "generalConfig.edit";
        this.addTitle = data.$event.title;
        this.title = "generalConfig.update";
        this.$refs.dialog.showDialog = true;
      }
    },
    showDeleteDialog(data) {
      if (data.data === "delete") {
        this.generalTypeID = data?.$event?.value;
        this.currentTypes = this.isGeneral?.filter(
          (item) => item.value !== this.generalTypeID
        );

        if (this.currentTypes.length === 0) {
          this.alertType = "error";
          this.alertTitle = "generalConfig.noReplacementFound";
          this.alertState = !this.alertState;
          return;
        } else {
          this.modelValue = this.currentTypes[0].value;
        }
        this.isDelete = true;
        this.cancelLabel = "generalConfig.cancelLabel";
        this.title = "generalConfig.delete";
        this.subTitle1 = "generalConfig.firstSubTitle";
        this.subTitle2 = "generalConfig.secondSubTitle";
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      setTimeout(() => {
        this.isDelete = false;
        this.confirmDelete = null;
        this.subTitle = "";
      }, 600);
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.addTitle = "";
        this.isEdit = false;
        this.isAdd = false;
        this.isDelete = false;
        this.subTitle = "";
        this.buttonLabel = "generalConfig.add";
        this.label = "generalConfig.title";
        this.cancelLabel = "generalConfig.cancel";
      }, 600);
    },
    addToSettings() {
      const payload = {
        title: this.addTitle,
        type: this.tabsInfo.name,
        is_editable: true,
      };
      Api()
        .post("/configuration/general/add-general/", payload)
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "added successfully";
            this.getAllSettings(this.currentPage);
            setTimeout(() => {
              this.addTitle = "";
              this.$refs.dialog.showDialog = false;
            }, 1000);
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateSettings() {
      const payload = {
        title: this.addTitle,
      };
      Api()
        .patch(`/configuration/general/update-general/${this.jobID}/`, payload)
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "updated successfully";
            this.getAllSettings();
            setTimeout(() => {
              this.addTitle = "";
              this.subTitle = "";
              this.isEdit = false;
              this.$refs.dialog.showDialog = false;
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            if (error.response.status === 311) {
              this.alertTitle = "alerts.dataExists";
            } else {
              this.alertTitle = error.response.data.detail;
            }
            this.alertState = !this.alertState;
          }
        });
    },
    deleteSettings() {
      Api()
        .delete(
          `/configuration/general/delete-general/${this.generalTypeID}/${this.modelValue}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "deleted successfully";
            this.getAllSettings(this.currentPage);
            this.$refs.isDeleteDialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllSettings(page) {
      Api()
        .get(
          `/configuration/general/get-general/${this.tabsInfo?.name}/${page}/`
        )
        .then((response) => {
          const payload = response.data;
          this.items = payload;
          this.$store.commit(
            `hrConfigurations/${this.tabsInfo?.setter}`,
            payload.data
          );
          this.$store.commit(
            "hrConfigurations/SET_CURRENT_PAGE_TO_STATE",
            payload.current_page
          );
          this.$store.commit(
            "hrConfigurations/SET_TOTAL_PAGES_TO_STATE",
            payload.total_data
          );
        });
      // .catch((error) => {
      //
      // });
    },
    loadMore() {
      // console('go to next page')
      const page = this.currentPage + 1;
      this.getAllSettings(page);
    },
  },

  updated() {
    if (this.tabType === "projectConfig") {
      this.$store.commit(
        "commonResources/SET_CURRENT_TAB_TO_STATE",
        this.tabsInfo?.routeIndex
      );
    }
  },

  created() {
    if (this.tabType === "projectConfig") {
      this.$store.commit(
        "commonResources/SET_CURRENT_TAB_TO_STATE",
        this.tabsInfo?.routeIndex
      );
    }
    this.$store.commit("hrConfigurations/SET_CURRENT_TAB_TO_STATE", 0);
    const page = this.currentPage;
    this.getAllSettings(page);
  },
});
</script>
