<template>
  <div>
    <app-table
      :isPartner="true"
      :headers="headers"
      :items="isAllContractPartners"
      :showCursor="false"
      @edit-event="showDialog({ $event, data: 'edit' })"
      @delete-event="showDialog({ $event, data: 'delete' })"
    >
      <template #title>
        <h3 class="H500">{{ $t("projects.allPartners") }}</h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('projects.addPartner')"
          @click-event="showDialog({ data: 'add' })"
          :disabled="shouldDisableInput ? 'disabled' : null"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
      <template #footer>
        <div>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </div>
      </template>
    </app-table>

    <!-- add and update bank details -->
    <app-dialog
      ref="dialogState"
      :title="isEdit ? $t('projects.editPartner') : $t('projects.addPartner')"
      subTitle="xjgjh"
      width="408"
    >
      <template #content>
        <app-single-select
          :showCheckbox="false"
          :labelPlaceholder="$t('projects.partner.selectPartner')"
          :label="$t('projects.partner.partnersName')"
          :options="isAllPartners"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchPartners')"
          v-model="payload.partner_id"
          :hasError="isFieldInvalid('partner_id')"
          :isRequired="true"
        >
        </app-single-select>

        <app-toggle-button
        class="justify-between mt-3"
          :id="member"
          :label="$t('projects.memberBiddingCommunity')"
          v-model="payload.member_bidding_community"
        ></app-toggle-button>
        <app-toggle-button
        class="justify-between mt-3"
          :id="profit"
          :label="$t('projects.profitShare')"
          v-model="payload.profit_share"
        ></app-toggle-button>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <app-primary-button
              :buttonLabel="
                isEdit ? $t('addcustomrole.saveChanges') : $t('projects.add')
              "
              @click="isEdit ? updateContractPartner() : addContractPatner()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <!-- delete bank details -->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employees.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteContractPartner()"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { convertYesToTrue, extractUnderscore, getTransString } from "@/services/helpers";
import { fetchAllPartnerWPS, addOrUpdatePartnerWPS, deletePartnerWPS } from "@/services/endpoints"
import Paginator from "@/components/shared/PaginationComponent.vue";

import Api from '@/services/api'

export default defineComponent({
  name: "Partners",
  components: {
    Paginator,
  },
  props: {},
  data() {
    return {
      headers: ["Sr. No.", 'Name of Partner', 'Member Bidding Community', 'Profit Share'],
      currentPages: 1,
      isEdit: false,
      isDelete: false,
      title: "",
      subTitle: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
      dialogState: false,
      newState: {},
      payload: {
        partner_id: null,
        contract_id: this.$store.state.projectModules.contractID,
        member_bidding_community: false,
        profit_share: false
      },
      partnerOption: [],
      contractPartnerId: "",
      selectedPartner: '',
      requiredFields: ['partner_id'],
          isDirty: [],
          hasError: ""
    }
  },

  computed: {
    isAllPartners() {
      const modObject = this.$store.getters["partnerModules/isAllPartners"];

      return modObject?.map((obj) => {
        const { id: value, "company_name": text } = obj;
        return { text, value };
      });
    },
    isAllPartnersSecond() {
      const modObject = this.$store.getters["partnerModules/isAllPartners"];
      return modObject?.map((obj) => {
        return obj.company_name;
      });
    },
    isContractID() {
      return this.$store.getters["projectModules/isContractId"]
    },
    isAllContractPartners() {
      const modifiedTrue = this.$store.getters["projectModules/isContractPartner"]
      // const transformedKey = 'sr no';
      const newKey = 'sr. no.';
      modifiedTrue?.map(obj => {
        obj[newKey]= obj['sr no']

        delete obj['sr no']
        for (let key in obj) {
          if (typeof  obj[key] === 'boolean') {
             obj[key] =  obj[key] ? 'Yes' : 'No'
          }
        }
      })

      return modifiedTrue
    },
    currentPage() {
      return this.$store.getters["projectModules/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["projectModules/isPartnerwpsTotalPages"];
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
  },
  watch: {
    isContractID(value) {
      this.payload.contract_id = value
    },

  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkAlertEror(message){
      this.alertState = true
      this.alertType = "error";
      this.alertTitle = message;
    },
      isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
      inputFieldsValidation(){
    this.hasError = false
    Object.keys( this.payload).forEach((key) => {
        if (this.requiredFields?.includes(key) && ( this.payload[key] === "" ||  this.payload[key] === undefined || this.payload[key] === null)) {
          this.isDirty.push(key);

          this.hasError = true
          this.checkAlertEror('Please fill out the required fields');
        }
      });
      if (this.hasError) {

          return this.hasError;
        }

  },
    showDialog(data) {
      if (data.data === "add") {
        this.isEdit = false
        this.payload.partner_id = ""
        this.$refs.dialogState.showDialog = true;
        this.subTitle = "";
      } else if (data.data === "delete") {
        this.contractPartnerId = data?.$event?.id
        this.$refs.dialog.showDialog = true;
        this.title = "projects.deletePartnerTitle";
        this.subTitle = "projects.deletePartnerSubitle";
      }
      const newdata = data?.$event;
      if (data.data === "edit") {
        this.contractPartnerId = data?.$event.id
        const modifiedData = {}
        for (const key in newdata) {
          const newKey = key.replace(/ /g, "_");
          modifiedData[newKey] = newdata[key];

        }
        for (const key in modifiedData) {
          const value = modifiedData[key];
          if (typeof value === 'string') {
            if (value.toLowerCase() === 'yes') {
              modifiedData[key] = true;
            } else if (value.toLowerCase() === 'no') {
              modifiedData[key] = false;
            }
          }
        }

        this.subTitle = "";
        this.isEdit = true;
        this.$refs.dialogState.showDialog = true;
        this.payload.partner_id = modifiedData.partner_id

        this.payload.contract_id = this.$store.state.projectModules.contractID
        this.payload.member_bidding_community = modifiedData.member_bidding_community
        this.payload.profit_share = modifiedData.profit_share
      }
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.payload.partner_id = ""
      this.payload.member_bidding_community = false
      this.payload.profit_share = false
      this.isEdit = false
      this.isDirty = []
    },
    handlePartnersData(data) {
      this.payload.partner_id = data ? data?.value : ""
    },


    getAllContractPartners(page) {
      Api()
        .get(fetchAllPartnerWPS + `${page}` + `/${this.isContractID}`)
        .then(response => {
          if (response.data.status === "success") {
            const data = response?.data?.data
            const modifiedData = extractUnderscore(data)
            this.$store.commit(
              "projectModules/SET_CONTRACT_PARTNERS_TO_STATE",
              modifiedData
            );
            this.$store.commit(
              "projectModules/SET_PARTNERWPS_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
    },
    addContractPatner() {
      this.inputFieldsValidation()
        if (this.hasError) return this.hasError;
      Api()
        .post(addOrUpdatePartnerWPS, this.payload)
        .then(response => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false
            this.alertState = true;
            this.alertType = "success"
            this.alertTitle = response?.data?.detail;
            this.getAllContractPartners(this.currentPage)
            this.hideDialog()

          } else if (response.data.status === "error") {
            this.$refs.dialogState.showDialog = false
            this.alertState = true;
            this.alertType = "error"
            this.alertTitle = response?.data?.detail;
          }
        })
      // .catch(error => {
      //   if(error){
      //     this.$refs.dialogState.showDialog = false
      //     this.alertState = true;
      //     this.alertType = "error"
      //     this.alertTitle = error.response?.data?.detail;
      //   }
      // })
    },
    updateContractPartner() {
      this.inputFieldsValidation()
        if (this.hasError) return this.hasError;
      const payload = {
        partner_id: this.payload.partner_id,
        contract_id: this.$store.state.projectModules.contractID,
        member_bidding_community: this.payload.member_bidding_community,
        profit_share: this.payload.profit_share,
        contract_partner_id: this.contractPartnerId
      }
      Api()
        .post(addOrUpdatePartnerWPS, convertYesToTrue(payload))
        .then(response => {
          if (response.data.status === "success") {
            this.$refs.dialogState.showDialog = false
            this.alertState = true;
            this.alertType = "success"
            this.alertTitle = response?.data?.detail;
            this.getAllContractPartners(this.currentPage)
            this.payload.partner_id = ''
            this.payload.member_bidding_community = false
            this.payload.profit_share = false

          }
        })
    },
    deleteContractPartner() {
      Api()
        .delete(deletePartnerWPS + `${this.contractPartnerId}`)
        .then(response => {
          if (response.data.status === "success") {
            this.alertState = true
            this.alertType = "success"
            this.alertTitle = response?.data.detail
            this.$refs.dialog.showDialog = false;
            this.getAllContractPartners(this.currentPage)
          }
          if (response.data.status === 'error') {
            this.$refs.dialog.showDialog = false;
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
    },
    loadMore() {
      let page = this.currentPages
      this.getAllContractPartners(page)
    }
  },
  mounted() {
    this.$store.commit(
      "projectModules/SET_CONTRACT_PARTNERS_TO_STATE",
      [])
    this.$store.dispatch("partnerModules/getAllPartners");

  },

  created() {
    // this.$store.dispatch("partnerModules/getAllPartners");
    this.getAllContractPartners(this.currentPage)
  }
});
</script>
<style scoped></style>
