<template>
  <div>
    <app-header
      :name="employeeName"
      class="mb-5 ml-2"
      :clickEvent="handleGoToBack"
      :show-arrow-back="true"
    >
      <template #action v-if="canEdit">
        <app-secondary-button
          v-show="hideEditButton"
          :buttonLabel="$t('employees.editProfile')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
    </app-header>
    <div>
      <div>
        <button
          v-for="(tab, index) in tabList"
          :key="index"
          :class="['tab-button', { active: currentTab === tabs[index] }]"
          @click="handleProceed(index)"
        >
          {{ $t(tab.getTranslationKey()) }}
        </button>
        <div class="mt-2 border-t-[1px]"></div>
      </div>
    </div>
    <component
      :is="currentTab"
      v-bind="isCurrentTab"
      class="pt-5"
      :handleButtonRoute="handleButtonRoute"
    ></component>
  </div>
  <app-unsaved-changes-dialog
    ref="unsavedChanges"
    :title="$t('employees.unsavedChangesTitle')"
    :subtitle="$t('employees.unsavedChangesSubTitle')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click="closeDialog"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('employees.discardChanges')"
            @click="discardChanges"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-unsaved-changes-dialog>
</template>

<script>
import { defineComponent } from "vue";
import Personal from "@/components/dashboard/employees/Personal.vue";
import Contact from "@/components/dashboard/employees/Contact.vue";
import Employment from "@/components/dashboard/employees/Employment.vue";
import Qualification from "@/components/dashboard/employees/Qualifications.vue";
import Projects from "@/components/dashboard/employees/Projects.vue";
import Documents from "@/components/dashboard/employees/Documents.vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import EmployeeTimeOff from "@/components/dashboard/employees/EmployeeTimeOff.vue";
import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";
// import Signature from "@/components/dashboard/employees/Signature.vue";

export default defineComponent({
  name: "AddEmployee",
  components: {
    Personal,
    Contact,
    Employment,
    Qualification,
    EmployeeTimeOff,
    Projects,
    Documents,
    AppHeader,
    AppUnsavedChangesDialog,
    // Signature,
  },
  data() {
    return {
      employeeName: "",
      currentTab: "Personal",
      newTabIndex: null,
      isArrowBack: false,
      tabsDefault: [
        "Personal",
        "Contact",
        "Employment",
        "Qualification",
        "EmployeeTimeOff",
        // "Signature",
      ],
      tabListDefault: [
        "Personal",
        "Contact",
        "Employment",
        "Qualification",
        "Time Off",
        // "Signature",
      ],
    };
  },
  computed: {
    noAccess() {
      const modifiedData = this.$store.getters["auth/noAccess"];
      return modifiedData?.filter((module) => module.module_parent_name === "Employee")
        .map((module) => module.module_name);
    },
    canView() {
      return this.$store.getters["auth/canView"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      // const findModules = modifiedData?.filter((module)=>module.module_parent_name==='Employee').map((module)=>module.module_name)
      const findModules = modifiedData
        ?.filter((module) => module.module_parent_name === "Employee")
        .map((module) => module.module_name);
      this.$store.commit("auth/SET_CAN_EDIT_TO_STATE", findModules);
      return findModules;
    },

    tabList() {
      const notFoundInFilter = [];
      for (const item of this.tabListDefault) {
        if (!this.noAccess.includes(item)) {
          notFoundInFilter.push(item);
        }
      }
     
      return notFoundInFilter;
    },

    tabs() {
      const notFoundInFilter = [];
      // const tabsModified = []
      this.tabListDefault.forEach((item, index) => {
        if (!this.noAccess.includes(item)) {
          const tab = this.tabsDefault[index];

          notFoundInFilter.push(tab);
        }
      });

      return notFoundInFilter;
    },
    hideEditButton() {
      return (
        !this.isEditButton &&
        this.canEdit?.includes(this.currentTab) &&
        this.currentTab !== "EmployeeTimeOff" &&
        !this.isHome
      );
    },
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEmployeesUpdate() {
      return this.$store.getters["hrResources/isEmployeesUpdate"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    editEmployeeLang() {
      return this.translation.locale;
    },
    isEmployeeName() {
      return this.$store.getters["hrResources/isEmployeeName"];
    },
  },
  mounted() {
    this.currentTab = this.tabs[0];
    this.$refs.unsavedChanges.showDialog = false;
  },
  watch: {
    isUserKey(newVal) {
      if (newVal) {
        const currentIndex = this.tabs.indexOf(this.currentTab);
        if (currentIndex !== -1 && currentIndex < this.tabs.length - 1) {
          this.currentTab = this.tabs[currentIndex + 1];
        }
      }
    },
  },
  methods: {
    handleBeforeUnload(event) {
      if (this.checkChangeStatus()) {
        // Customize the confirmation message
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";

        // Display the confirmation dialog
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    checkChangeStatus() {
      return Object.values(this.isEmployeesUpdate).some((elt) => elt === true);
    },
    showEditButton() {
      this.$store.commit(
        "hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE",
        this.canEdit?.includes(this.currentTab)
      );
    },
    handleProceed(index) {
      this.newTabIndex = index;
      const currentTabStatus =
        this.currentTab === "EmployeeTimeOff"
          ? this.isEmployeesUpdate["Leaves"]
          : this.isEmployeesUpdate[this.currentTab];
      if (currentTabStatus) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.currentTab = this.tabs[index];
      }
    },
    handleButtonRoute(route) {
      this.newTabIndex = route;
      if (this.isEmployeesUpdate[this.currentTab]) {
        this.$refs.unsavedChanges.showDialog = true;
      } else {
        this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
      }
    },
    handleGoToBack() {
      if (Object.values(this.isEmployeesUpdate).some((elt) => elt === true)) {
        this.$refs.unsavedChanges.showDialog = true;
        this.isArrowBack = true;
      } else {
        this.$router.go(-1);
      }
    },
    closeDialog() {
      this.$refs.unsavedChanges.showDialog = false;
    },
    discardChanges() {
      this.$refs.unsavedChanges.showDialog = false;
      const newUpdates = Object.keys(this.isEmployeesUpdate).reduce(
        (acc, curr) => {
          if (this.isEmployeesUpdate[curr]) {
            acc[curr] = false;
          }
          return acc;
        },
        {}
      );
      this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
      if (this.isArrowBack) {
        this.$router.go(-1);
      } else {
        this.currentTab = this.tabs[this.newTabIndex];
      }
    },
  },
  created() {
    this.employeeName = this.isEmployeeName;
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  updated() {
    this.currentTab = this.tabsDefault[this.isCurrentTab];
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
});
</script>

<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
.tab-button.disabled {
  cursor: not-allowed;
}
</style>
