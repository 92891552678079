<template>
  <div class="!bg-[#FCFCFD] rounded-lg border mt-7 pl-5 py-[6px]">
    <!-- budget total -->
    <v-row class="">
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.totalIncome") }}
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(totalIncome)
              : totalIncome?.formatCurrency()
          }}
        </p>
      </v-col>
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.totalCost") }}
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(totalCost)
              : totalCost?.formatCurrency()
          }}
        </p>
      </v-col>
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.profitMargin1") }}
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(profitMarginOne)
              : profitMarginOne?.formatCurrency()
          }}
        </p>
      </v-col>
      <v-col cols="3" class="float-right">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.profitMargin2") }}
          <!-- Profit Margin 2 <br /> -->
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(profitMarginTwo)
              : profitMarginTwo?.formatCurrency()
          }}
        </p>
      </v-col>
    </v-row>
  </div>
  <div class="!bg-[#FCFCFD] rounded-lg border mt-7 pl-5 py-[6px]">
    <v-row class="">
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.projectAmount") }}
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(Number(projectAmount))
              : Number(projectAmount)?.formatCurrency()
          }}
        </p>
      </v-col>
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.totalBudget") }}
        </p>
        <p class="font-bold mt-2">
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(totalBudget)
              : totalBudget?.formatCurrency()
          }}
        </p>
      </v-col>
      <v-col cols="3">
        <p class="text-[14px] text-[#667085] font-medium">
          {{ $t("projectBudget.budgetDifference") }}
          <br />
        </p>
        <p
          class="font-bold mt-2"
          :class="{ 'red-border': budgetDifference < 0 }"
        >
          {{ isCurrentProject.currency }}
          {{
            appLang === "de"
              ? germanCurrency(budgetDifference)
              : budgetDifference?.formatCurrency()
          }}
        </p>
      </v-col>
    </v-row>
  </div>
  <bop-summary :receiveTotals="receiveTotals"></bop-summary>
</template>

<script lang="js">
import { defineComponent } from "vue";
import BopSummary from "./BopSummary.vue";

import { mapState } from "vuex";
import {currencyFormatter,getTransString} from "@/services/helpers";

export default defineComponent({
  name: "Budget Total",
  components: {BopSummary  },
  props: {
    receiveTotals: {
      type: Object
    }
  },
  data() {
    return {
      backstopping: 0
    };
  },

  watch: {
  },
  computed: {
    isCurrentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    totalStatistics() {
      return this.$store.getters["projectBudgetModules/isTotalStatistics"];
    },
    contractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    totalIncome(){
      return this.totalStatistics?.total_income
    },
    totalCost(){
      return this.totalStatistics?.total_cost
    },
    profitMarginOne(){
      return this.totalStatistics?.profit_margin_1
    },
    profitMarginTwo(){
      return  this.totalStatistics?.profit_margin_2
    },
    totalBudget(){
      return this.totalStatistics?.total_budget
    },
    projectAmount(){

      return Object.keys(this.contractDetails)?.length === 0 ?  0 : this.contractDetails.project_amount
    },
    budgetDifference(){
      return this.projectAmount - this.totalBudget
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  methods: {
    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
  },
  mounted() {

  }
});
</script>
<style scoped>
.red-border {
  color: #ae6a65;
}
</style>
