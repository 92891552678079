<template>
  <div>
    <div class="flex flex-col">
      <label class="mb-1" for=""> {{ label}} </label>
      <div class="select-wrapper">
        <select
        :disabled="disabled"
          v-model="selectedMonth"
          class="border outline-none w-[320px] rounded-lg p-2 cursor-pointer hover:border-[#d8dae5] focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none] focus:ring peer block border-1 border-solid border-gray-300 box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);"
          @change="onChange"
        >
          <option value="" selected disabled>
            {{ $t("Select month"?.getTranslationKey()) }}
          </option>
          <option
            v-for="(month, index) in months"
            :value="month"
            :key="index"
          >
            {{ month }}
          </option>
        </select>
        <span class="icon-calendar" >
          <v-icon  icon="custom:calendarIcon" size="20" :class="{'!text-slate-400': disabled}" ></v-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppMonthSelect",
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Month',
    },
  },
  data() {
    return {
      selectedMonth: this.value || "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {},
  watch:{
    value(val){
      this.selectedMonth = val;
    },
    selectedMonth(val){
      this.$emit('update:modelValue', val);
    }
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.selectedMonth);
    },
  },
  created() {},
});
</script>

<style scoped>
select:disabled {
  color: #667085;
  background: #f9fafb;
  cursor: default;
}
</style>

