<template>
  <section>
    <app-signature-dialog
      ref="signatureDialog"
      :title="$t('Flight Request Details'.getTranslationKey())"
      width="65%"
    >
      <template #content>
        <div class="mt-5 text-[14px] text-[#101828] font-['inter']">
          <!-- {{ items }} -->
          <div class="mt-8">
            <app-table
              :hideMenu="true"
              :showCursor="false"
              :showCheck="false"
              :headers="headers"
              :items="tableItems"
              :hideTableHeader="false"
              :hideFooterHeader="true"
            >
              <template #title>
                <h3 class="H500">
                  {{ $t("Flight Request Details".getTranslationKey()) }}
                </h3>
              </template>
            </app-table>
          </div>
          <div class="mt-6">
            <v-row>
              <v-col cols="5" class="py-1">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("Account Holder".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        isRequestDetails.account_owner
                          ? isRequestDetails.account_owner
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("Bank".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{
                        isRequestDetails.bank_name
                          ? isRequestDetails.bank_name
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("City of Bank".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        isRequestDetails.city_of_bank
                          ? isRequestDetails.city_of_bank
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("BIC/SWIFT".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{
                        isRequestDetails.swift_code
                          ? isRequestDetails.swift_code
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="5" class="p-0">
                    <p class="font-bold text-left">
                      {{ $t("Account Number".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n5">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="6" class="p-0 ml-n3">
                    <p class="">
                      {{
                        isRequestDetails.account_number
                          ? isRequestDetails.account_number
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="7" class="py-1 d-flex align-center">
                <v-row>
                  <v-col cols="3">
                    <p class="font-bold text-left">
                      {{ $t("IBAN".getTranslationKey()) }}
                    </p>
                  </v-col>
                  <v-col cols="1" class="p-0 font-bold ml-n11">
                    <p class="text-center">:</p>
                  </v-col>
                  <v-col cols="8" class="p-0 ml-n5">
                    <p class="">
                      {{
                        isRequestDetails.iban ? isRequestDetails.iban : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="flex gap-5">
          <div class="w-[20%]">
            <app-upload-signature
              position="Requester"
              :is-signed="isRequestDetails.is_requester_signed"
              :signed-url="isRequestDetails.requester_signature"
              :date-signed="isRequestDetails.requester_signed_on"
              :signee="isRequestDetails?.owner__name||''"
            ></app-upload-signature>
          </div>
         
          <div class="w-[20%]">
            <app-upload-signature
            v-if="isRequestDetails.is_requester_signed "
            position="Project Manager"
              :is-signed="isRequestDetails.is_pm_signed"
              :signed-url="isRequestDetails.pm_signature"
              :date-signed="isRequestDetails.pm_signed_on"
              signee=""
            ></app-upload-signature>
          </div>
          <div class="w-[20%]">
            <app-upload-signature
            v-if="isRequestDetails.is_requester_signed  && isRequestDetails.is_pm_signed"
            position="CEO"
              :is-signed="isRequestDetails.is_ceo_signed"
              :signed-url="isRequestDetails.ceo_signature"
              :date-signed="isRequestDetails.ceo_signed_on"
              signee=""
            ></app-upload-signature>
          </div>
          <div class="w-[20%]">
            <app-upload-signature
            v-if="isRequestDetails.is_requester_signed  && isRequestDetails.is_pm_signed && isRequestDetails.is_ceo_signed"
            position="Controller"
              :is-signed="isRequestDetails.is_controller_signed"
              :signed-url="isRequestDetails.controller_signature"
              :date-signed="isRequestDetails.controller_signed_on"
              signee=""
            ></app-upload-signature>
          </div>
          
       
        </div>
      </template>
      <template #actions>
        <v-row>
          <v-col cols="12" class="justify-end my-2 d-flex">
            <app-secondary-button
              :buttonLabel="$t('hospitalityPackage.cancel')"
              @click="hideDialog"
            >
            </app-secondary-button>
            <app-primary-button
              :buttonLabel="$t('hospitalityPackage.send')"
              class="mx-6"
              :loading="loading"
              @click="signAndSave()"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-signature-dialog>
  </section>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import {
  signFlightRequest,
  getFlightRequesDetails,
} from "@/services/endpoints";
import { getTransString, formatDate } from "@/services/helpers";
import moment from "moment";
export default defineComponent({
  name: "SignatureForm",
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      showSignature: false,
    };
  },
  computed: {
    items() {
      const modifiedData =
        this.$store.getters["flightModule/isFlightDetailsTable"];
      const extractedData = {
        date: "",
        first_name: "",
        last_name: "",
        "gross amt.": 0,
        "net amt.": 0,
      };
      modifiedData.forEach((obj) => {

        if ("requested_on" in obj)
          extractedData.date = moment(obj.requested_on).format("D.M.YYYY");
        if ("owner__name"  in obj) {
          const firstName = `${obj.owner__name}`;
          extractedData.first_name = firstName;
        }
        if ("owner__last_name" in obj) {
          const lastName = `${obj.owner__last_name}`;
          extractedData.last_name = lastName;
        }
        if ("gross_amount" in obj)
          extractedData["gross amt."] = obj.gross_amount;
        if ("net_amount" in obj) extractedData["net amt."] = obj.net_amount;
      });
      return [extractedData];
    },

    tableItems() {
      return this.items.map((item) => {
        return {
          date: formatDate(item.date, this.appLocaleLang),
          requester: `${item.first_name} ${item.last_name}`,
          "gross amt.": this.appLocaleLang === 'de'?  `€ ${Number(item["gross amt."] || 0)?.germanCurrencyFormatter()}` : `€ ${Number(item["gross amt."] || 0)?.formatCurrency()}`,
          "net amt.": this.appLocaleLang === 'de'?  ` € ${Number(item["net amt."] || 0)?.germanCurrencyFormatter()}`:`€ ${Number(item["net amt."] || 0)?.formatCurrency()}`,
        };
      });
    },
    headers() {
      return ["Date", "Requester", "Gross Amt.", "Net Amt."];
    },
    signature() {
      return this.$store.getters["commonResources/signatureImageUrl"];
    },
    signatureDate() {
      return this.$store.getters["commonResources/signatureDate"];
    },
    currentFlightRequestId() {
      return this.$store.getters["flightModule/isEditFlightId"];
    },
    isRequestDetails() {
      return this.$store.getters["flightModule/isRequestDetails"];
    },
    currentUserDetails() {
      return this.$store.getters["auth/isUserDetails"];
    },
    appLocaleLang(){
      return this.$store.getters["translation/getLocale"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDialog() {
      this.$refs.signatureDialog.showDialog = false;
    },
    showSignatureDialog() {
      this.$refs.signatureDialog.showDialog = true;
    },
    signAndSave(){
      const payload = {
        signee: "Requester",
        signature: this.signature,
        signed_date:  moment(this.signatureDate, "DD/MM/YYYY")?.format("YYYY-MM-DD"),
      }
      Api()
      .put(`${signFlightRequest}${this.currentFlightRequestId}/`, payload)
      .then(response => {
        if(response.data.status === 'success'){
          this.alertState = true;
          this.alertTitle = response.data.detail;
          this.hideDialog() 
          this.$store.commit("flightModule/SET_EDIT_FLIGHT_TO_STATE", false);
          this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", null);
          setTimeout(() => {
            
            this.$router.go(-1);
          }, 2000);
        }
      })
      .catch( error => {
        this.alertState = true;
        this.alertType = 'error';
        this.alertTitle = error.response.data.detail;
      })
    },
    detailsOfFlight() {
      Api()
        .get(`${getFlightRequesDetails}${this.currentFlightRequestId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const flightModifiedData =
              response.data.data.flight_request_details;
            this.$store.commit(
              "flightModule/SET_REQUEST_DETAILS",
              response.data.data?.flight_request_details
            );
            this.$store.commit(
              "flightModule/SET_FLIGHT_DETAILS_TABLE",
              Object.entries(flightModifiedData).map(([key, value]) => ({
                [key]: value,
              }))
            );
          }
        })
        .catch();
    },
  },
  mounted() {
    if (this.currentFlightRequestId) {
      this.detailsOfFlight();
     
    }
  },
});
</script>

<style scoped></style>
