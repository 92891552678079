Copy code
<template>
  <section class="p-1">
    <div class="w-full">
      <div class="relative overflow-hidden bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg">
        <!-- Table Header -->
        <div class="flex flex-col items-center justify-between p-4 px-6 space-y-3 md:flex-row md:space-y-0 md:space-x-4 text-lg font-medium"  :class="{ hide: hideTableHeader }">
          <div class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            <div class="flex items-center w-full space-x-3 md:w-auto" v-if="selectedCount <= 0">
              <slot name="title"></slot>
            </div>
          </div>
        </div>

        <!-- Table Content -->
        <div class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" aria-describedby="">
            <thead class="bg-white">
              <tr>
                <th v-for="header in headers" :key="header" class="px-6 py-3 text-base !text-[#344054] !font-medium">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td v-for="header in headers" :key="header" class=" mt-3pointer-events-none">
                  {{ item[header?.toLowerCase()] || "-" }}
                </td>
                <td v-if="isIncomingInvoice">
                  {{ $t('advancePaymentRequest.markAsSeen') }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="border-t-2 min-h-[40px] max-h-[100px] flex items-center pl-6" v-if="showTotal && items?.length > 0">
            <slot></slot>
          </div>
        </div>

        <!-- Table Footer -->
        <nav v-if="items?.length > 0" class="flex items-start justify-end p-4 pr-5 space-y-3 md:flex-row md:items-center md:space-y-0 mb-n5" :class="{ hide: hideTableFooter }" aria-label="Table navigation">
          <slot name="footer"></slot>
        </nav>

        <!-- Empty State -->
        <div class="empty_state" v-else>
          <EmptyState />
          <h3 class="H500">
            {{ $t("employees.no") }} {{ pagename }} {{ $t("employees.found") }}
          </h3>
          <p class="P300">
            {{ $t("employees.youWillSee") }} {{ message }}
            {{ $t("employees.hereWhen") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script lang="js">
  import { defineComponent } from "vue";
  import EmptyState from '../../assets/icons/EmptyState.vue'
  import { getTransString} from "@/services/helpers";
  import { useI18n } from 'vue-i18n';
  
  // import Paginator from "@/components/shared/PaginationComponent.vue";
  
  export default defineComponent({
    name: "AppBasicTable",
    components: {EmptyState, },
    props: {
      items: {
        type: Array,
      },
     pagename: {
        type: String,
      },
      message: {
        type: String,
      },
      headers: {
        type: Array,
        default: null,
      },
      showCheck: {
        type: Boolean,
        default: false,
      },
    
      hideMenu: {
        type: Boolean,
        default: false,
      },
      dropDisabled: {
        type: Boolean,
        default: false,
      },
      totalPages: {
        type: Number,
        default: 10
      },
      page: {
        type: Number,
        default: 1
      },
      
      showTotal: {
        type: Boolean,
        default: false
      },
      hideTableHeader: {
        type: Boolean,
        default: false
      },
      hideTableFooter: {
        type: Boolean,
        default: false
      },

      isIncomingInvoice: {
        type: Boolean,
        default: false
      },
      
      
    },
    data() {
      return {
        currentPage: this.page,
        selectedCount: 0,
       
        headerCheck: false,
        show:null,
        
        checkSelected: false
      };
    },
  
    watch: {
     
    },
    computed: {
      currentPath() {
        return this.$route.path
      },
    
    },
    methods: {
      showItem(item) {
      this.show = item;
      },
      hideItem() {
        this.show = null;
      },
      loadMore(){
        this.callBack()
      },
     
      getFormattedTime(time) {
        const options = { hour: 'numeric', minute: 'numeric', hour12: false };
        const formattedTime = new Date(`2000-01-01T${time}`)?.toLocaleTimeString([], options);
        return formattedTime?.replace(/\s/g, '').toLowerCase();
      },
      transText(text) {
        const {t} = useI18n();
        return t(getTransString(text));
      },
      getTranslatedStringKey(field) {
        return getTransString(field)
      },
     
    }
  });
  </script>
  <style scoped>
  table {
    color: #344054;
  }
  tr > th {
    background-color: #F2F4F7;
  }
  tbody tr:nth-of-type(even) {
    background-color: #f9fafb;
  }
 
  .app-menu {
    display: inline-flex;
  }
  td {
    padding-left: 24px;
  }
  tr {
    padding: 16px 24px;
    height: 56px;
    width: 100%;
  }
  
  .container {
    display: flex;
    align-items: center;
  }
  

  
  .v-list-item--density-default.v-list-item--one-line {
    min-height: 30px;
    padding-top: 0;
    cursor: pointer;
    padding-bottom: 0;
  
    height: 30px;
    background-color: #ffff;
    border-radius: 8px;
  }
  .selector {
    width: 20px;
    height: 20px;
    background: #f4eae9;
    border: 1px solid #ae6a65;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tablecard {
    border: 2px solid #eaecf0;
  }
  .empty_state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    padding: 20px;
  
    flex-direction: column;
    min-height: 400px;
  }
  .time_header {
    display: flex;
    align-items: center;
  }
  .time_header > p {
    margin: 5px;
    border-radius: 8px;
  }
  .count {
    padding: 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    background: #f2f4f7;
    border-radius: 16px;
  }

  .modal,
  .countries-modal {
    position: absolute;
    z-index: 80;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    width: auto;
    height: auto;
    background: #ffffff;
    border: 1px solid #f2f4f7;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
  }
  .modal {
    padding: 20px;
  }
  .countries-modal {
    left: auto;
    padding: 10px;
  }
  nav {
    z-index: 7;
  }
  .disabled {
    color: #a8adb6 !important;
  }
 
  
  .hide {
    display: none;
  }
  
 
  </style>
  