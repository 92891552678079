<template>
  <div
    v-if="appLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <create-edit-expense class=""></create-edit-expense>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CreateEditExpense from "./CreateEditExpense.vue";

export default defineComponent({
  name: "Expense",
  components: { CreateEditExpense },
  data() {
    return {};
  },
  computed: {
    appLoading() {
      return this.$store.getters["expenseModules/appLoading"];
    },
    currentPage() {
      return this.$store.getters["expenseModules/isCurrentPage"];
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
  },

  methods: {
    getAllExpenses(data) {
      const payload = {
        load_page: data,
        expense_type: 'cash',
        foreign_establishment_id: this.isForeignEstablishmentId,
        month: new Date()?.getMonth() + 1,
        year: new Date()?.getFullYear(),
      };
      this.$store.dispatch("expenseModules/getAllExpenses", payload);
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
    this.getAllExpenses(true);
    this.$store.dispatch("pmLocalModules/getAllCurrencies")
  },
});
</script>
