<template>
  <div
    v-if="apploader"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else class="SPC-MT-400">
    <app-table
      :showCursor="false"
      :showEditPage="false"
      :headers="tableHeaders"
      :items="isForeignEstablishment"
      :showCheck="false"
      :isForeignEstablishment="true"
      @edit-event="showDialog({ $event, data: 'edit' })"
      @delete-event="showDeleteDialog({ $event, data: 'delete' })"
      @approve-event="
        approveForeignEstablishOrDisapproveForeignEstablishment({
          $event,
          data: 'approve',
        })
      "
    >
      <template #title>
        <h3 class="H500">{{ $t("contracts.allForeignEstablishment") }}</h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('contracts.foreignAddNew')"
          @click="showDialog({ data: 'add' })"
          :disabled="shouldDisableInput ? 'disabled' : null"
          v-if="isCurrentTab === 1"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </template>
      <template #footer>
        <div>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </div>
      </template>
    </app-table>
  </div>
  <!--  dialog for adding and deleting config types-->
  <app-dialog
    ref="dialog"
    :title="combineTranslation($t(title), $t('contracts.foreignEstablishment'))"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #content>
      <app-select-field
        v-model="country"
        :label="combineTranslation($t('contracts.country'), '*')"
        placeholder="Select country"
        :options="countryOptions"
        data-cy="country"
        :isTranslation="true"
      ></app-select-field>
      <app-select-field-object
        v-model="office"
        :label="combineTranslation($t('projects.projectOffice'), '*')"
        placeholder="Select foreign office"
        :options="officeOptions"
        data-cy="foreign_office"
        :isTranslation="false"
      ></app-select-field-object>
      <!-- <app-select-field-object
        v-model="expert"
        :label="combineTranslation($t('projects.personOnSite'), '*')"
        placeholder="Select expert"
        :options="expertOptions"
        data-cy="experts"
        :isTranslation="false"
      ></app-select-field-object> -->
      <!-- {{ expert }} -->
      <multi-select
        data-cy="experts"
        :label="combineTranslation($t('projects.personOnSite'), '*')"
        :options="expertOptions || []"
        v-model="expert"
        :labelPlaceholder="combineTranslation($t('contracts.selectExpert'))"
        @selected-options="selectedOption({ $event, data: 'expert' })"
        class="w-inherit"
        :showCheckbox="true"
      >
      </multi-select>
      <app-toggle-button
        v-model="doubleTaxAgreement"
        class="justify-between my-4"
        :label="combineTranslation($t('projects.doubleTaxAgreement'))"
      ></app-toggle-button>
      <app-toggle-button
        v-model="conditional"
        class="justify-between my-4"
        :label="combineTranslation($t('projects.projectConditional'))"
      ></app-toggle-button>
      <app-toggle-button
        v-model="taxExemption"
        class="justify-between my-4"
        :label="combineTranslation($t('projects.taxExemption'))"
      ></app-toggle-button>
      <app-toggle-button
        v-model="pmLocal"
        class="justify-between my-4"
        :label="combineTranslation($t('projects.pmLocal'))"
      ></app-toggle-button>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <app-primary-button
            :buttonLabel="
              isEdit ? $t('projectBudget.saveChanges') : $t('generalConfig.add')
            "
            @click-event="
              isEdit ? editForeignEstablishment() : addForeignEstablishment()
            "
            :disabled="!country || !office || !expert"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <!-- dialog for deleting config types -->
  <app-dialog
    ref="isDeleteDialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="5" class="">
          <app-secondary-button
            :buttonLabel="$t('generalConfig.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="7" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteForeignEstablishment()"
          >
            {{ $t("accessrole.delete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(alertTitle?.getTranslationKey())"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import {
  getCountryWithForeignOffice,
  getProjectForeignEstablishmentEndpoint,
  getSingleDoubleTaxation,
  getAllSignedContractExpert,
  createForeignEstablishment,
  updateForeignEstablishment,
  approveEstablishment,
} from "@/services/endpoints";
import { getTransString } from "@/services/helpers";
import countries from "@/services/country_api";

export default defineComponent({
  name: "ProjectForeignEstablishment",
  components: { Paginator },
  props: {},
  data() {
    return {
      tableHeaders: [
        "No.",
        "Country",
        "Project Office",
        "Person on Site",
        "PM - Local",
        "Double Tax Agreement",
        "Approval Status",
      ],
      apploader: true,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      buttonLabel: "Add",
      label: "Title",
      currentPublicHoliday: {},
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      country: "",
      office: "",
      expert: [],
      doubleTaxAgreement: false,
      conditional: false,
      taxExemption: false,
      pmLocal: false,
      countryOptions: null,
      expertOptions: null,
      officeOptions: null,
      countryValue: "",
      fetchCountries: {},
      currentPages: 1,
      isEditId: null,
      expertArray: [],
      isExpert: {},
    };
  },
  computed: {
    isForeignEstablishment() {
      return this.$store.getters["projectModules/isForeignEstablishment"];
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Foreign Establishment"
      );
      return findModules?.length !== 0;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-project" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
  },
  watch: {
    country: function (val) {
      this.countryValue = val;
      const payload = {
        country: this.countryValue,
      };
      this.getCountryForeignOffice();
      Api()
        .post(`${getSingleDoubleTaxation}`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.fetchCountries = response.data.data;
            if (Object.keys(this.fetchCountries || {}).length) {
              const doubleTax = Object.entries(this.fetchCountries)[0];
              this.doubleTaxAgreement = doubleTax[1];
            }
          }
        })
        .catch();
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    combineTranslation(...args) {
      return args.reduce((acc, curr) => acc + " " + curr, "");
    },
    showDialog(data) {
      this.isEdit = false;
      this.jobID = data?.$event?.value;
      this.modelValue = data?.$event?.title;
      if (data.data === "add") {
        this.title = "generalConfig.add";
        this.$refs.dialog.showDialog = true;
      } else if (data.data === "edit") {
        const editData = data?.$event;
        // console.log("response..........", editData)
        this.isEdit = true;
        this.isEditId = editData.id;
        this.title = "projectBudget.edit";
        this.$refs.dialog.showDialog = true;
        this.country = editData.country;
        this.office = this.officeOptions?.find((off) => {
          if (off.id === editData["project office"]) {
            return off.value;
          }
        })?.value;
        // this.expert = this.expertOptions.find((expert) => {
        //   if (expert.id === editData["person on site"]) {
        //     return expert.value;
        //   }
        // })?.value;
        this.expert = editData["person on site"]?.map((expert) => {
          return {
            text: expert.text,
            value: expert.value,
          };
        });
        this.doubleTaxAgreement = editData["double taxation agreement"];
        this.conditional = editData.conditional_AB;
        this.taxExemption = editData.tax_exemption;
        this.pmLocal = editData["pm - local"];
      }
    },
    selectedOption(data) {
      if (data.data === "expert") {
        this.expertArray = data.$event;
      }
    },
    showDeleteDialog(data) {
      if (data.data === "delete") {
        this.isDeleteId = data?.$event.id;
        this.isDelete = true;
        this.cancelLabel = "generalConfig.cancelLabel";
        this.title = "projects.delForeignEst";
        this.subTitle = "projects.deleteEstConfirm";
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      setTimeout(() => {
        this.isDelete = false;
        this.confirmDelete = null;
        this.subTitle = "";
      }, 600);
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      this.addTitle = "";
      this.isEdit = false;
      this.isAdd = false;
      this.isDelete = false;
      this.subTitle = "";
      this.buttonLabel = "generalConfig.add";
      this.label = "generalConfig.title";
      this.cancelLabel = "generalConfig.cancel";
      this.doubleTaxAgreement = false;
      this.conditional = false;
      this.taxExemption = false;
      this.pmLocal = false;
      this.country = "";
      this.office = "";
      this.expert = [];
    },
    getFullName(firstName, lastName) {
      return `${lastName} ${firstName}`;
    },
    getYesNo(value) {
      return value ? "Yes" : "No";
    },
    setResponseData(response) {
      
      const payload = response.data.reduce((prev, curr, index) => {
        return [
          ...prev,
          {
            "no.": index + 1,
            country: curr.country,
            "project office": curr.project_office__place,
            "person on site": curr.icon_personnel_on_site?.map((expert) => {
              return {
                text: this.getFullName(
                  expert.first_name,
                  expert.last_name
                ),
                value: expert.id,
              };
            }),
            "pm - local": this.getYesNo(curr.pm_local),
            "double tax agreement": this.getYesNo(
              curr.double_taxation_agreement
            ),
            "approval status": this.getYesNo(curr.approval_status),
            id: curr.id,
            tax_exemption: curr.tax_exemption,
            conditional_AB: curr.conditional_AB,
          },
        ];
      }, []);

      this.$store.commit(
        "projectModules/SET_PROJECT_FOREIGN_ESTABLISHMENT",
        payload
      );
      this.$store.commit(
        "hrResources/SET_CURRENT_PAGE_TO_STATE",
        response.current_page
      );

      this.$store.commit(
        "hrResources/SET_TOTAL_PAGES_TO_STATE",
        response.total_data
      );
    },

    getAllForeignEstablishment(page) {
      this.apploader = true;
      if (!this.isProjectID) return;
      Api()
        .get(
          `${getProjectForeignEstablishmentEndpoint}${this.isProjectID}/${page}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.setResponseData(response.data);
            this.apploader = false;
            this.$store.commit(
              "projectModules/SET_LOCAL_BUDGET_TAB",
              response.data.show_local_budget
            );
          }
        })
        .catch(() => {
          this.apploader = false;
        });
    },

    getCountryForeignOffice() {
      if (this.countryValue) {
        const payload = {
          country: this.countryValue,
        };
        Api()
          .post(`${getCountryWithForeignOffice}`, payload)
          .then((response) => {
            if (response.data.status === "success") {
              this.officeOptions = response.data?.data.map((expert) => {
                return {
                  id: expert.place,
                  value: Number(expert.id),
                };
              });
            }
          })
          .catch();
      }
    },
    getSignedExpert() {
      const uniqueExpertIds = new Set();

      Api()
        .get(`${getAllSignedContractExpert}${this.isProjectID}`)
        .then((response) => {
          if (response.data.status === "success") {
            const expertData = response.data.data;
            this.expertOptions = expertData.reduce((options, expert) => {
              if (!uniqueExpertIds.has(Number(expert.expert_id))) {
                uniqueExpertIds.add(Number(expert.expert_id));
                options.push({
                  text: expert.expert_or_company_name,
                  value: Number(expert.expert_id),
                });
              }
              return options;
            }, []);
          }
        })
        .catch();
    },
    approveForeignEstablishOrDisapproveForeignEstablishment(data) {
      const approveId = data.$event.id;
      let approval_status =
        data.$event["approval status"] === "No" ? true : false;

      const payload = {
        status: approval_status,
      };
      Api()
        .patch(`${approveEstablishment}${approveId}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.getAllForeignEstablishment(this.currentPage);
          }
        })
        .catch((error) => {
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error.response.data?.detail;
        });
    },
    addForeignEstablishment() {
      const payload = {
        country: this.country,
        project_office_id: this.office,
        icon_personnel_on_site_id: this.expert,
        double_taxation_agreement: this.doubleTaxAgreement,
        conditional_AB: this.conditional,
        tax_exemption: this.taxExemption,
        pm_local: this.pmLocal,
      };
      Api()
        .post(`${createForeignEstablishment}${this.isProjectID}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.hideDialog();
            this.getAllForeignEstablishment(this.currentPage);
          }
        })
        .catch((error) => {
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error.response.data?.detail;
        });
    },
    editForeignEstablishment() {
      Api()
        .patch(`${updateForeignEstablishment}${this.isEditId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.hideDialog();
            this.getAllForeignEstablishment(this.currentPage);
          }
        })
        .catch((error) => {
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error.response.data?.detail;
        });
    },
    deleteForeignEstablishment() {
      Api()
        .delete(`${updateForeignEstablishment}${this.isDeleteId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.hideDeleteDialog();
            this.getAllForeignEstablishment(this.currentPage);
          }
        })
        .catch();
    },
    loadMore() {
      const page = this.currentPages;
      this.getAllForeignEstablishment(page);
    },
  },
  mounted() {
    this.countryOptions = this.getCountryByName();
  },
  created() {
    this.getAllForeignEstablishment(this.currentPage);
    this.getSignedExpert();
  },
});
</script>
