import { ref, computed, onMounted, getCurrentInstance } from "vue";
import moment from "moment";
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import Api from "@/services/api";
import {
  getUnpaginatedData,
  hrLeaveRequest,
  publicHolidays,
} from "@/services/endpoints";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export const setupLogic = () => {
  let alertState = ref(false);
  let alertTitle = ref("");
  let alertType = ref("success");
  const currentMonth = ref(new Date());
  let currentMonthName = ref("");
  const weeksInMonth = ref([]);
  const inputValues = ref([]);
  const { t } = useI18n();
  const inputs = ref([]);
  const inputs1 = ref([]);
  const inputs2 = ref([]);
  const inputs3 = ref([]);
  const inputs4 = ref([]);
  const inputs5 = ref([]);
  const inputs6 = ref([]);
  const showCardIndex = ref(-1);
  const showCardIndex2 = ref(-1);
  const showCardIndex3 = ref(-1);
  const showCardIndex4 = ref(-1);
  const showCardIndex5 = ref(-1);
  const showCardIndex6 = ref(-1);
  const showCardIndex7 = ref(-1);
  const showCardIndex8 = ref(-1);
  const inputValuesByMonth = ref({});
  const inputsByMonth = ref({});
  const inputsByMonth1 = ref({});
  const inputsByMonth2 = ref({});
  const inputsByMonth3 = ref({});
  const inputsByMonth4 = ref({});
  const inputsByMonth5 = ref({});
  const inputsByMonth6 = ref({});
  let annualLeaveDays = ref(0);
  let specialLeaveDays = ref(0);
  let sickLeaveDays = ref(0);
  let sickHoLeaveDays = ref(0);
  let childsickLeaveDays = ref(0);
  let kCareLeaveDays = ref(0);
  let unpaidLeaveDays = ref(0);
  let unexcusedLeaveDays = ref(0);
  const statuses = ref([]);
  const statuses1 = ref([]);
  const statuses2 = ref([]);
  const statuses3 = ref([]);
  const statuses4 = ref([]);
  const statuses5 = ref([]);
  const statuses6 = ref([]);
  const statuses7 = ref([]);
  let leave_stats = ref(undefined);
  let start_date1,
    end_date1,
    description1,
    start_date2,
    end_date2,
    description2;
  let start_date3,
    end_date3,
    description3,
    start_date4,
    end_date4,
    description4;
  let start_date5,
    end_date5,
    description5,
    start_date6,
    end_date6,
    description6;
  let start_date7,
    end_date7,
    description7,
    start_date8,
    end_date8,
    description8;

  const cardValues = [
    { value: "0.5", label: t("calender.firsthalf") },
    { value: "0.5", label: t("calender.secondhalf") },
    { value: "1", label: t("calender.fullday") },
    { value: "", label: t("calender.remove") },
  ];
  const currentYearAndMonth = computed(() =>
    format(currentMonth.value, "yyyy")
  );
  const currentYearAndMonth1 = computed(() =>
    format(currentMonth.value, "MMMM yyyy")
  );

  const store = useStore();
  const isUserKey = computed(() => {
    return store.getters["hrResources/isUserKey"];
  });
  const isName = computed(() => {
    return store.getters["hrResources/isEmployeeName"];
  });

  const getStatusColor = (index) => {
    const status = statuses.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };

  const getStatusColor1 = (index) => {
    const status = statuses1.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor2 = (index) => {
    const status = statuses2.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor3 = (index) => {
    const status = statuses3.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor4 = (index) => {
    const status = statuses4.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor5 = (index) => {
    const status = statuses5.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor6 = (index) => {
    const status = statuses6.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const getStatusColor7 = (index) => {
    const status = statuses7.value[index];

    if (status === "Pending") {
      return "pending-class"; // CSS class for 'Pending'
    } else if (status === "Approved") {
      return "approved-class"; // CSS class for 'Approved'
    } else if (status === "Declined") {
      return "rejected-class"; // CSS class for 'Approved'
    } else if (status === "Draft") {
      return "draft-class";
    } else {
      return ""; // No additional class for other statuses
    }
  };
  const nextMonth = () => {
    getAllLeaves();
    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() + 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    updateCalendar();
  };

  const previousMonth = () => {
    getAllLeaves();
    currentMonth.value = new Date(
      currentMonth.value.setMonth(currentMonth.value.getMonth() - 1)
    );
    const currentMonthIndex = currentMonth.value.getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
    updateCalendar();
  };
  // Add the following function to your setup()

  const updateCalendar = () => {
    const start = startOfMonth(currentMonth.value);
    const end = endOfMonth(currentMonth.value);
    const dates = eachDayOfInterval({ start, end }).map((date) => {
      return {
        date: format(date, "d"),
        dayOfWeek: format(date, "E"),
      };
    });

    const calendarWeeks = [];
    while (dates.length) {
      const week = dates.splice(0, 7);
      calendarWeeks.push(week);
    }

    weeksInMonth.value = calendarWeeks;

    // Retrieve input values for the current month
    inputValues.value =
      inputValuesByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs.value =
      inputsByMonth.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs1.value =
      inputsByMonth1.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs2.value =
      inputsByMonth2.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs3.value =
      inputsByMonth3.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs4.value =
      inputsByMonth4.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs5.value =
      inputsByMonth5.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
    inputs6.value =
      inputsByMonth6.value[format(currentMonth.value, "yyyy-MM")] ||
      new Array(weeksInMonth.value.flat().length).fill("");
  };

  updateCalendar();

  const days = computed(() => {
    const daysWithValues = [];
    const firstWeek = weeksInMonth.value[0];

    for (let i = 0; i < firstWeek.length; i++) {
      if (firstWeek[i].date) {
        const day = firstWeek[i].dayOfWeek;
        daysWithValues.push(day.substring(0, 2));
      }
    }

    return daysWithValues;
  });

  const weeks = computed(() => {
    const filteredWeeks = weeksInMonth.value.map((week) => {
      return week.filter((day) => day.date !== "");
    });

    return filteredWeeks;
  });

  const getIndex = (weekIndex, dateIndex) => {
    const totalDays = weeksInMonth.value.flat().length;
    return weekIndex * 7 + dateIndex >= totalDays
      ? totalDays - 1
      : weekIndex * 7 + dateIndex;
  };
  const bindInputWithDate = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputValues.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };

  const bindInputWithDate2 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate3 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs1.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate4 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs2.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate5 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs3.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate6 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs4.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate7 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs5.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const bindInputWithDate8 = (weekIndex, dateIndex) => {
    const index = getIndex(weekIndex, dateIndex);
    const selectedDate = weeks.value[weekIndex][dateIndex].date;
    const inputValue = inputs6.value[index];
    const hours = parseFloat(inputValue) || 0;
    const date = selectedDate
      ? format(currentMonth.value, "yyyy-MM-") + selectedDate
      : "";
    const time = hours > 0 ? `${hours}` : "";

    return { date, time };
  };
  const isDatePast = (index) => {
    const currentDate = new Date();
    const selectedDate = new Date(currentMonth.value);
    selectedDate.setDate(index);
    const isSameDay = selectedDate.getDate() === currentDate.getDate();
    const isPastDate = selectedDate.getTime() < currentDate.getTime();
    return !isSameDay && isPastDate;
  };

  const pastDateAnyYear = (index) => {
    const currentDate = new Date();
    const selectedDate = new Date(currentMonth.value);
    selectedDate.setDate(index - -1);
    const isPastDate = selectedDate.getTime() < currentDate.getTime();
    return isPastDate;
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const getValidDays = (week) => {
    const validDays = [];
    const dateRow = week.filter((date) => date.date);
    // const totalDates = dateRow.length;

    for (let i = 0; i < week.length; i++) {
      if (dateRow[i]) {
        validDays.push(week[i].dayOfWeek.substring(0, 2));
      } else {
        validDays.push("");
      }
    }

    return validDays;
  };
  const selectedDates1 = []; // Array to store selected dates for input row 1
  const selectedDates2 = []; // Array to store selected dates for input row 2
  const selectedDates3 = []; // Array to store selected dates for input row 3
  const selectedDates4 = []; // Array to store selected dates for input row 4
  const selectedDates5 = []; // Array to store selected dates for input row 5
  const selectedDates6 = []; // Array to store selected dates for input row 6
  const selectedDates7 = []; // Array to store selected dates for input row 7
  const selectedDates8 = []; // Array to store selected dates for input row 8

  // Card methods for the first input row
  const totalHours1 = ref(0);
  const totalHours2 = ref(0);
  const totalHours3 = ref(0);
  const totalHours4 = ref(0);
  const totalHours5 = ref(0);
  const totalHours6 = ref(0);
  const totalHours7 = ref(0);
  const totalHours8 = ref(0);
  const showCard = (weekIndex, dateIndex) => {
    showCardIndex.value = getIndex(weekIndex, dateIndex);
    description1 = "Annual Leave";
    closeCard2();
    closeCard3();
    closeCard4();
    closeCard5();
    closeCard6();
    closeCard7();
    closeCard8();
  };
  const closeCard1 = () => {
    showCardIndex.value = -1;
  };
  const selectCardValue = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);

    if (
      Number(value ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;

    inputValues.value[index] = value;
    showCardIndex.value = -1;
    const { date, time } = bindInputWithDate(weekIndex, dateIndex);
    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;

    const dateTime = `${date} ${time}`;
    selectedDates1.push(dateTime); // Push the combined date and time to the array for input row 2
    totalHours1.value = inputValues.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const parsedDates = selectedDates1.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date1 = new Date(Math.min(...parsedDates));
    end_date1 = new Date(Math.max(...parsedDates));
  };

  // Card methods for the second input row
  const showCard2 = (weekIndex, dateIndex) => {
    showCardIndex2.value = getIndex(weekIndex, dateIndex);

    description2 = "Special Leave";
    closeCard1();

    closeCard3();
    closeCard4();
    closeCard5();
    closeCard6();
    closeCard7();
    closeCard8();
  };
  const closeCard2 = () => {
    showCardIndex2.value = -1;
  };
  const selectCardValue2 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs.value[index] = value;
    showCardIndex2.value = -1;
    const { date, time } = bindInputWithDate2(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates2.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours2.value = inputs.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses1.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates2.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date2 = new Date(Math.min(...parsedDates));
    end_date2 = new Date(Math.max(...parsedDates));
  };
  const showCard3 = (weekIndex, dateIndex) => {
    showCardIndex3.value = getIndex(weekIndex, dateIndex);

    description3 = "Sick Leave";
    closeCard1();
    closeCard2();

    closeCard4();
    closeCard5();
    closeCard6();
    closeCard7();
    closeCard8();
  };
  const closeCard3 = () => {
    showCardIndex3.value = -1;
  };
  const selectCardValue3 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs1.value[index] = value;
    showCardIndex3.value = -1;
    const { date, time } = bindInputWithDate3(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates3.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours3.value = inputs1.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses2.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates3.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date3 = new Date(Math.min(...parsedDates));
    end_date3 = new Date(Math.max(...parsedDates));
  };

  const showCard4 = (weekIndex, dateIndex) => {
    showCardIndex4.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description4 = "Sick Leave HO";
    closeCard1();
    closeCard2();
    closeCard3();

    closeCard5();
    closeCard6();
    closeCard7();
    closeCard8();
  };
  const closeCard4 = () => {
    showCardIndex4.value = -1;
  };
  const selectCardValue4 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs2.value[index] = value;
    showCardIndex4.value = -1;
    const { date, time } = bindInputWithDate4(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates4.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours4.value = inputs2.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses3.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates4.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date4 = new Date(Math.min(...parsedDates));
    end_date4 = new Date(Math.max(...parsedDates));
  };

  const showCard5 = (weekIndex, dateIndex) => {
    showCardIndex5.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description5 = "Child Sick Leave";
    closeCard1();
    closeCard2();
    closeCard3();
    closeCard4();

    closeCard6();
    closeCard7();
    closeCard8();
  };
  const closeCard5 = () => {
    showCardIndex5.value = -1;
  };
  const selectCardValue5 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs3.value[index] = value;
    showCardIndex5.value = -1;
    const { date, time } = bindInputWithDate5(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates5.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours5.value = inputs3.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses4.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates5.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date5 = new Date(Math.min(...parsedDates));
    end_date5 = new Date(Math.max(...parsedDates));
  };

  const showCard6 = (weekIndex, dateIndex) => {
    showCardIndex6.value = getIndex(weekIndex, dateIndex);
    description6 = "Child Care Leave";
    closeCard1();
    closeCard2();
    closeCard3();
    closeCard4();
    closeCard5();

    closeCard7();
    closeCard8();
  };
  const closeCard6 = () => {
    showCardIndex6.value = -1;
  };
  const selectCardValue6 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs4.value[index] = value;
    showCardIndex6.value = -1;
    const { date, time } = bindInputWithDate6(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates6.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours6.value = inputs4.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses5.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates6.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date6 = new Date(Math.min(...parsedDates));
    end_date6 = new Date(Math.max(...parsedDates));
  };
  const showCard7 = (weekIndex, dateIndex) => {
    showCardIndex7.value = getIndex(weekIndex, dateIndex);
    // const { date, time} = bindInputWithDate2(weekIndex, dateIndex, 2);
    description7 = "Unpaid Leave";
    closeCard1();
    closeCard2();
    closeCard3();
    closeCard4();
    closeCard5();
    closeCard6();

    closeCard8();
  };
  const closeCard7 = () => {
    showCardIndex7.value = -1;
  };
  const selectCardValue7 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs6.value[index] ?? 0) >
      1
    )
      return null;
    inputs5.value[index] = value;
    showCardIndex7.value = -1;
    const { date, time } = bindInputWithDate7(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates7.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours7.value = inputs5.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses6.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates7.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date7 = new Date(Math.min(...parsedDates));
    end_date7 = new Date(Math.max(...parsedDates));
  };
  const showCard8 = (weekIndex, dateIndex) => {
    showCardIndex8.value = getIndex(weekIndex, dateIndex);

    description8 = "Unexcused Leave";
    closeCard1();
    closeCard2();
    closeCard3();
    closeCard4();
    closeCard5();
    closeCard6();
    closeCard7();
  };
  const closeCard8 = () => {
    showCardIndex8.value = -1;
  };
  const selectCardValue8 = (weekIndex, dateIndex, value) => {
    const index = getIndex(weekIndex, dateIndex);
    if (
      Number(value ?? 0) +
        Number(inputValues.value[index] ?? 0) +
        Number(inputs.value[index] ?? 0) +
        Number(inputs1.value[index] ?? 0) +
        Number(inputs2.value[index] ?? 0) +
        Number(inputs3.value[index] ?? 0) +
        Number(inputs4.value[index] ?? 0) +
        Number(inputs5.value[index] ?? 0) >
      1
    )
      return null;
    inputs6.value[index] = value;
    showCardIndex8.value = -1;
    const { date, time } = bindInputWithDate8(weekIndex, dateIndex);

    const dateTime = `${date} ${time}`; // Combine date and time
    selectedDates8.push(dateTime); // Push the combined date and time to the array for input row 2

    // Calculate total hours for input row 2
    totalHours8.value = inputs6.value.reduce((total, value, index) => {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && !statuses7.value[index]) {
        return total + parsedValue;
      } else {
        return total;
      }
    }, 0);

    const monthKey = format(currentMonth.value, "yyyy-MM");
    inputValuesByMonth.value[monthKey] = inputValues.value;
    inputsByMonth.value[monthKey] = inputs.value;
    inputsByMonth1.value[monthKey] = inputs1.value;
    inputsByMonth2.value[monthKey] = inputs2.value;
    inputsByMonth3.value[monthKey] = inputs3.value;
    inputsByMonth4.value[monthKey] = inputs4.value;
    inputsByMonth5.value[monthKey] = inputs5.value;
    inputsByMonth6.value[monthKey] = inputs6.value;
    // Find the start and future date for input row 2
    const parsedDates = selectedDates8.map(
      (dateTime) => new Date(dateTime.split(" ")[0])
    );
    start_date8 = new Date(Math.min(...parsedDates));
    end_date8 = new Date(Math.max(...parsedDates));
  };
  const rejectLeave = () => {
    description8 = false;
  };
  const approveLeave = () => {
    description7 = false;
  };

  const addLeave = () => {
    const leaveDays1 = selectedDates1.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });

    const leaveDays2 = selectedDates2.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays3 = selectedDates3.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays4 = selectedDates4.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays5 = selectedDates5.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays6 = selectedDates6.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays7 = selectedDates7.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const leaveDays8 = selectedDates8.map((dateTime) => {
      const date = dateTime.split(" ")[0];
      const time = dateTime.split(" ")[1];

      return {
        leave_day: date,
        days: time,
      };
    });
    const payload = {
      leaves: [
        {
          leave_type: description1,
          start_date: formatDate(start_date1),
          end_date: formatDate(end_date1),
          total_days: totalHours1.value,
          leave_days: leaveDays1 || null,
        },
        {
          leave_type: description2,
          start_date: formatDate(start_date2),
          end_date: formatDate(end_date2),
          total_days: totalHours2.value,
          leave_days: leaveDays2 || null,
        },
        {
          leave_type: description3,
          start_date: formatDate(start_date3),
          end_date: formatDate(end_date3),
          total_days: totalHours3.value,
          leave_days: leaveDays3 || null,
        },
        {
          leave_type: description4,
          start_date: formatDate(start_date4),
          end_date: formatDate(end_date4),
          total_days: totalHours4.value,
          leave_days: leaveDays4 || null,
        },
        {
          leave_type: description5,
          start_date: formatDate(start_date5),
          end_date: formatDate(end_date5),
          total_days: totalHours5.value,
          leave_days: leaveDays5 || null,
        },
        {
          leave_type: description6,
          start_date: formatDate(start_date6),
          end_date: formatDate(end_date6),
          total_days: totalHours6.value,
          leave_days: leaveDays6 || null,
        },
        {
          leave_type: description7,
          start_date: formatDate(start_date7),
          end_date: formatDate(end_date7),
          total_days: totalHours7.value,
          leave_days: leaveDays7 || null,
        },
        {
          leave_type: description8,
          start_date: formatDate(start_date8),
          end_date: formatDate(end_date8),
          total_days: totalHours8.value,
          leave_days: leaveDays8 || null,
        },
      ],
      user_key: isUserKey.value,
    };
    payload.leaves = payload.leaves.filter(
      (leaf) =>
        leaf.leave_type &&
        leaf.start_date !== "NaN-aN-aN" &&
        leaf.end_date !== "NaN-aN-aN" &&
        leaf.total_days !== undefined &&
        leaf.leave_days !== undefined
    );
    function filterLeaveDays(payload) {
      const { leaves } = payload;

      for (let i = 0; i < leaves.length; i++) {
        const leave = leaves[i];
        const { leave_days } = leave;

        if (leave_days && leave_days.length > 0) {
          const leaveDaysMap = new Map();

          for (let j = 0; j < leave_days.length; j++) {
            const { leave_day } = leave_days[j];
            leaveDaysMap.set(leave_day, j);
          }

          const filteredLeaveDays = [];

          for (const index of leaveDaysMap.values()) {
            const leave_day = leave_days[index];
            filteredLeaveDays.unshift(leave_day);
          }

          leave.leave_days = filteredLeaveDays;
        }
      }

      return payload;
    }

    const filteredPayload = filterLeaveDays(payload);
    filteredPayload.leaves.forEach((leave) => {
      leave.leave_days = leave.leave_days.filter(
        (day) => day.days !== "" && leave.leave_days.length > 0
      );
    });
    function processFilteredPayload(filteredPayload) {
      // We're going to use a filter on the leaves array
      filteredPayload.leaves = filteredPayload.leaves.filter((leave) => {
        // Filter out leave_days with empty days
        leave.leave_days = leave.leave_days.filter((day) => day.days !== "");
        // If after filtering, there are no leave_days, then return false so this leave gets removed
        if (leave.leave_days.length === 0) return false;
        const leaveDays = leave.leave_days.map(
          (day) => new Date(day.leave_day)
        );
        const minDate = new Date(Math.min(...leaveDays));
        const maxDate = new Date(Math.max(...leaveDays));
        leave.start_date = minDate.toISOString().split("T")[0];
        leave.end_date = maxDate.toISOString().split("T")[0];
        return true; // If we reach here, keep the leave in the array
      });
      return filteredPayload;
    }
    const processedPayload = processFilteredPayload(filteredPayload);
    Api()
      .post(`${hrLeaveRequest}`, processedPayload)
      .then((response) => {
        if (response.data.status === "success") {
          alertType.value = "success";
          emitClose(response.data);
          alertTitle.value = response.data.detail;
          alertState.value = !alertState.value;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
        ) {
          alertType.value = "error";
          alertTitle.value = error.response.data.detail;
          alertState.value = !alertState.value;
        }
      });
  };
  // get leaves
  // Assuming you have fetched the data from the API and stored it in a variable called 'fetchedData'
  const getAllLeaves = () => {
    const userKey = isUserKey.value;
    const payload = {
      user_key: userKey,
    };

    Api()
      .post(`${getUnpaginatedData}`, payload)
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.data[1];
          leave_stats.value = response.data.data[0].leave_stat;

          const leaveDaysArray = data.leaves.flatMap((entry) =>
            entry.leave_days.map((leaveDay) => ({
              ...leaveDay,
              leave_type: entry.leave_type,
              status: entry.status,
            }))
          );

          processLeaveDaysArray(leaveDaysArray);
        }
      });
  };
  let isStatus = ref("");
  const LEAVE_TYPES = {
    ANNUAL: "Annual Leave",
    SPECIAL: "Special Leave",
    SICKLEAVE: "Sick Leave",
    SICKLEAVEHO: "Sick Leave HO",
    CHILDSICKLEAVE: "Child Sick Leave",
    KCARELEAVE: "Child Care Leave",
    UNPAIDLEAVE: "Unpaid Leave",
    UNEXECUSEDLEAVE: "Unexcused Leave",
  };
  const processLeaveDaysArray = (leaveDays) => {
    statuses.value = Array(inputValues.value.length).fill("");
    statuses1.value = Array(inputs.value.length).fill("");
    statuses2.value = Array(inputs1.value.length).fill("");
    statuses3.value = Array(inputs2.value.length).fill("");
    statuses4.value = Array(inputs3.value.length).fill("");
    statuses5.value = Array(inputs4.value.length).fill("");
    statuses6.value = Array(inputs5.value.length).fill("");
    statuses7.value = Array(inputs6.value.length).fill("");
    leaveDays.forEach((leaveDay) => {
      const date = moment(leaveDay.leave_day);
      const weekIndex = findWeekIndex(date);

      if (weekIndex !== -1) {
        const dateIndex = findDateIndex(weekIndex, date);

        if (dateIndex !== -1) {
          const index = getIndex(weekIndex, dateIndex);
          assignLeaveValue(index, leaveDay);
        }
      }
    });

    computeLeaveTotals();
  };

  const findWeekIndex = (date) => {
    return weeks.value.findIndex((week) =>
      week.some(
        (d) =>
          d.date &&
          moment([
            currentMonth.value.getFullYear(),
            currentMonth.value.getMonth(),
            d.date,
          ]).isSame(date, "day")
      )
    );
  };

  const findDateIndex = (weekIndex, date) => {
    return weeks.value[weekIndex].findIndex(
      (d) =>
        d.date &&
        moment([
          currentMonth.value.getFullYear(),
          currentMonth.value.getMonth(),
          d.date,
        ]).isSame(date, "day")
    );
  };

  const assignLeaveValue = (index, leaveDay) => {
    switch (leaveDay.leave_type) {
      case LEAVE_TYPES.ANNUAL:
        inputValues.value[index] = leaveDay.days;
        statuses.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.SPECIAL:
        inputs.value[index] = leaveDay.days;
        statuses1.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.SICKLEAVE:
        inputs1.value[index] = leaveDay.days;
        statuses2.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.SICKLEAVEHO:
        inputs2.value[index] = leaveDay.days;
        statuses3.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.CHILDSICKLEAVE:
        inputs3.value[index] = leaveDay.days;
        statuses4.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.KCARELEAVE:
        inputs4.value[index] = leaveDay.days;
        statuses5.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.UNPAIDLEAVE:
        inputs5.value[index] = leaveDay.days;
        statuses6.value[index] = leaveDay.status;
        break;
      case LEAVE_TYPES.UNEXECUSEDLEAVE:
        inputs6.value[index] = leaveDay.days;
        statuses7.value[index] = leaveDay.status;
        break;
      default:
        // console.error('Unknown leave type:', leaveDay.leave_type);
        break;
    }
  };

  const computeLeaveTotals = () => {
    annualLeaveDays.value = sumDays(inputValues.value);
    specialLeaveDays.value = sumDays(inputs.value);
    sickLeaveDays.value = sumDays(inputs1.value);
    sickHoLeaveDays.value = sumDays(inputs2.value);
    childsickLeaveDays.value = sumDays(inputs3.value);
    kCareLeaveDays.value = sumDays(inputs4.value);
    unpaidLeaveDays.value = sumDays(inputs5.value);
    unexcusedLeaveDays.value = sumDays(inputs6.value);
  };

  const sumDays = (values) => {
    return values.reduce((total, value) => {
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) ? total + parsedValue : total;
    }, 0);
  };
  onMounted(() => {
    getAllPublicHolidays();
    getAllLeaves();
    const currentMonthIndex = new Date().getMonth();
    currentMonthName.value = t(`calender.months.${currentMonthIndex}`);
  });
  const { emit } = getCurrentInstance();

  const emitClose = (data) => {
    emit("close-event", data);
  };
  // const formatLeaveDays = (leaveDays) => {
  //   if (leaveDays === 0) {
  //     return "0";
  //   }

  //   const isNegative = leaveDays < 0;
  //   const absoluteDays = Math.abs(Math.floor(leaveDays));
  //   const absoluteHours = Math.floor((Math.abs(leaveDays) % 1) * 8);
  //   // const absoluteHours = leaveDays;

  //   let formattedString = "";

  //   if (isNegative) {
  //     formattedString += "-";
  //   }

  //   if (absoluteDays > 0) {
  //     formattedString += `${absoluteDays} ${
  //       absoluteDays === 1 ? t("calender.day") : t("calender.days")
  //     }`;
  //   }

  //   if (absoluteHours > 0) {
  //     if (formattedString !== "") {
  //       formattedString += " ";
  //     }
  //     formattedString += `${absoluteHours} ${
  //       absoluteHours === 1 ? t("calender.hour") : t("calender.hours")
  //     }`;
  //   }

  //   return formattedString;
  // };
  const formatLeaveDays = (leaveDays) => {
    if (leaveDays === undefined) {
      return "";
    }
  
    // Calculate the absolute decimal value
    const absoluteValue = leaveDays;
  
    // Determine the sign
    const sign = leaveDays < 0 ? "-" : "";
  
    // Create the formatted string
    const formattedString = `${sign}${absoluteValue} ${
      absoluteValue === "1.0" ? t("calender.day") : t("calender.days")
    }`;
  
    return formattedString;
  };
  

  const closeAll = () => {
    closeCard1();
    closeCard2();
    closeCard3();
    closeCard4();
    closeCard5();
    closeCard6();
    closeCard7();
    closeCard8();
  };

  // get holidays
  const holidays = ref([]);

  const getAllPublicHolidays = async () => {
    try {
      const response = await Api().get(`${publicHolidays}`);

      if (response.data && response.data.status === "success") {
        holidays.value = response.data.data;
      }
    } catch (error) {
      // Handle the error as needed
    }
  };

  function getMonthValue(currentMonth) {
    const date = new Date(currentMonth);
    const monthValue = date.getMonth() + 1; // Adding 1 to get month value from 1 to 12
    return monthValue;
  }
  function isHoliday(day) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth1 = getMonthValue(currentMonth.value); // Month starts from 0, so adding 1

    const formattedDate = `${currentYear}-${currentMonth1
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

    for (const holiday of holidays.value) {
      if (
        formattedDate >= holiday.start_date &&
        formattedDate <= holiday.end_date
      ) {
        return true;
      }
    }

    return false;
  }
  function getPreviousYear() {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return previousYear;
  }

  const previousYear = getPreviousYear();
  function formatDatecaryOver(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return date.toLocaleDateString(undefined, options);
    } else {
      return "N/A";
    }
  }
  const formattedMonth = ref("");
  const currentYear = ref("");
  function checkActiveMonth() {
    const currentDate = new Date();
    const currentMonthAndYearWithday = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    return currentMonthAndYearWithday === currentYearAndMonth1.value;
  }
  return {
    checkActiveMonth,
    formattedMonth,
    currentYear,
    formatDatecaryOver,
    previousYear,
    getPreviousYear,
    getAllPublicHolidays,
    getMonthValue,
    isHoliday,
    holidays,
    formatLeaveDays,
    emitClose,
    addLeave,
    days,
    isName,
    weeks,
    nextMonth,
    previousMonth,
    currentYearAndMonth,
    format,
    inputValues,
    inputs,
    inputs1,
    inputs2,
    inputs3,
    inputs4,
    inputs5,
    inputs6,
    getIndex,
    showCard,
    selectCardValue,
    showCard2,
    showCard7,
    showCard3,
    showCard4,
    showCard5,
    showCard6,
    showCard8,
    selectCardValue2,
    selectCardValue3,
    selectCardValue4,
    selectCardValue5,
    selectCardValue6,
    selectCardValue7,
    selectCardValue8,
    showCardIndex,
    showCardIndex2,
    showCardIndex3,
    showCardIndex4,
    showCardIndex5,
    showCardIndex6,
    showCardIndex7,
    showCardIndex8,
    inputValuesByMonth,
    inputsByMonth,
    inputsByMonth1,
    inputsByMonth2,
    inputsByMonth3,
    inputsByMonth4,
    inputsByMonth5,
    inputsByMonth6,
    cardValues,
    totalHours1,
    totalHours2,
    totalHours3,
    totalHours4,
    totalHours5,
    totalHours6,
    totalHours7,
    totalHours8,
    alertState,
    alertTitle,
    alertType,
    getValidDays,
    pastDateAnyYear,
    approveLeave,
    rejectLeave,
    isUserKey,
    getAllLeaves,
    isStatus,
    isDatePast,
    leave_stats,
    annualLeaveDays,
    specialLeaveDays,
    sickHoLeaveDays,
    sickLeaveDays,
    childsickLeaveDays,
    kCareLeaveDays,
    unexcusedLeaveDays,
    unpaidLeaveDays,
    statuses,
    statuses1,
    statuses2,
    statuses3,
    statuses4,
    statuses5,
    statuses6,
    statuses7,
    getStatusColor,
    getStatusColor1,
    getStatusColor2,
    getStatusColor3,
    getStatusColor4,
    getStatusColor5,
    getStatusColor6,
    getStatusColor7,
    closeCard1,
    closeCard2,
    closeCard3,
    closeCard4,
    closeCard5,
    closeCard6,
    closeCard7,
    closeCard8,
    closeAll,
    currentMonthName,
  };
};
