<template>
  <div class="flex flex-col justify-center items-center h-[50vh]">
    <div
      class="flex items-center justify-center w-12 h-12 bg-[#E8D4D3] rounded-full"
    >
      <svg
        width="26"
        height="26"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7757_35993)">
          <path
            d="M10 13.3307V9.9974M10 6.66406H10.0084M18.3334 9.9974C18.3334 14.5998 14.6024 18.3307 10 18.3307C5.39765 18.3307 1.66669 14.5998 1.66669 9.9974C1.66669 5.39502 5.39765 1.66406 10 1.66406C14.6024 1.66406 18.3334 5.39502 18.3334 9.9974Z"
            stroke="#AE6A65"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_7757_35993">
            <rect width="26" height="26" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="py-2">
      <h3 class="H500">{{ $t("homeview.accessDenied") }}</h3>
    </div>
    <div class="py-2">
      <p class="P300">{{ $t("homeview.routeNotallowMessageSorry") }}</p>
      <p class="P300">{{ $t("homeview.routeNotallowMessageItSupport") }}</p>
    </div>
    <div class="py-2">
      <app-primary-button
        @click="goToPrevious"
        :buttonLabel="$t('homeview.takeMeBack')"
      ></app-primary-button>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Dennied",
  methods: {
    // functions
    goToPrevious(){
      if (typeof this.clickEvent === 'function') {
        this.clickEvent();
      } else {
        this.$router.go(-1);
      }
    }
  },
});
</script>
