<template>
  <div class="mt-8">
    <app-table
      :headers="headers"
      :items="isCountries"
      :showCheck="false"
      :isDoubleTaxation="true"
      @table-toggle="changeCountryStatus"
      :changeCountry="changeCountry"
      
    >
      <template #title>
        <h3 class="H500">{{ $t("contracts.allDoubleTaxation") }}</h3>
      </template>
      <template #actions>
        <app-primary-button
          :buttonLabel="$t('projects.save')"
          data-cy="btn_doubleTaxation"
          @click="saveDoubleTaxationStatus()"
          :disabled="shouldDisableInput ? 'disabled' : null"
        >
        </app-primary-button>
      </template>
    </app-table>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import countries from "@/services/country_api";
import API from "@/services/api";
import { addDoubleTaxation, getAllDoubleTaxation } from "@/services/endpoints";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "DoubleTaxation",
  components: {},
  props: {},
  data() {
    return {
      toggleValue: false,
      countries: [],
      changeCountry: {},
      alertType: "success",
      alertState: false,
      alertTitle: "",
      fetchCountries: [],
    };
  },
  watch: {
    changeCountry: {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },
  },
  computed: {
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    shouldDisableInput() {
      return (
        this.currentPath === "/edit-project" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    headers() {
      return ["Country", "Double Taxation Status"];
    },
    isCountries() {
      return countries.map((country) => {
        return {
          country: country.name,
          "double taxation status": "",
        };
      });
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    changeCountryStatus(countryData) {
      this.changeCountry[countryData.country] = countryData.$event;
    },
    saveDoubleTaxationStatus() {
      API()
        .post(`${addDoubleTaxation}`, this.changeCountry)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response.data.detail;
          }
        })
        .catch((error) => {
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error.response.data?.detail;
        });
    },
    getAllDoubleTaxationCountries() {
      API()
        .get(`${getAllDoubleTaxation}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.fetchCountries = response.data?.data;
            if (Object.keys(this.fetchCountries).length) {
              this.changeCountry = this.fetchCountries;
            } else {
              countries.map((country) => {
                this.changeCountry[country.name] = false;
              });
            }
            
          }
        })
        .catch((error) => {});
    },
  },
  created() {},
  mounted() {
    this.getAllDoubleTaxationCountries();
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 8);
  },
});
</script>
