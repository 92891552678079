<template>
  <v-row :class="{ 'pointer-events-none': disabled }">
    <v-col cols="12">
      <div class="py-2 label">
        <label>{{ label }}</label>
      </div>
      <QuillEditor
        contentType="text"
        v-model:content="editorContent"
        :options="options"
        :class="{'!bg-[#F9FAFB] !text-[#667085] !pointer-events-none' : disabled}"
        :style="editorStyles"
        @input="$emit('text-change', editorContent)"
      />
    </v-col>
  </v-row>
  
</template>
<script>
import { defineComponent } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: "App Text Editor",
  components: {
    QuillEditor,
  },
  props: {
    getText: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Enter a description",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["description"],
  data() {
    return {
      editorContent: this.description,
      options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: this.placeholder,
      },
      editorStyles: {
        minHeight: "130px",
        padding: "10px, 14px",
        fontSize: "16px",
        backgroundColor: "white",
      },
    };
  },
  
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    getEditorData() {
      //
    },
    
  },
});
</script>
<style scoped>
.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
</style>