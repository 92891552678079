<template>
  <div>
    <AddChequeTable></AddChequeTable>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AddChequeTable from "./AddChequeTable.vue";
export default defineComponent({
  name: "AddNewCheque",
  components: {
    AddChequeTable,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 3);
  },
});
</script>

