<template>
  <v-sheet max-width="1125" class="bg-transparent mt-7">
    <!-- project forecast -->
    <v-row>
      <v-col cols="10" class="mt-5 !divide-y">
        <p class="pb-3 font-semibold text-[1.125rem]">EOI</p>
        <div></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="justify-between pr-4 ma-0 d-flex align-center">
        <p class="font-medium text-[1rem]">Is ICON Lead</p>
        <div class="flex single_radio">
          <h3 class="mr-4 font-medium !text-[#344054]">
            {{ $t("projects.no") }}
          </h3>
          <label
            for="lead"
            class="relative inline-flex items-center cursor-pointer"
            :class="{ '!cursor-default': shouldDisableInput }"
          >
            <input
              type="checkbox"
              id="lead"
              class="sr-only peer"
              v-model="projectsForm.is_icon_lead"
              @input="handleEOIdata(projectsForm.is_icon_lead)"
              :disabled="shouldDisableInput"
            />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:bg-white after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#AE6A65]"
            ></div>
          </label>
          <h3 class="ml-4 font-medium !text-[#344054]">
            {{ $t("projects.yes") }}
          </h3>
        </div>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-single-select
          class=""
          v-if="!projectsForm.is_icon_lead"
          id="lead_name"
          :showCheckbox="false"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchLeadName')"
          :options="partnersNameOptions"
          :labelPlaceholder="$t('projects.leadNamePlaceholder')"
          :label="$t('projects.leadName')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.lead_name_id"
        ></app-single-select>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="5" class="pr-4">
        <app-single-select
          class=""
          id="template_for"
          :showCheckbox="false"
          :showSearch="true"
          :searchPlaceholder="$t('projects.searchTemplate')"
          :options="companiesOption"
          :labelPlaceholder="$t('projects.templateForPlaceholder')"
          :label="$t('projects.templateFor')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.template_for"
        ></app-single-select>
      </v-col>
      <v-col cols="5" class="pl-4">
        <multi-select
          id=""
          :showSearch="true"
          :showCheckbox="true"
          :searchPlaceholder="$t('projects.searchPartners')"
          :options="partnersNameOptions"
          :labelPlaceholder="$t('projects.partnerNamePlaceholder')"
          :label="$t('projects.partnerName')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.partner_names"
          @selected-options="selectedOptions({ $event, data: 'partner' })"
        ></multi-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="pr-4">
        <app-date-field
          id=""
          type="date"
          :max="maxStartDate(projectsForm.project_end_date)"
          :label="$t('projects.projectStartDate')"
          v-model="projectsForm.project_start_date"
          @input="handleEOIdata(projectsForm.project_start_date)"
          :disabled="shouldDisableInput"
        >
        </app-date-field>
      </v-col>
      <v-col cols="5" class="pl-4">
        <app-date-field
          id=""
          type="date"
          :min="minEndDate(projectsForm.project_start_date)"
          :label="$t('projects.projectEndDate')"
          v-model="projectsForm.project_end_date"
          @input="handleEOIdata(projectsForm.project_end_date)"
          :disabled="shouldDisableInput"
        >
        </app-date-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="pr-4">
        <app-input-field
          id=""
          :placeholder="$t('projects.projectDurationPlaceholder')"
          :label="$t('projects.projectDuration')"
          v-model.number="projectsForm.project_duration"
          validation="digitOnly"
          :disabled="shouldDisableInput"
        >
        </app-input-field>
      </v-col>
      <v-col cols="5" class="pr-4">
        <!-- <app-multi-select
          id="client_name"
          :showSearch="true"
          :showCheckbox="true"
          :searchPlaceholder="$t('projects.searchClients')"
          :options="clientOptions"
          :labelPlaceholder="$t('projects.clientNamePlaceholder')"
          :label="$t('projects.clientName')"
          :disabled="shouldDisableInput"
          :optionItems="clientNames"
          @input="handleEOIdata(client_names)"
          @selected-options="selectedOptions({ $event, data: 'client' })"
        ></app-multi-select> -->
        <multi-select
          id="client_name"
          :showSearch="true"
          :showCheckbox="true"
          :searchPlaceholder="$t('projects.searchClients')"
          :options="clientOptions"
          :labelPlaceholder="$t('projects.clientNamePlaceholder')"
          :label="$t('projects.clientName')"
          :disabled="shouldDisableInput"
          v-model="projectsForm.client_names"
          @input="handleEOIdata(client_names)"
          @selected-options="selectedOptions({ $event, data: 'client' })"
        ></multi-select>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col
        cols="5"
        class="pr-4"
        v-for="(clientDetails, index) of getClientDetails"
        :key="index"
      >
        <div class="">
          <p class="text-[14px] text-[#344054] font-medium">
            {{ clientDetails?.text }}
          </p>
          <p class="text-[14px] text-[#667085] font-normal">
            {{ clientDetails?.address }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { getSelectOptionList, maximumStartDate, minimumEndDate } from '@/services/helpers'
import { isEqual } from 'lodash';

export default defineComponent({
  props: {
      projectData: {
        type: Object
      }
    },
  data() {
    return {
      clientNames: '',
      selectedTemplate: '',
      selectedPartnerNames: '',
      initialClientNames: '',
      initialSelectedTemplate: '',
      initialSelectedLeadName: '',
      initialSelectedPartnerNames: '',
      projectsForm: {
        is_icon_lead: true,
        lead_name_id: null,
        client_names: [],
        partner_names: [],
        template_for: '',
        project_start_date: null,
        project_end_date: null,
        project_duration: null,
      },
      originalProjectsForm: {
        is_icon_lead: true,
        lead_name_id: null,
        client_names: [],
        partner_names: [],
        template_for: '',
        project_start_date: null,
        project_end_date: null,
        project_duration: null,
      },
      firstRun: true,
    }
  },
  watch: {
    projectData (data) {
      this.populateProjectForm(this.projectsForm, this.projectData)

       if(this.firstRun) {
         this.populateProjectForm(this.originalProjectsForm, data);
          this.firstRun = false
       }

    },
    projectsForm: {
        deep: true,
        handler(value) {

          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
            "BasicInformation": !isEqual(value, this.originalProjectsForm)
          });

        },
    },

  },

  computed: {
    getClientDetails() {
    return this.projectsForm.client_names?.reduce((prev, curr)=>{

      const selectedClient = this.clientOptions?.find(obj => curr?.value=== obj?.value);
      return [...prev, selectedClient]
    }, [])
  },
    companiesOption(){
      const optionList = getSelectOptionList('hrConfigurations/isCompanies')
      return optionList?.map((company)=>{
        return{
          text: company.id,
          value: company.value,
        }
      })
    },
    partnersNameOptions(){
      const optionList = getSelectOptionList('expertModules/isAllPartners')
      return optionList?.map((partner)=>{
        return{
          text: partner.id,
          value: partner.value,
        }
      })
    },
    clientOptions(){
      const optionList = getSelectOptionList('expertModules/isAllPartners')
      return optionList?.map((client)=>{
        return{
          text: client.id,
          value: client.value,
          address: client.address
        }
      })
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
  },
  methods: {
  minEndDate(date){
    return minimumEndDate(date)
  },
  maxStartDate(date){
    return maximumStartDate(date)
  },
    selectedOptions(data) {
      if(data.data==='client'){
        this.projectsForm.client_names = data?.$event
      }else{
        this.projectsForm.partner_names = data?.$event
      }
    },

    handleEOIdata() {
      const payload = {
        is_icon_lead: this.projectsForm.is_icon_lead,
        lead_name_id: parseInt(this.projectsForm.lead_name_id),
        client_names: this.projectsForm.client_names,
        partner_names: this.projectsForm.partner_names,
        template_for_id: parseInt(this.projectsForm.template_for),
        project_start_date: this.projectsForm.project_start_date,
        project_end_date: this.projectsForm.project_end_date,
        project_duration: this.projectsForm.project_duration,
      }
      this.$emit('emit-eoi-data', payload)
    },
    populateProjectForm(saveTo, data) {

      saveTo.is_icon_lead = data.is_icon_lead
      saveTo.template_for = data?.template_for
      saveTo.lead_name_id = data?.lead_name
      saveTo.project_start_date = data.project_start_date
      saveTo.project_end_date = data.project_end_date
      saveTo.project_duration = data.project_duration
      saveTo.client_names= data.client_names?.map((client)=>({
          text: client?.company_name,
          value: client?.id
      }))
      saveTo.partner_names = data.partner_names?.map((partner)=>({
          text: partner?.company_name,
          value: partner?.id
      }))
    }
  },
  created(){
    this.populateProjectForm(this.projectsForm, this.projectData);
    this.populateProjectForm(this.originalProjectsForm, this.projectData)

   },
   mounted(){
    this.populateProjectForm(this.projectsForm, this.projectData);
    this.populateProjectForm(this.originalProjectsForm, this.projectData)
   }

})
</script>
