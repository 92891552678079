<template>
  <div class="container">
    <div class="table">
      <app-table
        :headers="headers"
        :items="isAllAbsence"
        :isAbsence="true"
        :canEdit="canEdit"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDialog({ $event, data: 'delete' })"
        data-cy="absenceTable"
      >
        <template #title>
          <h3 class="H500">{{ $t("leaves.AllAbsence") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            @click-event="showDialog({ data: 'add' })"
            :buttonLabel="$t('leaves.newAbsence')"
            data-cy="btn_addAbsence"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <app-dialog
      ref="dialogState"
      :title="isEdit ? $t('leaves.editAbsence') : $t('leaves.addAbsence')"
      width="408"
    >
      <template #content>
        <app-input-field
          :label="$t('leaves.destination') + '*'"
          :placeholder="$t('leaves.enterDestination')"
          v-model.trim="absence.destination"
        >
        </app-input-field>
        <app-select-field-object
          :label="$t('leaves.reason') + '*'"
          :options="absenceReason.convertArrayToObject()"
          placeholder="Select reason"
          v-model="absence.reason"
          :isTranslated="true"
        >
        </app-select-field-object>
        <app-date-field
          :label="$t('leaves.startDate') + '*'"
          v-model.trim="absence.start_date"
          type="date"
          id="absence.start-date"
          name="absence.start-date"
        >
        </app-date-field>
        <app-date-field
          :label="$t('leaves.endDate') + '*'"
          v-model.trim="absence.end_date"
          type="date"
          id="end-date"
          name="end-date"
          :min="setMinimumDate"
        >
        </app-date-field>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :loading="isLoading"
              :buttonLabel="
                isEdit ? $t('addcustomrole.saveChanges') : $t('employees.add')
              "
              @click-event="isEdit ? updateAbsence() : saveAbsence()"
              :disabled="
                absence.destination === '' ||
                absence.start_date === '' ||
                absence.end_date === ''
              "
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteAbsence"
            >
              <!-- Yes , delete -->
              {{ $t("employees.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import { employeeAbsence, getEmployeeAbsence, deleteAbsence } from "@/services/endpoints"
import moment from 'moment'
import Api from '@/services/api'
import { extractUnderscore, getTransString } from "@/services/helpers";
export default defineComponent({
  name: "Absence",
  components: {
    Paginator
  },
  data() {
    return {
      headers: [
        "Reason",
        "Destination",
        "Date",
      ],
      absenceReason: ['Acquisition', 'Project Meeting', 'Conference'],
      absence: {
        destination: "",
        reason: "",
        start_date: "",
        end_date: ""
      },
      absenceArray: [],
      absenceId: null,
      currentPages: 1,
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      addTitle: "",
      dialogState: false,
      isLoading: false

    };

  },

  computed: {
    currentPath() {
      return this.$route.path;
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isPersonalKey() {
      return this.$store.getters["auth/isPersonalKey"];
    },

    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    isAllAbsence() {
      return this.$store.getters["hrResources/isAllAbsence"]

    },
    totalPages() {
      return this.$store.getters["hrResources/isAbsenceTotalPages"];
    },
    setMinimumDate() {
      if (this.absence.start_date) {
        const minimumDate = new Date(this.absence.start_date)
        minimumDate.setDate(minimumDate.getDate())
        return minimumDate.toISOString().split('T')[0]
      }
      return null
    },
    canEdit(){
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter((module)=>module.module_name==='Time Off')
      return findModules?.length === 0 ? false : true
    },
  },

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog(data) {
      if (data.data === "add") {
        this.$refs.dialogState.showDialog = true;
      } else if (data.data === 'delete') {
        this.absenceId = data.$event.id
        this.$refs.dialog.showDialog = true
        this.title = "leaves.deleteAbsence";
        this.subTitle = "leaves.deleteConfirmAbsence";
      }

      if (data.data === 'edit') {
        const dateData = data.$event['date'].split('-')

        this.absenceId = data.$event.id
        this.isEdit = true
        this.$refs.dialogState.showDialog = true;
        this.absence.destination = data.$event.destination
        this.absence.reason = data.$event.reason
        this.absence.start_date = moment(dateData[0], "Do MMM YYYY").format("YYYY-MM-DD")
        this.absence.end_date = moment(dateData[1], "Do MMM YYYY").format("YYYY-MM-DD")
      }
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false
      this.isEdit = false
      this.absence.destination = ""
      this.absence.reason = ""
      this.absence.start_date = ""
      this.absence.end_date = ""
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false
    },
    getAbsence(page) {
      let newUserKey = this.isUserKey
      if (this.isHome) {
        newUserKey = this.isEmployeeUserKeyFromHome
      } else {
        newUserKey
      }
      Api()
        .get(`${getEmployeeAbsence}${newUserKey}/${page}`)
        .then(response => {
          if (response.data.status === "success") {
            const data = response.data.data
            const extractedData = extractUnderscore(data)
            this.absenceArray = extractedData.map(newData => {
              const startDate = moment(newData['start date']).format("Do MMM YYYY")
              const endDate = moment(newData['end date']).format("Do MMM YYYY")

              return {
                reason: newData['reason'],
                destination: newData['destination'],
                date: ` ${startDate} - ${endDate}`,
                id: newData.id
              }

            })

            this.$store.commit("hrResources/SET_ABSENCE_TO_STATE", this.absenceArray)
            this.$store.commit("hrResources/SET_ABSENCE_TOTAL_PAGES_TO_STATE", response.data.total_data)
          }
        })
        .catch()
    },
    saveAbsence() {
      this.isLoading = true
      Api()
        .post(employeeAbsence + `${this.isUserKey}/`, this.absence)
        .then(response => {
          if (response.data.status === "success") {
            this.isLoading = false
            this.alertType = "success"
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$refs.dialogState.showDialog = false;
            this.getAbsence(this.currentPage)
            this.absence.destination = ""
            this.absence.reason = ""
            this.absence.start_date = ""
            this.absence.end_date = ""
          }
        })
        .catch()
    },
    updateAbsence() {
      this.isLoading = true
      const payload = {
        absence_id: this.absenceId,
        destination: this.absence.destination,
        reason: this.absence.reason,
        start_date: this.absence.start_date,
        end_date: this.absence.end_date,
      }
      Api()
        .post(`${employeeAbsence}${this.isUserKey}/`, payload)
        .then(response => {
          if (response.data.status === "success") {
            this.isLoading = false
            this.alertType = "success"
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.isEdit = false
            this.$refs.dialogState.showDialog = false;
            this.getAbsence(this.currentPage)
            this.absence.destination = ""
            this.absence.reason = ""
            this.absence.start_date = ""
            this.absence.end_date = ""
          }
        })
        .catch()
    },
    deleteAbsence() {
      Api()
        .delete(`${deleteAbsence}${this.absenceId}/`)
        .then(response => {
          if (response.data.status === "success") {
            this.$refs.dialog.showDialog = false
            this.alertState = true
            this.alertTitle = response?.data?.detail
            this.getAbsence(this.currentPage)
          }
        })
        .catch()
    },
    loadMore() {
      let page = this.currentPages;
      this.getAbsence(page);
    },


  },
  mounted() {
    this.getAbsence(this.currentPage)
  }



});
</script>

<style scoped>
.table {
  width: 100%;
}
</style>
