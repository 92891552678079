<template>
    <section>
        <v-row>
          <v-col class="d-flex align-center justify-end" cols="10"  md="8"  v-if="currentAdvancePaymentId">
            <app-primary-button
              :buttonLabel="$t('hospitalityPackage.previewAndSign')"
              @click="previewAndSign()"
              v-if="!shouldDisableInput"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      <v-row class="d-flex align-center">
        <v-col cols="5" md="4" class="pr-4">
          <app-single-select
            :label="$t('advancePaymentRequest.costCenterReq')"
            :labelPlaceholder="$t('advancePaymentRequest.selectCostCenter')"
            :showCheckbox="false"
            :showSearch="true"
            :searchPlaceholder="$t('advancePaymentRequest.searchCostCenter')"
            :options="costCentersOptions"
            v-model="newRquestForm.cost_center"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('cost_center')"
            :isRequired="true"
          >
          </app-single-select>
        </v-col>
       
      </v-row>
      <v-row>
        <v-col cols="5"  md="4" class="pr-4">
          <!-- <app-single-select
            v-if="selectedOption === 'Employee'"
            :label="$t('advancePaymentRequest.employee')"
            :labelPlaceholder="$t('advancePaymentRequest.selectEmployee')"
            :showCheckbox="false"
            :showSearch="true"
            :searchPlaceholder="$t('advancePaymentRequest.searchEmployee')"
            :options="employeesOptions"
            v-model="newRquestForm.employee"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('employee')"
            :isRequired="true"
          >
          </app-single-select> -->
          <app-single-select
            v-if="selectedOption === 'Expert'"
            :label="$t('advancePaymentRequest.expert')"
            :labelPlaceholder="$t('advancePaymentRequest.selectExpert')"
            :showCheckbox="false"
            :showSearch="true"
            :searchPlaceholder="$t('advancePaymentRequest.searchExpert')"
            :options="expertOPtion"
            @update:modelValue="fetchContract($event, 'Expert')"
            v-model="newRquestForm.expert"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('expert')"
            :isRequired="true"
          >
          </app-single-select>
          <app-single-select
            v-else
            :label="$t('advancePaymentRequest.company')"
            :labelPlaceholder="$t('advancePaymentRequest.selectCompany')"
            :showCheckbox="false"
            :showSearch="true"
            :searchPlaceholder="$t('advancePaymentRequest.searchCompany')"
            :options="companiesOption"
            @update:modelValue="fetchContract($event, 'Company')"
            v-model="newRquestForm.company"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('company')"
            :isRequired="true"
          >
          </app-single-select>
        </v-col>
        <v-col cols="5"  md="4" class="pl-4" >
          <app-single-select
            :label="$t('advancePaymentRequest.contract')"
            :labelPlaceholder="$t('advancePaymentRequest.selectContract')"
            :showCheckbox="false"
            :showSearch="true"
            :searchPlaceholder="$t('advancePaymentRequest.searchContract')"
            :options="contractsOption"
            v-model="newRquestForm.contract_id"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('contract_id')"
            :isRequired="true"
          >
          </app-single-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5"  md="4" class="pr-4">
          <app-input-field
            :label="$t('advancePaymentRequest.amount')"
            :placeholder="$t('advancePaymentRequest.enterAmount')"
            v-model.number="newRquestForm.amount"
            :disabled="shouldDisableInput || disabledFormInputs"
            validation="floatingNumber"
            :hasError="isFieldInvalid('amount')"
            :isRequired="true"
          >
          </app-input-field>
        </v-col>
        <v-col cols="5" md="4" class="pl-4">
          <app-input-field
            :label="$t('advancePaymentRequest.conditionOnAccount')"
            :placeholder="$t('advancePaymentRequest.enterConditionOnAccount')"
            v-model.number="newRquestForm.condition_amount"
            :disabled="shouldDisableInput || disabledFormInputs"
            :hasError="isFieldInvalid('condition_amount')"
            :isRequired="true"
          >
          </app-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          
          <app-upload-documents v-if="!shouldDisableInput" :disabled="disabledFormInputs" :documentFiles="newRquestForm.request_files"></app-upload-documents>
        </v-col>
      </v-row>
      <v-row
        class="mb-10"
        :class="{
          'pointer-events-none': shouldDisableInput || disabledFormInputs,
        }"
      >
        <v-col cols="10" md="8">
          <div class="label py-2">
            <label for="comment">{{ $t("advancePaymentRequest.comment") }}</label>
          </div>
          <QuillEditor
            :placeholder="$t('advancePaymentRequest.enterComment')"
            :class="contentStyles"
            contentType="text"
            v-model:content="newRquestForm.comment"
            :options="options"
            :style="editorStyles"
          />
        </v-col>
      </v-row>
      <v-sheet
      max-width=""
      class="bg-transparent mt-10"
      v-if="!disabledFormInputs"
    >
      <v-row v-if="!shouldDisableInput">
        <v-col cols="10" class="d-flex justify-end mt-16">
          <app-secondary-button
            :buttonLabel="$t('advancePaymentRequest.cancel')"
            @click="close"
          >
          </app-secondary-button>
          <app-primary-button
            :buttonLabel="$t('advancePaymentRequest.save')"
            class="ml-5"
            :loading="loading"
            @click="createAdvancementPaymentRequest()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </v-sheet>
      <signature-form ref="signatureForm" />
    </section>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </template>
  <script>
  import { defineComponent } from "vue";
  import { getTransString, scrollToTop } from "@/services/helpers";
  import { isEqual } from "lodash";
  import Api from "@/services/api";
  import { QuillEditor } from "@vueup/vue-quill";
  import "@vueup/vue-quill/dist/vue-quill.snow.css";
  import { createAdvancementPaymentRequest } from "@/services/endpoints";
  import SignatureForm from "./SignatureForm.vue";
 
  export default defineComponent({
    components: {
      QuillEditor,
      SignatureForm,
    },
    props: {
      disabledFormInputs: {
        type: Boolean,
        default: false,
      },
      hidePreviewAndSignButton: {
        type: Boolean,
        default: false,
      },
    },
    name: "RequestAdvancePayment",
    data() {
      return {
        options: {
          modules: {
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["blockquote"],
              ["link"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
          placeholder: this.placeholder,
        },
        editorStyles: {
          minHeight: "190px",
          padding: "10px, 14px",
          fontSize: "16px",
        },
        selectedOption: "Employee",
        alertType: "success",
        alertState: false,
        alertTitle: "",
        cancelLabel: "Cancel",
        title: "",
        subTitle: "",
        newRquestForm: {
          request_for: "",
          cost_center: "",
          employee: "",
          company: null,
          amount: "",
          condition_amount: null,
          contract_id: "",
          expert: null,
          request_files: [],
          contract_type: "",
          comment: "",
        },
        isDirty: [],
        requiredFields: [],
        showButtonOnNewRequest: false,
      };
    },
    watch: {
      selectedOption: function (val) {
        this.newRquestForm.request_for = val;
        this.hasError = false;
        this.isDirty = [];
        this.requiredFields = [];
        // this.resetForm()
        if (val === "Employee") {
          this.currentPaymentRequests.request_for !== "Employee" ? this.resetForm() :this.populateInputField(this.newRquestForm, this.currentPaymentRequests);
          this.requiredFields.push("employee", "cost_center", "amount");
        }
        if (val === "Expert") {
          this.currentPaymentRequests.request_for !== "Expert" ? this.resetForm() :this.populateInputField(this.newRquestForm, this.currentPaymentRequests);
          this.requiredFields.push("expert", "cost_center", "amount");
        }
        if (val === "Company") {
          this.currentPaymentRequests.request_for !== "Company" ? this.resetForm() :this.populateInputField(this.newRquestForm, this.currentPaymentRequests);
          this.requiredFields.push("company", "cost_center", "amount");
        }
      },
      //currentPaymentRequests
      newRquestForm: {
        deep: true,
        handler(value) {
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
            BasicInformation: !isEqual(value, this.originalProjectsForm),
          });
        },
      },
      currentPaymentRequests: {
        handler: function (data) {
          if (data) {
            this.populateInputField(this.newRquestForm, data);
          }
        },
        immediate: true,
      },
    },
    computed: {
      currentPage() {
        return this.$store.getters["hospitalityModules/isCurrentPage"];
      },
      costCentersOptions() {
        const optionList = this.$store.getters["hrConfigurations/isCostCenters"];
        return optionList.map((costCenter) => {
          return {
            text: `${costCenter.cost_center_number} - ${costCenter.cost_center_type}`,
            value: costCenter.id,
          };
        });
      },
      companiesOption() {
        const modObject = this.$store.getters["partnerModules/isAllPartners"];
  
        return modObject?.map((obj) => {
          const { id: value, company_name: text } = obj;
          return { text, value };
        });
      },
      // employeesOptions() {
      //   return this.$store.getters["advancePaymentModules/allEmployees"];
      // },
      expertOPtion() {
        return this.$store.getters["advancePaymentModules/allExperts"];
      },
  
      allContracts() {
        return this.$store.getters["advancePaymentModules/allContracts"];
      },
      currentPaymentRequests() {
        return this.$store.getters[
          "advancePaymentModules/currentPaymentRequests"
        ];
      },
      currentAdvancePaymentId() {
        return this.$store.getters[
          "advancePaymentModules/currentAdvancePaymentId"
        ];
      },
      contractsOption() {
        const modifiedData = this.allContracts;
        return modifiedData?.map((contract) => {
          return {
            text: contract.type,
            value: contract.contract_id,
          };
        });
      },
      documentUrl() {
        const modifiedData = this.$store.getters["commonResources/documentUrl"];
        return modifiedData.map((url) => {
          return url.file_path;
        });
      },
      currentPath() {
        return this.$route.path;
      },
      isEditEmployee() {
        return this.$store.getters["hrResources/isEditEmployee"];
      },
      isEditButton() {
        return this.$store.getters["hrResources/isEditButton"];
      },
      shouldDisableInput() {
        // Add your condition to disable the input element here
        return (
          (this.currentPath === "/edit-payment-request" || this.currentPath==='/profile-edit-payment-request') &&
          this.isEditEmployee &&
          !this.isEditButton
        );
      },
      contentStyles() {
        return this.shouldDisableInput
          ? "bg-[#F9FAFB] !text-[#667085] pointer-events-none"
          : "bg-white !border !rounded-b-lg";
      },
    },
    methods: {
    
      showButton(){
        scrollToTop()
        return this.shouldDisableInput ?  !(this.currentPath === "/add-payment-request" && this.showButtonOnNewRequest) : this.hidePreviewAndSignButton
      },
      resetForm() {
        this.newRquestForm = {
          request_for: "",
          cost_center: "",
          employee: "",
          company: null,
          amount: "",
          condition_amount: null,
          contract_id: "",
          expert: null,
          request_files: [],
          contract_type: "",
          comment: "",
        };
      },
      previewAndSign() {
        this.$refs.signatureForm.showSignatureDialog();
      },
      close() {
        this.$router.go(-1);
      },
      fetchContract(data, type) {
        if (type === "Expert") {
          this.$store.dispatch(
            "advancePaymentModules/getAllExpertOrCompanyContract",
            { name: "Expert", id: data }
          );
        } else {
          this.$store.dispatch(
            "advancePaymentModules/getAllExpertOrCompanyContract",
            { name: "Company", id: data }
          );
        }
      },
      getTranslatedStringKey(field) {
        return getTransString(field);
      },
  
      checkAlertEror(message) {
        this.alertState = true;
        this.alertType = "error";
        this.alertTitle = message;
      },
      isFieldInvalid(fieldName) {
        return this.isDirty?.includes(fieldName) || false;
      },
      inputFieldsValidation() {
        this.hasError = false;
        Object.keys(this.newRquestForm).forEach((key) => {
          if (
            this.requiredFields?.includes(key) &&
            (this.newRquestForm[key] === undefined ||
              this.newRquestForm[key] === null ||
              this.newRquestForm[key] === "")
          ) {
            this.isDirty.push(key);
            this.hasError = true;
            this.checkAlertEror("Please fill out the required fields");
          }
        });
        if (this.hasError) {
          return this.hasError;
        }
      },
      createAdvancementPaymentRequest() {
        this.inputFieldsValidation();
        if (this.hasError) return;
        if (this.selectedOption === "Employee") {
          this.newRquestForm.request_for = this.selectedOption;
          delete this.newRquestForm.contract_id;
          delete this.newRquestForm.contract_type;
          delete this.newRquestForm.expert;
          delete this.newRquestForm.company;
        }
        if (this.selectedOption === "Expert") {
          this.newRquestForm.request_for = this.selectedOption;
          const contract = this.allContracts.find(
            (contract) => this.newRquestForm.contract_id === contract.contract_id
          );
          this.newRquestForm.contract_type = contract.contract_type;
  
          delete this.newRquestForm.employee;
          delete this.newRquestForm.company;
        }
        if (this.selectedOption === "Company") {
          this.newRquestForm.request_for = this.selectedOption;
          const contract = this.allContracts.find(
            (contract) => this.newRquestForm.contract_id === contract.contract_id
          );
          this.newRquestForm.contract_type = contract.contract_type;
  
          delete this.newRquestForm.expert;
          delete this.newRquestForm.employee;
        }
        this.newRquestForm.request_files = this.documentUrl;
        const payload = {
          ...this.newRquestForm,
        };
        Api()
          .post(`${createAdvancementPaymentRequest}`, payload)
          .then((response) => {
            if (response.data.status === "success") {
              const payload = {
                load_page: false,
          
              };
              this.$store.dispatch(
                "advancePaymentModules/getSingleAdvancementPaymentRequest",
                payload
              );
  
              this.alertType = "success";
              this.alertState = true;
              this.alertTitle = response?.data?.detail;
          
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertState = true;
              this.alertTitle = error.response?.data?.detail;
            }
          });
      },
      getSingleAdvancementPaymentRequest() {
        const payload = {
          load_page: true,
        };
        this.$store.dispatch(
          "advancePaymentModules/getSingleAdvancementPaymentRequest",
          payload
        );
      },
      populateInputField(saveTo, data) {
        this.selectedOption = data.request_for;
        data.company ? this.fetchContract(data.company, data.request_for) : this.fetchContract(data.expert, data.request_for)
        saveTo.cost_center = data.cost_center;
        saveTo.expert = data.expert;
        saveTo.company = data.company;
        saveTo.request_for = data.request_for;
        saveTo.amount = data.amount;
        saveTo.condition_amount = data.condition_amount;
        saveTo.contract_id = data.contract_id;
        saveTo.request_files = data.request_files;
          // data.request_for !== "Employee"
          //   ? this.fetchContract(data.contract_id, data.request_for)
          //   : null
      },
    },
    created() {
      if (this.currentAdvancePaymentId) {
        this.populateInputField(this.newRquestForm, this.currentPaymentRequests);
      }
      this.$store.dispatch(
        "advancePaymentModules/getAllExpertsOrEmployees",
        "Employee"
      );
      this.$store.dispatch(
        "advancePaymentModules/getAllExpertsOrEmployees",
        "Expert"
      );
      this.$store.commit("advancePaymentModules/SET_ADVANCEMENT_PAYMENT_REQUEST_FORM" , this.newRquestForm);
      this.$store.dispatch("hrConfigurations/getAllCostCentersWithoutPagination");
      this.$store.dispatch("partnerModules/getAllPartners");
      this.selectedOption === undefined ? this.selectedOption = "Expert" : this.selectedOption
    },
  });
  </script>
  