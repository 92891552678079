<template>
  <div>
    <div class="mb-5">
      <div>
        <button
          v-for="(tab, index) in tabList"
          :key="index"
          :class="['tab-button', { active: currentTab === tabs[index] }]"
          @click="goToTab(index)"
        >
          {{ $t(getTranslatedStringKey(tab)) }}
        </button>
      </div>
    </div>
    <ClientTabs
      :is="currentTab"
      v-bind="isCurrentTab"
      type="tab"
      class="pt-2"
      :tabsInfo="tabsInfo[currentTab]"
      @hide-dialog="hideDialog"
    ></ClientTabs>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ClientTabs from "./ClientTabs.vue";

import { mapState } from "vuex";
import { getTransString } from "@/services/helpers";

export default defineComponent({
  name: "AddEmployee",
  components: {
    ClientTabs,
  },
  data() {
    return {
      currentTab: "Basic Information",
      tabList: ["Basic Information", "Address", "Contact"],
      tabs: ["BasicInformation", "Address", "Contact"],
      tabsInfo: {
        BasicInformation: {
          name: "Task Areas",
          singular: "Task Area",
          getter: "isTaskAreas",
          setter: "SET_TASK_AREAS_TO_STATE",
          tabId: 0,
          reqFields: ["company_name", "city", "country"],
          fields: [
            {
              name: "Company Name *",
              placeholder: "Enter company name",
              fieldType: "input",
              id: "company_name",
            },
            {
              name: "City *",
              placeholder: "Enter city name",
              validation: "lettersOnly",
              fieldType: "input",
              id: "city",
            },
            {
              name: "Country *",
              placeholder: "Select country",
              validation: "lettersOnly",
              fieldType: "select",
              id: "country",
            },
          ],
        },
        Address: {
          name: "Job Types",
          singular: "Job Type",
          getter: "isJobTypes",
          setter: "SET_JOB_TYPES_TO_STATE",
          tabId: 1,
          reqFields: ["address_line_1", "city", "country", "email"],
          fields: [
            {
              name: "Address Line 1*",
              placeholder: "Enter address",
              fieldType: "input",
              id: "address_line_1",
            },
            {
              name: "City *",
              placeholder: "Enter city name",
              validation: "lettersOnly",
              fieldType: "input",
              id: "city",
            },
            {
              name: "Country *",
              placeholder: "Select country",
              validation: "lettersOnly",
              fieldType: "select",
              id: "country",
            },
            {
              name: "Email *",
              placeholder: "Enter email address",
              validation: "lettersOnly",
              fieldType: "input",
              id: "email",
            },
          ],
        },
        Contact: {
          name: "Employment Types",
          singular: "Employment Type",
          getter: "isEmploymentTypes",
          tabId: 2,
          reqFields: [
            "contact_person",
            "contact_phone_number",
            "contact_email",
          ],
          fields: [
            {
              name: "Contact Person *",
              placeholder: "Enter contact person name",
              validation: "lettersOnly",
              fieldType: "input",
              id: "contact_person",
            },
            {
              name: "Phone *",
              placeholder: "Enter phone number",
              validation: "phoneOnly",
              fieldType: "input",
              id: "contact_phone_number",
            },
            {
              name: "Email *",
              placeholder: "Enter email address",
              validation: "lettersOnly",
              fieldType: "input",
              id: "contact_email",
            },
          ],
        },
      },
    };
  },
  computed: {
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    isCurrentTab() {
      return this.$store.getters["partnerModules/isCurrentTab"];
    },
    addEmployeeLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  methods: {
    showEditButton() {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDialog() {
      this.$emit("hide-dialog");
    },
    goToTab(tabIndex) {
      this.$store.commit("partnerModules/SET_CURRENT_TAB_TO_STATE", tabIndex);
    },
  },
  created() {
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.commit("partnerModules/SET_PARTNER_KEY_TO_STATE", null);
    this.currentTab = this.tabs[this.isCurrentTab];
  },
  updated() {
    this.currentTab = this.tabs[this.isCurrentTab];
  },
});
</script>

<style scoped>
.tab-button {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
  margin-right: 10px;
  color: #667085;
}
.tab-button:hover {
  background: #f8f8f8;
}
.tab-button.active {
  background: #efefef;
  color: #352c2c;
}
.tab-button.disabled {
  cursor: not-allowed;
}
</style>
