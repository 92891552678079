import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
    getAllProjectLocalBudgetUrl,
    putProjectLocalBudgetUrl
} from "@/services/endpoints";

interface LocalBudget {
    local_budget_id: number,
    quantity: number,
    amount: number,
    rowIndex?: number
}

interface LocalBudgetBody {
    bill: string,
    input_locally: boolean,
    create_sub_budget_for_pm_local: boolean,
    change_pm_local_division: boolean,
    local_budgets: LocalBudget[]
}

interface LocalBudgetPayload{
    project: string | number,
    request: LocalBudgetBody
}

interface Office {
    local_budget_id: number,
    country: string,
    quantity: number,
    amount : number,
    amount_per_unit: number,
}

interface LineItem {
    id: number,
    position: string,
    structure: string, 
    account: string,
    number_of_units: number,
    amount_per_unit: number,
    total_amount: number, 
    offices: Office[] 
}

export default {
  namespaced: true,

  state: {
    error: {},
    response: {},
    appLoading: false,
    projectLocalBudgets: {},
    projectLocalBudgetsData: {},
    local_budgets: [],
    amount_per_unit_arr: [],
  },

  mutations: {
    
    SET_ERROR(state: any, data: any) {
        state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
        state.response = { ...data };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: boolean) {
        state.appLoading = payload;
    },
    SET_ALL_PROJECT_LOCAL_BUDGET(state: any, data: any) {
        state.projectLocalBudgets = { ...data }
    },
    SET_ALL_PROJECT_LOCAL_BUDGET_DATA(state: any, data: LocalBudgetBody) {
        state.projectLocalBudgetsData = { ...data }
    },
    RE_SET_PROJECT_LOCAL_BUDGETS(state: any) {
        for (const budgetItem of state.projectLocalBudgetsData.local_budgets) {
          for (const budgetItemOffice of budgetItem.offices) {
            const rowIndex = budgetItemOffice.rowIndex;
            const local_budget_id = budgetItemOffice.local_budget_id;
          
            const localBudget = state.projectLocalBudgets.local_budgets[rowIndex];
            const index  = localBudget.offices.findIndex((obj: any) => obj.local_budget_id === local_budget_id);

            if (index !== -1) {
              localBudget.offices[index].quantity = budgetItemOffice.quantity;
              localBudget.offices[index].amount = budgetItemOffice.amount;
            }
          }
            
        }
    },
  },
  getters: {
    getError(state: any) {
        return state.error;
    },
    getResponse(state: any) {
        return state.response;
    },
    getAppLoading(state: any) {
        return state.appLoading;
    },
    getProjectLocalBudget(state: any) {
        return state.projectLocalBudgets
    },
    getProjectLocalBudgetData(state: any) {
        return state.projectLocalBudgetsData
    },
    getAmountPerUnitArr(state: any) {
        state.amount_per_unit_arr = []
        state.projectLocalBudgets?.local_budgets?.forEach( (local_budget: LineItem) => {
            state.amount_per_unit_arr.push(local_budget.amount_per_unit)
        });
        return state.amount_per_unit_arr
    },
  },

  actions: {
    async fetchAllLocalBudget(
        { commit }: ActionContext<any, any>,
        payload: {project :number}
      ) {
        try {
          commit("SET_APP_LOADING_TO_STATE", true);
          const data = await Api().get(
            `${getAllProjectLocalBudgetUrl}${payload.project}/`
          );
          commit("SET_ALL_PROJECT_LOCAL_BUDGET", {
            ...data?.data?.data
          });
        
          commit("SET_ERROR", {});
          commit("SET_APP_LOADING_TO_STATE", false);
        } catch ({ response }: any) {
          commit("SET_ERROR", response);
          commit("SET_ALL_PROJECT_LOCAL_BUDGET", {});
        }
      },

    async updateLocalBudget( { commit }: ActionContext<any, any>,
        payload: LocalBudgetPayload) {

        try{
            const data = await Api().put(
            `${putProjectLocalBudgetUrl}${payload.project}/`, 
                payload.request
            );

            commit("SET_RESPONSE", data.data);
            commit("SET_ERROR", {});
        } catch ({ response }: any) {
            commit("SET_ERROR", response);
            commit("SET_RESPONSE", {});
        }

    }
  },
};
