<template>
  <div>
    <div
      v-if="isAppLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <v-row v-if="!isAppLoading">
      <v-col cols="12" class="">
        <form>
          <div class="flex justify-start mb-[24px] items-center">
            <div
              :class="[
                'rounded-full profile-img-wrapper p-4 bg-[#F9FAFB]',
                personalForm.profile_picture && 'selected',
              ]"
            >
              <img
                :src="
                  personalForm.profile_picture
                    ? personalForm.profile_picture
                    : noProfileImg
                "
                alt=""
                class="w-[80px] h-[80px] rounded-full object-cover"
              />
              <input
                type="file"
                ref="fileInput"
                @change="handleFileSelect"
                hidden
              />
            </div>
            <div class="flex flex-col ml-[24px] items-center">
              <div>
                <h1 class="text-[30px]">
                  {{ $t(getTranslatedStringKey("Profile")) }}
                </h1>
                <div class="flex mt-[16px]">
                  <app-secondary-button
                    :buttonLabel="$t('personal.changePhoto')"
                    :disabled="
                      (currentPath === '/edit-employee' ||
                        currentPath === '/edit-expert' ||
                        currentPath === '/profile') &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    @click="openFileInput"
                  />
                  <button
                    v-if="personalForm.profile_picture"
                    type="button"
                    :onclick="removePhoto"
                    :disabled="
                      (currentPath === '/edit-employee' ||
                        currentPath === '/edit-expert' ||
                        currentPath === '/profile') &&
                      isEditEmployee &&
                      !isEditButton
                    "
                    class="text-[#667085] p-2 ml-3 hover:bg-gray-50"
                  >
                    {{ $t("personal.removePhoto") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="max-w-[87%] mb-[32px]" />
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                id="1"
                :label="$t('personal.username')"
                :placeholder="$t('personal.enterUsername')"
                v-model:modelValue="personalForm.userName"
                :hasError="isFieldInvalid('userName')"
                :isRequired="true"
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="2"
                :label="$t('tablefields.title')"
                :placeholder="$t('personal.enterTitle')"
                v-model:modelValue="personalForm.title"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="2"
                :label="$t('personal.firstName')"
                :placeholder="$t('personal.enterUsername')"
                validation="lettersOnly"
                :hasError="isFieldInvalid('firstName')"
                :isRequired="true"
                v-model:modelValue="personalForm.firstName"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                id="3"
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                :label="$t('personal.lastName')"
                :placeholder="$t('personal.enterLastName')"
                validation="lettersOnly"
                :hasError="isFieldInvalid('lastName')"
                :isRequired="true"
                v-model:modelValue="personalForm.lastName"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="4"
                :label="$t('personal.middleName')"
                :placeholder="$t('personal.enterMiddleName')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.middleName"
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="5"
                :label="$t('personal.dateOfBirth')"
                minDate=""
                type="date"
                v-model:modelValue="personalForm.dateOfBirth"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                v-model="personalForm.gender"
                :label="$t('personal.gender')"
                placeholder="Select gender"
                :options="genderOptions"
                :is-translation="true"
                data-cy="personal_gender"
              ></app-select-field-object>
            </div>
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                v-model="personalForm.availability"
                :label="$t('personal.availability')"
                placeholder="Select availability"
                :is-translation="true"
                :options="availabilityArray"
              ></app-select-field-object>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-3">
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                :label="$t('personal.civilStatus')"
                placeholder="Select civil status"
                :options="civilStatusOptions"
                v-model="personalForm.civilStatus"
              ></app-select-field-object>
            </div>

            <div class="w-[440px]">
              <app-select-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                v-model="personalForm.countryOfBirth"
                :label="$t('personal.countryOfBirth')"
                :placeholder="$t('personal.selectCountry')"
                :options="countryOptions"
              ></app-select-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-3">
            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="5"
                :label="$t('personal.citizenship')"
                :options="countryCitizensArray"
                :isTranslation="false"
                :placeholder="$t('personal.selectCitizenship')"
                validation="lettersOnly"
                v-model:modelValue="personalForm.citizenship"
              ></app-select-field-object>
            </div>

            <div class="w-[440px]">
              <app-select-field-object
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="5"
                :label="$t('experts.germanyResidenceStatus')"
                :options="residenceStatus.convertArrayToObject()"
                :isTranslation="false"
                placeholder="Select residence status"
                validation="lettersOnly"
                v-model:modelValue="personalForm.residenceStatus"
              ></app-select-field-object>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-3">
            <div class="w-[440px] mb-5">
              <app-input-field
                :disabled="
                  (currentPath === '/edit-employee' ||
                    currentPath === '/edit-expert' ||
                    currentPath === '/profile') &&
                  isEditEmployee &&
                  !isEditButton
                "
                id="6"
                :label="combineTranslation($t('personal.officialEmail'), '*')"
                :placeholder="$t('contact.enterEmailAddress')"
                validation="emailOnly"
                :hasError="isFieldInvalid('lastName')"
                :isRequired="true"
                v-model:modelValue="personalForm.official_email"
              ></app-input-field>
              <!-- <div class="flex items-center gap-3 mt-2 mb-7">
                <input
                  :disabled="
                    ((currentPath === '/edit-employee' ||
                      currentPath === '/edit-expert' ||
                      currentPath === '/profile') &&
                      isEditEmployee &&
                      !isEditButton) ||
                    personalForm.official_email === ''
                  "
                  type="checkbox"
                  class=""
                  v-model="personalForm.isCommunication"
                />
                <label
                  for="communicationCheckbox"
                  class="text-[#667085] text-[14px]"
                  >{{ $t("personal.selectForCommunication") }}
                </label>
              </div> -->
            </div>
          </div>
          <v-row class="mt-5">
            <v-col cols="12">
              <div class="w-[920px]">
                <div
                  v-if="
                    !isEditEmployee ||
                    (currentPath !== '/edit-employee' &&
                      currentPath !== '/edit-expert' &&
                      currentPath !== '/profile')
                  "
                  class="flex justify-end mb-7"
                >
                  <div class="flex gap-3">
                    <div>
                      <app-secondary-button
                        :buttonLabel="$t('personal.saveAndExit')"
                        @click-event="savePersonalToState('exit')"
                        :disabled="
                          personalForm.userName === '' ||
                          personalForm.firstName === '' ||
                          personalForm.lastName === '' ||
                          personalForm.official_email === ''
                        "
                      >
                      </app-secondary-button>
                    </div>
                    <div>
                      <app-primary-button
                        :buttonLabel="$t('personal.saveAndContinue')"
                        @click-event="savePersonalToState('continue')"
                      >
                      </app-primary-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-end gap-7">
                  <div v-show="isEditButton">
                    <app-secondary-button
                      :buttonLabel="$t('personal.cancel')"
                      @click="cancel"
                    ></app-secondary-button>
                  </div>
                  <app-primary-button
                    v-show="isEditButton"
                    :buttonLabel="$t('employment.saveChanges')"
                    @click-event="savePersonalToState('edit')"
                    :disabled="
                      personalForm.userName === '' ||
                      personalForm.firstName === '' ||
                      personalForm.lastName === ''
                    "
                  >
                  </app-primary-button>
                </div>
              </div>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import countries from "@/services/country_api";
import { getTransString } from "@/services/helpers";
import { countryCitizens } from "@/services/citizen";
import { validEmail } from "@/services/validation";
import NoProfileImg from "@/assets/no-profile-img.svg";
import Api from "@/services/api";
import { FormApi } from "@/services/customApi";
import { isEqual } from "lodash";

export default defineComponent({
  name: "Personal",
  components: {},
  props: {
    errors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isAppLoading: false,
      noProfileImg: NoProfileImg,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      profilePictureFile: null,
      isEdit: false,
      firstRun: true,
      loadUserKey: null,
      countryOptions: null,
      countryCitizensArray: [],
      genderOptions: [
        { id: "Male", value: "Male" },
        { id: "Female", value: "Female" },
      ],
      availabilityArray: [
        { id: "Available", value: "Available" },
        { id: "Only inhouse", value: "Only inhouse" },
        { id: "Contact first", value: "Contact first" },
        { id: "Not available", value: "Not available" },
        { id: "Sorted out", value: "Sorted out" },
        { id: "Deceased", value: "Deceased" },
      ],
      residenceStatus: ["Permanent", "Temporary"],
      // civilStatusOptions: [
      //   {
      //     id: "Married",
      //     value: "Married",
      //   },
      //   { id: "Unmarried", value: "Unmarried" },
      //   { id: "Divorced", value: "Divorced" },
      // ],

      originalFormData: {
        userName: "",
        title: null,
        firstName: "",
        middleName: "",
        lastName: "",
        countryOfBirth: null,
        dateOfBirth: null,
        citizenship: "",
        civilStatus: "",
        profile_picture: null,
        gender: "",
        official_email: "",
        isCommunication: false,
        availability: "",
        residenceStatus: "",
      },
      personalForm: {
        userName: "",
        title: null,
        firstName: "",
        middleName: "",
        lastName: "",
        countryOfBirth: null,
        dateOfBirth: null,
        citizenship: "",
        civilStatus: "",
        profile_picture: null,
        gender: "",
        official_email: "",
        isCommunication: true,
        availability: "",
        residenceStatus: "",
      },
      username: null,
      allUserRoles: [],
      url: "/configuration/roles/all-user-roles",
      page: 1,
      roles: [],
      requiredFields: ["userName", "lastName", "firstName", "official_email"],
      isDirty: [],
      hasError: false,
    };
  },
  computed: {
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["hrResources/isEditButton"] &&
        this.canEditPersonalModule?.includes("Personal")
      );
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    userKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    currentPath() {
      return this.$route.path;
    },
    civilStatusOptions() {
      const data = [
        "Unknown",
        "Married",
        "Unmarried",
        "Divorced",
        "Widowed",
        "Other",
      ];
      return data?.convertArrayToObject();
    },
  },
  watch: {
    isPersonalData(newValue) {
      this.populateFieldsWithData(newValue, this.personalForm);
      if (this.firstRun) {
        this.populateFieldsWithData(newValue, this.originalFormData);
        this.firstRun = false;
      }
    },
    personalForm: {
      deep: true,
      handler(value) {
        this.$store.commit("expertModules/SET_EMPLOYEE_UPDATE", {
          Personal: !isEqual(value, this.originalFormData),
        });
      },
    },
  },
  methods: {
    // check the required fields
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    combineTranslation(...args) {
      return args.reduce((acc, curr) => acc + " " + curr, "");
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleNextClick() {
      this.$emit("next-clicked");
    },
    cancel() {
      this.$router.go(-1);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    removePhoto() {
      this.$refs.fileInput.value = "";
      URL.revokeObjectURL(this.personalForm.profile_picture);
      this.profilePictureFile = "";
      this.personalForm.profile_picture = null;
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.profilePictureFile = file;
      this.personalForm.profile_picture = URL.createObjectURL(file);
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.personalForm).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.personalForm[key] === "" ||
            this.personalForm[key] === undefined ||
            this.personalForm[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    savePersonalToState(data) {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      if (!validEmail(this.personalForm.official_email)) {
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }

      const payload = {
        user_key: this.isEditEmployee ? this.userKey : "",
        username: this.personalForm.userName,
        title: this.personalForm.title,
        first_name: this.personalForm.firstName,
        middle_name: this.personalForm.middleName,
        last_name: this.personalForm.lastName,
        country_of_birth: this.personalForm.countryOfBirth,
        date_of_birth: this.personalForm.dateOfBirth,
        citizenship: this.personalForm.citizenship,
        civil_status: this.personalForm.civilStatus,
        gender: this.personalForm.gender,
        official_email: this.personalForm.official_email,
        availability: this.personalForm.availability,
        user_type: "Expert",
        residence_status: this.personalForm.residenceStatus,
        communication_email: this.personalForm.isCommunication
          ? this.personalForm.official_email
          : "",
      };

      if (payload.user_key === "") {
        delete payload.user_key;
      }

      if (this.personalForm.dateOfBirth == null) {
        delete payload.date_of_birth;
      }

      const formData = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }

      if (this.profilePictureFile) {
        formData.append("profile_picture", this.profilePictureFile);
      }

      FormApi.post("/hr/employees/add-personal-info/", formData)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_USER_KEY_TO_STATE",
              response.data.data.user_key
            );
            this.$store.commit(
              "hrResources/SET_USER_INFO_TO_STATE",
              response.data.data
            );
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;

            this.$store.commit("expertModules/SET_EMPLOYEE_UPDATE", {
              Personal: false,
            });

            if (data === "exit") {
              this.$router.push({ name: "experts" });
            }
            if (data === "continue") {
              this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 1);
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getPersonalContactInfo() {
      this.isAppLoading = true;
      Api()
        .get(
          `/hr/employees/get-employee-personal-contact-info/${this.userKey}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.isAppLoading = false;
            this.$store.commit(
              "hrResources/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
            this.isAppLoading = false;
          }
        });
    },
    populateFieldsWithData(data, saveTo) {
      saveTo["userName"] = this.isEdit ? data.username : "";
      saveTo["firstName"] = this.isEdit ? data.first_name : "";
      saveTo["middleName"] = this.isEdit ? data?.middle_name : "";
      saveTo["lastName"] = this.isEdit ? data?.last_name : "";
      saveTo["countryOfBirth"] = this.isEdit
        ? data?.country_of_birth
        : this.countryOptions[64];
      saveTo["dateOfBirth"] = this.isEdit ? data?.date_of_birth : null;
      saveTo["citizenship"] = this.isEdit ? data?.citizenship : "";
      saveTo["civilStatus"] = this.isEdit
        ? data?.civil_status
        : this.civilStatusOptions[0]?.value;
      saveTo["gender"] = this.isEdit
        ? data?.gender
        : this.genderOptions[0]?.value;
      saveTo["official_email"] = this.isEdit ? data?.official_email : "";
      saveTo["isCommunication"] = this.isEdit
        ? data?.communication_email === data?.official_email
        : false;
      saveTo["profile_picture"] = this.isEdit ? data?.profile_picture : "";
      saveTo["title"] = this.isEdit ? data?.title : "";
      saveTo["availability"] = this.isEdit ? data?.availability : "";
      saveTo["residenceStatus"] = this.isEdit ? data?.residence_status : "";
    },
    async getAllUserRoles() {
      try {
        let hasMorePages = true;

        while (hasMorePages) {
          const response = await Api().post(`${this.url}/${this.page}/`);
          const data = response.data.data;
          this.allUserRoles = [...this.allUserRoles, ...data];
          if (data.length < 10) {
            hasMorePages = false;
          } else {
            this.page++;
          }
        }

        const roleNames = this.allUserRoles.map((obj) => Object.keys(obj)[0]);

        const outputArray = roleNames.map((roleName) => ({
          roleName,
        }));

        this.roles = outputArray.map((obj) => obj.roleName); // Extract only the role names
      } catch (error) {
        // console.error(error);
      }
    },
  },
  mounted() {
    this.getAllUserRoles();
    // Loop through the countryCitizens object
    for (const country in countryCitizens) {
      if (Object.prototype.hasOwnProperty.call(countryCitizens, country)) {
        const citizen = countryCitizens[country];
        this.countryCitizensArray.push({ id: citizen, value: citizen });
      }
    }
    this.countryOptions = this.getCountryByName();
    // this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
  },
  created() {
    this.$store.commit(
      "hrResources/SET_EDIT_EMPLOYEE_STATE",
      this.userKey !== null && this.userKey.length > 0
    );
    if (this.isEditEmployee) {
      this.getPersonalContactInfo();
    }
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.isEdit = this.isEditEmployee;
    this.isEditButton = this.isEditEmployee;
    this.username = this.$store.state.hrResources?.userName;
  },
});
</script>

<style scoped>
.select-field {
  width: 400px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  /* cursor: not-allowed; */
  color: gray;
  opacity: 0.5;
}

.profile-img-wrapper {
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
  border: 2px solid #fff;
}

.profile-img-wrapper.selected {
  padding: 5px;
}

.profile-img-wrapper.selected img {
  width: 100px;
  height: 100px;
}
</style>
