<template>
  <div>
    <div>
      <v-row>
        <v-col cols="5" class="">
          <app-single-select
            :label="$t('projectConfiguration.firstApprover')"
            :options="employees"
            :labelPlaceholder="$t('projectConfiguration.selectFirstApprover')"
            :searchPlaceholder="$t('projectConfiguration.searchEmployees')"
            :showSearch="true"
            :showCheckbox="false"
            v-model="form.first_approver_id"
          ></app-single-select>
        </v-col>
        <v-col cols="5" class="">
          <app-single-select
            :label="$t('projectConfiguration.secondApprover')"
            :options="employees"
            :labelPlaceholder="$t('projectConfiguration.selectSecondApprover')"
            :searchPlaceholder="$t('projectConfiguration.searchEmployees')"
            :showSearch="true"
            :showCheckbox="false"
            v-model="form.second_approver_id"
          ></app-single-select>
        </v-col>
      </v-row>
      <div class="h-[300px]"></div>
      <v-row>
        <v-col cols="10">
          <div class="flex justify-between SPC-MT-400">
            <div></div>
            <div class="flex">
              <app-secondary-button
                :buttonLabel="$t('tablefields.cancel')"
                class="px-2"
                @click-event="reset"
              />
              <app-primary-button
                :buttonLabel="$t('tablefields.save')"
                class="px-2"
                :disabled="disable"
                @click-event="createOrUpdate"
              />
            </div>
          </div>
        </v-col>
      </v-row>

      <app-dialog
        ref="dialogState"
        :title="
          mode === 'edit'
            ? $t('projectConfiguration.editContractTask')
            : $t('projectConfiguration.addContractTask')
        "
        subTitle="xjgjh"
        :width="550"
      >
        <template #content>
          <app-input-field
            :placeholder="$t('projects.contractAddenda.enterTitle')"
            :label="$t('projects.contractAddenda.title') + '*'"
            v-model="title"
          ></app-input-field>

          <div class="mt-[20px]">
            <div class="label py-2">
              <label>{{ $t("taskareas.content") + "*" }}</label>
            </div>
            <QuillEditor
              contentType="text"
              v-model:content="editorContent"
              :options="options"
              :style="editorStyles"
            />
          </div>
        </template>
        <template #actions>
          <v-row class="flex justify-end px-4 mt-1 mb-5">
            <v-col cols="4" class="">
              <app-secondary-button
                :buttonLabel="$t('employees.cancel')"
                @click="hideDialog()"
                class="cursor-pointer"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="4" class="">
              <app-primary-button
                :buttonLabel="
                  mode === 'edit'
                    ? $t('projects.projectBudget.saveChanges')
                    : $t('projectConfiguration.save')
                "
                :disabled="disable"
                @click-event="
                  mode === 'edit' ? editContractTask() : saveContractTask()
                "
              >
              </app-primary-button>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
      <app-dialog
        ref="dialog"
        :title="deleteTitle"
        :subtitle="deleteSubTitle"
        height="302"
        width="408"
      >
        <template #actions>
          <v-row class="px-4 mb-5">
            <v-col cols="6" class="">
              <app-secondary-button
                :buttonLabel="$t('employees.noKeepIt')"
                @click-event="hideDialog()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="6" class="">
              <v-btn
                variant="default"
                min-width="100%"
                min-height="100%"
                class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
                @click="delContractTask"
              >
                <!-- Yes , delete -->
                {{ $t("employees.yesDelete") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </app-dialog>

      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { getTransString } from "@/services/helpers";
import { mapActions, mapGetters } from 'vuex';
import { isEqual } from "lodash";

export default defineComponent({
  name: "ContractApprovers",
  components: {
  },
  props: {

  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      employees: [],
      form: {
        first_approver_id: '',
        second_approver_id: ''
      },
      initialForm: {
        first_approver_id: '',
        second_approver_id: ''
      }
    };
  },

  computed: {
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    ...mapGetters("projectConfigurations", ["getEmployees", "getApprovers", "getError", "getResponse"]),
    disable() {
      return !(this.form.first_approver_id && this.form.second_approver_id)
    }
  },

  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$store.commit("projectConfigurations/SET_CONFIGURATIONS_UPDATE", {
          projectConfiguration: !isEqual(value, this.initialForm),
        });
      },
    },
  },

  methods: {
    ...mapActions("projectConfigurations", ["getAllEmployees", "getAllContractApprovers", "createOrUpdateContractApprovers"]),

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    async createOrUpdate() {
      await this.createOrUpdateContractApprovers(this.form);
      if (this.getResponse?.status === 'success') {
          this.addTitle = "";
          this.alertType = "success";
          this.alertTitle = this.getResponse?.detail;
          this.alertState = true;
      }
      await this.getAllContractApprovers()
      this.form.first_approver_id = this.getApprovers?.first_approver?.employee_id
      this.form.second_approver_id = this.getApprovers?.second_approver?.employee_id
      this.initialForm = {...this.form}
      this.$store.commit("projectConfigurations/SET_CONFIGURATIONS_UPDATE", {
        projectConfiguration: !isEqual(this.form, this.initialForm),
      });
    },

    async reset() {
      await this.getAllContractApprovers()
      this.form.first_approver_id = this.getApprovers?.first_approver?.employee_id
      this.form.second_approver_id = this.getApprovers?.second_approver?.employee_id
    }

  },

  async created() {
      await this.getAllEmployees()
      await this.getAllContractApprovers()

      this.employees = [...this.getEmployees].map(employee => {
      return{
        text: employee?.full_name,
        value: employee?.employeeinformation__employee_id,
      }
    })

  },

  mounted() {

    this.form.first_approver_id = this.getApprovers?.first_approver?.employee_id
    this.form.second_approver_id = this.getApprovers?.second_approver?.employee_id
    this.initialForm.first_approver_id = this.getApprovers?.first_approver?.employee_id
    this.initialForm.second_approver_id = this.getApprovers?.second_approver?.employee_id
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 7);
  }

});
</script>
<style scoped>
.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
</style>
