import Api from "@/services/api";
import { costCentersEndpoint, payingCompany, getFlightDropdowns, getExpertContracts, getFirmExperts, getSingleFlightRequest, getAllFlights,searchFlight,getEmployeeFlightRequests } from "@/services/endpoints";
import { ActionContext } from "vuex";
export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allFlights: [],
    tickets: [
      {
        text: "Ticket by Travel Agency",
        value: "Travel Agency",
      },
      {
        text: "Ticket by ICON",
        value: "ICON",
      },
    ],
    expertContractOptions: [
      {
        text: "Expert with contract",
        value: "Expert with contract",
      },
      {
        text: "Expert without contract",
        value: "Expert without contract",
      },
      {
        text: "Employee",
        value: "Employee",
      },
      {
        text: "Firm with contract",
        value: "Firm with contract",
      },
      {
        text: "Firm without contract",
        value: "Firm without contract",
      },
    ],
    flightTypeOptions: [
      {
        text: "Private",
        value: "Private",
      },
      {
        text: "Project based",
        value: "Project based",
      },
    ],
    taxOptions: [
      {
        text: "None",
        value: 0,
      },
      {
        text: "7%",
        value: 7,
      },
      {
        text: "16%",
        value: 16,
      },
      {
        text: "19%",
        value: 19,
      },
    ],
    isEditFlight: false,
    isEditFlightId: null,
    flightPackage: {},
    flightPackageId: null,
    flightPackageUpdate: {},
    currentFlightRequest: {},
    appLoading: false,
    flightDetailsTable: {},
    requestDetails: {},
    allFlightDropdowns: [],
    allExpertContracts: [],
    allFirmExperts: [],
    allCostCentres: [],
    allPayingCompanies: [],
    
  },
  mutations: {
    SET_ALL_FLIGHTS_TO_STATE(state: any, payload: any) {
      state.allFlights = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
      state.totalData = payload;
    },
    SET_EDIT_FLIGHT_TO_STATE(state: any, payload: any) {
      state.isEditFlight = payload;
    },
    SET_EDIT_FLIGHT_ID_TO_STATE(state: any, payload: any) {
      state.isEditFlightId = payload;
    },
    SET_FLIGHT_PACKAGE_TO_STATE(state: any, payload: any) {
      state.flightPackage = payload;
    },
    SET_FLIGHT_PACKAGE_ID_TO_STATE(state: any, payload: any) {
      state.flightPackageId = payload;
    },
    SET_FLIGHT_PACKAGE_UPDATE(state: any, payload: any) {
      state.flightPackageUpdate = {
        ...state.flightPackageUpdate,
        ...payload,
      };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_FLIGHT_DETAILS_TABLE(state: any, payload: any) {
      state.flightDetailsTable = payload;
    },
    SET_REQUEST_DETAILS(state: any, payload: any) {
      state.requestDetails = payload;
    },
    SET_FLIGHT_DROPDOWNS_TO_STATE(state: any, payload: any) {
      state.allFlightDropdowns = payload;
    },
    SET_EXPERT_CONTRACTS_TO_STATE(state: any, payload: any) {
      state.allExpertContracts = payload;
    },
    SET_FIRM_EXPERTS_TO_STATE(state: any, payload: any) {
      state.allFirmExperts = payload;
    },
    SET_COST_CENTERS_TO_STATE(state: any, payload: any) {
      state.allCostCentres = payload;
    },
    SET_PAYING_COMPANY_TO_STATE(state: any, payload: any) {
      state.allPayingCompanies = payload;
    },
    SET_CURRENT_FLIGHT_REQUEST_TO_STATE (state: any, payload: any){
      state.currentFlightRequest = payload
    }
  },
  getters: {
    isAllFlights(state: any) {
      return state.allFlights;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isTotalData(state: any) {
      return state.totalData;
    },
    isTickets(state: any) {
      return state.tickets;
    },
    isExpertContractOptions(state: any) {
      return state.expertContractOptions;
    },
    isTaxOptions(state: any) {
      return state.taxOptions;
    },
    isFlightTypeOptions(state: any) {
      return state.flightTypeOptions;
    },
    isEditFlight(state: any) {
      return state.isEditFlight;
    },
    isEditFlightId(state: any) {
      return state.isEditFlightId;
    },
    isFlightPackage(state: any) {
      return state.flightPackage;
    },
    isFlightPackageId(state: any) {
      return state.flightPackageId;
    },
    getArrayOfObjects(state: any, key: string, value:any) {
      return Object.entries(state.flightPackage)?.map(([key, value]) => ( { [key]: value })) 
    },
    isFlightPackageUpdate(state: any) {
      return state.flightPackageUpdate;
    },
    isRequestDetails(state: any) {
      return state.requestDetails;
    },
    isFlightDetailsTable(state: any) {
      return state.flightDetailsTable;
    },
    isAppLoading(state: any) {
      return state.appLoading;
    },
    isAllFlightDropdowns(state: any) {
      return state.allFlightDropdowns;
    },
    isAllExpertContracts(state: any) {
      return state.allExpertContracts;
    },
    isAllFirmExperts(state: any) {
      return state.allFirmExperts;
    },
    isAllCostCentres(state: any) {
      return state.allCostCentres;
    },
    isAllPayingCompanies(state: any) {
      return state.allPayingCompanies;
    },
    currentFlightRequest(state:any){
      return state.currentFlightRequest
    }
    
  },
  actions: {
    
    getFlightDropdowns(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(`${getFlightDropdowns}${payload.cost_centre_id}/`, {flight_for_type: payload.flight_for_type})
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_FLIGHT_DROPDOWNS_TO_STATE", response.data.data);
            // context.commit("SET_EXPERT_CONTRACT_OPTIONS_TO_STATE", response.data.data.expert_contract_options);
            // context.commit("SET_TAX_OPTIONS_TO_STATE", response.data.data.tax_options);
            // context.commit("SET_FLIGHT_TYPE_OPTIONS_TO_STATE", response.data.data.flight_type_options);
          }
        })
        .catch();
    },
    getExpertContracts(context: ActionContext<any, any>, payload: any) {  
      Api()
        .get(`${getExpertContracts}${payload}/`, )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_EXPERT_CONTRACTS_TO_STATE", response.data.data);
            // context.commit("SET_EXPERT_CONTRACT_OPTIONS_TO_STATE", response.data.data.expert_contract_options);
            // context.commit("SET_TAX_OPTIONS_TO_STATE", response.data.data.tax_options);
            // context.commit("SET_FLIGHT_TYPE_OPTIONS_TO_STATE", response.data.data.flight_type_options);
          }
        })
        .catch();
    },
    getFirmExperts(context: ActionContext<any, any>, payload: any) {  
      Api()
        .get(`${getFirmExperts}${payload}/`, )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_FIRM_EXPERTS_TO_STATE", response.data.data);
            // context.commit("SET_EXPERT_CONTRACT_OPTIONS_TO_STATE", response.data.data.expert_contract_options);
            // context.commit("SET_TAX_OPTIONS_TO_STATE", response.data.data.tax_options);
            // context.commit("SET_FLIGHT_TYPE_OPTIONS_TO_STATE", response.data.data.flight_type_options);
          }
        })
        .catch();
    },
    getCostCenters(context: ActionContext<any, any>) {  
      Api()
        .get(`${costCentersEndpoint}` )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_COST_CENTERS_TO_STATE", response.data.data);
          }
        })
        .catch();
    },
    getPayingCompanies(context: ActionContext<any, any>){
      Api()
      .get(`${payingCompany}` )
      .then((response) => {
        if (response.data.status === "success") {
          context.commit("SET_PAYING_COMPANY_TO_STATE", response.data.data);
        }
      })
      .catch();
    },
    getSingleFlight(context: ActionContext<any, any>, payload:any) {
      if(payload.load_page){
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
     
      Api()
        .get(`${getSingleFlightRequest}${context.getters.isEditFlightId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_CURRENT_FLIGHT_REQUEST_TO_STATE", response.data.data);
          }
        })
        .catch((error) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            
          }
        });
    },
    getAllFlightRequest(context: ActionContext<any, any>, payload:any){
      if(payload.load_page){
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(`${getAllFlights}${payload.page_number}/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            const responseData = response.data.data;
            const modifiedData = responseData
            context.commit(
              "SET_ALL_FLIGHTS_TO_STATE",
              modifiedData
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_pages
            );
          }
        })
        .catch(() => {
          context.commit("SET_APP_LOADING_TO_STATE", false);
        });
    },
    searchFlightRequest(context: ActionContext<any, any>, payload:any){
      context.commit("SET_APP_LOADING_TO_STATE", false);
      Api()
      .post(`${searchFlight}${context.getters.isCurrentPage}/`, {search_query: payload})
      .then(response => {
        if (response.data.status === "success") {
          context.commit("SET_APP_LOADING_TO_STATE", false);
            const responseData = response.data.data;
        
            context.commit(
              "SET_ALL_FLIGHTS_TO_STATE",
              responseData
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_pages
            );
          }
      })
      .catch()
    },
    getEmployeeFlights(context: ActionContext<any, any>, payload:any) {
      if(payload.load_page){
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getEmployeeFlightRequests}${payload.page_number}/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            const responseData = response.data.data;
            
            context.commit(
              "SET_ALL_FLIGHTS_TO_STATE",
              responseData
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_pages
            );
          }
        })
        .catch(() => {
          context.commit("SET_APP_LOADING_TO_STATE", false);
        });
    },
  },
};
