<template>
  <section>
    <div class="w-full">
      <!-- Start coding here -->
      <div
        class="relative overflow-hidden bg-white divide-y divide-gray-200 tablecard dark:bg-gray-800 sm:rounded-lg"
      >
        <div
          class="flex flex-col items-center justify-between p-3 px-6 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
        >
          <div class="w-1/2 py-2 text-2xl">
            <div class="flex items-center justify-between">
              <h3 class="H500">
                {{ $t("requestFundsTab.funds") }}
              </h3>
            </div>
          </div>
          <div
            class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3"
          ></div>
        </div>

        <div class="overflow-x-auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            aria-describedby=""
          >
            <thead class="bg-white">
              <tr style="height: 40px">
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-center border-b H100"
                  colspan="5"
                >
                  {{ $t("requestFundsTab.funds") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-center border-b border-l H100"
                  colspan="2"
                >
                  {{ $t("requestFundsTab.expenses") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-center border-b border-l H100"
                  colspan="3"
                >
                  {{ $t("requestFundsTab.liquidity") }}
                </th>
                <th
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-center border-b border-l H100"
                  colspan="1"
                >
                </th>
                <th
                  v-if="!hideInput"
                  scope="col"
                  class="px-5 text-base !text-[#344054] !font-medium text-center border-b border-l H100"
                  colspan="1"
                >
                  {{ $t("requestFundsTab.requestFund") }}
                </th>
              </tr>
              <tr style="height: 40px">
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b"
                >
                  {{ $t("requestFundsTab.planned") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.requested") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.approved") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.remaining") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.monthly") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.logged") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.approved") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.cash") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.bank") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.total") }}
                </th>
                <th
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.cheques") }}
                </th>
                <th
                  v-if="!hideInput"
                  scope="col"
                  class="px-4 text-base !text-[#344054] !font-medium text-right border-b border-l"
                >
                  {{ $t("requestFundsTab.amount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 50px">
                <td class="text-right pr-4 border-b">
                  {{ getLanguageCurrency(overViewData?.plan_funds) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.requested_funds) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.approved_funds) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.remaining_funds) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.monthly_funds) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.logged_expenses) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.approval_expenses) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.liquidity_cash) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.liquidity_bank) }}
                </td>
                <td class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.liquidity_total) }}
                </td>
                <td  class="text-right pr-4 border-b border-l">
                  {{ getLanguageCurrency(overViewData?.cheques) }}
                </td>
                <td v-if="!hideInput" class="border-b border-l pa-0">
                  <input
                    class="w-full text-right h-[50px] py-2 px-4 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
                    type="text"
                    :disabled="disabled"
                    pattern="^[1-9]\d*$"
                    :placeholder="$t('requestFundsTab.enterAmount')"
                    :value="
                      disabled
                        ? getLanguageCurrency(
                            overViewData?.total_requested_amount
                          )
                        : overViewData?.total_requested_amount
                    "
                    v-if="disabled"
                  />
                  <input
                    v-else
                    class="w-full text-right h-[50px] py-2 px-3 focus:border-[#FDC6C2] focus:outline-[#FDC6C2] focus:ring-[none]"
                    type="text"
                    :disabled="disabled"
                    pattern="^[1-9]\d*$"
                    :placeholder="$t('requestFundsTab.enterAmount')"
                   
                    @input="handleInputData($event, overViewData?.remaining_funds || 0)"
                    v-model="inputValue"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { currencyFormatter } from "@/services/helpers";
import { mapState, mapGetters } from "vuex";

export default defineComponent({
  name: "FundsTable",
  props: {
    mode: {
      type: String,
      default: "view",
    },
    overViewData: {
      type: Object,
      default: () => {},
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputValue: "",
    };
  },
  computed: {
    ...mapGetters("pmLocalRequestFund", [
      "getIsFundTable",
      "getRequestedFundsTotalAmountForFundTableInput"
    ]),
    disabled() {
      if (this.mode === "view") {
        return true;
      } else {
        return false;
      }
    },
    appLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),
  },
  watch: {
    getIsFundTable: {
      handler: function (val) {
        if (!val) {
          this.clearInput();
        }
      },
      deep: true,
    },
    getRequestedFundsTotalAmountForFundTableInput: {
      handler: function (val) {
        if (val) {
          this.inputValue = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    getLanguageCurrency(amount) {
      return this.appLang === "de"
        ? this.germanCurrency(amount)
        : amount?.formatCurrency();
    },

    germanCurrency(curr) {
      return currencyFormatter(curr);
    },
    handleInputData($event, remaining_amount) {
      let amount = $event.target.value || 0;

      if (isNaN(parseFloat(amount))) {
        $event.target.value = 0;
      }

      let requested_amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount);

      let tr = $event.target.parentNode.parentNode;
      if (requested_amount.toFixed(2) > remaining_amount) {
        tr?.classList.add("red-border");
      }else{
        tr?.classList.remove("red-border");
      }
      //persist the requested amount to the store
      this.$store.commit("pmLocalRequestFund/ASSIGN_REQUESTED_AMOUNT_TO_FUND", {
        requested_amount: requested_amount,
      });
      this.$store.commit(
        "pmLocalRequestFund/CLEAN_REQUESTED_FUNDS_BODY", false
      );
      this.$store.commit(
        "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_PROJECT_FUNDS",
        {
          budget_item: null,
          requested_amount: requested_amount,
          general_fund_request: true,
        }
      );
      this.$store.commit(
        "pmLocalRequestFund/SET_REQUESTED_AMOUNT_TOTAL_TO_FUND_TABLE_ENTERED_VALUE",
        requested_amount
      );
    },
    clearInput() {
        this.inputValue = '';
    },
  },
  created() {},
  mounted() {},
});
</script>

<style scoped>
table {
  color: #344054;
}
tr > th {
  background-color: #f2f4f7;
}
tbody tr:nth-of-type(even) {
  background-color: #f9fafb;
}

td {
  padding-left: 24px;
}
tr {
  padding: 16px 24px;
  height: 56px;
  width: 100%;
}
.tablecard {
  border: 2px solid #eaecf0;
}

.red-border {
  border: 2px solid #fdc6c2;
}
</style>
