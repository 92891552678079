<template>
  <v-sheet class="bg-transparent">
    <!-- dialog for deleting cost center -->
    <app-dialog
      ref="isDeleteDialog"
      :title="$t('projectConfiguration.deleteCostCenter')"
      :subtitle="$t('projectConfiguration.deleteCostCenterSubtitle')"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteCostCenter()"
            >
              {{ $t("projectConfiguration.delete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import { getTransString } from "@/services/helpers"
import Api from "@/services/api";

export default defineComponent({
    name: "Delete Cost Centers",
    components: {
  },
  data(){
    return{
      isDelete: false,
      title: "",
      subTitle: "",
      alertTitle: '',
      alertType: "success",
      alertState: false,
      payload: {},

    }
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showDialog() {
        this.isDelete = true;
        this.cancelLabel = "generalConfig.cancelLabel";
        this.title = "generalConfig.delete";
        this.subTitle1 = "generalConfig.firstSubTitle";
        this.subTitle2 = "generalConfig.secondSubTitle";
        this.$refs.isDeleteDialog.showDialog = true;
    },

    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
    },
    deleteCostCenter() {
      Api()
        .delete(`/configuration/projects/delete-cost-center/${this.costCenterID}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = response?.data?.detail;
            this.$store.dispatch('hrConfigurations/getAllCostCenters', 1)

            this.hideDeleteDialog()
          }
          if (response.data.status === "error") {
          this.hideDeleteDialog()
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
              this.hideDeleteDialog()
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

},

})
</script>
