<template>
  <div class="filter_parent SPC-MT-100">
    <div class="SPC-MT-400">
      <app-table
        :showCursor="false"
        :showEditPage="false"
        :headers="tableHeaders"
        :items="userDocuments"
        :showCheck="false"
        :hideMenu="!canEdit"
        @edit-event="showDialog({ $event, data: 'edit' })"
        @delete-event="showDeleteDialog({ $event, data: 'delete' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("documents.allDocuments") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            :buttonLabel="$t('documents.addDocument')"
            @click="showAddFileDialog"
            :disabled="!isEditButton"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
      <app-document-upload
        :showUploadOnEdit="true"
        @url-from-file="handleFileUrl"
        @get-data="getAllDocuments"
        @reset-dialog-status="isEdit = false"
        ref="appFileUpload"
        :show-file-type="false"
        :is-edit="isEdit"
        :edit-object-prop="updateFileData"
        :title="$t('documents.addDocument')"
      ></app-document-upload>
    </div>
    <!-- dialog for deleting config types -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="mb-5 mt-1 px-4">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click-event="hideDeleteDialog()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deletePublicHoliday()"
            >
              {{ $t("accessrole.delete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <app-alert
      :title="$t(this.getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import api from "@/services/api";
import {
  getUserDocumentsUrl,
  manageUserDocumentsUrl,
} from "@/services/endpoints";

export default defineComponent({
  name: "Documents",
  components: {
    Paginator,
  },
  data() {
    return {
      countryOptions: null,
      tableHeaders: ["Title", "Date Uploaded"],
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      buttonLabel: "Add",
      label: "Title",
      currentFile: {},
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      end_date: "",
      start_date: "",
      addTitle: "",
      modelValue: "",
      fileSize: "",
      fileTitle: "",
      fileUrl: "",
      fileId: 0,
      fileName: "",
      items: null,
      loading: false,
      currentPages: 1,
      newPassword: "",
      confirmPassword: "",
      errorPasswordMessage: "",
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      errorConfirmPassword: "",
      updateFileData: {},
    };
  },
  watch: {},
  computed: {
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "User Documents"
      );
      return findModules?.length !== 0;
    },
    userDocuments() {
      return this.$store.getters["hrResources/isUserDocuments"];
    },
    currentPage() {
      return this.$store.getters["hrResources/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["hrResources/isTotalPages"];
    },
    userKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"] && this.canEdit;
    },
    currentPath() {
      return this.$route.path;
    },
  },

  methods: {
    setResponseData(response) {
      const payload = response.data.data.reduce((prev, curr) => {
        return [
          ...prev,
          {
            title: curr.title,
            "date uploaded": curr.date_uploaded,
            id: curr.id,
          },
        ];
      }, []);

      this.$store.commit("hrResources/SET_USER_DOCUMENTS_TO_STATE", payload);
      this.$store.commit(
        "hrResources/SET_CURRENT_PAGE_TO_STATE",
        response.data.current_page
      );
      this.$store.commit(
        "hrResources/SET_TOTAL_PAGES_TO_STATE",
        response.data.total_data
      );
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    combineTranslation(...args) {
      return args.reduce((acc, curr) => acc + " " + curr, "");
    },
    getAlertMessage(configType, alertKeyTextTrans, alertText) {
      if (this.alertType === "success") {
        return this.combineTranslation(configType, alertKeyTextTrans);
      } else {
        return alertText;
      }
    },
    showDialog(data) {
      this.isEdit = false;
      this.jobID = data?.$event?.value;
      this.modelValue = data?.$event?.title;
      if (data.data === "add") {
        this.title = "generalConfig.add";
        this.$refs.dialog.showDialog = true;
      } else if (data.data === "edit") {
        this.title = "generalConfig.add";
        this.isEdit = true;
        this.getSingleFile(data.$event.id);
      }
    },
    getSingleFile(id) {
      api()
        .get(`${manageUserDocumentsUrl}${id}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response?.data?.data;
            this.$refs.appFileUpload.showDialog(data);
          }
        })
        .catch();
    },
    showDeleteDialog(data) {
      if (data.data === "delete") {
        this.currentFile = data?.$event;
        this.isDelete = true;
        this.cancelLabel = "generalConfig.cancelLabel";
        this.title = "projects.deleteFile";
        this.subTitle = "projects.deleteFileConfirm";
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      setTimeout(() => {
        this.isDelete = false;
        this.confirmDelete = null;
        this.subTitle = "";
      }, 600);
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.addTitle = "";
        this.isEdit = false;
        this.isAdd = false;
        this.isDelete = false;
        this.subTitle = "";
        this.buttonLabel = "generalConfig.add";
        this.label = "generalConfig.title";
        this.cancelLabel = "generalConfig.cancel";
      }, 600);
    },
    fetchAgain(page) {
      // const page = this.currentPage + 1;
      this.getAllPublicHolidays(page);
    },
    handleFileUrl(event) {
      this.project_files = [...event];
      if (this.project_files.length > 0) {
        this.disableFileSaveButton = false;
      } else {
        this.disableFileSaveButton = true;
        this.isLoading = false;
      }
    },
    updatePublicHolidays() {
      const payload = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (this.currentFile.title !== this.addTitle) {
        payload.title = this.addTitle;
      }
      Api()
        .patch(
          `/configuration/public-holiday/update-public-holiday/${this.currentFile.id}/`,
          payload
        )
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "Public holiday updated successfully";
            this.getAllPublicHolidays(this.currentPage);
            this.end_date = null;
            this.start_date = null;
            this.currentFile = {};
            this.addTitle = "";
            this.$refs.dialog.showDialog = false;
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    deletePublicHoliday() {
      Api()
        .delete(`${manageUserDocumentsUrl}${this.currentFile.id}/`)
        .then((response) => {
          if (response) {
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = "User document deleted";
            this.getAllDocuments(this.currentPage);
            this.currentFile = {};
            this.addTitle = "";
            this.hideDeleteDialog();
          }
          if (response.data.status === "error") {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.subTitle = "";
            this.alertType = "error";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs.dialog.showDialog = false;
            this.addTitle = "";
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllDocuments(page) {
      Api()
        .get(`${getUserDocumentsUrl}${this.userKey}/${page}/`)
        .then((response) => {
          if (response.data.status === "success") {
            // this.headers = response.data.headers;
            this.setResponseData(response.data);
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllDocuments(page);
    },
    showAddFileDialog() {
      this.isEdit = false;
      this.$refs.appFileUpload.showDialog();
    },
  },
  created() {
    this.getAllDocuments(this.currentPage);
    if (this.currentPath === "/add-expert") {
      this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", true);
    }
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 3);
  },
});
</script>

<style scoped>
label {
  color: #344054;
}
</style>
