<template>
  <div
    v-if="getAppLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <v-sheet max-width="" class="bg-transparent mt-n5 mb-7">
      <v-row class="justify-space-between">
        <v-col>
          <div
            class="flex items-center h-full text-xl font-semibold leading-7 text-left text-gray-900 font-inter"
          >
            {{ $t("requestFundsTab.period") }}
          </div>
        </v-col>
        <v-col cols="2">
          <div></div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <app-date-field
            :label="$t('leaves.startDate')"
            type="date"
            v-model="startDate"
            :disabled="!isEditButton"
            :min="startDate"
            :max="endDate"
          >
          </app-date-field>
        </v-col>
        <v-col cols="4">
          <app-date-field
            :label="$t('leaves.endDate')"
            type="date"
            v-model="endDate"
            :disabled="!isEditButton"
            :min="startDate"
            :max="endDate"
          >
          </app-date-field>
        </v-col>
      </v-row>

      <v-row class="pa-0 mt-7">
        <v-col>
          <div>
            <funds-table
              mode="add"
              :overViewData="getRequestedFundsStats.funds"
            ></funds-table>
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-0 mt-7">
        <v-col>
          <div>
            <budget-item-table
              mode="add"
              :data="getRequestedFundsStats.request_items"
              :tableStats="tableStats"
            ></budget-item-table>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-end pa-0 mt-7">
        <v-col cols="1">
          <div>
            <app-secondary-button
              :buttonLabel="$t('education.cancel')"
              @click="hideTab()"
            >
            </app-secondary-button>
          </div>
        </v-col>
        <v-col cols="1">
          <div>
            <app-primary-button
              :buttonLabel="$t('requestFundsTab.submit')"
              @click="showDialog"
            >
            </app-primary-button>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <app-dialog
        ref="dialogState"
        :title="
        $t('requestFundsTab.requestFund')
        "
        subTitle="xjgjh"
        :width="1200"
    >
        <template #content>
            <div>
                <v-sheet max-width="" class="bg-transparent">
                    <v-row>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.costCenter') }}</v-col>
                        <v-col cols="10" class="text-sm  text-[#344054]">: {{ getRequestedFundsStatsModalData?.cost_centre }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.title') }}</v-col>
                        <v-col cols="10" class="text-sm  text-[#344054]">: {{ getRequestedFundsStatsModalData?.project_title }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.location') }}</v-col>
                        <v-col cols="10" class="text-sm  text-[#344054]">: {{ getRequestedFundsStatsModalData?.location?.country }}, {{ getRequestedFundsStatsModalData?.location?.place }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.period') }}</v-col>
                        <v-col cols="10" class="text-sm  text-[#344054]">: {{ formatDate(startDate) }} - {{ formatDate( endDate) }}</v-col>
                    </v-row>

                    <v-row>
                        <v-col > 
                            <funds-table v-if="getIsFundTable" mode="view" :overViewData="getRequestedFundsStats.funds"></funds-table>  
                            <budget-item-table v-else  mode="view" :data="requestItems" :tableStats="tableStats"></budget-item-table>  
                        </v-col>
                    
                    </v-row>

                    <v-row class="pa-0 mt-4">
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.accountHolder') }}</v-col>
                        <v-col cols="3" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.account_title }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.bank') }}</v-col>
                        <v-col cols="5" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.bank_name }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.cityOfBank') }}</v-col>
                        <v-col cols="3" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.bank_address }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">BIC/SWIFT</v-col>
                        <v-col cols="5" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.swift_code }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">{{ $t('requestFundsTab.accountNumber') }}</v-col>
                        <v-col cols="3" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.account_number }}</v-col>
                        <v-col cols="2" class="text-sm font-semibold">IBAN</v-col>
                        <v-col cols="5" class="text-sm text-[#344054]">: {{ getRequestedFundsStatsModalData?.bank?.iban }}</v-col>
                    </v-row>
                    <v-row class="pa-0 mt-4">
                        <v-col cols="4">
                            <div>     
                                <div>
                                    <div class="flex flex-col items-left mt-0">
                                        <div
                                            class="h-[60px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left items-center bg-white"
                                        >
                                            <img
                                            :src="imageUrl"
                                            alt=""
                                            class="max-h-[60px] min-w-[100px] max-w-[200px]"
                                            v-if="showSignature"
                                            />
                                            <p @click="signOrUpload" class="text-[#8B2923] text-sm cursor-pointer" v-if="!showSignature" >
                                            {{ $t("requestFundsTab.clickToSign") }}
                                            </p>
                                        </div>
                                    </div>
                                    <input type="file"  
                                    @change="handleFileUpload"
                                    style="display: none"
                                    ref="fileInput"
                                    accept="image/*">
                                    
                                </div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[30px]  w-2/4 p-0 flex justify-left mt-2">{{ getRequestedFundsStatsModalData?.requester_signature?.title }} {{ getRequestedFundsStatsModalData?.requester_signature?.last_name }} {{ getRequestedFundsStatsModalData?.requester_signature?.first_name }}</div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[40px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left"><input class="w-full" type="date"></div>        
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div>     
                                <div>
                                    <div class="flex flex-col items-left mt-0">
                                        <div
                                            class="h-[60px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left items-center bg-white"
                                        > </div>
                                    </div>
                                    
                                </div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[30px]  w-2/4 p-0 flex justify-left mt-2">{{ getRequestedFundsStatsModalData?.offer_controller?.title }} {{ getRequestedFundsStatsModalData?.offer_controller?.last_name }} {{ getRequestedFundsStatsModalData?.offer_controller?.first_name }}</div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[40px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left"><input class="w-full" type="date"></div>        
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div>     
                                <div>
                                    <div class="flex flex-col items-left mt-0">
                                        <div
                                            class="h-[60px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left items-center bg-white"
                                        > </div>
                                    </div>
                                    
                                </div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[30px]  w-2/4 p-0 flex justify-left mt-2">{{ getRequestedFundsStatsModalData?.project_manager?.title }} {{ getRequestedFundsStatsModalData?.project_manager?.last_name }} {{ getRequestedFundsStatsModalData?.project_manager?.first_name }}</div> 
                                <div class="text-gray-700 text-sm font-semibold leading-7 h-[40px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left"><input class="w-full" type="date"></div>        
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-sheet>
            </div>
        </template>
        <template #actions>
            <v-row class="flex justify-end px-4 mt-1 mb-5">
                <v-col cols="1" class="">
                    <app-secondary-button
                        :buttonLabel="$t('employees.cancel')"
                        @click="hideDialog()"
                        class="cursor-pointer"
                    >
                    </app-secondary-button>
                </v-col>
                <v-col cols="2" class="">
                    <app-primary-button
                        :buttonLabel="$t('requestFundsTab.sendforApproval')"
                        @click="submit"
                        class="cursor-pointer"
                    >
                    </app-primary-button>
                </v-col>
            </v-row>
        </template>
    </app-dialog>
    <app-dialog
        ref="dialogUploadState"
        width="586"
        height="800"
        :title="$t('contracts.cropAnduploadSiganture')"
        :subtitle="``"
    >
        <template #content>
            <VueCropper
                v-if="selectedFile"
                ref="cropper"
                :src="imageUrl"
                view-mode="1"
                :guides="true"
                :auto-crop="true"
                :auto-crop-area="0.5"
                :movable="true"
                :zoomable="true"
                :drag-mode="'crop'"
                :data="{width: 400, height: 400}"
            ></VueCropper>
        </template>
        <template #actions>
            <v-row class="px-4 mt-1 mb-5">
                <v-col cols="6">
                    <app-secondary-button
                        :buttonLabel="$t('projects.cancel')"
                        @click-event="cancelUpload()"
                    >
                    </app-secondary-button>
                </v-col>
                <v-col cols="6">
                    <app-primary-button
                        :buttonLabel="$t('contracts.uploadSignature')"
                        :loading="isLoading"
                        @click-event="cropImage()"
                    >
                    </app-primary-button>
                </v-col>
            </v-row>
        </template>
    </app-dialog>
    <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
    />
</div>
</template>

<script>
import { defineComponent } from "vue";
import FundsTable from "./requestedFunds/FundsTable.vue";
import BudgetItemTable from "./requestedFunds/BudgetItemTable.vue";
import { mapActions, mapGetters } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { getTransString } from "@/services/helpers";
import API from "@/services/api";
import { uploadEmployeeSignature } from "@/services/endpoints";
import { isEqual } from 'lodash';

export default defineComponent({
  name: "RequestNewFund",
  components: {
    FundsTable,
    BudgetItemTable,
    VueCropper,
  },
  data() {
    return {
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      dialogState: true,
      dialogUploadState: false,
      imageUrl: null,
      selectedFile: null,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      showSignature: false,
      initialRequestedFundsBody: {
        start_date: null,
        end_date: null,
        project_funds:[],
        requester_signature: null,
      },
    };
  },
  computed: {
    ...mapGetters("pmLocalRequestFund", [
      "getAppLoading",
      "getRequestedFundsStats",
      "getIsFundTable",
      "getRequestedFundsBody",
    ]),
    getRequestedFundsResponse() {
      return this.$store.getters["pmLocalRequestFund/getResponse"];
    },
    getRequestedFundsStatsModalData() {
      return this.getRequestedFundsStats?.model_data;
    },
    signature() {
      return this.getRequestedFundsStatsModalData?.requester_signature
        ?.signature;
    },
    getRequestedFundsError() {
      return this.$store.getters["pmLocalRequestFund/getError"];
    },
    foreignEstablishment() {
      return this.$store.getters["foreignOfficeModule/isOfficeID"];
    },
    tableStats() {
      return {
        total_planned_amount: this.getRequestedFundsStats.total_planned_amount,
        total_remaining_amount:
          this.getRequestedFundsStats.total_ramaining_amount,
        total_requested_amount:
          this.getRequestedFundsStats.total_requested_amount,
      };
    },
    requestItems() {
      return this.getRequestedFundsStats.request_items;
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isContractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },

  },
  watch: {
    startDate: {
      handler: function (val) {
        this.$store.commit(
          "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_START_DATE",
          val
        );
      },
      deep: true,
    },
    endDate: {
      handler: function (val) {
        this.$store.commit(
          "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_END_DATE",
          val
        );
      },
      deep: true,
    },
    getRequestedFundsBody: {
      handler: function (val) {
          this.$store.commit("pmLocalModules/SET_PM_LOCAL_UPDATE", {
            "RequestNewFund": !isEqual(val, this.initialRequestedFundsBody)
          });

      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("pmLocalRequestFund", [
      "fetchRequestedFundStats",
      "saveRequestedFund",
    ]),
    hideTab() {
      this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 6);
    },

    async submit() {
      try {
        await this.saveRequestedFund({
          foreignEstablishment: this.foreignEstablishment,
        });
        this.alertType = this.getRequestedFundsResponse.status;
        this.alertState = true;
        this.alertTitle = this.getRequestedFundsResponse.detail;

        if ("data" in this.getRequestedFundsError) {
          this.alertType = "error";

          if ("requester_signature" in this.getRequestedFundsError.data) {
            this.alertTitle =
              "Your Signature is required to submit the request.";
          }

          if ("start_date" in this.getRequestedFundsError.data) {
            this.alertTitle = "Please select a start date";
          }

          if ("end_date" in this.getRequestedFundsError.data) {
            this.alertTitle = "Please select an end date";
          }

          if ("status" in this.getRequestedFundsError.data) {
              this.alertTitle = this.getRequestedFundsError.data.detail;
          }
          return;
        }
        this.$store.commit("pmLocalRequestFund/RE_SET_REQUESTED_FUNDS_BODY");

        setTimeout(() => {
          this.$store.commit("pmLocalModules/SET_SHOW_REQUEST_NEW_FUND", false);
          this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 6);
        }, 500);
      } catch (error) {
        this.alertType = "error";
        // this.alertState = !this.alertState;
        // this.alertTitle = error?.message;
      }
    },
    showDialog() {
      this.$refs.dialogState.showDialog = true;
      this.$store.commit(
        "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE",
        ""
      );
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.imageUrl = null;
      this.showSignature = false;
    //   this.$store.commit("pmLocalRequestFund/RE_SET_REQUESTED_FUNDS_BODY");
    //   this.$store.commit(
    //     "pmLocalRequestFund/REMOVE_REQUESTED_AMOUNTS_IN_BUDGET_ITEMS"
    //   );
    //   this.startDate = new Date().toISOString().slice(0, 10)
    //   this.endDate = new Date().toISOString().slice(0, 10)
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    openFileInput() {
      this.showSignature = true;
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const validateImageType = ["image/jpeg", "image/png", "image/jpg"];
      if (!validateImageType.includes(file.type)) {
        this.alertType = "error";
        this.alertState = true;
        this.alertTitle = "Please upload a valid image file.";
        return;
      }
      this.selectedFile = file;
      this.imageUrl = URL.createObjectURL(file);
      // this.uploadImage();
      this.$refs.dialogUploadState.showDialog = true;
    },
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.selectedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        this.uploadImage();
      }, "image/jpeg");
      this.$refs.dialogUploadState.showDialog = false;
    },
    clearImage() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogUploadState.showDialog = false;
      this.deleteSignature();
    },
    cancelUpload() {
      this.imageUrl = null;
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
      this.$refs.dialogUploadState.showDialog = false;
    },
    uploadImage() {
      if (!this.selectedFile) {
        alert("Please select an image to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("signature", this.selectedFile);
      API()
        .post(`${uploadEmployeeSignature}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            await this.fetchRequestedFundStats({
              foreignEstablishment: this.foreignEstablishment,
            });

            this.$store.commit(
              "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE",
              this.signature
            );
          }
        })
        .catch((error) => {
          this.alertType = "error";
          this.alertState = true;
          this.alertTitle = "Error uploading image. Please try again.";
        });
    },
    formatDate(inputDate) {
      if (!inputDate) {
          return "";
      }
      // Parse the input date
      const dateParts = inputDate?.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);

      // Format the date as "dd.mm.yyyy"
      const formattedDate = `${day}.${month}.${year}`;

      return formattedDate;
    },
    signOrUpload() {
      if (this.signature) {
        this.imageUrl = this.signature;
        this.$store.commit(
          "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE",
          this.signature
        );
        this.showSignature = true;
      } else {
        this.openFileInput();
      }
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 7); //9
    this.$store.commit("pmLocalRequestFund/RE_SET_REQUESTED_FUNDS_BODY");
  },
  async mounted() {
    await this.fetchRequestedFundStats({
      foreignEstablishment: this.foreignEstablishment,
    });

    //request fund within project start and end date
    this.startDate = this.isContractDetails.project__project_start_date || new Date().toISOString().slice(0, 10);
    this.endDate = this.isContractDetails.project__project_end_date || new Date().toISOString().slice(0, 10);

    this.initialRequestedFundsBody.start_date = this.startDate;
    this.initialRequestedFundsBody.end_date = this.endDate;
    this.$store.commit(
      "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_START_DATE",
      this.startDate
    );
    this.$store.commit(
      "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_END_DATE",
      this.endDate
    );
  },
});
</script>
<style scoped></style>
