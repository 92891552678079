const documentData= `
<h3>* Between ICON-INSTITUT Sustain GmbH, Von-Groote-Straße 28, 50968 Köln Hereinafter named ICON and Jael Blair, Caldwell, Netherlands, the following contracts will be concluded:</h3>
<h2>Subject-Matter of the Contract</h2><br>
<h3>* [expert name] will work as [function] in the Project [projectName]</h3><br>
<h3>* Reference No.:[contract ID]</h3><br>
<h3>* Contracting Authority: [contracting authority from the project creation form],[project_id]</h3><br>
<h3 class="py-2">The following Annexes form an integral part of the contract: [integral]</h3><br>
<h3>- ToR (Consultant) [tor]</h3><br>
<h3>- Timesheets  [timesheet]</h3><br>
<h3>- Mission-Plan [mission]</h3><br>

<h2>Tasks</h2><br>
<h3>[number of working days] In her function as [function], [expert name]  will be responsible for the overall management of the project and the coordination of the project activities. [expert name] will be also responsible for the coordination of the Expert Team as well as for the quality control, the compilation and the timely submission of the reports. [expert name] contributes to all Ad-Hoc Reports and Strategies as well as to any additional Ad Hoc Report which might be requested by the Beneficiary and/or ICON. The Inputs to the Reports and the dates of submission have to be preliminary discussed and agreed upon with ICON. All tasks have to be executed in accordance with the Terms of Reference (ToR-Consultant) [function].</h3><br>
<h3>In total 8 Quarterly Progress Report [individual deliverable title] will  be produced.</h3><br>
<h3>One Draft Version of the Final Report [individual deliverable title] will  be produced. </h3><br>
<h3>One Final Version of the Final Report [individual deliverable title] will be  produced.</h3><br>

<h3>One Short Version of the Final Report [individual deliverable title] will be  produced.</h3><br>
<h3>Development of a Communication and Awareness  Plan [individual deliverable title].</h3><br>
<h3>In total 15 Monthly Reports will be produced.</h3><br>
<h3>[expert name] is committed to keep timesheets. The timesheets will be signed by [expert name] and will be handed over to ICON in the original. The timesheets serve as a proof that the services have been rendered as agreed upon in this contract and have to be signed by ICON. The acknowledgement of travel-days, public holidays and weekends as working-days requires an individual authorisation.</h3><br>
<h3>All tasks have to be executed in accordance with the Terms of Reference (ToR-Consultant). All documents produced by [expert name] [expert name] will be written in  [language] and be submitted to ICON in electronic form [deliverable mode of submission]. The submission dates have to be agreed with GIZ/ICON. The documents have to be accepted by [submited to eg.client].</h3><br>

<h2>Place of Performance [performance] </h2><br>
<h3>[country to where the expect will travel to] </h3><br>

<h2>Duration of the Contract and of the Missions</h2><br>
<h3>This contract is a framework contract which is completed by detailed mission plans. This contract will take effect as [contract start date] and ends on [contract end date].The dates for the Missions will be agreed upon with [expert name] will be included in the Mission Plans. The Mission Plans form an integral part of this contract.</h3><br>

<h2>Fixing of Price </h2><br>
<h3>For the services rendered, [expert name]  will be paid a fee of [fees] per working day for a maximum of [number of working days] working days.</h3><br>
<h3> [expert name] obtains a fixed fee for  </h3><br>
<h3>- 8 Quarterly Project Progress Reports in the amount of each [EUR]  [fixed price per month/day] (in total [EUR] 20.160)</h3><br>
<h3>- Draft Version of the Final Report [individual deliverable title] in the amount of [EUR]  [fixed price per month/day]</h3><br>
<h3>- Short Version of the Final Report [individual deliverable title] in the amount of [EUR]   [fixed price per month/day]</h3><br>

<h3>-Development of a Communication and Awareness Plan [individual deliverable title] in the  amount of [EUR] [fixed price per month/day]</h3><br>
<h3>-15 [Report for travel] Monthly Reports in the amount [individual deliverable title] of each [EUR]   [fixed price per month/day] (in total [EUR] 43.200)</h3><br>

<h3>- Any other expenses are considered to have been included in the fees (i.e. travel costs, tickets, per diems, taxes etc.) [only show if the contract involves travel].</h3>

<h2> The Use of Documents and Results</h2><br>
<h3> [expert name] designates a non-revocable, transferable and non-exclusive right of use and enjoyment to ICON in the fulfilment of this contract concerning all domestic and international patent (or equivalent) rights, inventions, design documents, processes and other documentation which result from the project and the contract to ICON.</h3><br>
<h3> All project-related documentations and results may not be given to any third party by [expert name].Exceptions are only possible with prior written permission of ICON.</h3><br>
<h3> All project-related documentations and results may not be given to any third party by [expert name].Exceptions are only possible with prior written permission of ICON.</h3><br>


<h3>In total 15 Monthly Reports will be produced.</h3><br>
<h3>[expert name] is committed to keep timesheets. The timesheets will be signed by [expert name] and will be handed over to ICON in the original. The timesheets serve as a proof that the services have been rendered as agreed upon in this contract and have to be signed by ICON. The acknowledgement of travel-days, public holidays and weekends as working-days requires an individual authorisation.</h3><br>
<h3>All tasks have to be executed in accordance with the Terms of Reference (ToR-Consultant). All documents produced by [expert name] will be written in English and be submitted to ICON in electronic form. The submission dates have to be agreed with GIZ/ICON. The documents have to be accepted by ICON.</h3><br>

<h2>General Terms and Conditions of Contract of the ICON-INSTITUTE Consulting Gruppe</h2><br>
<h3>The General Terms and Conditions of Contract of the ICON-INSTITUTE Consulting Gruppe in the Version of the [edit this section] are added to this Contract as an Annex and form an integral part of the Contract. [expert name] hereby declares that he has taken note of the General Terms and Conditions of Contract.</h3><br>

<h2>Explanation</h2><br>
<h3>[expert name] confirms that he is not habitually resident in the Federal Republic of Germany.</h3><br>

<h2>Special Agreement</h2><br>
<h3>As an amendment to Art. 8.3 of the General Terms and Conditions of Contract of the ICON-INSTITUTE Consulting Gruppe the following is agreed: The contract will come to an end if the project is ended or interrupted before the planned finishing date, regardless of the reason for the termination or the interruption of project. If the consulting services and other services (reports, studies, workshops, training courses, etc.) agreed in the contract cannot be provided or can only be provided partially due to the restrictions existing and occurring during the contractual period as a result from the COVID-19 Pandemic, there is entitlement to remuneration only for the services provided.</h3><br>
`
export default documentData