<template>
  <app-dialog
    ref="isBudgetSection"
    :title="$t('projectBudget.deleteBudgetSection')"
    :subtitle="$t('projectBudget.confirmDeleteBudgetSection')"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="5" class="">
          <app-secondary-button
            :buttonLabel="$t('projects.cancel')"
            @click-event="hideDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="7" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="delBudgetSection"
          >
            {{ $t("projectBudget.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue"
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import { deleteBudgetSection } from '@/services/endpoints';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'Delete Budget section',
  props: {
    payload: {
      type: Object,
    }
  },
  data() {
    return {
      loading: false,
      addloading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      mandatorySection: {
        id: '',
        section: 1
      }
    }
  },

  computed: {
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    budgetSections() {
      return this.$store.getters['projectBudgetModules/isBudgetSections']
    },
    contractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    ...mapGetters("projectBudgetModules", ["getError", "getResponse"]),
  },

  methods: {
    ...mapActions("projectBudgetModules", ["deleteBudgetSection"]),
    getTranslatedStringKey(field) {
      return getTransString(field);
    },

    showDialog() {
      this.$refs.isBudgetSection.showDialog = true;
    },
    hideDialog() {
      this.$refs.isBudgetSection.showDialog = false;
    },
    async delBudgetSection (){
      if (this.payload.id) {
          await this.deleteBudgetSection({ budgetSectionId: this.payload.id})
          if(this.getResponse.status === 'success'){
            this.alertType = "success";
            this.alertState = !this.alertState;
            this.alertTitle = this.getResponse.detail;
            this.hideDialog();
            this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
              "ProjectBudget": false
            });
          }
      }

    }
  },
  mounted() {
    const filterMandatory = this.budgetSections
      ?.filter(section => section?.title === 'Mandatory')
      .map(section => ({
        id: section.id,
        title: section.title
      }));
    this.mandatorySection.id= filterMandatory[0]?.id

    if (filterMandatory[0]?.title === 'Mandatory' &&  this.contractDetails?.is_bank_guarantee === false) {
      this.delBudgetSection()
    }
  }

})
</script>
