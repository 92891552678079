<template>
  <app-basic-table
  :items="schedule"
  :headers="header"
  >
  <template #title>
    <h3 class="">{{ $t("homeview.teamSchedule") }}</h3>
  </template>
  <template #footer>
    <div>
      <Paginator
        :total-items="totalPages"
        v-model="currentPages"
        :items-per-page="10"
        :max-pages-shown="5"
        :on-click="loadMore"
      /> 
     
    </div>
  </template>
  </app-basic-table>
</template>

<script lang="js">
import { defineComponent } from "vue";
// import {convertTo12HourFormat} from "@/services/helpers";
import Paginator from "@/components/shared/PaginationComponent.vue";
export default defineComponent({
  name: "TeamSchedule",
  components: {
  
    Paginator,
  },
  data() {
    return {
      totalPages: 1,
      currentPages: 1,
      showClose: false,
      header: ['Name', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    
    }
  },

  props: {
    schedule: {
      type: Array
    }
    
  },
  methods: {
    loadMore() {
      this.$store.dispatch("auth/getUserHomePage");
    },
   
    emitOpen(data) {
      this.$emit("open-event", data);
    },
    handleClick() {
      this.$store.commit("hrResources/SET_HOME_TO_STATE",true);
      this.$store.commit('hrResources/SET_EMPLOYEE_USER_KEY_FROM_HOME',this.user_key)
      this.$router.push('/profile')
    }
  },
});
</script>
<style scoped>
.working-time {
  background-color: #f2f4f7;
  padding: 2px 8px;
  border-radius: 16px;
  margin-right: 6px;
  margin-top: 8px;
}
.working-time div {
  color: #344054;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: default;
}
</style>
