<template>
  <app-dialog ref="dialog" :title="title" :subtitle="subTitle" width="816">
    <template #content>
      <v-row class="">
        <v-col cols="6" class="pr-4">
          <app-input-field
            :label="$t('Sender'.getTranslationKey())"
            :placeholder="$t('Enter sender email'.getTranslationKey())"
            v-model="travelAgencyForm.sender_email"
            disabled
          ></app-input-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <app-input-field
            :label="$t('Recipient'.getTranslationKey()) + '*'"
            :placeholder="$t('Enter recipient email'.getTranslationKey())"
            v-model="travelAgencyForm.recipient_email"
            type="email"
            validation="emailOnly"
            :hasError="isFieldInvalid('recipient_email')"
            :isRequired="true"
          ></app-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-4"
          ><app-input-field
            :label="$t('Subject'.getTranslationKey())"
            :placeholder="$t('Enter subject'.getTranslationKey())"
            v-model="flightForm.flight_for"
            disabled
          ></app-input-field
        ></v-col>
        <v-col cols="6" class="pl-4">
          <app-single-select
            :label="$t('Paying Company'.getTranslationKey()) + '*'"
            :labelPlaceholder="$t('Select paying company'.getTranslationKey())"
            :options="allPayingCompanies"
            v-model="travelAgencyForm.icon_company"
            :showSearch="true"
            :searchPlaceholder="$t('Search company'.getTranslationKey())"
          ></app-single-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-4">
          <app-single-select
            v-if="
              flightForm.flight_for === 'Expert with contract' ||
              flightForm.flight_for === 'Expert without contract'
            "
            :label="$t('Expert'.getTranslationKey())"
            :labelPlaceholder="$t('Select expert'.getTranslationKey())"
            :options="expertArray"
            v-model="flightForm.expert_id"
            :showSearch="true"
            disabled
          ></app-single-select>
          <app-single-select
            v-if="flightForm.flight_for === 'Employee'"
            :label="$t('Employee'.getTranslationKey())"
            :labelPlaceholder="$t('Select employee'.getTranslationKey())"
            :options="employeeArray"
            v-model="flightForm.employee_id"
            :showSearch="true"
            disabled
          ></app-single-select>

          <app-single-select
            v-if="
              flightForm.flight_for === 'Firm with contract' ||
              flightForm.flight_for === 'Firm without contract'
            "
            :label="$t('Firm'.getTranslationKey())"
            :labelPlaceholder="$t('Select firm'.getTranslationKey())"
            :options="firmArray"
            v-model="flightForm.partner_id"
            :showSearch="true"
            disabled
          ></app-single-select>
        </v-col>
        <v-col cols="6" class="pl-4">
          <app-input-field
            :label="$t('Email'.getTranslationKey())"
            :placeholder="$t('Enter email'.getTranslationKey())"
            v-model="travelAgencyForm.expert_email"
            type="email"
            validation="emailOnly"
            :disabled="true"
          ></app-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-4">
          <app-input-field
            :label="$t('Address'.getTranslationKey()) + '*'"
            :placeholder="$t('Enter address'.getTranslationKey())"
            v-model="travelAgencyForm.address"
            :hasError="isFieldInvalid('address')"
            :isRequired="true"
          ></app-input-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <app-input-field
            :label="$t('Mobile Phone'.getTranslationKey()) + '*'"
            :placeholder="$t('Enter mobile number'.getTranslationKey())"
            v-model="travelAgencyForm.mobile_number"
            :hasError="isFieldInvalid('mobile_number')"
            :isRequired="true"
          ></app-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-4">
          <app-date-field
            type="date"
            :label="$t('Date of Birth'.getTranslationKey())"
            v-model="travelAgencyForm.date_of_birth"
          ></app-date-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <app-date-field
            type="date"
            :label="$t('Departure Date'.getTranslationKey())"
            v-model="flightForm.departure_date"
            disabled
          ></app-date-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pr-4">
          <app-date-field
            type="date"
            :label="$t('Return Date'.getTranslationKey())"
            v-model="flightForm.return_date"
            disabled
          ></app-date-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <app-input-field
            :label="$t('Route'.getTranslationKey())"
            :placeholder="$t('Enter Route'.getTranslationKey())"
            v-model="flightForm.route"
            disabled
          ></app-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="mb-2 text-sm text-gray-700 font-['inter'] font-medium">
            {{ $t("Wishes".getTranslationKey()) }}
          </p>
          <QuillEditor
            :placeholder="
              $t('Enter other wishes for flight'.getTranslationKey())
            "
            :disabled="shouldDisableInput || disabledFormInputs"
            :class="contentStyles"
            contentType="text"
            v-model:content="travelAgencyForm.other_requests"
            :options="options"
            :style="editorStyles"
          />
        </v-col>
      </v-row>
      <div class="flex gap-10 mt-24">
        <div class="flex items-center gap-3">
          <input
            type="checkbox"
            class="cursor-pointer"
            v-model="travelAgencyForm.copy_to_sender"
            data-cy="copySender"
            id="copySender"
          />
          <label
            for="copySender"
            class="text-[#667085] text-[14px] font-medium"
            >{{ $t("Send Copy to Sender".getTranslationKey()) }}</label
          >
        </div>
        <div class="flex items-center gap-3">
          <input
            type="checkbox"
            class="cursor-pointer rounded text-pink-500"
            v-model="travelAgencyForm.copy_to_pm"
            data-cy="copyManager"
            id="copyManager"
          />
          <label
            for="copyManager"
            class="text-[#667085] text-[14px] font-medium"
            >{{ $t("Send Copy to Manager".getTranslationKey()) }}</label
          >
        </div>
      </div>
      <v-row>
        <v-col cols="12" class="flex justify-end gap-6 mt-8">
          <app-secondary-button
            :buttonLabel="$t('Cancel'.getTranslationKey())"
            @click="cancelTravelAgency()"
          ></app-secondary-button>
          <app-primary-button
            :buttonLabel="$t('Send'.getTranslationKey())"
            @click="sendEmailRequest()"
          ></app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { validEmail } from "@/services/validation";
import { sendEmail } from "@/services/endpoints";
import {
  getTransString,
  maximumStartDate,
  minimumEndDate,
} from "@/services/helpers";
export default defineComponent({
  name: "TravelAgency",
  components: {
    QuillEditor,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      travelAgencyForm: {
        sender_email: "",
        recipient_email: "",
        subject: "",
        cost_centre_number: "",
        address: "",
        icon_company: "",
        expert_id: "",
        mobile_number: "",
        date_of_birth: "",
        date: "",
        back_date: "",
        route: "",
        other_requests: "",
        copy_to_sender: false,
        copy_to_pm: false,
        expert_email: "",
        contract_type: "",
      },
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      flight_id: null,
      hasError: false,
      isDirty: [],
      requiredFields: ["recipient_email", "address", "mobile_number"],
    };
  },
  watch: {
    "currentFlightRequest.travel_agency_details": {
      handler: function (val) {
        if (!val) return;
        this.populateFormFields(this.travelAgencyForm, val);
      },
      immediate: true,
    },
  },
  computed: {
    currentUserEmail() {
      return this.$store.getters["auth/isCurrentUserEmail"];
    },
    allFlightDropdowns() {
      return this.$store.getters["flightModule/isAllFlightDropdowns"];
    },
    allPayingCompanies() {
      const modifiedPayingCompanies =
        this.$store.getters["flightModule/isAllPayingCompanies"];
      return modifiedPayingCompanies?.map((company) => {
        return {
          text: company.title,
          value: company.title,
        };
      });
    },
    allExpertContracts() {
      return this.$store.getters["flightModule/isAllExpertContracts"];
    },
    holdContractType() {
      return this.allExpertContracts.contracts?.map((item) => {
        return {
          id: item.id,
          contract_type: item.contract_type,
        };
      });
    },
    holdExpertEmail() {
      return this.allFlightDropdowns.experts;
    },
    currentPath() {
      return this.$route.path;
    },
    currentFlightRequest() {
      return this.$store.getters["flightModule/currentFlightRequest"];
    },
    flightForm() {
      return this.currentPath === "/flight"
        ? this.currentFlightRequest
        : this.form;
    },
    expertArray() {
      return this.allFlightDropdowns.experts?.map((expert) => {
        return {
          text: `${expert.first_name} ${expert.last_name}`,
          value: expert.id,
        };
      });
    },
    holdFirmEmail() {
      return this.allFlightDropdowns.firms;
    },
    holdEmployeeEmail() {
      return this.allFlightDropdowns.employees;
    },
    firmArray() {
      return this.allFlightDropdowns.firms?.map((firm) => {
        return {
          text: firm.company_name,
          value: firm.id,
        };
      });
    },
    employeeArray() {
      return this.allFlightDropdowns.employees?.map((employee) => {
        return {
          text: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        };
      });
    },
  },
  methods: {
    displayAlertMessage(message, errorType) {
      this.alertState = true;
      this.alertType = errorType;
      this.alertTitle = message;
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.travelAgencyForm).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.travelAgencyForm[key] === undefined ||
            this.travelAgencyForm[key] === null ||
            this.travelAgencyForm[key] === "")
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.displayAlertMessage(
            "Please fill out the required fields",
            "error"
          );
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    showTravelAgencyDialog() {
      this.$refs.dialog.showDialog = true;
    },
    cancelTravelAgency() {
      this.$refs.dialog.showDialog = false;
      this.travelAgencyForm = {};
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    prefillInput(infoType) {
      this.travelAgencyForm.expert_email =
        infoType?.employee__official_email || infoType?.email;
      this.travelAgencyForm.address =
        infoType?.house_address || infoType?.address_line_1;
      this.travelAgencyForm.date_of_birth = infoType?.date_of_birth;
      this.travelAgencyForm.mobile_number = infoType?.phone_number;
    },
    getEmail() {
      if (!this.flightForm.flight_for) return;
      if (
        this.flightForm.flight_for === "Expert with contract" ||
        this.flightForm.flight_for === "Expert without contract"
      ) {
        const expertInfo = this.holdExpertEmail?.find(
          (info) => info.id === this.flightForm.expert_id
        );
        this.travelAgencyForm.expert_id = this.flightForm.expert_id;
        this.prefillInput(expertInfo);
      } else if (this.flightForm.flight_for === "Employee") {
        const employeeInfo = this.holdEmployeeEmail?.find(
          (info) => info.id === this.flightForm.employee_id
        );
        this.travelAgencyForm.expert_id = this.flightForm.employee_id;
        this.prefillInput(employeeInfo);
      } else if (
        this.flightForm.flight_for === "Firm with contract" ||
        this.flightForm.flight_for === "Firm without contract"
      ) {
        const firmInfo = this.holdFirmEmail?.find(
          (info) => info.id === this.flightForm.partner_id
        );
        this.travelAgencyForm.expert_id = this.flightForm.partner_id;
        this.prefillInput(firmInfo);
      }
    },
    sendEmailRequest() {
      if (
        this.travelAgencyForm.recipient_email &&
        !validEmail(this.travelAgencyForm.recipient_email)
      ) {
        this.alertType = "error";
        this.alertTitle = "Please enter a valid recipient email address.";
        this.alertState = true;
        return;
        
      }
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;

      this.travelAgencyForm.cost_centre_id = this.flightForm.cost_centre_id;
      this.travelAgencyForm.sender_email = this.currentUserEmail;

      // this.travelAgencyForm.expert_id= travelAgencyExpert
      this.travelAgencyForm.route = this.flightForm.route;
      this.travelAgencyForm.date = this.flightForm.departure_date;
      this.travelAgencyForm.back_date = this.flightForm.return_date;
      this.travelAgencyForm.subject = this.flightForm.flight_for;
      this.travelAgencyForm.contract_type = this.holdContractType?.find(
        (type) => {
          if (type.id === this.flightForm.contract_id) {
            return type.contract_type;
          }
        }
      )?.contract_type;
      // this.travelAgencyForm.email= expertEmail

      if (!this.flightForm.contract_id) {
        delete this.travelAgencyForm.contract_type;
      }
      Api()
        .post(`${sendEmail}${this.flight_id}/`, this.travelAgencyForm)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.cancelTravelAgency();
            setTimeout(() => {
              this.$router.go(-1);
            }, 7000);
          }
        })
        .catch((error) => {
          this.alertState = true;
          this.alertType = "error";
          this.alertTitle = error.response.data?.detail;
        });
    },
    populateFormFields(saveTo, data) {
      (saveTo.cost_centre_number = data.cost_centre_id),
        (saveTo.sender_email = data.sender_email),
        (saveTo.recipient_email = data.recipient_email),
        (saveTo.expert_email = data.expert_email),
        (saveTo.subject = data.subject),
        (saveTo.icon_company = data.icon_company),
        (saveTo.expert = data.expert),
        (saveTo.address = data.address),
        (saveTo.mobile_number = data.mobile_number),
        (saveTo.date_of_birth = data.date_of_birth),
        (saveTo.date = data.date),
        (saveTo.back_date = data.back_date),
        (saveTo.route = data.route),
        (saveTo.other_requests = data.other_requests),
        (saveTo.copy_to_sender = data.copy_to_sender),
        (saveTo.copy_to_pm = data.copy_to_pm),
        (saveTo.contract_type = this.holdContractType?.find((type) => {
          if (type.id === this.flightForm.contract_id) {
            return type.contract_type;
          }
        })?.contract_type);
      // saveTo.expert_email= expert_email
    },
  },

  created() {
    this.travelAgencyForm.sender_email = this.currentUserEmail;
  },
});
</script>
<style scoped>
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: #fbf7f6;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #943b36;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

