// Sentry
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";

const app = createApp(App);
const env = process.env.VUE_APP_ENVIRONMENT;

Sentry.init({
  app,
  dsn: "https://f050746a4d4bbeeb4e01d65063bd7954@o1182568.ingest.sentry.io/4506144413122560",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["icon-cash-tool.amalitech-dev.net", /^\//],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],
  environment: env,
  // Performance Monitoring
  tracesSampler: () => {
    if (env === "production") {
      return 0.5;
    } else if (env === "staging") {
      return 0.25;
    } else if (env === "local") {
      return 0;
    } else {
      return 0.1;
    }
  },
  sampleRate: process.env.ISLOCAL ? 0.0 : 0.25,

  logErrors: true,
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.25, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Track Vue Components
  trackComponents: true,
});
