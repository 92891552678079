<template>
  <div>
    <app-header
      :name="$t('foreignOffices.foreignOffice')"
      :show-arrow-back="false"
    />
  </div>
  <div class="filter_parent SPC-MT-400">
    <v-row>
      <v-col cols="4" class="">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchForeignOffice"
          :callback="true"
        />
      </v-col>
      <v-col cols="4" class="">
        <app-select-field
          class="px-2"
          :options="countryOptions"
          :label="$t('foreignOffices.country')"
          placeholder="All"
          v-model="country"
        />
      </v-col>
    </v-row>
    <div
      v-if="appLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <div v-else class="SPC-MT-400">
      <app-table
        :showEditPage="true"
        :headers="tableHeaders"
        :items="allForeignOffices"
        :showCheck="false"
        :hideMenu="!canEdit"
        @edit-event="routeToEditOffice"
        @delete-event="showDialog"
      >
        <template #title>
          <h3 class="H500">{{ $t("foreignOffices.allForiegnOffices") }}</h3>
        </template>
        <template #actions v-if="canEdit">
          <app-primary-button
            :buttonLabel="$t('foreignOffices.addForiegnOffices')"
            @click="addForeignOffice"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </template>
        <template #footer>
          <Paginator
            :total-items="totalPages"
            v-model="currentPages"
            :items-per-page="10"
            :max-pages-shown="5"
            :on-click="loadMore"
          />
        </template>
      </app-table>
      <delete-office ref="deleteOffice"></delete-office>
    </div>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AppHeader from "@/components/shared/AppHeader.vue";
import Paginator from "@/components/shared/PaginationComponent.vue";
import Api from "@/services/api";
import { mapState } from "vuex";
import moment from "moment";
import { getTransString, addUnderscores } from "@/services/helpers";
import DeleteOffice from "./DeleteOffice.vue";
import { searchForeignOffice } from "@/services/endpoints";
import countries from "@/services/country_api";

export default defineComponent({
  name: "Foreign Office List",
  components: {
    AppHeader,
    Paginator,
    DeleteOffice,
  },
  data() {
    return {
      countryOptions: null,
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      cancelLabel: "Cancel",
      title: "",
      subTitle: "",
      isEdit: false,
      isDelete: false,
      currentPages: 1,
      country: "",
    };
  },

  watch: {
    country(args) {
      this.searchForeignOffice(args);
    },
  },
  computed: {
    allForeignOffices() {
      const modifiedData =
        this.$store.getters["foreignOfficeModule/allForeignOffices"];
      return modifiedData?.map((data) => {
        return {
          id: data.id,
          country: data.country,
          "name of office": data.place,
          email: data.email,
          "telephone number": data.telephone_number,
          "office start date": `${moment(data?.office_start_date)?.format(
            "Do MMM YYYY"
          )}`,
          "office end date": `${
            data?.office_end_date === null
              ? ""
              : moment(data?.office_end_date)?.format("Do MMM YYYY")
          }`,
        };
      });
    },
    tableHeaders() {
      return this.$store.getters["foreignOfficeModule/tableHeaders"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      const findModules = modifiedData?.filter(
        (module) => module.module_name === "Foreign Office"
      );
      return findModules?.length !== 0;
    },
    currentPage() {
      return this.$store.getters["foreignOfficeModule/isCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["foreignOfficeModule/isTotalPages"];
    },
    appLoading() {
      return this.$store.getters["foreignOfficeModule/appLoading"];
    },
    filtersBy() {
      return this.$store.getters["foreignOfficeModule/isFilterBys"];
    },
    appLocaleLang() {
      return this.translation.locale;
    },

    ...mapState(["translation"]),
  },

  methods: {
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    addForeignOffice() {
      this.$store.commit(
        "foreignOfficeModule/SET_CURRENT_FOREIGN_OFFICE_TO_STATE",
        {}
      );
      this.$store.commit("foreignOfficeModule/SET_OFFICE_ID_STATE", "");
      this.$router.push({ name: "addForeignOffice" });
    },
    routeToEditOffice(event) {
      const dataObject = addUnderscores(event);
      this.$store.commit("foreignOfficeModule/SET_OFFICE_TITLE_STATE", dataObject.place);
      this.$store.commit(
        "foreignOfficeModule/SET_OFFICE_ID_STATE",
        dataObject?.id
      );
      this.$router.push({ name: "editForeignOffice" });
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog(event) {
      this.$refs.deleteOffice.officeId = event.id;
      this.$refs.deleteOffice.showDialog();
    },
    getAllOffices(page) {
      const payload = {
        load_page: false,
        page_number: page,
      };
      this.$store.dispatch("foreignOfficeModule/getAllOffices", payload);
    },
    fetchAgain(page) {
     
      this.getAllOffices(page);
    },

    searchForeignOffice(search_query) {
      const payload = {
        search_query: search_query,
      };

      Api()
        .post(`${searchForeignOffice}${this.currentPage}/`, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "foreignOfficeModule/SET_OFFICES_TO_STATE",
              response.data.data
            );
            this.$store.commit(
              "foreignOfficeModule/SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            this.$store.commit(
              "foreignOfficeModule/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data

            );
          }
        });
    },

    loadMore() {
      const page = this.currentPages;
      this.getAllOffices(page);
    },
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.countryOptions = this.getCountryByName();
    const page = 1;
    this.getAllOffices(page);
    this.$store.commit(
      "foreignOfficeModule/SET_CURRENT_FOREIGN_OFFICE_TO_STATE",
      {}
    );
  },
});
</script>
