<template>
  <v-sheet max-width="1125" class="bg-transparent mt-5">
    <form>
      <fieldset :disabled="shouldDisableInput ? 'disabled' : null">
        <v-row>
          <v-col cols="10" class="mt-5 !divide-y">
            <p class="pb-3 font-semibold text-[1.125rem]">
              {{ $t("projects.deadline") }}
            </p>
            <div></div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id=""
              type="date"
              :label="$t('projects.internalDeadline')"
              v-model="projectsForm.internal_deadline"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id=""
              type="date"
              :label="$t('projects.externalDeadline')"
              v-model="projectsForm.external_deadline"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-select-field-object
              id=""
              placeholder="Select submission method"
              :options="methodsOptions"
              :label="$t('projects.submissionMethod')"
              v-model="projectsForm.submission_method"
              :disabled="shouldDisableInput ? 'disabled' : null"
            ></app-select-field-object>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="5" class="">
            <p class="text-[#344054] text-[14px] font-medium italic">
              {{ $t("projects.lastEditedBy") }}:
              {{ currentProject.last_updated_by__first_name }}
              {{ currentProject.last_updated_by__last_name }}
            </p>
          </v-col>
        </v-row>
      </fieldset>
    </form>
  </v-sheet>
</template>
<script lang="js">
import { defineComponent } from 'vue';
import { isEqual } from 'lodash';

export default defineComponent({
  props: {
    projectData: {
      type: Object
    }
  },
    data(){
        return{
          projectsForm:{
              internal_deadline: null,
              external_deadline:  null,
              submission_method: '',
          },
          originalProjectsForm:{
            internal_deadline: null,
            external_deadline: null,
            submission_method: '',
          },
          firstRun: true
        }
    },
    watch: {
      projectData (data) {

        this.populateProjectForm(this.projectsForm, data);

        if(this.firstRun) {
          this.populateProjectForm(this.originalProjectsForm, data);
          this.firstRun = false
        }
      },
      projectsForm: {
        deep: true,
        handler(value) {
          this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "BasicInformation": !isEqual(value, this.originalProjectsForm)
        });

        },
      }
    },

    computed:{
    methodsOptions() {
      const modifiedData = this.$store.getters["projectModules/isSubmissionMethods"];
      return modifiedData?.convertArrayToObject();
    },
      isUsername() {
      return this.$store.getters["auth/isUsername"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    currentPath() {
      return this.$route.path;
    },
    currentProject() {
      return this.$store.getters["projectModules/isCurrentProject"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
   },
   methods:{
    populateProjectForm(saveTo, data) {
      saveTo.internal_deadline = data?.internal_deadline
      saveTo.external_deadline = data?.external_deadline
      saveTo.submission_method = data?.submission_method
    }
   },

 created(){
  this.populateProjectForm(this.projectsForm, this.projectData);
  this.populateProjectForm(this.originalProjectsForm, this.projectData)
 }
})
</script>
