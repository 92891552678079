import Api from "@/services/api";
import { ActionContext } from "vuex";
import { addProjectFile } from "./../../services/endpoints";

interface Payload {
  project_id: string; // Define an index signature
  total_offer_amount?: number;
  margin_amount?: number;
}

export default {
  namespaced: true,
  state: {
    calculation: {},
    error: {},
    response: {},
    appLoading: false,
  },
  getters: {
    getCalculation(state: any) {
      return state.calculation;
    },
    getError(state: any) {
      return state.error;
    },
    getResponse(state: any) {
      return state.response;
    },
    getAppLoading(state: any) {
      return state.appLoading;
    },
  },
  mutations: {
    SET_CALCULATION(state: any, data: any) {
      state.calculation = { ...data };
    },
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
  },
  actions: {
    async fetchCalculation(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      commit("SET_APP_LOADING_TO_STATE", true);
      try {
        const data = await Api().get(
          "/projects/project/calculation/get-calculation/" + payload.project_id
        );
        commit("SET_CALCULATION", data.data.data);
        commit("SET_ERROR", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      } catch ({ response }: any) {
        commit("SET_ERROR", {
          error: response,
        });
        commit("SET_CALCULATION", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async saveCalculation(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().post(
          "/projects/project/calculation/add-update-calculation/" +
            payload.project_id +
            "/",
          {
            total_offer_amount: parseFloat(payload.total_offer_amount + ""),
            margin_amount: parseFloat(payload.margin_amount + ""),
          }
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async saveUploadedFiles({ commit }: ActionContext<any, any>, payload: any) {
      try {
        const data = await Api().post(addProjectFile, {
          project_id: payload.project_id,
          project_files: payload.project_files,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
  },
};
