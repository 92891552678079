<template>
  <div>
    <div>
      <v-row>
        <v-col cols="5" class="">
          <app-search-field
            @search-event="fetchAgain(1)"
            @search-function="search"
            :callback="true"
          />
        </v-col>
      </v-row>
      <div class="SPC-MT-400">
        <app-table
          :headers="headers"
          :items="allContractTasks"
          :showCheck="false"
          :hideMenu="false"
          @edit-event="showDialog({ $event, data: 'edit' })"
          @delete-event="showDialog({ $event, data: 'delete' })"
        >
          <template #title>
            <h3 class="H500">
              {{ $t("projectConfiguration.allContractTasks") }}
            </h3>
          </template>
          <template #actions>
            <app-primary-button
              :buttonLabel="$t('projectConfiguration.newTask')"
              @click="showDialog({ data: 'add' })"
            >
              <template #icon>
                <v-icon icon="mdi-plus"></v-icon>
              </template>
            </app-primary-button>
          </template>
          <template #footer>
            <div>
              <Paginator
                :total-items="totalPages"
                v-model="currentPages"
                :items-per-page="10"
                :max-pages-shown="5"
                :on-click="loadMore"
              />
            </div>
          </template>
        </app-table>
      </div>

      <app-dialog
        ref="dialogState"
        :title="
          mode === 'edit'
            ? $t('projectConfiguration.editContractTask')
            : $t('projectConfiguration.addContractTask')
        "
        subTitle="xjgjh"
        :width="550"
      >
        <template #content>
          <app-input-field
            :placeholder="$t('projects.contractAddenda.enterTitle')"
            :label="$t('projects.contractAddenda.title') + '*'"
            v-model="title"
          ></app-input-field>

          <div class="mt-[20px]">
            <div class="label py-2">
              <label>{{ $t("taskareas.content") + "*" }}</label>
            </div>
            <QuillEditor
              contentType="html"
              v-model:content.trim="editorContent"
              :options="options"
              :style="editorStyles"
            />
          </div>
        </template>
        <template #actions>
          <v-row class="flex justify-end px-4 mt-1 mb-5">
            <v-col cols="4" class="">
              <app-secondary-button
                :buttonLabel="$t('employees.cancel')"
                @click="hideDialog()"
                class="cursor-pointer"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="4" class="">
              <app-primary-button
                :buttonLabel="
                  mode === 'edit'
                    ? $t('projectBudget.saveChanges')
                    : $t('projectConfiguration.save')
                "
                :disabled="disable"
                @click-event="
                  mode === 'edit' ? editContractTask() : saveContractTask()
                "
              >
              </app-primary-button>
            </v-col>
          </v-row>
        </template>
      </app-dialog>
      <app-dialog
        ref="dialog"
        :title="deleteTitle"
        :subtitle="deleteSubTitle"
        height="302"
        width="408"
      >
        <template #actions>
          <v-row class="px-4 mb-5">
            <v-col cols="6" class="">
              <app-secondary-button
                :buttonLabel="$t('employees.noKeepIt')"
                @click-event="hideDialog()"
              >
              </app-secondary-button>
            </v-col>
            <v-col cols="6" class="">
              <v-btn
                variant="default"
                min-width="100%"
                min-height="100%"
                class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
                @click="delContractTask"
              >
                <!-- Yes , delete -->
                {{ $t("employees.yesDelete") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </app-dialog>

      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { getTransString, truncateText, stripHtmlTags } from "@/services/helpers";
import { QuillEditor } from "@vueup/vue-quill";
import Paginator from "@/components/shared/PaginationComponent.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { mapActions } from 'vuex';

export default defineComponent({
  name: "ContractTasks",
  components: {
    QuillEditor,
    Paginator
  },
  props: {

  },
  data() {
    return {
      headers: ["Title", "Content"],
      items: [],
     
      currentPages: 1,
     
      editorContent: "",
      editorStyles: {
          minHeight: "150px",
          padding: "10px, 14px",
          fontSize: "16px",
          maxHeight: "300px",
          "overflow-y": "auto"
      },
      options: {
          modules: {
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["blockquote"],
              ["link"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
          placeholder: "Enter contract task here",
      },
      isDelete: false,
      title: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
      dialogState: false,
      dialog: false,
      mode: 'add',
      task_id: '',
      deleteTitle: "Delete Task?",
      deleteSubTitle: "Are you sure you want to delete this task? This action can’t be undone.",
      search_query: ""
    };
  },

  computed: {
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    allContractTasks(){
     const modifiedData = this.getContractTasks
     const maxLength = 100;
      
      return modifiedData?.map(item => ({
        id: item?.id,
        title: item?.title,
        content: truncateText(stripHtmlTags(item?.content), maxLength),

    }))
    },
    getContractTasks(){
     return this.$store.getters['projectConfigurations/getContractTasks']
     
    },
    isCurrentPage(){
     return this.$store.getters['projectConfigurations/isCurrentPage']
     
    },
    totalPages(){
     return this.$store.getters['projectConfigurations/isTotalPages']
     
    },
    
    getError(){
      return this.$store.getters['projectConfigurations/getError']
    },
    getResponse(){
      return this.$store.getters['projectConfigurations/getResponse']
    },
    disable() {
      return !(this.title && this.editorContent)
    }
  },

  watch: {
  },

  methods: {
    ...mapActions("projectConfigurations", ["getAllContractTasks", "createContractTask", "updateContractTask", "deleteContractTask", "getOneContractTask", "searchContractTasks"]),
   
    fetchData(page=1){
      this.getAllContractTasks({pageNumber: page})
    
    },
   
    async fetchAgain(page) {
  
      this.fetchData(page)
    },

    async search(search_query) {
      this.search_query = search_query
      await this.searchContractTasks({pageNumber: this.currentPages, searchQuery: this.search_query})
     
    },

    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDialog() {
      this.$refs.dialogState.showDialog = false;
      this.$refs.dialog.showDialog = false;
      this.editorContent = '';
      this.title = '';
    },
    async showDialog(payload){
      if("data" in payload &&  payload.data === 'add'){
        this.mode = 'add'
        this.$refs.dialogState.showDialog = true;
      }else if("data" in payload &&  payload.data === 'edit') {
        this.mode = 'edit'
        this.task_id = payload?.$event?.id
        await this.getOneContractTask({task_id: this.task_id})
        this.title = this.getResponse?.data?.title;
        this.editorContent = this.getResponse?.data?.content;
        this.$refs.dialogState.showDialog = true;
      }else if("data" in payload &&  payload.data === 'delete') {
        this.task_id = payload?.$event?.id
        this.$refs.dialog.showDialog = true;
      }

    },
    async saveContractTask(){
      await this.createContractTask({title: this.title, content: this.editorContent});
      if (this.getResponse?.status === 'success') {
            this.addTitle = "";
            this.alertType = "success";
            this.alertTitle = this.getResponse?.detail;
            this.alertState = true;
            this.editorContent = '';
            this.title = '';
            this.fetchData(this.currentPage)
            this.$refs.dialogState.showDialog = false;
            const maxLength = 100;
            this.items?.unshift(this.getResponse?.data)
            this.items = this.items?.map(item => ({
                id: item?.id,
                title: item?.title,
                content: truncateText(stripHtmlTags(item?.content), maxLength),

            }))
      }
    },

    async editContractTask() {
      await this.updateContractTask({
        task_id: this.task_id,
        title: this.title,
        content: this.editorContent
      });
      if (this.getResponse?.status === 'success') {
            this.addTitle = "";
            this.alertType = "success";
            this.alertTitle = this.getResponse?.detail;
            this.alertState = true;
            this.editorContent = '';
            this.title = '';
            this.fetchData(this.currentPage)
            const maxLength = 100;
            const indexToUpdate = this.items.findIndex(item => item.id === this.task_id);

            if (indexToUpdate !== -1) {
                // Update the object at the found index
                this.items[indexToUpdate] = {id: this.getResponse?.data.id, title: this.getResponse?.data.title, content: truncateText(stripHtmlTags(this.getResponse?.data.content),maxLength)};
            }
            this.$refs.dialogState.showDialog = false;
      }
    },

    async delContractTask() {
      await this.deleteContractTask({task_id: this.task_id})
      if (this.getResponse?.status === 'success') {
            this.addTitle = "";
            this.alertType = "success";
            this.alertTitle = this.getResponse?.detail;
            this.alertState = true;
            this.fetchData(this.currentPage)
            const indexToRemove = this.items.findIndex(item => item.id === this.task_id);

            if (indexToRemove !== -1) {
                // Remove the object at the found index
                this.items.splice(indexToRemove, 1);
            }

            this.$refs.dialog.showDialog = false;
      }
    },
    async loadMore() {
      // let page = this.currentPages;
      this.fetchData(this.currentPages)
  
    },
  },

  created() {
 
    this.fetchData(this.currentPage)
  },

  mounted() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 6);
  }

});
</script>
<style scoped>
.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
</style>
