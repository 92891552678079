<template>
  <div>
    <div
      v-if="isAppLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
    <form v-if="!isAppLoading">
      <div class="flex justify-start gap-12 mb-7">
        <div class="w-[400px]">
          <app-input-field
            id="2"
            :label="$t('contact.phoneNumber')"
            placeholder="0343 1234 23"
            validation="phoneOnly"
            v-model:modelValue="personalForm.phone_number"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
            type="text"
          ></app-input-field>
        </div>
        <div class="w-[400px]">
          <app-input-field
            id="3"
            :label="$t('contact.mobileNumber')"
            placeholder="0343 1234 23"
            validation="phoneOnly"
            v-model:modelValue="personalForm.mobile_number"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
            type="text"
          ></app-input-field>
        </div>
      </div>
      <div class="flex justify-start gap-12 mb-3">
        <div class="w-[400px]">
          <app-input-field
            id="6"
            type="email"
            :label="$t('contact.emailAddress')"
            placeholder="pepperoni@gmail.com"
            validation="emailOnly"
            v-model:modelValue="personalForm.email"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
        <div class="w-[400px]">
          <app-input-field
            id="5"
            :label="$t('contact.emergencyPhone')"
            placeholder="0343 1234 23"
            type="text"
            validation="phoneOnly"
            v-model:modelValue="personalForm.emergency_phone_number"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
      </div>
      <div class="flex items-center gap-3 mb-7">
        <input
          type="checkbox"
          class=""
          v-model="personalForm.isCommunication"
          :disabled="
            ((currentPath === '/edit-employee' ||
              currentPath === '/edit-expert' ||
              currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton) ||
            personalForm.official_email === ''
          "
        />
        <label for="communicationCheckbox" class="text-[#667085] text-[14px]">{{
          $t("contact.selectForCommunication")
        }}</label>
      </div>
      <div class="flex justify-start gap-12 mb-7">
        <div class="w-[400px]">
          <app-input-field
            id="6"
            :label="$t('contact.houseNumber')"
            placeholder="PB Block 98, Apartment 12"
            v-model:modelValue="personalForm.house_address"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
        <div class="w-[400px]">
          <app-input-field
            id="7"
            :label="$t('contact.addressLine')"
            placeholder="Kylia Street, 032"
            v-model:modelValue="personalForm.second_address"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
      </div>
      <div class="flex justify-start gap-12 mb-7">
        <div class="w-[400px]">
          <app-input-field
            id="8"
            :label="$t('contact.postalCode')"
            placeholder="50667"
            validation="digitOnly"
            v-model:modelValue="personalForm.postal_code"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
        <div class="w-[400px]">
          <app-input-field
            id="9"
            :label="$t('contact.city')"
            placeholder="Cologne"
            validation="lettersOnly"
            v-model:modelValue="personalForm.city"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-input-field>
        </div>
      </div>
      <div class="flex justify-start gap-12 mb-7">
        <div class="w-[400px]">
          <app-select-field
            :label="$t('contact.country')"
            class="select-field"
            :options="countryOptions"
            v-model="personalForm.country_of_birth"
            :disabled="
              (currentPath === '/edit-employee' ||
                currentPath === '/edit-expert' ||
                currentPath === '/profile') &&
              isEditEmployee &&
              !isEditButton
            "
          ></app-select-field>
        </div>
      </div>

      <div
        v-if="!isEditEmployee || currentPath !== '/edit-expert'"
        class="flex justify-between gap-3 mb-7 w-[860px]"
      >
        <div></div>
        <div class="flex gap-3">
          <div>
            <app-secondary-button
              @click="previous"
              :buttonLabel="$t('contact.previous')"
            ></app-secondary-button>
          </div>
          <div>
            <app-primary-button
              :buttonLabel="$t('contact.next')"
              @click-event="nextPage"
              v-show="disableSaveButton()"
            >
            </app-primary-button>
          </div>
          <div>
            <app-primary-button
              :buttonLabel="$t('contact.saveAndContinue')"
              @click-event="savePersonalToState('continue')"
              v-show="!disableSaveButton()"
            >
            </app-primary-button>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-end gap-7 mb-7">
        <div v-show="isEditButton">
          <app-secondary-button
            :buttonLabel="$t('personal.cancel')"
            @click="cancel"
          ></app-secondary-button>
        </div>
        <app-primary-button
          v-show="isEditButton"
          :buttonLabel="$t('employment.saveChanges')"
          @click-event="savePersonalToState('edit')"
        >
        </app-primary-button>
      </div>

      <app-alert
        :title="$t(getTranslatedStringKey(alertTitle))"
        @close-alert="alertState = false"
        :state="alertState"
        :timeout="5000"
        :type="alertType"
      />
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import countries from "@/services/country_api";
import { mapState } from "vuex";
import Api from "@/services/api";
import { validEmail } from "@/services/validation";
import { getTransString } from "@/services/helpers";
import { isEqual } from "lodash";

export default defineComponent({
  name: "Contact",
  components: {},
  props: {
    handleButtonRoute: {
      type: Function,
    },
  },
  data() {
    return {
      countryOptions: null,
      isEdit: false,
      alertTitle: "",
      alertType: "success",
      alertState: false,
      firstRun: true,
      originalFormData: {},
      isAppLoading: false,
      personalForm: {
        phone_number: "",
        mobile_number: "",
        house_address: "",
        email: "",
        postal_code: "",
        emergency_phone_number: "",
        isCommunication: false,
        country: "",
        second_address: "",
        city: "",
        user_key: "",
      },
    };
  },
  computed: {
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["hrResources/isEditButton"] &&
        this.canEditPersonalModule?.includes("Contact")
      );
    },
    isCurrentTab() {
      return this.$store.getters["hrResources/isCurrentTab"];
    },
    userKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
    currentPath() {
      return this.$route.path;
    },
    ...mapState("hrResources", ["personalData"]),
  },
  watch: {
    isPersonalData(newValue) {
      this.populateFieldsWithData(newValue, this.personalForm);
      if (this.firstRun) {
        this.populateFieldsWithData(newValue, this.originalFormData);
        this.firstRun = false;
      }
    },
    personalForm: {
      deep: true,
      handler(value) {
        this.originalFormData.country_of_birth = this.countryOptions[64];
        this.$store.commit("expertModules/SET_EMPLOYEE_UPDATE", {
          Contact: !isEqual(value, this.originalFormData),
        });
      },
    },
  },
  methods: {
    disableSaveButton() {
      const personalFormObj = { ...this.personalForm };
      const excludes = ["user_key", "isCommunication", "country_of_birth"];
      const modifiedObject = Object.keys(personalFormObj).reduce(
        (prev, curr) => {
          if (excludes.includes(curr)) {
            return prev;
          } else {
            prev[curr] = personalFormObj[curr];
            return prev;
          }
        },
        {}
      );
      return Object.values(modifiedObject).every((c) => c === "" || c === null);
    },
    nextPage() {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
    },
    previous() {
      const getCurrentTab = this.isCurrentTab - 1;
      this.handleButtonRoute(getCurrentTab);
    },
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    savePersonalToState(data) {
      if (this.personalForm.email && !validEmail(this.personalForm.email)) {
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }

      const updatedPayload = {};
      for (const field in this.isPersonalData) {
        if (this.isPersonalData[field] !== this.personalForm[field]) {
          updatedPayload[field] = this.personalForm[field];
        }
      }

      updatedPayload.user_key = this.userKey;
      if (this.personalForm.isCommunication) {
        updatedPayload.communication_email = this.personalForm.email;
      }

      Api()
        .post("/hr/employees/add-personal-info/", updatedPayload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "hrResources/SET_USER_KEY_TO_STATE",
              response.data.data.user_key
            );

            this.alertType = "success";
            this.alertTitle = "Expert information updated successfully";
            this.alertState = !this.alertState;

            this.$store.commit("expertModules/SET_EMPLOYEE_UPDATE", {
              Contact: false,
            });

            setTimeout(() => {
              if (this.currentPath === "/add-expert") {
                if (data === "continue") {
                  this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 2);
                }
              }
            }, 1500);
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getPersonalContactInfo() {
      this.isAppLoading = true;
      Api()
        .get(
          `/hr/employees/get-employee-personal-contact-info/${this.userKey}/`
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.isAppLoading = false;
            this.$store.commit(
              "hrResources/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
            this.isAppLoading = false;
          }
        });
    },
    populateFieldsWithData(data, saveTo) {
      saveTo.phone_number = data?.phone_number;
      saveTo.mobile_number = data?.mobile_number;
      saveTo.house_address = data?.house_address;
      saveTo.email = data?.email;
      saveTo.postal_code = data?.postal_code;
      saveTo.emergency_phone_number = data?.emergency_phone_number;
      saveTo.country = data?.country;
      saveTo.second_address = data?.second_address;
      saveTo.city = data?.city;
      saveTo.user_key = this.$store.state.hrResources?.userKey;
      saveTo.isCommunication = data?.communication_email === data?.email;
    },
  },
  mounted() {
    this.personalForm.country_of_birth = this.countryOptions[64];
  },
  created() {
    this.getPersonalContactInfo();
    this.countryOptions = this.getCountryByName();
    this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 1);
    this.isEdit = this.isEditEmployee;
  },
});
</script>

<style scoped>
.select-field {
  width: 400px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  /* cursor: not-allowed; */
  color: gray;
  opacity: 0.5;
}
</style>
