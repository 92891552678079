<template>
  <div
    v-if="appLoading"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>
  <div v-else >
    <div class="justify-between mb-3 d-flex align-center">
      <div >
        <p class="text-[18px] font-bold" >
          {{ $t("projects.projectFilesTitle") }}
        </p>
      </div>
      <div>
       
        <div class="">
          <app-primary-button
            :buttonLabel="$t('projects.addFiles')"
            @click="showAddFileDialog"
            v-if="!shouldDisableInput"
          >
            <template #icon>
              <v-icon icon="mdi-plus"></v-icon>
            </template>
          </app-primary-button>
        </div>
      </div>
    </div>
    <div v-if="isEmptyFileState">
      <NoAvailableData
        :page-name="$t('projects.projectFilesTitle')"
        messageTypes="files"
      />
    </div>
    <div v-else>
      <div v-for="(accordionData, index) of accordionInfo" :key="index">
        <General :accordionInfo="accordionData"></General>
      </div>
    </div>
    <create-project-files
      ref="createProjectFiles"
      :fileType="accordionInfo?.name"
      :isDialog="dialogState"
      :file-prop-title="fileTitle"
      :file-prop-path="filePath"
      :is-edit-prop="isEdit"
      :file-name-prop="fileName"
      :file-size="fileSize"
      :edit-object="payload"
    ></create-project-files>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import NoAvailableData from '@/components/shared/NoAvailableData.vue';
import CreateProjectFiles from "./CreateProjectFiles.vue"
import General from './General.vue'

export default defineComponent({
  components:{
    General,
    CreateProjectFiles,
    NoAvailableData,
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isLoading: true,
      count: 0,
      showmenu: false,
      accordionInfo:{
        Tender: {
          name: "Tender",

        },
        Budget: {
          name: "Budget",

        },
        Proposal: {
          name: "Proposal",

        },
        Clients: {
          name: "Clients",

        },
        Partners: {
          name: "Partners",

        },
        Experts: {
          name: "Experts",

        },
        Addendum: {
          name: "Addendum",

        },
        Calculations: {
          name: "Calculations",

        },
        Others: {
          name: "Others",

        }
      }

    }
  },
  watch: {

  },
  computed: {
    showTabs() {
      return this.$store.getters["projectModules/showTabs"];
    },
    isEmptyFileState() {
       return this.$store.getters["projectModules/isEmptyFileState"];
     },
    isSubCostCenter() {
      return this.$store.getters["projectModules/isSubCostCenter"];
    },
    projectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    appLoading() {
      return this.$store.getters["projectModules/isAppLoading"]
    },
    currentPath() {
      return this.$route.path;
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return  this.currentPath === '/edit-project' && this.isEditEmployee && !this.isEditButton
    },
    isForeignEstablishment(){
      return this.$store.getters['contractModules/isForeignEstablishment']
    }
  },
  methods: {
    showAddFileDialog() {
    this.$refs.createProjectFiles.showDialog()
  },
  },
  created() {
    this.showmenu = true
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    const payload = {
      project_id : this.projectID,
      load_page: true
    }
    this.$store.dispatch("projectModules/getAllProjectFiles", payload);
    if(this.showTabs && this.isSubCostCenter){
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 8)
    } else {
      this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 1);
    }
  }
})
</script>
