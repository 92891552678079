<template>
  <div
    v-if="apploader"
    class="d-flex justify-center h-[500px] flex items-center"
  >
    <app-loader></app-loader>
  </div>

  <div v-else>
    <div class="mt-10">
      <v-col cols="4" class="pl-0">
        <app-search-field
          @search-event="fetchAgain(1)"
          @search-function="searchExpertContract"
          :callback="true"
        />
      </v-col>
      <app-table
        :headers="headers"
        :items="isAllContracts"
        :isContracts="false"
        :showCheck="false"
        :hideMenu="true"
        :showCursor="false"
      >
        <template #title>
          <h3 class="H500">{{ $t("contracts.allContracts") }}</h3>
        </template>
        <template #footer>
          <div>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
  </div>

  <app-dialog
    ref="dialog"
    :title="title"
    :subtitle="subTitle"
    height="302"
    width="408"
  >
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6" class="">
          <app-secondary-button
            :buttonLabel="$t('employees.cancel')"
            @click-event="hideDeleteDialog()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteContract()"
          >
            <!-- Yes , delete -->
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { formatDate, getTransString } from "@/services/helpers";
import { defineComponent } from "vue";

import Paginator from "@/components/shared/PaginationComponent.vue";
import API from "@/services/api";

import {
  deleteContractEndpoint,
  getAllLocalContracts,
searchProjectContract,
} from "@/services/endpoints";

export default defineComponent({
  name: "Contracts",
  components: {
    Paginator,
  },
  data() {
    return {
      headers: ["Contract ID", "Start Date", "End Date", "Status"],
      // contractOptions: false,
      // showCreateFreelancerContract: false,
      // showCreateEmploymentContract: false,
      // showCreatePersonnelContract: false,
      // showAdendum: false,
      // showPreview: false,
      title: "",
      subTitle: "",
      currentPages: 1,
      contractType: "",
      deleteContractId: "",
      isEdit: false,
      apploader: false,
    };
  },
  computed: {
    isContractView() {
      return this.$store.getters["contractModules/isContractView"];
    },
    isAllContracts() {
      return this.$store.getters["contractModules/isAllContracts"];
    },
    totalPages() {
      return this.$store.getters["contractModules/isContractPage"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    currentPage() {
      return this.$store.getters["contractModules/isCurrentPage"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isForeignEstablishment() {
      return this.$store.getters["contractModules/isForeignEstablishment"];
    },
    isPreviewToProfile() {
      return this.$store.getters["contractModules/isPreviewToProfile"];
    },
    isContractEditId() {
      return this.$store.getters["contractModules/isContractEditId"];
    },
    isContractType() {
      return this.$store.getters["contractModules/isContractType"];
    },
  },
  methods: {
    showContractOptions() {
      this.contractOptions = !this.contractOptions;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    hideDeleteDialog() {
      this.$refs.dialog.showDialog = false;
    },
    // showDialog(data) {
    //   if (data.data === "edit") {
    //     const editData = data.$event;
    //     this.$store.commit(
    //       "contractModules/SET_CONTRACT_EDIT_ID_TO_STATE",
    //       data.$event.id
    //     );
    //     this.$store.commit(
    //       "contractModules/SET_CONTRACT_TYPE_TO_STATE",
    //       data.$event.url_string
    //     );
    //     this.$store.commit("contractModules/SET_EDIT_CONTRACT_TO_STATE", true);
    //     if (editData.url_string === "freelancer" ) {
    //       this.showFreelancer();
    //       this.$store.commit(
    //         "contractModules/SET_CONTRACT_ID_FREELANCER_TO_STATE",
    //         null
    //       );
    //     } else if (editData.url_string === "employment") {
    //       this.showEmployment();
    //       this.$store.commit(
    //         "contractModules/SET_CONTRACT_ID_EMPLOYMENT_TO_STATE",
    //         null
    //       );
    //     } else if (editData.url_string === "personnel") {
    //       this.showPersonnel();
    //       this.$store.commit(
    //         "contractModules/SET_CONTRACT_ID_PERSONNEL_TO_STATE",
    //         null
    //       );
    //     }
    //   } else if (data.data === "delete") {
    //     this.$refs.dialog.showDialog = true;
    //     this.title = "contracts.deleteContractTitle";
    //     this.subTitle = "contracts.deleteContractTitleConfirm";
    //     this.deleteContractId = data.$event.id;
    //     this.contractType = data.$event.url_string;
    //   }
    // },

    // editAddendum(data) {
    //   if (data.data === "edit") {
    //     const editData = data.$event;
    //     this.$store.commit(
    //       "contractModules/SET_CONTRACT_EDIT_ID_TO_STATE",
    //       data.$event.id
    //     );
    //     this.$store.commit(
    //       "contractModules/SET_CONTRACT_TYPE_TO_STATE",
    //       data.$event.url_string
    //     );
    //     if (editData.url_string === "freelancer" ) {
    //       this.showAdendumPage();
    //       // this.$store.commit("contractModules/SET_CONTRACT_ID_FREELANCER_TO_STATE", data.$event.id)
    //     } else if (editData.url_string === "employment") {
    //       this.showAdendumPage();
    //       // this.$store.commit("contractModules/SET_CONTRACT_ID_EMPLOYMENT_TO_STATE", data.$event.id)
    //     } else if (editData.url_string === "personnel") {
    //       this.showAdendumPage();
    //       // this.$store.commit("contractModules/SET_CONTRACT_ID_PERSONNEL_TO_STATE", data.$event.id)
    //     }
    //   } else if (data.data === "delete") {
    //     this.$refs.dialog.showDialog = true;
    //     this.title = "contracts.deleteContractTitle";
    //     this.subTitle = "contracts.deleteContractTitleConfirm";
    //     this.deleteContractId = data.$event.id;
    //     this.contractType = data.$event.url_string;
    //   }
    // },
    // showFreelancer() {
    //   this.showCreateFreelancerContract = true;
    //   this.contractOptions = false;
    //   this.showCreateEmploymentContract = false;
    //   this.showCreatePersonnelContract = false;
    //   this.showAdendum = false;
    //   this.showPreview = false;
    // },
    // showEmployment() {
    //   this.showCreateFreelancerContract = false;
    //   this.contractOptions = false;
    //   this.showCreateEmploymentContract = true;
    //   this.showCreateFreelancerContract = false;
    //   this.showAdendum = false;
    //   this.showPreview = false;
    // },
    // showPersonnel() {
    //   this.showCreatePersonnelContract = true;
    //   this.showAdendum = false;
    //   this.showPreview = false;
    //   this.contractOptions = false;
    //   this.showCreateEmploymentContract = false;
    //   this.showCreateFreelancerContract = false;
    // },

    // showAdendumPage() {
    //   this.showAdendum = true;
    //   this.showPreview = false;
    //   this.showCreatePersonnelContract = false;
    //   this.contractOptions = false;
    //   this.showCreateEmploymentContract = false;
    //   this.showCreateFreelancerContract = false;
    // },
    // showPreviewPage(data) {
    //   this.$router.push({ name: "contract-approval" });
    //   this.$store.commit("contractModules/SET_CONTRACT_EDIT_ID_TO_STATE",data.$event.id);
    //   this.$store.commit("contractModules/SET_CONTRACT_TYPE_TO_STATE",data.$event.url_string)
    // },

    // showAllContracts() {
    //   this.showCreateFreelancerContract = false;
    //   this.showCreateEmploymentContract = false;
    //   this.showCreatePersonnelContract = false;
    //   this.showAdendum = false;
    //   this.showPreview = false;
    //   this.$store.commit("contractModules/SET_CONTRACT_VIEW_TO_STATE", false);
    //   this.$store.commit("contractModules/SET_EDIT_CONTRACT_TO_STATE", false);
    //   this.getAllContracts(this.currentPage);
    // },

      fetchAgain(page){
        this.searchExpertContract(page);
      },
    searchExpertContract(search_query){
      
      API()
        .post(`${searchProjectContract}${this.isProjectID}/${this.currentPage}/`, {search_query})
        .then((res) => {
          const resdata = res.data.data;
          this.apploader = false;
          const modifiedResponseData = resdata?.map((modData) => {
            const formattedStartDate = formatDate(modData.contract_start_date);
            const formattedEndDate = formatDate(modData.contract_end_date);
            return {
              "contract id": modData.id,
              "start date": `${formattedStartDate}`,
              "end date": `${formattedEndDate}`,
              status: modData.is_signed ? "Signed" : "Not signed",
              contract_type: modData.contract_type,
            };
          });

          this.$store.commit(
            "contractModules/SET_ALL_CONTRACTS_TO_STATE",
            modifiedResponseData
          );
          this.$store.commit(
            "contractModules/SET_CONTRACT_PAGES_TO_STATE",
            res.data.total_data
          );
        })
        .catch(() => {
          
        });
    },
    getAllContracts(page) {
      this.apploader = true;
      API()
        .get(`${getAllLocalContracts}${this.isProjectID}/${page}`)
        .then((res) => {
          const resdata = res.data.data;

          this.apploader = false;
          const modifiedResponseData = resdata?.map((modData) => {
            const formattedStartDate = formatDate(modData.contract_start_date);
            const formattedEndDate = formatDate(modData.contract_end_date);
            return {
              "contract id": modData.id,
              "start date": `${formattedStartDate}`,
              "end date": `${formattedEndDate}`,
              status: modData.is_signed ? "Signed" : "Not signed",
              contract_type: modData.contract_type,
            };
          });

          this.$store.commit(
            "contractModules/SET_ALL_CONTRACTS_TO_STATE",
            modifiedResponseData
          );
          this.$store.commit(
            "contractModules/SET_CONTRACT_PAGES_TO_STATE",
            res.data.total_data
          );
        })
        .catch(() => {
          this.apploader = false;
        });
    },
    deleteContract() {
      API()
        .delete(
          `${deleteContractEndpoint}${this.contractType}/${this.deleteContractId}`
        )
        .then((res) => {
          if (res.data.status === "success") {
            this.$refs.dialog.showDialog = false;
            this.alertState = true;
            this.alertTitle = res?.data?.detail;
            this.getAllContracts(this.currentPage);
          }
        });
    },
    loadMore() {
      let page = this.currentPages;
      this.getAllContracts(page);
    },
  },
  watch: {
    isContractView(newValue) {
      if (newValue) {
        this.showAllContracts();
      }
    },
  },
  mounted() {
    if (this.currentPage) {
      this.getAllContracts(this.currentPage);
    }
  },
  beforeUnmount() {
    this.$store.commit("contractModules/SET_PREVIEW_PROFILE_TO_STATE", false);
  },
  created() {
    if (this.isPreviewToProfile) {
      this.$store.commit("contractModules/SET_EDIT_CONTRACT_TO_STATE", true);
      const data = {
        data: "edit", // or "delete" depending on your use case
        $event: {
          id: this.isContractEditId,
          url_string: this.isContractType,
        },
      };
      this.showDialog(data);
    } else {
      this.$store.commit("contractModules/SET_CONTRACT_LINKED_TO_STATE", false);
      this.$store.commit("contractModules/SET_CONTRACT_VIEW_TO_STATE", false);
      this.$store.commit("contractModules/SET_EDIT_CONTRACT_TO_STATE", false);
    }

    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
  },
});
</script>
