<template>
  <v-sheet class="bg-transparent mb-10" max-width="1440">
    <div v-for="(accordion, index) of filterAccordion()" :key="index">
      <app-accordion :accordionInfo="accordion">
        <template #content>
          <div>
            <app-custom-table
                :title="$t('All Fee Invoices'.getTranslationKey())"
                :headers="tableHeaders"
                :items="invoiceData[accordion.tag]"
                show-menu
                :dropdown-options="['View Invoice']"
                @menu-click="handleViewInvoice"
                :column-widths="{requested: '10%', approved: '10%'}"
            >
              <template #action-btn>
                <app-primary-button
                    :buttonLabel="'Generate Invoice'.getTranslationKey()"
                    data-cy="btn_feeInvoice"
                    @click=" invoiceDialog(accordion.signature_type)"
                >
                  <template #icon>
                    <v-icon icon="mdi-plus"></v-icon>
                  </template>
                </app-primary-button>
              </template>
              <template #srNo="{item}">
                <p class="ml-4">{{item.srNo}}</p>
              </template>
              <template #status="{item}">
                <span :class="statusBackgroundColor(item.status)" class="px-3 py-1 rounded-full text-sm">{{item.status}}</span>
              </template>
              <template #approved="{item}">
                <span>{{item.approved ? item.approved : "-"}}</span>
              </template>
              <template #header-request_date="{item}">
                <div class="text-center">
                  <span>{{item}}</span>
                </div>
              </template>
              <template #request_date="{item}">
                <div class="text-center">
                  <span>{{item['request date'] ? formatDate(item['request date'], locale) : '_'}}</span>
                </div>
              </template>
              <template v-for="slotName in ['requested', 'approved']" v-slot:[slotName]="{item}" :key="slotName">
                <div :class="{'text-right': item[slotName]}">
                  <span>{{item[slotName] ? locale === 'de' ? item[slotName].germanCurrencyFormatter() : item[slotName].formatCurrency() : '_'}}</span>
                </div>
              </template>
            </app-custom-table>
          </div>
        </template>
      </app-accordion>
    </div>
    <signature-form ref="signatureForm"></signature-form>
  </v-sheet>
  <v-dialog max-width="600" v-model="isLoading" persistent >
    <app-loader class=" w-full flex items-center justify-center"></app-loader>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import InvoiceTickets from "./InvoiceTickets.vue";
import TravelExpense from "./TravelExpense.vue";
import FeeInvoice from "./FeeInvoice.vue";
import SignatureForm from "@/components/dashboard/projects/localExpert/SignatureForm.vue";
import Api from "@/services/api";
import {getInvoiceDetailsForSettlement, getMissionPlanningInvoice} from "@/services/endpoints";
import {formatDate} from "../../../../services/helpers";
export default defineComponent({
  name: "Accordions",
  components: {
    // FeeInvoice,
    SignatureForm,
    // InvoiceTickets,
    // TravelExpense,
    // FeeInvoice,
  },
  data() {
    return {
      isLoading: false,
      tableHeaders: [
        {
          key: "srNo",
          label: "Sr. No.",
        },
        {
          key: "requested",
          label: "Requested",
        },
        {
          key: "request_date",
          label: "Request Date",
        },
        {
          key: "approved",
          label: "Approved",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      accordionInfo: {
        FeeInvoice: {
          tag: "fee_invoices",
          name: "Fee Invoice",
          tableTitle: "All Fee Invoices",
          attribute: "isFeeInvoice",
          signature_type: "Fees",
          component: FeeInvoice,
        },

        TravelExpense: {
          tag: "travel_invoices",
          name: "Travel Expense",
          tableTitle: "All Travel Invoices",
          attribute: "isTravelExpense",
          signature_type: "Travel",
          component: TravelExpense,
        },
        InvoiceTickets: {
          tag: "ticket_invoices",
          name: "Tickets",
          tableTitle: "All Ticket Invoices",
          attribute: "isTicketInvoice",
          signature_type: "Tickets",
          component: InvoiceTickets,
        },
        ReportInvoice: {
          tag: "report_invoices",
          name: "Reports",
          tableTitle: "All Report Invoices",
          attribute: "isReportInvoice",
          signature_type: "Report",
          component: InvoiceTickets
        }
      },
    };
  },
  computed:{
    EditMissionId() {
      return this.$store.getters["contractModules/isEditMissionId"];
    },
    isFeeInvoice(){
      return this.$store.getters["projectModules/isFeeInvoice"]
    },
    isTravelExpense(){
      return this.$store.getters["projectModules/isTravelExpense"]
    },
    isTicketInvoice(){
      return this.$store.getters["projectModules/isTicketInvoice"]
    },
    isReportInvoice(){
      return this.$store.getters["projectModules/isReportInvoice"]
    },
    invoiceData(){
      return this.$store.getters["projectModules/getInvoiceData"]
    },
    statusBackgroundColor(){
      return (status) => {
        return status === "Paid" ? "bg-[#ECFDF3] text-[#027A48]" : "bg-[#FFFAEB] text-[#B54708]";
      }
    },
    locale() {
      return this.$store.getters["translation/getLocale"]
    }
  },
  methods: {
    formatDate,
    handleViewInvoice(data) {
      this.isLoading = true
      Api()
          .get(`${getMissionPlanningInvoice}${data.srNo}`)
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("projectModules/SET_SETTLEMENT_DATA_FOR_VIEWING", response.data.data)
              this.$refs.signatureForm.showSignatureDialog(data.settlement_type, 'view')
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false
          });
    },
    filterAccordion(){
      let newAccordion = this.accordionInfo
      const accordionArrays = []
      const accordionKeys = {
        "isFeeInvoice": "FeeInvoice",
        "isTravelExpense": "TravelExpense",
        "isTicketInvoice": "InvoiceTickets",
        "isReportInvoice": "ReportInvoice"
      }

      Object.keys(accordionKeys).forEach((key) => {
        if(this[key]){
          accordionArrays.push(newAccordion[accordionKeys[key]])
        }
      })
      return accordionArrays
    },
    invoiceDialog(type) {
      this.isLoading = true
      Api()
          .get(`${getInvoiceDetailsForSettlement}${this.EditMissionId}`)
          .then((response) => {
            if (response.data.status === "success") {
              this.$store.commit("projectModules/SET_SETTLEMENT_DATA", response.data.data)
              this.$refs.signatureForm.showSignatureDialog(type, 'generate')
            }
          })
          .catch()
          .finally(() => {
            this.isLoading = false
          })
    }
  },
  created() {},
  mounted() {},
});
</script>
