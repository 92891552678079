<template>
  <div class="!divide-y my-10"></div>
  <div
    class="!bg-[#FFFCF5] outline outline-1 outline-[#FEC84B] mt-1 p-2 flex items-center"
    v-if="isAllBalanceInfoData?.locked_month_details?.is_locked"
  >
    <div>
      <div
        class="p-[2px] flex text-sm bg-[#FFFAEB] items-center rounded-lg w-fit"
      >
        <p class="bg-[#DC6803] rounded-lg px-2 text-[#FFFCF5] mr-2">
          {{ $t("Month Locked".getTranslationKey()) }}
        </p>
        <p class="text-[#B54708] mr-2">
          {{ formattedDate(isAllBalanceInfoData?.locked_month_details?.locked_on) }}
          
        </p>
        <p class="text-[#B54708] mr-2">
          | {{ isAllBalanceInfoData?.locked_month_details?.locked_by_first_name }} {{ isAllBalanceInfoData?.locked_month_details?.locked_by_last_name }}
        </p>
      </div>
    </div>
  </div>
  <div class="flex justify-between my-6">
    <div class="flex w-4/5 gap-4">
      <div v-for="(label, index) in buttonLabel" :key="label">
        <app-secondary-button
          :buttonLabel="label"
          :class="[
            '!font-medium ',
            { 'active-button !text-[#A2544F]': activeButton === label },
          ]"
          @click="handleOnClick(label, index)"
        >
        </app-secondary-button>
      </div>
    </div>
    <app-secondary-button
      :buttonLabel="$t('Lock this month'.getTranslationKey())"
      @click="openLockMonth()"
      v-if="showLockButton && !isAllBalanceInfoData?.locked_month_details?.is_locked"
    >
      <template #icon> <v-icon icon="mdi-lock"></v-icon> </template
    ></app-secondary-button>
    <app-primary-button
      :buttonLabel="$t('Unlock this month'.getTranslationKey())"
      @click="handleUnlockMonth()"
      v-if="showLockButton && isAllBalanceInfoData?.locked_month_details?.is_locked"
    >
      <template #icon> <v-icon icon="mdi-lock-open"></v-icon> </template
    ></app-primary-button>
  </div>
  <!-- {{ lockMonthData }} -->
  <balance-info :passedData="passedData"></balance-info>
  
  <app-dialog
    ref="dialog"
    :title="$t('Lock'.getTranslationKey()) + ` ${lockMonthData.month} ${lockMonthData.year}`"
    :subtitle="subTitle"
    height="400"
    width="500"
  >
    <!-- <template #content>
      <v-row class="mt-4 pa-0">
        <v-col cols="">
          <div>
            <div>
              <div class="flex flex-col mt-0 items-left">
                <div
                  class="h-[60px] border-b border-dotted border-gray-900 w-2/4 p-0 flex justify-left items-center bg-white"
                >
                  <img
                    :src="imageUrl"
                    alt=""
                    class="max-h-[60px] min-w-[100px] max-w-[200px]"
                    v-if="showSignature"
                  />
                  <p
                    @click="signOrUpload"
                    class="text-[#8B2923] text-sm cursor-pointer"
                    v-if="!showSignature"
                  >
                    {{ $t("requestFundsTab.clickToSign") }}
                  </p>
                </div>
              </div>
              <input
                type="file"
                @change="handleFileUpload"
                style="display: none"
                ref="fileInput"
                accept="image/*"
              />
            </div>
            <div
              class="text-gray-700 text-sm font-semibold leading-7 h-[30px] w-2/4 p-0 flex justify-left mt-2"
            >
            {{ isFullName }}
            </div>
          </div>
        </v-col>
      </v-row>
    </template> -->
    <template #actions>
      <div class="flex items-end justify-end w-full">
        <v-col cols="3" class="">
          <app-secondary-button
            :buttonLabel="$t('Cancel'.getTranslationKey())"
            @click="closeLockMonth()"
          ></app-secondary-button>
        </v-col>
        <v-col cols="3" class="">
          <app-primary-button
            :buttonLabel="$t('Lock'.getTranslationKey())"
            @click="handleLockMonth()"
          ></app-primary-button>
        </v-col>
      </div>
    </template>
  </app-dialog>

  <app-dialog
    ref="dialogUploadState"
    width="586"
    height="800"
    :title="$t('contracts.cropAnduploadSiganture')"
    :subtitle="``"
  >
    <template #content>
      <VueCropper
        v-if="selectedFile"
        ref="cropper"
        :src="imageUrl"
        view-mode="1"
        :guides="true"
        :auto-crop="true"
        :auto-crop-area="0.5"
        :movable="true"
        :zoomable="true"
        :drag-mode="'crop'"
        :data="{ width: 400, height: 400 }"
      ></VueCropper>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('projects.cancel')"
            @click-event="cancelUpload()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('contracts.uploadSignature')"
            :loading="isLoading"
            @click-event="cropImage()"
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
  
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script>
import { defineComponent } from "vue";
import BalanceInfo from "./BalanceInfo.vue";
import AppSecondaryButton from "@/components/shared/AppSecondaryButton.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import API from "@/services/api";
import { uploadEmployeeSignature, lockPmLocalMonth, unLockPmLocalMonth } from "@/services/endpoints";
import { formatDate, getTransString } from "@/services/helpers";
export default defineComponent({
  components: { BalanceInfo, AppSecondaryButton, VueCropper },
  data() {
    return {
      activeButton: null,
      buttonLabel: [
        "Bank/Cash Transfer",
        "Currency Exchange",
        "Average Exchange Rate",
        "Fund/Expense Overview",
        // "Cash Overview",
      ],
      passedData: null,
      title: "",
      subTitle: "",
      dialogUploadState: false,
      imageUrl: null,
      selectedFile: null,
      showSignature: false,
      lockedMonth: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
    };
  },
  props: {
    showLockButton: {
      type: Boolean,
      default: false,
    },
    lockMonthData: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    isAllBalanceInfoData() {
      return this.$store.getters["projectOverview/isAllBalanceInfoData"];
    },
    isFullName(){
      return this.$store.getters["auth/isfullName"];
    },
    isForeignEstablishmentId() {
      return this.$store.getters["pmLocalModules/isForeignEstablishmentID"];
    },
    isProjectId() {
      return this.$store.getters["pmLocalModules/isProjectId"];
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    formattedDate(date) {
      return formatDate(date);
    },
    handleOnClick(label, index) {
      this.activeButton = label;
      if (this.buttonLabel[index] === "Bank/Cash Transfer") {
        this.passedData = this.isAllBalanceInfoData?.bank_cash_transfer;
      } else if (this.buttonLabel[index] === "Currency Exchange") {
        this.passedData = this.isAllBalanceInfoData?.currency_exchange_rate;
      } else if (this.buttonLabel[index] === "Average Exchange Rate") {
        this.passedData = this.isAllBalanceInfoData
      } else if (this.buttonLabel[index] === "Fund/Expense Overview") {
        this.passedData = this.isAllBalanceInfoData?.funds_overview;
      }
    },
    closeLockMonth() {
      this.$refs.dialog.showDialog = false;
    },
    openLockMonth() {
      this.$refs.dialog.showDialog = true;
      this.subTitle = "projectOverview.lockMessage";
    },
    handleLockMonth() {
      API()
      .post(`${lockPmLocalMonth}${this.isProjectId}/${this.isForeignEstablishmentId}/`, {
        month: this.lockMonthData.month,
        year: this.lockMonthData.year,
      })
      .then(response => {
        if (response.data.status === "success"){
          this.alertState = true;
          this.alertType = "success";
          this.alertTitle = response.data.details;
          this.closeLockMonth()
        }
      })
      .catch(error => {
        this.alertState = true;
        this.alertType = "error";
        this.alertTitle = error.response.data.detail;
        this.closeLockMonth()
      })
    },
    handleUnlockMonth() {
      API()
      .put(`${unLockPmLocalMonth}${this.isProjectId}/${this.isForeignEstablishmentId}/`, {
        month: this.lockMonthData.month,
        year: this.lockMonthData.year,
      })
      .then(response => {
        if (response.data.status === "success"){
          this.alertState = true;
          this.alertType = "success";
          this.alertTitle = response.data.details;
          this.closeLockMonth()
        }
      })
      .catch(error => {
        this.alertState = true;
        this.alertType = "error";
        this.alertTitle = error.response.data.detail;
        this.closeLockMonth()
      })
    },
    openFileInput() {
      this.showSignature = true;
      this.$refs.fileInput.click();
    },
    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   if (!file) {
    //     return;
    //   }
    //   const validateImageType = ["image/jpeg", "image/png", "image/jpg"];
    //   if (!validateImageType.includes(file.type)) {
    //     this.alertType = "error";
    //     this.alertState = true;
    //     this.alertTitle = "Please upload a valid image file.";
    //     return;
    //   }
    //   this.selectedFile = file;
    //   this.imageUrl = URL.createObjectURL(file);
    //   this.$refs.dialogUploadState.showDialog = true;
    // },
    // cropImage() {
    //   this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
    //     this.selectedFile = new File([blob], "cropped-image.jpg", {
    //       type: "image/jpeg",
    //     });
    //     this.imageUrl = URL.createObjectURL(this.selectedFile);
    //     this.uploadImage();
    //   }, "image/jpeg");
    //   this.$refs.dialogUploadState.showDialog = false;
    // },
    // cancelUpload() {
    //   this.imageUrl = null;
    //   this.$refs.fileInput.value = "";
    //   this.selectedFile = null;
    //   this.$refs.dialogUploadState.showDialog = false;
    //   // this.$refs.dialog.showDialog = false;
    // },
    // uploadImage() {
    //   if (!this.selectedFile) {
    //     alert("Please select an image to upload.");
    //     return;
    //   }

    //   const formData = new FormData();
    //   formData.append("signature", this.selectedFile);
    //   API()
    //     .post(`${uploadEmployeeSignature}`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then(async (response) => {
    //       if (response.data.status === "success") {
    //         this.alertType = "success";
    //         this.alertState = true;
    //         this.alertTitle = response?.data?.detail;
    //         await this.fetchRequestedFundStats({
    //           foreignEstablishment: this.foreignEstablishment,
    //         });

    //         this.$store.commit(
    //           "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE",
    //           this.signature
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       this.alertType = "error";
    //       this.alertState = true;
    //       this.alertTitle = "Error uploading image. Please try again.";
    //     });
    // },
    // signOrUpload() {
    //   if (this.signature) {
    //     this.imageUrl = this.signature;
    //     this.$store.commit(
    //       "pmLocalRequestFund/SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE",
    //       this.signature
    //     );
    //     this.showSignature = true;
    //   } else {
    //     this.openFileInput();
    //   }
    // },
  },
  created() {
    this.activeButton = this.buttonLabel[0];
    this.handleOnClick(this.buttonLabel[0], 0);
  },
});
</script>
<style scoped>
.active-button {
  background-color: #f4eae9 !important;
  border-radius: 8px !important;
}
</style>
