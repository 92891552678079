<template>
  <v-sheet>
    <app-dialog
      ref="dialog"
      height="302"
      width="408"
      :title="$t('Add/View Files(s)'.getTranslationKey())"
    >
      <template #content>
        <input
          type="file"
          hidden
          multiple
          ref="fileInput"
          @change="selectedFile"
          accept="application/pdf"
        />
        <div
          @click="pickFile"
          class="flex flex-col items-center justify-center py-4 border rounded-lg cursor-pointer"
        >
          <p class="text-[#A2544F]">
            {{ $t("Click to upload".getTranslationKey()) }}
          </p>
          <p class="text-[#667085] text-xs">
            {{ $t("Only PDF can be uploaded.".getTranslationKey()) }}
          </p>
        </div>
        <v-row v-if="uploading" class="">
          <v-col v-for="(singleUpload, index) of uploadedFiles" :key="index">
            <div
              :class="[
                'px-4 py-2 mt-6 text-[#667085] !rounded-lg border border-gray-200',
                { '!border-[#B97F7B]': singleUpload?.progress === 100 },
              ]"
            >
              <v-row v-show="!isEdit">
                <v-col cols="12">
                  <div class="d-flex justify-space-between">
                    <p
                      class="truncate !font-inter !font-medium !text-[0.875rem] text-[#344054]"
                      @click="pickFile"
                    >
                      {{ singleUpload?.name }}
                    </p>
                    <span class="ml-2">
                      <v-icon
                        v-if="singleUpload?.progress === 100"
                        icon="mdi:mdi-close-circle"
                        color="#AE6A65"
                        size="20"
                        @click="removeFile(index)"
                      ></v-icon>
                      <v-progress-circular
                        v-else
                        :model-value="singleUpload?.progress"
                        :width="3"
                        color="#AE6A65"
                      ></v-progress-circular>
                    </span>
                  </div>
                  <p
                    class="mt-0 !font-['inter'] !font-normal !text-sm text-[#667085]"
                  >
                    {{ singleUpload?.size }} - {{ singleUpload?.progress }}%
                    {{
                      singleUpload?.progress === 100
                        ? $t("projects.fileUploaded")
                        : $t("projects.fileUploading")
                    }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div class="flex w-full gap-6 mx-4 mb-4">
          <app-secondary-button
            :buttonLabel="$t('Cancel'.getTranslationKey())"
            @click="closeDialog"
            class="w-1/2"
          ></app-secondary-button>
          <app-primary-button
            :buttonLabel="$t('Save'.getTranslationKey())"
            @click="sendInvoiceFile()"
            class="w-1/2"
          ></app-primary-button>
        </div>
      </template>
    </app-dialog>
  </v-sheet>
</template>

<script>
import { formatBytes, getTransString } from "@/services/helpers";
import { defineComponent } from "vue";
import UploadApi from "@/services/fileUploadApi";
import Queue from "@/services/Queue";
import { deleteInvoiceFile, uploadInvoiceFile } from "@/services/endpoints";
import Api from "@/services/api";
export default defineComponent({
  name: "InvoiceFileUpload",
  props:{
    executeFunction: Function
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      fileList: [],
      queueUpload: new Queue(),
      queueSize: null,
      lastItem: null,
      uploading: false,
      uploadedFiles: [],
      count: 0,
    };
  },
  computed: {
    isInvoiceFileType() {
      return this.$store.getters["invoiceModule/isInvoiceFileType"];
    },
    isInvoiceNumberForFile() {
      return this.$store.getters["invoiceModule/isInvoiceNumberForFile"];
    },
  },
  watch: {
    queueSize(newSize) {
      if (newSize > 0) {
        this.uploadFiles();
      }
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    showDialog() {
      this.$refs.dialog.showDialog = true;
    },
    closeDialog() {
      this.$refs.dialog.showDialog = false;
      this.$store.commit("invoiceModule/SET_INVOICE_FILE_TYPE", null);
      this.$store.commit("invoiceModule/SET_INVOICE_NUMBER_FOR_FILE", null);
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.removeInvoiceFile();
    },
    pickFile() {
      this.$refs.fileInput.click();
    },
    selectedFile(event) {
      this.uploading = true;
      this.filelist = "";
      this.filelist = [...event.target.files];

      this.validateAndInitialUpload();
    },
    validateAndInitialUpload() {
      for (let i = 0; i < this.filelist.length; i++) {
        const file = this.filelist[i];
        this.queueUpload.enqueue(file);
        this.queueSize = this.queueUpload.getSize();

        if (file.size > 16777216) {
          this.isLoading = false;
          this.alertState = true;
          (this.alertType = "error"),
            (this.alertTitle = "You cannot upload file size more than 16mb");

          return;
        } else {
          this.uploadedFiles?.push({
            name: file.name,
            progress: 0,
            size: formatBytes(file.size),
          });
        }

        this.config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            this.uploadedFiles[
              this.count % this.uploadedFiles?.length
            ].progress = progress;
          },
        };
      }
      this.lastItem =
        this.uploadedFiles?.length > 0
          ? this.uploadedFiles[this.uploadedFiles?.length - 1]
          : undefined;
    },
    uploadFiles() {
      const file = this.queueUpload.peek();
      const formData = new FormData();
      formData.append("invoice_files", file);
      UploadApi()
        .patch(
          `${uploadInvoiceFile}${this.isInvoiceNumberForFile}/?file_type=${this.isInvoiceFileType}`,
          formData,
          this.config
        )
        .then(async (response) => {
          if (response.data.status === "success") {
            this.uploaded = true;
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.details;
            this.fileList.push(response?.data?.data[0]);
            this.$store.commit(
              "commonResources/SET_DOCUMENT_URL_TO_STATE",
              this.fileList
            );
            const isEmpty = this.queueUpload.isEmpty();
            if (!isEmpty) {
              this.queueUpload.removeItem(0);
              this.queueSize = this.queueUpload.getSize();
              this.count++;
            } else {
              this.count = 0;
              this.uploadedFiles = [];
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.uploaded = false;
            this.alertType = "error";
            this.alertState = true;
            this.alertTitle = "Error uploading PDF. Please try again.";
          }
        });
    },
    sendInvoiceFile(){
      this.executeFunction();
      this.closeDialog();
    },
    removeInvoiceFile(){
        Api()
        .delete(`${deleteInvoiceFile}${this.isInvoiceNumberForFile}?file_type=${this.isInvoiceFileType}`)
        .then(response => {
          if(response.data.status === "success"){
            this.alertState = true;
            this.alertTitle = response.data.detail;
            this.$store.commit("invoiceModule/SET_INVOICE_FILE_TYPE", null);
            this.$store.commit("invoiceModule/SET_INVOICE_NUMBER_FOR_FILE", null);
            this.$store.commit("commonResources/SET_DOCUMENT_URL_TO_STATE", null);
          }
        })
        .catch()
    }
  },
});
</script>
