<template>
  <div
    v-if="appLoading"
    class="d-flex justify-center h-[500px] flex items-center relative"
  >
    <app-loader></app-loader>
  </div>
  <div v-else>
    <div>
      <v-sheet max-width="1125" class="mt-5 mb-8 bg-transparent">
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-single-select
              :label="$t('Cost Center'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select Cost Center'.getTranslationKey())"
              :options="allCostCentres"
              v-model="flightForm.cost_centre_id"
              :showSearch="true"
              
              :searchPlaceholder="$t('Search cost center'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('cost_centre_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" class="pr-4">
            <app-single-select
              :label="$t('Flight Type'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select flight type'.getTranslationKey())"
              :options="isFlightTypeOptions"
              v-model="flightForm.flight_type"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('flight_type')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              :label="$t('Flight For'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select flight for'.getTranslationKey())"
              :options="flightForOptions"
              v-model="flightForm.flight_for"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('flight_for')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>

        <v-row v-show="moreOptions">
          <v-col cols="5" class="pr-4">
            <app-single-select
              v-if="
                flightForm.flight_for === 'Expert with contract' ||
                flightForm.flight_for === 'Expert without contract'
              "
              :label="$t('Expert'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select expert'.getTranslationKey())"
              :options="expertArray"
              v-model="flightForm.expert_id"
              @update:modelValue="fetchExpertEmail"
              :showSearch="true"
              :searchPlaceholder="$t('Search expert'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('expert_id')"
              :isRequired="true"
            ></app-single-select>

            <app-single-select
              v-else-if="
                flightForm.flight_for === 'Firm with contract' ||
                flightForm.flight_for === 'Firm without contract'
              "
              :label="$t('Firm'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select firm'.getTranslationKey())"
              :options="firmArray"
              v-model="flightForm.partner_id"
              :showSearch="true"
              :searchPlaceholder="$t('Search firm'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('partner_id')"
              :isRequired="true"
            ></app-single-select>
            <div v-else-if="flightForm.flight_for === 'Employee'">
              <app-input-field
                v-if="filterFormsByCurrentRoute"
                :label="$t('Employee'.getTranslationKey()) + '*'"
                :placeholder="$t('Enter employee name'.getTranslationKey())"
                v-model="currentUserFullName"
                disabled
              ></app-input-field>
              <app-single-select
                v-else
                :label="$t('Employee'.getTranslationKey()) + '*'"
                :labelPlaceholder="$t('Select employee'.getTranslationKey())"
                :options="employeeArray"
                v-model="flightForm.employee_id"
                :showSearch="true"
                :searchPlaceholder="$t('Search employee'.getTranslationKey())"
                :disabled="shouldDisableInput || disabledFormInputs"
                :hasError="isFieldInvalid('employee_id')"
                :isRequired="true"
              ></app-single-select>
            </div>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              v-if="flightForm.flight_for === 'Expert with contract'"
              :label="$t('Contract'.getTranslationKey())"
              :labelPlaceholder="$t('Select contract'.getTranslationKey())"
              :options="contractArray"
              v-model="flightForm.contract_id"
              :showSearch="true"
              :searchPlaceholder="$t('Search contract'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('contract_id')"
              :isRequired="true"
            ></app-single-select>
            <app-single-select
              v-if="
                flightForm.flight_for === 'Firm with contract' ||
                flightForm.flight_for === 'Firm without contract'
              "
              :label="$t('Expert'.getTranslationKey())"
              :labelPlaceholder="$t('Select expert'.getTranslationKey())"
              :options="expertInFirmArray"
              v-model="flightForm.firm_expert_name"
              :showSearch="true"
              :searchPlaceholder="$t('Search expert'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('firm_expert_name')"
              :isRequired="true"
            ></app-single-select>

            <app-input-field
              v-if="flightForm.flight_for === 'Expert without contract'"
              :label="$t('Expert Email'.getTranslationKey())"
              :placeholder="$t('Enter expert email'.getTranslationKey())"
              v-model="flightForm.expert_email"
              type="email"
              :disabled="true"
              :hasError="isFieldInvalid('expert_email')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row v-if="flightForm.flight_for === 'Firm with contract'">
          <v-col cols="5" class="pr-4">
            <app-single-select
              :label="$t('Contract'.getTranslationKey())"
              :labelPlaceholder="$t('Select contract'.getTranslationKey())"
              :options="contractArray"
              v-model="flightForm.contract_id"
              :showSearch="true"
              :searchPlaceholder="$t('Search contract'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('contract_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-input-field
              v-if="
                flightForm.flight_for === 'Expert with contract' ||
                flightForm.flight_for === 'Expert without contract' ||
                flightForm.flight_for === 'Employee'
              "
              :label="$t('Passport'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter passport number'.getTranslationKey())"
              v-model="flightForm.passport_number"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('passport_number')"
              :isRequired="true"
            ></app-input-field>
            <app-single-select
              v-if="
                flightForm.flight_for === 'Firm with contract' ||
                flightForm.flight_for === 'Firm without contract'
              "
              :label="$t('Country'.getTranslationKey()) + '*'"
              :labelPlaceholder="
                $t('Select passport country'.getTranslationKey())
              "
              :options="isCountries"
              v-model="flightForm.passport_country"
              :showSearch="true"
              :searchPlaceholder="$t('Search country'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('passport_country')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              v-if="
                flightForm.flight_for === 'Expert with contract' ||
                flightForm.flight_for === 'Expert without contract' ||
                flightForm.flight_for === 'Employee'
              "
              :label="$t('Country'.getTranslationKey()) + '*'"
              :labelPlaceholder="
                $t('Select passport country'.getTranslationKey())
              "
              :options="isCountries"
              v-model="flightForm.passport_country"
              :showSearch="true"
              :searchPlaceholder="$t('Search country'.getTranslationKey())"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('passport_country')"
              :isRequired="true"
            ></app-single-select>
            <app-input-field
              v-if="
                flightForm.flight_for === 'Firm with contract' ||
                flightForm.flight_for === 'Firm without contract'
              "
              :label="$t('Passport'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter passport number'.getTranslationKey())"
              v-model="flightForm.passport_number"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('passport_number')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-input-field
              :label="$t('Flights'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter number of flights'.getTranslationKey())"
              v-model.number="flightForm.number_of_flights"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('number_of_flights')"
              :isRequired="true"
              validation="digitOnly"
            ></app-input-field
          ></v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              :label="$t('Route'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter Route'.getTranslationKey())"
              v-model="flightForm.route"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('route')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-single-select
              :label="$t('Requester'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select requester'.getTranslationKey())"
              :searchPlaceholder="$t('Search requester'.getTranslationKey())"
              :options="requesterArray"
              v-model="flightForm.requester_id"
              :showSearch="true"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('requester_id')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              :label="$t('Request Date'.getTranslationKey()) + '*'"
              v-model="formattedDate"
              :disabled="true"
              :hasError="isFieldInvalid('requested_on')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4"
            ><app-date-field
              type="date"
              :label="$t('Departure Date'.getTranslationKey()) + '*'"
              v-model="flightForm.departure_date"
              :maxDate="maxStartDate(flightForm.return_date)"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('departure_date')"
              :isRequired="true"
            ></app-date-field
          ></v-col>
          <v-col cols="5" class="pl-4"
            ><app-date-field
              type="date"
              :label="$t('Return Date'.getTranslationKey()) + '*'"
              v-model="flightForm.return_date"
              :min="minEndDate(flightForm.departure_date)"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('return_date')"
              :isRequired="true"
            ></app-date-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-input-field
              :label="$t('Book via'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter booking medium'.getTranslationKey())"
              v-model="flightForm.book_via"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('book_via')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              :label="$t('Budget Item'.getTranslationKey())"
              :labelPlaceholder="$t('Select budget item'.getTranslationKey())"
              :showSearch="true"
              :searchPlaceholder="$t('hospitalityPackage.searchBudgetItem')"
              :options="budgetItemOptions"
              v-model="flightForm.budget_id"
              :disabled="shouldDisableInput || disabledFormInputs"
            ></app-single-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-input-field
              :label="$t('Ticket Amount'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter ticket amount'.getTranslationKey())"
              v-model.number="flightForm.ticket_amount"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('ticket_amount')"
              :isRequired="true"
              validation="floatingNumber"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              :label="$t('Tax on Ticket'.getTranslationKey())"
              :labelPlaceholder="$t('Select tax on ticket'.getTranslationKey())"
              :options="isTaxOptions"
              v-model="flightForm.ticket_tax"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('ticket_tax')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-4">
            <app-input-field
              :label="$t('Fee'.getTranslationKey()) + '*'"
              :placeholder="$t('Enter fee amount'.getTranslationKey())"
              v-model.number="flightForm.fee"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('fee')"
              :isRequired="true"
              validation="floatingNumber"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-single-select
              :label="$t('VAT Fee'.getTranslationKey()) + '*'"
              :labelPlaceholder="$t('Select VAT fee'.getTranslationKey())"
              :options="isTaxOptions"
              v-model="flightForm.vat_fee"
              :disabled="shouldDisableInput || disabledFormInputs"
              :hasError="isFieldInvalid('vat_fee')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="5" class="flex justify-between">
            <div v-for="radButton in isTickets" :key="radButton.text">
              <div class="flex items-center SPC-MT-100">
                <input
                  type="radio"
                  :name="radButton.text"
                  :value="radButton.value"
                  :checked="radButton.value === selectedType"
                  v-model="selectedType"
                  :disabled="shouldDisableInput || disabledFormInputs"
                />
                <label class="pl-2 text-sm font-medium text-gray-700">{{
                  radButton.text
                }}</label>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="mb-4">
          <app-upload-documents
            :disabled="disabledFormInputs"
            :documentFiles="flightForm.request_files"
          ></app-upload-documents>
        </div>
        <v-row
          class="mt-5 mb-10"
          :class="{ 'pointer-events-none': shouldDisableInput }"
        >
          <v-col cols="10">
            <p class="mb-2 text-sm text-gray-700 font-['inter'] font-medium">
              {{ $t("Additional Information".getTranslationKey()) }}
            </p>
            <QuillEditor
              :placeholder="
                $t('Enter additional information'.getTranslationKey())
              "
              :disabled="shouldDisableInput || disabledFormInputs"
              :class="contentStyles"
              contentType="text"
              v-model:content="flightForm.additional_information"
              :options="options"
              :style="editorStyles"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="
            (currentPath === '/edit-flight' && isCurrentTab === 0) ||
            currentPath === '/add-flight' ||
            currentPath === '/profile-add-flight-request'
          "
        >
          <v-col
            v-if="!shouldDisableInput"
            cols="10"
            class="flex justify-end gap-6 mt-24 mb-10"
          >
            <app-secondary-button
              :buttonLabel="$t('Cancel'.getTranslationKey())"
              @click="cancelFlight()"
            ></app-secondary-button>
            <app-primary-button
              :buttonLabel="
                currentPath === '/edit-flight'
                  ? $t('Save changes'.getTranslationKey())
                  : $t('Save'.getTranslationKey())
              "
              @click="
                currentPath === '/edit-flight'
                  ? updateSingleFlightRequest()
                  : saveFlight()
              "
            ></app-primary-button>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <travel-agency-modal
      ref="travelAgency"
      :form="flightForm"
    ></travel-agency-modal>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
    ref="alert"
  />
</template>

<script>
import { defineComponent } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import API from "@/services/api";
import { addFlightRequest, updateFlightRequest } from "@/services/endpoints";
import countries from "@/services/country_api";
import {
  getTransString,
  maximumStartDate,
  minimumEndDate,
  formatDate,
  scrollToTop
} from "@/services/helpers";
import TravelAgencyModal from "./TravelAgencyModal.vue";
import { mapState } from "vuex";
import moment from "moment";
export default defineComponent({
  name: "AddFlight",
  components: {
    QuillEditor,
    TravelAgencyModal,
  },
  props: {
    disabledFormInputs: {
      type: Boolean,
      default: false,
    },
    hidePreviewAndSignButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      moreOptions: false,
      optionsArray: {},
      selectedType: "ICON",
      editor: "",
      title: "",
      subTitle: "",
      shouldBeTravel: false,
      date: new Date().toISOString()?.substr(0, 10),
      flightForm: {
        cost_centre_id: "",
        flight_for: "",
        flight_type: "",
        route: "",
        number_of_flights: "",
        expert_id: "",
        partner_id: "",
        firm_expert_name: "",
        other_expert_name: "",
        expert_email: "",
        employee_id: "",
        contract_id: null,
        contract_type: "",
        passport_number: "",
        passport_country: "",
        requester_id: "",
        requested_on: "",
        departure_date: "",
        return_date: "",
        book_via: "",
        ticket_amount: "",
        ticket_tax: "",
        fee: "",
        vat_fee: "",
        budget_id: "",
        ticket_via: "",
        additional_information: "",
        request_files: "",
      },

      alertType: "success",
      alertState: false,
      alertTitle: "",

      hasError: false,
      isDirty: [],
      requiredFields: [
        "cost_centre_id",
        "flight_for",
        "flight_type",
        "route",
        "flights",
        "passport_number",
        "number_of_flights",
        "requester_id",
        "requested_on",
        "departure_date",
        "return_date",
        "book_via",
        "ticket_amount",
        "tax_on_ticket",
        "fee",
        "vat_fee",
        "editor",
      ],
      flight_for_employee: "Employee",
      documentFiles: [],
      options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: this.placeholder,
      },
      editorStyles: {
        minHeight: "190px",
        padding: "10px, 14px",
        fontSize: "16px",
      },
      showButtonOnNewRequest: false,
    };
  },
  watch: {
    // selectedType: function (val) {
    //   if (val === "Travel Agency") {
    //     this.$refs.travelAgency.getEmail();
    //     this.$refs.travelAgency.showTravelAgencyDialog();
    //   } else {
    //     this.shouldBeTravel = false;
    //   }
    // },
    "flightForm.cost_centre_id": function (val) {
      this.moreOptions = true;
      if (!this.flightForm.flight_for) return;

      const payload = {
        flight_for_type: this.flightForm.flight_for,
        cost_centre_id: val,
      };
      this.$store.dispatch("flightModule/getFlightDropdowns", payload);
    },
    "flightForm.flight_for": function (val) {
      this.moreOptions = true;
      if (!this.flightForm.cost_centre_id) return;

      const payload = {
        flight_for_type: val,
        cost_centre_id: this.flightForm.cost_centre_id,
      };
      this.$store.dispatch("flightModule/getFlightDropdowns", payload);
    },
    "flightForm.expert_id": function (val) {
      if (!this.flightForm.expert_id) return;

      const payload = {
        id: val,
        type: 1,
      };
      this.$store.dispatch("flightModule/getExpertContracts", payload);
    },
    "flightForm.firm_expert_name": function (val) {
      if (!this.flightForm.partner_id) return;
      const expert = this.allFirmExperts.experts?.find(
        (expert) => expert.expert_name === val
      );

      const payload = {
        id: expert.id,
        type: 2,
        data: {expert_name: val}
      };
      this.$store.dispatch("flightModule/getExpertContracts", payload);
    },
    "flightForm.partner_id": function (val) {
      if (!this.flightForm.partner_id) return;
      this.$store.dispatch("flightModule/getFirmExperts", val);
    },
    currentFlightRequest: {
      deep: true,
      handler(val) {
        this.populateInputFields(this.flightForm, val);
      },
    },
  },

  computed: {
    appLocaleLang() {
      return this.translation.locale;
    },
    ...mapState(["translation"]),

    formattedDate() {
      return this.flightForm.requested_on? formatDate(this.flightForm.requested_on, this.appLocaleLang) : formatDate(this.date, this.appLocaleLang);
    },
    isTickets() {
      return this.$store.getters["flightModule/isTickets"];
    },
    isExpertContractOptions() {
      return this.$store.getters["flightModule/isExpertContractOptions"];
    },
    employeeOptions() {
      return [
        {
          text: "Employee",
          value: "Employee",
        },
      ];
    },
    flightForOptions() {
      return this.filterFormsByCurrentRoute
        ? this.employeeOptions
        : this.isExpertContractOptions;
    },
    isTaxOptions() {
      return this.$store.getters["flightModule/isTaxOptions"];
    },
    isFlightTypeOptions() {
      return this.$store.getters["flightModule/isFlightTypeOptions"];
    },
    isCountries() {
      return countries.map((country) => {
        return {
          text: country.name,
          value: country.name,
        };
      });
    },
    isEditFlight() {
      return this.$store.getters["flightModule/isEditFlight"];
    },
    currentFlightRequestId() {
      return this.$store.getters["flightModule/isEditFlightId"];
    },

    currentPath() {
      return this.$route.path;
    },
    isCurrentTab() {
      return this.$store.getters["commonResources/isCurrentTab"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      return (
        this.currentPath === "/edit-flight" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    documentUrl() {
      const modifiedData = this.$store.getters["commonResources/documentUrl"];
      return modifiedData.map((data) => {
        return {
          file_path: data.file_path,
          file_title: data.file_title,
        };
      });
    },
    allFlightDropdowns() {
      return this.$store.getters["flightModule/isAllFlightDropdowns"];
    },
    allExpertContracts() {
      return this.$store.getters["flightModule/isAllExpertContracts"];
    },
    allFirmExperts() {
      return this.$store.getters["flightModule/isAllFirmExperts"];
    },
    holdExpertEmail() {
      return this.allFlightDropdowns.experts?.map((expert) => {
        return {
          text: expert.employee__official_email,
          value: expert.id,
        };
      });
    },
    expertArray() {
      return this.allFlightDropdowns.experts?.map((expert) => {
        return {
          text: `${expert.first_name} ${expert.last_name}`,
          value: expert.id,
        };
      });
    },
    holdFirmEmail() {
      return this.allFlightDropdowns.firms?.map((firm) => {
        return {
          text: firm.email,
          value: firm.id,
        };
      });
    },
    firmArray() {
      return this.allFlightDropdowns.firms?.map((firm) => {
        return {
          text: firm.company_name,
          value: firm.id,
        };
      });
    },
    employeeArray() {
      return this.allFlightDropdowns.employees?.map((employee) => {
        return {
          text: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        };
      });
    },
    requesterArray() {
      return this.allFlightDropdowns.requesters?.map((requester) => {
        return {
          text: `${requester.first_name} ${requester.last_name}`,
          value: requester.id,
        };
      });
    },
    holdContractType() {
      return this.allExpertContracts.contracts?.map((item) => {
        return {
          id: item.id,
          contract_type: item.contract_type,
        };
      });
    },
    contractArray() {
      return this.allExpertContracts.contracts?.map((contract) => {
        return {
          text: `${contract.project__project_title}-${contract.contract_type}-${contract.id}`,
          value: contract.id,
        };
      });
    },
    expertInFirmArray() {
      return this.allFirmExperts.experts?.map((expert) => {
        return {
          text: `${expert.expert_name}`,
          value: `${expert.expert_name}`,
        };
      });
    },
    allCostCentres() {
      const modifiedData = this.$store.getters["flightModule/isAllCostCentres"];
      return modifiedData.cost_centres?.map((cost) => {
        return {
          text: `${cost.cost_center_number} - ${cost.title}`,
          value: cost.id,
        };
      });
    },

    allPayingCompanies() {
      const modifiedData =
        this.$store.getters["flightModule/isAllPayingCompanies"];
      return modifiedData.paying?.map((pay) => {
        return {
          text: pay.title,
          value: pay.title,
        };
      });
    },
    filterFormsByCurrentRoute() {
      return (
        this.currentPath === "/profile-add-flight-request" ||
        this.currentPath === "/view-flight-request-details"
      );
    },
    currentFlightRequest() {
      return this.$store.getters["flightModule/currentFlightRequest"];
    },
    contentStyles() {
      return this.shouldDisableInput
        ? "bg-[#F9FAFB] !text-[#667085] pointer-events-none"
        : "bg-white !border !rounded-b-lg";
    },
    appLoading() {
      return this.$store.getters["flightModule/isAppLoading"];
    },
    budgetItemOptions() {
      return this.allFlightDropdowns.budget_items?.map((budgetItem) => {
        return {
          text: budgetItem?.budget_item__position,
          value: budgetItem?.id,
        };
      });
    },
    currentUserFullName() {
      const modifiedData = this.$store.getters["auth/isUserDetails"];
      return `${modifiedData?.first_name} ${modifiedData?.last_name}`;
    },
    fetchEmployeeID() {
      return this.employeeArray
        ? this.employeeArray.find((item) => {
            if (item.text === this.currentUserFullName) {
              return item.value;
            }
          })
        : "";
    },
  },
  methods: {
    showButton() {
      scrollToTop();
      return this.shouldDisableInput
        ? !(
            this.currentPath === "/add-flight" &&
            this.showButtonOnNewRequest
          )
        : this.hidePreviewAndSignButton;
    },
    fetchBudgetItems(id) {
      this.$store.dispatch("hospitalityModules/getBudgetItems", id);
    },
    fetchExpertEmail(id) {
      this.flightForm.expert_email = this.allFlightDropdowns.experts?.find(
        (expert) => {
          if (expert.id === id) {
            return expert.employee__official_email;
          }
        }
      )?.employee__official_email;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    cancelFlight() {
      this.$router.go(-1);
      this.$store.commit("flightModule/SET_EDIT_FLIGHT_TO_STATE", false);
      this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", null);
    },

    displayAlertMessage(message, errorType) {
      this.alertState = true;
      this.alertType = errorType;
      this.alertTitle = message;
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.flightForm).forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.flightForm[key] === undefined ||
            this.flightForm[key] === null ||
            this.flightForm[key] === "")
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.displayAlertMessage(
            "Please fill out the required fields",
            "error"
          );
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },

    showDialog() {
      this.$refs.dialog.showDialog = true;
      this.title = this.$t("Email Details").getTranslationKey();
    },

    cancelFlightRequest() {
      this.flightForm = {};
      this.cancelFlight();
    },

    filterFormFields() {
      if (this.flightForm.budget_id === "") {
        delete this.flightForm.budget_id;
      }
      if (
        this.flightForm.flight_for === "Firm with contract" ||
        this.flightForm.flight_for === "Expert with contract"
      ) {
        this.flightForm.contract_type = this.holdContractType?.find((type) => {
          if (type.id === this.flightForm.contract_id) {
            return type.contract_type;
          }
        })?.contract_type;
      }
      if (this.currentPath === "/profile-add-flight-request") {
        this.flightForm.employee_id = this.fetchEmployeeID?.value;
      }
      if (this.flightForm.flight_for === "Expert with contract") {
        delete this.flightForm.other_expert_name;
        delete this.flightForm.employee_id;
        delete this.flightForm.partner_id;
        delete this.flightForm.expert_email;
        delete this.flightForm.firm_expert_name;
      } else if (this.flightForm.flight_for === "Expert without contract") {
        delete this.flightForm.contract_type;
        delete this.flightForm.contract_id;
        delete this.flightForm.employee_id;
        delete this.flightForm.partner_id;
        delete this.flightForm.firm_expert_name;
      } else if (this.flightForm.flight_for === "Employee") {
        delete this.flightForm.other_expert_name;
        delete this.flightForm.expert_id;
        delete this.flightForm.contract_id;
        delete this.flightForm.contract_type;
        delete this.flightForm.expert_email;
        delete this.flightForm.partner_id;
        delete this.flightForm.firm_expert_name;
      } else if (this.flightForm.flight_for === "Firm with contract") {
        delete this.flightForm.other_expert_name;
        delete this.flightForm.employee_id;
        delete this.flightForm.expert_email;
        delete this.flightForm.expert_id;
      } else if (this.flightForm.flight_for === "Firm without contract") {
        delete this.flightForm.other_expert_name;
        delete this.flightForm.employee_id;
        delete this.flightForm.contract_id;
        delete this.flightForm.contract_type;
        delete this.flightForm.expert_email;
        delete this.flightForm.expert_id;
      }
    },
    saveFlight() {
      this.flightForm.request_files = this.documentUrl;
      this.flightForm.requested_on = moment(this.date).format("YYYY-MM-DD");
      this.flightForm.ticket_via = this.selectedType;
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      this.filterFormFields();
      API()
        .post(`${addFlightRequest}`, this.flightForm)
        .then((response) => {
          if (response.data.status === "success") {
            this.displayAlertMessage(response.data?.detail, "success");
            // this.cancelFlightRequest();
            this.$refs.travelAgency.flight_id = response.data?.data?.flight_id;
            if (this.selectedType === "Travel Agency") {
              this.$refs.travelAgency.getEmail();
              this.$refs.travelAgency.showTravelAgencyDialog();
            } else {
              this.$store.commit("flightModule/SET_EDIT_FLIGHT_ID_TO_STATE", response.data?.data?.flight_id);
              this.$store.dispatch("flightModule/getSingleFlight", {load_page: false});

              this.currentPath === "/profile-add-flight-request"
              ? scrollToTop()
              : setTimeout(() => {
                  this.$router.push("/flight");
                }, 3000);
             
            }
          
          }
        })
        .catch((error) => {
          this.displayAlertMessage(error.response.data?.detail, "error");
        });
    },

    updateSingleFlightRequest() {
      this.flightForm.request_files = this.documentUrl;
      if (!this.flightForm.budget_id) {
        delete this.flightForm.budget_id;
      }

      if (!this.flightForm.request_files) {
        delete this.flightForm.request_files;
      }
      this.filterFormFields();
      API()
        .put(
          `${updateFlightRequest}${this.currentFlightRequestId}/`,
          this.flightForm
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.displayAlertMessage(response.data?.detail, "success");
            // setTimeout(() => {
            //   this.cancelFlightRequest();
            // }, 3000);
            if (this.selectedType === "Travel Agency") {
              this.$refs.travelAgency.showTravelAgencyDialog();
            }
          }
        })
        .catch((error) => {
          this.displayAlertMessage(error.response.data?.detail, "error");
        });
    },
    populateInputFields(saveTo, data) {
      saveTo.cost_centre_id = data?.cost_centre_id;
      saveTo.flight_for = data.flight_for;
      saveTo.flight_type = data.flight_type;
      saveTo.route = data.route;
      saveTo.number_of_flights = data.number_of_flights;
      saveTo.expert_id = data?.expert_id ? data?.expert_id : "";
      saveTo.partner_id = data?.partner_id ? data?.partner_id : "";
      saveTo.firm_expert_name = data?.firm_expert_name;
      saveTo.expert_email = data.expert_email;
      saveTo.employee_id = data?.employee_id;
      saveTo.contract_id = data?.contract_id;
      saveTo.passport_number = data.passport_number;
      saveTo.passport_country = data.passport_country;
      saveTo.requester_id = data?.requester_id;
      saveTo.requested_on = data.requested_on;
      saveTo.departure_date = data.departure_date;
      saveTo.return_date = data.return_date;
      saveTo.book_via = data.book_via;
      saveTo.ticket_amount = data.ticket_amount;
      saveTo.ticket_tax = data.ticket_tax;
      saveTo.fee = data.fee;
      saveTo.ticket_via = data.ticket_via;
      saveTo.request_files = data?.request_files;
      saveTo.vat_fee = data.vat_fee;
      saveTo.budget_id = data.budget_id;
      saveTo.additional_information = data?.additional_information;
      this.selectedType = data.ticket_via;
      this.fetchBudgetItems(data?.cost_centre_id);
    },
  },

  created() {
    if (this.currentFlightRequestId) {
      this.populateInputFields(this.flightForm, this.currentFlightRequest);
    }
    this.$store.commit("auth/SET_RAIL_TO_STATE", false);
    this.$store.dispatch("flightModule/getCostCenters");
    this.$store.dispatch("flightModule/getPayingCompanies");
  },
});
</script>
<style scoped>
@import "@/styles/shared/radio-button.css";
</style>
