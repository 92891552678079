<template>
  <v-row class="mb-3">
    <v-col cols="0" class="d-flex align-center">
      <p class="text-[18px] font-bold" @click="goToPreviousPage()">
        {{ $t("projectBudget.projectBudgetTitle") }}
      </p>
    </v-col>
    <v-col cols="3">
      <div class="flex controls" v-show="!isBudgetMode">
        <button
          class="px-12"
          @click="
            isSplit === 'milestone' ? goToPreviousPage() : emitPreviousYear()
          "
        >
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 11.5L1.5 6.5L6.5 1.5"
              :stroke="disablePreviousColor"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="flex px-4 current-date">
          {{ isSplit === "milestone" ? `Page ${isCurrentPage}` : currentYear }}
        </div>
        <button
          class="px-12"
          @click="isSplit === 'milestone' ? goToNextPage() : emitNextYear()"
        >
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 11.5L6.5 6.5L1.5 1.5"
              :stroke="disableNextColor"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </v-col>
    <v-col cols="6">
      <div class="justify-end d-flex align-center">
        <div v-if="isBudgetMode" class="d-flex">
          <!-- select split -->
          <div v-if="!isSplitted" class="w-[155px] mt-n4 mx-4">
            <!-- split budget -->
            <app-select-field-object
              :disabled="!isEditButton"
              v-if="canSplit"
              placeholder="Select split"
              :options="splitOptions"
              v-model="splitBudget"
              @change="updateSplitBudget"
            >
            </app-select-field-object>
          </div>

          <!-- view and delete split -->
          <div class="flex" v-else>
            <app-secondary-button
              class=""
              :buttonLabel="$t('projectBudget.deleteSplit')"
              :loading="loading"
              @click="showDeleteSplit()"
              v-if="isSplitted"
            >
              <template #icon>
                <v-icon icon="custom:trashIcon" size="20" class="mr-2"></v-icon>
              </template>
            </app-secondary-button>

            <app-secondary-button
              @click="viewSplit()"
              class="px-4"
              :buttonLabel="$t('projectBudget.viewSplit')"
              v-if="isSplitted"
            >
              <v-icon
                icon="custom:viewIcon"
                class="small-icon"
                color="#344054"
              ></v-icon>
              <template #icon>
                <v-icon icon="custom:viewIcon" color="#344054"></v-icon>
              </template>
            </app-secondary-button>
          </div>
          <!-- create budget section -->
          <app-primary-button
            :disabled="!isEditButton"
            :buttonLabel="$t('projectBudget.createBudgetSection')"
            :loading="loading"
            @click="$emit('show-dialog')"
          >
          </app-primary-button>
        </div>
        <div v-else class="d-flex">
          <!-- view budget -->
          <app-secondary-button
            @click="isSplitEditMode ? cancelSplitEditMode() : swithBudget()"
            class="px-4"
            :buttonLabel="
              $t(
                `${
                  isSplitEditMode
                    ? $t('projects.cancel')
                    : $t('projectBudget.viewBudget')
                }`
              )
            "
          >
            <v-icon
              v-if="!isSplitEditMode"
              icon="custom:viewIcon"
              class="small-icon"
              color="#344054"
            ></v-icon>
            <template #icon v-if="!isSplitEditMode">
              <v-icon icon="custom:viewIcon" color="#344054"></v-icon>
            </template>
          </app-secondary-button>
          <!-- edit split budget -->
          <app-primary-button
            :buttonLabel="
              $t(
                `${
                  isSplitEditMode
                    ? $t('projectBudget.saveChanges')
                    : $t('projectBudget.edit')
                }`
              )
            "
            @click-event="isSplitEditMode ? editSplit() : splitEditMode()"
          >
            >
            <template #icon v-if="!isSplitEditMode">
              <v-icon
                icon="custom:editIcon"
                class="small-icon"
                color="#fff"
              ></v-icon>
            </template>
          </app-primary-button>
        </div>
      </div>
    </v-col>
  </v-row>

  <!-- split confirmation -->
  <app-dialog
    ref="isSplit"
    :title="$t('projectBudget.splitBudgetTitle')"
    :subtitle="$t('projectBudget.splitNow')"
  >
    <template #content v-if="isSplit === 'Milestone'">
      <app-input-field
        v-model="milestoneNumber"
        :label="$t('projectBudget.numberOfMilestone')"
      ></app-input-field>
    </template>
    <template #actions>
      <v-row class="px-4 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('projects.cancel')"
            @click-event="hideSplit()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6">
          <app-primary-button
            :buttonLabel="$t('projectBudget.splitNowButton')"
            :loading="loading"
            @click-event="splitNow()"
            :disabled="
              isSplit === 'Milestone' && !milestoneNumber ? true : false
            "
          >
          </app-primary-button>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <!-- split confirmation -->
  <app-dialog
    ref="isSplitDlete"
    :title="$t('projectBudget.deleteSplitTitle')"
    :subtitle="$t('projectBudget.deleteSplitSubtitle')"
  >
    <template #actions>
      <v-row class="px-2 mt-1 mb-5">
        <v-col cols="6">
          <app-secondary-button
            :buttonLabel="$t('experts.noKeepIt')"
            @click-event="hideDeleteSplit()"
          >
          </app-secondary-button>
        </v-col>
        <v-col cols="6" class="">
          <v-btn
            variant="default"
            min-width="100%"
            min-height="100%"
            class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
            @click="deleteSplit()"
          >
            {{ $t("employees.yesDelete") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>

  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>
<script lang="js">
import { defineComponent } from "vue"
import { getYear } from "date-fns";
import Api from "@/services/api";
import { getTransString } from "@/services/helpers";
import { mapGetters,  mapActions } from 'vuex';


export default defineComponent({
  data() {
    return {
      counter:1,
      loading: false,
        addloading: false,
        alertType: "success",
        alertState: false,
        alertTitle: "",
        splitType:'',
        milestoneNumber:'',
      splitBudget: '',
      addBudgetSection: '',
      currentYear: getYear(new Date()),
      startDate : '',
      endDate :'',
      curentPage:1,
      disableNextColor: '#667085',
      disablePreviousColor: '#667085',
      // startYear: getYear(this.startDate),
      // endYear: getYear(this.endDate)
    }
  },


  computed: {

    ...mapGetters("projectBudgetModules", ["isBudgetData", "isSplitted", "canSplit", "getError", "getResponse"]),

    showSelectSplit() {

      // Initialize a flag to check if is_planning is true
      let isPlanningTrue = false;

      // Iterate through the data
      for (const section of this.isBudgetData) {
        for (const item of section.budget_items) {
          if (item?.is_planning) {
            isPlanningTrue = true;
            break;
          }
        }
        if (isPlanningTrue) {
          break;
        }
      }

      return isPlanningTrue
    },

    isTotalPages() {
      return this.$store.getters["projectBudgetModules/isTotalPages"];
    },
    isCurrentPage() {
      return this.$store.getters["projectBudgetModules/isCurrentPages"];
    },
    isCurrentYear() {
      return this.$store.getters["projectBudgetModules/isCurrentYear"];
    },
    isCreatedSplit() {
      return this.$store.getters["projectBudgetModules/isCreatedSplit"];
    },
    isContractDetails() {
      return this.$store.getters["projectModules/isContractDetails"];
    },
    isSplitEditMode() {
      return this.$store.getters["projectBudgetModules/isSplitEditMode"];
    },
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    isBudgetMode() {
      return this.$store.getters["projectBudgetModules/isBudgetMode"];
    },
    isSplit() {
      return this.$store.getters["projectBudgetModules/isSplit"];
    },
    isSplitExist() {
      return this.$store.getters["projectBudgetModules/isSplitExist"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    splitOptions() {
      const data = [
        'Monthly',
        'Quarterly',
        'Milestone',
      ]

      return data?.convertArrayToObject()

    },
    startYear(){

      return getYear(new Date(this.isContractDetails.project__project_start_date))
    },
    endYear(){

      return getYear(new Date(this.isContractDetails.project__project_end_date))
    },
    isEmptyBudgetState() {
       return this.$store.getters["projectBudgetModules/isEmptyBudgetState"];
     },
  },

  methods: {
    ...mapActions("projectBudgetModules", ["updateBudgetSectionLineItems", "fetchAllBudgetSection"]),
    getTranslatedStringKey(field) {
        return getTransString(field);
      },
    emitNextYear() {

      if (this.currentYear < this.endYear) {
        this.currentYear += 1;
     this.counter += 1
        this.$store.commit('projectBudgetModules/SET_CURENTYEAR_TO_STATE', this.currentYear);
        this.disableNextColor = "#667085"
        this.disablePreviousColor = "#667085"
      }

      if(this.currentYear == this.endYear){
        this.disableNextColor = "#acb3c2"
      }
      // this.$emit("next-year-event", this.currentYear);
    },
    emitPreviousYear() {

      if (this.currentYear > this.startYear) {
        this.currentYear -= 1;
        this.counter = this.counter - 1
         this.$store.commit('projectBudgetModules/SET_CURENTYEAR_TO_STATE', this.currentYear);
         this.disableNextColor = "#667085"

        }
        if (this.currentYear == this.startYear) {
          this.disablePreviousColor  = "#acb3c2"
        }
    },
    goToPreviousPage() {

      this.$store.commit('projectBudgetModules/SET_CURRENTPAGE_TO_STATE',this.curentPage);
      if (this.curentPage > 1) {
        this.curentPage = this.isCurrentPage - 1;
        this.disableNextColor = "#667085"
      }

      if (this.curentPage <= 1) {
          this.disablePreviousColor  = "#acb3c2"
      }
    },
    goToNextPage() {

      this.$store.commit('projectBudgetModules/SET_CURRENTPAGE_TO_STATE',this.curentPage);
      if (this.curentPage < this.isTotalPages) {

        this.curentPage= this.isCurrentPage + 1;
        this.disableNextColor = "#667085"
        this.disablePreviousColor = "#667085"
      }
      if(this.curentPage == this.isTotalPages){
        this.disableNextColor = "#acb3c2"
      }
    },
    showDialog() {
      this.$refs.isBudgetSection.showDialog = true;
    },
    showSplit() {
      this.$refs.isSplit.showDialog = true;
    },
    hideSplit() {
      this.splitBudget = '';
      this.$refs.isSplit.showDialog = false;
    },
    showDeleteSplit(){
      this.$refs.isSplitDlete.showDialog = true;
    },
    hideDeleteSplit() {
      this.$refs.isSplitDlete.showDialog = false;
    },

    hideDialog() {
      this.$refs.isBudgetSection.showDialog = false;
      this.addBudgetSection = ''
    },
    deleteSplit() {
      this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE', '');
      this.splitBudget= ''
      Api()
          .delete(`/projects/project/budget-split/${this.isProjectID}/`)
          .then((response) => {
            if (response.data.status === "successful") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
              this.splitType=''
              this.$refs.isSplitDlete.showDialog = false;
              this.$store.commit('projectBudgetModules/SET_SPLITEXIST_TO_STATE', false);
              this.$store.commit('projectBudgetModules/SET_SPLIT_TYPE_TO_STATE',this.splitType)
              this.$store.commit('projectBudgetModules/SET_IS_SPLITTED', false)
            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
              this.$refs.isSplitDlete.showDialog = false;
            }
          });
    },
    viewSplit() {
      this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE',  this.splitType);
      this.$store.commit('projectBudgetModules/SET_BUDGET_MODE_TO_STATE', false);
      this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE', true);
      this.$store.commit('projectBudgetModules/SET_CURENTYEAR_TO_STATE', this.currentYear);
    },
    updateSplitBudget() {
      this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE', this.splitBudget || " ");
      this.showSplit()
    },
    swithBudget() {
      this.$store.commit('projectBudgetModules/SET_BUDGET_MODE_TO_STATE', true);
      this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE', false);
      this.currentYear = getYear(new Date())
      this.$store.commit('projectBudgetModules/SET_CURENTYEAR_TO_STATE', this.currentYear);

    },
    editSplit(){
      this.$store.commit('projectBudgetModules/SET_SAVE_EDIT_SPLIT_TO_STATE',true);
    },
    splitEditMode(){
      this.$store.commit('projectBudgetModules/SET_SAVE_EDIT_SPLIT_MODE_TO_STATE',true);
    },
    cancelSplitEditMode(){
      this.$store.commit('projectBudgetModules/SET_SAVE_EDIT_SPLIT_MODE_TO_STATE',false);
    },
    async splitNow(){
      let payload
      if(this.isSplit !=='Milestone'){
        payload = {
          category_name:this.isSplit,
        };
      }else{
        payload = {
          category_name:this.isSplit,
          milestones_number:this.milestoneNumber
        };
      }
      let budgetSectionData = this.assignRowIndexToBudgetSection(this.isBudgetData);

      await this.updateBudgetSectionLineItems({ project: this.isProjectID, request: budgetSectionData})

      if(this.getResponse.status === 'success'){
          Api().post(`/projects/project/create-budget-split/${this.isProjectID}/`, payload)
          .then((response) => {
            if (response.data.status === "successful") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
              this.checkSplit()
              this.$refs.isSplit.showDialog = false;
              this.$store.commit('projectBudgetModules/SET_SPLITEXIST_TO_STATE', true);
              this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE', true);
              this.$store.commit('projectBudgetModules/SET_BUDGET_MODE_TO_STATE',false);
              this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE', this.splitBudget.toLowerCase())
              this.$store.commit('projectBudgetModules/SET_SPLIT_CREATED_TO_STATE', this.splitBudget.toLowerCase())
              this.$store.commit('projectBudgetModules/SET_IS_SPLITTED', true)

            }
          })
          .catch((error) => {
            if (error) {
              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
              this.$refs.isSplit.showDialog = false;
              this.splitBudget = '';
              this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE', false);
            }
          });
      } else {

        const error = this.getBudgetLineItemError(this.getError)

        this.alertType = "error";
        this.alertState = !this.alertState;
        this.alertTitle = error?.message;

        this.$store.commit("projectModules/SET_PROJECT_UPDATE", {
          "ProjectBudget": false
        });
      }

    },

    getBudgetLineItemError (errorObject){
      let indexOfNonEmpty = null;
      let data = errorObject.data
      for (let i = 0; i < data.length; i++) {
          if (Object.keys(data[i]).length > 0) { // Check if the object is not empty
              indexOfNonEmpty = i;
              break;
          }
      }

      // If a non-empty object is found, get the budget_items and find the first non-empty item
      if (indexOfNonEmpty !== null) {
          const nonEmptyObject = data[indexOfNonEmpty];
          const budgetItems = nonEmptyObject["budget_items"] || [];

          // Find the first non-empty item in budget_items
          const nonEmptyItem = budgetItems.find(item => Object.keys(item).length > 0);

          if ('structure' in nonEmptyItem) {
            return {
              field: "structure",
              sectionIndex: indexOfNonEmpty,
              message: "Please Select a Structure"
            }
          }else if("amount_per_unit"  in nonEmptyItem) {
            return {
              field: "amount_per_unit",
              sectionIndex: indexOfNonEmpty,
              message: "Amount Per Unit is Required"
            }
          }else if("number_of_units"  in nonEmptyItem) {
            return {
              field: "number_of_units",
              sectionIndex: indexOfNonEmpty,
              message: "Number of Units is Required"
            }
          }
          return {
              field: "unknown",
              sectionIndex: 0,
              message: "Some Required Fields are Missing"
          }
      }

    },

    assignRowIndexToBudgetSection(budgetSectionDataFromStore) {
      if (budgetSectionDataFromStore.length > 0) {
       // Clone the budget_sections array to avoid modifying the original data
         const clonedBudgetSections = [...budgetSectionDataFromStore];

         // Assign row index to each element in the cloned array
         clonedBudgetSections.forEach((section) => {

           section?.budget_items?.forEach((item, index )=> {
            item.row_index = index
           })
         });

        return clonedBudgetSections;
      }
      return budgetSectionDataFromStore;
    },

   checkSplit(){

        Api()
          .get(`/projects/project/budget-split/${this.isProjectID}/?year=${this.currentYear}`)
          .then((response) => {
            if (response.data.status === "successful") {
             const data=response.data
              this.$store.commit('projectBudgetModules/SET_SPLIT_CREATED_TO_STATE', this.splitBudget.toLowerCase())
              this.splitType = data.split_type.toLowerCase();
              this.$store.commit('projectBudgetModules/SET_SPLIT_TYPE_TO_STATE',this.splitType)
            }
          })
          .catch((error) => {
            if (error) {
              this.$store.commit('projectBudgetModules/SET_SPLIT_MODE_TO_STATE', false);

            }
          });

    },

  },
  mounted() {
    this.$store.commit('projectBudgetModules/SET_SPLIT_TO_STATE', '')
    if (this.isSplitted) {
      this.checkSplit()
    }

    this.currentYear = getYear(new Date())
    this.$store.commit('projectBudgetModules/SET_CURENTYEAR_TO_STATE', this.currentYear);
},
})
</script>
<style scoped>
.small-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
