<template>
  <v-sheet max-width="" class="px-10 py-5  mt-5 bg-white border rounded-lg">
    <div class="flex cursor-pointer items-center justify-between">
      <div class="flex cursor-pointer H400 w50 mt-n1"  @click="toggleAccordion">
        <v-icon v-if="showAccordionContent" icon="custom:expandLess" />
        <v-icon v-else icon="custom:expandIcon" />
        <h4
          class="ml-4 !text-[#344054] !text-[1rem] !font-inter !font-semibold"
        >
          {{isTranslation?  $t(getTranslatedStringKey(accordionInfo?.name)): accordionInfo?.name }}
        </h4>
      </div>
      <div class="flex " v-show="showAccordionContent">
        <slot name="action"></slot>
      </div>
    </div>
    <div v-show="showAccordionContent" class="mt-6 mb-5">
      <slot name="content"></slot>
    </div>
    <!-- delete dialog -->
  </v-sheet>
  <div v-show="showFooter" class="mt-n6 mb-15">

    <div v-show="!showAccordionContent" class="px-10 mt-5  bg-white border border-t-0 rounded-b-lg">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import { getTransString } from '@/services/helpers'


export default defineComponent({
  props: {
    accordionInfo: {
      type: Object,
    },
    firstItem: {
      type: String,
    },
    isTranslation:{
      type: Boolean,
      default: true
    }, 
    showFooter:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showAccordionContent: false,
    }
  },
watch:{},

  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    toggleAccordion(){
      this.showAccordionContent = !this.showAccordionContent
    }
  },
  created() {
  //  if( Object.keys(this.accordionInfo)[0]){
  //   this.showAccordionContent = true
  //  }
    if(this.accordionInfo?.name === "Contract Details"){
      this.showAccordionContent = true
    }

    if (this.accordionInfo?.name === "My leaves"){
      this.showAccordionContent = true
    }
    if (this.accordionInfo?.name === "Leaves"){
      this.showAccordionContent = true
    }


  }
})
</script>
<style scoped>
.ae-drag-window {
  border: 1px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}

.ae-drag-window:hover {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(139, 41, 35, 0.04);
  border: 1px solid #fdc6c2 !important;
}

.ae-upload-progress {
  border-radius: 5px;
}

.ae-bg-green-300 {
  border: 5px solid #fdc6c2 !important;
  border-radius: 12px;
  height: 270px;
  padding-top: 50px;
}
</style>
