<template>
    <div
      v-if="appLoading"
      class="d-flex justify-center h-[500px] flex items-center"
    >
      <app-loader></app-loader>
    </div>
  <v-sheet v-else max-width="1125" class="mt-5 bg-transparent mb-7">
    <form>
      <fieldset :disabled="shouldDisableInput ? 'disabled' : null">
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-single-select
              id="country"
              :showSearch="true"
              :searchPlaceholder="$t('foreignOffices.searchCountry')"
              :disabled="shouldDisableInput"
              :showCheckbox="false"
              :options="countryList"
              :labelPlaceholder="$t('foreignOffices.countryPlaceholder')"
              :label="$t('foreignOffices.country*')"
              v-model="foreignOfficeForm.country"
              :hasError="isFieldInvalid('country')"
              :isRequired="true"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="place"
              :placeholder="$t('foreignOffices.placePlaceholder')"
              :label="$t('foreignOffices.place')"
              v-model="foreignOfficeForm.place"
              :disabled="shouldDisableInput"
              :hasError="isFieldInvalid('place')"
              :isRequired="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-date-field
              id="officeStartDate"
              type="date"
              :max="maxStartDate(foreignOfficeForm.office_end_date)"
              :label="$t('foreignOffices.officeStartDate*')"
              v-model.trim="foreignOfficeForm.office_start_date"
              :hasError="isFieldInvalid('office_start_date')"
              :isRequired="true"
              :disabled="shouldDisableInput"
            ></app-date-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-date-field
              id="officeEndDate"
              type="date"
              :min="minEndDate(foreignOfficeForm.office_start_date)"
              :placeholder="$t('foreignOffices.placePlaceholder')"
              :label="$t('foreignOffices.officeEndDate')"
              v-model.trim="foreignOfficeForm.office_end_date"
              :disabled="shouldDisableInput"
            ></app-date-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id="street1"
              type="text"
              :placeholder="$t('foreignOffices.street1Placeholder')"
              :label="$t('foreignOffices.street1')"
              v-model="foreignOfficeForm.street_1"
              :disabled="shouldDisableInput"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="street2"
              type="text"
              :placeholder="$t('foreignOffices.street2Placeholder')"
              :label="$t('foreignOffices.street2')"
              v-model="foreignOfficeForm.street_2"
              :disabled="shouldDisableInput"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id="telephone"
              type="text"
              validation="phoneOnly"
              :placeholder="$t('foreignOffices.telephonePlaceholder')"
              :label="$t('foreignOffices.telephone')"
              v-model="foreignOfficeForm.telephone_number"
              :hasError="isFieldInvalid('telephone_number')"
              :isRequired="true"
              :disabled="shouldDisableInput"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="mobilePhone"
              type="text"
              validation="phoneOnly"
              :placeholder="$t('foreignOffices.mobilePhonePlaceholder')"
              :label="$t('foreignOffices.mobilePhone')"
              v-model="foreignOfficeForm.mobile_number"
              :disabled="shouldDisableInput"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-input-field
              id="email"
              type="email"
              validation="emailOnly"
              :placeholder="$t('foreignOffices.emailPlaceholder')"
              :label="$t('foreignOffices.email')"
              v-model="foreignOfficeForm.email"
              :disabled="shouldDisableInput"
              :hasError="emailError"
              :isRequired="true"
              errorMessage="Invalid email address"
              @update:modelValue="resetEmailError"
            ></app-input-field>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="postalCode"
              type="text"
              :placeholder="$t('foreignOffices.postalCodePlacholder')"
              :label="$t('foreignOffices.postalCode')"
              v-model="foreignOfficeForm.postal_code"
              :disabled="shouldDisableInput"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="mt-5 !divide-y">
            <p class="pb-3 font-semibold text-[1.125rem]">
              {{ $t("foreignOffices.bankInformation") }}
            </p>
            <div></div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="5" class="pr-4">
            <app-single-select
              id="name"
              :showSearch="true"
              :showAddMore="true"
              :addMoreText="$t('foreignOffices.addBank')"
              :searchPlaceholder="$t('foreignOffices.searchBank')"
              :disabled="shouldDisableInput"
              :showCheckbox="false"
              :options="bankNameOptions"
              :labelPlaceholder="$t('foreignOffices.selectBank')"
              :label="$t('foreignOffices.name')"
              v-model="foreignOfficeForm.bank_id"
              @update:modelValue="valueToInputField"
              @add-More="addBankDetail"
            ></app-single-select>
          </v-col>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="title"
              :placeholder="$t('foreignOffices.titlePlaceholder')"
              :label="$t('foreignOffices.title')"
              v-model="title"
              disabled="true"
            ></app-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pl-4">
            <app-input-field
              id="title"
              :placeholder="$t('foreignOffices.accountNumberPlaceholder')"
              :label="$t('foreignOffices.accountNumber')"
              v-model="account_number"
              disabled="true"
            ></app-input-field>
          </v-col>
        </v-row>
      </fieldset>
    </form>
    <!-- action buttons -->
    <v-sheet max-width="1118" class="mt-16 bg-transparent">
      <v-row class="">
        <v-col cols="10" offset="" class="">
          <v-sheet max-width="" class="justify-end bg-transparent d-flex">
            <app-secondary-button
              v-show="isEditButton"
              :loading="loading"
              :buttonLabel="$t('projects.saveAndExit')"
              @click="currentPath === '/edit-foreign-office'?  updateForeignOffice('exit') : addForeignOffice('exit')"
            >
            </app-secondary-button>
            <app-primary-button
              v-show="isEditButton"
              :buttonLabel="$t('projects.saveAndContinue')"
              class="ml-5"
              :loading="loading"
              @click="currentPath === '/edit-foreign-office'?  updateForeignOffice('continue') : addForeignOffice('continue')"
            >
            </app-primary-button>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
    <create-edit-bank-detail ref="CreateEditBankDetail" @prefill-input-field="handleEventData"></create-edit-bank-detail>
  </v-sheet>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { createForeignOffice, updateForeignOffice } from "@/services/endpoints";
import countries from "@/services/country_api";
import { validEmail } from "@/services/validation";

import {
  getTransString,
  maximumStartDate,
  minimumEndDate,
} from "@/services/helpers";
import CreateEditBankDetail from "../hr-settings/bank-details-dialog/CreateEditBankDetail.vue";
import { isEqual } from 'lodash';
export default defineComponent({
    name:'Basic Information',
    components:{
        CreateEditBankDetail
    },
  data() {
    return {
      foreignOfficeForm: {
        country: null,
        office_start_date: null,
        office_end_date: null,
        street_1: "",
        street_2: "",
        postal_code: "",
        place: "",
        telephone_number: "",
        mobile_number: "",
        email: "",
        bank_id: null,
      },
      originalForeignOfficeForm: {
        country: null,
        office_start_date: null,
        office_end_date: null,
        street_1: "",
        street_2: "",
        postal_code: "",
        place: "",
        telephone_number: "",
        mobile_number: "",
        email: "",
        bank_id: null,
      },
      alertType: "success",
      alertState: false,
      emailError: false,
      alertTitle: "",
      isDirty: [],
      title: null,
      account_number: null,
      requiredFields: ["country", "place", "office_start_date", "telephone_number"],
      firstRun: true,
    };
  },
  watch:{
    currentForeignOffice(data){
        this.populateInpuField(this.foreignOfficeForm, data)
        // this.populateInpuField(data)
        if(this.firstRun){
            this.populateInpuField(this.originalForeignOfficeForm, data)
        }
        
    },
    foreignOfficeForm: {
        deep: true,
        handler(value) {
          this.$store.commit("foreignOfficeModule/SET_FOREIGN_OFFICE_UPDATE", {
            "BasicInformation": !isEqual(value, this.originalForeignOfficeForm)
          });

        },
    },
  },
  computed: {
    bankNameOptions() {
      return this.isComputedBankDetails?.map((obj) => {
        const {
          id: value,
          bank_name: text,
          account_number: account_number,
        } = obj;
        return { account_number, value, text };
      });
    },
    isComputedBankDetails() {
      return this.$store.getters["foreignOfficeModule/allBanks"];
    },
    officeID() {
      return this.$store.getters["foreignOfficeModule/isOfficeID"];
    },
    currentForeignOffice() {
      return this.$store.getters["foreignOfficeModule/isCurrentForeignOffice"];
    },
    countryList() {
      return countries?.map((country) => {
        return {
          text: country.name,
          value: country.name,
        };
      });
    },
    appLoading() {
      return this.$store.getters["foreignOfficeModule/appLoading"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    isEditOffice() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-foreign-office" &&
        this.isEditOffice &&
        !this.isEditButton
      );
    },
  },
  methods: {
    minEndDate(date) {
      return minimumEndDate(date);
    },
    maxStartDate(date) {
      return maximumStartDate(date);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    resetEmailError() {
      this.emailError = false;
    },
    checkAlertEror(message) {
      this.alertState = true;
      this.alertType = "error";
      this.alertTitle = message;
    },
    inputFieldsValidation() {
      this.hasError = false;
      Object.keys(this.foreignOfficeForm)?.forEach((key) => {
        if (
          this.requiredFields?.includes(key) &&
          (this.foreignOfficeForm[key] === "" ||
            this.foreignOfficeForm[key] === undefined ||
            this.foreignOfficeForm[key] === null)
        ) {
          this.isDirty.push(key);
          this.hasError = true;
          this.checkAlertEror("Please fill out the required fields");
        }
      });
      if (this.hasError) {
        return this.hasError;
      }
    },
    isFieldInvalid(fieldName) {
      return this.isDirty?.includes(fieldName) || false;
    },
    valueToInputField() {
      if (this.foreignOfficeForm.bank_id) {
        const bank = this.isComputedBankDetails?.find((account) => {
          if (account.id === this.foreignOfficeForm.bank_id) {
            return account;
          }
        });
        
        this.title = bank["account_title"];
        this.account_number = bank["account_number"];
      }
    },
    getSingleOffice(id) {
      if (this.officeID) {
        const payload = {
          load_page: true,
          officeID: id,
        };
        this.$store.dispatch("foreignOfficeModule/getSingleOffice", payload);
      }
    },
    addBankDetail(){
        this.$refs.CreateEditBankDetail.showDialog()
    },
    handleEventData(event){
        this.foreignOfficeForm.bank_id = event.id
        this.title= event.account_title
        this.account_number = event.account_number;
        // this.valueToInputField()
    },
    addForeignOffice(route) {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      if (
        this.foreignOfficeForm.email &&
        !validEmail(this.foreignOfficeForm.email)
      ) {
        this.emailError = true;
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }
      Api()
        .post(`${createForeignOffice}`, this.foreignOfficeForm)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$store.commit(
              "foreignOfficeModule/SET_OFFICE_ID_STATE",
              response?.data?.data?.foreign_office_id
            );
            this.$store.commit(
              "foreignOfficeModule/SET_OFFICE_TITLE_STATE",
              this.foreignOfficeForm.place
            );

            // this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", response.data.data.id);
            setTimeout(() => {
              if (route === "exit") {
                this.$router.push({ name: "foreignOffice" });
              }
              if (route === "continue") {
                this.$store.commit(
                  "commonResources/SET_CURRENT_TAB_TO_STATE",
                  1
                );
              }
            }, 1500);
          }
        })
        .catch((error) => {
          if (error) {
            this.emailError = false;
          }
        });
    },
    updateForeignOffice(route) {
      this.inputFieldsValidation();
      if (this.hasError) return this.hasError;
      if (
        this.foreignOfficeForm.email &&
        !validEmail(this.foreignOfficeForm.email)
      ) {
        this.emailError = true;
        this.alertType = "error";
        this.alertTitle = "Please enter a valid email address.";
        this.alertState = !this.alertState;
        return;
      }
      Api()
        .put(`${updateForeignOffice}${this.officeID}/`, this.foreignOfficeForm)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertType = "success";
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
            this.$store.commit(
              "foreignOfficeModule/SET_OFFICE_ID_STATE",
              response?.data?.data?.foreign_office_id
            );

            // this.$store.commit("projectModules/SET_CONTRACT_ID_TO_STATE", response.data.data.id);
            setTimeout(() => {
              if (route === "exit") {
                this.$router.push({ name: "foreignOffice" });
              }
            }, 1500);
          }
        })
        .catch((error) => {
          if (error) {
            this.emailError = false;
          }
        });
    },
    populateInpuField(saveTo, data){
      
        saveTo.country = data?.country
        saveTo.place= data?.place
        saveTo.office_start_date= data?.office_start_date
        saveTo.office_end_date= data?.office_end_date
        saveTo.street_1= data?.street_1
        saveTo.street_2= data?.street_2
        saveTo.email= data?.email
        saveTo.telephone_number= data?.telephone_number
        saveTo.mobile_number= data?.mobile_number
        saveTo.postal_code= data?.postal_code
        saveTo.bank_id= data?.bank_id
        this.valueToInputField()
    }
  },
  mounted(){
    this.populateInpuField(this.foreignOfficeForm, this.currentForeignOffice)
    this.populateInpuField(this.originalForeignOfficeForm, this.currentForeignOffice)
  },
  created() {
    this.$store.commit("commonResources/SET_CURRENT_TAB_TO_STATE", 0);
    this.$store.dispatch('foreignOfficeModule/getAllBanks')
    if (this.officeID) {
        this.getSingleOffice(this.officeID)
    }
    this.populateInpuField(this.currentForeignOffice)
    this.populateInpuField(this.originalForeignOfficeForm, this.currentForeignOffice)
  },
});
</script>
