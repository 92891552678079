<template>
  <div>
    <QuillEditor
      contentType="text"
      v-model:content="editorContent"
      :options="options"
      :style="editorStyles"
    />
    <div class="flex justify-end mt-6">
      <app-primary-button
        :buttonLabel="$t('projectConfiguration.save')"
        @click="addProjectNote()"
        :disabled="shouldDisableInput ? 'disabled' : null"
      ></app-primary-button>
    </div>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script>
import { defineComponent } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import { getTransString } from "@/services/helpers";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import {
  addOrUpdateProjectNote,
  fetchContractDetails,
} from "@/services/endpoints";
import api from "@/services/api";

export default defineComponent({
  name: "ProjectNote",
  components: {
    QuillEditor,
  },
  data() {
    return {
      editorContent: "",
      alertType: "success",
      alertState: false,
      alertTitle: "",
      options: {
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
            ],
            ["bold", "italic"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: "Enter a description",
      },
      editorStyles: {
        minHeight: "190px",
        padding: "10px, 14px",
        fontSize: "16px",
      },
    };
  },
  computed: {
    isProjectID() {
      return this.$store.getters["projectModules/isProjectID"];
    },
    currentPath() {
      return this.$route.path;
    },
    contractDetails() {
      //isContractDetails
      return this.$store.getters["projectModules/isContractDetails"];
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
    shouldDisableInput() {
      // Add your condition to disable the input element here
      return (
        this.currentPath === "/edit-project" &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    addProjectNote() {
      const payload = {
        project_id: this.$store.state.projectModules?.projectID,
        project_note: this.editorContent,
      };
      api()
        .post(addOrUpdateProjectNote, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.alertState = true;
            this.alertTitle = response?.data?.detail;
          }
        })
        .catch((error) => {
          if (error) {
            this.alertState = true;
            this.alertType = "error";
            this.alertTitle = error.response?.data?.detail;
          }
        });
    },
    getProjectNote() {
      api()
        .get(fetchContractDetails + `${this.isProjectID}`)
        .then((response) => {
          if (response.data.status === "success") {
            const contractData = response.data.data;
            this.editorContent = contractData.project_note;
          }
        });
    },
  },
  created() {
    // this.getProjectNote();
    this.editorContent = this.contractDetails?.project_note;
  },
});
</script>

<style scoped>
.ql-container.ql-snow {
  border: 1px solid #d0d5dd !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
  background-color: #fcfcfd;
}
.ql-snow.ql-toolbar .ql-picker-label:hover {
  background-color: #fcfcfd !important;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}
</style>
