<template>
  <div>
    <div class="py-2 label">
      <label :for="id" class="text-[#344054] text-[14px] font-medium">{{
        label
      }}</label>
    </div>
    <div class="multi-select">
      <input type="hidden" :name="name" />
      <div
        class="dropdown"
        :class="{ open: isOpen }"
        @click="!disabled ? toggleDropdown() : ''"
      >
        <div
          class="selected-items"
          :class="{ disabled: disabled, 'wrapper-error': error }"
        >
          <div
            v-for="(option, index) in displayedOptions"
            :key="option?.value"
            class="selected-item"
            @click="preventDropdownClose"
          >
            <span>{{ truncateText(option?.text) }}</span>
            <button @click="removeSelectedOption(index)">
              <div class="text-[#98A2B3] font-[50px] text-[15px]">x</div>
            </button>
          </div>
          <div
            v-if="selectedOptions?.length === 0"
            class="text-[16px] text-[#667085] ml-2"
          >
            <span>{{ labelPlaceholder }}</span>
          </div>
          <span v-if="!error" class="dropdown-arrow">
            <v-icon icon="custom:expandIcon" />
          </span>
        </div>
        <div
          class="absolute right-0 flex mr-2 transform -translate-y-1/2 item-center top-1/3"
          v-if="error"
        >
          <v-icon size="20" icon="custom:errorIcon" />
        </div>
        <div class="mt-2 dropdown-list" v-show="isOpen" @click.stop>
          <div class="divide-y">
            <div v-if="showSearch" class="mt-2 mb-2 ml-2">
              <span class="mr-1"
                ><v-icon size="small" color="#c5c9d3" icon="custom:searchIcon"
              /></span>
              <input
                class="placeholder"
                :placeholder="searchPlaceholder"
                v-model="searchTerm"
                @input="filterOptions"
                @click.stop
              />
            </div>
            <div
              v-if="showAddMore"
              @click="handleAddMore"
              class="mt-2 mb-2 ml-2 cursor-pointer"
            >
              <p class="!text-[#101828] !font-inter !font-normal">
                <span class="mr-1"
                  ><v-icon size="small" color="#667085" icon="mdi-plus"
                /></span>
                {{ addMoreText }}
              </p>
            </div>
            <div></div>
          </div>
          <div
            @click.stop="toggleOption(allItemsOption)"
            class="dropdown-option"
          >
            <div class="flex items-center">
              <div v-if="showCheckbox">
                <input
                  type="checkbox"
                  class="option-checkbox"
                  :id="'option-checkbox-' + 'All'"
                  value="All"
                  v-model="allSelected"
                  :checked="allSelected"
                />
              </div>
              <div>
                <label :for="'option-checkbox-' + 'All'"> All </label>
              </div>
            </div>
          </div>
          <div
            v-for="option in filteredOptions"
            :key="option.value"
            @click.stop="toggleOption(option)"
            :class="['dropdown-option', { selected: isSelected(option) }]"
          >
            <div class="flex items-center">
              <div v-if="showCheckbox">
                <input
                  type="checkbox"
                  class="option-checkbox"
                  :id="'option-checkbox-' + option?.text"
                  :value="option?.value"
                  :checked="isSelected(option)"
                />
              </div>
              <div>
                <label :for="'option-checkbox-' + option.value">
                  {{ option?.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <p v-if="error" class="text-red-500 text-[14px]">
          {{ $t(`${errorMessage}`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MultiSelect",
  props: {
    id: {
      type: String,
      default: "multi-select",
    },
    label: {
      type: String,
      default: "Label",
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: "multi-select",
    },
    labelPlaceholder: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object || String,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    showAddMore: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    addMoreText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOptions: this.modelValue || [],
      searchTerm: "",
      isOpen: false,
      maxDisplayedItems: 3,
      allItemsOption: { text: "All", value: "All" },
      allSelected: false,
    };
  },
  computed: {
    filteredOptions() {
      if (this.searchTerm) {
        const term = this.searchTerm?.toLowerCase();
        return this.options?.filter((option) =>
          option.text?.toLowerCase()?.includes(term)
        );
      }
      return this.options;
    },
    displayedOptions() {
      const extraSelectedCount = Math?.max(
        this.selectedOptions?.length - this.maxDisplayedItems,
        0
      );
      const displayedItems = this.selectedOptions?.slice(
        0,
        this.maxDisplayedItems
      );
      if (extraSelectedCount > 0) {
        displayedItems?.push({
          text: `+${extraSelectedCount}`,
        });
      }
      return displayedItems;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    truncateText(text) {
      if (text?.length > 11) {
        return text?.slice(0, 11) + "...";
      }
      return text;
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    isSelected(option) {
      return this.selectedOptions?.some((selected) =>
        this.compareOptions(selected, option)
      );
    },
    handleToggleAll() {
      if (this.allSelected) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions = this.filteredOptions;
      }
      this.error = false;
      this.returnObject
        ? this.$emit("update:modelValue", "All")
        : this.$emit("update:modelValue", this.selectedOptions);
    },
    toggleOption(option) {
      if (option.value === "All") {
        this.handleToggleAll();
        return;
      }
      if (this.isSelected(option)) {
        this.removeSelectedOption(this.findSelectedIndex(option));
      } else {
        this.selectedOptions?.push(option);
      }
      this.error = false;
      this.returnObject
        ? this.$emit("update:modelValue", option)
        : this.$emit("update:modelValue", this.selectedOptions);
    },
    removeSelectedOption(index) {
      this.selectedOptions?.splice(index, 1);
    },
    findSelectedIndex(option) {
      return this.selectedOptions?.findIndex((selected) =>
        this.compareOptions(selected, option)
      );
    },
    compareOptions(option1, option2) {
      return (
        option1?.value === option2?.value && option1?.text === option2?.text
      );
    },
    filterOptions() {
      this.isOpen = true;
    },
    closeDropdown(event) {
      if (!this.$el?.contains(event?.target)) {
        this.isOpen = false;
      }
    },
    preventDropdownClose(event) {
      event?.stopPropagation();
    },
    handleAddMore() {
      this.$emit("add-More");
    },
  },
  watch: {
    modelValue(val) {
      this.selectedOptions = val;
    },
    hasError(val) {
      this.error = val;
    },
  },
});
</script>

<style scoped>
.multi-select {
  position: relative;
}

/* .dropdown {
  display: inline-block;
} */
.wrapper-error {
  border-color: red !important;
}
.selected-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  /* min-width: 440px; */
  background: #ffff;
  width: 100%;
  max-width: 100%;
  position: relative;
  height: 44px;
  overflow-y: auto;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
  font-size: 14px;
  transition: transform 0.3s;
}

.selected-item {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin: 2px 3px;
  padding: 0 2px;
  color: #344054;
  height: 24px;
}

.selected-item span {
  margin-right: 4px;
}

.selected-item button {
  padding: 2px;
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;
}

.placeholder {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

.dropdown-list {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  min-width: -webkit-fill-available;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.dropdown-list .dropdown-option {
  padding: 4px;
  cursor: pointer;
}

.dropdown-list .dropdown-option.selected {
  background-color: #fbf7f6;
}

.dropdown-list .dropdown-option:hover {
  background-color: #f7fafc;
}

.option-checkbox,
.dropdown-list .dropdown-option {
  cursor: pointer;
}

.option-checkbox:hover,
.dropdown-list .dropdown-option:hover {
  background-color: #f7fafc;
}

/* Added styles */
.dropdown-option label {
  cursor: pointer;
  color: #101828;
  font-size: 16px;
}

.dropdown-list {
  pointer-events: all;
}

.dropdown-list.hide {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
  position: relative;
  margin-right: 5px;
  margin-top: 5px;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
  border-color: #ae6a65;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  background: #fafafb;
  color: #8e95a5;
  cursor: default;
}
</style>
