<template>
  <div>
    <v-row>
      <v-col cols="12" class="">
        <form disabled>
          <div class="mb-7" v-if="isProjectPage">
            <div class="">
              <app-input-field
                id="1"
                :label="$t('basicinformation.companyName')"
                :placeholder="$t('basicinformation.icon')"
                v-model:modelValue="basicInfo.companyName"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
              ></app-input-field>
            </div>
            <div class="mt-5">
              <app-input-field
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="2"
                :label="$t(getTranslatedStringKey('City *'))"
                :placeholder="$t(getTranslatedStringKey('City'))"
                v-model:modelValue="basicInfo.city"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7" v-else>
            <div class="w-[440px]">
              <app-input-field
                id="1"
                :label="$t('basicinformation.companyName')"
                :placeholder="$t('basicinformation.icon')"
                v-model:modelValue="basicInfo.companyName"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
              ></app-input-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="2"
                :label="$t(getTranslatedStringKey('City *'))"
                :placeholder="$t(getTranslatedStringKey('City'))"
                v-model:modelValue="basicInfo.city"
              ></app-input-field>
            </div>
          </div>
          <div class="flex justify-start gap-12 mb-7">
            <div class="w-[440px]">
              <app-select-field
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                v-model="basicInfo.country"
                :label="$t(getTranslatedStringKey('Country *'))"
                :placeholder="$t('personal.selectCountry')"
                :options="countryOptions"
              ></app-select-field>
            </div>
            <div class="w-[440px]">
              <app-input-field
                type="url"
                :disabled="
                  currentPath === '/edit-partner' &&
                  isEditPartner &&
                  !isEditButton
                "
                id="3"
                :label="$t('basicinformation.website')"
                :placeholder="$t('basicinformation.websitelabel')"
                v-model:modelValue="basicInfo.website"
              ></app-input-field>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <div class="w-[920px]">
                <div
                  v-if="currentPath !== '/edit-partner'"
                  class="flex justify-end mb-7"
                >
                  <div class="flex gap-3">
                    <div>
                      <app-secondary-button
                        :buttonLabel="$t('personal.saveAndExit')"
                        @click-event="savePartnerInfo('exit')"
                        :disabled="disableSaveButton()"
                      >
                      </app-secondary-button>
                    </div>
                    <div>
                      <app-primary-button
                        @click-event="savePartnerInfo('continue')"
                        :buttonLabel="$t('personal.saveAndContinue')"
                        :disabled="disableSaveButton()"
                      >
                      </app-primary-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flex justify-end gap-7">
                  <div v-show="isEditButton">
                    <app-secondary-button
                      :buttonLabel="$t('personal.cancel')"
                      @click="cancel"
                    ></app-secondary-button>
                  </div>
                  <app-primary-button
                    v-show="isEditButton"
                    :buttonLabel="$t('employment.saveChanges')"
                    @click-event="savePartnerInfo('edit')"
                    :disabled="!disableSaveButton()"
                  >
                  </app-primary-button>
                </div>
              </div>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>

    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
    />
  </div>
  <div class="modal-board" v-if="showmodal && basicInfo.official_email === ''">
    <div class="flex justify-between">
      <h3 class="H400">We recommend that you provide an email</h3>
      <v-icon icon="custom:closeIcon" @click="showmodal = false" />
    </div>
    <p class="P300">
      Leaving the email blank means employees won’t receive any invitation
      email.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import countries from "@/services/country_api";
import { getTransString } from "@/services/helpers";
import { countryCitizens } from "@/services/citizen";
import Api from "@/services/api";
import { isEqual } from "lodash";
export default defineComponent({
  name: "Personal",
  components: {},
  props: {
    errors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      alertType: "success",
      alertState: false,
      alertTitle: "",
      isEdit: false,
      firstRun: true,
      loadUserKey: null,
      countryOptions: null,
      countryCitizensArray: [],
      originalFormData: {
        companyName: "",
        city: "",
        website: "",
        country: "",
      },
      basicInfo: {
        companyName: "",
        city: "",
        website: "",
        country: "",
      },
      allUserRoles: [],
      page: 1,
      roles: [],
      showmodal: true,
    };
  },
  computed: {
    isEditPartner() {
      return this.$store.getters["partnerModules/isEditPartner"];
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    isEditButton() {
      return (
        this.$store.getters["partnerModules/isEditButton"] &&
        this.canEditPersonalModule?.includes("Partner Basic Information")
      );
    },
    isPartnerData() {
      return this.$store.getters["partnerModules/isPartnerInfo"];
    },
    isPartnerId() {
      return this.$store.getters["partnerModules/isPartnerId"];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    basicInfo: {
      deep: true,
      handler(value) {
        this.$store.commit("partnerModules/SET_PARTNER_UPDATE", {
          BasicInformation: !isEqual(value, this.originalFormData),
        });
      },
    },
  },
  methods: {
    getCountryByName() {
      const countryArray = [];
      for (const country of countries) {
        countryArray.push(country.name);
      }
      return countryArray;
    },
    disableSaveButton() {
      const personalFormObj = { ...this.basicInfo };
      const excludes = ["website"];
      const modifiedObject = Object.keys(personalFormObj).reduce(
        (prev, curr) => {
          if (excludes.includes(curr)) {
            return prev;
          } else {
            prev[curr] = personalFormObj[curr];
            return prev;
          }
        },
        {}
      );
      return Object.values(modifiedObject).some((c) => c === "");
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    handleNextClick() {
      this.$emit("next-clicked");
    },
    hideDialog() {
      this.$emit("hide-dialog", false);
    },
    cancel() {
      this.$router.go(-1);
    },

    savePartnerInfo(data) {
      const payload = {
        company_name: this.basicInfo.companyName,
        city: this.basicInfo.city,
        website: this.basicInfo.website,
        country: this.basicInfo.country,
      };

      if (this.isPartnerId) {
        payload.partner_id = this.isPartnerId;
      }

      Api()
        .post("/partners/add-new-partner/", payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit(
              "partnerModules/SET_PARTNER_KEY_TO_STATE",
              response.data.data.partner_id
            );
            this.$store.commit(
              "partnerModules/SET_PERSONAl_DATA_TO_STATE",
              response.data.data
            );
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;

            this.$store.commit("partnerModules/SET_PARTNER_UPDATE", {
              BasicInformation: false,
            });

            setTimeout(() => {
              if (data === "exit") {
                this.$router.push({ name: "partners" });
              }
              if (data === "continue") {
                this.$store.commit(
                  "partnerModules/SET_CURRENT_TAB_TO_STATE",
                  1
                );
              }
            }, 1500);
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },

    getPartnerInfo() {
      Api()
        .get(`/partners/get-partner-info/${this.isPartnerId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data;
            this.$store.commit(
              "partnerModules/SET_PERSONAl_DATA_TO_STATE",
              data
            );
          }
        });
    },
    populateFieldsWithData() {
      this.basicInfo = this.isPartnerData;
      this.basicInfo.companyName =
        (this.isPartnerData.company_name ||
          this.isPartnerData["company name"]) ??
        "";

      this.originalFormData = this.isPartnerData;
      this.originalFormData.companyName =
        (this.isPartnerData.company_name ||
          this.isPartnerData["company name"]) ??
        "";
    },
  },
  mounted() {
    // Loop through the countryCitizens object
    for (const country in countryCitizens) {
      if (Object.prototype.hasOwnProperty.call(countryCitizens, country)) {
        const citizen = countryCitizens[country];
        this.countryCitizensArray.push({ id: citizen, value: citizen });
      }
    }
    this.countryOptions = this.getCountryByName();
    // this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
  },
  created() {
    this.$store.commit(
      "partnerModules/SET_EDIT_PARTNER_TO_STATE",
      this.isPartnerId !== null
    );
    this.isEdit = this.isEditPartner;
    this.$store.commit("partnerModules/SET_CURRENT_TAB_TO_STATE", 0);
    this.getPartnerInfo()
    // if (this.$store.getters["partnerModules/isPartnerId"]) {
    //   this.populateFieldsWithData();
    // }
  },
});
</script>

<style scoped>
.select-field {
  width: 440px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #f4eae9;
}

input[type="checkbox"]:checked::before {
  content: "✔";
  color: #ae6a65;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-board {
  position: fixed;
  top: 90%;
  left: 85%;
  transform: translate(-50%, -50%);
  right: 0;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 396px;
  height: 98px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}
.disabled {
  /* cursor: not-allowed; */
  color: gray;
  opacity: 0.5;
}
</style>
