<template>
    <div>
      <app-header
        :name="isUserKey ? isName : $t('foreignEmployees.newEmployee')"
        class="mb-5 ml-2"
        :clickEvent="handleGoToBack"
        :show-arrow-back="true"
      >
      <template #action v-if="isUserKey">
        
        <app-secondary-button
          v-show="!isEditButton "
          :buttonLabel="$t('employees.editProfile')"
          @click="showEditButton"
        >
          <template #icon>
            <v-icon icon="custom:editIcon" color="#344054"></v-icon>
          </template>
        </app-secondary-button>
      </template>
      </app-header>
      <div>
        <div>
          <button
            :disabled="!isUserKey"
            v-for="(tab, index) in tabList"
            :key="index"
            :class="[
              'tab-button',
              { active: currentTab === tabs[index] },
              { disabled: !isUserKey },
              { '!opacity-50': tabListForExcluded?.includes(tab) && !isUserKey},
            ]"
            @click="handleProceed(index)"
          >
            {{ $t(tab.getTranslationKey()) }}
          </button>
          <div class="mt-2 border-t-[1px]"></div>
        </div>
      </div>
      <component
        :is="currentTab"
        v-bind="isCurrentTab"
        class="pt-5"
        :handleButtonRoute="handleButtonRoute"
      ></component>
    </div>
    <app-unsaved-changes-dialog
      ref="unsavedChanges"
      :title="$t('employees.unsavedChangesTitle')"
      :subtitle="$t('employees.unsavedChangesSubTitle')"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('generalConfig.cancel')"
              @click="closeDialog"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6">
            <app-primary-button
              :buttonLabel="$t('employees.discardChanges')"
              @click="discardChanges"
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-unsaved-changes-dialog>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Personal from "@/components/dashboard/foreign-employees/Personal.vue";
  import Contact from "@/components/dashboard/foreign-employees/Contact.vue";
 
  import Qualification from "@/components/dashboard/foreign-employees/Qualification.vue";
 
  import AppHeader from "@/components/shared/AppHeader.vue";
  import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";
  
  export default defineComponent({
    name: "AddForeignEmployee",
    components: {
      AppUnsavedChangesDialog,
      Personal,
      Contact,
      Qualification,
      AppHeader,
    },
    data() {
      return {
        currentTab: "Personal",
        isArrowBack: false,
        tabList: ["Personal", "Contact",  "Qualification"],
        tabs: ["Personal", "Contact", "Qualification"],
        tabListForExcluded: ["Contact","Qualification"],
      };
    },
    computed: {
        
    noAccess() {
      const modifiedData = this.$store.getters["auth/noAccess"];
      return modifiedData?.filter((module) => module.module_parent_name === "Foreign Employee")
        .map((module) => module.module_name);
    },
    canView() {
      return this.$store.getters["auth/canView"];
    },
    canEdit() {
      const modifiedData = this.$store.getters["auth/canEdit"];
      // const findModules = modifiedData?.filter((module)=>module.module_parent_name==='Employee').map((module)=>module.module_name)
      const findModules = modifiedData
        ?.filter((module) => module.module_parent_name === "Foreign Employee")
        .map((module) => module.module_name);
        this.$store.commit("auth/SET_CAN_EDIT_TO_STATE", findModules);
        
      return findModules;
    },
    hideEditButton() {
      const originalArray = this.canEdit
          const newArray = originalArray?.map(item => item?.replace("Foreign Staff ", ""));
      return (
        !this.isEditButton &&
        newArray?.includes(this.currentTab) &&
        this.currentTab !== "EmployeeTimeOff" 
  
      );
    },
      isAllEmployees() {
        return this.$store.getters["hrResources/isAllQualification"];
      },
      isUserKey() {
        return this.$store.getters["hrResources/isUserKey"];
      },
      isEditButton() {
      return this.$store.getters["hrResources/isEditButton"];
    },
      isEmployeesUpdate() {
        return this.$store.getters["hrResources/isEmployeesUpdate"];
      },
      isCurrentTab() {
        return this.$store.getters["hrResources/isCurrentTab"];
      },
      isName() {
        const personalData = this.$store.getters["hrResources/isPersonalData"];
        return (
          (personalData?.first_name || "") + " " + (personalData?.last_name || "")
        );
      },
      addEmployeeLang() {
        return this.translation.locale;
      },
    },
    methods: {
        showEditButton() {
          const originalArray = this.canEdit
          const newArray = originalArray?.map(item => item?.replace("Foreign Staff ", ""));

      this.$store.commit(
        "hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE",
        newArray?.includes(this.currentTab)
      );
    },
      handleBeforeUnload(event) {
        if (this.checkChangeStatus()) {
          // Customize the confirmation message
          const confirmationMessage =
            "You have unsaved changes. Are you sure you want to leave this page?";
  
          // Display the confirmation dialog
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      },
      checkChangeStatus() {
        return Object.values(this.isEmployeesUpdate).some((elt) => elt === true);
      },
      handleProceed(index) {
        this.newTabIndex = index;
        if (this.isEmployeesUpdate[this.currentTab]) {
          this.$refs.unsavedChanges.showDialog = true;
        } else {
          this.currentTab = this.tabs[index];
        }
      },
      handleGoToBack() {
        if (Object.values(this.isEmployeesUpdate).some((elt) => elt === true)) {
          this.$refs.unsavedChanges.showDialog = true;
          this.isArrowBack = true;
        } else {
          this.$router.go(-1);
        }
      },
      handleButtonRoute(route) {
        this.newTabIndex = route;
        if (this.isEmployeesUpdate[this.currentTab]) {
          this.$refs.unsavedChanges.showDialog = true;
        } else {
          this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", route);
        }
      },
      closeDialog() {
        this.$refs.unsavedChanges.showDialog = false;
      },
      discardChanges() {
        this.$refs.unsavedChanges.showDialog = false;
        const newUpdates = Object.keys(this.isEmployeesUpdate).reduce(
          (acc, curr) => {
            if (this.isEmployeesUpdate[curr]) {
              acc[curr] = false;
            }
            return acc;
          },
          {}
        );
        this.$store.commit("hrResources/SET_EMPLOYEE_UPDATE", newUpdates);
        if (this.isArrowBack) {
          this.$router.go(-1);
        } else {
          this.currentTab = this.tabs[this.newTabIndex];
        }
      },
    },
    created() {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
      this.currentTab = this.tabs[this.isCurrentTab];
      window.addEventListener("beforeunload", this.handleBeforeUnload);
    },
    updated() {
      this.currentTab = this.tabs[this.isCurrentTab];
    },
    beforeUnmount() {
      window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
  });
  </script>
  
  <style scoped></style>
  