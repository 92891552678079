import CryptoJS from "crypto-js";
import enStrings from "@/locales/en.json";
import store from "@/store";
import moment from "moment";

const key = "fabrodenobetomekhuck";

const enTrans = enStrings;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthsNameWithNumber = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

export const monthsShortForm = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const permissions = store?.getters["auth/permissions"];

export function isValidEmail(email) {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

// encryption
export function Encrypt(text) {
  const b64 = CryptoJS.AES.encrypt(JSON.stringify(text), key).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  return e64.toString(CryptoJS.enc.Hex);
}

// Table Field translation strings
export const getTransString = (field) => {
  let selectedKey = "";
  Object.keys(enTrans).some((parentKey) => {
    const childKey = Object.keys(enTrans[parentKey]).find((key) => {
      return enTrans[parentKey][key] === field;
    });
    if (childKey) {
      selectedKey = parentKey + "." + childKey;
      return true;
    }
  });
  return selectedKey;
};

//returns title from module list for select field options
export const getSelectOptionList = (storeModuleGetters) => {
  const moduleGetter = store?.getters[storeModuleGetters];
  return moduleGetter.map((moduleList) => {
    if (!moduleList.employee_id) {
      return {
        id: moduleList.title ? moduleList.title : moduleList.company_name,
        value: moduleList.id,
        name: moduleList.company_name,
        address: `${moduleList.address_line_1}, ${moduleList.city}, ${moduleList.country}`,
      };
    } else {
      return {
        id: `${moduleList.first_name} ${moduleList.last_name}`,
        value: moduleList.id,
      };
    }
  });
};

export function handleFilterSet(type, args, removeFilter, setFilter) {
  if (args[0] === "All" || args[0] === "Alle" || args[0] === "") {
    store.commit(removeFilter, type);
  } else {
    store.commit(setFilter, [type, args[0]]);
  }
}

// Restrict field to only specified regex
export const restrictCharactersByRegex = (event, regex) => {
  const inputValue = event.target.value;
  event.target.value = inputValue?.replace(regex, "");
};

// Replace a value with another value in an array
export const arrayValueReplacer = (data, valueToReplace, replacementValue) => {
  return data.map((element) => {
    if (element === valueToReplace) {
      return replacementValue;
    }
    return element;
  });
};

// Prepare data for select
export const prepareDataForSelect = (data, fieldForId, fieldForValue) => {
  return data.reduce((acc, curr) => {
    return [...acc, { id: curr[fieldForId], value: curr[fieldForValue] }];
  }, []);
};
// date calculation
export function formatDuration(start_date, end_date) {
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  const durationInMilliseconds = endDate - startDate;
  const durationInDays = Math.floor(
    durationInMilliseconds / (1000 * 60 * 60 * 24)
  );
  const durationInYears = Math.floor(durationInDays / 365);
  const remainingDays = durationInDays % 365;
  const durationInMonths = Math.floor(remainingDays / 30.436875); // Average days per month
  const remainingMonths = remainingDays % 30.436875;

  let formattedDuration = "";

  if (durationInYears > 0) {
    formattedDuration +=
      durationInYears + " year" + (durationInYears > 1 ? "s" : "");
  }

  if (durationInMonths > 0) {
    if (formattedDuration !== "") {
      formattedDuration += " ";
    }
    formattedDuration +=
      durationInMonths + " month" + (durationInMonths > 1 ? "s" : "");
  }

  if (remainingMonths > 0) {
    if (formattedDuration !== "") {
      formattedDuration += " ";
    }
    formattedDuration +=
      Math.round(remainingMonths) +
      " day" +
      (Math.round(remainingMonths) > 1 ? "s" : "");
  }

  if (formattedDuration === "") {
    if (remainingDays === 0) {
      formattedDuration = "Less than a day";
    } else {
      formattedDuration =
        remainingDays + " day" + (remainingDays > 1 ? "s" : "");
    }
  }

  return formattedDuration;
}

// format Byte function
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getFileNameFromURL(url) {
  const startIndex = url.lastIndexOf("_") + 1;
  return url.substring(startIndex);
}

export function getFullFileNameFromURL(url) {
  const startIndex = url.lastIndexOf("/") + 1;
  return url.substring(startIndex);
}

export const getInitial = (name) => {
  if (name) {
    let initials = "";
    const splittedName = name?.split(" ") || [];
    splittedName.forEach((elt) => {
      initials += elt[0]?.toUpperCase() || "";
    });
    return initials;
  }

  return "";
};

export function convertToCamelCase(str) {
  return str?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // Remove spaces
    return index === 0 ? match?.toLowerCase() : match?.toUpperCase();
  });
}

// extract underscore from table field and add space
export function extractUnderscore(data) {
  return data?.map((obj) => {
    const newObj = {};
    for (let key in obj) {
      const newKey = key.replace(/_/g, " ");
      newObj[newKey] = obj[key];
    }
    return newObj;
  });
}

//remove underscore from object
export function addUnderscores(obj) {
  const updatedObj = {};
  Object?.keys(obj)?.forEach((key) => {
    const newKey = key?.replace(/ /g, "_");
    updatedObj[newKey] = obj[key];
  });

  return updatedObj;
}

export function fileNameFromURL(url) {
  const startIndex = url?.lastIndexOf("/") + 1;
  return url?.substring(startIndex);
}

export function convertYesToTrue(object) {
  for (let key in object) {
    if (object[key] === "Yes") {
      object[key] = true;
    } else if (object[key] === "No") {
      object[key] = false;
    }
  }
  return object;
}
export function convertToBoolean(data) {
  if (typeof data === "object" && data !== null) {
    if (Array?.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (typeof data[i] === "string" && data[i]?.toLowerCase() === "yes") {
          data[i] = true;
        } else {
          convertToBoolean(data[i]);
        }
      }
    } else {
      for (const key in data) {
        if (
          typeof data[key] === "string" &&
          data[key]?.toLowerCase() === "yes"
        ) {
          data[key] = true;
        } else {
          convertToBoolean(data[key]);
        }
      }
    }
  }
  return data;
}

export function formatAmount(amount) {
  return parseFloat(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function currencyFormatter(value) {
  return new Intl.NumberFormat("en-DE", { minimumFractionDigits: 2 }).format(
    value
  );
}

export function formatDate(date, lang) {
  return lang === "de"
    ? moment(date, "YYYY-MM-DD")?.format("D.M.YYYY")
    : moment(date, "YYYY-MM-DD")?.format("D/M/YYYY");
}

export const formattedDate = (date) => {
  const locale = store?.getters["translation/getLocale"];
  if (typeof date === "object") {
    date = date?.toISOString()?.slice(0, 10);
  }
  return locale === "de"
    ? moment(date?.trim()?.dateToYYYYMMDD(false), "YYYY-MM-DD")?.format(
        "D.M.YYYY"
      )
    : moment(date?.trim()?.dateToYYYYMMDD(false), "YYYY-MM-DD")?.format(
        "D/M/YYYY"
      );
};
export function compareObjects(obj1, obj2, keyMapping) {
  // Check if obj1 and obj2 are valid objects
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Iterate through the key mapping array
  for (const mapping of keyMapping) {
    const keyObj1 = mapping[0]; // Key in obj1
    const keyObj2 = mapping[1]; // Key in obj2

    // Check if the keys exist in both objects using Object.prototype.hasOwnProperty.call
    if (
      !Object?.prototype?.hasOwnProperty?.call(obj1, keyObj1) ||
      !Object?.prototype?.hasOwnProperty?.call(obj2, keyObj2)
    ) {
      return false;
    }

    // Iterate through the key mapping array
    for (const mapping of keyMapping) {
      const keyObj1 = mapping[0]; // Key in obj1
      const keyObj2 = mapping[1]; // Key in obj2

      // Check if the keys exist in both objects using Object.prototype.hasOwnProperty.call
      if (
        !Object.prototype.hasOwnProperty.call(obj1, keyObj1) ||
        !Object.prototype.hasOwnProperty.call(obj2, keyObj2)
      ) {
        return false;
      }

      // Compare the values for the corresponding keys
      if (obj1[keyObj1] !== obj2[keyObj2]) {
        return false;
      }
    }

    // If all key-value pairs in the mapping are equal, return true
    return true;
  }
}

export const findKeyByValue = (object, targetItem) => {
  for (const [key, value] of Object.entries(object)) {
    if (value.includes(targetItem)) {
      return key;
    }
  }
  return null;
};

// Prototypes

String.prototype.toTitleCase = function () {
  return this?.charAt(0)?.toUpperCase() + this.slice(1)?.toLowerCase();
};

String.prototype.getTranslationKey = function () {
  return getTransString(this);
};

Array.prototype.convertArrayToObject = function () {
  return this?.map((curr) => ({ id: curr, value: curr }));
};

Number.prototype.formatCurrency = function () {
  return parseFloat(this).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

Number.prototype.germanCurrencyFormatter = function () {
  return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(
    this
  );
};

Number.prototype.getCurrencyFormat = function () {
  const lang = store?.getters["translation/getLocale"];
  return lang === "de"
      ? this?.germanCurrencyFormatter()
      : this?.formatCurrency();
};

Array.prototype.getObjectValues = function () {
  return this?.map((curr) => curr?.value);
};
export const getYearsList= (startingYear) => {
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear; year >= startingYear; year--) {
    yearsList.push({ text: year, value: year });
  }

  return yearsList;
};

export const noAccess = () => {
  return getPermissionModuleName("0");
};

export const canView = () => {
  return getPermissionModuleName("1");
};

export const canEdit = () => {
  return getPermissionModuleName("2");
};

export const getPermissionModuleName = (level) => {
  let moduleNames = [];
  for (const permission of permissions) {
    // if accessLevel is 0, dont push to the module names array
    if (permission?.access_level === level) {
      moduleNames.push({
        module_name: permission?.module__name,
        module_parent_name: permission?.module__parent__name,
      });
    }
  }
  return moduleNames;
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + "...";
  }
};

export const stripHtmlTags = (input) => {
  return input.replace(/<[^>]*>/g, "");
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Adds smooth scrolling effect
  });
};

export const minimumEndDate = (startDateArg) => {
  // startDateArg is the start_date
  if (startDateArg) {
    const minDate = new Date(startDateArg);
    minDate.setDate(minDate?.getDate() + 1);
    return minDate?.toISOString()?.split("T")[0];
  }
  return null;
};

export const maximumStartDate = (endDateArg) => {
  // endDateArg is the end_date
  if (endDateArg) {
    const minDate = new Date(endDateArg);
    minDate.setDate(minDate?.getDate() - 1);
    return minDate?.toISOString()?.split("T")[0];
  }
  return null;
};

export const getFirstDateOfWeek = (startDay) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate how many days to subtract to get to the start of the week
  const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

  const firstDateOfWeek = new Date(today);
  firstDateOfWeek.setDate(today.getDate() - daysUntilStartOfWeek);

  const year = firstDateOfWeek.getFullYear();
  const month = String(firstDateOfWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(firstDateOfWeek.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getFormattedFirstDatesForNextNWeeks = (startDay, n) => {
  let result = "";

  for (let i = 0; i < n; i++) {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate how many days to subtract to get to the start of the week
    const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

    const firstDateOfWeek = new Date(today);
    firstDateOfWeek.setDate(today.getDate() - daysUntilStartOfWeek + 7 * i);

    const year = firstDateOfWeek.getFullYear();
    const month = String(firstDateOfWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(firstDateOfWeek.getDate()).padStart(2, "0");

    result = `${year}-${month}-${day}`;
  }

  return result;
};

export const getFormattedFirstDatesForNextAndPastNWeeks = (startDay, n) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate how many days to subtract to get to the start of the week
  const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

  const firstDateOfCurrentWeek = new Date(today);
  firstDateOfCurrentWeek.setDate(today.getDate() - daysUntilStartOfWeek);

  const year = firstDateOfCurrentWeek.getFullYear();
  const month = String(firstDateOfCurrentWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(firstDateOfCurrentWeek.getDate()).padStart(2, "0");

  const futureDate = `${year}-${month}-${day}`;

  const firstDateOfPastWeek = new Date(firstDateOfCurrentWeek);
  firstDateOfPastWeek.setDate(firstDateOfCurrentWeek.getDate() - 7 * n);

  const pastYear = firstDateOfPastWeek.getFullYear();
  const pastMonth = String(firstDateOfPastWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const pastDay = String(firstDateOfPastWeek.getDate()).padStart(2, "0");

  const pastDate = `${pastYear}-${pastMonth}-${pastDay}`;

  return { future: futureDate, past: pastDate };
};

export const getYearsFromProject = (startDate, endDate) => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push({ text: i, value: i });
  }
  return years;
};

String.prototype.dateToYYYYMMDD = function (noZero = true) {
  const regex = /([-/.])/;

  // Check if the string contains any of the specified characters
  let day = null;
  let month = null;
  let year = null;

  const match = this.match(regex);
  if (match) {
    const trimBy = match[1];
    const YYMMDDFormat = /^\d{4}\D\d{2}\D\d{2}$/;
    if (YYMMDDFormat.test(this)) {
      const dateSplit = this.split("-");
      day = dateSplit[2];
      month = dateSplit[1];
      year = dateSplit[0];
    } else {
      const dateSplit = this.split(trimBy);
      day = dateSplit[0];
      month = dateSplit[1];
      year = dateSplit[2];
    }

    if (noZero) {
      return `${year}-${month}-${day}`;
    } else {
      return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
    }
  }
};

// show reset password dialog
export const showResetPasswordDialog = (data, isReset) => {
  const userKey = data?.$event?.user_key;
  const newPassword = "";
  const confirmPassword = "";
  const subTitle = "";
  const cancelLabel = "employees.cancel";
  let title = "";
  let buttonLabel = "";
  let showDialog = false;
  if (data.data === "reset") {
    title = "employees.resetPassword";
    buttonLabel = "employees.resetPassword";
    isReset = !isReset;
    showDialog = true;
  }

  return {
    title,
    subTitle,
    buttonLabel,
    cancelLabel,
    isReset,
    userKey,
    newPassword,
    confirmPassword,
    showDialog,
  };
};

String.prototype.isDecimal = function () {
  // Use parseFloat to attempt conversion
  const floatValue = parseFloat(this);

  // Check if the conversion was successful and the result is a number
  if (isNaN(floatValue) || !isFinite(floatValue)) {
    return false;
  }

  // Check if the string has a non-zero fractional part
  if (!this.includes(".")) {
    return false;
  }

  // Check if the string has a valid decimal format (e.g., not a date)
  const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
  if (dateRegex.test(this)) {
    return false;
  }

  // Check if the string has a valid decimal format (e.g., not a date range)
  const dateRangeRegex = /^\d{2}\.\d{2}\.\d{4}-\d{2}\.\d{2}\.\d{4}$/;
  const dateRangeRegexCond2 = /^\d{2}\.\d{2}\.\d{4} - \d{2}\.\d{2}\.\d{4}$/;
  return !(dateRangeRegex.test(this) || dateRangeRegexCond2.test(this));
};
export const getSignee = (currentUserDetails) => {
  const roleMapping = {
    is_ceo: "ceo",
    is_team_lead: "team_lead",
    is_controller: "controller",
    is_project_manager: "pm",
    is_accountant: "accountant",
    is_partner: "company",
    is_expert: "expert",
  };

  let signee = "employee";

  for (const role in roleMapping) {
    if (currentUserDetails[role]) {
      signee = roleMapping[role];
      break;
    }
  }

  return signee;
};
export const getFlightRequestSignee = (currentUserDetails) => {
  const roleMapping = {
    is_ceo: "CEO",
    is_team_lead: "Team Lead",
    is_controller: "Controller",
    is_project_manager: "Project Manager",
    is_accountant: "Accountant",
    is_partner: "Company",
    is_expert: "Expert",
  };

  let signee = "Requester";

  for (const role in roleMapping) {
    if (currentUserDetails[role]) {
      signee = roleMapping[role];
      break;
    }
  }

  return signee;
};

String.prototype.formatDateIncludingMonthName = function () {
  // Split the input string to extract day, month, and year
  const parts = this.split(" ");

  // Extract day, month, and year
  const day = parts[0].replace(/[^0-9]/g, ""); // Remove non-numeric characters
  const month = parts[1];
  const year = parts[2];

  // Convert month abbreviation to a numeric value
  const monthIndex = new Date(Date.parse(`${month} 1, 2000`)).getMonth() + 1;
  if (!monthIndex) return this;

  // Create a Date object with the extracted components
  const formattedDate = new Date(`${year}-${monthIndex}-${day}`);

  // Format the Date object to the desired output format
  return formattedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

String.prototype.formattedDate = function () {
  const lang = store?.getters["translation/getLocale"];
  return formatDate(this?.trim()?.dateToYYYYMMDD(false), lang);
};

export const getYearsListFrom2000 =()=>{
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear; year >= 2000; year--) {
    yearsList.push({text: year, value: year});
  }

  return yearsList;
}

export const getTabIndex = (data, tab) => {
  return data.findIndex((item) => item === tab);
};

export const convertTimestamptoDate=(timestamp)=>{
  const date = new Date(timestamp);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
}

export const checkNumberInput = (event) => {
  const regex = /^[0-9\b]+$/;
  const essentialKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", ".", "Tab", "Enter"];
  if (!regex.test(event.key) && !essentialKeys.includes(event.key)) {
    event.preventDefault();
  }
}

// export const routeBack =() =>{
//   router.go(-1);
// }
