<template>
  <div class="container">
    <div class="flex">
      <div class="pr-6">
        <app-date-field
          :label="$t('leaves.startDate')"
          type="date"
          v-model="start_date"
          id="start-date"
          @input="searchLeave"
          name="start-date"
        ></app-date-field>
      </div>
      <div class="pr-6">
        <app-date-field
          :label="$t('leaves.endDate')"
          type="date"
          v-model="end_date"
          id="start-date"
          @input="searchLeave"
          name="end-date"
        ></app-date-field>
      </div>
      <div class="w-[200px]">
        <app-select-field-object
          :label="$t('leaves.status')"
          placeholder="All"
          :options="statusOption.convertArrayToObject()"
          @change="searchLeave"
          v-model="status"
        ></app-select-field-object>
      </div>
    </div>
    <div class="table py-13">
      <app-table
        :isCalender="true"
        :headers="headers"
        :items="isAllLeaves"
        :showCursor="false"
        pagename="Leaves"
        message="Leaves"
        @delete-event="showDelete({ $event, data: 'delete' })"
        @edit-event="showDetail({ $event, data: 'detail' })"
      >
        <template #title>
          <h3 class="H500">{{ $t("leaves.AllLeaves") }}</h3>
        </template>
        <template #footer>
          <div v-if="isFilter">
            <Paginator
              :total-items="totalPages"
              v-model="currentfilter"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMorefilter"
            />
          </div>
          <div v-else>
            <Paginator
              :total-items="totalPages"
              v-model="currentPages"
              :items-per-page="10"
              :max-pages-shown="5"
              :on-click="loadMore"
            />
          </div>
        </template>
      </app-table>
    </div>
    <app-calender-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="662"
      width="1808"
    >
    </app-calender-dialog>
    <app-calender-dialog ref="isDetail" :title="title" :subtitle="subTitle">
      <template #content>
        <div>
          <HrDetailCalender @close-event="hideDetail" />
        </div>
      </template>
    </app-calender-dialog>
    <app-dialog
      ref="delete"
      :title="$t('Delete Leave?')"
      :subtitle="`Are you sure you want to permanently delete this Leave ? This action can’t be undone.`"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6">
            <app-secondary-button
              :buttonLabel="$t('No, Keep it')"
              @click-event="closeDelete()"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="deleteLeave()"
            >
              Yes , delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
  </div>
  <app-alert
    :title="$t(getTranslatedStringKey(alertTitle))"
    @close-alert="alertState = false"
    :state="alertState"
    :timeout="5000"
    :type="alertType"
  />
</template>

<script lang="js">
 import { defineComponent } from "vue";
 import Api from "@/services/api";
 import AppCalenderDialog from "@/components/shared/AppCalenderDialog.vue";
 import Paginator from "@/components/shared/PaginationComponent.vue";
import HrDetailCalender from "./HrDetailCalender.vue";
import { useI18n } from 'vue-i18n';
import {getEmployeeLeaveByHrOrApprover,filterLeaves} from '@/services/endpoints'
 import moment from 'moment'
 import { getTransString } from "@/services/helpers";
 export default defineComponent({
   name: "All leaves",
   components: {
     Paginator,
     HrDetailCalender,
     AppCalenderDialog,
   },
   created() {
     this.$store.commit('hrResources/SET_CURRENT_TAB_TO_STATE', 4);
   },
   data() {
     return {
       headers: [
         "Requested By",
         "Date",
         "1st Approval",
         "2nd Approval",
         "Status",
               ],
       currentPages : 1,
       currentfilter:1,
       loading: false,
       addloading: false,
       alertType: "success",
       alertState: false,
       alertTitle: "",
       data: [],
       values: [],
       options: [""],
       cancelLabel: "Cancel",
       buttonLabel: "Add position",
       title: "",
       subTitle: "",
       isLeavedeleteId:'',
       isEdit: false,
       isAdd: false,
       isDelete: false,
       addTitle: "",
       isFilter:false,
    status:'',
    start_date:'',
    end_date:'',
    statusOption:['Approved', 'Pending', 'Declined']




     };

   },

   computed: {

     isUserKey() {
       return this.$store.getters["hrResources/isUserKey"];
     },
     currentPage() {
       return this.$store.getters["hrResources/isCurrentPage"];
     },
     isCurrentTab() {
       return this.$store.getters["hrResources/isCurrentTab"];
     },
     totalPages() {
       return this.$store.getters["hrResources/isTotalPages"];
     },
     isPersonalData() {
       return this.$store.getters["hrResources/isPersonalData"];
     },
     isAllLeaves() {
       const allLeaves = this.$store.getters["hrResources/isAllLeaves"];
        const transLeaves = allLeaves.reduce((accumulator, current) => {
          const newCurrent = {...current};
          newCurrent.status = this.transText(current.status)
          return [...accumulator, newCurrent]
        }, [])
        return transLeaves
     },
   },

   methods: {
     getTranslatedStringKey(field) {
       return getTransString(field);
     },
     transText(text) {
      const {t} = useI18n();
      return t(getTransString(text));
    },
    //  showDialog(data) {
    //    this.leaveId = data?.$event?.id;
    //    this.modelValue = data?.$event?.title;
    //    this.subTitle = "";
    //    if (data.data === "add") {
    //      this.title = "";
    //      this.$refs.dialog.showDialog = true;
    //    }
    //  },
    //  hideDialog() {
    //   this.getAllLeaves(this.currentPage);
    //      this.$refs.dialog.showDialog = false;
    //  },

     showDetail(data) {
       this.leaveId = data?.$event?.id;
       this.leaveType = data?.$event.leaveType;
       this.status = data?.$event.status;
       this.month=data?.$event.month
       this.year=data?.$event.year
       this.requestedby=data?.$event.requestedby
       this.$store.commit("hrResources/SET_LEAVE_ID_TO_STATE",this.leaveId);
       this.$store.commit("hrResources/SET_LEAVE_TYPE_TO_STATE",this.leaveType);
       this.$store.commit("hrResources/SET_LEAVE_STATUS_TO_STATE",this.status);
       this.$store.commit("hrResources/SET_LEAVE_MONTH_TO_STATE",this.month);
       this.$store.commit("hrResources/SET_LEAVE_PERSON_TO_STATE",this.requestedby);
       this.$store.commit("hrResources/SET_LEAVE_YEAR_TO_STATE", this.year);
       if (data.data === "detail") {
         this.isDelete = !this.isDelete;
         this.$refs.isDetail.showDialog = true;
       }
     },
     hideDetail() {
      this.getAllLeaves(this.currentPage);
         this.$refs.isDetail.showDialog = false;


     },

     searchLeave(page) {
      const payload = {
  filters: []
};

if (this.start_date) {
  payload.filters.push({ leave_start_date: this.start_date });
  this.isFilter=true
  page=this.currentfilter
}

if (this.end_date) {
  payload.filters.push({ leave_end_date: this.end_date });
  this.isFilter=true
  page=this.currentfilter
}
if (this.status) {
  payload.filters.push({ status: this.status });
  this.isFilter=true
page=this.currentfilter

}
if (this.status==='All') {
 this.getAllLeaves(this.currentPage)
 this.end_date=''
 this.start_date=''
 page=this.currentfilter


}
if (this.end_date==='') {
 page=this.currentfilter
}
if (this.start_date==='') {
 page=this.currentfilter


}
  Api()
    .post(`${filterLeaves}${page}/`, payload)
    .then((response) =>  {
           if (response.data.status === "success") {
             const data = response.data.data;

            //  this.$refs.dialog.showDialog = false;
             this.values = data.map((obj) => {
               const startDate = moment(obj.leave_start_date).format('Do MMM YYYY');
                 const endDate = moment(obj.leave_end_date).format('Do MMM YYYY');
                 const Year = moment(obj.leave_start_date).format('YYYY');
                 const days = Math.floor(obj.total_days);
               const hours = Math.floor((obj.total_days - days) * 24);
               const result = `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`;
                 return {
                 id: obj.id,
                 days:result,
                 date: ` ${startDate} - ${endDate}`,
                 status: obj.status,
                 'leave type': obj.leave_type,
                 'requested by': ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                 '1st approval':  ` ${obj.leave_approver__first_name===""?"":obj.leave_approver__first_name}   ${obj.leave_approver__last_name===""?"-":obj.leave_approver__last_name}`,
                 '2nd approval':  ` ${obj.hr_approver__first_name===null?"":obj.hr_approver__first_name}   ${obj.hr_approver__last_name===null?"-":obj.hr_approver__last_name}`,
                 leaveType: obj.leave_type,
                 is_approver_approved:obj.is_approver_approved,
                 requestedby: ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                 year: Year,
                };
             });
            //  this.$refs.dialog.showDialog = false;
             this.$store.commit("hrResources/SET_ALL_LEAVES_TO_STATE", this.values);
             this.$store.commit(
               "hrResources/SET_CURRENT_PAGE_TO_STATE",
               response.data.current_page
             );
             this.$store.commit(
               "hrResources/SET_TOTAL_PAGES_TO_STATE",
               response.data.total_data
             );
           }
         });

},

 clearFilters() {
  this.start_date = '';
  this.end_date = '';
  this.status = '';
},


     getAllLeaves(page) {
       const userKey = this.isUserKey;

 const payload = {
   user_key: userKey,
 };

       Api()
         .post(`${getEmployeeLeaveByHrOrApprover}${page}/`,payload)
         .then((response) => {
           if (response.data.status === "success") {
             const data = response.data.data;

            //  this.$refs.dialog.showDialog = false;
             this.values = data.map((obj) => {
               const startDate = moment(obj.leave_start_date).format('Do MMM YYYY');
                 const endDate = moment(obj.leave_end_date).format('Do MMM YYYY');
                 const Year = moment(obj.leave_start_date).format('YYYY');
                 const days = Math.floor(obj.total_days);
               const hours = Math.floor((obj.total_days - days) * 24);
               const result = `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`;
                 return {
                 id: obj.id,
                 days:result,
                 date: ` ${startDate} - ${endDate}`,
                 status: obj.status,
                 'leave type': obj.leave_type,
                 'requested by': ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                 '1st approval':  ` ${obj.leave_approver__first_name===""?"":obj.leave_approver__first_name}   ${obj.leave_approver__last_name===""?"-":obj.leave_approver__last_name}`,
                 '2nd approval':  ` ${obj.hr_approver__first_name===null?"":obj.hr_approver__first_name}   ${obj.hr_approver__last_name===null?"-":obj.hr_approver__last_name}`,
                 leaveType: obj.leave_type,
                 month:obj.leave_start_date,
                 requestedby: ` ${obj.employee__first_name}   ${obj.employee__last_name}`,
                 year: Year,
               };
             });
            //  this.$refs.dialog.showDialog = false;
             this.$store.commit("hrResources/SET_ALL_LEAVES_TO_STATE", this.values);
             this.$store.commit(
               "hrResources/SET_CURRENT_PAGE_TO_STATE",
               response.data.current_page
             );
             this.$store.commit(
               "hrResources/SET_TOTAL_PAGES_TO_STATE",
               response.data.total_data
             );
           }
         });
     },

     showDelete(data) {
      this.isLeavedeleteId = data?.$event?.id;
      if (data.data === "delete") {
        this.isDelete = !this.isDelete;
        this.$refs.delete.showDialog = true;
      }
    },
    closeDelete() {
      this.$refs.delete.showDialog = false;
      this.getAllLeaves(this.currentPage);
    },
    deleteLeave(){
      Api()
          .delete(`/hr/employee/delete-leave/${this.isLeavedeleteId}/`,)
          .then((response) => {
            if (response.data.status === "success") {
              this.alertType = "success";
              this.alertState = !this.alertState;
              this.alertTitle = response.data.detail;
              this.$refs.delete.showDialog = false;
          this.getAllLeaves(this.currentPage)
            }
          })
          .catch((error) => {
            if (error) {

              this.alertType = "error";
              this.alertTitle = error.response.data.detail;
              this.alertState = !this.alertState;
              this.$refs.delete.showDialog = false;
            }
          });

    },


     loadMore() {
       const page=this.currentPages;
       this.getAllLeaves(page);
     },
     loadMorefilter() {
       const page=this.currentfilter;
       this.searchLeave(page);

     },
   },
   mounted() {
     this.getAllLeaves(this.currentPage);
   },

 });
</script>

<style scoped>
.table {
  width: 100%;
}
</style>
