<template>
  <v-app-bar height="73" color="" elevation="0" class="!border-blue-500 px-5">
    <v-icon v-if="rail" class="ml-2 mr-4" icon="custom:logoIcon"></v-icon>
    <v-spacer></v-spacer>
    <app-locale class="px-2"></app-locale>
    <!-- <app-notification class="mx-3"></app-notification> -->
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-if="isUserAuthDetails?.profile_picture" icon v-bind="props">
          <img
              :src="isUserAuthDetails?.profile_picture"
              alt=""
              class="w-[40px] h-[40px] rounded-full object-cover"
          />
        </v-btn>
        <div v-else v-bind="props" class="avatar" :style="getAvatarStyle">
          {{ initials }}
        </div>
      </template>
      <v-list class="py-2">
        <v-badge dot offset-y="35" offset-x="195" color="#12B76A">
          <v-list-item
              :prepend-avatar="isUserAuthDetails?.profile_picture"
              :title="isUsername"
              :subtitle="isCurrentUserEmail"
              nav
              v-if="isUserAuthDetails?.profile_picture"
          >
          </v-list-item>
          <v-list-item v-else nav>
            <template v-slot:prepend>
              <div class="avatar" :style="getAvatarStyle">{{ initials }}</div>
            </template>
            <v-list-item-title>{{ isUsername }}</v-list-item-title>
            <v-list-item-subtitle>{{
                isCurrentUserEmail
              }}</v-list-item-subtitle>
          </v-list-item>
        </v-badge>
        <v-divider></v-divider>
        <!-- <v-list-item class="hover:bg-slate-100">
          <v-list-item-title to="profile" style="cursor: pointer">{{
            $t("appnavbar.myProfile")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item class="hover:bg-slate-100">
          <v-list-item-title to="settings" style="cursor: pointer">{{
            $t("appnavbar.settings")
          }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item class="hover:bg-slate-100">
          <v-list-item-title style="cursor: pointer" @click="navigateToTimesheet">{{
              $t('Project Time Sheet'.getTranslationKey())
            }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="hover:bg-slate-100">
          <v-list-item-title style="cursor: pointer" @click="logout">{{
              $t("appnavbar.logout")
            }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script lang="js">
import { defineComponent } from "vue";
// import AppNotification from "./AppNotification.vue";
import AppLocale from "./AppLocale.vue";
import { logoutUser } from "@/services/auth";

export default defineComponent({
  name: "App Navbar",
  components: {
    // AppNotification,
    AppLocale },
  data() {
    return {
      avatar: require("@/assets/Avatar.svg"),
    };
  },
  computed:{
    rail(){
      return this.$store.getters["auth/isRail"]
    },
    isCurrentUserEmail() {
      return this.$store.getters["auth/isCurrentUserEmail"];
    },
    isUsername() {
      return this.$store.getters["auth/isUsername"];
    },
    isUserAuthDetails() {
      return this.$store.getters["auth/isUserDetails"];
    },
    initials() {
      // Get the initials from the user's name
      const names = this.$store.getters["auth/isUserDetails"];
      const firstInitial = names?.first_name?.charAt(0)?.toUpperCase();
      const lastInitial = names?.last_name?.charAt(0)?.toUpperCase();
      return firstInitial + lastInitial;
    },
    getAvatarStyle() {
      // Define the background style for the avatar
      return {
        backgroundColor: '#f2f2f2', // Set the desired background color
        color: '#333', // Set the desired text color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '40px', // Set the desired width
        height: '40px', // Set the desired height
        borderRadius: '50%',
        marginRight: '10px' // Add margin-right value to separate avatar from details
      };
    }
  },
  methods: {
    logout() {
      this.$router.push("/");
      setTimeout(() => {
        logoutUser();
        // location.reload()
      }, 500);
    },
    navigateToTimesheet() {
      this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 5)
      this.$router.push("/profile");
    },
  },
});
</script>

<style scoped>
.v-app-bar {
  border-top: 2px solid #8b2923 !important;
  border-bottom: 1px solid #eaecf0 !important;
}
.v-divider {
  margin: 10px;
  border: 0.5px solid #868788 !important;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 40px;
  padding-top: 2px;
  width: 240px;
  padding-bottom: 2px;
}
.v-divider[data-v-3807d652] {
  margin: 0px;
  border: 0.5px solid #868788 !important;
}
.v-divider[data-v-9dceee0e] {
  margin: 0px !important;
}
</style>
