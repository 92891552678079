<template>
  <!-- language -->
  <div class="container">
    <!-- dialog for adding and updating language-->
    <app-dialog
      ref="dialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
      :icon="icon"
    >
      <template #content>
        <div>
          <app-select-field
            class="mb-3"
            :label="$t('employeeLanguage.language') + '*'"
            :options="languagesTitle"
            v-model="language"
            :placeholder="$t('employeeLanguage.selectLanguage')"
            data-cy="selectLanguage"
          ></app-select-field>

          <app-select-field-object
            class="mb-3"
            :label="$t('employeeLanguage.reading') + '*'"
            type="text"
            :options="languageOptions"
            v-model="reading"
            placeholder="Select reading proficiency"
            data-cy="selectReading"
          ></app-select-field-object>
          <app-select-field-object
            class="mb-3"
            :label="$t('employeeLanguage.speaking') + '*'"
            type="text"
            v-model="speaking"
            :options="languageOptions"
            placeholder="Select speaking proficiency"
            data-cy="selectSpeaking"
          ></app-select-field-object>
          <app-select-field-object
            :label="$t('employeeLanguage.writing') + '*'"
            type="text"
            :options="languageOptions"
            v-model="writing"
            placeholder="Select writing proficiency"
            data-cy="selectWriting"
          ></app-select-field-object>
        </div>
      </template>
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="5" class="">
            <app-secondary-button
              :buttonLabel="$t('employeeLanguage.cancel')"
              @click-event="hideDialog()"
              data-cy="btn_langCancel"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="7" class="">
            <app-primary-button
              :buttonLabel="
                isEdit
                  ? $t('employeeLanguage.updateLanguage')
                  : $t('employeeLanguage.addLanguage')
              "
              :loading="loading"
              @click-event="isEdit ? updateItem() : addToLanguage()"
              data-cy="btn_Language"
              :disabled="
                language === '' ||
                reading === '' ||
                speaking === '' ||
                writing === ''
              "
            >
            </app-primary-button>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- dialog for delete language -->
    <app-dialog
      ref="isDeleteDialog"
      :title="title"
      :subtitle="subTitle"
      height="302"
      width="408"
    >
      <template #actions>
        <v-row class="px-4 mt-1 mb-5">
          <v-col cols="6" class="">
            <app-secondary-button
              :buttonLabel="$t('employeeLanguage.noKeepIt')"
              @click-event="hideDeleteDialog()"
              data-cy="btn_langkeepIt"
            >
            </app-secondary-button>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              variant="default"
              min-width="100%"
              min-height="100%"
              class="bg-[#D92D20] text-white !normal-case rounded-lg font-['Open_Sans'] text-[1rem] font-normal"
              @click="confirmDeleteAction()"
              data-cy="btn_langDelete"
            >
              {{ $t("employeeLanguage.yesDelete") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>
    <!-- Language -->
    <div class="card_container">
      <div class="flex items-center justify-between header">
        <h3 class="H600">{{ $t("employeeLanguage.language") }}</h3>
        <app-primary-button
          v-if="!showAndHideElement"
          :buttonLabel="$t('employeeLanguage.addLanguage')"
          @click-event="showDialog({ data: 'add' })"
          
          data-cy="btn_addLanguage"
        >
          <template #icon>
            <v-icon icon="mdi-plus"></v-icon>
          </template>
        </app-primary-button>
      </div>
      <div class="flex content SPC-MT-400">
        <div class="left_section w-50">
          <h3 class="H500">{{ $t("employeeLanguage.languageInformation") }}</h3>
          <p class="P300 desc">
            {{ $t("employeeLanguage.languageProficiencies") }}
          </p>
        </div>
        <div class="flex w-100 flex-column">
          <div
            v-for="item in values"
            :key="item.id"
            class="flex items-center justify-between my-2 right_section w-100"
          >
            <div>
              <h3 class="H500">{{ item.language }}</h3>
              <p class="P300">
                {{ $t("employeeLanguage.reading") }} ({{ item.reading }}) |
                {{ $t("employeeLanguage.writing") }} ({{ item.writing }}) |
                {{ $t("employeeLanguage.speaking") }} ({{ item.speaking }})
              </p>
            </div>
            <div>
              <DropDown
                v-if="!showAndHideElement"
                @edit-event="showDialog({ $event, data: 'edit', id: item.id })"
                @delete-event="
                  showDeleteDialog({ $event, data: 'delete', id: item.id })
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style="border-bottom: 1px solid #eaecf0; width: 100%"
        class="SPC-MT-400"
      ></div>
    </div>
    <app-alert
      :title="$t(getTranslatedStringKey(alertTitle))"
      @close-alert="alertState = false"
      :state="alertState"
      :timeout="5000"
      :type="alertType"
      id="alert"
      data-cy="response_alert"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Api from "@/services/api";
import { mapState } from "vuex";
import AppPrimaryButton from "@/components/shared/AppPrimaryButton.vue";
import DropDown from "@/components/shared/DropDown.vue";
import { getTransString } from "@/services/helpers";
export default defineComponent({
  name: "Qualification",
  components: {
    DropDown,
    AppPrimaryButton,
  },
  data() {
    return {
      loading: false,
      alertType: "success",
      alertState: false,
      alertTitle: "",
      addpage: false,
      currentPage: 1,
      totalPages: 50,
      header: ["Institution", "Certificate Title"],
      languageOptions: [
        {
          id: "1 - Elementary Proficiency",
          value: "1 - Elementary Proficiency",
        },
        {
          id: "2 - Limited Working Proficiency",
          value: "2 - Limited Working Proficiency",
        },
        {
          id: "3 - Professional Working Proficiency",
          value: "3 - Professional Working Proficiency",
        },
        {
          id: "4 - Full Professional Proficiency",
          value: "4 - Full Professional Proficiency",
        },
        {
          id: "5 - Native / Bilingual Proficiency",
          value: "5 - Native / Bilingual Proficiency",
        },
      ],
      data: [],
      cancelLabel: "Cancel",
      buttonLabel: "Add Language",
      title: "",
      subTitle: "",
      isEdit: false,
      isAdd: false,
      isDelete: false,
      addTitle: "",
      modelValue: "",
      items: null,
      values: [],
      language: "",
      reading: "",
      speaking: "",
      writing: "",
    };
  },

  mounted() {
    this.getAllLanguage();
  },
  computed: {
    isAllEmployees() {
      return this.$store.getters["hrResources/isAllQualification"];
    },
    isUserKey() {
      return this.$store.getters["hrResources/isUserKey"];
    },
    languagesTitle() {
      return this.$store.getters["hrConfigurations/isLanguagesTitle"];
    },
    hrConfigLang() {
      return this.translation.locale;
    },
    canEditPersonalModule() {
      return this.$store.state.auth.canEditModuleSections;
    },
    // isEditButton() {
    //   return this.currentPath === "/profile"
    //     ? this.$store.getters["hrResources/isEditButton"]
    //     : this.$store.getters["hrResources/isEditButton"] &&
    //         this.canEditPersonalModule?.includes("Qualification") || this.canEditPersonalModule?.includes("Foreign Staff Qualification");
    // },
    isEditButton() {
      return this.currentPath === "/profile"
        ? this.$store.getters["hrResources/isEditButton"]
        : this.$store.getters["hrResources/isEditButton"] 
    },
    isEditEmployee() {
      return this.$store.getters["hrResources/isEditEmployee"];
    },
    currentPath() {
      return this.$route.path;
    },
    isHome() {
      return this.$store.getters["hrResources/isHome"];
    },
    isEmployeeUserKeyFromHome() {
      return this.$store.getters["hrResources/isEmployeeUserKeyFromHome"];
    },
    // canEdit() {
    //   return this.currentPath === "/edit-employee"
    //     ? this.canEditPersonalModule?.includes("Qualification")
    //     : true;
    // },
    canEdit() {
      return this.canEditPersonalModule?.includes("Qualification") || this.canEditPersonalModule?.includes("Foreign Staff Qualification")
        
    },
    showAndHideElement() {
      return (
        (this.currentPath === "/edit-employee" ||
          this.currentPath === "/edit-expert" ||
          this.currentPath === "/edit-foreign-employee" ||
          this.currentPath === "/profile") &&
        this.isEditEmployee &&
        !this.isEditButton
      );
    },
    ...mapState(["translation"]),
  },
  methods: {
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    addEmployee() {
      this.$router.push({ name: "addEmployee" });
    },
    showDialog({ data, id }) {
      if (data === "add") {
        this.language = "";
        this.reading = "";
        this.speaking = "";
        this.writing = "";
        this.subTitle = "";
        this.title = "employeeLanguage.addLanguage";
        // this.language = this.languagesTitle[0];
        this.isEdit = false;
        this.buttonLabel = "employeeLanguage.addLanguage";
        this.$refs.dialog.showDialog = true;
      } else if (data === "edit") {
        this.isEdit = true;
        this.buttonLabel = "employeeLanguage.update";
        const selectedItem = this.values.find((item) => item.id === id);
        if (selectedItem) {
          this.language = selectedItem.language;
          this.reading = this.getLangOptionValue(selectedItem.reading);
          this.speaking = this.getLangOptionValue(selectedItem.speaking);
          this.writing = this.getLangOptionValue(selectedItem.writing);
          this.title = "employeeLanguage.updateLanguage";
          this.updateId = id; // Store the ID of the item to be updated
          this.$refs.dialog.showDialog = true;
        }
      }
    },
    showDeleteDialog({ data, id }) {
      if (data === "delete") {
        this.isDelete = true;
        this.title = "employeeLanguage.deleteLanguage";
        this.subTitle = "employeeLanguage.areYouSure";
        this.confirmDelete = id; // Store the ID of the item to be deleted
        this.$refs.isDeleteDialog.showDialog = true;
      }
    },

    confirmDeleteAction() {
      this.deleteItem(this.confirmDelete);
    },
    getLangOptionValue(value) {
      return this.languageOptions.find((elt) => {
        const id = Number(elt.id.split("-")[0]?.trim());
        return id === value;
      })?.value;
    },
    editItem() {
      const updatedItem = {
        id: this.editId,
        institute: this.institution,
        certificate_title: this.certificate,
      };

      this.editItem(updatedItem);
      this.isEdit = false;
      this.editId = null;
      this.institution = "";
      this.$refs.dialog.showDialog = false;
    },
    hideDialog() {
      this.$refs.dialog.showDialog = false;
      setTimeout(() => {
        this.addTitle = "";
        this.isEdit = false;
        this.isAdd = false;
        this.subTitle = "";
        this.buttonLabel = "employeeLanguage.addLanguage";
        this.label = "employeeLanguage.addTitle";
        this.cancelLabel = "employeeLanguage.cancel";
      }, 600);
    },
    hideDeleteDialog() {
      this.$refs.isDeleteDialog.showDialog = false;
      this.isDelete = false;
      this.confirmDelete = null;
      this.subTitle = "";
    },
    expectValueFromString(value) {
      return parseInt(value.match(/\d+/)[0], 10);
    },
    addToLanguage() {
      this.loading = true;
      const reading = this.expectValueFromString(this.reading);
      const speaking = this.expectValueFromString(this.speaking);
      const writing = this.expectValueFromString(this.writing);
      const payload = {
        user: this.isUserKey,
        language: this.language,
        reading: reading,
        speaking: speaking,
        writing: writing,
      };
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/languages/' : '/hr/employee/qualifications/languages/'

      Api()
        .post(endpoint, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.loading = false;
            this.getAllLanguage();
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            setTimeout(() => {
              this.addTitle = "";
              this.$refs.dialog.showDialog = false;
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    getAllLanguage() {
      let newUserKey = this.isUserKey;
      if (this.isHome) {
        newUserKey = this.isEmployeeUserKeyFromHome;
      } else {
        newUserKey;
      }
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/user-languages/' : '/hr/employee/qualifications/user-languages/'

      Api()
        .get(`${endpoint}${newUserKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.headers = response.data.data.headers;
            const data = response.data.data;
            this.$refs.dialog.showDialog = false;
            this.values = data.map((obj) => {
              return {
                id: obj.id,
                language: obj.language,
                reading: obj.reading,
                speaking: obj.speaking,
                writing: obj.writing,
              };
            });
            this.$refs.dialog.showDialog = false;
          }
        });
      // .catch((error) => {
      //   if (error) {
      //     this.alertType = "error";
      //     this.alertTitle = error.response.data.detail;
      //     this.alertState = !this.alertState;
      //   }
      // });
    },
    deleteItem(id) {
      this.selectedItemId = id;
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/languages/' : '/hr/employee/qualifications/languages/'

      Api()
        .delete(`${endpoint}${this.selectedItemId}/`)
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllLanguage();
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.isDeleteDialog.showDialog = false;
            this.isDelete = false;
            this.subTitle = "";
            this.confirmDelete = null;
          }
        })
        .catch((error) => {
          if (error) {
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
    updateItem(id) {
      this.loading = true;
      this.selectedItemId = id;
      const reading = this.expectValueFromString(this.reading);
      const speaking = this.expectValueFromString(this.speaking);
      const writing = this.expectValueFromString(this.writing);
      const payload = {
        language: this.language,
        reading: reading,
        speaking: speaking,
        writing: writing,
      };
      const endpoint = this.currentPath === '/add-foreign-employee' || this.currentPath === '/edit-foreign-employee' ? '/hr/foreign-employee/qualifications/languages/' : '/hr/employee/qualifications/languages/'

      Api()
        .patch(
          `${endpoint}${this.updateId}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getAllLanguage();
            this.loading = false;
            this.alertType = "success";
            this.alertTitle = response.data.detail;
            this.alertState = !this.alertState;
            this.$refs.dialog.showDialog = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
            this.alertType = "error";
            this.alertTitle = error.response.data.detail;
            this.alertState = !this.alertState;
          }
        });
    },
  },
  created() {
    
    this.$store.dispatch("hrConfigurations/getAllConfigurationLanguages", 1);
  },
});
</script>

<style scoped>
label {
  color: #344054;
}

.header {
  margin-top: 50px;
}
.desc {
  width: 300px;
}
</style>
